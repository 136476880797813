import { Component, Inject, OnInit, Renderer2, forwardRef } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LocalStorageService } from '../local-storage.service';
import { ThemeService } from '../service/theme.service';
import { CognitoService } from '../service/cognito.service';
import { ClientService } from '../service/client.service';
import { Router } from '@angular/router';
import { RoleService } from '../service/role.service';
import { IotService } from '../service/iot.service';
import { DashboardService } from '../service/dashboard.service';
import { ModalService } from '../service/device-modal.service';
import { DistributorsService } from '../service/distributors.service';
import { environment } from '../environments/environment';
import { FilterService } from '../service/filter.service';


@Component({
  selector: 'app-client-user-list',
  templateUrl: './client-user-list.component.html',
  styleUrls: ['./client-user-list.component.css', '../../global-elements.css']
})
export class ClientUserListComponent implements  OnInit {

  languageStatus:string = this.localStorageService.getItem('language');

  public currentClientId: string = '';
  public currentClientRole: any;
  public clientUserArray: any[] = [];
  public originalClientUserArray: any[] = [];
  public userFilter: string = "";
  public filterBy: string = '';
  public sortBy: string = '';
  public ascDesc: number = 0;

  constructor(
    @Inject(forwardRef(() => TranslateService)) private translate: TranslateService,
    private localStorageService: LocalStorageService,
    public theme: ThemeService,
    private cognitoService: CognitoService,
    public clientService: ClientService,
    public roleService: RoleService,
    public iotService: IotService,
    public dashboardService: DashboardService,
    public modal: ModalService,
    public distributorService: DistributorsService,
    private renderer: Renderer2,
    private router: Router,
    private filter: FilterService
    ){
      // Check if the user has selected a language in local storage
      //or use a default language
      if (this.languageStatus == null){
        // Set the default language to French
        translate.use('fr');
      } else {
        // Set the default language to the user's selected language
        translate.use(this.languageStatus);
      }

  }

  async ngOnInit() {
    await this.cognitoService.confirmValidUser();
    await this.cognitoService.getUserType();
    // Call role service to get the current user roles
    this.currentClientRole = await this.roleService.getRoles();

    // Check if user have all permission to be in that component
    await this.cognitoService.getCurrentRole([environment.users.role.administrator, environment.users.role.client, environment.users.role.distributor], [environment.users.standardUser, environment.users.superAdmin], true, this.roleService.roles);

    // Call the function to initialize all data of the page
    this.initData();
  }

  // Asynchronously initialize data
  async initData() {
    // Get the current user using Cognito service
    const currentUser = await this.cognitoService.getUser();

    // Extract and set the current client ID from user attributes
    this.currentClientId = currentUser.attributes['custom:client_id'];

    // Retrieve all users for the current client ID
    await this.getAllClientUsers(this.currentClientId);
  }

  // Function to filter and count users based on user_type
  async getAllClientUsers(clientId: string) {
    // Retrieve users from the client service
    await this.clientService.getUsersByClientId(clientId).then((res) => {
      this.clientUserArray = res;
      this.originalClientUserArray = res;
    });
  }

  // Function used to made a search in the list
  search(){
    // Check if there's a filterBy variable and set it to it's default value
    if(this.filterBy === ''){
      this.filterBy = 'given_name';
    }

    this.clientUserArray = this.filter.search(this.userFilter, this.clientUserArray, this.originalClientUserArray, this.filterBy);
  }

  // Function called to get the style of the columns of the grid list
  getColumnStyle(): any {
    const numberOfColumn = this.ajustNumberOfColumns(this.getScreenWidth());
    if(numberOfColumn){
      if(this.clientUserArray.length >= numberOfColumn){
        const gridColumns = `repeat(${numberOfColumn}, minmax(0, 300px))`;
        return {
          'grid-template-columns': gridColumns
        };
      }else{
        return {
          'display': `flex`,
          'justify-content': `center`,
          'margin': '0 -10px'
        };
      }
    }
  }

  // Navigates the user to the client creation page.
  toCreateClient(){
    sessionStorage.setItem('createClient', 'client-user-list')
    this.router.navigate(['/user-create-client'])
  }

  // function called to adjust the number of column of the grid depbending of inner window width
  ajustNumberOfColumns(width: number): number | undefined{
    // Return the floor of the inner page width divided by 450 px as the width of the tile size
    return Math.floor((width / 325) * (90 / 100));
  }

  // Function to get the current screen width
  getScreenWidth(): number {
    // Return the inner width of the window as the screen width
    return window.innerWidth;
  }

  // Handles changes in sorting for a list of clients.
  onSortChange(caller: string){
    // Switch the value of ascending or descending
    if(caller === 'switch'){
      if(this.ascDesc === 0){
        this.ascDesc = 1;
      }else{
        this.ascDesc = 0;
      }
    }

    // Avoid sortBy to have no value
    if(this.sortBy === ''){
      this.sortBy = 'address';
    }

    // Function that sort the list
    this.clientUserArray.sort((a, b) => {
      switch(this.sortBy){
        case 'address':
          return this.ascDesc === 1 ? b.address.localeCompare(a.address) : a.address.localeCompare(b.address);

        case 'username':
          return this.ascDesc === 1 ? b.username.localeCompare(a.username) : a.username.localeCompare(b.username);
      }
    });
  }

   /**
 * Function that toggles the display of the complete address of the user .
 * @param i the id of the address container
 */
  displayAddress(i:number){
    //get the address container
    let element = document.getElementById(i.toString());
    //logic to display the address
    if(element && element.classList.contains('address')){
      //to show complete address
      this.renderer.removeClass(element,'address')
      this.renderer.addClass(element,'addressVisible')
    } else{
      //to show address with ellipsis
      this.renderer.addClass(element,'address')
      this.renderer.removeClass(element,'addressVisible')
    }
  }

  // Navigate to the user update page with specified parameters
  async goToUserUpdate(userSub: string, username: string) {
    // Check if the current user has the administrator role
    if (this.currentClientRole.includes(environment.users.role.administrator)) {

        // If the user is an administrator, navigate to the user-update-client page
        this.router.navigate(['./user-update-client'], {
            queryParams: {
                userSub,
                username
            }
        });
    }
  }

}
