import { ZoneService } from './../service/zone.service';
import { Component, OnInit, HostListener } from '@angular/core';
import { CommonModule } from '@angular/common';
import { environment } from '../environments/environment';
import { navigation } from '../constants/navigation';
import { ThemeService } from '../service/theme.service';
import { DashboardService } from '../service/dashboard.service';
import { IotService, MultipleDevices } from '../service/iot.service';
import { Devices } from '../service/iot.service';
import { ChartService } from '../service/chart.service';
import { CognitoService } from '../service/cognito.service';
import { ReportsService } from '../service/reports.service';


interface ZoneItem {
  strokeColor: string;
  zoneName: string;
  clientId: string,
  client_name: string,
  fillColor: string,
  patch: [],
  markers: [],
  active: ""
}

@Component({
  selector: 'app-muirwood-dashboard',
  templateUrl: './muirwood-dashboard.component.html',
  styleUrls: ['./muirwood-dashboard.component.css', '../../dashboard-tiles.css']
})
export class MuirwoodDashboardComponent implements OnInit{

  public map: any = {};
  public showCreateZoneModal: boolean = false;
  public showUpdateZoneModal: boolean = false;
  isSmallScreenWidth: boolean = this.getScreenWidth() <= 859;
  public navigationItemsArray: any = [];
   // Initialize an array to store GPS data
   public gpsArray: any[] = [];
   public newClientArray: any[] = [];
   public clientsData: any[] = [];
   public zonesData: any[] = [];
   public patchArray: any[] = [];
   public globalPath: any[] = [];
   public dots: any[] = [];
   public clientArray: any[] = [];
   public zonesArray2: any[] = [];
   public zonesArray: ZoneItem[] = []

   // when true show all zones whitin zonesArray
  public showAllZones: boolean = true;

  // Initialize isCustomComponent as true
  public isCustomComponent: boolean = true;

  public enable: any;

  // Initialize lastLocationgroup
  public lastLocationgroup: any;

  // Initialize LATITUDE with 0
  public LAT: number = 0;

  // Initialize LONGITUDE with 0
  public LNG: number = 0;

  // the value will be updated according to the zone chosen for visualization by the user
  public chosenZone: number = 3;

  // Initialize array of Devices
  public array: Devices[] = [];

  // Initialize an array for MultipleDevices
  public myDevicesArray: MultipleDevices[] = [];

  public deviceArray: Devices[] = [];
  gridsterOptions = {
    draggable: {
      enabled: false,
    },
    resizable: {
      enabled: false,
    },
    minCols: 1,
    maxCols: 3,
    minRows: 1,
    maxRows: 2,
    defaultItemCols: 1,
    defaultItemRows: 1,
  };

  dashboardItems = [
    { cols: 2, rows: 1, y: 0, x: 0 },
    { cols: 1, rows: 1, y: 0, x: 2 },
    // Add more items as needed
  ];

  constructor(public theme: ThemeService,
              public dashboard: DashboardService,
              public devices: IotService,
              public charts: ChartService,
              public zoneService: ZoneService,
              public cognitoService: CognitoService,
              public reports : ReportsService
             ){

              }

  async ngOnInit(){
    //Filter navigation items
    this.navigationItemsArray = navigation.filter(item => item.userType === environment.users.superAdmin);

    // Initialize indexToRemove with -1
    let indexToRemove = -1;

    // Loop through the navigationItemsArray to find the index of the item to remove
    for (let i = 0; i < this.navigationItemsArray.length; i++) {
        // Check if the name of the current item is 'sidenavMenu.WORKORDER'
        if (this.navigationItemsArray[i].name === 'sidenavMenu.WORKORDER') {
          // If found, set indexToRemove to the current index and exit the loop
            indexToRemove = i;
            break;
        }
    }

    // Remove the item if the index is found
    if (indexToRemove !== -1) {
        this.navigationItemsArray.splice(indexToRemove, 1);
    }

    //Runs all necessary functions for the Dashboard on init
    this.initialize();

    await this.getZonesList();

    //Get current screen width on init
    const currentScreenWidth = this.getScreenWidth();
    const currentScreenHeight = this.getScreenHeight();

    // Check the screen width and update the isSmallScreen flag accordingly
    this.isSmallScreenWidth = currentScreenWidth <= 859;

    //Adjust chart parameters for view size
    this.charts.adjustView(currentScreenWidth, currentScreenHeight);
  }

  //Required to run the on load functions for the Muirwood Dashboard
  async initialize(){
    //Dashboard service init function for Muirwood Dashboard
    await this.dashboard.initializeDashboardData();
  }

  // Method to check if the screen meets the condition for applying a new layout
  shouldApplyNewLayout(): boolean {
    // Return true if either the showUpdateZoneModal or showCreateZoneModal is true
    // and the screen size is considered small (based on the isSmallScreen flag)
    return (this.showUpdateZoneModal || this.showCreateZoneModal) && (this.isSmallScreenWidth);
  }

    // Event listener for window resize
  @HostListener('window:resize', ['$event'])
  onResize(event: any): void {

      const currentScreenWidth = this.getScreenWidth();
      const currentScreenHeight = this.getScreenHeight();
      // Check the screen width and update the isSmallScreen flag accordingly
      this.isSmallScreenWidth = currentScreenWidth <= 859;

      //Adjust chart parameters for view size
      this.charts.adjustView(currentScreenWidth, currentScreenHeight);
  }

  // Function to get the current screen width
  getScreenWidth(): number {
    // Return the inner width of the window as the screen width
    return window.innerWidth;
  }

  // Funciton to get the current screen height
  getScreenHeight(): number {
    // Return the current screen height of the window
    return window.innerHeight;
  }

  // Function to create a Google Map
  // Takes a center object with latitude (lat) and longitude (lng) properties
  // Returns a Google Map instance
  createGoogleMap(center: { lat: number, lng: number }): google.maps.Map {
    // Create a new Google Map instance, passing the HTML element with the id "mainMap" as the map container
    // Set initial zoom level to 2.5, center the map at the specified coordinates, and use the map with the ID 'DEVICES_MAP'
    // Disable default UI elements for a cleaner map interface
    return new google.maps.Map(document.getElementById("mainMap") as HTMLElement, {
        zoom: 2.5,
        center: center,
        mapId: 'DEVICES_MAP',
        disableDefaultUI: true,
    });
  }

  // Initialize Google Maps
  async initMap(): Promise<void> {


    // Initialize variables for drawing polygon and storing polygon path
    let drawingPolygon: google.maps.Polygon | null = null;
    let polygonPath: google.maps.LatLng[] = [];

    // Defines the initial center of the map
    let center = { lat: 45.65054871734081, lng: -73.84789617258333 };

    // Create Google Map with the specified center
    let map = this.createGoogleMap(center);
  }

  // Asynchronously fetch a list of things and device information from the IoT service
  async displayMarkers(map: google.maps.Map): Promise<void> {
    // Retrieve the list of things and device information
    const thingsList = await this.devices.listThings();
    const deviceList = await this.devices.getDeviceList();

    // Loop through the list of things to gather GPS information
    for (let i = 0; i < thingsList.length; i++) {
        let name: string | undefined;
        name = thingsList[i].thingName;
        if (name !== undefined) {
            // Retrieve the device shadow information, including GPS coordinates
            const result = await this.devices.getDeviceShadow(name);
            this.gpsArray.push(result.state.reported.dat.gps);
        }
    }

    // Loop through the collected GPS coordinates to create markers on the map
    for (let i = 0; i < this.gpsArray.length; i++) {
        // Extract latitude and longitude from the GPS coordinate string
        let gpsString: string = this.gpsArray[i].toString();
        let gpsSplited = gpsString.split(",");
        let latNumber = Number(gpsSplited[0]);
        let lngNumber = Number(gpsSplited[1]);
        this.LAT = latNumber;
        this.LNG = lngNumber;

        // Define marker properties
        const marker = {
            position: { lat: this.LAT, lng: this.LNG },
            map: map,
            icon: "assets/bin.png",
            device: thingsList[i],
            gps: this.gpsArray[i],
            animation: google.maps.Animation.DROP
        };

        // Check if modals are not being displayed before creating a new marker
        if (!this.showCreateZoneModal && !this.showUpdateZoneModal) {
            // Create a new marker on the map
            const newMarker = new google.maps.Marker(marker);

            // Add a click event listener to the marker
            newMarker.addListener("click", () => {
                // Call the hasMultipleDevices function with the corresponding GPS coordinates and the list of devices
                this.hasMultipleDevices(this.gpsArray[i], deviceList);

                // Set the map zoom level to 10 and center it on the clicked marker position
                map.setZoom(10);
                map.setCenter(newMarker.getPosition() as google.maps.LatLng);
            });
        }
    }
  }

  //Pass the Map object to iterate through Zone data and populate the Google Map
  async displayZones(map: google.maps.Map): Promise<void> {

        // loop through zonesArray and set necessary parameters
        this.zonesArray.forEach((zone, index) => {
            const polygon = new google.maps.Polygon({
                paths: zone.patch, // apply path according to each zone
                strokeColor: zone.strokeColor, // apply stroke color according to each zone
                strokeOpacity: 0.8,
                strokeWeight: 2,
                fillColor: zone.fillColor, // apply fill color according to each zone
                fillOpacity: 0.35,
                editable: false,
            });
            polygon.setMap(map); // draw the polygons / zones
        });
  }

  //Get all zones in the application
  async getZonesList(): Promise<void> {
    try {
      // Call the zoneService to get zones data
      this.zoneService.getZones().subscribe(
        // Successful response callback
        (response: any) => {
          // Store the API response in the zonesData array
          this.zonesData = response;

          // Map the response data to a new format for easier use
          this.zonesArray2 = this.zonesData.map(item => {
            return {
              fillColor: item.zone_color,
              patch: JSON.parse(item.zone_coordinates),
              strokeColor: item.zone_color,
              zoneId: item.zone_id,
              clientId: item.client_id,
              zoneCoordinates: JSON.parse(item.zone_coordinates),
              zoneName: item.zone_name,
              modified: item.modified,
              active: item.active
            };
          });

          // Copy zonesArray2 to zonesArray
          for (let i = 0; i < this.zonesArray2.length; i++) {
            this.zonesArray.push(this.zonesArray2[i]);
          }

          // Initialize the map
          this.initMap();

          // Remove Muirwood Studio from client list to display correctly in the selection client list
          this.clientArray = this.clientArray.filter(client => client.client_name !== '');

          // Deduplicate client data based on client_name and client_id
          this.newClientArray = this.clientArray.reduce((accumulator, item) => {
            const key = item.client_name + item.client_id;
            if (!this.map[key]) {
              this.map[key] = true;
              // Add unique clients to the newClientArray
              accumulator.push({ client_id: item.client_id, client_name: item.client_name });
            }
            return accumulator;
          }, []);
        }
      );
    } catch (error) {
      // Handle and log any errors that occur during the process
      console.error("Error: ", error);
    }
  }

  /**
 * Fetches a device location group using the provided parameters.
 *
 * @param array - An array of parameters used to fetch the location group.
 * @param devices - A list of devices to filter based on the array.
 * @returns A Promise resolving to the location group of devices.
*/
  async hasMultipleDevices(array: any, devices: Devices[]){
    // Call the IOT service to fetch the device location group and return group
    // of devices with the same coordinates.
    let locationGroup = await this.devices.getDeviceLocationGroup(array, devices);
    if (this.lastLocationgroup == locationGroup) {
    }
    // Check if 'locationGroup' is undefined
    if (locationGroup == undefined) {
      // If it's undefined, assign it the value of 'this.lastLocationgroup'
      locationGroup = this.lastLocationgroup
    } else {
      // If it's defined, update 'this.lastLocationgroup' with its value
      this.lastLocationgroup = locationGroup;
    }

    // Loop through each element in the 'locationGroup' array
    for (let i = 0; i < locationGroup.length; i++) {

      // Get the device name from the current 'locationGroup' element
      const deviceName = locationGroup[i].thingName;

      // Create an object 'multipleDevicesWithSameCoordinates' with the current device
      const multipleDevicesWithSameCoordinates: MultipleDevices = {
        devices: [locationGroup[i]]
      }

      // Push 'multipleDevicesWithSameCoordinates' into 'myDevicesArray'
      this.myDevicesArray.push(multipleDevicesWithSameCoordinates);
    }
    return locationGroup;
  }
}

