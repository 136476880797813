import { RelationshipModalComponent } from './relationship-modal/relationship-modal.component';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { UserEditComponent } from './user-edit/user-edit.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { MatTableModule } from '@angular/material/table';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DashboardComponent } from './dashboard/dashboard.component';
import { RouterModule, Router } from '@angular/router';
import { SideNavComponent } from './side-nav/side-nav.component';
import { FilterPipe } from './filter.pipe';
import { GoogleMapsModule } from '@angular/google-maps';
import { IotComponent } from './iot/iot.component';
import { ConfigsComponent } from './configs/configs.component';
import { AdminMapComponent } from './admin-map/admin-map.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { DriverDashboardComponent } from './driver-dashboard/driver-dashboard.component';
import { MatStepperModule } from '@angular/material/stepper';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { DriversComponent } from './drivers/drivers.component';
import {
  TranslateLoader,
  TranslateModule,
  TranslateService,

} from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient, HttpBackend } from '@angular/common/http';

import { DeviceModalComponent } from './device-modal/device-modal.component';
import { IotService } from './service/iot.service';
import { RoleService } from './service/role.service';
import { CognitoService } from './service/cognito.service';
import { ClientService } from './service/client.service';
import { ValidationService } from './service/validation.service';
import { BinsService } from './service/bins.service';
import { FilterService } from './service/filter.service';
import { CustomRouteReuseStrategy } from './custom-route-reuse-strategy';
import { RouteReuseStrategy } from '@angular/router';
import { CommonModule } from '@angular/common';
import { ClientCreateComponent } from './client-create/client-create.component';
import { ClientListComponent } from './client-list/client-list.component';
import { ClientEditComponent } from './client-edit/client-edit.component';
import { BinUsageComponent } from './bin-usage/bin-usage.component';
import { BinListComponent } from './bin-list/bin-list.component';
import { BinCreateComponent } from './bin-create/bin-create.component';
import { AdminComponent } from './admin/admin.component';
import { DeviceCreateComponent } from './device-create/device-create.component';
import { BinModelListComponent } from './bin-model-list/bin-model-list.component';
import { BinModelCreateComponent } from './bin-model-create/bin-model-create.component';
import { BinUpdateComponent } from './bin-update/bin-update.component';
import { AlertModalComponent } from './alert-modal/alert-modal.component';
import { BinModelUpdateComponent } from './bin-model-update/bin-model-update.component';
import { BinModalComponent } from './bin-modal/bin-modal.component';
import { EntitiesComponent } from './entities/entities.component';
import { DistributorsListComponent } from './distributors-list/distributors-list.component';
import { TaxeTypeListComponent } from './taxe-type-list/taxe-type-list.component';
import { TaxeTypeCreateComponent } from './taxe-type-create/taxe-type-create.component';
import { TaxeTypeUpdateComponent } from './taxe-type-update/taxe-type-update.component';
import { NotificationListComponent } from './notification-list/notification-list.component';
import { DistributorsCreateComponent } from './distributors-create/distributors-create.component';
import { DistributorsEditComponent } from './distributors-edit/distributors-edit.component';
import { UserListComponent } from './user-list/user-list.component';
import { UserCreateClientComponent } from './user-create-client/user-create-client.component';
import { UserCreateDistributorComponent } from './user-create-distributor/user-create-distributor.component';
import { UserCreateMuirwoodComponent } from './user-create-muirwood/user-create-muirwood.component';
import { DeviceModalConfigComponent } from './device-modal-config/device-modal-config.component';
import { NotificationService } from './service/notification.service';
import { MatDialog } from '@angular/material/dialog';
import { ErrorModalComponent } from './error-modal/error-modal.component';
import { OperatorListComponent } from './operator-list/operator-list.component';
import { OperatorCreateComponent } from './operator-create/operator-create.component';
import { OperatorUpdateComponent } from './operator-update/operator-update.component';
import { UserUpdateClientComponent } from './user-update-client/user-update-client.component';
import { UserUpdateMuirwoodComponent } from './user-update-muirwood/user-update-muirwood.component';
import { UserUpdateDistributorComponent } from './user-update-distributor/user-update-distributor.component';
import { WorkOrderCreateComponent } from './work-order-create/work-order-create.component';
import { UserCreateOperatorComponent } from './user-create-operator/user-create-operator.component';
import { UserUpdateOperatorComponent } from './user-update-operator/user-update-operator.component';
import { MicrosoftSignUpComponent } from './microsoft-sign-up/microsoft-sign-up.component';
import { OperatorDashboardComponent } from './operator-dashboard/operator-dashboard.component';
import { DistributorDashboardComponent } from './distributor-dashboard/distributor-dashboard.component';
import { ClientDashboardComponent } from './client-dashboard/client-dashboard.component';
import { MuirwoodDashboardComponent } from './muirwood-dashboard/muirwood-dashboard.component';
import { GridsterModule } from 'angular-gridster2';
import { environment } from './environments/environment';
import { SystemMessageComponent } from './system-message/system-message.component';
import { WorkOrderListComponent } from './work-order-list/work-order-list.component';
import { PieChartComponent } from './pie-chart/pie-chart.component';
import { BarChartComponent } from './bar-chart/bar-chart.component';
import { BarChartAdminComponent } from './bar-chart-admin/bar-chart-admin.component';
import { MapComponent } from './map/map.component';
import { ClientThingListComponent } from './client-thing-list/client-thing-list.component';
import { BinDashboardComponent } from './bin-dashboard/bin-dashboard.component';
import { DistributorBinsDashboardComponent } from './distributor-bins-dashboard/distributor-bins-dashboard.component';
import { ClientBinDashboardComponent } from './client-bin-dashboard/client-bin-dashboard.component';
import { DistributorWorkOrderDashboardComponent } from './distributor-work-order-dashboard/distributor-work-order-dashboard.component';
import { PieChartSingleComponent } from './pie-chart-single/pie-chart-single.component';
import { WorkOrderDashboardModalComponent } from './work-order-dashboard-modal/work-order-dashboard-modal.component';
import { ClientUserListComponent } from './client-user-list/client-user-list.component';
import { UserEditModalComponent } from './user-edit-modal/user-edit-modal.component';
import { UserEditPreferenceComponent } from './user-edit-preference/user-edit-preference.component';
import { DistributorIotAssociationModalComponent } from './distributor-iot-association-modal/distributor-iot-association-modal.component';
import { TruckListComponent } from './truck-list/truck-list.component';
import { OperatorAdminDashboardComponent } from './operator-admin-dashboard/operator-admin-dashboard.component';
import { DriverActiveRouteComponent } from './driver-active-route/driver-active-route.component';
import {
  MsalService,
  MsalModule,
  MsalGuardConfiguration,
  MsalInterceptor,
  MsalInterceptorConfiguration,
} from '@azure/msal-angular';
import {
  InteractionType,
  IPublicClientApplication,
  PublicClientApplication,
} from '@azure/msal-browser';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { EntitiesLocationListComponent } from './entities-location-list/entities-location-list.component';
import { EntitiesLocationCreateComponent } from './entities-location-create/entities-location-create.component';
import { EntitiesLocationUpdateComponent } from './entities-location-update/entities-location-update.component';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { LoadingElementComponent } from './loading-element/loading-element.component';
import { DistributorBinsModelDashboardComponent } from './distributor-bins-model-dashboard/distributor-bins-model-dashboard.component';
import { DistributorIotDashboardComponent } from './distributor-iot-dashboard/distributor-iot-dashboard.component';
import { DistributorAdminDashboardComponent } from './distributor-admin-dashboard/distributor-admin-dashboard.component';
import { DeviceStatisticsComponent } from './device-statistics/device-statistics.component';
import { LineChartComponent } from './line-chart/line-chart.component';
import { PdfGeneratorComponent } from './pdf-generator/pdf-generator.component';
import { BarChartCollectionsComponent } from './bar-chart-collections/bar-chart-collections.component';
import { NgxCsvParserModule } from 'ngx-csv-parser';
import { ClientWorkOrderDashboardComponent } from './client-work-order-dashboard/client-work-order-dashboard.component';
import { ReportModalComponent } from './report-modal/report-modal.component';
import { LineChartBatComponent } from './line-chart-bat/line-chart-bat.component';
import { LineChartVccComponent } from './line-chart-vcc/line-chart-vcc.component';
import { DeviceModalGraphsComponent } from './device-modal-graphs/device-modal-graphs.component';
import { LineChartTechnicalComponent } from './line-chart-technical/line-chart-technical.component';
import { LineChartTempComponent } from './line-chart-temp/line-chart-temp.component';
import { DispatchDashboardComponent } from './dispatch-dashboard/dispatch-dashboard.component';
import { TruckService } from './service/truck.service';
import { OperatorCreateTruckComponent } from './operator-create-truck/operator-create-truck.component';
import { OperatorUpdateTruckComponent } from './operator-update-truck/operator-update-truck.component';
import { JocelynTestComponent } from './jocelyn-test/jocelyn-test.component';
import { DispatcherControlCenterComponent } from './dispatcher-control-center/dispatcher-control-center.component';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { AssignRouteFormModalComponent } from './assign-route-form-modal/assign-route-form-modal.component';
import { TruckModalComponent } from './truck-modal/truck-modal.component';
import { TruckModalService } from './service/truck-modal.service';
import { OperatorMapComponent } from './operator-map/operator-map.component';
import { DriverRoutesComponent } from './driver-routes/driver-routes.component';
import { TruckStepperComponent } from './truck-stepper/truck-stepper.component';
import { MatIconModule } from '@angular/material/icon';
import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { DispatchNetworkComponent } from './dispatch-network/dispatch-network.component';
import { DispatchWorkOrderComponent } from './dispatch-work-order/dispatch-work-order.component';
import { DispatchOverviewComponent } from './dispatch-overview/dispatch-overview.component';
//Microsoft Guard Configurations
const guardConfig: MsalGuardConfiguration = {
  interactionType: InteractionType.Popup,
  authRequest: {
    scopes: environment.saml.microsoft.scopes,
  },
  loginFailedRoute: environment.saml.microsoft.loginFailedRoute,
};

//Microsoft Interceptor Configurations
const msalInterceptorConfig: MsalInterceptorConfiguration = {
  interactionType: InteractionType.Popup,
  protectedResourceMap: new Map([
    ['https://graph.microsoft.com/v1.0/me', ['user.read']],
  ]),
};

//Microsoft SAML/SSO Login Configurations
const msalConfig = {
  auth: {
    clientId: environment.saml.microsoft.clientId,
    authority: environment.saml.microsoft.authority,
    redirectUri: environment.saml.microsoft.redirectUri,
    navigateToLoginRequestUrl:
      environment.saml.microsoft.navigateToLoginRequestUrl,
  },
  cache: {
    cacheLocation: environment.saml.microsoft.cacheLocation,
    storeAuthStateInCookie: environment.saml.microsoft.storeAuthStateInCookie,
  },
};

//Microsoft Instance
const msalInstance: IPublicClientApplication = new PublicClientApplication(
  msalConfig
);

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    UserEditComponent,
    HeaderComponent,
    FooterComponent,
    DashboardComponent,
    SideNavComponent,
    FilterPipe,
    IotComponent,
    ConfigsComponent,
    AdminMapComponent,
    DeviceModalComponent,
    ClientCreateComponent,
    ClientListComponent,
    ClientEditComponent,
    BinUsageComponent,
    BinListComponent,
    BinCreateComponent,
    AdminComponent,
    DeviceCreateComponent,
    BinModelListComponent,
    BinModelCreateComponent,
    BinUpdateComponent,
    BinModelUpdateComponent,
    BinModalComponent,
    AlertModalComponent,
    EntitiesComponent,
    DistributorsListComponent,
    TaxeTypeListComponent,
    TaxeTypeCreateComponent,
    TaxeTypeUpdateComponent,
    NotificationListComponent,
    DeviceModalConfigComponent,
    DeviceModalGraphsComponent,
    ErrorModalComponent,
    DistributorsCreateComponent,
    DistributorsEditComponent,
    UserListComponent,
    UserCreateClientComponent,
    UserCreateDistributorComponent,
    UserCreateMuirwoodComponent,
    OperatorListComponent,
    OperatorCreateComponent,
    OperatorUpdateComponent,
    UserUpdateClientComponent,
    UserUpdateMuirwoodComponent,
    UserUpdateDistributorComponent,
    WorkOrderCreateComponent,
    SystemMessageComponent,
    WorkOrderListComponent,
    UserCreateOperatorComponent,
    UserUpdateOperatorComponent,
    MicrosoftSignUpComponent,
    OperatorDashboardComponent,
    MuirwoodDashboardComponent,
    DistributorDashboardComponent,
    ClientDashboardComponent,
    EntitiesLocationListComponent,
    EntitiesLocationCreateComponent,
    EntitiesLocationUpdateComponent,
    PieChartComponent,
    BarChartComponent,
    BarChartAdminComponent,
    LoadingElementComponent,
    MapComponent,
    DistributorBinsModelDashboardComponent,
    DistributorIotDashboardComponent,
    DistributorAdminDashboardComponent,
    DeviceStatisticsComponent,
    LineChartComponent,
    LineChartBatComponent,
    LineChartVccComponent,
    LineChartTechnicalComponent,
    LineChartTempComponent,
    ClientThingListComponent,
    BinDashboardComponent,
    DistributorBinsDashboardComponent,
    PdfGeneratorComponent,
    BarChartCollectionsComponent,
    ClientBinDashboardComponent,
    DistributorWorkOrderDashboardComponent,
    PieChartSingleComponent,
    WorkOrderDashboardModalComponent,
    ClientUserListComponent,
    ClientWorkOrderDashboardComponent,
    ReportModalComponent,
    UserEditModalComponent,
    UserEditPreferenceComponent,
    RelationshipModalComponent,
    DistributorIotAssociationModalComponent,
    TruckListComponent,
    /* components of User type Operators */
    DriverDashboardComponent,
    DispatchDashboardComponent,
    OperatorAdminDashboardComponent,
    DriverActiveRouteComponent,
    OperatorCreateTruckComponent,
    OperatorUpdateTruckComponent,
    JocelynTestComponent,
    DispatcherControlCenterComponent,
    AssignRouteFormModalComponent,
    TruckModalComponent,
    OperatorMapComponent,
    DriverRoutesComponent,
    TruckStepperComponent,
    DriversComponent,
    DispatchNetworkComponent,
    DispatchWorkOrderComponent,
    DispatchOverviewComponent
  ],

  imports: [
    DragDropModule,
    CommonModule,
    BrowserModule,
    MatTableModule,
    HttpClientModule, // Import HttpClientModule for HttpClient
    BrowserAnimationsModule,
    GoogleMapsModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpBackend], // Specify HttpClient as a dependency
      },
    }),
    MsalModule.forRoot(msalInstance, guardConfig, msalInterceptorConfig),
    GridsterModule,
    NgxChartsModule,
    NgSelectModule,
    NgxCsvParserModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatFormFieldModule,
    MatStepperModule,
    MatButtonModule,
    MatInputModule,
    MatIconModule
  ],
  providers: [
    IotService,
    RoleService,
    CognitoService,
    TranslateService,
    ClientService,
    ValidationService,
    BinsService,
    FilterService,
    NotificationService,
    MsalService,
    TruckService,
    TruckModalService,

    {
      provide: STEPPER_GLOBAL_OPTIONS,
      useValue: { displayDefaultIndicatorType: false },
    },

    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true,
    },
    { provide: RouteReuseStrategy, useClass: CustomRouteReuseStrategy },
    provideAnimationsAsync(),
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}

// required for AOT compilation
export function HttpLoaderFactory(http: HttpBackend): TranslateHttpLoader {
  return new TranslateHttpLoader(new HttpClient(http), '/assets/translation/', '.json');
}


