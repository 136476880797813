import { environment } from './../environments/environment';
import { Component, ElementRef, ViewChild } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ReportsService } from '../service/reports.service';
import { ChartService } from '../service/chart.service';
import { PdfService } from '../service/pdf.service';

interface BinData {
  bin_id: string;
  bin_gps?: string;
  bin_location: string;
  thing_name: string;
  bin_usage: string;
  bin_address: string;
  legal_name: string;
  market_segment: string;
  threshold: number;
  bin_height: number;
  total_volume: number;
}

@Component({
  selector: 'app-pdf-generator',
  templateUrl: './pdf-generator.component.html',
  styleUrls: ['./pdf-generator.component.css'],
})
export class PdfGeneratorComponent {
  @ViewChild('usageCanvas') usageCanvas!: ElementRef;
  @ViewChild('marketCanvas') marketCanvas!: ElementRef;
  @ViewChild('countCanvas') countCanvas!: ElementRef;
  @ViewChild('tonnageCanvas') tonnageCanvas!: ElementRef;
  constructor(public reports: ReportsService,
              public charts: ChartService,
              public pdf: PdfService) {}

  async ngAfterViewInit() {
    this.pdf.usageCanvas = this.usageCanvas;
    this.pdf.marketCanvas = this.marketCanvas;
    this.pdf.countCanvas = this.countCanvas;
    this.pdf.tonnageCanvas = this.tonnageCanvas;
  }
}
