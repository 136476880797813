<div  [ngClass]="theme.getThemeClass()"  class="container-fluid bg-light main-dashboard" style="background-color: rgb(255, 255, 255); height: 100vh; margin-top: 5px;">

    <section [ngClass]="theme.getThemeClass()" class="dashboard-container row bg-light">
    <app-error-modal></app-error-modal>

    <!-- Section of different dashboard depend on user type -->
    <app-operator-dashboard *ngIf="cognitoService.userType === 'operator'"></app-operator-dashboard>
    <app-muirwood-dashboard *ngIf="cognitoService.userType === 'muirwood'"></app-muirwood-dashboard>
    <app-client-dashboard *ngIf="cognitoService.userType === 'client'"></app-client-dashboard>
    <app-distributor-dashboard *ngIf="cognitoService.userType === 'distributor'"></app-distributor-dashboard>
    </section>
</div>



