import { Injectable } from '@angular/core';

Injectable({
  providedIn: 'root'
})

// Constant used in tax type pages for select of tax type
export const TaxeTypeISOCode = [
    { "value" : "CA", "label" : "GST"},
    { "value" : "CA-QC", "label" : "QST"},
    { "value" : "CA-BC", "label" : "TVP"},
    { "value" : "CA-MB", "label" : "TVP"},
    { "value" : "CA-SK", "label" : "TVP"},
    { "value" : "CA-PE", "label" : "TVH"},
    { "value" : "CA-NB", "label" : "TVH"},
    { "value" : "CA-NS", "label" : "TVH"},
    { "value" : "CA-ON", "label" : "TVH"},
    { "value" : "CA-NT", "label" : "TVH"},
]
