export const MetaData = {

    desired: {
      config: {
        dst: { timestamp: 1695158984 },
        env: { timestamp: 1695158984 },
        voc: { timestamp: 1695158984 },
        img: { timestamp: 1695158984 },
        lfr: { timestamp: 1695158984 },
        gps: { timestamp: 1695158984 },
        gtc: { timestamp: 1695158984 },
        slp: { timestamp: 1695158984 },
        hit: { timestamp: 1695158984 },
        his: { timestamp: 1695158984 }
      }
    },
    reported: {
      dat: {
        fdv: { timestamp: 1695158984 },
        tmp: { timestamp: 1695158984 },
        sig: { timestamp: 1695158984 },
        vcc: { timestamp: 1695158984 },
        bat: { timestamp: 1695158984 },
        dst: { timestamp: 1695158984 },
        gps: [
          { timestamp: 1695158984 },
          { timestamp: 1695158984 }
        ],
        tm0: { timestamp: 1695158984 },
        nct: { timestamp: 1695158984 },
        img: [],
        pre: { timestamp: 1695158984 },
        tm2: { timestamp: 1695158984 },
        hum: { timestamp: 1695158984 },
        hib: { timestamp: 1695158984 }
      }
    }

};
