import { Injectable } from '@angular/core';

interface route{
  routeID:string,   //routeID
  name:string,      //route name
  date:string,      //route date
  driver:string,    //assigned driver
  stops:any[],      //list of stops
  distance:number   //route total distance in miles
  routeUrl:string   // Url to the route details
}

@Injectable({
  providedIn: 'root'
})
export class DriverService {
 selectedRoute:route = {
   routeID: '',
   name: '',
   date: '',
   driver: '',
   stops: [],
   distance: 0,
   routeUrl: ''
 };  //the selected route
isRouteSelected:boolean =false;
  constructor() { }


}
