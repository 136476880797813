
<div *ngIf="errorService.showErrorModal" class="error-modal">
    <header class="error-header">
      <img class="header-icon" [src]="errorHeaderIconPath" alt="Error Icon">
    </header>
    <section [ngClass]="theme.getThemeClass(true)"  class="error-content">
      <h2>{{ errorService.errorTitle }}</h2>
      <p>{{ errorService.errorMessage }}</p>
      <nav class="button-container">
        <button class="btn btn-danger" (click)="errorService.closeErrorModal()">{{ 'ok' | translate }}</button>
      </nav>
    </section>
</div>

