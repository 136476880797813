import { Component, OnInit, ElementRef, HostListener, Inject, forwardRef } from '@angular/core'; //AfterViewInit, HostListener,
import { LocalStorageService } from '../local-storage.service';
import { Router } from '@angular/router';
import { CognitoService } from '../service/cognito.service';
import { TranslateService } from '@ngx-translate/core';
import { Devices, IotService, MultipleDevices } from '../service/iot.service'; // DeviceConfig,
import { ModalService } from '../service/device-modal.service';
import { ThemeService } from '../service/theme.service';
import { ValidationService } from '../service/validation.service';
import { ClientService } from '../service/client.service';
import { WorkOrderService } from '../service/work-order.service';
import { ZoneService } from '../service/zone.service';
import { SystemMessageService } from '../service/system-message.service';
import { lastValueFrom } from 'rxjs';
import { environment } from '../environments/environment';
import { FilterService } from '../service/filter.service';
import { RoleService } from '../service/role.service';
import { BinUsage} from '../constants/bin-usage';

interface ZoneItem {
  strokeColor: string;
  zoneName: string;
  operator_id: string,
  operator_name: string,
  fillColor: string,
  patch: [],
  markers: [],
  active: "",
  waste_type: "",
}

@Component({
  selector: 'app-operator-map',
  templateUrl: './operator-map.component.html',
  styleUrl: './operator-map.component.css'
})
export class OperatorMapComponent {
  // Use for zone filter
  public zoneFilter: string ="";

  // map object
  public map: any = {};

  // polygon object
  public polygon: any;

  public firstCoord: any;

  // Array that stores user selection of multiple zones
  public selectedZones: any[] = [];

  // Array that stores multiple zones selected by the user
  public filteredArray: any[] = [];

  public operator_id: string =""; 

  public clientsData: any[] = [];
  public zonesData: any[] = [];
  public patchArray: any[] = [];
  public globalPath: any[] = [];
  public dots: any[] = [];
  public clientArray: any[] = [];
  public zonesArray2: any[] = [];
  public mapCenterDefault: any;

  public binUsage = BinUsage;
  //public wasteTypeSelected: string = "";

  // Retrieve current language selected from local storage
  languageStatus:string = this.localStorageService.getItem('language');

  public zoneName: string = "";

  public selectedColor: string = '#000000'; // Default value is black (#000000)
  public zoneBeingEdited: string = "";

  public drawingOn: boolean = false;
  // public clientSelected: boolean = false;
  public isDropdownOpen: boolean = false;
  public showCreateZoneModal: boolean = false;
  public showUpdateZoneModal: boolean = false;

  // when true show all zones whitin zonesArray
  public showAllZones: boolean = true;

  // Initialize isCustomComponent as true
  public isCustomComponent: boolean = true;

  public enable: boolean = false;

  // Initialize lastLocationgroup
  public lastLocationgroup: any;

  // the value will be updated according to the zone chosen for visualization by the user
  public chosenZone: number = 3;

  // Initialize an array for MultipleDevices
  public myDevicesArray: MultipleDevices[] = [];

  public zonesArray: ZoneItem[] = [];

  isSmallScreen: boolean = this.getScreenWidth() <= 859;

  public originalZonesArray: any[] = []

  public iWantToShowMarkers: boolean = false;
  public iWantToShowZones: boolean = false;

  public hasError: boolean = false;

  constructor(
    public iotService: IotService,
    @Inject(forwardRef(() => TranslateService)) private translate: TranslateService,
    private localStorageService: LocalStorageService,
    public cognitoService: CognitoService,
    public modal: ModalService,
    public theme: ThemeService,
    public validationService: ValidationService,
    public clientService: ClientService,
    private router: Router,
    public workOrderService: WorkOrderService,
    public zoneService: ZoneService,
    public systemMessage: SystemMessageService,
    private elementRef: ElementRef,
    private filter: FilterService,
    private roleService: RoleService
    )    {

      if (this.languageStatus == null){
        // Set the default language to French
        translate.use('fr');
      } else {
        // Set the default language to the user's selected language
        translate.use(this.languageStatus);
      }
    }

  async ngOnInit(): Promise<void> {
    this.systemMessage.buttonBlocked = false;
    // Check user permissions
    this.cognitoService.confirmValidUser();
    await this.cognitoService.getUserType();
    // Check the role of the user and check if he is allowed on the page
    await this.roleService.getRoles().then(async (result)=>{
      const userRoles = JSON.parse(JSON.stringify(result))
      // Check with user roles and type if he's alowed in that page, if not he'll be redirect to his dashboard
      await this.cognitoService.getCurrentRole([environment.users.role.administrator, environment.users.role.operations, environment.users.role.dispatch],
      [environment.users.superAdmin, environment.users.maintenance],
      true, userRoles);
      }
    );

    this.cognitoService.clearCognitoUserData();

    this.clearPastZoneData();

    // Fetch the list of zones asynchronously
    const zonesListPromise = await this.getZonesList(); // call aws api

    // Get information about the current user
    const currentUser = await this.getCurrentUser(); // call aws cognito


    const checkDeleteZoneFail = this.localStorageService.getItem('zoneDeletedFail');

    const checkDeleteZoneSucess = this.localStorageService.getItem('successDeleteOperatorZone');

    if (checkDeleteZoneSucess) {
      this.systemMessage.selectRibbon('success', 'zoneDeleted');
      this.localStorageService.removeItem('successDeleteOperatorZone');
    }

    if (checkDeleteZoneFail) {
      this.systemMessage.selectRibbon('alert-warning', 'zoneDeletedFail');
      this.localStorageService.removeItem('zoneDeletedFail');
    }

    // Display a system message if neither the create nor update zone modals are active
    if (!this.showCreateZoneModal && !this.showUpdateZoneModal && !this.hasError && !checkDeleteZoneSucess && !checkDeleteZoneFail) {
      this.systemMessage.selectRibbon('info', 'welcomeAdminMap');
    }
  }

  // Function used to disable save button
  disableButton() {
    this.systemMessage.buttonBlocked = true;
  }

  // Function used to made a search in the list
  search(){
    // Get the lowercase value of the filter text
    const value = (this.zoneFilter || '').toLowerCase();

    this.zonesArray = this.filter.search(value, this.zonesArray, this.originalZonesArray, 'zoneName');
  }

  // Function to toggle the zone status (enable/disable)
  toggleZone() {
    // Toggle the 'enable' flag and call the appropriate function
    this.enable ? this.enableZone() : this.disableZone();
  }

  // Function to toggle the dropdown's open/closed state
  dropdownAction() {
    // Invert the current state of the dropdown (open becomes closed, and vice versa)
    this.isDropdownOpen = !this.isDropdownOpen;
  }

  // Clear zone data from zone service
  clearPastZoneData() {
    const { zoneData } = this.zoneService;

    zoneData.owner_id = "";
    zoneData.zone_coordinates = "";
    zoneData.zone_name = "";
    zoneData.modified = "";
    zoneData.created = "";
    zoneData.created_by = "";
    zoneData.active = "";
    zoneData.zone_color = "";
    zoneData.waste_type = "";
  }

  // Function to create a Google Map
  // Takes a center object with latitude (lat) and longitude (lng) properties
  // Returns a Google Map instance
  createGoogleMap(center: { lat: number, lng: number }): google.maps.Map {
    try {
      let zoom = 4;

      if(center != this.mapCenterDefault) {
        zoom = 8;
      }

      this.hasError = false;
      // Try to create a new Google Map instance
      // If an error occurs, the catch block will be executed
      return new google.maps.Map(document.getElementById("mainMap") as HTMLElement, {
        zoom: zoom,
        center: center,
        mapId: 'DEVICES_MAP',
        disableDefaultUI: true,
      });

    } catch (error) {
      console.error('Map creation error:', error);
      this.systemMessage.selectRibbon('danger', 'loadingDataGeneralError');
      this.hasError = true;
      throw error;
    }
  }

  // Asynchronously fetch a list of things and device information from the IoT service
  async displayMarkers(map: google.maps.Map): Promise<void> {
    try {
      this.hasError = true;

      // Set a constant of all things and there shadow
      const [thingsList, deviceList] = await Promise.all([
          this.iotService.listThings(),
          this.iotService.getDeviceList()
      ]);

      // Set an array of all things shadow
      const deviceShadowsPromises = thingsList.map(thing => {
          const name = thing.thingName;
          return name !== undefined ? this.iotService.getDeviceShadow(name) : Promise.resolve(null);
      });

      const deviceShadows = await Promise.all(deviceShadowsPromises);

      deviceShadows.forEach((deviceShadow, i) => {
          if (!this.showCreateZoneModal && !this.showUpdateZoneModal && deviceShadow) {
              const gps = deviceShadow.state.reported.dat.gps;
              const gpsString: string = gps.toString();
              const [latNumber, lngNumber] = gpsString.split(",").map(Number);

              const marker = {
                  position: { lat: latNumber, lng: lngNumber },
                  map: map,
                  icon: "assets/bin.png",
                  device: thingsList[i],
                  gps: gps,
                  animation: google.maps.Animation.DROP
              };

              const newMarker = new google.maps.Marker(marker);

              newMarker.addListener("click", () => {
                  this.hasMultipleDevices(gps, deviceList);
                  map.setZoom(10);
                  map.setCenter(newMarker.getPosition() as google.maps.LatLng);
              });
            }
      });
    } catch (error) {
      this.systemMessage.selectRibbon('danger', 'loadingDataGeneralError');
      this.hasError = true;
      console.error("Error in displayMarkers:", error);
    }
  }

  // Initialize Google Maps
  async initMap(): Promise<void> {

    // Defines the initial center of the map
    this.mapCenterDefault = { lat: 58.52758318433685, lng: -93.68679680458172 }; // 58.52758318433685, -93.68679680458172
    // let center =

    // Create Google Map with the specified center
    let map = this.createGoogleMap(this.mapCenterDefault);


    if (this.iWantToShowMarkers) {
      // Display markers on the map
      await this.displayMarkers(map);
    }

    if (this.iWantToShowZones) {
      // Display predefined zones on the map
      this.displayZones(map);
    }

    if(this.showUpdateZoneModal) {
      this.enableUpdateDrawing(map);
    }

    // Check if the modal for creating a new zone is visible
    if (this.showCreateZoneModal) {
      // Enable/disable drawing on the map
      this.enableDrawing(map);
    }
  }

  // Function to show all zones
  showZones() {
    // Set the flag to indicate that all zones should be shown
    this.showAllZones = true;

    // Initialize the map (assuming initMap() is responsible for handling the map display)
    this.initMap();
  }

  // Function to hide all zones
  hideZones() {
    // Set the flag to indicate that all zones should be hidden
    this.showAllZones = false;

    // Initialize the map (assuming initMap() is responsible for handling the map display)
    this.initMap();
  }

  // Function used to display map zones polygon
  async displayZones(map: google.maps.Map): Promise<void> {
    // function that displays zones
    if (this.showAllZones) {
        // clear zone selection
        this.selectedZones = [];

        // get all zone names inside of this.zonesArray
        const zoneNames = this.zonesArray.map(item => item.zoneName);

        if (this.zoneBeingEdited !== "") {
          // Remove zoneBeingEdited from zoneNames
          const indexToRemove = zoneNames.indexOf(this.zoneBeingEdited);
          if (indexToRemove !== -1) {
            zoneNames.splice(indexToRemove, 1);
          }
        }

        // format zone names to be pushed on this.selectedZones
        const flattenedZoneNames = zoneNames.flat();

        // Add the zone names to the selectedZones array
        this.selectedZones.push(...flattenedZoneNames);

        // Remove duplicates (if necessary)
        this.selectedZones = [...new Set(this.selectedZones)];

        // prevents display of all zones on the map
        this.showAllZones = true;

        // prevents display one selection zone on the map
        this.chosenZone = 0;

        // filtering array zone Array all items containing zone value equivalent to user defined
        this.filteredArray = this.zonesArray.filter(item => this.selectedZones.includes(item.zoneName));

        // loop through zonesArray
        // Create an array of polygon base on the information of filteredArray
        const polygons = this.filteredArray.map((zone) => {
          const polygon = new google.maps.Polygon({
              paths: zone.patch,
              strokeColor: zone.strokeColor,
              strokeOpacity: 0.8,
              strokeWeight: 2,
              fillColor: zone.fillColor,
              fillOpacity: 0.35,
          });

          // Use this condition to allow the update of the drawing.
          if (this.drawingOn) {
              google.maps.event.addListener(polygon, 'click', function () {
                  // When the polygon is clicked, log a message indicating which zone was clicked.
                  polygon.setEditable(!polygon.getEditable());
              });
          }

          return polygon;
        });

        // Set the map for all polygons at once.
        polygons.forEach((polygon) => {
          polygon.setMap(map);
        });

    } else {
        // if there are no selections, display all zones
        this.showAllZones = false;
    }
  }

  // Function used to display map zones polygon when zones is updated
  async displayZonesWhenUpdate(map: google.maps.Map, mapName: string ): Promise<void> {
    // function that displays zones
    if (this.selectedZones.length > 0) {
        // clear zone selection
        this.selectedZones = [];

        // get all zone names inside of this.zonesArray
        const zoneNames = this.zonesArray.map(item => item.zoneName);

        if (mapName !== "") {
          // Remova zoneBeingEdited de zoneNames
          const indexToRemove = zoneNames.indexOf(mapName);
          if (indexToRemove !== -1) {
            zoneNames.splice(indexToRemove, 1);
          }
        }

        // format zone names to be pushed on this.selectedZones
        const flattenedZoneNames = zoneNames.flat();

        // Adicione os nomes de zona ao array selectedZones
        this.selectedZones.push(...flattenedZoneNames);

        // Remova duplicatas (se necessário)
        this.selectedZones = [...new Set(this.selectedZones)];
        // this.selectedZones.push(zoneNames)

        // prevents display of all zones on the map
        this.showAllZones = false;

        // prevents display one selection zone on the map
        this.chosenZone = 0;
        this.zonesArray.push(this.zonesArray2[0])

        // filtering array zone Array all items containing zone value equivalent to user defined
        this.filteredArray = this.zonesArray.filter(item => this.selectedZones.includes(item.zoneName));

        // Criar um array de polígonos com base nas informações do filteredArray
        const polygons = this.filteredArray.map((zone) => {
          const polygon = new google.maps.Polygon({
              paths: zone.patch,
              strokeColor: zone.strokeColor,
              strokeOpacity: 0.8,
              strokeWeight: 2,
              fillColor: zone.fillColor,
              fillOpacity: 0.35,
              map: map,
          });

          return polygon;
        });

        // Use esta condição para permitir a atualização do desenho
        if (this.drawingOn) {
          // Adicionar evento 'click' para todos os polígonos de uma vez
          polygons.forEach((polygon, index) => {
              google.maps.event.addListener(polygon, 'click', () => {
                  polygon.setEditable(!polygon.getEditable());
              });
          });
        }

        // Definir o mapa para todos os polígonos de uma vez
        polygons.forEach((polygon) => {
          polygon.setMap(map);
        });

    } else {
        // if there are no selections, display all zones
        this.showAllZones = true;
    }

    // displays the zone chosen by the user
    if (this.chosenZone != 0 && this.chosenZone > 0) {
        // prevents display of all zones on the map
        this.showAllZones = false;

        // takes path according to the zone chosen by the user
        let pathZone = this.zonesArray[this.chosenZone - 1].patch;

        // takes stroke color according to the zone chosen by the user
        let strokeColorZone = this.zonesArray[this.chosenZone - 1].strokeColor;

        // takes fill color according to the zone chosen by the user
        let fillColorZone = this.zonesArray[this.chosenZone - 1].fillColor;

        // loop through zonesArray
        const polygons = this.zonesArray.map((zone) => {
          return new google.maps.Polygon({
              paths: zone.patch,
              strokeColor: zone.fillColor,
              strokeOpacity: 0.8,
              strokeWeight: 2,
              fillColor: zone.fillColor,
              fillOpacity: 0.35,
          });
      });

      // Definir o mapa para todos os polígonos de uma vez
      polygons.forEach((polygon) => {
          polygon.setMap(map);
      });
    }
  }

  // Function that listen to a click on the map to draw a polygon
  enableDrawing(map: google.maps.Map): void {
    if (this.drawingOn) {
        google.maps.event.addListener(map, 'click', (event: any) => {
            // Get the coordinates of the click.
            const clickedLatLng = event.latLng;

            // Adds the coordinates to the list of points
            this.dots.push(clickedLatLng);

            // Displays the coordinates in the console
            const coordinatePoint = `{ lat: ${clickedLatLng.lat()}, lng: ${clickedLatLng.lng()} }`;
            this.patchArray.push(coordinatePoint);

            // If there are at least three points, form a polygon
            if (this.dots.length >= 3) {
                // Remove the previous polygon, if it exists.
                if (this.polygon) {
                    this.polygon.setMap(null);
                }

                // Creates a new polygon with the coordinates of the points
                this.polygon = new google.maps.Polygon({
                    paths: this.dots,
                    strokeColor: this.selectedColor,
                    strokeOpacity: 0.8,
                    strokeWeight: 2,
                    fillColor: this.selectedColor,
                    fillOpacity: 0.35,
                    editable: true,
                });

                // Add the polygon to the map
                this.polygon.setMap(map);

                // Add event listeners to the map to check for changes in the polygon.
                google.maps.event.addListener(this.polygon.getPath(), 'insert_at', () => {
                    this.getPolygonCoordinates();
                });

                google.maps.event.addListener(this.polygon.getPath(), 'set_at', () => {
                    this.getPolygonCoordinates();
                });
            }

            const parseLatLng = (str: string) => {
                const match = str.match(/\{ lat: (.*), lng: (.*) \}/);
                if (match) {
                    return { lat: parseFloat(match[1]), lng: parseFloat(match[2]) };
                }
                return null;
            };

            // Converting the array.
            this.globalPath = this.patchArray.map(parseLatLng).filter(Boolean);

        });
    }
  }

  // Function to enable updating of a drawing on the map
  enableUpdateDrawing(map: google.maps.Map): void {

    // Check for an existing polygon
    this.polygon = new google.maps.Polygon({
        paths: this.dots,
        strokeColor: this.zoneService.zoneData.zone_color,
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: this.zoneService.zoneData.zone_color,
        fillOpacity: 0.35,
        editable: true,
    });

    // Check if the polygon exists
    if (this.polygon) {
        // Add a click event listener to the map
        google.maps.event.addListener(map, 'click', (event: any) => {
            // Check if the click is inside the polygon
            if (google.maps.geometry.poly.containsLocation(event.latLng, this.polygon)) {
                // Allow editing when the polygon is clicked
                this.polygon.setEditable(true);
            } else {
                // If the click is outside the polygon, disable editing
                this.polygon.setEditable(false);
            }
        });
    }
  }

  // Method to calculate the center point of a set of coordinates
  calculateCenter(coordinates: any) {
    // Initialize variables to store the sum of latitudes and longitudes
    let sumLat = 0;
    let sumLng = 0;

    // Get the total number of points in the coordinates array
    let totalPoints = coordinates.length;

    // Iterate through each coordinate in the array
    for (const coord of coordinates) {
        // Sum up the latitudes and longitudes
        sumLat += coord.lat;
        sumLng += coord.lng;
    }

    // Calculate the average latitude and longitude to find the center point
    const centerLat = sumLat / totalPoints;
    const centerLng = sumLng / totalPoints;

    // Return the calculated center point as an object with 'lat' and 'lng' properties
    return { lat: centerLat, lng: centerLng };
  }

  // Asynchronous function to update a zone
  async update(zone: any) {
    
    // Get the translation of the confirmation message
    const confirmationMessage$ = this.translate.get('confirmationQuestionDisplayMarkers');
    const confirmationMessage = await lastValueFrom(confirmationMessage$);

    // Display a confirmation dialog to the user
    const displayMarkersConfirmed = window.confirm(confirmationMessage);

    if(displayMarkersConfirmed) {
      const centralPoint = this.calculateCenter(zone.patch)
      // Clear existing zone data
      this.zoneService.clearZoneData();

      // Set properties based on the input zone
      this.selectedColor = zone.fillColor;
      this.firstCoord = zone.patch;

      if(zone.active == "1") {
        this.enable = true;
      } else {
        this.enable = false;
      }

      // Store the zone being edited
      this.zoneBeingEdited = zone.zoneName;

      // Initialize variables for drawing polygon and storing polygon path
      let drawingPolygon: google.maps.Polygon | null = null;
      let polygonPath: google.maps.LatLng[] = [];

      // Define the initial center of the map
      let center = centralPoint;

      // Create a Google Map with the specified center
      let map = this.createGoogleMap(center);

      // Display markers on the map
      await this.displayMarkers(map);
      
      // Display all predefined zones on the map
      // this.displayZonesWhenUpdate(map, zone.zoneName);

      // Generate the polygon with SQL data
      this.polygon = new google.maps.Polygon({
        paths: zone.patch,
        strokeColor: zone.strokeColor,
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: zone.fillColor,
        fillOpacity: 0.35,
        editable: true,
      });

      // Add the polygon to the map
      this.polygon.setMap(map);

      // Add event listeners to the polygon to check for changes
      google.maps.event.addListener(this.polygon.getPath(), 'insert_at', () => {
        this.getPolygonCoordinates();
      });

      google.maps.event.addListener(this.polygon.getPath(), 'set_at', () => {
        this.getPolygonCoordinates();
      });

      // Assign properties from the 'zone' object to 'zoneData' again (duplicated?)
      this.zoneService.zoneData.owner_id = this.cognitoService.userInfos.operator_id
      this.zoneService.zoneData.zone_color = zone.strokeColor; // Note: Corrected duplicate assignment
      this.zoneService.zoneData.modified = '';
      this.zoneService.zoneData.zone_coordinates = zone.Coordinates; // Note: Corrected case of 'Coordinates'
      this.zoneService.zoneData.zone_id = zone.zoneId;
      this.zoneService.zoneData.zone_name = zone.zoneName;
      this.zoneService.zoneData.owner_name = this.zoneService.zoneData.owner_name;
      this.zoneService.zoneData.active = zone.active;
      this.zoneService.zoneData.waste_type = zone.waste_type;

      // Set flags and show instructions
      this.showUpdateZoneModal = true;
      this.systemMessage.selectRibbon('info', 'updateZoneInstructionPt1');
    }
  }

  // Function to get polygon coordinates
  getPolygonCoordinates() {
    // Initialize arrays to store coordinates
    this.globalPath = [];
    this.patchArray = [];

    // Get the coordinates of the polygon
    const coordinates: google.maps.LatLng[] = this.polygon.getPath().getArray();

    // Process each coordinate
    coordinates.forEach((coordinate: google.maps.LatLng) => {
        // Helper function to parse LatLng string
        const parseLatLng = (str: string) => {
            const match = str.match(/\{ lat: (.*), lng: (.*) \}/);
            if (match) {
                return { lat: parseFloat(match[1]), lng: parseFloat(match[2]) };
            }
            return null;
        };

        // Convert the coordinate to a LatLng string
        const coordinatePoint = `{ lat: ${coordinate.lat()}, lng: ${coordinate.lng()} }`;
        this.patchArray.push(coordinatePoint);

        // Convert the array of LatLng strings to an array of LatLng objects
        this.globalPath = this.patchArray.map(parseLatLng).filter(Boolean);
    });
  }

  /**
   * Fetches a device location group using the provided parameters.
   *
   * @param array - An array of parameters used to fetch the location group.
   * @param devices - A list of devices to filter based on the array.
   * @returns A Promise resolving to the location group of devices.
  */
  async hasMultipleDevices(array: any, devices: Devices[]){
    // Call the IOT service to fetch the device location group and return group
    // of devices with the same coordinates.
    let locationGroup = await this.iotService.getDeviceLocationGroup(array, devices);

    // Check if 'locationGroup' is undefined
    if (locationGroup == undefined) {
      // If it's undefined, assign it the value of 'this.lastLocationgroup'
      locationGroup = this.lastLocationgroup
    } else {
      // If it's defined, update 'this.lastLocationgroup' with its value
      this.lastLocationgroup = locationGroup;
    }

    // Loop through each element in the 'locationGroup' array
    for (let i = 0; i < locationGroup.length; i++) {

      // Get the device name from the current 'locationGroup' element
      const deviceName = locationGroup[i].thingName;

      // Create an object 'multipleDevicesWithSameCoordinates' with the current device
      const multipleDevicesWithSameCoordinates: MultipleDevices = {
        devices: [locationGroup[i]]
      }

      // Push 'multipleDevicesWithSameCoordinates' into 'myDevicesArray'
      this.myDevicesArray.push(multipleDevicesWithSameCoordinates);

      // Check the length of 'locationGroup'
      if (locationGroup.length == 1) {
        // If there's only one device, show a modal for that device
        this.modal.openModalDevice(locationGroup[0]);
      } else if (locationGroup.length > 1) {

        // If there are multiple devices, set some modal options and show a modal for device selection
        this.modal.showDeviceModal = false;
        this.modal.openModalMultipleDevices(this.myDevicesArray[i], deviceName)
        this.lastLocationgroup =  locationGroup;
      } else {
        // Handle the case of an incorrect 'locationGroup.length'
      }
    }
    return locationGroup;
  }

  // Function that delete a client zone
  async deleteZone() {
      // Get the translation of the confirmation message
      const confirmationMessage$ = this.translate.get('confirmationQuestionSaveZone');
      const confirmationMessage = await lastValueFrom(confirmationMessage$);

      // Display a confirmation dialog to the user
    const isConfirmed = window.confirm(confirmationMessage);

    if (isConfirmed) {

      try {
        this.zoneService.deleteOperatorZone().subscribe((result) => {
          this.localStorageService.addItem('successDeleteOperatorZone', 'successDeleteOperatorZone');
          window.location.reload();
        });
        // Disable drawing mode
        this.drawingOn = false;
        // Hide the update zone modal
        this.showUpdateZoneModal = false;
      } catch (error) {
        console.error(error)
        // Display an informational message related to updating a zone
        this.localStorageService.addItem('zoneDeletedFail', 'zoneDeletedFail');
      }
    }
  }

  // Function to initiate the creation of a new zone
  createNewZone() {
    // Set the flag to show the create zone modal
    this.showCreateZoneModal = true;

    // Display an informational message related to creating a zone
    this.systemMessage.selectRibbon('info', 'createZoneInstructionPt1');
  }

  //////////////// ************* Function used for the moment ************** ////////////////
  // Function to initiate the update of an existing zone
  // updateZone() {
  //   // Set the flag to show the update zone modal
  //   this.showUpdateZoneModal = true;

  //   // Display an informational message related to updating a zone
  //   this.systemMessage.selectRibbon('info', 'updateZoneInstructionPt1');
  // }

  // Function to close the modal and perform additional actions
  closeModal() {
    // Clear past zone data
    this.clearPastZoneData();

    // Clear validation error array
    this.validationService.validationErrorArray = [];

    // Hide both create and update zone modals
    this.showCreateZoneModal = false;
    this.showUpdateZoneModal = false;

    // Navigate to the '/admin-map' route
    this.router.navigate(['/admin-map']);

    // Disable drawing mode
    this.drawingOn = false;

    // Reinitialize the map
    this.initMap();
  }

  // Function to initiate the drawing mode
  startDrawing() {
    // Check validation for all inputs before starting drawing
    if (this.checkValidationAllInputs()) {
      // Enable drawing mode
      this.drawingOn = true;

      // Initialize the map
      this.initMap();

      // Display an informational message related to creating a zone (part 2)
      this.systemMessage.selectRibbon('info', 'createZoneInstructionPt2');
    }
  }

  // Function to transform a Date object to an 8-digit number representation (YYYYMMDD)
  transformDateTo8Digits(inputDate: Date): number {
    const year = inputDate.getFullYear(); // Extract year, month, and day components from the inputDate
    const month = inputDate.getMonth() + 1; // Months are zero-indexed (0 to 11)
    const day = inputDate.getDate();

    // Ensure adding leading zeros for single-digit months and days
    const formattedMonth = month < 10 ? `0${month}` : `${month}`;
    const formattedDay = day < 10 ? `0${day}` : `${day}`;

    // Concatenate year, month, and day as a string and convert to a number
    const result = parseInt(`${year}${formattedMonth}${formattedDay}`, 10);

    return result;
  }

  // Function to save the created zone
  async saveZone() {
    // Get the translation of the confirmation message
    const confirmationMessage$ = this.translate.get('confirmationQuestionSaveZone');
    const confirmationMessage = await lastValueFrom(confirmationMessage$);

    // Display a confirmation dialog to the user
    const isConfirmed = window.confirm(confirmationMessage);

    // If validation passes, proceed with saving the zone
    if (this.checkValidationAllInputs() && isConfirmed) {

      try {
        // Call the zone service to create the operator zone
        this.zoneService.createOperatorZone().subscribe((result) => {
          window.location.reload();
        });

        this.zoneService.zoneCreated = true;

        // Disable drawing mode
        this.drawingOn = false;

        // Hide the create zone modal
        this.showCreateZoneModal = false;

      } catch (error) {
        console.error(error)
        this.systemMessage.buttonBlocked = true;

        // Display an informational message related to updating a zone
        this.systemMessage.selectRibbon('alert-warning', 'errorSaveZone');
      }

    } else {
        this.systemMessage.buttonBlocked = false;
        // Log an error if there is a validation issue
        console.error("Validation Issue");
    }
  }

  // Function to save the updated zone
  async saveUpdateZone() {
    // Get the translation of the confirmation message
    const confirmationMessage$ = this.translate.get('confirmationQuestionSaveZone');
    const confirmationMessage = await lastValueFrom(confirmationMessage$);

    // Display a confirmation dialog to the user
    const isConfirmed = window.confirm(confirmationMessage);

    // If validation passes, proceed with updating the zone
    if (this.checkValidationAllInputsUpdate() && isConfirmed) {
        // Call the zone service to update the operator zone

        try {
          this.zoneService.updateOperatorZone().subscribe((result) => {
          sessionStorage.setItem('previous', 'user-List')
          window.location.reload();
          });

          this.zoneService.zoneUpdated = true;

          // Disable drawing mode
          this.drawingOn = false;

          // Hide the update zone modal
          this.showUpdateZoneModal = false;
        } catch (error) {
          console.error(error)
          this.systemMessage.buttonBlocked = true;

          // Display an informational message related to updating a zone
          this.systemMessage.selectRibbon('alert-warning', 'errorUpdateSaveZone');
        }
    } else {
      this.systemMessage.buttonBlocked = false;
      // Log an error if there is a validation issue
      console.error("Validation Issue");
    }
  }

  // Function to validate a zone name by checking in the zone name array if it already exist
  validateZoneName(zoneName: string) {
    // Extract an array of existing zone names from zonesArray
    const zoneNames = this.zonesArray.map(zone => zone.zoneName.toString());

    // Check if the provided zoneName is already in the array of existing zone names
    if (zoneNames.includes(zoneName.toString())) {
        // Invalid Zone: Zone name is already in use
        console.error(`Zone "${zoneName}" found in the array.`);
        this.systemMessage.buttonBlocked = true;

        // Display a danger message indicating that the zone name is invalid
        this.systemMessage.selectRibbon('danger', 'zoneNameInvalid');

        // Return false to indicate that the zone name is not valid
        return false;
    } else {

        // Return true to indicate that the zone name is valid
        return true;
    }
  }

  // Function to check validation for all inputs before proceeding
  checkValidationAllInputs() {

    // Get the current date
    const currentDate = new Date();

    // Transform the date into an 8-digit format
    const created = this.transformDateTo8Digits(currentDate);

    // Convert the globalPath coordinates to a string
    const stringCoordinates = JSON.stringify(this.globalPath);

    // Set the 'active' flag to "1"
    const active = "1";

    // Assign values to zoneData properties from various sources
    this.zoneService.zoneData.owner_id = this.cognitoService.userInfos.attributes['custom:operations_id'];
    this.zoneService.zoneData.zone_coordinates = stringCoordinates;
    this.zoneService.zoneData.zone_name = this.zoneName;
    this.zoneService.zoneData.created = created.toString();
    this.zoneService.zoneData.created_by = this.workOrderService.workOrderData.created_by;
    this.zoneService.zoneData.active = active;
    this.zoneService.zoneData.zone_color = this.selectedColor;

    // Validate the zone name
    this.validationService.validateZoneName(this.zoneService.zoneData.zone_name);
    this.validationService.validateWasteType(this.zoneService.zoneData.waste_type);
    // Check if all validations pass
    if (this.validationService.zoneNameValid && this.validationService.wasteTypeValid) {
        // If all validations pass, return true
        return true;
    } else {
        // Define a mapping of error codes to corresponding error messages
        const errorMappings: Record<string, string> = {
            'emptyZoneName': 'emptyZoneName',
            'noWasteType': 'noWasteType'
        };

        // Retrieve the first validation error from the array
        const validationError = this.validationService.validationErrorArray[0];

        // Check if the validation error code exists in the mapping
        if (errorMappings[validationError]) {
            this.systemMessage.buttonBlocked = true;

            // If so, display a danger ribbon message with the corresponding key
            this.systemMessage.selectRibbon('danger', errorMappings[validationError]);
        } else {
            // If the error code is not in the mapping, log a generic error message
            console.error('An error occurred, please contact support');
        }

        // Return false as there are validation errors
        return false;
    }
  }

  // Function to check validation for all inputs during an update
  checkValidationAllInputsUpdate() {
    // Get the current date
    const currentDate = new Date();

    // Transform the date to 8 digits
    const modified = this.transformDateTo8Digits(currentDate);

    // Convert the globalPath coordinates to a string
    let stringCoordinates = JSON.stringify(this.globalPath);

    // If the stringCoordinates length is 2, use the firstCoord instead
    if (stringCoordinates.length === 2) {
        stringCoordinates = JSON.stringify(this.firstCoord);
    }

    // Update the zone data with the coordinates, modified date, creator, and color
    this.zoneService.zoneData.zone_coordinates = stringCoordinates;
    this.zoneService.zoneData.modified = modified.toString();
    this.zoneService.zoneData.created_by = this.workOrderService.workOrderData.created_by;
    this.zoneService.zoneData.zone_color = this.selectedColor;

    // Validate the zone name using the validation service
    this.validationService.validateZoneName(this.zoneService.zoneData.zone_name);
    this.validationService.validateWasteType(this.zoneService.zoneData.waste_type);

    // Check if the username is valid
    if (this.validationService.zoneNameValid && this.validationService.wasteTypeValid) {
        return true;
    } else {
        // Define a mapping of error codes to corresponding error messages
        const errorMappings: Record<string, string> = {
            'emptyZoneName': 'emptyZoneName',
            'noWasteType': 'noWasteType'
        };

        // Retrieve the first validation error from the array
        const validationError = this.validationService.validationErrorArray[0];

        // Check if the validation error code exists in the mapping
        if (errorMappings[validationError]) {
            this.systemMessage.buttonBlocked = true;

            // If so, display a danger ribbon message with the corresponding key
            this.systemMessage.selectRibbon('danger', errorMappings[validationError]);
        } else {
            console.error('An error occurred, please contact support');
        }
        return false;
    }
  }

  // Function used to get current user sub
  async getCurrentUser() {
    try {
      // Retrieve the current user's sub (subject) using the Cognito service
      // and assign it to the created_by property in the workOrderData of workOrderService.
      this.workOrderService.workOrderData.created_by = await this.cognitoService.getCurrentUserSub();
    } catch (error) {
      // If there's an error while obtaining the current user's data,
      // log an error message to the console.
      console.error('Error retrieving current user data:', error);
    }
  }

  // Function triggered when a color is selected
  onColorSelected(event: any) {
    // Set the selected color based on the value of the selected color input
    this.selectedColor = event.target.value;
  }

  // Function to get all zones
  async getZonesList(): Promise<void> {
  try {
    // Call the zoneService to get zones data
    this.zoneService.getOperatorZones(this.cognitoService.userInfos.attributes['custom:operations_id']).subscribe(
      
      // Successful response callback
      (response: any) => {
        // Store the API response in the zonesData array
        this.zonesData = response;

        // Add console.log to check if this.zonesData is null or empty
        if (this.zonesData == null || this.zonesData.length === 0) {
          this.systemMessage.selectRibbon('danger', 'loadingDataGeneralError');
          this.hasError = true;

          return; // Exit the function or handle the case accordingly
        } else {
          this.hasError = false;

        }

        // Map the response data to a new format for easier use
        this.zonesArray2 = this.zonesData.map(item => {
          const zoneCoordinates = typeof item.zone_coordinates === 'string'
            ? JSON.parse(item.zone_coordinates)
            : item.zone_coordinates;

          return {
            fillColor: item.zone_color,
            patch: zoneCoordinates,
            strokeColor: item.zone_color,
            zoneId: item.zone_id,
            clientId: item.owner_id,
            zoneCoordinates: zoneCoordinates,
            zoneName: item.zone_name,
            modified: item.modified,
            active: item.active,
            waste_type: item.waste_type
          };
        });

        // Copy zonesArray2 to zonesArray
        this.zonesArray.push(...this.zonesArray2);
        this.originalZonesArray.push(...this.zonesArray2);

        // Initialize the map
        this.initMap();

        this.clientArray = this.clientArray.filter(client => client.client_name !== '');
      },
    );
  } catch (error) {
    // Handle and log any errors that occur during the process
    console.error('Error: ', error);
  }
}

  // Function to disable the zone asynchronously
  async disableZone() {
    // Set the type of operation in the zone data to "1"
    this.zoneService.zoneData.type_of_operation = "1";

    // Set the active status in the zone data to "0" to indicate it's inactive
    this.zoneService.zoneData.active = "0";

    // Disable the zone by setting the 'enable' flag to false
    this.enable = false;
  }

  // Function to enable the zone asynchronously
  async enableZone() {
    // Set the type of operation in the zone data to "1"
    this.zoneService.zoneData.type_of_operation = "1";

    // Set the active status in the zone data to "1"
    this.zoneService.zoneData.active = "1";

    // Enable the zone by setting the 'enable' flag to true
    this.enable = true;
  }

  // Event listener for click events on the document
  @HostListener('document:click', ['$event'])
  // Function to handle the click event
  handleClick(event: Event){
      // Set an instance of the dropdown button with the id 'buttonDropdown'
      const buttonDropdown = this.elementRef.nativeElement.querySelector('#buttonDropdown');
      // Set an instance of the dropdown content element with the id 'dropdownContent'
      const dropdownContent = this.elementRef.nativeElement.querySelector('#dropdownContent');

      // If the click event does not target the button or the dropdown content
      if(event.target !== buttonDropdown && event.target !== dropdownContent){
          // Set the variable to show/hide the dropdown content to false to hide it
          this.isDropdownOpen = false;
      }
  }

  // Event listener for window resize
  @HostListener('window:resize', ['$event'])
  onResize(event: any): void {
      // Check the screen width and update the isSmallScreen flag accordingly
      this.isSmallScreen = this.getScreenWidth() <= 859;
  }

  // Function to get the current screen width
  getScreenWidth(): number {
    // Return the inner width of the window as the screen width
    return window.innerWidth;
  }

  // Method to check if the screen meets the condition for applying a new layout
  shouldApplyNewLayout(): boolean {
    // Return true if either the showUpdateZoneModal or showCreateZoneModal is true
    // and the screen size is considered small (based on the isSmallScreen flag)
    return (this.showUpdateZoneModal || this.showCreateZoneModal) && this.isSmallScreen;
  }
}

