import { Component, OnInit, ViewChild } from '@angular/core';
import { LocalStorageService } from '../local-storage.service';
import { BinsService } from '../service/bins.service';
import { ValidationService } from '../service/validation.service';
import { formatDate } from '@angular/common';
import { Router } from '@angular/router';
import { LocalizationService } from '../service/localization.service';
import { CognitoService } from '../service/cognito.service';
import { ThemeService } from '../service/theme.service';
import { SystemMessageService } from '../service/system-message.service';
import { environment } from '../environments/environment';
import { RoleService } from '../service/role.service';
import { TranslateService } from '@ngx-translate/core';
import { firstValueFrom } from 'rxjs';
import { NgSelectComponent } from '@ng-select/ng-select';
import { MarketSegment } from '../constants/market-segments';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-bin-create',
  templateUrl: './bin-create.component.html',
  styleUrls: ['./bin-create.component.css', '../../global-elements.css']
})
export class BinCreateComponent implements OnInit {

  latitudeFormControl = new FormControl('', [Validators.required, Validators.pattern('^[-+]?([1-8]?\d(\.\d+)?|90(\.0+)?)$')]);

  binForm!: FormGroup; // Adicionamos "!" para indicar que a propriedade será inicializada no construtor
  Validators!: Validators


  @ViewChild('binSelect') binSelect!: NgSelectComponent;
  // Get current theme from localstorage
  themeStatus: string = this.localStorageService.getItem('theme');

  // Variable used for validate function  in addition of validation service;
  validateCreate: boolean = false;
  validateClientId: boolean = false;

  // Own variable that get the selected usage from bin-usage component
  public binUsageSelected: string = '';

  // Variables used by html to show/hide codes
  public showErrorMessageEmptyInput: boolean = false;
  private systemMessage: string = '';

  // Variables used for the client assiciate to the bin
  public associatedClientId: string = '';

  // Variable used to set number of bin we want to create at once
  public numberToCreate: string = '';
  public thing_name: string = '';
  public thingArray: any;
  public userRole: string = '';
  public latitude: number = 0;
  public longitude: number = 0;

  public MarketSegment = MarketSegment;

  constructor(private localStorageService: LocalStorageService,
              public bin: BinsService,
              public validationService: ValidationService,
              private route: Router,
              private localization: LocalizationService,
              public cognitoService: CognitoService,
              public theme: ThemeService,
              public systemMessageService: SystemMessageService,
              private roleService: RoleService,
              private translate: TranslateService,
              private formBuilder: FormBuilder,){

                // Inicializamos a propriedade formBuilder no construtor
              this.formBuilder = new FormBuilder();

              this.binForm = this.formBuilder.group({
                bin_gps_X: ['', Validators.pattern(/^-?([1-8]?\d(\.\d+)?|90(\.0+)?)$/)]
              });
            }

  async ngOnInit(): Promise<void> {

    // Retrieve the referrer from sessionStorage or set it to an empty string if not found
    this.localization.referer = sessionStorage.getItem('referer') || '';

    sessionStorage.removeItem("referer"); // Clear session storage variable referer

    this.systemMessageService.buttonBlocked = false;

    // preparing bin names list - this.bin.binsNameList - to be used on duplicated bin name validation
    await this.bin.generateBinNamesList();

    await this.cognitoService.confirmValidUser();
    await this.roleService.getRoles();

    this.cognitoService.getCurrentRole([environment.users.role.administrator, environment.users.role.distributor, environment.users.role.client], [environment.users.superAdmin, environment.users.supplier], true, this.roleService.roles);
    // Get the user type
    await this.cognitoService.getUserType();
    // Call a function that reset the bin_data to nothing so duplication of the list is avoid
    this.bin.initBinData();
    // Set back the bin model list
    await this.bin.getBinsModel();

    this.bin.isDeviceArray = false;

    // Check witch user type is the current user and get only infos he can associate bins
    switch(this.cognitoService.userType){
      // Remove the break because each one of hte two user type get the client list
      case 'muirwood':


      case 'distributor':
        // Distributor user will be abble to associate a bin to his own distributor ID or clients
        await this.cognitoService.getClientsInfos();
        this.userRole = 'distributor';
        break;
    }

    // Set the caller variable of localization service at bin-create so it will be used by this service for some use
    this.localization.caller = 'bin-create';
    this.localization.initAutocomplete(); // Initiate the autocomplete of the address input whit google.map

    // Function that will be automatically trigger and used whit bin-service when bin-usage component change the value of it's select
    this.bin.selectedTypeOfWaste$.subscribe(() => {
      this.binUsageSelected = this.bin.typeOfWaste; // Set the value of is own binusage variable to the one of bin service
    });

  }

  disableButton() {
    this.systemMessageService.buttonBlocked = true;
  }

  async onSubmit(){

    // Remove the comment to perform the automated test for latitude and longitude
    // this.runTestsLat();
    // this.runTestsLong();

    // Create a variable whit the current date
    const currentDate = new Date();
    const formattedDate = formatDate(currentDate, 'yyyyMMdd', 'en_US');

    // Set created and modified date to current date, modified is required event if it's not so the query won't break
    this.bin.bin_data.created = parseInt(formattedDate, 10);
    this.bin.bin_data.modified = parseInt(formattedDate, 10);

    // Set bin_location to the variable in localization service that google return
    this.bin.bin_data.bin_location = this.localization.autoCompletionReturnGpsLocation;
    this.bin.bin_data.bin_address = this.localization.autoCompletionReturnAddress;
    this.bin.bin_data.bin_postal_code = this.localization.autoCompletionReturnPostalCode;

    // This function is in addition of validation service to be sure that input are well filled and will set validateCreate at true if so
    this.checkValidation();

    if(this.validateCreate){

      this.createBin();
    } else {
      // If system message have nothing it put a generic message
      if(this.systemMessage === ''){
        this.systemMessageService.buttonBlocked = true;

        this.systemMessage = 'alert-danger-generic-message';
      }
      // Set the system message service to display the error to the user
      this.systemMessageService.selectRibbon('danger', this.systemMessage);
    }
  }

  // Function called when all validation are done and we can create bins
  async createBin(){

    // Do this part of the code if user want to create more then one bin
    if(parseInt(this.numberToCreate) > 1){
      const givenBinName = this.bin.bin_data.bin_name;
      for(let i = 0; i < parseInt(this.numberToCreate); i ++){

        // create bin name for multiples devices
        let binName = `${givenBinName}-${i + 1}`;
        this.bin.bin_data.bin_name = binName;

        // If there's device related to the bin model, set the thing_name in iotService then call the fonction to update the device association
        if(this.bin.isDeviceArray){
          this.bin.thing_name = this.bin.device_array[i].thing_name;
          this.bin.distributor_id = this.bin.selectedBinModel.distributor_id;

        }
        // Call the function that will create the bin
        await this.bin.createBin();
        this.bin.bin_data.bin_name = givenBinName;
      }
    }else{ // Do this code if user want to create only one bin

      if(this.thing_name !== ''){
        // If there's device related to the bin model, set the thing_name in iotService then call the fonction to update the device association
        this.bin.thing_name = this.thing_name;
        this.bin.distributor_id = this.bin.selectedBinModel.distributor_id;
      }
      // Call the funciton to create the bin
      await this.bin.createBin();
    }
    // Function that put session variable then return to the admin page
    this.processRefererForRedirection();
  }

  // Function to validate latitude
  validateLatitude(input: number | null): boolean {
    // Check if input is null
    if (input === null) {
        return true; // Return true for null input
    }

    // Convert the number to a string for validation
    const inputAsString = input.toString();

    // Regular expression for latitude validation
    const regexLat = /^-?(?:90(?:\.0{1,18})?|[0-8]?\d(?:\.\d{1,18})?)$/;

    // Test if the input matches the latitude regex
    return regexLat.test(inputAsString);
  }

  // Function to validate longitude
  validateLongitude(input: number | null): boolean {
    // Check if input is null
    if (input === null) {
        return true; // Return true for null input
    }

    // Convert the number to a string for validation
    const inputAsString = input.toString();

    // Regular expression to validate longitude
    const regexLong = /^-?(?:180(?:\.0{1,18})?|(?:1[0-7]?\d|0?\d?\d)(?:\.\d{1,18})?)$/;

    // Test if the input matches the longitude regex
    return regexLong.test(inputAsString);
  }


  // automated test for latitude
  runTestsLat() {
    const testCases: { latitude: number, expected: boolean }[] = [
        { latitude: 50.123456, expected: true },
        { latitude: -90.0, expected: true },
        { latitude: 100.123, expected: false },
        { latitude: 0, expected: true },
        { latitude: 45.678, expected: true },
        { latitude: 91.234, expected: false },
        { latitude: -91.234, expected: false },
        { latitude: 180.0, expected: false },
        { latitude: -180.0, expected: false },
    ];

    // Loop through each test case
    testCases.forEach((testCase, index) => {
        // Call the function to validate latitude
        const result = this.validateLatitude(testCase.latitude);


        // Print error message if result does not match expected
        if (result !== testCase.expected) {
            console.error(`Test case ${index + 1} failed!`);
        }
    });
  }

  // automated test for longitude
  runTestsLong() {
    const testCases: { longitude: number, expected: boolean }[] = [
      { longitude: 50.123456, expected: true },
      { longitude: -90.0, expected: true },
      { longitude: 100.123, expected: true },
      { longitude: 0, expected: true },
      { longitude: 45.678, expected: true },
      { longitude: 181.234, expected: false },
      { longitude: -181.234, expected: false },
      { longitude: 180.0, expected: true },
      { longitude: -180.0, expected: true },
    ];
    // Loop through each test case
    testCases.forEach((testCase, index) => {
        // Call the function to validate longitude
        const result = this.validateLongitude(testCase.longitude);
        // Print error message if result does not match expected
        if (result !== testCase.expected) {
            console.error(`Test case ${index + 1} failed!`);
        }
    });
  }

  // Function in addition of validation service that put the variable validateCreate at rue if all variables are properly implemented
  checkValidation(){

    const isValidLatitude = this.validateLatitude(this.latitude);
    const isValidLongitude = this.validateLongitude(this.longitude);

    if(this.latitude === 0 || this.longitude === 0) {
      this.bin.bin_data.bin_gps = '';
    } else {
      this.bin.bin_data.bin_gps = this.latitude + "," + this.longitude;
    }

    if(!isValidLatitude) {
      this.systemMessage = 'invalidLatitude';
      // this.systemMessageService.buttonBlocked = true;
    }

    if(!isValidLongitude) {
      this.systemMessage = 'invalidLongitude';
      // this.systemMessageService.buttonBlocked = true;
    }

    let notDuplicatedBinName = false;

    // Check if the trash bin name is not duplicated
    if(!this.isTrashBinNameDuplicated(this.bin.bin_data.bin_name)) {
      notDuplicatedBinName = true;
      // Check if all required variables are properly set and validation errors are empty
      if(isValidLatitude && isValidLongitude && notDuplicatedBinName && this.bin.bin_data.bin_name !== '' && this.bin.bin_data.bin_model_id !== '' && this.bin.bin_data.bin_usage !== ''  && parseInt(this.numberToCreate) > 0 &&
        (this.validationService.validationErrorArray[0] === '' || this.validationService.validationErrorArray[0] === undefined)){
          // Perform additional validations for client ID
          // Check if additional device-related checks are needed
          this.checkIfNeedDevice();
      } else {
        this.systemMessageService.buttonBlocked = true;

        if (!isValidLatitude) {
          this.systemMessage = 'invalidLatitude';
        } else if (!isValidLongitude) {
            this.systemMessage = 'invalidLongitude';
        } else {
            this.systemMessage = 'binCreateViewAlertEmptyInput';
        }

      }

    } else {
      this.systemMessageService.buttonBlocked = true;

      // Set system message if trash bin name is duplicated
      notDuplicatedBinName = false;
      this.systemMessage = 'binCreateViewAlertDuplicatedBinName';
    }

  }

  // Checks if the provided bin name already exists in the list of bin names.
  isTrashBinNameDuplicated(binName: string) {

    // Check if the list of bin names is not an array
    if (!Array.isArray(this.bin.binsNameList)) {
      console.error('The list of bin names is not an array.');
      return false;
    }

    // Checks if the bin_name already exists in the list
    return this.bin.binsNameList.includes(binName);
  }

  // Function called to check if the been model required a device and if so, if the device is selected
  checkIfNeedDevice(){
    // Set an instance of bin model from the bin_data.bin_model
    const binModel = this.bin.bin_model_array.find(binModel => binModel.bin_model_id === this.bin.bin_data.bin_model_id);

    // If the bin_model need a thing and there's not, will send a message to the user
    if(binModel?.with_thing && this.thing_name === ''){
      this.validateCreate = false;
      this.systemMessageService.buttonBlocked = true;

      this.systemMessage = 'deviceNeeded';
    }
    else{
      this.validateCreate = true;
    }
  }

  // Function called when user select a bin model number to associate to th bin
  async binModelChange(){
    this.bin.binModelOrClientChange();
  }

  // Function called when user change the threshold input and won't let him to put threshold under 40% or over 90%
  thresholdChange(){
    if(this.bin.bin_data.threshold < 40 || this.bin.bin_data.threshold > 90){
      this.systemMessageService.buttonBlocked = true;

      this.systemMessageService.selectRibbon('danger', 'thresholdBreakPoint');

      // Set the threshold to 80 by default if user is offset the threshold range
      this.bin.bin_data.threshold = 80;
    }
  }

  // Function called when user change the number
  async changeNumberToCreate(){
    if(this.bin.bin_data.bin_model_id !== ''){
      const bin_model = this.bin.bin_model_array.find(binModel => binModel.bin_model_id === this.bin.bin_data.bin_model_id);
      // Check if number user want to create is bigger of the available device number of this distributor
      if((parseInt(this.numberToCreate) > this.bin.numberOfThingsAvailable) && (bin_model?.with_thing === 1)){
        this.systemMessageService.buttonBlocked = true;

        this.systemMessageService.selectRibbon('danger', 'distributorHaveNoMoreDevice');
        // Set the input to the maximum number of device available
        this.numberToCreate = this.bin.numberOfThingsAvailable.toString();

        // If it's a muirwood user, it will ask if he want to go to iot component to get new device for the distributor
        if(this.cognitoService.userType === 'muirwood'){
          const result = window.confirm(await firstValueFrom(this.translate.get('distributorHaveNoMoreDevice')) + '. ' + await firstValueFrom(this.translate.get('beRedirectToIot')));
          if(result === true){
            this.route.navigate(['/iot']); // Return to iot component
          }
        }
      }
    }else{
      this.systemMessageService.selectRibbon('danger', 'selectBinModelFirst');
      this.numberToCreate = '0';
    }
  }

  // Function called when user change the device select
  selectedDeviceChange(){
    // Set bin_data thing name to the selected thing_name
    this.bin.bin_data.thing_name = this.thing_name;

    // Set the number to create at one
    if(this.thing_name !== ''){
      this.numberToCreate = '1';
    }else{
      this.numberToCreate = '0';
    }
  }

  // Functino called when user change the select of client
  async selectedClientChange(){
    if(this.bin.bin_data.bin_model_id !== ''){
      // If bin model select is not empty it will call his function to get all available device
      this.binModelChange();
    }
  }

  // Check the referring page and perform redirection accordingly
  processRefererForRedirection() {

    switch(true) {
      // If referred from distributor bins model dashboard, return to distributor bins model dashboard
      case this.localization.referer === 'distributor-bins-model-dashboard':
        this.localization.referer = '';
        this.returnToDistributorBinDashboard();
        break;
      // If no referring page and user role is distributor, return to distributor dashboard
      case this.localization.referer === '' && this.userRole === 'distributor':
        this.localization.referer = '';
        this.returnToDistributorDashboard();
        break;

      case this.localization.referer === 'bin-dashboard':
        this.localization.referer = '';
        this.returnToDistributorBinListDashboard();
        break;
      // If none of the above conditions met, use the previous redirect condition
      default:
        this.localization.referer = '';
        this.returnToAdmin();
        break;

    }

  }

  // Implemented by cancel button to return to the admin component
  returnToAdmin(){

    // Put a sessions store variable so admin component could know to return on bin-list
    sessionStorage.setItem("previous", "bin-list");
    sessionStorage.setItem("from", "bin-create"); // This variable is used for bin-list to show the proper success message on create or update
    this.route.navigate(['/admin']); // Return to admin component
  }

  // function called when a distributor user click on cancel button and return him to the bin model list
  returnToDistributorBinDashboard(){
    this.route.navigate(['/distributor-bins-model-dashboard']);
  }

  // Function called when a distributor need to be redirected to his dashboard
  returnToDistributorDashboard() {
    this.route.navigate(['/dashboard']);
  }

  // Function called when a distributor need to be redirected to his bin list
  returnToDistributorBinListDashboard() {
    this.route.navigate(['/bin-dashboard']);
  }

  // Function called when a client user click on cancel button and bring him back to bin list
  returnToClientBinDashboard(){
    this.route.navigate(['/client-bin-dashboard']);
  }

  // Function that avoid submitting the page when user press enter at the end of inputting address in address input
  onInputAddressKeydown(event: KeyboardEvent): void {
    if(event.key === 'Enter'){
      event.preventDefault(); // Don't submit the page
    }
  }
}
