import { ValidationService } from './../service/validation.service';
import { Component, OnInit, Renderer2, HostListener, Inject, forwardRef  } from '@angular/core'; // AfterViewInit,
import { LocalStorageService } from '../local-storage.service';
import { CognitoService } from '../service/cognito.service';
import { TranslateService } from '@ngx-translate/core';
import { ClientService } from '../service/client.service';
import { ThemeService } from '../service/theme.service';
import { FilterService } from '../service/filter.service';

@Component({
  selector: 'app-client-list',
  templateUrl: './client-list.component.html',
  styleUrls: ['./client-list.component.css']
})
export class ClientListComponent implements OnInit {

  // Retrieve current language selected from local storage
  languageStatus:string = this.localStorageService.getItem('language');

  // Variables for user filtering
  public mainContent: string = '';
  
  public userFilter: string = '';
  public filterBy: string = '';

  public clientArray: any[] = [];
  private originalClientArray: any[] = [];

  // Flags for UI state
  public filterOn: boolean = false;
  public loadingMoreData = false;

  // Value to manage the pagination
  private itemsPerPage = 5;
  private loadedItems = 0;

  // Array Fetch customer data
  public clientsData: any[] = [];

  constructor(
    @Inject(forwardRef(() => TranslateService)) private translate: TranslateService,
    private localStorageService: LocalStorageService,
    private renderer: Renderer2,
    private cognitoService: CognitoService,
    private clientService: ClientService,
    public validationService: ValidationService,
    public theme: ThemeService,
    private filter: FilterService
  ) {
    this.cognitoService.confirmValidUser();
    // Check if the user has selected a language in local storage
    //or use a default language
    if (this.languageStatus == null){
      // Set the default language to French
      translate.use('fr');
    } else {
      // Set the default language to the user's selected language
      translate.use(this.languageStatus);
    }

  }

  ngOnInit(): void {
    // Function to load initial user data and reset the loading state
    this.getClientList();
  }

  refreshList() {
    this.localStorageService.addItem('entitiesContent', 'clients')
    window.location.reload();
  }

  getClientList() {
    try {
      // Use the clientService to fetch client data from an API
      this.clientService.getClients().subscribe(
        // Successful response callback
        (response: any) => {
          this.clientsData = response; // Fill the array with customer data
          this.clientArray = this.clientsData.slice(0, this.itemsPerPage); // Load the first 10 items
          this.originalClientArray = this.clientsData.slice(0, this.itemsPerPage); // Load the first 10 items
          this.loadedItems = this.itemsPerPage;
        }
      );
    } catch (error) {
      console.error("Error: ", error);
    }
  }

  loadMoreItems() {
    // Slice the remaining items based on the loaded items and items per page
    const remainingItems = this.clientsData.slice(this.loadedItems, this.loadedItems + this.itemsPerPage);
    this.clientArray = this.clientArray.concat(remainingItems); // Add more items to the displayed list
    this.loadedItems += this.itemsPerPage; // Update the count of loaded items
  }

   // Change language based on user selection
  changeLanguage(language: string) {
    this.translate.use(language);
  }

  ngAfterViewInit(): void {
    // Call the function to detect if the user has reached the end of the page on a mobile device
    this.detectEndOfPageOnMobile();
  }

  // Function used to made a search in the list
  search(){
    // Check if there's a filterBy variable and set it to it's default value
    if(this.filterBy === ''){
      this.filterBy = 'legal_name';
    }

    this.clientArray = this.filter.search(this.userFilter, this.clientArray, this.originalClientArray, this.filterBy);
  }

  // Function to detect if the user has reached the end of the page on a mobile device
  detectEndOfPageOnMobile(): void {
    // Check if the user is using a mobile device
    if (this.isMobileDevice()) {
      // Listen for the 'touchmove' event to track scrolling on the document
      this.renderer.listen('document', 'touchmove', (event: TouchEvent) => {
        // Get the current scroll position, window height, and document height
        const scrollPosition = window.scrollY || document.documentElement.scrollTop || document.body.scrollTop || 0;
        const windowHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight || 0;
        const documentHeight = Math.max(
          document.body.scrollHeight,
          document.documentElement.scrollHeight,
          document.body.offsetHeight,
          document.documentElement.offsetHeight,
          document.documentElement.clientHeight
        );
        // Check if the user has scrolled to the end of the page and not currently loading more data
        if (scrollPosition + windowHeight >= documentHeight && !this.loadingMoreData) {
          this.loadMoreItems();
        }
      });
    }
  }

  // Function to determine if the current device is a mobile device
  isMobileDevice(): boolean {
    // Get the user agent string and convert it to lowercase
    const userAgent = navigator.userAgent.toLowerCase();

    // Check if the user agent matches any common mobile device keywords
    return /mobile|android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini/i.test(userAgent);
  }

  // Host listener to detect scroll events on the window
  @HostListener('window:scroll', []) onScroll(): void {
    // Get the height of the visible window area
    const windowHeight = 'innerHeight' in window ? window.innerHeight : document.documentElement.offsetHeight;

    // Get references to the document's body and html elements
    const body = document.body;
    const html = document.documentElement;

    // Calculate the maximum document height using various metrics
    const documentHeight = Math.max(body.scrollHeight, body.offsetHeight, html.clientHeight, html.scrollHeight, html.offsetHeight);

    // Get the current scroll position
    const scrollPosition = window.scrollY || document.documentElement.scrollTop || document.body.scrollTop || 0;

    // Check if the user has scrolled near the bottom of the page
    if (documentHeight - windowHeight - scrollPosition < 100 && !this.loadingMoreData && !this.filterOn) {
      this.loadMoreItems()
    }
  }

  // Takes a sequence of numbers as input and formats it as a CA phone number in the format "(XXX) XXX-XXXX".
  formatNumber(numberSequence: string | undefined | null): string {
    // If the input is undefined or null, return an empty string (or handle the case appropriately)
    if (numberSequence === undefined || numberSequence === null) {
        return ''; // or handle the case appropriately
    }

    // If the length of the cleaned number is 10, format it as (XXX) XXX-XXXX
    const justNumbers = numberSequence.replace(/\D/g, '');

    if (justNumbers.length === 10) {
        const parte1 = justNumbers.slice(0, 3);
        const parte2 = justNumbers.slice(3, 6);
        const parte3 = justNumbers.slice(6);
        return `(${parte1}) ${parte2}-${parte3}`;
    } else {
        // If the length is not 10, return the input as is
        return numberSequence;
    }
  }
}
