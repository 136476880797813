import { Injectable } from '@angular/core';
import { BinModel } from './bins.service';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BinModalService {

  // Variable used for UI show/hide
  public showModal: boolean = false;

  // Variable array of bin-model
  public binModel: any;

  // Variable to trigger function in other component
  private returnToBinList = new Subject<void>;
  returnToBinList$ = this.returnToBinList.asObservable();

  constructor(private route: Router) {

  }

  // Function caled when user want to open the modal
  openModal(binModel: BinModel){
    this.showModal = true; // Variable used to show/hide the modal
    this.binModel = binModel; // Array entity of the bin model pass from bin-model-list
  }

  // function that close the modal
  closeModal(){
    this.showModal = false; // Variable used to show/hide the modal
  }

  // Function that set session storage variables and return to bin list
  getBinNumber(){
    sessionStorage.setItem('bin_number', this.binModel.bin_model_number);
    // Put a sessions store variable so admin component could know to return on bin-list
    sessionStorage.setItem("previous", "bin-list");
    sessionStorage.setItem("from", "bin-model-list"); // Set this variable so bin-model-list will use it on success creation to put he good UI message
    this.route.navigate(['/admin']); // Return to admin component
    this.closeModal(); // Close the modal
    this.returnToBinList.next(); // That will trigger a function in bin-list
  }
}
