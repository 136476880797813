import { Injectable } from '@angular/core';
import { Observable, Subject, map, catchError } from 'rxjs';
import { Socket } from 'socket.io-client';
import { ThemeService } from './theme.service';
import { environment } from '../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { NotificationService } from './notification.service';

export interface Message {
  action: string;
  user: string;
  body: string;
  recepient: string[];
  type: string;
  title: string;
  timestamp: number;
}

@Injectable({
  providedIn: 'root',
})
export class WebSocketService {
  private socket: WebSocket | null = null;

  constructor(
    private http: HttpClient,
    public notificationService: NotificationService
  ) {}

  connectToWebsocket(userSub: string) {
    this.socket = new WebSocket('wss://ifm-devapi.mwstudio.io:8100');

    this.socket.onopen = () => {
      console.log('Connection established.');

      // Envoyer l'ID Cognito après la connexion
      const message = JSON.stringify({
        action: 'register',
        user: userSub,
        content: '',
        to_user: '',
      });
      this.socket?.send(message);
    };

    this.socket.onclose = (event) => {
      if (event.wasClean) {
        console.log('The connection is closed.');
      } else {
        console.log('Connection failure'); // for example, the server process is "killed"
      }
      console.log('Code: ' + event.code + ' reason: ' + event.reason);

      const message = JSON.stringify({
        action: 'unregister',
        user: userSub,
        content: '',
        to_user: '',
      });
      this.socket?.send(message);
    };

    this.socket.onmessage = async (event) => {
      await this.notificationService.getNotifications();
      console.log('Received data ' + event.data);
    };

    this.socket.onerror = (error: any) => {
      console.log('Error' + error.message);
    };
  }

  send(message: {
    action: string;
    user: string;
    body: string;
    recepient: string[];
    type: string;
    title: string;
    timestamp: number;
  }) {
    const jsonMessage = JSON.stringify(message);
    this.socket?.send(jsonMessage);
  }

  receiveMessage() {
    return new Promise<any[]>((resolve) => {
      this.receiveMessageLambda().subscribe((response) => {
        console.log(response);
      });
    });
  }

  receiveMessageLambda() {
    // Define the HTTP headers with content type
    const headers = new HttpHeaders({
      'Content-Type': 'application/json', // Adjust content type as needed
    });

    // Get the url for the lambda function getBinsModels
    return this.http.post(
      environment.api.stage + environment.api.route.testWebsocket,
      { headers: headers }
    );
  }
}
