import { Component, OnInit, ViewChild } from '@angular/core';
import { ThemeService } from '../service/theme.service';
import { DistributorsService } from '../service/distributors.service';
import { CognitoService } from '../service/cognito.service';
import { RoleService } from '../service/role.service';
import { formatDate } from '@angular/common';
import { WorkOrderDashboardModalService } from '../service/work-order-dashboard-modal.service';
import { SystemMessageService } from '../service/system-message.service';
import { environment } from '../environments/environment';
import { LocalStorageService } from '../local-storage.service';
import { FilterService } from '../service/filter.service';
import { WorkOrderService } from '../service/work-order.service';

@Component({
  selector: 'app-client-work-order-dashboard',
  templateUrl: './client-work-order-dashboard.component.html',
  styleUrls: ['./client-work-order-dashboard.component.css', '../../global-elements.css']
})
export class ClientWorkOrderDashboardComponent implements OnInit {
  @ViewChild(WorkOrderDashboardModalService) service: any;

  public ascDesc: number = 0;
  public sortBy: string = '';

  public workOrderArray: any[] = [];
  private originalWorkOrderArray: any[] = [];

  // variables for filter
  public filterBy: string = '';
  public userFilter: string = '';

  constructor(public theme: ThemeService,
              public distributors: DistributorsService,
              public cognitoService: CognitoService,
              public roleService: RoleService,
              public workOrderModalService: WorkOrderDashboardModalService,
              private systemMessageService: SystemMessageService,
              private localStorageService: LocalStorageService,
              private filter: FilterService,
              private workOrderService: WorkOrderService){

  }

  async ngOnInit() {
     // Call cognito to confirm valid user and get the user type of the current user
      await this.cognitoService.confirmValidUser();
     await this.cognitoService.getUserType();
     await this.roleService.getRoles();
    //Verifies that the current User is allowed to view this component
    this.cognitoService.getCurrentRole(
      [environment.users.role.administrator, environment.users.role.client],
      [environment.users.standardUser]
    ); //Role Check

    // Get the local storage message when user create a work order then come back to the list to send him if work order creation was successful or not
    const workOrderCreateMessage = this.localStorageService.getItem('workOrderCreate');
    if(workOrderCreateMessage){
      if(workOrderCreateMessage === 'success'){
        this.systemMessageService.selectRibbon('success', 'createOrderSuccess');
      }else if(workOrderCreateMessage === 'fail'){
        this.systemMessageService.selectRibbon('danger', 'createOrderError');
      }
      this.localStorageService.removeItem('workOrderCreate');
    }

    // Call role service to get the current user roles
    await this.roleService.getRoles();

    if(!this.workOrderService.workOrdersArray || this.workOrderService.workOrdersArray.length === 0){
      // Call the function to get all bin model association of the distributor
      await this.workOrderService.getAllEntityWorkOrders(this.cognitoService.clientId);
    }
    this.workOrderArray = this.workOrderService.workOrdersArray;
    this.originalWorkOrderArray = this.workOrderService.workOrdersArray;
  }

  // Function that return column template for the display grid of the list
  getColumnStyle(): any{
    const numberOfColumn = this.ajustNumberOfColumns(this.getScreenWidth());
    if(numberOfColumn){
      if(this.workOrderService.workOrdersArray.length >= numberOfColumn){
        const gridColumns = `repeat(${numberOfColumn}, minmax(0, 325px))`;
        return {
          'grid-template-columns': gridColumns
        };
      }else{
        return {
          'display': `flex`,
          'justify-content': `center`,
          'margin': '0 -10px'
        };
      }
    }
  }

  // Function used to made a search in the list
  search(){
    // Check if there's a filterBy variable and set it to it's default value
    if(this.filterBy === ''){
      this.filterBy = 'thing_name';
    }

    this.workOrderArray = this.filter.search(this.userFilter, this.workOrderArray, this.originalWorkOrderArray, this.filterBy);
  }

  // Funciton called when user sort the array
  onSortChange(caller: string){
    // Switch the value of ascending or descending
    if(caller === 'switch'){
      if(this.ascDesc === 0){
        this.ascDesc = 1;
      }else{
        this.ascDesc = 0;
      }
    }

    // Avoid sortBy to have no value
    if(this.sortBy === ''){
      this.sortBy = 'work_order_id';
    }

    // Function that sort the list
    this.workOrderArray.sort((a, b) => {
      switch(this.sortBy){
        case 'work_order_id':
          return this.ascDesc === 0 ? b.work_order_id.localeCompare(a.work_order_id) : a.work_order_id.localeCompare(b.work_order_id);

        case 'work_order_type':
          return this.ascDesc === 0 ? b.work_order_type.localeCompare(a.work_order_type) : a.work_order_type.localeCompare(b.work_order_type);
      }
    });
  }

  // Funciton called when user change work order status
  changeWorkOrderStatus(work_order_id: string, status: string){
    this.workOrderModalService.changeStatus(work_order_id, status);
  }

  // Function called when user change the deadline date for the work order
  changeWorkOrderDeadlineDate(work_order_id: string, deadlineDate: string){
    this.workOrderModalService.changeDeadline(work_order_id, deadlineDate);
  }

  // Function called when user change the description of the work order
  changeWorkOrderDescription(work_order_id: string, description: string){
    this.workOrderModalService.changeDescription(work_order_id, description);
  }

  // Function called when user add comments on the work order
  async changeWorkOrderComments(work_order_id: string, comments: string){
    // Get the user sub for inserting in work_order_comments table
    const userSub = await this.cognitoService.getCurrentUserSub();

    // call funciton in the modal service
    this.workOrderModalService.addComments(work_order_id, comments, userSub);
  }

  // Function called to get a date format 'dd/MM/yyyy'
  formatDate(date: number){
    return formatDate(date, 'dd/MM/yyyy', 'en-US');
  }

  // Function to get the current screen width
  getScreenWidth(): number {
    // Return the inner width of the window as the screen width
    return window.innerWidth;
  }

  // function called to adjust the number of column of the grid depbending of inner window width
  ajustNumberOfColumns(width: number): number | undefined{
    // Return the floor of the inner page width divided by 450 px as the width of the tile size
    return Math.floor((width / 350) * (90 / 100));
  }
}
