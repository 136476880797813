import { CommonModule } from '@angular/common';
import { Component, HostListener, OnInit } from '@angular/core';
import { ThemeService } from '../service/theme.service';
import { navigation } from '../constants/navigation';
import { DashboardService } from '../service/dashboard.service';
import { environment } from '../environments/environment';
import { ChartService } from '../service/chart.service';
import { CognitoService } from '../service/cognito.service';
import { lastValueFrom } from 'rxjs';
import { Router } from '@angular/router';
import { RoleService } from '../service/role.service';

@Component({
  selector: 'app-operator-dashboard',
  templateUrl: './operator-dashboard.component.html',
  styleUrl: './operator-dashboard.component.css'
})
export class OperatorDashboardComponent implements OnInit {
  userType:string='';   // to save user type
  roles: string[] = []; // to save operator roles

  public navigationItemsArray: any = [];
  constructor(
    public theme: ThemeService,
    public dashboard: DashboardService,
    public charts: ChartService,
    private cognitoService: CognitoService,
    public dashboardService: DashboardService,
    private router: Router,
    public users: CognitoService,
    public roleService: RoleService
){

}

async ngOnInit(){
    await this.cognitoService.confirmValidUser();
    await this.cognitoService.getUserType();
    this.roles = await this.roleService.getRoles();
    this.userType = this.cognitoService.userType;
    console.log(this.roles)
}

handleLinkClick(event: MouseEvent, route: string) {
  //set a key-value pair for navigation of user-create-client
  if (route === '/user-create-distributor') {
    sessionStorage.setItem('createDistributor','dashboard')
  }
  //navigate to proper target
  this.router.navigate([`/${route}`])
}

}
