import { Injectable } from '@angular/core';
import { NotificationTypes } from '../constants/notification-types';
import { environment } from '../environments/environment';
import { HttpClient } from '@angular/common/http';
import { map, catchError } from 'rxjs';

export interface Notification {
  notificationType: string;
  notificationTitle: string;
  notificationBody: string;
  timestamp: string;
  icon: string;
  displayed: boolean; // Added property to track displayed state
}

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  public notifications: Notification[] = [];
  public usersub: string = '';

  // Array object of types of notification sended to users
  //private notificationsArray: any[] = [];
  // private notificationsArray: any[]; = [
  //   {
  //     notificationType: "urgent",
  //     notificationTitle: "Repairs!",
  //     notificationBody: "This is an example of the notification description. We just need to be sure all this text is displayed.",
  //     timestamp: "1695158984"
  //   },
  //   {
  //     notificationType: "device",
  //     notificationTitle: "Device Offline!",
  //     notificationBody: "This is an example of the notification description. We just need to be sure all this text is displayed.",
  //     timestamp: "1695158984"
  //   },
  //   {
  //     notificationType: "billing",
  //     notificationTitle: "Invoice Ready!",
  //     notificationBody: "This is an example of the notification description. We just need to be sure all this text is displayed.",
  //     timestamp: "1695158984"
  //   },
  //   {
  //     notificationType: "work_order",
  //     notificationTitle: "Collection Route",
  //     notificationBody: "This is an example of the notification description. We just need to be sure all this text is displayed.",
  //     timestamp: "1695158984"
  //   }
  // ];

  constructor(private http: HttpClient) { }

  // Function that take notification array and put it in notification local variable
  getNotifications(): Promise<Notification[]> {
    return new Promise<any[]>((resolve) => {
      this.getNotificationByUsersubLambda().subscribe((response: any) => {
        // Put the array of Bins returned by the lambda function into an tmp array
        this.notifications = response;
        resolve(this.notifications);
      });
    });
  }

  private getTimestampDisplay(timestamp: string): string {
    // Implement logic to format timestamp in hours/days
    // You can use libraries like moment.js or Angular DatePipe for this
    // For simplicity, we'll just return the timestamp as is in this example
    return timestamp;
  }

  getNotificationByUsersubLambda(){
    // Get the url for the lambda function getBinsModels
    const url = environment.api.stage + environment.api.route.getNotificationByUsersub;
    // Will call the lambda function in getBinModel url then return a response
    return this.http.get(url).pipe(
      map((response) => {
        // return the response data
        return response;
      }),
      catchError((error) => {
        //console.error('API Error:', error);
        throw error(error); // Re-throw the error for the calling code to handle
      })
    );
  }
}

