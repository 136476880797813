import { environment } from './../environments/environment';

////// ********** Navigation constant is used for side navigation bars upon the App and on hamberger bars in tiles of distributor or client tiles ********* /////////
export const navigation = [
  {
    number: '1',
    name: 'sidenavMenu.HOME',
    mobile:'headerMobileMenuHome',
    icon: 'fas fa-home', // Icon representing configuration settings
    routeUrl: 'dashboard',// Route URL for the configurations page
    validation: false,
    userType: "",
    userRole: "",
    actions: []
  },
  {
    number: '2',
    name: 'sidenavMenu.CLIENTS',
    mobile: 'headerMobileMenuACCOUNTS',
    icon: 'fas fa-users', // Icon representing configuration settings
    routeUrl: 'entities', // Route URL for the configurations page
    validation: true,
    userType: environment.users.superAdmin,
    userRole: environment.users.role.administrator,
    actions: []
  },
  {
    number: '3',
    name: 'sidenavMenu.IOT',
    mobile: 'headerMobileMenuIOT',
    icon: 'fas fa-satellite-dish', // Icon representing configuration settings
    routeUrl: 'iot', // Route URL for the configurations page
    validation: true,
    userType: environment.users.superAdmin,
    userRole: environment.users.role.administrator,
    actions: []
  },
  {
    number: '6',
    name: 'sidenavMenu.ADMIN',
    mobile: 'headerMobileMenuADMIN',
    icon: 'fa-solid fa-users-gear', // Icon representing configuration settings
    routeUrl: 'admin', // Route URL for the configurations page
    validation: true,
    userType: environment.users.superAdmin,
    userRole: environment.users.role.administrator,
    actions: []
  },
  {
    number: '7',
    name: 'sidenavMenu.ZONES',
    mobile: 'headerMobileMenuZONES',
    icon: 'far fa-map', // Icon representing configuration settings
    routeUrl: 'zones', // Route URL for the configurations page
    validation: true,
    userType: environment.users.superAdmin,
    userRole: environment.users.role.administrator,
    actions: []
  },
  {
    number: '8',
    name: 'sidenavMenu.distributor.ADMIN',
    mobile: 'sidenavMenu.distributor.ADMIN',
    icon: 'fa-solid fa-users-gear', // Icon representing configuration settings
    routeUrl: 'distributor-admin-dashboard', // Route URL for the configurations page
    validation: true,
    userType: environment.users.supplier,
    userRole: environment.users.role.distributor,
    actions: [
      {
        title: 'graphs',
        options: [
          { name: 'pieChart', icon: 'icon-pie'},
          { name: 'lineChart', icon: 'icon-line'},
          { name: 'barChart', icon: 'icon-bar'}
        ],
        selectedOption: ''
      },
      {
        title: 'adminViewUserListNavBar',
        options: [
          {name: 'createUser', icon: 'fa fa-plus', route: '/user-create-distributor'},
          {name: 'userList', icon: 'fa fa-list', route: '/distributor-admin-dashboard'}
        ]
      }
    ]
  },
  {
    number: '9',
    name: 'sidenavMenu.distributor.IOT',
    mobile: 'sidenavMenu.distributor.IOT',
    icon: 'fa-solid fa-satellite-dish', // Icon representing configuration settings
    routeUrl: 'distributor-iot-dashboard', // Route URL for the configurations page
    validation: true,
    userType: environment.users.supplier,
    userRole: environment.users.role.distributor,
    actions: [
      {
        title: 'graphs',
        options: [
          { name: 'pieChart', icon: 'icon-pie'},
          { name: 'lineChart', icon: 'icon-line'},
          { name: 'barChart', icon: 'icon-bar'}
        ],
        selectedOption: ''
      },
      {
        title: 'iotNavButtonDevices',
        options: [
          {name: 'userlistTableHeaderDeviceList', icon: 'fa fa-trash', route: '/distributor-iot-dashboard'}
        ]
      }
    ]
  },
  {
    number: '10',
    name: 'sidenavMenu.distributor.BINS',
    mobile: 'sidenavMenu.distributor.BINS',
    icon: 'far fa-trash-alt', // Icon representing configuration settings
    routeUrl: 'distributor-bins-model-dashboard', // Route URL for the configurations page
    validation: true,
    userType: environment.users.supplier,
    userRole: environment.users.role.distributor,
    actions: [
      {
        title: 'graphs',
        options: [
          { name: 'pieChart', icon: 'icon-pie'},
          { name: 'lineChart', icon: 'icon-line'},
          { name: 'barChart', icon: 'icon-bar'}
        ],
      },
      {
        title: 'chartLabelBins',
        options: [
          {name: 'binCreateViewButtonCreateBin', icon: 'fa fa-plus', route: '/bin-create'},
          {name: 'binModelCreateBinModelCreate', icon: 'fa fa-plus', route: '/bin-model-create'},
          {name: 'binModelListViewBinModelList', icon: 'fa fa-list', route: '/distributor-bins-model-dashboard'}
        ]
      }
    ]
  },
  {
    number: '11',
    name: 'sidenavMenu.client.ADMIN',
    mobile: 'headerMobileMenuADMIN',
    icon: 'fas fa-users', // Icon representing configuration settings
    routeUrl: 'client-user-list', // Route URL for the configurations page
    validation: true,
    userType: environment.users.standardUser,
    userRole: environment.users.role.client,
    actions: [
      {
        title: 'graphs',
        options: [
          { name: 'pieChart', icon: 'icon-pie'},
          { name: 'lineChart', icon: 'icon-line'},
          { name: 'barChart', icon: 'icon-bar'}
        ],
        selectedOption: ''
      },
      {
        title: 'adminViewUserListNavBar',
        options: [
          {name: 'createUser', icon: 'fa fa-plus', route: '/user-create-client'},
          {name: 'userList', icon: 'fa fa-list', route: '/client-user-list'}
        ]
      }
    ]
  },
  {
    number: '12',
    name: 'sidenavMenu.client.IOT',
    mobile: 'headerMobileMenuIOT',
    icon: 'fa-solid fa-satellite-dish', //
    routeUrl: 'client-thing-list', // Route URL for the configurations page
    validation: true,
    userType: environment.users.standardUser,
    userRole: environment.users.role.client,
    actions: [
      {
        title: 'graphs',
        options: [
          { name: 'pieChart', icon: 'icon-pie'},
          { name: 'lineChart', icon: 'icon-line'},
          { name: 'barChart', icon: 'icon-bar'}
        ],
        selectedOption: ''
      },
      {
        title: 'iotNavButtonDevices',
        options: [
          {name: 'availableDevice', icon: 'icon-device', route: '/client-thing-list'}
        ]
      }
    ]
  },
  {
    number: '13',
    name: 'sidenavMenu.client.BINS',
    mobile: 'sidenavMenu.client.BINS',
    icon: 'far fa-trash-alt', //
    routeUrl: 'client-bin-dashboard', // Route URL for the configurations page
    validation: true,
    userType: environment.users.standardUser,
    userRole: environment.users.role.client,
    actions: [
      {
        title: 'graphs',
        options: [
          { name: 'pieChart', icon: 'icon-pie'},
          { name: 'lineChart', icon: 'icon-line'},
          { name: 'barChart', icon: 'icon-bar'}
        ],
      },
      {
        title: 'chartLabelBins',
        options: [
          {name: 'binModelListViewBinModelList', icon: 'fa fa-list', route: '/client-bin-dashboard'}
        ]
      }
    ]
  },
  {
    number: '14',
    name: 'sidenavMenu.distributor.WORKORDER',
    mobile: 'sidenavMenu.distributor.WORKORDER',
    icon: 'fa-solid fa-list-check', // Icon representing configuration settings
    routeUrl: 'distributor-work-order-dashboard', // Route URL for the configurations page
    validation: true,
    userType: environment.users.supplier,
    userRole: environment.users.role.distributor,
    actions: [
      {
        title: 'graphs',
        options: [
          { name: 'pieChart', icon: 'icon-pie'},
          { name: 'lineChart', icon: 'icon-line'},
          { name: 'barChart', icon: 'icon-bar'}
        ],
      },
      {
        title: 'chartLabelBins',
        options: [
          {name: 'createWorkOrder', icon: 'fa fa-plus', route: '/work-order-create'},
          {name: 'workOrderList', icon: 'fa fa-list', route: '/distributor-work-order-dashboard'}
        ]
      }
    ]
  },
  {
    number: '15',
    name: 'sidenavMenu.client.WORKORDER',
    mobile: 'sidenavMenu.client.WORKORDER',
    icon: 'fa-solid fa-list-check', // Icon representing configuration settings
    routeUrl: 'client-work-order-dashboard', // Route URL for the configurations page
    validation: true,
    userType: environment.users.standardUser,
    userRole: environment.users.role.client,
    actions: [
      {
        title: 'graphs',
        options: [
          { name: 'pieChart', icon: 'icon-pie'},
          { name: 'lineChart', icon: 'icon-line'},
          { name: 'barChart', icon: 'icon-bar'}
        ],
      },
      {
        title: 'chartLabelBins',
        options: [
          {name: 'createWorkOrder', icon: 'fa fa-plus', route: '/work-order-create'},
          {name: 'workOrderList', icon: 'fa fa-list', route: '/client-work-order-dashboard'}
        ]
      }
    ]
  },
  {
    number: '16',
    name: 'sidenavMenu.WORKORDER',
    mobile: 'headerMobileWorkOrder',
    icon: 'fa-solid fa-list-check', // Icon representing configuration settings
    routeUrl: 'work-order-list', // Route URL for the configurations page
    validation: true,
    userType: environment.users.superAdmin,
    userRole: environment.users.role.administrator,
    actions: []
  },
  //operator Driver navigation items
  {
    number: '17',
    name: 'sidenavMenu.MyRoutes',
    mobile: 'headerMobileMyRoutes',
    icon: 'fa-solid fa-route', // Icon representing a route
    routeUrl: 'driver-routes', // Route URL for the configurations page
    validation: true,
    userType: environment.users.maintenance,
    userRole: environment.users.role.driver,
    actions: []
  },
  {
    number: '18',
    name: 'sidenavMenu.MyTrucks',
    mobile: 'headerMobileMyTrucks',
    icon: 'fa-solid fa-truck', // Icon representing a route
    routeUrl: 'my-truck', // Route URL for the configurations page
    validation: true,
    userType: environment.users.maintenance,
    userRole: environment.users.role.driver,
    actions: []
  },
  {
    number: '19',
    name: 'sidenavMenu.MyWorkOrders',
    mobile: 'headerMobileMyWorkOrders',
    icon: 'fa-solid fa-truck-ramp-box', // Icon representing a route
    routeUrl: 'driver-work-orders', // Route URL for the configurations page
    validation: true,
    userType: environment.users.maintenance,
    userRole: environment.users.role.driver,
    actions: []
  },
  {
    number: '20',
    name: 'sidenavMenu.MyStats',
    mobile: 'headerMobileMyStats',
    icon: 'fa-solid fa-chart-simple', // Icon representing a route
    routeUrl: 'my-stats', // Route URL for the configurations page
    validation: true,
    userType: environment.users.maintenance,
    userRole: environment.users.role.driver,
    actions: []
  },
 //operator Dispatch navigation items
  {
    number: '21',
    name: 'sidenavMenu.Routing',
    mobile: 'headerMobileRouting',
    icon: 'fa-brands fa-hubspot', // Icon representing a route
    routeUrl: 'dispatcher-control-center', // Route URL for the configurations page
    validation: true,
    userType: environment.users.maintenance,
    userRole: environment.users.role.dispatch,
    actions: []
  },
  {
    number: '22',
    name: 'sidenavMenu.Drivers',
    mobile: 'headerMobileDrivers',
    icon: 'fa-solid fa-id-card', // Icon representing a route
    routeUrl: 'drivers', // Route URL for the configurations page
    validation: true,
    userType: environment.users.maintenance,
    userRole: environment.users.role.dispatch,
    actions: []
  },
  {
    number: '23',
    name: 'sidenavMenu.Trucks',
    mobile: 'headerMobileTrucks',
    icon: 'fa-solid fa-truck', // Icon representing a route
    routeUrl: 'truck-list', // Route URL for the configurations page
    validation: true,
    userType: environment.users.maintenance,
    userRole: environment.users.role.dispatch,
    actions: []
  },
  {
    number: '24',
    name: 'sidenavMenu.operator.WORKORDER',
    mobile: 'headerMobile.Operator.WORKORDER',
    icon: 'fa-solid fa-truck-ramp-box', // Icon representing a route
    routeUrl: 'dispatch-work-orders', // Route URL for the configurations page
    validation: true,
    userType: environment.users.maintenance,
    userRole: environment.users.role.dispatch,
    actions: []
  },
  {
    number: '25',
    name: 'sidenavMenu.Network',
    mobile: 'headerMobile.Network',
    icon: 'fa-solid fa-diagram-project', // Icon representing a route
    routeUrl: 'dispatch-network', // Route URL for the configurations page
    validation: true,
    userType: environment.users.maintenance,
    userRole: environment.users.role.dispatch,
    actions: []
  },
  {
    number: '26',
    name: 'sidenavMenu.feed',
    mobile: 'headerMobileFeed',
    icon: 'fa-solid fa-arrows-to-eye', // Icon representing a route
    routeUrl: 'overview', // Route URL for the configurations page
    validation: true,
    userType: environment.users.maintenance,
    userRole: environment.users.role.dispatch,
    actions: []
  },
]; 
