<app-system-message></app-system-message>
<!-- Container for the form with dynamic styling -->
<div [ngClass]="theme.getThemeClass()" class="container-fluid">

  <section [ngClass]="theme.getThemeClass()" class="card bg-light">
      <article class="card-body mx-auto article">
          <h4 class="card-title mt-0 text-center">{{ "createNewOperatorUser" | translate }}</h4>
          <form>
              <!-- CLIENT NAME SELECT INPUT -->
              <label class="label-spacer" for="clientName">{{ 'selectOperator' | translate }} <span class="requiredStar">*</span></label>
              <div class="input-group mb-3">
                  <span class="input-group-text" id="basic-addon1"><i class="fas fa-briefcase"></i></span>
                  <select
                      [(ngModel)]="this.cognitoService.cognitoUserData.custom_operator_id"
                      class="form-control"
                      name="client_id"
                      aria-label="Client Name"
                      aria-describedby="basic-addon1"
                      [ngClass]="{'error-border': this.validationService.validationErrorArray[0] === 'clientInputError'}">
                      <option value="" disabled selected>{{ 'selectOperator' | translate }}</option>
                      <option *ngFor="let operator of this.operatorArray" [value]="operator.operator_id">{{ operator.legal_name }}</option>
                  </select>
              </div>

              <!-- USERNAME INPUT -->
              <label class="label-spacer" for="username">{{ 'username' | translate }} <span class="requiredStar">*</span></label>
              <div class="input-group mb-3 firstInput">
                  <span class="input-group-text" id="basic-addon1"><i class="fas fa-user-tie"></i></span>
                  <input type="text"
                  [(ngModel)]="this.cognitoService.cognitoUserData.username"
                  class="form-control" name="username"
                  placeholder="{{ 'username' | translate }}"
                  aria-label="Username" aria-describedby="basic-addon1"
                  (blur)="this.validationService.validateUsername(this.cognitoService.cognitoUserData.username)"
                  [class.error-border]="this.validationService.validationErrorArray[0] == 'usernameInvalid' || this.validationService.validationErrorArray[0] == 'usernameNotUnique'">
              </div>

              <!-- GIVEN NAME INPUT -->
              <label class="label-spacer" for="givenName">{{ 'givenName' | translate }} <span class="requiredStar">*</span></label>
              <div class="input-group mb-3 firstInput">
                  <span class="input-group-text" id="basic-addon1"><i class="fas fa-address-card"></i></span>
                  <input type="text"
                  [(ngModel)]="this.cognitoService.cognitoUserData.given_name"
                  class="form-control" name="given_name"
                  placeholder="{{ 'givenName' | translate }}"
                  aria-label="Given Name" aria-describedby="basic-addon1"
                  (blur)="this.validationService.validateGivenName(this.cognitoService.cognitoUserData.given_name)"
                  [class.error-border]="this.validationService.validationErrorArray[0] == 'givenNameInvalid'">
              </div>

              <!-- FAMILY NAME INPUT -->
              <label class="label-spacer" for="familyName">{{ 'familyName' | translate }} <span class="requiredStar">*</span></label>
              <div class="input-group mb-3 firstInput">
                  <span class="input-group-text" id="basic-addon1"><i class="far fa-address-card"></i></span>
                  <input type="text"
                  [(ngModel)]="this.cognitoService.cognitoUserData.family_name"
                  class="form-control" name="family_name"
                  placeholder="{{ 'familyName' | translate }}"
                  aria-label="Family Name" aria-describedby="basic-addon1"
                  (blur)="this.validationService.validateFamilyName(this.cognitoService.cognitoUserData.family_name)"
                  [class.error-border]="this.validationService.validationErrorArray[0] == 'familyNameInvalid'">
              </div>

              <!-- EMAIL INPUT -->
              <label class="label-spacer" for="deviceClient">{{ 'email' | translate }} <span class="requiredStar">*</span></label>
              <div class="input-group mb-3">
                  <span class="input-group-text" id="basic-addon1"><i class="fa fa-envelope"></i></span>
                  <input type="text"
                  [(ngModel)]="this.cognitoService.cognitoUserData.email"
                  class="form-control" name="email"
                  placeholder="{{ 'email' | translate }}"
                  aria-label="Email" aria-describedby="basic-addon1"
                  (blur)="this.validationService.validateClientEmail(this.cognitoService.cognitoUserData.email)"
                  [class.error-border]="this.validationService.validationErrorArray[0] == 'clientEmailInvalid'">
              </div>

              <!-- PHONE NUMBER INPUT -->
              <label class="label-spacer" for="deviceClient">{{ 'phoneNumber' | translate }} <span class="requiredStar">*</span></label>
              <div class="input-group mb-3">
                  <span class="input-group-text" id="basic-addon1"><i class="fa fa-phone"></i></span>
                  <input type="text"
                  [(ngModel)]="this.cognitoService.cognitoUserData.phone_number"
                  class="form-control telefone-input" name="phone_number"
                  placeholder="(000) 000-0000" aria-label="Phone Number"
                  aria-describedby="basic-addon1"
                  (blur)="this.validationService.validatePhoneNumber(this.cognitoService.cognitoUserData.phone_number)"
                  [class.error-border]="this.validationService.validationErrorArray[0] == 'clientPhoneInvalid'">
              </div>

              <!-- ADDRESS INPUT -->
              <label class="label-spacer" for="deviceClient">{{ 'address' | translate }} <span class="requiredStar">*</span></label>
              <div class="input-group mb-3">
                  <span class="input-group-text" id="basic-addon1"><i class="fas fa-search-location"></i></span>
                  <input id="autocomplete"
                    [(ngModel)]="this.cognitoService.cognitoUserData.address" (keydown)="onInputAddressKeydown($event);"
                    type="text" class="form-control" name="address"
                    placeholder="{{ 'clientcreateFormInputFindAddress' | translate }}"
                    aria-label="Enter a place" aria-describedby="basic-addon1"
                    (blur)="this.validationService.validateAddress(this.cognitoService.cognitoUserData.address)"
                    [class.error-border]="this.validationService.validationErrorArray[0] == 'clientAddressInvalid'">
              </div>

              <!-- ROLES CHECKBOX INPUT -->
              <label class="toggle-container-label">{{ 'roles' | translate }} <span class="requiredStar">*</span></label>
              <div class="toggle-container">
                  <ng-container *ngFor="let role of RolesArray">
                      <div class="row" *ngIf="operatorRolesToBeDisplayed(role.label)">
                          <div class="form-group">
                              <label class="switch">
                                  <input #rolesSwitch type="checkbox" [value]="role.label" (click)="this.checkRolesRestriction(role.label)" [checked]="this.cognitoService.roleUserArray.includes(role.label)">
                                  <span class="slider round"></span>
                              </label>
                              <label>{{ role.label | translate }}</label>
                          </div>
                      </div>
                  </ng-container>
              </div>

              <!-- Submit button -->
              <nav class="form-group displayFlex">
                  <button (click)="returnToAdmin()" [ngClass]="theme.getThemeClass()" type="button" class="btn btn-primary btn-block btnCancel fixed-size">{{ 'clientcreateFormBtnCancel' | translate }}</button>
                  <button [disabled]="this.systemMessageService.buttonBlocked" [ngClass]="theme.getThemeClass()" (click)="createUser(); disableButton()" type="button" class="btn btn-primary btn-block btnCreate fixed-size">{{ 'clientcreateFormBtnCreateClient' | translate }}</button>
              </nav>
          </form>
      </article>
    </section>
</div>





