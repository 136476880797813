<!-- <div [ngClass]="theme.getThemeClass()" class="themeBg"></div> -->
<app-system-message></app-system-message>

<div [ngClass]="theme.getThemeClass()" class="container-fluid">
  <div class="page-header">
    <!-- For future development -->
    <!-- <button class="btn back" (click)="backToDispatchDashboard()"><i class="fa fa-arrow-left"></i></button> -->
    <h2>{{ "trukList" | translate }}</h2>
  </div>
  <!-- Section for search and sort -->
  <nav id="search-component" class="nav-sort-filter">
    <!-- input search bar -->
    <input
      #searchBox
      type="search"
      id="search-box"
      [(ngModel)]="truckFilter"
      (input)="this.search()"
      class="form-control rounded searchInput"
      placeholder="{{ 'userlistSearchInputPlaceHolder' | translate }}"
      aria-label="Search"
      aria-describedby="search-addon"
    />

    <!-- ng-select for types issue with transaltion -->
   <!--  <ng-select
      class="form-control ng-select sort-by-select"
      [(ngModel)]="this.filterBy"
      placeholder="{{ 'truckType' | translate }}"
      [ngClass]="{ 'has-value': this.filterBy }"
      *ngIf="this.filterBy === 'type'"
      >
      <ng-container *ngFor="let type of truckTypes">
        <ng-option value="type.value">{{ type.value | translate }}</ng-option>
      </ng-container>
    </ng-select> -->
    <!-- filter by section  -->
    <ng-select
      class="form-control ng-select sort-by-select"
      [(ngModel)]="this.filterBy"
      placeholder="{{ 'filterBy' | translate }}"
      [ngClass]="{ 'has-value': this.filterBy }"
    >
      <ng-option value="" disabled selected>{{
        "filterBy" | translate
      }}</ng-option>
      <ng-option value="brand">{{ "truckBrand" | translate }}</ng-option>
      <ng-option value="type">{{ "truckType" | translate }}</ng-option>
      <ng-option value="status">{{ "truckStatus" | translate }}</ng-option>
    </ng-select>
    <!-- sort by section -->
    <ng-select
      class="form-control ng-select sort-by-select"
      [(ngModel)]="this.sortBy"
      (change)="onSortChange('select')"
      placeholder="{{ 'sortBy' | translate }}"
      [ngClass]="{ 'has-value': this.sortBy }"
    >
      <ng-option value="" disabled selected>{{ "sortBy" | translate}}</ng-option>
      <ng-option value="size">{{ "truckSize" | translate}}</ng-option>
      <ng-option value="capacity">{{"truckCapacity" | translate }}</ng-option>
      <ng-option value="year">{{"truckYear" | translate }}</ng-option>
      <ng-option value="status">{{"truckYear" | translate }}</ng-option>
    </ng-select>
    <!-- filter radio button -->
    <div class="card-title mt-0 status">
      <label class="switch">
        <input
          type="checkbox"
          [ngModel]="this.ascDesc"
          (change)="onSortChange('switch')"
        />
        <span class="slider round">
          <span class="toggle-switch-label-off">{{ "asc" | translate }}</span>
          <span class="toggle-switch-label-on">{{ "desc" | translate }}</span>
        </span>
      </label>
    </div>
  </nav>
  <!-- create truck button -->
  <nav class="createBtns">
    <button
      [ngClass]="theme.getThemeClass()"
      class="btn btn-dropdown"
      (click)="toCreateTruck()"
    >
      {{ "createNewTruck" | translate }}
    </button>
  </nav>
  <!-- truck list container -->
  <div class="grid-container" [ngClass]="theme.getThemeClass()">
    <section
      [ngClass]="theme.getThemeClass()"
      class="gridster"
      [ngStyle]="getColumnStyle()"
    >
      <ng-container *ngFor="let truck of trucksArray; let i = index">
        <article class="gridster-item" [ngClass]="theme.getThemeClass()">
          <!-- Your tile content goes here -->
          <nav [ngClass]="theme.getThemeClass()" class="dashboard-tile">
            <!-- Truck unique identifiers with link to truck edit -->
            <header class="dashboard-tile-header">
              <a
                [ngClass]="{
                  'disabled-link': !this.roleService.roles.includes('_admin')
                }"
                (click)="toUpdateTruck(truck.truck_id)"
              >
                <h5 class="dashboard-tile-title">
                  {{ truck.nickname }}
                </h5>
              </a>
            </header>
            <!-- truck info -->
            <div class="tiles-container">
              <!-- truck brand and year -->
              <a>
                <h6>
                  {{ getTruckBrandKey(truck.brand) | translate }} - {{ truck.year }}
                </h6>
              </a>
              <!-- truck driver -->
              <div class="titles-items" style="cursor: pointer" (click)="this.truckModalService.openTruckModal(truck)">
                <span class="i"><i class="fa-solid fa-user"></i></span>
                <label class="item-label">{{ truck.username || 'none' | translate}}</label>
              </div>
              <!-- truck status -->
              <div class="titles-items">
                <span class="i"><i class="fa-solid fa-heart-pulse"></i></span>
                <label class="item-label">{{ getTruckStatusKey(truck.status) | translate }}</label>
              </div>
              <!-- truck type -->
              <div class="titles-items">
                <span class="i"><i class="fa-solid fa-truck-ramp-box"></i></span>
                <label class="item-label">{{ getTruckTypeKey(truck.type) | translate}}</label>
              </div>
              <!-- truck size -->
              <div class="titles-items">
                <span class="i"><i class="fa-solid fa-text-height"></i></span
                ><label class="item-label">{{ truck.size }} (m)</label>
              </div>
              <!-- truck capacity -->
              <div class="titles-items email">
                <span class="i"><i class="fa-solid fa-weight-scale"></i></span
                ><label class="item-label">{{ truck.capacity }} (L)</label>
              </div>
            </div>
          </nav>
        </article>
      </ng-container>
    </section>
  </div>

  <ng-template #noneApplicable>
    <label>N/A</label>
  </ng-template>
  <app-truck-modal *ngIf="this.truckModalService.showTruckModal"></app-truck-modal>
</div>
