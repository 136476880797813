<!-- Apply dynamic classes based on the theme using ngClass directive -->
<div [ngClass]="theme.getThemeClass()" class="container-fluid">
    <!-- Container with box styles and fade-in-out animation -->
    <div class="box fade-in-out" >
        <!-- Font Awesome spinner icon with custom styles -->
        <div class="fa-3x mb-2">
            <i class="fa-solid fa-spinner fa-spin-pulse fa-2xl color"></i>
        </div>
        <!-- Logo container with dynamic theme class and navigation link -->
        <div>
            <a [ngClass]="theme.getThemeClass()"
            class=" hide-too-small-screen navbar-brand px-1" href="javascript:void(0);" data-bs-toggle="dropdown">
                <!-- Logo image with custom styles -->
                <img class="logo" src="../assets/muirwood-studio.png">
            </a>
        </div>
    </div>
</div>






