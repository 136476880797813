<header [ngClass]="theme.getThemeClass()" *ngIf="!IsLoginPageActive()" class="navbar navbar-expand navbar-light bg-light px-2">
  <!-- Dropdown menu for smaller screens -->
  <div *ngIf="smallscreen">
    <li class="nav-item dropdown">
      <a class="nav-link dropdown-toggle" href="javascript:void(0);"  data-bs-toggle="dropdown">
        <i class="fa fa-bars"></i>
      </a>
      <ul [ngClass]="theme.getThemeClass()" class="ulDrop dropdown-menu dropdown-menu-light">
        <!-- Menu Items -->
        <ng-container *ngFor="let item of nav.list">
          <li>
            <a [ngClass]="theme.getThemeClass()" class="dropdown-item" href="{{item.routeUrl}}">
              <i class="textAlignCenter" [ngClass]="item.icon"></i>
              <span class="customMarginLeft1">
                {{ item.mobile | translate }}
              </span>
            </a>
          </li>
        </ng-container>
      </ul>
    </li>
  </div>
  <!-- Brand logo -->
  <nav class="hideee">
    <!--  for the link below. Functionality moved to the side-nav component (click)="SideNavToggle()"  -->
    <a [ngClass]="theme.getThemeClass()" class=" hide-too-small-screen navbar-brand px-1" href="javascript:void(0);" ><img *ngIf="!smallscreen" class="header-logo hides" src="../assets/muirwood-studio.png"></a>

    <ul [ngClass]="theme.getThemeClass()" class="ulDrop dropdown-menu dropdown-menu-light">
      <!-- Menu Items -->
      <ng-container *ngFor="let item of nav.list">
        <li>
          <a [ngClass]="theme.getThemeClass()" class="dropdown-item" href="{{item.routeUrl}}">
            <i class="textAlignCenter" [ngClass]="item.icon"></i>
            <span class="customMarginLeft1">
              {{ item.mobile| translate }}
            </span>
          </a>
        </li>
      </ng-container>
    </ul>

  </nav>


  <!-- Navbar items -->
  <nav   class="navbar-collapse">
    <ul  class="navbar-nav me-auto">
      <!-- Navbar items go here -->
    </ul>
  </nav>

  <!-- Notifications button -->
  <button [ngClass]="theme.getThemeClass()" type="button" class="btn btn-outline-dark position-relative customNotificationBtn1" (click)="clearNotifications(); showNotificationsDropdown()">
    <i  class="far fa-bell font20"></i>
    <span class="badge position-absolute translate-middle rounded-pill badge-danger" *ngIf="unreadNotifications > 0" >
       {{unreadNotifications}}
    </span>
  </button>

  <!-- User profile dropdown -->
  <div class="profile-logo dropstart">
    <button class="btn btn-outline-gray customUserProfileBtn1" data-bs-toggle="dropdown">
      <a [ngClass]="theme.getThemeClass()" href="user-edit" class="dropdown-item textAlignCenter customPaddingTop">{{userName}}</a>
    </button>
    <ul [ngClass]="theme.getThemeClass()" class="dropdown-menu dropdown-menu-light customUserProfileBtn2">
      <!-- Dropdown menu items -->
      <li>
        <a [ngClass]="theme.getThemeClass()" href="user-edit" class="dropdown-item verticalAlignMiddle">
          {{ 'headerUserMenuMyAccount' | translate }}
        </a></li>
      <li>
        <a [ngClass]="theme.getThemeClass()" href="user-edit-preference" class="dropdown-item verticalAlignMiddle" >
          {{ 'deviceAlert' | translate }}
        </a>
      </li>
      <li (click)="$event.stopPropagation()">
        <a [ngClass]="theme.getThemeClass()" href="javascript:void(0);" class="dropdown-item verticalAlignMiddle">
          {{ 'headerUserMenuTheme' | translate }}
        <label class="switch verticalAlignMiddle mb-2">
          <input type="checkbox" id="toggleDarkMode()" [checked]="theme.isSwitchOn" (change)="toggleDarkMode($event)">
          <span class="slider"></span>
        </label></a>
      </li>
      <li>
        <a [ngClass]="theme.getThemeClass()" href="login" class="dropdown-item verticalAlignMiddle" (click)="clearStorage()">
          {{ 'headerUserMenuLogOut' | translate }}
        </a>
      </li>
    </ul>
  </div>
</header>
<!-- Notification list component -->
<app-notification-list *ngIf="this.notifications" class="appDeviceModal"></app-notification-list>
