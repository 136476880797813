import { Component, OnInit, Inject, forwardRef } from '@angular/core';
import { RoleService } from '../service/role.service';
import { CognitoService } from '../service/cognito.service';
import { TranslateService } from '@ngx-translate/core';
import { LocalStorageService } from '../local-storage.service';
import { ClientService } from '../service/client.service';
import { ValidationService } from '../service/validation.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ThemeService } from '../service/theme.service';
import { NavigationService } from '../service/navigation.service';
import { SystemMessageService } from '../service/system-message.service';
import { Truck, TruckService } from '../service/truck.service';
import { TruckBrands } from '../constants/truck-brands';
import { TruckTypes } from '../constants/truck-types';
import { TruckStatus } from '../constants/truck-status';
import { Operator, OperatorService } from '../service/operator.service';
import { environment } from '../environments/environment';

@Component({
  selector: 'app-operator-update-truck',
  templateUrl: './operator-update-truck.component.html',
  styleUrl: './operator-update-truck.component.css'
})

export class OperatorUpdateTruckComponent implements OnInit {
  truckID:string= '';// the ID of the truck
  truck: Truck[] = [];
  TruckBrands = TruckBrands; //list of truck brands not to be confused with truckBrand (singular)
  TruckTypes = TruckTypes; //list of truck types not to be confused with truckType (singular)
  TruckStatus = TruckStatus;//list of truck status not to be confused with truckStatus (singular)
  maxTruckYear = new Date().getFullYear() + 2;// Maximum year number for truck input
  operatorArray: Operator[] = [];

  constructor(private localStorageService: LocalStorageService,
    @Inject(forwardRef(() => TranslateService)) private translate: TranslateService,
    public TruckService:TruckService,
    public cognitoService: CognitoService,
    public roleService: RoleService,
    public clientService: ClientService,
    public validationService: ValidationService,
    private router: Router,
    public theme: ThemeService,
    public navigationService: NavigationService,
    private route: ActivatedRoute,
    public systemMessageService: SystemMessageService,
    public truckService: TruckService,
    private operatorService: OperatorService){

    this.route.queryParams.subscribe(params => {
      this.truckID = params['truckID'];
    });
    this.systemMessageService.buttonBlocked = false;
  }

  async ngOnInit(){
    // Get user type, roles, confir that user is valid in cognito
    await this.cognitoService.getUserType();
    await this.cognitoService.confirmValidUser();
    await this.roleService.getRoles();

    // Check with user roles and type if he's alowed in that page, if not he'll be redirect to his dashboard
    await this.cognitoService.getCurrentRole([environment.users.role.administrator, environment.users.role.operations],
                                              [environment.users.superAdmin, environment.users.maintenance],
                                              true, this.roleService.roles);

    await this.TruckService.getTruckByTruckId(this.truckID);
    this.operatorArray = await this.operatorService.getOperators();
  }

  checkYear(event: any): void {
    const selectedYear = event.target.value;

    // Perform validation for truck year
    if (selectedYear < 1900 || selectedYear > this.maxTruckYear) {
      switch(true){
        case selectedYear < 1900:
           this.truckService.truck.year = 1900
           break;
        case selectedYear > this.maxTruckYear:
          this.truckService.truck.year = this.maxTruckYear
           break;
      }
    }
  }
   /**
   * Function that takes you to previous page
   */
   toPreviousPage(){
    const from = sessionStorage.getItem('updateTruck') //get route to previous page (truck list componenent)
    if(from){
      //navigate back to truck-list if route exist
      this.router.navigate(['/truck-list'])
    }else{
      //navigate to dashboard
      this.router.navigate(['/admin']);
    }
    sessionStorage.removeItem('updateTruck')
  }
    /**
   * Function that disable the create truck button after first click
   */
    disableButton(){
      this.systemMessageService.buttonBlocked = true;
    }
    updateTruck(){
      this.checkInputs()
      if(
        this.validationService.truckNicknameValid   &&
        this.validationService.truckBrandValid &&
        this.validationService.truckTypeValid  &&
        this.validationService.truckSizeValid  &&
        this.validationService.truckCapacityValid &&
        this.validationService.truckYearValid &&
        this.validationService.truckStatusValid
      ){
        try {
          this.truckService.updateTruck();
          this.toPreviousPage();
        } catch (error) {

        }
      }
    }

    //function that triggers input validation
  async checkInputs(){
    this.validationService.validateTruckBrand(this.truckService.truck.brand)
    this.validationService.validateTruckNickname(this.truckService.truck.nickname);
    this.validationService.validateTruckType(this.truckService.truck.type)
    this.validationService.validateTruckSize(this.truckService.truck.size)
    this.validationService.validateTruckCapacity(this.truckService.truck.capacity)
    this.validationService.validateTruckYear(this.truckService.truck.year)
    this.validationService.validateTruckStatus(this.truckService.truck.status)

     // Define a mapping of error codes to corresponding error messages
     const errorMappings: Record<string, string> = {
      //'createTruckVinInputError': 'invalidVIN',
      'createTruckNicknameInputError': 'invalidNickname',
      'createTruckBrandInputError': 'invalidBrand',
      'createTruckTypeInputError': 'invalidType',
      'createTruckSizeInputError': 'invalidSize',
      'createTruckCapacityInputError': 'invalidCapacity',
      'createTruckYearInputError': 'invalidYear',
      'createTruckStatusInputError': 'invalidStatus',
    };

    // Retrieve the first validation error from the array
    const validationError = this.validationService.validationErrorArray[0];

    // Check if the validation error code exists in the mapping
    if (errorMappings[validationError]) {
      this.systemMessageService.buttonBlocked = true;

      // If so, display a danger ribbon message with the corresponding key
      this.systemMessageService.selectRibbon('danger', errorMappings[validationError]);
    }
  }
}
