import { Component, OnInit, Renderer2 } from '@angular/core';
import { ThemeService } from '../service/theme.service';
import { ClientService } from '../service/client.service';
import { CognitoService } from '../service/cognito.service';
import { RoleService } from '../service/role.service';
import { BinUsage } from '../constants/bin-usage';
import { MarketSegment } from '../constants/market-segments';
import { environment } from '../environments/environment';
import { SystemMessageService } from '../service/system-message.service';
import { FilterService } from '../service/filter.service';

@Component({
  selector: 'app-client-bin-dashboard',
  templateUrl: './client-bin-dashboard.component.html',
  styleUrls: ['./client-bin-dashboard.component.css', '../../global-elements.css']
})
export class ClientBinDashboardComponent implements OnInit {
  public clientBinArray: any[] = [];
  private originalClientBinArray: any[] = [];
  private array: any;

  public userFilter: string = '';
  public filterBy: string = '';
  public sortBy: string = '';
  public ascDesc: number = 0;

  public isPlaceholderVisible: boolean = true;

  constructor(public theme: ThemeService,
              public clients: ClientService,
              public cognitoService: CognitoService,
              public systemMessageService: SystemMessageService,
              private renderer: Renderer2,
              private roleService: RoleService,
              private filter: FilterService){

  }

  async ngOnInit() {
    await this.cognitoService.confirmValidUser();
    await this.cognitoService.getUserType();
    // Call role service to get the current user roles
    await this.roleService.getRoles();

    // Check if user have all permission to be in that component
    this.cognitoService.getCurrentRole([environment.users.role.administrator, environment.users.role.client, environment.users.role.driver], [environment.users.standardUser], true, this.roleService.roles);

    await this.getClientBin();
     // Retrieve the value of 'from' from sessionStorage
     const fromComponent = sessionStorage.getItem('from');

     // Check if 'fromComponent' has a value
     if(fromComponent){
       // Switch statement based on the value of 'fromComponent'
       switch(fromComponent){
         // If 'fromComponent' is 'bin-model-update'
         case 'bin-update':
           // Call a method from systemMessageService to select a ribbon with success message
           this.systemMessageService.selectRibbon('success', 'binModelCreateViewAlertSuccessUpdate');
         break;

         // Default case
         default:
         break;

       }
       // Remove 'from' from sessionStorage
       sessionStorage.removeItem('from');
     }
  }

  // Get ll bins for the client
  getClientBin(){
    return new Promise<any[]>((resolve) => {
      // Call the function that call the lambda function
      this.clients.getBinByClientId(this.cognitoService.clientId).subscribe(async (response) => {
        // Put the array of Bins returned by the lambda function into an tmp array
        this.array = response;
        await this.replaceTypeOfWasteAndMarketSegmentKeys();

        this.clientBinArray = this.array;
        // Set the bin_array and original_bin_array whit the tmp array values
        this.originalClientBinArray = this.array;
        resolve(this.clientBinArray);
      });
    });
  }

  // Function used to made a search in the list
  search(){
    // Check if there's a filterBy variable and set it to it's default value
    if(this.filterBy === ''){
      this.filterBy = 'bin_name';
    }

    this.clientBinArray = this.filter.search(this.userFilter, this.clientBinArray, this.originalClientBinArray, this.filterBy);
  }

  // Function used when user sort on a column of the list
  onSortChange(caller: string){

    this.isPlaceholderVisible = false;

    // Switch the value of ascending or descending
    if(caller === 'switch'){
      if(this.ascDesc === 0){
        this.ascDesc = 1;
      }else{
        this.ascDesc = 0;
      }
    }

    // Avoid sortBy to have no value
    if(this.sortBy === ''){
      this.sortBy = 'bin_address';
    }

    // Function that sort the list
    this.clientBinArray.sort((a, b) => {
      switch(this.sortBy){
        case 'bin_address':
          return this.ascDesc === 1 ? b.bin_address.localeCompare(a.bin_address) : a.bin_address.localeCompare(b.bin_address);

        case 'thing_name':
          if (a.thing_name && b.thing_name) {
            return this.ascDesc === 1 ? b.thing_name.localeCompare(a.thing_name) : a.thing_name.localeCompare(b.thing_name);
          } else {
            // If one of the instance in the array don't have thing_name it will put it at the end of the array
            if (a.thing_name) {
              return this.ascDesc === 1 ? 1 : -1; // Put a before if he have thing_name
            } else if (b.thing_name) {
              return this.ascDesc === 1 ? -1 : 1; // Put b before if he have thing_name
            } else {
              return 0;
            }
          }

        case 'bin_name':
          return this.ascDesc === 1 ? b.bin_name.localeCompare(a.bin_name) : a.bin_name.localeCompare(b.bin_name);

        case 'bin_model_number':
          return this.ascDesc === 1 ? b.bin_model_number.localeCompare(a.bin_model_number) : a.bin_model_number.localeCompare(b.bin_model_number);
      }
    });
  }

  // Function called to get the column style of the grid list
  getColumnStyle(): any {
    const numberOfColumn = this.ajustNumberOfColumns(this.getScreenWidth());
    return  `repeat(${numberOfColumn}, minmax(350px, 1fr))`;
  }

  // Function called to replace the key of type of waste by there name
  async replaceTypeOfWasteAndMarketSegmentKeys(){
    this.array.forEach((item: any) => {
      const binUsage = BinUsage.find(usage => usage.value === item.bin_usage);
      const binMarketSegment = MarketSegment.find(marketSegment => marketSegment.value === item.market_segment);
      if(binUsage){
        item.bin_usage = binUsage.label;
      }
      if(binMarketSegment){
        item.market_segment = binMarketSegment.label;
      }
    });
  }

   // function called to adjust the number of column of the grid depbending of inner window width
   ajustNumberOfColumns(width: number): number | undefined{
    // Return the floor of the inner page width divided by 325 px as the width of the tile size
    return Math.floor((width / 325) * (85 / 100));
  }

  // Function to get the current screen width
  getScreenWidth(): number {
    // Return the inner width of the window as the screen width
    return window.innerWidth;
  }
}
