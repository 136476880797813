import {
  Component,
  ElementRef,
  HostListener,
  OnInit,
  ViewChild,
} from '@angular/core';
import { ThemeService } from '../service/theme.service';
import { IotService } from '../service/iot.service';
import { ReportsService } from '../service/reports.service';
import { map } from 'rxjs/operators';
import { ChartService } from '../service/chart.service';
import { TranslateService } from '@ngx-translate/core';
import { CognitoService } from '../service/cognito.service';

@Component({
  selector: 'app-device-modal-graphs',
  templateUrl: './device-modal-graphs.component.html',
  styleUrl: './device-modal-graphs.component.css',
})
export class DeviceModalGraphsComponent implements OnInit {
  //component variables
  public thingData: any; //saves device data
  public startDate = this.reports.getDateThirtyDaysAgo(); //filter start date
  public endDate = this.reports.getCurrentDate(); //filter end date
  public getThemeClass: boolean = true; //for theme
  public firstLoad = true; //checks for component first load
  @ViewChild('graphs') graphsDiv!: ElementRef; //reference of the graph container

  constructor(
    public theme: ThemeService,
    public iotService: IotService,
    public chart: ChartService,
    public reports: ReportsService,
    public translate: TranslateService,
    public cognitoService: CognitoService
  ) {}

  async ngOnInit() {
    await this.cognitoService.getUserType();
    //initialize graph
    this.applyFilter(this.iotService.thingName);
  }

  ngAfterViewChecked() {
    //adjust size of graphs when first loading them when view < 930px
    if (this.firstLoad && window.innerWidth <= 930) {
      const graphDivWidth = this.graphsDiv.nativeElement.offsetWidth;
      this.chart.viewLineChartTechnical = [graphDivWidth, 300];
      this.firstLoad = false;
    }
  }

  /**
   * Function that get data and set the graphs for device-graphs modal.
   *
   * @param thingName An array containing report data.
   */
  async applyFilter(thingName: string) {
    //clear graphs of data
    this.clearGraphs();
    try {
      /* (await this.iotService.getShadowFeed([thingName], this.startDate, this.endDate)) */
      //get the device data
      (await this.reports.getHistory(thingName))
        .pipe(
          map((data: any[]) => {
            //initialize data arrays
            let filteredDateArray = [], //for date range
              filteredVb1Array = []; //for VB1

            // Filtering out data outside of date range and falsy values of VB1
            filteredDateArray = data.filter((row) =>
              this.isDateIn(new Date(parseInt(row.timestamp)))
            );
            filteredVb1Array = data.filter(
              (row) => row.vb1 != null && parseInt(row.vb1) != 0
            );
            return { filteredDateArray, filteredVb1Array };
          })
        )
        .subscribe((response: any) => {
          this.thingData = response.filteredDateArray;
          //initialized temperature graph
          this.setTempGraph(response.filteredDateArray);
          //check device OS version
          const isVersionBelow17 = this.isVersionBelow17(
            response.filteredVb1Array
          );

          //set battery/vcc/vb1 graphs based on device version
          switch (isVersionBelow17) {
            case true: //device with version under 17
              this.setTripleLineGraph(response.filteredDateArray); //initialize graph without VB1
              this.chart.hasVB1 = false; //hides VB1 from the legend
              break;
            case false:
              //devie with version 17 and above
              this.setTripleLineGraph(
                response.filteredDateArray,
                response.filteredVb1Array
              ); //initialize graph with VB1
              this.chart.hasVB1 = true;
              break;
          }
        });
    } catch (error) {
      console.error(error);
    }
  }

  /**
   * Function called when user click on cancel button of the modal
   */
  cancel() {
    // hide modal
    this.iotService.showDeviceTechnicalGraphs = false;
  }

  /**
   * Function that will put different style to the page depend on his user type
   */
  getDisplayByUserType() {
    const screenWidth = window.innerWidth;

    // Style for user type Muirwood and width under 930 px
    if (this.cognitoService.userType === 'muirwood' && screenWidth < 930) {
      return {
        display: 'block',
        position: 'fixed',
        width: '450px',
        height: '525px',
        padding: '15px',
        top: '52%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        'box-shadow': '2px 3px 3px 2px gray',
        'z-index': '50',
        margin: 'auto',
        'border-radius': '5px',
        border: '1px solid gray',
      };
    }
    // Style for user type Muirwood and width over 930 px
    if (this.cognitoService.userType === 'muirwood' && screenWidth >= 930) {
      return {
        display: 'block',
        position: 'fixed',
        width: '750px',
        height: '450px',
        top: '20%',
        left: '0',
        'box-shadow': '2px 3px 3px 2px gray',
        'z-index': '10',
        padding: '15px',
        'border-radius': '5px',
        border: '1px solid gray',
      };
    }
    // Style for other user then Muirwood
    if (this.cognitoService.userType !== 'muirwood') {
      return {
        display: 'block',
        position: 'fixed',
        width: '450px',
        height: '300px',
        padding: '15px',
        top: '52%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        'box-shadow': '2px 3px 3px 2px gray',
        'z-index': '50',
        margin: 'auto',
        'border-radius': '5px',
        border: '1px solid gray',
      };
    } else {
      return {
        display: 'none',
      };
    }
  }

  /**
   * Function that checks if the OS version of the device is below version 1.17
   *  This is achieved by checking if all the values of the parameter array are equal to 0.
   *  If all values are falsy, device OS version is below 1.17
   * @param data an array of VB1 values
   * @returns A boolean indicating device OS version. True for OS versions below 1.17, false for OS versions 1.17 and above
   */
  isVersionBelow17(data: any[]) {
    return data.every((row) => parseInt(row.vb1) === 0);
  }

  /**
   * Function that checks if a date is between a date interval
   * @param date the date to check
   * @returns A boolean indicating if the date passed is in the time interval
   */
  isDateIn(date: Date): boolean {
    const start = new Date(this.startDate + 'T00:00:00');
    const end = new Date(this.endDate + 'T23:59:59');
    // Check if the date is after or equal to the start of 2023
    // and before the start of 2024
    return date >= start && date <= end;
  }

  /**
   * Function that clears graphs data
   */
  clearGraphs() {
    this.chart.singleLineChartTemp = [
      {
        name: '',
        series: '',
      },
    ];
    this.chart.lineChartTechnical = [
      {
        name: '',
        series: '',
      },
      {
        name: '',
        series: '',
      },
    ];
  }

  /**
   * Function that initialize data for the temperature graph
   * @param data an array of data from the device
   */
  setTempGraph(data: any) {
    //initialiaze data array
    let tempSeries: any[] = [], //to draw graph line
      tempValues: number[] = []; //to determine graph Y axis scale
    //interate through the data
    for (let row of data) {
      //get the date and temperature values
      const date = new Date(parseInt(row.tmp_timestamp) * 1000);
      const temp = parseInt(row.tmp) || parseFloat(row.tmp);
      //push data to the arrays
      tempSeries.push({
        //to draw graph line
        name: date.toLocaleTimeString() + " - " + date.toLocaleDateString(),
        value: temp,
        date: date.toLocaleDateString(),
      });
      if (temp != 0) {
        //to determine graph Y axis scale
        tempValues.push(temp);
      }
    }
    //generate y axis scaling
    this.chart.generateTempScaleMinMax(tempValues);
    //filtering out falsy temperature values
    const filteredTemp = tempSeries.filter((row) => row.value != 0);
    //initialize tooltip label text
    let title = '';
    switch (this.translate.currentLang) {
      case 'en':
        title = 'Temperature Rate of ';
        break;
      case 'es':
        title = 'Tasa de Temperatura de ';
        break;
      case 'fr':
        title = 'Taux de Température de ';
        break;
    }
    //finally set the graph data
    this.chart.singleLineChartTemp = [
      {
        name: title + data[0].thing_name,
        series: filteredTemp,
      },
    ];
  }
  /**
   * Function that initialize data for the battery/vcc/vb1 graph
   * @param data an array of data from the device
   * @param vb1Data an array of VB1 data from the device, initialized to [] for version below 1.17
   */
  setTripleLineGraph(data: any, vb1Data: any = []) {
    //initialiaze data array
    let vb1Series: any[] = [], //for vb1
      batSeries: any[] = [], //for battery
      vccSeries: any[] = []; //for vcc
    //interate through the data
    for (let row of data) {
      //get the dates, battery and vcc values
      const dateBat = new Date(parseInt(row.bat_timestamp) * 1000),
        dateVcc = new Date(parseInt(row.vcc_timestamp) * 1000),
        bat = parseFloat(row.bat),
        vcc = parseFloat(row.vcc) || parseInt(row.vcc);
      //push data to the arrays
      batSeries.push({
        name: dateBat.toLocaleTimeString() + " - " + dateBat.toLocaleDateString(),
        value: bat.toFixed(2),
        date: dateBat.toLocaleDateString(),
      });
      vccSeries.push({
        name: dateVcc.toLocaleTimeString() + " - " + dateVcc.toLocaleDateString(),
        value: vcc.toFixed(2),
        date: dateVcc.toLocaleDateString(),
      });
    }
    //filtering out falsy temperature values
    const filetredVcc = vccSeries.filter((row) => row.value != 0);
    const filetredBat = batSeries.filter((row) => row.value != 0);

    //initialize tooltip label text
    let title = '',
      title2 = '',
      title3 = '';
    switch (this.translate.currentLang) {
      case 'en':
        title = 'Battery Rate of ';
        title2 = 'VCC Rate of ';
        title3 = 'VB1 Rate of ';
        break;
      case 'es':
        title = 'Tasa de Batteria de ';
        title2 = 'Tasa de VCC de ';
        title3 = 'Tasa de VB1 de ';
        break;
      case 'fr':
        title = 'Taux de Batterie de ';
        title2 = 'Taux de VCC de ';
        title3 = 'Taux de VB1 de ';
        break;
    }
    //set the graph data for battery and vcc
    this.chart.lineChartTechnical = [
      {
        name: title + data[0].thing_name,
        series: filetredBat,
      },
      {
        name: title2 + data[0].thing_name,
        series: filetredVcc,
      },
    ];
    // If vb1 data available
    if (vb1Data.lenght != 0) {
      //interate through the data
      for (let row of vb1Data) {
        const dateVb1 = new Date(parseInt(row.dst_timestamp) * 1000),
          vb1 = parseFloat(row.vb1);
        //get the dates and vb1 values
        vb1Series.push({
          name: dateVb1.toLocaleTimeString() + " - " + dateVb1.toLocaleDateString(),
          value: vb1.toFixed(2),
          date: dateVb1.toLocaleDateString(),
        });
      }
      //finally set the vb1 graph data
      this.chart.lineChartTechnical.push({
        name: title3 + data[0].thing_name,
        series: vb1Series,
      });
    }


  }

  // Function to get the current screen width
  getScreenWidth(): number {
    // Return the inner width of the window as the screen width
    return window.innerWidth;
  }

  // Event listener for window resize
  @HostListener('window:resize', ['$event'])
  onResize(event: any): void {
    // get the current window width
    const currentScreenWidth = this.getScreenWidth();
    if (currentScreenWidth > 580 && currentScreenWidth <= 930) {
      this.chart.viewLineChartTechnical = [500, 300];
    } else if (currentScreenWidth <= 580) {
      //get width of the graph container
      const graphDivWidth = this.graphsDiv.nativeElement.offsetWidth;
      //set the width of the chart to width of container
      this.chart.viewLineChartTechnical = [graphDivWidth, 300];
    } else {
      this.chart.viewLineChartTechnical = [300, 300];
    }
  }
}
