<app-system-message></app-system-message>

<div  class="themeBg" [ngClass]="theme.getThemeClass()"></div>
<div [ngClass]="theme.getThemeClass()" class="container-fluid">

  <section [ngClass]="theme.getThemeClass()" class="row flex-lg-nowrap centerContent">
    <article [ngClass]="theme.getThemeClass()" class="col height100V">
      <div [ngClass]="theme.getThemeClass()" class="card e-profile scale">
        <label class="MFAAuthenticatedDiv marginTop"><h1>{{ "headerUserMenuMyAccount" | translate }}</h1></label>
        <nav class="MFAAuthenticatedDiv marginTopCustom" *ngIf="this.cognitoService.userMFAPreference !== ''">
          <img class="MFAAuthenticatedIcon" src="../../assets/authenticated.png">
          <label class="MFAAuthenticated">{{ "MFAAuthenticated" | translate }}</label>
        </nav>
        <div class="tab-pane active">
          <form class="form userEdit formHeight" (ngSubmit)="onSubmit()" novalidate>
            <div class="col form-group">

              <!-- FIRST NAME INPUT -->
              <label class="label-spacer">{{ "usereditFormLabelFirstName" | translate }}</label>
              <input
                [(ngModel)]="firstName"
                type="text"
                name="firstName"
                class="form-control"
                placeholder
                value="{{ firstName }}"
                [class.error-border]="this.validationService.validationErrorArray[0] =='givenNameInvalid' "/>

              <!-- LAST NAME INPUT -->
              <label class="label-spacer">{{ "usereditFormLabelLastName" | translate }}</label>
              <input
                [(ngModel)]="lastName"
                type="text"
                class="form-control"
                name="lastName"
                placeholder
                value="{{ lastName }}"
                [class.error-border]="this.validationService.validationErrorArray[0] == 'familyNameInvalid' "/>

              <!-- EMAIL -->
              <label class="label-spacer">{{ "usereditFormLabelEmail" | translate }}</label>
              <input
                [(ngModel)]="email"
                type="text"
                class="form-control no-edit"
                name="email"
                placeholder
                value="{{ email }}"
                readonly/>

              <!-- ADDRESS INPUT -->
              <label class="label-spacer">{{ "usereditFormLabelAddress" | translate }}</label>
              <input
                [(ngModel)]="address"
                id="autocomplete"
                class="form-control"
                type="text"
                name="address"
                placeholder
                value="{{ address }}"
                aria-label="Enter a place"
                aria-describedby="basic-addon1"
                (blur)="this.validationService.validateAddress(address) "
                [class.error-border]="this.validationService.validationErrorArray[0] == 'clientAddressInvalid' "/>

              <!-- PHONE NUMBER INPUT -->
              <div class="phoneLabel">
                <label class="label-spacer">{{ "userlistClientsViewTableHeaderPhoneNumber" | translate }}</label>
                <div class="phoneAuthentificated">
                  <label class="phoneVerifed" *ngIf="this.phoneNumberVerified === 'true'">
                    <img class="imgAuthenticate" src="../../assets/authenticated.png"/>
                    {{ "phoneNumberVerified" | translate }}
                  </label>
                  <label class="phoneNotVerifed" *ngIf="this.phoneNumberVerified === 'false'">
                    <a (click)="authenticatePhoneNumber()">
                      <img class="imgAuthenticate" src="../../assets/not-authenticated.png"/>
                      {{ "phoneNumberNotVerified" | translate }}
                    </a>
                  </label>
                </div>
              </div>
              <input
                [(ngModel)]="phoneNumber"
                class="form-control"
                type="text"
                name="phoneNumber"
                placeholder
                value="{{ phoneNumber }}"
                [class.error-border]="this.validationService.validationErrorArray[0] == 'clientPhoneInvalid' "
              />

              <!-- Role group -->
              <label class="toggle-container-label">
                {{ "roles" | translate }}
              </label>
              <div class="toggle-container vam">
                <ng-container *ngFor="let role of Roles" class="test">
                    <div *ngIf="role.label && checkRoles(role.value)" class="custom-control custom-switch cs-container role-container mb-2">
                    <label  style="color: grey;" class="role-label ml-2">{{ role.label | translate }}</label>
                  </div>
                </ng-container>
              </div>
              <!--End of Role Group-->
              <nav class="row" *ngIf="this.cognitoService.userMFAPreference === 'NOMFA' ">
                <div class="col d-flex justify-content-center">
                  <a  [ngClass]="theme.getThemeClass()" class="signupmfa userEditLink" (click)="signUpMFA()">
                    {{ "signUpMFA" | translate }}
                  </a>
                </div>
              </nav>
              <nav class="row">
                <div class="col d-flex justify-content-center">
                  <a [ngClass]="theme.getThemeClass()" class="changePassword userEditLink" (click)="changePW()">
                    {{ "usereditFormLabelChangePw" | translate }}
                  </a>
                </div>
              </nav>
              <nav class="row">
                <div class="col d-flex justify-content-center">
                  <a  [ngClass]="theme.getThemeClass()" class="changePreference userEditLink" (click)="changePreference()">
                    {{ "accessAlertPreference" | translate }}
                  </a>
                </div>
              </nav>
              <nav class="row">
                <div class="col d-flex justify-content-center">
                  <button
                    [ngClass]="theme.getThemeClass()"
                    class="btn"
                    type="submit">
                    {{ "usereditFormBtnSaveChanges" | translate }}
                  </button>
                </div>
              </nav>
            </div>
          </form>
        </div>
      </div>
    </article>
  </section>
</div>
<app-user-edit-modal *ngIf="this.userEditModalService.showUserEditModal" class="user-edit-modal"></app-user-edit-modal>
