<app-system-message></app-system-message>
<!-- Container for the form with dynamic styling -->
<div [ngClass]="theme.getThemeClass()" class="container-fluido">
    <section [ngClass]="theme.getThemeClass()" class="card bg-light">
        <article class="card-body mx-auto article">
            <h4 class="card-title mt-0 text-center">{{ 'binModelCreateBinModelCreate' | translate }}</h4>
            <form>

                <!-- Input field for bin-model-number -->
                <label class="label-spacer">{{ "binModelName" | translate }} <span class="requiredStar">*</span></label>
                <div class="input-group mb-3">
                    <span class="input-group-text" id="basic-addon1"><i class="fa fa-hashtag"></i></span>
                    <input [(ngModel)]="this.bin.bin_model_data.bin_model_number" type="text"
                    class="form-control" name="bin_model_number"
                    placeholder="{{ 'binModelUpdateEnterBinNumber' | translate }}" aria-label="bin_model_number"
                    aria-describedby="basic-addon1"
                    (blur)="this.validationService.validateBinModelNumber(this.bin.bin_model_data.bin_model_number)"
                    [class.error-border]="this.validationService.validationErrorArray[0] == 'binModelNumberInvalid'">
                </div>
                <!-- Selet for bin shapes -->
                <label class="label-spacer">{{ "binShape" | translate }} <span class="requiredStar">*</span></label>
                <div class="input-group mb-3">
                    <span class="input-group-text" id="basic-addon1"><i class="fa fa-star"></i></span>
                    <select [(ngModel)]="this.selectedShape" (ngModelChange)="shapeChange()" class="form-control" name="bin_model_shape">
                        <option value="" disabled selected>{{ "binModelUpdateSelectEnterShape" | translate }}</option>
                        <option value="S">{{ "binModelUpdateOptionSquare" | translate }}</option>
                        <option value="C">{{ "binModelUpdateOptionCylinder" | translate }}</option>
                    </select>
                </div>
                <!-- Input for bin-width -->
                <label class="label-spacer">{{ "binWidthMM" | translate }} <span class="requiredStar">*</span></label>
                <div class="input-group mb-3">
                    <!-- <span class="input-group-text" id="basic-addon1"><i class="fa fa-arrows-alt-h"></i></span> -->
                    <span class="input-group-text" id="basic-addon1"><span class="width"><i class="fa fa-arrow-down"></i><i class="fa fa-arrow-down "></i><i class="fa fa-arrow-down binWidth"></i></span></span>
                    <input [(ngModel)]="this.width" type="number" min="1"
                    class="form-control" name="bin-width"
                    placeholder="{{ 'binModelUpdateEnterWidth' | translate }}" aria-label="width"
                    aria-describedby="basic-addon1">
                </div>
                <!-- Input for bin-height -->
                <label class="label-spacer">{{ "binHeightMM" | translate }} <span class="requiredStar">*</span></label>
                <div class="input-group mb-3">
                    <!-- <span class="input-group-text" id="basic-addon1"><i class="fa-solid fa-arrow-down"></i></span> -->
                     <span class=" input-group-text" id="basic-addon1"><span class="height"><i class="fa fa-arrow-down"></i><i class="fa fa-arrow-down binHeight"></i><i class="fa fa-arrow-down"></i></span></span>
                    <input [(ngModel)]="this.height" type="number" min="1"
                    class="form-control" name="bin_height"
                    placeholder="{{ 'binModelUpdateEnterHeight' | translate }}" aria-label="bin_height"
                    aria-describedby="basic-addon1"
                    (blur)="this.validationService.validateBinWidthHeightDepth(this.height, 'height')"
                    [class.error-border]="this.validationService.validationErrorArray[0] == 'binHeightInvalid'">
                </div>
                <!-- Input for bin-depth -->
                <label class="label-spacer " *ngIf="this.selectedShape === 'S'">{{ "binDepthMM" | translate }} <span class="requiredStar">*</span></label>
                <div class="input-group mb-3" *ngIf="this.selectedShape === 'S'">
                    <!-- <span class="input-group-text" id="basic-addon1"><i class="fa depth"></i></span> -->
                    <span class="input-group-text" id="basic-addon1"><span class="depth"><i class="fa fa-arrow-down binDepth"></i><i class="fa fa-arrow-down "></i><i class="fa fa-arrow-down"></i></span></span>
                    <input [(ngModel)]="this.depth" type="number" min="1"
                    class="form-control" name="bin_depth"
                    placeholder="{{ 'binModelUpdateEnterDepth' | translate }}" aria-label="bin_depth"
                    aria-describedby="basic-addon1">
                </div>
                <!-- Input for total volume calculated -->
                <label class="label-spacer ">{{ "binVolume" | translate }} <span class="requiredStar">*</span></label>
                <div class="input-group mb-3">
                    <span class="input-group-text" id="basic-addon1"><i class="fa total_volume"></i></span>
                    <input [(ngModel)]="this.totalVolume" type="number" min="1"
                    class="form-control totalVolume" name="total_volume"
                    placeholder="{{ 'binModelUpdateViewTotalVolume' | translate }}" aria-label="total_volume"
                    (blur)="this.validationService.validateBinWidthHeightDepth(this.height, 'height')"
                    [class.error-border]="this.validationService.validationErrorArray[0] == 'binHeightInvalid'">
                </div>
                <!-- Select to know if the bin is above ground or not -->
                <label class="label-spacer">{{ "_installation" | translate }} <span class="requiredStar">*</span></label>
                <div class="input-group mb-3">
                    <span class="input-group-text" id="basic-addon1"><i class="fa above_ground"></i></span>
                    <select [(ngModel)]="this.bin.bin_model_data.above_ground" class="form-control" name="bin_model_above_ground">
                        <option value="" disabled selected>{{ "binModelUpdateSelectEnterAboveGround" | translate }}</option>
                        <option value="1">{{ "inGround" | translate }}</option>
                        <option value="0">{{ "aboveGround" | translate }}</option>
                    </select>
                </div>
                
                <!-- Select used to get the distributor id used for the association of the bin-model -->
                <label *ngIf="this.cognitoService.userType === 'muirwood'" class="label-spacer">{{ "_distributor" | translate }} <span class="requiredStar">*</span></label>
                <div class="input-group mb-3" *ngIf="this.cognitoService.userType === 'muirwood'">
                  <span class="input-group-text" id="basic-addon1"><i class="fa fa-hashtag"></i></span>
                  <ng-select [(ngModel)]="this.bin.bin_model_data.distributor_id" class="form-control ng-select-distributor"
                    name="distributor"  [ngClass]="{'has-value': this.bin.bin_model_data.distributor_id }">
                      <ng-option value="" selected>{{ "distributorName" | translate }}</ng-option>
                      <ng-option *ngFor="let option of this.cognitoService.distributorsInfosArray" [value]="option.distributor_id">{{ option.legal_name }}</ng-option>
                  </ng-select>
                </div>

                <!-- Select to know if the bin is with coming with a device or not  -->
                <label class="label-spacer">{{ "comingWithDevice" | translate }} </label>
                <div class="input-group mb-3">
                  <span class="input-group-text" id="basic-addon1"><i class="fa-solid fa-microchip"></i></span>
                  <select [(ngModel)]="this.bin.bin_model_data.with_thing" class="form-control" name="bin_coming_with_device">
                      <option value="" disabled selected>{{ "comingWithDevice" | translate }}</option>
                      <option value="1">{{ "binModelUpdateOptionYes" | translate }}</option>
                      <option value="0">{{ "binModelUpdateOptionNo" | translate }}</option>
                  </select>
                </div>
                
                <!-- Submit button -->
                <nav class="form-group displayFlex">
                    <button (click)="returnToAdmin()" *ngIf="this.cognitoService.userType === 'muirwood'" [ngClass]="theme.getThemeClass()" type="button" class="btn btn-block btnCancel">{{ 'clientcreateFormBtnCancel' | translate }}</button>
                    <button (click)="returnToDistributorBinsDashboard()" *ngIf="this.cognitoService.userType === 'distributor'" [ngClass]="theme.getThemeClass()" type="button" class="btn btn-block btnCancel">{{ 'clientcreateFormBtnCancel' | translate }}</button>
                    <button [disabled]="this.systemMessageService.buttonBlocked" [ngClass]="theme.getThemeClass()" (click)="onSubmit(); disableButton()" type="button" class="btn btn-block btnCreate">{{ 'clientcreateFormBtnCreateClient' | translate }}</button>
                </nav>
            </form>
        </article>
    </section>
</div>


