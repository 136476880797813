import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { enableDebugTools } from '@angular/platform-browser';
import { ApplicationRef, enableProdMode } from '@angular/core';

//enableProdMode();

// platformBrowserDynamic().bootstrapModule(AppModule)
//   .catch(err => console.error(err));

// Execute ng.profiler.timeChangeDetection() to measure the duration of a change detection run (500ms or 5 change detection cycles)
platformBrowserDynamic().bootstrapModule(AppModule)
  .then(module => enableDebugTools(module.injector.get(ApplicationRef).components[0]));


