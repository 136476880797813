<!-- Search input component, shown when mainContent is 'location-list' -->
<div [ngClass]="theme.getThemeClass()" class="min-width-div container-fluid">
  <nav
    id="search-component"
    class="filter-group"
    *ngIf="mainContent === 'locations'"
  >
    <input
      #searchBox
      type="search"
      id="search-box"
      [(ngModel)]="userFilter"
      (input)="this.search()"
      class="form-control rounded searchInput"
      placeholder="{{ 'userlistSearchInputPlaceHolder' | translate }}"
      aria-label="Search"
      aria-describedby="search-addon"
    />
    <ng-select class="form-control ng-select select-filter-by" [(ngModel)]="this.filterBy"
      placeholder="{{ 'filterBy' | translate }}">
      <ng-option value="" disabled selected>{{ "filterBy" | translate }}</ng-option>
          <ng-option value="bin_name">{{ "binName" | translate }}</ng-option>
          <ng-option value="bin_model_number">{{ "binNumber" | translate }}</ng-option>
          <ng-option value="bin_usage">{{ "adminViewBinUsageNavBar" | translate }}</ng-option>
    </ng-select>
  </nav>

  <!-- Table header for the bin-list, shown when mainContent is 'location-list' -->
  <header [ngClass]="theme.getThemeClass()" class="table-header">
    <span class="left">{{ "locationsList" | translate }}</span>
    <span class="right">
      <button [ngClass]="theme.getThemeClass()" class="btn btn-dropdown" id="buttonDropdown" routerLink="/entities-location-create">
        {{ "newLocation" | translate }}
      </button>
    </span>
  </header>

  <!-- Table container for the bin-list, shown when mainContent is 'location-list' -->
  <section
    [ngClass]="theme.getThemeClass()"
    class="table-container"
    *ngIf="mainContent === 'locations'"
  >
    <article class="table-responsive">
      <table class="table table-hover">
        <thead>
          <tr>
            <th [ngClass]="theme.getThemeClass()" scope="col">
              {{ "entity" | translate }}
            </th>
            <th [ngClass]="theme.getThemeClass()">
              {{ "binListViewLocation" | translate }}
            </th>
            <th [ngClass]="theme.getThemeClass()" scope="col">
              {{ "iotTabHeaderResources" | translate }}
            </th>
          </tr>
        </thead>
        <ng-container *ngFor="let location of this.entitiesLocationService.location_detail_array">
          <tbody>
            <tr class="trTbody">
              <td
                [ngClass]="theme.getThemeClass()"
                [style.color]="
                  location.active ? 'green!important' : 'red!important'
                "
              >
                {{ location.entity_legal_name }}
              </td>
              <td
                [ngClass]="theme.getThemeClass()"
                class="hide-small-screen"
                [style.color]="
                  location.active ? 'green!important' : 'red!important'
                "
              >
                {{ location.location }}
              </td>
              <td [ngClass]="theme.getThemeClass()">
                <a [routerLink]="['/entities-location-update', location.location_id]"
                  ><img
                    class="iconUpdate"
                    src="./assets/update-pencil.png"
                    alt="icon of update"
                    height="30"
                    width="30"
                /></a>
              </td>
            </tr>
          </tbody>
        </ng-container>
      </table>
    </article>
  </section>
</div>

