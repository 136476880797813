import { ValidationService } from './../service/validation.service';
import {
  Component,
  OnInit,
  Renderer2,
  Inject,
  forwardRef,
  ViewChildren,
  QueryList,
  ElementRef,
} from '@angular/core';
import { LocalStorageService } from '../local-storage.service';
import { CognitoService } from '../service/cognito.service';
import { TranslateService } from '@ngx-translate/core';
import { ClientService } from '../service/client.service';
import { ThemeService } from '../service/theme.service';
import { FilterService } from '../service/filter.service';
import {
  Collection,
  CollectionCount,
  DeviceConfig,
  IotService,
} from '../service/iot.service';
import { Devices } from '../service/iot.service';
import { BinsService } from '../service/bins.service';
import {  Router } from '@angular/router';
import { DispatchService } from '../service/dispatch.service';
import { Bin, ReportsService } from '../service/reports.service';
import { DashboardService } from '../service/dashboard.service';
import { BinUsage } from '../constants/bin-usage';
import { DistributorsService } from '../service/distributors.service';

interface Client {
  active: string; // e.g., "1" or "0"
  address: string; // e.g., "25 rue chap"
  auto_collect: string; // e.g., "1"
  checked: boolean; // e.g., false
  client_id: string; // e.g., "CL4ccb363d4cd095d853"
  client_logo: string | null; // e.g., null or a string
  client_name: string; // e.g., "jocelyn client"
  created: Date | null; // e.g., a date or null
  email: string; // e.g., "joccorm@gmail.com"
  legal_name: string; // e.g., "jocelyn"
  modified: Date | null; // e.g., a date or null
  mws: string; // e.g., "0"
  phone_number: string; // e.g., "4388375731"
}

@Component({
  selector: 'app-dispatch-network',
  templateUrl: './dispatch-network.component.html',
  styleUrls: [
    '../distributor-iot-dashboard/distributor-iot-dashboard.component.css',
    '../distributor-admin-dashboard/distributor-admin-dashboard.component.css',
    '../distributor-bins-model-dashboard/distributor-tiles.css',
    '../../global-elements.css',
    './dispatch-network.component.css',
  ],
})
export class DispatchNetworkComponent implements OnInit {
  // Retrieve current language selected from local storage
  languageStatus: string = this.localStorageService.getItem('language');
  public isNavOpen = false;//to save status of nav menu
  public allThings: any[] = []; //to filter and display things list
  public originalAllThings: any[] = [];
  //public selecteClientThings: Devices[] = [];// to save a selected client things potential use
  public clientArray: any[] = [];// to filter and display the client list
  public originalClientArray: any[] = [];//to save the original client array
  public activeTab: string = 'client'; // to save the active tab in the UI
  public isClientsTabOpen = true;// to save status of Client tab
  public isDevicesTabOpen = false;// to save status of device tab
  public isRoutesTabOpen = false;// to save status of associated client tab
  public isReportTabOpen = false;// to save status of reports tab
  public clientSearch = '';// to search for a specific client based on active filter
  public deviceSearch = '';// to search for a specific device based on active filter
  public filterBy = '';// the property to filter the client's array by
  public filterDeviceBy = '';// the property to filter the device's array by
  public statusFilter: string = '';// the status to filter devices by
  public sortDeviceBy: string = '';// the property to sort devices by
  @ViewChildren('clientCheckbox') checkboxes!: QueryList<ElementRef>;// list of client input checkboxes
  public sortBy: string = '';// the property to sort clients by
  public thingStatusArray: any;// an array of things status
  // Array Fetch customer data
  public ascDesc: number = 0; // the sort order
  public isSortBy: boolean = false; // the active status of client sort by element
  public isSortDeviceBy: boolean = false;// the active status of device sort by element
  public startDate: any = this.reports.getFirstDateOfTheCurrentYear();// get first day of current year for report data
  public endDate: any = this.reports.getCurrentDate(); // get current date for report data
  public collectionData: Collection[] = [];// to save collection data
  public collectionCountData: CollectionCount[] = []; // to save count collection data
  public things: any; // an array of thing name
  public deviceNameList: string[] = []; // list of all devices name for report data
   // For info bubble
   public showStateInfo: boolean[] = [];
  tonnage: any;//to save calculated tonnage.
  public binData: Bin = { // bin data object
    above_ground: 0,
    active: 0,
    bin_address: '',
    bin_depth: 0,
    bin_height: 0,
    bin_id: '',
    bin_gps: '',
    bin_location: '',
    bin_model_number: '',
    bin_postal_code: '',
    bin_shape: '',
    bin_usage: '',
    bin_width: 0,
    client_id: '',
    distributor_id: '',
    thing_name: '',
    bin_volume: 0,
  };
  
  constructor(
    @Inject(forwardRef(() => TranslateService))
    private translate: TranslateService,
    private localStorageService: LocalStorageService,
    private renderer: Renderer2,
    private cognitoService: CognitoService,
    private clientService: ClientService,
    public dispatchService: DispatchService,
    public distributors: DistributorsService,
    public iotService: IotService,
    public reports: ReportsService,
    public validationService: ValidationService,
    private router: Router,
    public theme: ThemeService,
    private dashboardService: DashboardService,
    private filter: FilterService,
    public binService: BinsService
  ) {
    this.cognitoService.confirmValidUser();
    // Check if the user has selected a language in local storage
    //or use a default language
    if (this.languageStatus == null) {
      // Set the default language to French
      translate.use('fr');
    } else {
      // Set the default language to the user's selected language
      translate.use(this.languageStatus);
    }
  }
  async ngOnInit() {
    await this.getClientList(); // get the client list
    //await this.iotService.getClientsThingList(); //get the things
    await this.initializeDeviceData(); // Set up the each device data
    this.originalAllThings = this.iotService.devicesArray; // save  the orginal device array
  }

  /**
   * Function that get all the clients
   */
  getClientList() {
    try {
      // Use the clientService to fetch client data from an API
      this.clientService.getClients().subscribe(
        // Successful response callback
        (response: any) => {
          this.clientArray = response; // Fill the array with customer data
          this.originalClientArray = response; // Save the orginal client array
          this.clientArray.forEach((client) => {
            //create a checked property for UI selection
            client.checked = false;
          });
        }
      );
    } catch (error) {
      console.error('Error: ', error);
    }
  }

  /**
   * Function that initialize each thing's data
   */
  async initializeDeviceData() {
    (await this.dispatchService.getAllThings()).subscribe(
      (res: { thing_name: string }[]) => {
        const things = res.map((item) => item.thing_name); //get the list of thing names
        this.deviceNameList = things;
        this.getReport(this.deviceNameList); // Get report data for each things in the list
      }
    );
  }
  /**
   * Function that get report data for a list of things
   * @param {string[]} thingList - The list of device to get the report
   */
  async getReport(thingList: string[]) {
    //convert date to unix
    const start = this.dateToUnixTimestamp(this.startDate);
    const end = this.dateToUnixTimestamp(this.endDate + 'T23:59:59');
    //get things status array
    const data = await this.iotService.getDeviceHealth(thingList);
    this.iotService.thingStatusArray = JSON.parse(data.status);
    //get the report data 
    (await this.reports.getReportData(thingList, start, end, false)).subscribe(
      (res: any) => {
        this.collectionData = JSON.parse(res.collections);
        this.collectionCountData = JSON.parse(res.counts);
        console.log(res);

        for (const filteredItem of this.iotService.thingStatusArray) {
          // Retrieve configurations, bin data, and shadows for the current filtered item
          const config: DeviceConfig[] = JSON.parse(data.configs).filter(
            (item: DeviceConfig) => item.thing_name === filteredItem.thing_name
          );
          const bins: any[] = JSON.parse(data.bins).filter(
            (item: any) => item.thing_name === filteredItem.thing_name
          );
          const [bin] = bins || [];
          const shadows = JSON.parse(data.shadows).filter(
            (item: any) => item.thing_name === filteredItem.thing_name
          );
          const [shadow] = shadows || [];
          const device = this.dashboardService.getDevicesStructure(
            shadow,
            bin,
            config
          );
          // Retrieve report data asynchronously
          const collectionData = this.iotService.filterCollectionsByThingName(
            this.collectionData,
            filteredItem.thing_name
          );
          const counts = this.iotService.filterCollectionsCountsByThingName(
            this.collectionCountData,
            filteredItem.thing_name
          );

          // Update bin data
          this.binData.bin_volume = bin.total_volume || 0;
          this.binData.bin_height = bin.bin_height || 0;
          this.binData.bin_usage = bin.bin_usage ?? '';

          let cubicMetersOfWasteTotal = 0;

          // Iterate through collection data
          for (let index = 0; index < collectionData.length; index++) {
            // Calculate cubic meters of waste total
            cubicMetersOfWasteTotal += this.calculateCubicMeters(
              this.binData.bin_volume,
              counts?.avg_preceding_dst ?? 0,
              this.binData.bin_height
            );
          }

          // Calculate tonnage
          const tonnage =
            this.calculateWasteCollection(
              this.binData.bin_usage,
              cubicMetersOfWasteTotal // Usar a cubagem total para calcular a tonelagem
            ) * 0.001;

          // Update tonnage
          this.tonnage = parseFloat(tonnage.toFixed(1)); // Atribuir a tonelagem total

          let distance_to_lid = filteredItem.distance_to_lid;
          let lastUpdate = 0;

          if (filteredItem.last_update) {
            lastUpdate = new Date(Number(filteredItem.last_update)).getTime();
          }

          // Check if necessary data is available for further processing
          if (bin && distance_to_lid && device.config?.slp) {
            let object = {
              tonnage: this.tonnage,
              battery: filteredItem.battery,
              distance_to_lid: filteredItem.distance_to_lid,
              last_update: filteredItem.last_update,
              status: filteredItem.status,
              thing_name: filteredItem.thing_name,
              bin_capacity: bin.bin_height,
              location: bin.bin_address ?? '',
              fill_level: this.iotService.checkFillLevelPercentage(
                distance_to_lid,
                bin.bin_height
              ),
              bin_id: bin.bin_id ?? '',
              heartbeat: this.iotService.verifyDeviceHearthBeat(
                this.iotService.filterDeviceConfig(
                  config,
                  shadow.thing_name,
                  'last'
                ),
                this.iotService.filterDeviceConfig(
                  config,
                  shadow.thing_name,
                  'second_last'
                ),
                shadow.dst_timestamp,
                shadow.tm2
              ),
              sleep_time: device.config?.slp ?? 0,
              device: device,
            };

            // Push filtered object to array
            this.allThings.push(object);

            // Reset tonnage and bin data
            this.tonnage = 0;
            this.binData.bin_volume = 0;
            this.binData.bin_height = 0;
            this.binData.bin_usage = '';
          }
          this.originalAllThings = this.allThings;
          console.log(this.allThings);
        }
        let cubicMetersOfWasteTotal;
      }
    );
  }

  /**
   * Function that toggles the nav
   */
  toggleMenu() {
    this.isNavOpen = !this.isNavOpen;
    console.log(this.isNavOpen);
  }
  /**
   * Function that handles navigation
   * @param {number} tab - the tab tab to  navigate to
   */
  navigate(tab: number) {
    switch (tab) {
      case 1: //to clients
        this.isClientsTabOpen = true;
        this.isDevicesTabOpen = false;
        this.isRoutesTabOpen = false;
        this.isReportTabOpen = false;
        this.activeTab = 'client';
        break;
      case 2: //to devices
        this.isClientsTabOpen = false;
        this.isDevicesTabOpen = true;
        this.isRoutesTabOpen = false;
        this.isReportTabOpen = false;
        this.activeTab = 'device';
        break;
      case 3: //to associations
        this.isClientsTabOpen = false;
        this.isDevicesTabOpen = false;
        this.isRoutesTabOpen = true;
        this.isReportTabOpen = false;
        this.activeTab = 'associations';
        break;
      case 4: //to report
        this.isClientsTabOpen = false;
        this.isDevicesTabOpen = false;
        this.isRoutesTabOpen = false;
        this.isReportTabOpen = true;
        this.activeTab = 'report';
        break;
    }
  }
  /**
   * Function that format a 10 digits phone number
   * @param numberSequence - the  number sequence to format
   * @returns formatted phone number to (XXX) XXX-XXXX or original sequence
   */
  formatNumber(numberSequence: string | undefined | null): string {
    // If the input is undefined or null, return an empty string (or handle the case appropriately)
    if (numberSequence === undefined || numberSequence === null) {
      return ''; // or handle the case appropriately
    }

    // If the length of the cleaned number is 10, format it as (XXX) XXX-XXXX
    const justNumbers = numberSequence.replace(/\D/g, '');

    if (justNumbers.length === 10) {
      const parte1 = justNumbers.slice(0, 3);
      const parte2 = justNumbers.slice(3, 6);
      const parte3 = justNumbers.slice(6);
      return `(${parte1}) ${parte2}-${parte3}`;
    } else {
      // If the length is not 10, return the input as is
      return numberSequence;
    }
  }

  /**
   * Function used to made a search in the list
   * @param {number} i - Optional parameter that defaults search for thing name
   */
  search(i?: number) {
    // Check if there's a filterBy variable and set it to it's default value

    if (i && i === 1) {
      if (this.filterDeviceBy === '') {
        this.filterDeviceBy = 'thingName';
      }

      this.allThings = this.filter.search(
        this.deviceSearch,
        this.allThings,
        this.originalAllThings,
        this.filterDeviceBy
      );
      return;
    }
    if (this.filterBy === '') {
      this.filterBy = 'legal_name';
    }
    //search inputed client
    this.clientArray = this.filter.search(
      this.clientSearch,
      this.clientArray,
      this.originalClientArray,
      this.filterBy
    );
  }

  //old function but potential use so leaving it here
  /*  async getThing(clientId:string){
    console.log('Client ID:', clientId);
    this.selecteClientThings = this.allThings.filter((thing) => {
      return thing.bin?.client_id === clientId 
    })
    console.log( this.selecteClientThings)
  } */

  /**
   * Function that closes the navigation menu when user is navigating
   *
   */
  adjustSideBar() {
    if (!this.isNavOpen) {
      //do nothing if nav is closed
      return;
    }
    this.isNavOpen = false; //close the nav
  }

  //no uses found yet
  /*  selectAll(){
    if (this.isClientsTabOpen) {
      // get the checked value of the select all input
      const allChecked = this.checkboxes.toArray().every((checkbox) => checkbox.nativeElement.checked);
      this.checkboxes.forEach((checkbox: ElementRef) => {
        checkbox.nativeElement.checked = !allChecked; // Toggle the checked state
      });
      console.log( this.checkboxes.toArray()[0].nativeElement.checked)
    }
  } */
  /**
   * Function that set each client's checked state to the state of the "Select All" checkbox
   * @param event - used to access the  caller element
   */
  toggleSelectAll(event: Event) {
    //get checked value of select all checkbox
    const checked = (event.target as HTMLInputElement).checked;
    switch (this.activeTab) {
      case 'client':
        this.clientArray.forEach((client) => {
          client.checked = checked; //
        });
        break;
      case 'associations':
        this.dispatchService.associatedClients.forEach((client) => {
          client.checked = checked;
        });
        break;
    }
  }
  /**
   * Function that set each client's checked state to the state of the "Select All" checkbox
   * @param event - used to access the  caller element
   */
  toggleAssociatedClientDevice(event: Event) {
    const checked = (event.target as HTMLInputElement).checked;
    switch (checked) {
      case true:
        this.showAssociatedClientDevice();
        break;
      case false:
        this.allThings = this.originalAllThings;
        break;
    }
  }
  /**
   *
   * @returns boolean showing the checked status
   */
  areAllChecked(): boolean {
    return this.clientArray.every((client) => client.checked);
  }

  /**
   * Function that shows associated client devices in the device tab
   */
  showAssociatedClientDevice() {
    //filter allThings to show up only associated client devices
    this.allThings = this.allThings.filter((thing) => {
      return this.dispatchService.associatedClients.find((client) => {
        return client.client_id === thing?.device?.bin?.client_id;
      });
    });
  }

  /**
   * Function that count the devices associated with each client
   * @param clientID
   * @returns number of device
   */
  countDevice(clientID: string) {
    const deviceCount = this.allThings.filter((thing) => {
      return thing?.bin?.client_id === clientID;
    });
    console.log(deviceCount);
    return deviceCount.length;
  }

  /**
   * Function that select client passed
   * @param client client object
   */
  select(client: Client) {
    client.checked = !client.checked;
  }

  /**
   *Function that checks if there is at least 1 checked client
   * @returns true for at least 1 checked client
   */
  hasCheckedClient(): boolean {
    return this.clientArray.some((client) => client.checked);
  }
  /**
   * Function that associate selected client to the current dispatch user
   */
  associateClients() {
    const checkedClients = this.clientArray.filter(
      (client) => client.checked === true
    ); //get all selected clients
    this.dispatchService.associateClient(checkedClients); // associate them to dispatch
    this.clientArray.forEach((client) => (client.checked = false)); //deselect all clients
  }
  /**
   * Function that dissociate selected client to the current dispatch user
   */
  dissociateClients() {
    const checkedClients = this.dispatchService.associatedClients.filter(
      (client) => client.checked === true
    ); //get all selected clients
    this.dispatchService.dissociateClient(checkedClients); // associate them to dispatch
    this.clientArray.forEach((client) => (client.checked = false)); //deselect all clients
  }
  /**
   * Function that returns the number of devices associated to a specific client
   * @param clientID - The ID of the client
   * @returns number of device(s) associated to the client.
   */
  getDevices(clientID: string) {
    const deviceNum = this.allThings.filter((thing) => {
      return thing.device?.bin?.client_id === clientID; // Check if device.bin.client_Id matches the clientID
    });
    return deviceNum.length; // Return the array of matching devices
  }

  //saved for potential use
  /*  displayClientDevices(client_id:string){
    //Get client matching devices
    this.selecteClientThings = this.iotService.devicesArray.filter(device => {
      return device.bin?.client_id === client_id; // Check if device.bin.client_Id matches the clientID
    });
    this.allThings = this.selecteClientThings; //to display the selected client things
    this. navigate(2); // navigate to device tab
  } */

  /**
   * Function that performs a new search every time the type of thing to be filtered changes
   */
  onFilterByChange() {
    this.resetUserArray(); // Reset user array
    this.search(); // Perform search based on selected filter
    this.clientSearch = ''; // Clear the user input filter
  }
  /**
   * Function called to reset the user array
   */
  resetUserArray() {
    this.clientArray = this.originalClientArray;
  }
  /**
   * Function triggered when user change sort by  fitler of client tab
   * @param caller to change either the sort order or sort filter
   * @param event
   */
  onSortChange(caller: string, event: any) {
    // Reset sortBy variable to hide the toggle switch when user press the X in the select
    if (event === undefined) {
      this.isSortBy = false;
    } else {
      this.isSortBy = true;
    }

    // Switch the value of ascending or descending
    if (caller === 'switch') {
      if (this.ascDesc === 0) {
        this.ascDesc = 1;
      } else {
        this.ascDesc = 0;
      }
    }

    // Avoid sortBy to have no value
    if (this.sortBy === '') {
      this.sortBy = 'username';
    }

    // Function that sort the list
    this.clientArray.sort((a, b) => {
      switch (this.sortBy) {
        case 'username':
          return this.ascDesc === 1
            ? b.username.localeCompare(a.username)
            : a.username.localeCompare(b.username);

        case 'given_name':
          return this.ascDesc === 1
            ? b.given_name.localeCompare(a.given_name)
            : a.given_name.localeCompare(b.given_name);

        case 'family_name':
          return this.ascDesc === 1
            ? b.family_name.localeCompare(a.family_name)
            : a.family_name.localeCompare(b.family_name);
      }
    });
  }
  /**
   * Function triggered when user change sort on collumn of device tab
   * @param caller caller to change either the sort order or sort filter
   * @param event
   */
  onSortDeviceChange(caller: string, event: any) {
    // Reset sortBy variable to hide the toggle switch when user press the X in the select
    if (event === undefined) {
      this.isSortDeviceBy = false;
    } else {
      this.isSortDeviceBy = true;
    }
    // Switch the value of ascending or descending
    if (caller === 'switch') {
      if (this.ascDesc === 0) {
        this.ascDesc = 1;
      } else {
        this.ascDesc = 0;
      }
    }
    switch (this.sortDeviceBy) {
      case 'lastUpdate':
        this.sortByLastUpdate();
        break;
      case 'battery':
        this.sortByBattery();
        break;
      case 'fill':
        this.sortByFill();
        break;
    }

    // Avoid sortBy to have no value
    if (this.sortDeviceBy === '') {
      this.allThings = this.originalAllThings;
    }
  }

  /**
   * Function that sort the device array based on last update
   */
  sortByLastUpdate() {
    this.allThings.sort((a, b) => {
      return this.ascDesc === 1
        ? a.last_update - b.last_update
        : b.last_update - a.last_update;
    });
  }
  /**
   * Function that sort the device array based on associated bin fill level
   */
  sortByFill() {
    this.allThings.sort((a, b) => {
      const sortOrder = this.ascDesc ? 1 : -1;

      // If the status is "Inactive", move it to the end, regardless of fill Level
      if (a.status === 'Inactive' && b.status !== 'Inactive') {
        return 1;
      } else if (a.status !== 'Inactive' && b.status === 'Inactive') {
        return -1;
      }
      // If fill level unavailable, move it to the end, regardless of fill Level
      if (a.fill_level === 'N/A' && b.fill_level !== 'N/A') {
        return 1;
      } else if (a.fill_level !== 'N/A' && b.fill_level === 'N/A') {
        return -1;
      }

      let aFillLevel = 0;
      let bFillLevel = 0;

      if (a.fill_level && b.fill_level) {
        // Parse the string values into numeric values for fillLevel
        aFillLevel = parseFloat(a.fill_level.replace('%', ''));
        bFillLevel = parseFloat(b.fill_level.replace('%', ''));
      }

      // Compare the "fillLevel" properties of items
      if (aFillLevel < bFillLevel) {
        return -1 * sortOrder;
      } else if (aFillLevel > bFillLevel) {
        return 1 * sortOrder;
      } else {
        // If fillLevel values are equal, maintain the existing order
        return 0;
      }
    });
  }
  /**
   * Function that sort the device array based on battery level
   */
  sortByBattery() {
    this.allThings.sort((a, b) => {
      const sortOrder = this.ascDesc ? 1 : -1;
      if (
        (a.battery <= 100 && b.battery <= 100 && a.battery < b.battery) ||
        (a.battery > 100 && b.battery <= 100)
      ) {
        return -1 * sortOrder;
      } else if (
        (a.battery <= 100 && b.battery <= 100 && a.battery > b.battery) ||
        (a.battery <= 100 && b.battery > 100)
      ) {
        return 1 * sortOrder;
      } else {
        // If battery values are equal, maintain the existing order
        return 0;
      }
    });
  }

  /**
   * Function to handle changes in the status filter to filter things for a  speciffic status
   */
  onStatusFilterChange() {
    // Check if the status filter is null
    if (
      this.statusFilter === null ||
      this.statusFilter === '' ||
      this.statusFilter === undefined
    ) {
      // If null, call the search function to reset the array
      this.allThings = this.originalAllThings;
      return;
    }

    // Always call the search function, regardless of the status filter value
    this.allThings = this.allThings.filter((thing) => {
      return thing.status === this.statusFilter;
    });
  }
  /**
   * Function that toggles the display of the complete address of the user .
   * @param i the id of the address container
   */
  displayAddress(i: number) {
    //get the address container
    let element = document.getElementById(i.toString());
    //logic to display the address
    if (element && element.classList.contains('address')) {
      //to show complete address
      this.renderer.removeClass(element, 'address');
      this.renderer.addClass(element, 'addressVisible');
    } else {
      //to show address with ellipsis
      this.renderer.addClass(element, 'address');
      this.renderer.removeClass(element, 'addressVisible');
    }
  }

  /**
   * Function called to get the style of the columns of the grid list
   */
  getColumnStyle(): any {
    const numberOfColumn = this.ajustNumberOfColumns(this.getScreenWidth());
    if (numberOfColumn) {
      if (this.clientArray.length >= numberOfColumn) {
        const gridColumns = `repeat(${numberOfColumn}, minmax(0, 350px))`;
        return {
          'grid-template-columns': gridColumns,
        };
      } else {
        return {
          display: `flex`,
          'justify-content': `center`,
          margin: '0 -10px',
        };
      }
    }
  }

  /* getStatus(color:string): string{
    const statusMapping: { [key: string]: string } = {
      'green': 'chartLabelHealthy',
      'red': 'chartLabelUnhealthy',
      'grey': 'Inactive'
  };
  return statusMapping[color]
  } */

  /**
   * function called to adjust the number of column of the grid depbending of inner window width
   * @param {number} width - the width of the column
   * @returns Return the floor of the inner page width divided by 450 px as the width of the tile size
   */
  ajustNumberOfColumns(width: number): number | undefined {
    return Math.floor((width / 350) * (90 / 100));
  }

  /**
   * Function to get the current screen width
   */
  getScreenWidth(): number {
    // Return the inner width of the window as the screen width
    return window.innerWidth;
  }

  // Device tab functions

  /**
   * Function rhat set navigation anchor to dispatch-network for navigting back
   * @param thingName the name of the thing user wants to see satistics of
   */
  setNavToDeviceStat(thingName: string) {
    sessionStorage.setItem('toDeviceStats', 'dispatch-network');
    this.router.navigate([`/device-statistics/${thingName}`]);
  }
  /**
   * Gets the battery level style and information based on the provided battery level.
   * @param {any} batteryLevel - The battery level as any type (should be a number).
   * @returns {object} - An object containing icon, color, and title properties.
   */
  getBatteryLevel(batteryLevel: any): object {
    const result = {
      icon: '',
      color: '',
      title: '',
    };

    // Check if batteryLevel is a number and not NaN
    if (typeof batteryLevel === 'number' && !isNaN(batteryLevel)) {
      // Logic to set icon, color, and title based on the value of batteryLevel
      if (batteryLevel > 3.5) {
        return { color: '#63E6BE' };
      } else if (batteryLevel <= 3.5 && batteryLevel > 2.5) {
        return { color: '#63E6BE' };
      } else if (batteryLevel <= 2.5 && batteryLevel > 1.5) {
        return { color: '#FFD43B' };
      } else if (batteryLevel <= 1.5 && batteryLevel > 0) {
        return { color: '#f70202' };
      } else if (batteryLevel === 0) {
        return { color: '#f70202' };
      }

      result.title = `${batteryLevel}V`;
    } else {
      // If batteryLevel is not a number
      return { color: 'gray' };
    }

    return result;
  }
  /**
   * Function that get the style color depend on fill level of the bin
   * @param {string} fillLevel - the fill level of the device's associated bin
   * @returns
   */
  getFillLevelStyle(fillLevel: string): object {
    // Remove the percentage symbol
    let fillLevelWithoutPercent = '';
    if (fillLevel) {
      fillLevelWithoutPercent = fillLevel.replace('%', '');
    }

    // Convert to a floating-point number (float)
    const fillLevelFixed: number = parseFloat(fillLevelWithoutPercent);

    // Check if fillLevel is undefined
    if (fillLevel === undefined) {
      return { color: 'gray' }; // Style for gray color (default)
    }

    // Use the number for conditional comparisons
    if (fillLevelFixed <= 60) {
      return { color: 'green' }; // Style for green color
    } else if (fillLevelFixed <= 79.09) {
      return { color: '#B8860B' }; // Style for yellow color
    } else if (fillLevelFixed >= 79.09 && fillLevelFixed <= 100) {
      return { color: 'red' }; // Style for red color
    } else {
      return { color: 'gray' }; // Style for inactive (default)
    }
  }

  /**
   * Function used to set a date to unix timestamp
   * @param dateString - The date to convert to unix timestamp
   * @returns timestamp converted in seconds
   */
  dateToUnixTimestamp(dateString: string): number {
    // Parse the date string into a Date object
    const date = new Date(dateString);

    // Get the UTC timestamp in milliseconds
    const timestampInMilliseconds = date.getTime();

    // Convert milliseconds to seconds and return
    return Math.floor(timestampInMilliseconds / 1000);
  }

  /**
   * Function that will calculate the volume if all field are well filled
   * @param {number} binVolume - Total volume of the bin
   * @param {number} dst - distance to the captor
   * @param {number} binHeight - the height of the bin
   * @returns - return the calculated cubic meters
   */
  calculateCubicMeters(binVolume: number, dst: number, binHeight: number) {
    // Calculate the multiplier based on the given formula
    const multiplier = 1 - dst / binHeight;
    // Calculate the result - then return the calculated cubic meters
    return (binVolume / 1000) * multiplier;
  }

  /**
   * Functions that calculate the total waste collected
   * @param {string} usage -  the usage of the bin
   * @param {number} totalCubicMetersOfWaste - the total volume of waste  in cubic meters
   * @returns the total waste collected or 0 if invalid parameters
   */
  calculateWasteCollection(
    usage: string,
    totalCubicMetersOfWaste: number
  ): number {
    // Get the weight per cubic meter based on the given usage
    const weightPerCubicMeter = this.getWeightByValue(usage);

    // Check if the inputs are valid and return the calculated total waste collection
    if (!isNaN(totalCubicMetersOfWaste) && weightPerCubicMeter !== undefined) {
      return totalCubicMetersOfWaste * weightPerCubicMeter;
    } else {
      return 0; // Return 0 if inputs are not valid
    }
  }
  /**
   * Function that gets the average weight for the bin usage type
   * @param {string} usage - the usage of the bin
   * @returns the average weight for the bin usage type
   */
  getWeightByValue(usage: string): number {
    const bin = BinUsage.find((bin) => bin.value === usage);
    return bin?.weight || 0;
  }

  scrollToTop(){
    let targetElement;
    switch(this.activeTab){
      case 'client':
        targetElement =  document.getElementById('clientTop');
        break
      case 'device':
        targetElement =  document.getElementById('deviceTop');
        break
      case 'associations':
        targetElement =  document.getElementById('associationsTop');
        break
    }
    if (targetElement) {
      targetElement.scrollIntoView({
        behavior: 'smooth',
        block: 'start'
      });
    }
  }
}
