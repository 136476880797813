import { Component } from '@angular/core';
import { ChartService } from '../service/chart.service';
import { ThemeService } from '../service/theme.service';
@Component({
  selector: 'app-pie-chart',
  templateUrl: './pie-chart.component.html',
  styleUrls: ['./pie-chart.component.css', '../../charts.css']
})
export class PieChartComponent {

  constructor(public charts : ChartService,
              public theme: ThemeService){
    //Declare chart data as a constant
    const single = charts.singlePieChart
    //Assign data to chart object
    Object.assign(this, { single });
  }

}
