import { Injectable } from '@angular/core';
import { Truck, TruckService } from './truck.service';
import { environment } from '../environments/environment';
import { HttpHeaders, HttpClient } from '@angular/common/http';

export interface Drivers{
  user: string;
  username:string;
  truck_id:string;
}

@Injectable({
  providedIn: 'root'
})

export class TruckModalService {
  public showTruckModal: boolean = false;
  public operator_id: string = '';
  public userSub: string = '';
  public drivers: Drivers[] = [];

  constructor(
    public trucks: TruckService,
    private http: HttpClient,
  ) { }

  openTruckModal(truck:Truck) {
    this.showTruckModal = true;
    this.trucks.truck = truck;
  }

  async getAllDriversByOperatorId(): Promise<Drivers[]> {
    return new Promise<Drivers[]>((resolve) => {
      this.getAllDriversByOperatorIdLambda().subscribe((response: any) => {
        // Return the array of drivers in a json format
        resolve(response);
      })
    });
  }

  getAllDriversByOperatorIdLambda(){
    this.operator_id = this.trucks.truck.operator_id;
    // Get the url for the lambda function getAllDriversByOperatorId
    const url = environment.api.stage + environment.api.route.getAllDriversByOperatorId;
    // Define the HTTP headers with content type
    const headers = new HttpHeaders({
      'Content-Type':  'application/json' // Adjust content type as needed
    });
    // Will call the lambda function in updateBinModel url whit the passed data then return a response
    return this.http.post(url, {
      "operator_id": this.operator_id
      }, { headers: headers }
    );
  }

  /**
   * Function to update the trucks table, set the driver_id to the truck_id
   * @param driver_id 
   * @param truck_id 
   * @param _delete // Do we need to delete the driver from another truck?
   * @returns 
   */
  async updateDriverTruckAssociation(driver_id: string, truck_id: string, _delete: boolean = false): Promise<Drivers[]> {
    return new Promise<Drivers[]>((resolve) => {
      this.updateDriverTruckAssociationLambda(driver_id, truck_id, _delete).subscribe((response: any) => {
        // Return the array of drivers in a json format
        resolve(response);
      })
    });
  }

  updateDriverTruckAssociationLambda(driver_id: string, truck_id: string, _delete: boolean){
    const url = environment.api.stage + environment.api.route.updateDriverTruckAssociation;
    // Define the HTTP headers with content type
    const headers = new HttpHeaders({
      'Content-Type':  'application/json' // Adjust content type as needed
    });
    // Will call the lambda function in updateBinModel url whit the passed data then return a response
    return this.http.post(url, {
      "_delete": _delete,
      "driver_id": driver_id,
      "truck_id": truck_id
      }, { headers: headers }
    );
  }
}
