<app-system-message></app-system-message>
<!-- Container for the form with dynamic styling -->
<div [ngClass]="theme.getThemeClass()" class="container-fluido">
    <section [ngClass]="theme.getThemeClass()" class="card bg-light">
        <article class="card-body mx-auto article">
            <h4 class="card-title mt-0 text-center">{{ 'locationUpdate' | translate }}</h4>
            <form>
                <!-- Input field for address with Autocomplete -->
                <div class="input-group mb-3">
                  <span class="input-group-text" id="basic-addon1"><i class="fas fa-search-location"></i></span>
                  <input class="form-control" name="entityLocation" type="text" id="autocomplete" [(ngModel)]="this.entitiesLocationService.location_data.location" (keydown)="onInputAddressKeydown($event);">
                </div>
                <!-- Checkbos for the status of the bin model -->
                <div class="card-title mt-0 text-center status d-flex align-items-center">
                    <label class="switch">
                        <input type="checkbox" id="checkboxStatus" [name]="'active-location-' + entitiesLocationService.location_data.active" [ngModel]="this.active" (change)="this.alert.showAlertDelete= true; this.alert.deactiveLocation(entitiesLocationService.location_data.location_id, entitiesLocationService.location_data.location, this.active)" [disabled]="this.alert.showAlertDelete">
                        <span [ngClass]="{'active-checkbox': this.active === 1, 'inactive-checkbox': this.active === 0}"  class="slider round"></span>
                    </label>
                    <label class="toggle">{{ "iotTabHeaderStatus" | translate }}</label>
                </div>
                <!-- Submit button -->
                <nav class="form-group displayFlex">
                    <button (click)="returnToEntities()" [ngClass]="theme.getThemeClass()" type="submit" class="btn btn-block btnCancel fixed-size">{{ 'clientcreateFormBtnCancel' | translate }}</button>
                    <button [ngClass]="theme.getThemeClass()" (click)="onSubmit()" type="submit" class="btn btn-block btnCreate fixed-size">{{ 'update' | translate }}</button>
                </nav>
            </form>
        </article>
        <app-alert-modal *ngIf="this.alert.showAlertDelete" class="alertDelete" [ngClass]="theme.getThemeClass()"></app-alert-modal>
      </section>
</div>


