// Constant used for select in work order pages to change to the status
export const WorkOrderTypes = [
  { "value" : "M", "label" : "_maintenance"},
  { "value" : "I", "label" : "_installation"},
  { "value" : "C", "label" : "_collect"},
  { "value" : "O", "label" : "_order"},
  { "value" : "R", "label" : "_repair"},
  { "value" : "E", "label" : "_emergency"},
  { "value" : "D", "label" : "_decommission"}
];
