import { Injectable } from '@angular/core';

Injectable({
  providedIn: 'root'
})

export const ProductionStatus = [
  {"key":"0", "value":"N/A"},
  {"key":"1", "value":"Kit ready"},
  {"key":"2", "value":"Device identification"},
  {"key":"3", "value":"Network acquisition"},
  {"key":"4", "value":"Cycle testing"},
  {"key":"5", "value":"Cycle failed"},
  {"key":"6", "value":"Cycle successful"},
  {"key":"7", "value":"Assembly"},
  {"key":"8", "value":"Finalize"},
  {"key":"9", "value":"Ready to ship"}
]