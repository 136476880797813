import { Component, ElementRef, OnInit, ViewChild, forwardRef, Inject, Renderer2, AfterViewChecked } from '@angular/core'; //, AfterViewInit
import { HttpClient } from '@angular/common/http'; //, HttpHeaders
import { FormBuilder } from "@angular/forms"; // FormGroup, Validators
import { Router, ActivatedRoute } from '@angular/router';
import { LocalStorageService } from '../local-storage.service';
import { CognitoService,IUser } from '../service/cognito.service';
import { TranslateService } from '@ngx-translate/core';
import { ValidationService } from '../service/validation.service';
import { ThemeService } from '../service/theme.service';
import { SystemMessageService } from '../service/system-message.service';

@Component({
  selector: 'app-login',
  template: '<h1>Action: {{action}}</h1>',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})

export class LoginComponent implements OnInit, AfterViewChecked {
  // Variable to store the language selected value
  public selectedLanguage: string = "";

  // public currentLanguage:string = "";

  // Retrieve current language selected from local storage
  languageStatus:string = this.localStorageService.getItem('language');

  user: IUser;

  // Properties for managing view states
  themeStatusLogin: string =  "light";
  invalidLogin: boolean = true;
  emailExist: boolean = true;
  messageInvalidLogin: boolean = false;
  showModalEmail: boolean = false;
  emptyEmail: boolean = false;
  charactersChecked: boolean = false;
  lowerCaseChecked: boolean = false;
  upperCaseChecked: boolean = false;
  specialCharChecked: boolean = false;
  oneDigitChecked: boolean = false;
  showCheckBoxTip: boolean = true;
  showCheckBoxTipResetPw: boolean = false;
  charactersCheckmodel: boolean = false;
  lowerCaseCheckModel: boolean = false;
  upperCaseCheckModel: boolean = false;
  digitCheckModel: boolean = false;
  specialCharCheckModel: boolean = false;
  showPassword: boolean = false;
  showPasswordConfirmation: boolean = false;
  showOldPassword: boolean = false;
  loginActive: boolean = false;
  resetPasswordActive: boolean = false;
  userNewPasswordActive: boolean = false
  loginGenericErrorMessage:boolean = false;

  newPasswordFormActive: boolean = false;
  login: string ='';
  password: string ='';
  email: string = '';
  resetCode: string = '';
  message: string = 'error';
  forgotPassword: boolean = false;
  forgotPasswordForm: boolean = false;

  // Properties for managing user inputs
  requestLogin: string = '';
  responce: string = '';
  teste: boolean = false;
  name: string = ';'
  showErrorModal = false;
  emailIsNotEmpty = true;
  requestNewPassword: string = '';
  requestNewPasswordConfirmation: string = '';
  showAlertStrong: boolean = false;
  showAlertMedium: boolean = false;
  showAlertWeak: boolean = false;
  invalidEmail: boolean = false;
  newPasswordConfirmed: boolean = false;
  show: boolean = false;
  userdigit: boolean = false;
  userid: string = "a";

  // Properties of action put in url
  action: string | null;
  returnUrl: string | null = '';

  public MFACodeRequiredActive: boolean = false;
  public MFACodeSMS: string = '';
  public MFACodeTOTP: string = '';
  public MFACode: string = '';
  public wrongMFACode: boolean = false;
  public phone_number: string = '';
  public other_phone_number: string = '';
  public signUpMFAActive: boolean = false;
  public phoneAuthentificationActive: boolean = false;
  public nextTotpPage: boolean = false;

  public mfaChecked: boolean = false;
  @ViewChild('toggleCheckbox') toggleCheckbox!: ElementRef<HTMLInputElement>;
  @ViewChild('enterMFA', { static: false }) mfaCodeInput!: ElementRef<HTMLInputElement>;


  // variables used in the email validation form
  public emailValidationActive: boolean = false;
  public confirmationCode: string = '';

  // Variable that old the system message that throw to the user
  private systemMessage: string = '';

  public loading: boolean = false;

  public delayTImer: number = 3000;

  themeClass:string = '';

  constructor (
    private router: Router,
    private localStorageService: LocalStorageService,
    public cognitoService: CognitoService,
    @Inject(forwardRef(() => TranslateService)) @Inject(forwardRef(() => TranslateService)) private translate: TranslateService,
    private route: ActivatedRoute,
    private validate: ValidationService,
    public  theme: ThemeService,
    private systemMessageService: SystemMessageService
    ) {
      this.cognitoService.confirmValidUser();
      //get the action put in url
      this.action = this.route.snapshot.paramMap.get('action');
      this.returnUrl = this.route.snapshot.paramMap.get('return');

      this.user = {} as IUser;

      const userEdit = sessionStorage.getItem('filterComponent')

      this.themeClass = this.theme.getThemeClass();
     }

  ngOnInit(): void {
    //Check if an active user session exists
    this.cognitoService.getUser().then((response) => {
      if(response && this.action === null){
        this.router.navigate(['/dashboard']);
      }
    });
    // Set the variable qrCodeImgActive to false to be sure that the block <img> of the qr code is not display if there's nothing in
    this.cognitoService.qrCodeImgActive = false;
    // If action is change-password, we will disable the login display to show the display to change the password
    switch(this.action){
      case 'change-pw':
        this.newPasswordFormActive = true;
        this.loginActive = false;
        this.cognitoService.getUser().then((result) => {
          this.user.email = result.attributes.email;
        });
        break;

        case 'sign-up-MFA':
          // Set he variabe to show the sign-up to MFA page
          this.signUpMFAActive = true;
          // Set the variable to hide the login page
          this.loginActive = false;
          // Call the function to required the qr code from cognito
          this.getMFARegisterCodeTOTP();
          // Get the user from cognito
          this.cognitoService.getUser().then((result) => {
            const userObject = this.cognitoService.getUserMFAPreference();
            // Set the phone number to * and the last 4 digits of user phone number to hide the rest
            this.phone_number = '*******' + result.attributes.phone_number.slice(-4);
          });
          break;

        case 'authenticate-phone':
          // Set he variabe to show the phone number authenticate page
          this.phoneAuthentificationActive = true;
          // Set the variable to hide the login page
          this.loginActive = false;
          // Call the function to send an SMS to the user phone number
          this.cognitoService.sendSMS();
          // Set an information message to the user
          this.systemMessageService.selectRibbon('info', 'textMessageSendToUser');
          break;

      default:
        this.loginActive = true;
        break;
    }
    //this.phoneAuthentificationActive = true;
    // Fucntion triggered by cognito sevice when there is an error whit the MFA code provide by th euser
    this.cognitoService.wrongMFACode$.subscribe(() => {
      this.systemMessageService.selectRibbon('danger', 'wrongMFACode');
    });
  }

  ngAfterViewChecked():void{
    if (this.mfaCodeInput) {
      // Set focus to the input field
      this.mfaCodeInput.nativeElement.focus();
    }
  }

  // Change language based on user selection
  changeLanguage(language: string) {
    this.translate.use(language);
  }

  // Function call to set a time out
  async delay(): Promise<void> {
    return new Promise(resolve => {
      setTimeout(() => {
        resolve();
      }, this.delayTImer);
    });
  }


  // Handle form submission logic for regular login
  onSubmit() {
    let theme = localStorage.getItem('theme') ?? "light";
    theme = theme?.replaceAll(/["\\]/g, '') ?? "light"
    // Set theme in local storage to 'light'
    this.localStorageService.addItem('theme',  '"'+theme+'"');

    // Check if either login or password is empty
    if (this.user.email === "" || this.user.password === "") {
      this.systemMessage = 'passwordUsernameEmpty';
    }

    this.loading = true;
    this.loginActive = false

    //Call the aws service responsible for login.
    this.cognitoService.signIn(this.user).then(async (signedIn) => {
      if(signedIn){
        //Disable error messaging on successful login
        this.messageInvalidLogin = false;

          switch(signedIn){
            case 'change-password':
              this.loading = false;
              //First time sign-in requires a password change
              this.newPasswordFormActive = true; //Display Email confirmation form
              this.loginActive = false; //Hide login form
            break;

            case 'MFA-required':
              this.loading = false;
              // Set the variable to hide the login page
              this.loginActive = false;
              // Set the variable to show the MFA login page
              this.MFACodeRequiredActive = true;
            break;

            default:
              //Check if user has been signed in and an active session is opened.
                this.cognitoService.getUser().then(async (response: any)=>{
                    if(response){
                        const attributes = response.attributes;
                        this.localStorageService.addItem('user',  attributes.given_name + ' ' + attributes.family_name);
                        this.localStorageService.addItem('client',  attributes['custom:client_id']);
                        if(this.localStorageService.getItem("user").length !== 0){
                          this.cognitoService.getKeys();
                          await this.delay();
                          this.router.navigate(['/dashboard']);
                          this.loading = false;
                        }
                    }
                    else{
                      await this.delay();
                      this.loading = false;
                      this.loginActive = true;
                      // Set the system message to throw to the user
                      this.systemMessage = 'passwordUsernameIncorrect';
                      // throw the system message to the user
                      this.systemMessageService.selectRibbon('danger', this.systemMessage);
                    }
                }).catch(async (error)=>{
                  console.error("Error: ", error);
                  await this.delay();
                  this.loading = false;
                  this.loginActive = true;
                  // Set the system message to throw to the user
                  this.systemMessage = 'passwordUsernameIncorrect';
                  // throw the system message to the user
                  this.systemMessageService.selectRibbon('danger', this.systemMessage);
                });
            break;
          }
      }
      else{
        await this.delay();
        this.loading = false;
        this.loginActive = true;
        // this.router.navigate(['/dashboard']);
        this.systemMessage = 'passwordUsernameIncorrect';
        // throw the system message to the user
        this.systemMessageService.selectRibbon('danger', this.systemMessage);

      }
    }).catch(async (error) => {
      await this.delay();
      this.loading = false;
      this.loginActive = true
      // Set the system message to throw to the user
      this.systemMessage = 'passwordUsernameIncorrect';
      // throw the system message to the user
      this.systemMessageService.selectRibbon('danger', this.systemMessage);
      //for debug purposes we log the aws error. Must be commented for production
      console.error('Error signing in:', error);
      //prevent further code from running
      return false;
    });
  }

  // Handle "Forgot Password" link click
  forgotPasswordClick() {
    this.loading = true;
    //Hide error message on login form: Something went wrong, please try again.
    this.loginGenericErrorMessage = false;

    this.user.email = this.email
    // Set the loginActive flag to false to hide the login section
    this.loginActive = false;

    // Set the resetPasswordActive flag to true to show the reset password section
    this.resetPasswordActive = true;

    this.forgotPassword = true;
    this.loading = false;
  }

  // Handle the click event when the "Reset Password" button is clicked.
  async sendUserEmailClick() { // button reset password clicked
    // Check if email is empty.
    this.isEmptyEmail();

    if(!this.forgotPassword){
      // Update view states based on email validity and existence
      if (!this.emptyEmail && !this.invalidEmail && this.email === this.user.email ) {
        // Set the new password form to be active and the reset password form to be inactive
        if (this.showCheckBoxTipResetPw) {
          this.newPasswordFormActive = false;
          this.resetPasswordActive = false;
        } else {
          this.newPasswordFormActive = true;
          this.resetPasswordActive = false;
        }

      } else {
        // Set the system message to throw to the user
        this.systemMessage = 'loginForgotPasswordAlertEmailInvalid';
        // throw the system message to the user
        this.systemMessageService.selectRibbon('danger', this.systemMessage);
        // Set the new password form to be inactive and the reset password form to be active
        this.newPasswordFormActive = false;
        this.resetPasswordActive = true;
      }
    }
    else{
      //Set user object property
      this.user.email = this.email;

      //Call Cognito Service
      //Set user object property
      this.user.email = this.email

      if (!this.emptyEmail && !this.invalidEmail && this.email === this.user.email ) {
        //Call Cognito Service
        let sendEmail = await this.cognitoService.forgotPassword(this.email);
        // let sendEmail = true;

        if (sendEmail) {

         // Display forgot Password Form
          this.forgotPasswordForm = true;

          // Hide email form
          this.resetPasswordActive = false;

          //reset forgot password state
          this.forgotPassword = false;

        } else {
          // Hide email form
          this.resetPasswordActive = false;

          //reset forgot password state
          this.forgotPassword = false;

          //Back to login form
          this.loginActive = true;

          // Set the system message to throw to the user
          this.systemMessage = 'cognitoLoginErrorMessage';
          // throw the system message to the user
          this.systemMessageService.selectRibbon('danger', this.systemMessage);
        }
      }
    }
  }

  // Funciton that is triggered when user confirm the password reset
  async submitPasswordReset(){
    this.loading = true;

    if(this.validate.strongPassword.test(this.user.password)){

      //Send change request to Amazon
      var passwordReset = await this.cognitoService.resetPassword(this.user.email, this.user.code, this.user.password);
      //  var passwordReset = false;

      if(passwordReset){ //on success
        //Hide reset form here
        this.forgotPasswordForm = false;
        this.resetPasswordActive = false;

        //Show Login Form
        this.loginActive = true;
        //Hide error message on login form: Something went wrong, please try again.
        this.loginGenericErrorMessage = false;
        await this.delay();
        this.loading = false;
      }
      else{
          // Active Login View
          this.loginActive = true;

          // Desactive forgotPasswordForm view
          this.forgotPasswordForm = false;

          // Desactive resetPasswordActive view
          this.resetPasswordActive = false;

          // Desactive newPasswordFormActive view
          this.newPasswordFormActive = false;

          //Display error message on login form: Something went wrong, please try again.
          this.loginGenericErrorMessage = true;

          this.loading = false;
      }
    }
    else{
      this.loading = false;
      this.showCheckBoxTipResetPw = true;
    }
    this.loading = false;
  }

  // Check if the email is empty and set the appropriate flag
  isEmptyEmail() {
    if (this.email === "") {
      // If the email is empty, set the emptyEmail flag to true
      this.emptyEmail = true;
    } else{
      if(this.user.email === "" || this.user.email ===  null){
        this.user.email = this.email;
      }
      this.emptyEmail = false; // If the email is not empty, set the emptyEmail flag to false
    }
  }

  // Handle the click event when the "Confirm New Password" button is clicked.
  async confirmNewPasswordClick() {

    // Check if the new password matches the strong password criteria
    if(this.validate.strongPassword.test(this.requestNewPassword)) {
      try {
        if(this.action === 'change-password'){
          this.cognitoService.challengeName = '';
        }
        //Cognito functions to handle password change. Returns a False or Object
        const passwordChanged = await this.cognitoService.changePassword(this.user, this.requestNewPassword);

        if (passwordChanged) {

          const filterComponent = sessionStorage.getItem('filterComponent');

          if(filterComponent === 'userEdit') {
            sessionStorage.removeItem("from");
            this.emailValidationActive = false;
            sessionStorage.setItem("action", "updated");
            this.router.navigate(['/user-edit']); // Return to admin component
          } else {
            // Password was successfully changed, Display login form, hide all other forms.
            this.resetPasswordActive = false; //Verify Login Email Form
            this.newPasswordFormActive = false; //New Password Form
            this.user.password = "";
            this.emailValidationActive = true;
            this.systemMessageService.selectRibbon('info', 'emailValidationForCognito');
          }

        } else {
          this.loginActive = true;
          //Display error message on login form: Something went wrong, please try again.
          this.loginGenericErrorMessage = true;
        }
      } catch (error) {
        // Active the Login view
        this.loginActive = true;
        //Display error message on login form: Something went wrong, please try again.
        this.loginGenericErrorMessage = true;
        console.error('Error changing password:', error);
      }
    }
  }

  // Function called to validate the user email address in cognito
  async emailValidate(){
    // Call the function in cognito service to validate the email address
    const response = await this.cognitoService.validateEmail(this.confirmationCode);
    // Cognito return a success message
    if(response === undefined){
      // Activate the login form and deactivate the email validation form
      this.loginActive = true;
      this.emailValidationActive = false;
      // Throw a success message to the user
      this.systemMessageService.selectRibbon('success', 'validationEmailSuccess');
    } else {
      // Throw an error message to the user
      this.systemMessageService.selectRibbon('danger', 'alert-danger-generic-message');
    }
  }

  // Function called to validate the user phone number in cognito
  async phoneNumberValidate(){
    // Call the function in cognito service to validate thephone number
    const response = await this.cognitoService.validatePhoneNumber(this.confirmationCode);
    // Cognito return a success message
    if(response === undefined){
      // Deactivate the phone validation form and redirect the user to the user-edit component
      this.phoneAuthentificationActive = false;
      this.router.navigate(['/user-edit']);
      // Throw a success message to the user
      this.systemMessageService.selectRibbon('success', 'alert-success-generic-message');
    } else {
      // Throw an error message to the user
      this.systemMessageService.selectRibbon('danger', 'alert-danger-generic-message');
    }
  }

  // Validate and display password strength
  validatePassword(): void {
    const password = this.requestNewPassword;
    const password2 = this.user.password;

    // Reset flags and checkboxes
    this.showAlertStrong = false;
    this.showAlertMedium = false;
    this.showAlertWeak = false;
    this.showCheckBoxTip = false;

    // Check password from new password view against strong and medium regex patterns
    if (this.validate.strongPassword.test(password)) {
      this.showAlertStrong = true;
      this.showCheckBoxTip = false;
    } else if (this.validate.strongPassword.test(password)) {
      this.showAlertMedium = true;
      this.showCheckBoxTip = true;
    } else if (password.length >= 1) {
      this.showAlertWeak = true;
      this.showCheckBoxTip = true;
    }

    // Check password from new password with reset code view against strong and medium regex patterns
    if (this.validate.strongPassword.test(password2)) {
      //Hide error message on login form: Something went wrong, please try again.
      this.loginGenericErrorMessage = false;
    }

    // Update checkbox and flag values based on password from new password view characteristics
    this.charactersCheckmodel = password.length >= 8;
    this.charactersChecked = this.charactersCheckmodel;

    this.lowerCaseCheckModel = this.hasLowerCase(password);
    this.lowerCaseChecked = this.lowerCaseCheckModel;

    this.upperCaseCheckModel = this.hasUpperCase(password);
    this.upperCaseChecked = this.upperCaseCheckModel;

    this.digitCheckModel = this.hasDigit(password);
    this.oneDigitChecked = this.digitCheckModel;

    this.specialCharCheckModel = this.hasSpecialChar(password);
    this.specialCharChecked = this.specialCharCheckModel;

    if (this.forgotPasswordForm) {
      // Update checkbox and flag values for password2 if the forgotPasswordForm is present
      this.charactersCheckmodel = password2.length >= 8;
      this.charactersChecked = this.charactersCheckmodel;

      this.lowerCaseCheckModel = this.hasLowerCase(password2);
      this.lowerCaseChecked = this.lowerCaseCheckModel;

      this.upperCaseCheckModel = this.hasUpperCase(password2);
      this.upperCaseChecked = this.upperCaseCheckModel;

      this.digitCheckModel = this.hasDigit(password2);
      this.oneDigitChecked = this.digitCheckModel;

      this.specialCharCheckModel = this.hasSpecialChar(password2);
      this.specialCharChecked = this.specialCharCheckModel;
    }
  }

  // Check email validity
  checkEmail() {
    //Set user object property
    this.user.email = this.email;

    // Regular expression to validate email format
    var validation = new RegExp("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$");

    // Set userdigit flag to true
    this.userdigit = true;

    // Check if email is empty
    this.isEmptyEmail();

    // Check if userdigit is true and email format is not valid
    if (this.userdigit && !validation.test(this.user.email)) {
      this.invalidEmail = true;
      this.emailExist = true;
    } else this.invalidEmail = false; // Reset invalidEmail flag if email format is valid

    // Reset invalidEmail flag if email is empty
    if (this.emptyEmail) {
      this.invalidEmail = false;
    }
  }

  /**
   * Check if a string contains at least one lowercase character.
   * @param str The input string.
   * @returns True if the input string contains a lowercase character, otherwise false.
  */
  hasLowerCase(str:string) {
    return /[a-z]/.test(str)
  }

  /**
   * Check if a string contains at least one uppercase character.
   * @param str The input string.
   * @returns True if the input string contains an uppercase character, otherwise false.
  */
  hasUpperCase(str:string) {
    return /[A-Z]/.test(str)
  }

  /**
   * Check if a string contains at least one digit.
   * @param str The input string.
   * @returns True if the input string contains a digit, otherwise false.
  */
  hasDigit(str:string){
    return /\d/.test(str)
  }

  /**
   * Check if a string contains at least one special character.
   * Special characters include !@#$%^&*(),.?":{}|<>
   * @param str The input string.
   * @returns True if the input string contains a special character, otherwise false.
  */
  hasSpecialChar(str:string) {
    return /[!@#$%^&*(),.?":{}|<>]/.test(str)
  }

  // Toggle the visibility of the password input's content.
  togglePasswordVisibility(from: string) {
    if(from === 'password'){
      let eyeIcon = document.getElementById("eyeIconPassword")
      this.showPassword = !this.showPassword;
      if (this.showPassword) {
        eyeIcon?.classList.remove('fa-eye-slash');
        eyeIcon?.classList.add('fa-eye');
      } else {
        eyeIcon?.classList.add('fa-eye-slash');
        eyeIcon?.classList.remove('fa-eye');
      }
    }
    if(from === 'confirmPassword'){
      let eyeIcon = document.getElementById("eyeIconConfirmPassword")
      this.showPasswordConfirmation = !this.showPasswordConfirmation;
      if (this.showPasswordConfirmation) {
        eyeIcon?.classList.remove('fa-eye-slash');
        eyeIcon?.classList.add('fa-eye');
      } else {
        eyeIcon?.classList.add('fa-eye-slash');
        eyeIcon?.classList.remove('fa-eye');
      }
    }

  }

  // Function triggered when user cancel and he's redirected to the page he came from
  cancelHandler(){
    if(this.returnUrl === '' || this.returnUrl === null){
      this.router.navigate(['/login']);
    }else{
      this.router.navigate(['/' + this.returnUrl]);
    }
  }

  // Function called with the submit button in the sign-up MFA window
  submitMFACodeClick(){
    // this.mfaChecked is the variable that UI use to know if the user want the SMS_MFA or TOTP_MFA then go to the proper function
    if(this.mfaChecked){
      // Call the function that will sign-up with SMS MFA code
      this.cognitoService.signUpMFACodeSMS(this.MFACodeSMS);
    }else{
      // Call the function that will sign-up with TOTP MFA code
      this.cognitoService.singUpMFACodeTOTP(this.MFACodeTOTP);
    }
  }

  // Function called to sign-in when a user get MFA setting active
  async MFASignIn(){
    this.loading = true;
    this.nextTotpPage = false;
    this.loginActive = false;
    this.MFACodeRequiredActive = false;
    // Call the cognito service to sign-in
    await this.cognitoService.MFASignIn(this.user, this.MFACode).then((response) => {
      setTimeout(()=>{
        if(response !== 'no-MFA-sign-in'){

          // If return response from cognito is not an error the authentification have success so we get the cognito user
          this.cognitoService.getUser().then((response)=>{
            this.MFACodeRequiredActive = true;
            this.loading = false
            if(response){
              // If we get a user set the local strorage for the user and client he's in
              this.localStorageService.addItem('user',  response.attributes.given_name + ' ' + response.attributes.family_name);
              this.localStorageService.addItem('client',  response.attributes['custom:client_id']);
              if(this.localStorageService.getItem("user").length !== 0){
                this.loading = true;
                this.MFACodeRequiredActive = false;
                this.cognitoService.getKeys();

                setTimeout(()=>{
                  //Route to dashboard upon success
                  this.router.navigate(['/dashboard']);},4000)
              }
            }
          }).catch((error)=>{
            console.error("Error in getting user : ", error);
            this.loading = false
            this.MFACodeRequiredActive = true;
          });
        }
        else {
          this.loading = false;
          this.MFACodeRequiredActive = true;
        }
      },
      1000
      )
    });
  }

  /////////////// Function not in use yet but once we get access to sms from cognito it will ///////////////////////////
  // Function called in the MFA sign-up window when user trigger between SMS on TOTP page
  toggleMFAChangePage(){
    // Variable set to true when SMS MFA page is open and false when TOTP
    // Commentted for now because we can't receive SMS form cognito
    //this.mfaChecked = !this.mfaChecked;
  }

  // Function called in the sign-up MFA window when user click on the send button to get the QR code from cognito
  getMFARegisterCodeTOTP(){
    this.cognitoService.getQrCode();
  }

  // Function called to go back to user-edit
  backToEditPage(){
    this.router.navigate(['/user-edit'])
  }

} //End of Component



