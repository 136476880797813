<!-- Container -->
<div [ngClass]="theme.getThemeClass()" class="chart-container noMargin">
  <!-- Chart -->
  <ngx-charts-pie-grid
  [designatedTotal]="charts.totalPieChart"
  [view]="charts.viewPieChart"
  [scheme]="charts.colorSchemePieChart"
  [results]="charts.singlePieChart"
  (select)="charts.onSelectPieChart($event)"
  (activate)="charts.onActivatePieChart($event)"
  (deactivate)="charts.onDeactivatePieChart($event)"
  >
  </ngx-charts-pie-grid>
</div>

