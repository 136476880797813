import { ModalService } from './../service/device-modal.service';
import {
  Component,
  DoCheck,
  ElementRef,
  Inject,
  OnInit,
  ViewChild,
  forwardRef,
} from '@angular/core';
import { LocalStorageService } from '../local-storage.service';
import { TranslateService } from '@ngx-translate/core';
import { CognitoService } from '../service/cognito.service';
import { Devices, IotService, Collection, CollectionCount } from '../service/iot.service';
import { ThemeService } from '../service/theme.service';
import { SystemMessageService } from '../service/system-message.service';
import { RoleService } from '../service/role.service';
import { environment } from '../environments/environment';
import { lastValueFrom } from 'rxjs';
import { DashboardService } from '../service/dashboard.service';
import { CsvService } from '../service/csv.service';
import { Router } from '@angular/router';
import { ChartService } from '../service/chart.service';
import { PdfService } from '../service/pdf.service';
import { Bin, ReportsService } from '../service/reports.service';
import { BinUsage } from '../constants/bin-usage';
import { FilterService } from '../service/filter.service';
import { DeviceConfig } from '../service/iot.service';


export interface ThingList {
  thingName: string;
  status?: string;
  battery_percentage?: string;
  fill_level?: string;
}

interface TimestampObject {
  timestamp: number;
}

interface ClientThings {
  thingName: string;
  icon?: string;
  status: string;
  battery: number;
  fillLevel: string;
  hasBin: boolean;
  binCapacity: number;
  distance: number;
  location: string;
  binId: string;
  lastUpdate: number;
  heartbeat?: {
    beat_miss?: number;
    supose_to_communicate?: number;
    color?: string
  }
  sleep_time?: number;
  device?: Devices;
}

@Component({
  selector: 'app-client-thing-list',
  templateUrl: './client-thing-list.component.html',
  styleUrls: [
    './client-thing-list.component.css',
    '../../dashboard-tiles.css',
    '../../global-elements.css',
  ],
})
export class ClientThingListComponent implements OnInit, DoCheck {
  @ViewChild('usageCanvas') usageCanvas!: ElementRef;
  @ViewChild('marketCanvas') marketCanvas!: ElementRef;
  @ViewChild('countCanvas') countCanvas!: ElementRef;
  @ViewChild('tonnageCanvas') tonnageCanvas!: ElementRef;

  // Retrieve current language selected from local storage
  languageStatus: string = this.localStorageService.getItem('language');

  public collectionData: Collection[] = [];
  public collectionCountData: CollectionCount[] = [];

  public binData: Bin = {
    above_ground: 0,
    active: 0,
    bin_address: '',
    bin_depth: 0,
    bin_height: 0,
    bin_id: '',
    bin_gps: '',
    bin_location: '',
    bin_model_number: '',
    bin_postal_code: '',
    bin_shape: '',
    bin_usage: '',
    bin_width: 0,
    client_id: '',
    distributor_id: '',
    thing_name: '',
    bin_volume: 0,
  };

  public loading: boolean = false;
  public dataLoaded: boolean = false;
  public filteredArray: any[] = [];
  public userFilter: string = '';
  public statusFilter: string = '';
  public filterBy: string = '';
  public sortBy: string = '';
  public ascDesc: number | boolean = 1;
  public originalArray: any[] = [];

  // For info bubble
  public showStateInfo: boolean[] = [];

  public startDate: any = this.reports.getFirstDateOfTheCurrentYear();
  public endDate: any = this.reports.getCurrentDate();
  public tonnage: number = 0;

  constructor(
    private localStorageService: LocalStorageService,
    @Inject(forwardRef(() => TranslateService))
    private translate: TranslateService,
    private cognitoService: CognitoService,
    public iotService: IotService,
    public theme: ThemeService,
    public systemMessage: SystemMessageService,
    public dashboardService: DashboardService,
    public modal: ModalService,
    public csvExportService: CsvService,
    public roleService: RoleService,
    public charts: ChartService,
    public pdf: PdfService,
    public reports: ReportsService,
    private router: Router,
    private filter: FilterService
  ) {

    this.cognitoService.confirmValidUser();
    // Check if the user has selected a language in local storage
    //or use a default language
    if (this.languageStatus == null) {
      // Set the default language to French
      translate.use('fr');
    } else {
      // Set the default language to the user's selected language
      translate.use(this.languageStatus);
    }
  }



  showReportModal() {
    this.reports.showReportModal = true;
  }

  ngAfterViewInit(): void {
    this.pdf.tickFontSize = 14;
    this.pdf.legendFontSize = 10;
    this.pdf.usageCanvas = this.usageCanvas;
    this.pdf.marketCanvas = this.marketCanvas;
    this.pdf.countCanvas = this.countCanvas;
    this.pdf.tonnageCanvas = this.tonnageCanvas;
  }

   // Function that will calculate the volume if all field are well filled
   calculateCubicMeters(binVolume: number, dst: number, binHeight: number) {
    // Calculate the multiplier based on the given formula
    const multiplier = (1 - (dst / binHeight));
    // Calculate the result - then return the calculated cubic meters
    return binVolume / 1000 * multiplier;
  }

  //Get the average weight for the bin usage type
  getWeightByValue(value: string): number {
    const bin = BinUsage.find((bin) => bin.value === value);
    return bin?.weight || 0;
  }

  //Calculate the total waste collected
  calculateWasteCollection(usage: string, totalCubicMetersOfWaste: number): number {

    // Get the weight per cubic meter based on the given usage
    const weightPerCubicMeter = this.getWeightByValue(usage);

    // Check if the inputs are valid and return the calculated total waste collection
    if (!isNaN(totalCubicMetersOfWaste) && weightPerCubicMeter !== undefined) {
      return totalCubicMetersOfWaste * weightPerCubicMeter;
    }
    else {
      return 0; // Return 0 if inputs are not valid
    }
  }

  async ngOnInit(): Promise<void> {
    this.iotService.thingStatusArray = [];
    this.filteredArray = [];
    this.loading = true;

    this.reports.showReportModal = false;
    // Get the relationships
    await this.dashboardService.getRelationships();
    await this.cognitoService.getUserType();
    this.dashboardService.things =
      await this.dashboardService.getThingNamesByEntityId(
        this.cognitoService.clientId
      );
    this.resetDataStatus();

    // verify user
    await this.cognitoService.confirmValidUser();

    //Verifies that the current User is allowed to view this component
    await this.cognitoService.getCurrentRole(
      [environment.users.role.administrator, environment.users.role.client],
      [environment.users.standardUser]
    ); //Role Check

    const from = sessionStorage.getItem('from')

    if(from){
      switch(from){
        case 'bin-update':
          this.systemMessage.selectRibbon('success', 'binModelCreateViewAlertSuccessUpdate');
        break;
        // Default case
        default:
        break;

      }
      // Remove 'from' from sessionStorage
      sessionStorage.removeItem('from');

    }

    await this.initData();

    setTimeout(() => {
      this.loading = false;
    },2000);
  }

  // Function called to check if user came from device config modal then show a success message
  ngDoCheck(){
    const configUpdate = sessionStorage.getItem('device-config-modal')
    if (configUpdate){
      this.systemMessage.selectRibbon('success', configUpdate);
      sessionStorage.removeItem('device-config-modal');
    }
  }

  // Function to sort the array of items by the "binFillLevel" property
  // applySorting: Optional parameter to conditionally apply sorting (default is true)
  sortItemsByFillLevel(applySorting: boolean = true) {
    // Check if sorting should be applied and if the current sorting option is "binFillLevel"
    if (applySorting && this.sortBy === 'binFillLevel') {
      // Use the sort method to rearrange the items in the filteredArray
      this.filteredArray.sort((a, b) => {
        // Sort in ascending or descending order based on the checkbox
        const sortOrder = this.ascDesc ? 1 : -1;

        // If the status is "Inactive", move it to the end, regardless of fillLevel
        if (a.status === 'Inactive' && b.status !== 'Inactive') {
          return 1;
        } else if (a.status !== 'Inactive' && b.status === 'Inactive') {
          return -1;
        }

        // Parse the string values into numeric values for fillLevel
        const aFillLevel = parseFloat(a.fill_level.replace('%', ''));
        const bFillLevel = parseFloat(b.fill_level.replace('%', ''));

        // Compare the "fillLevel" properties of items
        if (aFillLevel < bFillLevel) {
          return -1 * sortOrder;
        } else if (aFillLevel > bFillLevel) {
          return 1 * sortOrder;
        } else {
          // If fillLevel values are equal, maintain the existing order
          return 0;
        }
      });
    }
  }

  // Function to sort the array of items by the "lastUpdate" property
  // applySorting: Optional parameter to conditionally apply sorting (default is true)
  sortItemsByLastUpdate(applySorting: boolean = true) {
    // Check if sorting should be applied and if the current sorting option is "lastUpdate"
    if (applySorting && this.sortBy === 'lastUpdate') {
      // Use the sort method to rearrange the items in the filteredArray
      this.filteredArray.sort((a, b) => {
        // Sort in ascending or descending order based on the checkbox
        const sortOrder = this.ascDesc ? 1 : -1;

        // Compare the "lastUpdate" properties (timestamps) of items
        if (a.last_update < b.last_update) {
          return -1 * sortOrder;
        } else if (a.last_update > b.last_update) {
          return 1 * sortOrder;
        } else {
          // If lastUpdate values are equal, maintain the existing order
          return 0;
        }
      });
    }
  }

  // Function to sort the array of items by the "battery" property
  // applySorting: Optional parameter to conditionally apply sorting (default is true)
  sortItemsByBattery(applySorting: boolean = true) {
    // Check if sorting should be applied and if the current sorting option is "battery"
    if (applySorting && this.sortBy === 'battery') {
      // Use the sort method to rearrange the items in the filteredArray
      this.filteredArray.sort((a, b) => {
        // Sort in ascending or descending order based on the checkbox
        const sortOrder = this.ascDesc ? 1 : -1;

        // If the status is "Inactive", move it to the end, regardless of battery value
        if (a.status === 'Inactive' && b.status !== 'Inactive') {
          return 1;
        } else if (a.status !== 'Inactive' && b.status === 'Inactive') {
          return -1;
        }

        // Compare the "battery" property of items
        if (a.battery < b.battery) {
          return -1 * sortOrder;
        } else if (a.battery > b.battery) {
          return 1 * sortOrder;
        } else {
          // If battery values are equal, maintain the existing order
          return 0;
        }
      });
    }
  }

  // Function called when there is a change in the sorting option or checkbox
  onSortChange(trigger: string) {
    // Toggle between ascending and descending order
    this.ascDesc = !this.ascDesc;

    // Use a switch statement to handle different sorting options
    switch (this.sortBy) {
      case 'battery':
        // Call the sorting function for battery
        this.sortItemsByBattery();
        break;

      case 'binFillLevel':
        // Call the sorting function for bin fill level
        this.sortItemsByFillLevel();
        break;

      case 'lastUpdate':
        // Call the sorting function for last update
        this.sortItemsByLastUpdate();
        break;

      // Add more cases for additional sorting options if needed

      default:
        // Handling for cases not covered by the existing options
        break;
    }
  }

  /**Function to retrieve thing names associated with a given client ID
  * @param clientId - The client ID for which to fetch thing names
  * @returns Returns an array of matching thing names **/
  getThingNamesByClientId(clientId: string): string[] {
    // Array to store matching thing names
    const matchingThingNames: string[] = [];

    // Iterate through relationships in the dashboard service
    this.dashboardService.relationships.forEach((item) => {
      // Check if the client ID matches the specified client ID
      if (item.client_id === clientId) {
        // Add the thing name to the array if the client ID matches
        matchingThingNames.push(item.thing_name);
      }
    });

    // Return the array of matching thing names
    return matchingThingNames;
  }

  // Function that reset all array
  resetDataStatus() {
    this.filteredArray = [];
  }

  // Asynchronous function to navigate to the update route for a specific bin
  async routeToUpdateBin(binId: string) {
    // Add the 'deviceList' item to the localStorageService
    this.localStorageService.addItem('lastUrl', 'deviceList');

    // Navigate to the 'bin-update' route with the specified binId
    this.router.navigate(['bin-update', binId]);
  }

  /**
   * Asynchronously initializes the data for the dashboard.
   */
  async initData() {
    try {
      // Set a new array of things that will get only thing_names in
      let things: any = [];

      // Set the things array with the map of the distributo device array to get only the thing_name in an array
      things = this.dashboardService.things;

      const data = await this.iotService.getDeviceHealth(things);
      this.iotService.thingStatusArray = JSON.parse(data.status);

       // Convert start and end dates to Unix timestamps
       const start = this.dateToUnixTimestamp(this.startDate);
       const end = this.dateToUnixTimestamp(this.endDate + 'T23:59:59');

       (await this.reports.getReportData(things, start, end)).subscribe(
         (res: any) => {
           this.collectionData = JSON.parse(res.collections);
           this.collectionCountData = JSON.parse(res.counts);
           // Iterate through filtered items
           for (const filteredItem of this.iotService.thingStatusArray) {

             // Retrieve configurations, bin data, and shadows for the current filtered item
             const configs: DeviceConfig[] = JSON.parse(data.configs).filter(
               (item: DeviceConfig) => item.thing_name === filteredItem.thing_name
             );
             const bins: any[] = JSON.parse(data.bins).filter(
               (item: any) => item.thing_name === filteredItem.thing_name
             );
             const [bin] = bins || [];
             const shadows = JSON.parse(data.shadows).filter(
               (item: any) => item.thing_name === filteredItem.thing_name
             );
             const [shadow] = shadows || [];
             const device = this.dashboardService.getDevicesStructure(
               shadow,
               bin,
               configs
             );

             // Retrieve report data asynchronously
             const collectionData = this.iotService.filterCollectionsByThingName(this.collectionData, filteredItem.thing_name);
             const counts = this.iotService.filterCollectionsCountsByThingName(this.collectionCountData, filteredItem.thing_name)

             // Update bin data
             this.binData.bin_volume = bin.total_volume || 0;
             this.binData.bin_height = bin.bin_height || 0;
             this.binData.bin_usage = bin.bin_usage ?? '';

             let cubicMetersOfWasteTotal = 0;

             // Iterate through collection data
             for (let index = 0; index < collectionData.length; index++) {
               // Calculate cubic meters of waste total
               cubicMetersOfWasteTotal += this.calculateCubicMeters(
                 this.binData.bin_volume,
                 counts?.avg_preceding_dst ?? 0,
                 this.binData.bin_height
               );
             }

             // Calculate tonnage
             const tonnage =
               this.calculateWasteCollection(
                 this.binData.bin_usage,
                 cubicMetersOfWasteTotal // Usar a cubagem total para calcular a tonelagem
               ) * 0.001;

             // Update tonnage
             this.tonnage = parseFloat(tonnage.toFixed(1)); // Atribuir a tonelagem total

             let distance_to_lid = filteredItem.distance_to_lid;

             // Check if necessary data is available for further processing
             if (bin && distance_to_lid && device.config?.slp) {
               let object = {
                 tonnage: this.tonnage,
                 battery: filteredItem.battery,
                 distance_to_lid: distance_to_lid,
                 last_update: filteredItem.last_update,
                 status: filteredItem.status,
                 thing_name: filteredItem.thing_name,
                 bin_capacity: bin.bin_height,
                 location: bin.bin_address ?? '',
                 fill_level: this.iotService.checkFillLevelPercentage(
                   distance_to_lid,
                   bin.bin_height
                 ),
                 bin_id: bin.bin_id ?? '',
                 heartbeat: this.iotService.verifyDeviceHearthBeat(
                   this.iotService.filterDeviceConfig(
                     configs,
                     shadow.thing_name,
                     'last'
                   ),
                   this.iotService.filterDeviceConfig(
                     configs,
                     shadow.thing_name,
                     'second_last'
                   ),
                   shadow.dst_timestamp,
                   shadow.tm2
                 ),
                 sleep_time: device.config?.slp ?? 0,
                 device: device,
               };
               // Push filtered object to array
               this.filteredArray.push(object);

               // Reset tonnage and bin data
               this.tonnage = 0;
               this.binData.bin_volume = 0;
               this.binData.bin_height = 0;
               this.binData.bin_usage = '';
             }
           }
         }
       );
      //});
      // Define the order of status priority
      const statusOrder = ['Healthy', 'Unhealthy', 'Inactive'];

      // Sort the array based on the status order
      this.filteredArray.sort((a, b) => {
        const statusA = statusOrder.indexOf(a.status);
        const statusB = statusOrder.indexOf(b.status);

        return statusA - statusB;
      });
    } catch (error) {
        console.error('Error initializing data:', error);
    }

    // Preserve the original state of filteredArray for reference
    this.originalArray = this.filteredArray;

    this.dataLoaded = true; // Set dataLoaded to true when data initialization is complete
  }

  // Function to convert an array of timestamps to an array of formatted date strings
  // timestamps: An array of TimestampObject containing timestamp values
  // Returns a tuple with two formatted date strings
  convertTimestampsArrayToDates(
    timestamps: TimestampObject[]
  ): [string, string] {
    // Check for valid input: the array should have exactly two timestamps,
    // and both timestamps should be valid (non-NaN) and non-negative.
    if (
      timestamps.length !== 2 ||
      isNaN(timestamps[0].timestamp) ||
      isNaN(timestamps[1].timestamp) ||
      timestamps[0].timestamp < 0 ||
      timestamps[1].timestamp < 0
    ) {
      throw new Error('Invalid timestamps provided');
    }

    // Initialize an array to store formatted date strings with default empty values
    const dates: [string, string] = ['', ''];

    // Convert each timestamp to a formatted date string
    for (let i = 0; i < timestamps.length; i++) {
      const timestamp = timestamps[i].timestamp;

      // Convert timestamp to date and format it as a string
      const date = new Date(timestamp * 1000).toLocaleString();

      // Store the formatted date string in the dates array
      dates[i] = date;
    }

    // Return the tuple containing two formatted date strings
    return dates;
  }

  // Function to convert a timestamp to a formatted date string
  // timestamp: A timestamp value to be converted
  // Returns a formatted date string
  convertTimestampToDate(timestamp: number): string {
    // Check for valid input: the timestamp should be a valid (non-NaN) and non-negative number
    if (isNaN(timestamp) || timestamp < 0) {
      throw new Error('Invalid timestamp provided');
    }

    // Convert the timestamp to a Date object and format it as a string
    const date = new Date(timestamp * 1000).toLocaleString();

    // Return the formatted date string
    return date;
  }

  // Async function to export data to CSV
  async exportCSV(thing: any) {
    // Get device shadow information using IoT service
    const shadow = await this.iotService.getDeviceShadow(thing.thing_name);

    // Convert timestamp to date format
    let timestampTest = shadow.timestamp * 1000;
    let date = new Date(timestampTest).toLocaleString();

    // Set CSV file name using the thing_name property
    const fileName = `${thing.thing_name}.csv`;

    // Extract properties from the 'thing' object for CSV data

    // from metadata
    const metadataFirmwareVersion: string =
      this.convertTimestampToDate(shadow.metadata.reported.dat.fdv.timestamp) ||
      'No Data Available';
    const metadataMcuTemperature: string =
      this.convertTimestampToDate(shadow.metadata.reported.dat.tmp.timestamp) ||
      'No Data Available';
    const metadataSignalStrength: string =
      this.convertTimestampToDate(shadow.metadata.reported.dat.sig.timestamp) ||
      'No Data Available';
    const metadataVccMeasurement: string =
      this.convertTimestampToDate(shadow.metadata.reported.dat.vcc.timestamp) ||
      'No Data Available';
    const metadataBatteryVoltageRemaining: string =
      this.convertTimestampToDate(shadow.metadata.reported.dat.bat.timestamp) ||
      'No Data Available';
    const metadataDistanceToLid: string =
      this.convertTimestampToDate(shadow.metadata.reported.dat.dst.timestamp) ||
      'No Data Available';
    const metadataGpsTimestamps = shadow.metadata.reported.dat.gps;
    const metadataGps: string[] = metadataGpsTimestamps
      ? this.convertTimestampsArrayToDates(metadataGpsTimestamps) || [
          'No Data Available',
          'No Data Available',
        ]
      : ['No Data Available', 'No Data Available'];
    const metadataTemperatureZero: string =
      this.convertTimestampToDate(shadow.metadata.reported.dat.tm0.timestamp) ||
      'No Data Available';
    const metadataNewtworkConnectTime: string =
      this.convertTimestampToDate(shadow.metadata.reported.dat.nct.timestamp) ||
      'No Data Available';
    const metadataAtmosphericPressure: string =
      this.convertTimestampToDate(shadow.metadata.reported.dat.pre.timestamp) ||
      'No Data Available';
    const metadataTemperature: string =
      this.convertTimestampToDate(shadow.metadata.reported.dat.tm2.timestamp) ||
      'No Data Available';
    const metadataHumidity: string =
      this.convertTimestampToDate(shadow.metadata.reported.dat.hum.timestamp) ||
      'No Data Available';
    const timestampPHN = shadow?.metadata?.reported?.car?.phn?.timestamp;

    const metadataPHN: string = timestampPHN
      ? this.convertTimestampToDate(timestampPHN)
      : 'No Data Available';

    const timestampIMEI = shadow?.metadata?.reported?.car?.imei?.timestamp;

    const metadataIMEI: string = timestampIMEI
      ? this.convertTimestampToDate(timestampIMEI)
      : 'No Data Available';

    const metadataFMV: string = shadow?.metadata?.reported?.car?.fmv?.timestamp
      ? this.convertTimestampToDate(shadow.metadata.reported.car.fmv.timestamp)
      : 'No Data Available';

    const timestampICC = shadow?.metadata?.reported?.car?.icc?.timestamp;
    const metadataICC: string = timestampICC
      ? this.convertTimestampToDate(timestampICC)
      : 'No Data Available';

    const timestampOPER = shadow?.metadata?.reported?.car?.oper?.timestamp;
    const metadataOPER: string = timestampOPER
      ? this.convertTimestampToDate(timestampOPER)
      : 'No Data Available';

    const timestampHWI = shadow?.metadata?.reported?.car?.hwi?.timestamp;
    const metadataHWI: string = timestampHWI
      ? this.convertTimestampToDate(timestampHWI)
      : 'No Data Available';

    const timestampDst = shadow?.metadata?.reported?.dst?.timestamp;

    const metadataDst: string = timestampDst
      ? this.convertTimestampToDate(timestampDst)
      : 'No Data Available';

    const timestampWelcome = shadow?.metadata?.reported?.welcome?.timestamp;

    const metadataWelcome: string = timestampWelcome
      ? this.convertTimestampToDate(timestampWelcome)
      : 'No Data Available';

    // from reported
    const reportedFirmwareVersion: string =
      shadow.state.reported.dat.fdv || '0.0.0';
    const reportedMcuTemperature: number = shadow.state.reported.dat.tmp ?? 0;
    const reportedSignalStrength: number = shadow.state.reported.dat.sig ?? 0;
    const reportedVccMeasurement: number = shadow.state.reported.dat.vcc ?? 0;
    const reportedBatteryVoltageRemaining: number =
      shadow.state.reported.dat.bat ?? 0;
    const reportedDistanceToLid: number = shadow.state.reported.dat.dst ?? 0;
    const reportedGps: number[] = shadow.state.reported.dat.gps || [0, 0];
    const reportedTemperatureZero: number = shadow.state.reported.dat.tm0 ?? 0;
    const reportedNewtworkConnectTime: number =
      shadow.state.reported.dat.nct ?? 0;
    const reportedAtmosphericPressure: number =
      shadow.state.reported.dat.pre ?? 0;
    const reportedTemperature: number = shadow.state.reported.dat.tm2 ?? 0;
    const reportedHumidity: number = shadow.state.reported.dat.hum ?? 0;
    const reportedPHN: string =
      shadow?.state?.reported?.car?.phn ?? 'No Data Available';

    const reportedIMEI: string =
      shadow?.state?.reported?.car?.imei ?? 'No Data Available';

    const reportedFMV: string =
      shadow?.state?.reported?.car?.fmv ?? 'No Data Available';

    const reportedICC: string =
      shadow?.state?.reported?.car?.icc ?? 'No Data Available';

    const reportedOPER: string =
      shadow?.state?.reported?.car?.oper ?? 'No Data Available';

    const reportedHWI: string =
      shadow?.state?.reported?.car?.hwi ?? 'No Data Available';

    // from status
    const battery = thing.battery;
    const binCapacity = thing.binCapacity;
    const distanceToLid = thing.distance_to_lid;
    const fillLevel = thing.fill_level;
    const location = thing.location;
    const status = thing.status;
    const thingName = thing.thing_name;
    const lastUpdate = date;
    let deviceCsv: any[] = [];

    // swtich case to apply the right language to the labels of the csv file
    switch (this.charts.getDateLanguage()) {
      case 'en-CA':
        deviceCsv = [
          {
            'File Name': fileName,
            'Thing Name': thingName,
            Battery: battery,
            'Bin Capacity': binCapacity,
            'Distance to Lid': distanceToLid,
            'Fill Level': fillLevel,
            Location: location,
            Status: status,
            'Last Update': lastUpdate,
            'Reported Firmware Version': reportedFirmwareVersion,
            'Reported MCU Temperature': reportedMcuTemperature,
            'Reported Signal Strength': reportedSignalStrength,
            'Reported VCC Measurement': reportedVccMeasurement,
            'Reported Battery Voltage Remaining':
              reportedBatteryVoltageRemaining,
            'Reported Distance to Lid': reportedDistanceToLid,
            'Reported GPS': reportedGps,
            'Reported Temperature Zero': reportedTemperatureZero,
            'Reported Network Connect Time': reportedNewtworkConnectTime,
            'Reported Atmospheric Pressure': reportedAtmosphericPressure,
            'Reported Temperature': reportedTemperature,
            'Reported Humidity': reportedHumidity,
            'Reported PHN': reportedPHN,
            'Reported IMEI': reportedIMEI,
            'Reported FMV': reportedFMV,
            'Reported ICC': reportedICC,
            'Reported OPER': reportedOPER,
            'Reported HWI': reportedHWI,
            'Metadata Firmware Version': metadataFirmwareVersion,
            'Metadata MCU Temperature': metadataMcuTemperature,
            'Metadata Signal Strength': metadataSignalStrength,
            'Metadata VCC Measurement': metadataVccMeasurement,
            'Metadata Battery Voltage Remaining':
              metadataBatteryVoltageRemaining,
            'Metadata Distance to Lid': metadataDistanceToLid,
            'Metadata GPS': metadataGps,
            'Metadata Temperature Zero': metadataTemperatureZero,
            'Metadata Network Connect Time': metadataNewtworkConnectTime,
            'Metadata Atmospheric Pressure': metadataAtmosphericPressure,
            'Metadata Temperature': metadataTemperature,
            'Metadata Humidity': metadataHumidity,
            'Metadata PHN': metadataPHN,
            'Metadata IMEI': metadataIMEI,
            'Metadata FMV': metadataFMV,
            'Metadata ICC': metadataICC,
            'Metadata OPER': metadataOPER,
            'Metadata HWI': metadataHWI,
            'Metadata DST': metadataDst,
            'Metadata Welcome': metadataWelcome,
          },
        ];

        break;

      case 'es-MX':
        deviceCsv = [
          {
            'Nombre de Archivo': fileName,
            'Nombre de la Cosa': thingName,
            Batería: battery,
            'Capacidad del Contenedor': binCapacity,
            'Distancia a la Tapa': distanceToLid,
            'Nivel de Llenado': fillLevel,
            Ubicación: location,
            Estado: status,
            'Última Actualización': lastUpdate,
            'Versión del Firmware Informada': reportedFirmwareVersion,
            'Temperatura del MCU Informada': reportedMcuTemperature,
            'Fuerza de Señal Informada': reportedSignalStrength,
            'Medición VCC Informada': reportedVccMeasurement,
            'Voltaje de Batería Restante Informado':
              reportedBatteryVoltageRemaining,
            'Distancia a la Tapa Informada': reportedDistanceToLid,
            'GPS Informado': reportedGps,
            'Temperatura Cero Informada': reportedTemperatureZero,
            'Tiempo de Conexión a la Red Informado':
              reportedNewtworkConnectTime,
            'Presión Atmosférica Informada': reportedAtmosphericPressure,
            'Temperatura Informada': reportedTemperature,
            'Humedad Informada': reportedHumidity,
            'PHN Informado': reportedPHN,
            'IMEI Informado': reportedIMEI,
            'FMV Informado': reportedFMV,
            'ICC Informado': reportedICC,
            'OPER Informado': reportedOPER,
            'HWI Informado': reportedHWI,
            'Versión del Firmware en Metadata': metadataFirmwareVersion,
            'Temperatura del MCU en Metadata': metadataMcuTemperature,
            'Fuerza de Señal en Metadata': metadataSignalStrength,
            'Medición VCC en Metadata': metadataVccMeasurement,
            'Voltaje de Batería Restante en Metadata':
              metadataBatteryVoltageRemaining,
            'Distancia a la Tapa en Metadata': metadataDistanceToLid,
            'GPS en Metadata': metadataGps,
            'Temperatura Cero en Metadata': metadataTemperatureZero,
            'Tiempo de Conexión a la Red en Metadata':
              metadataNewtworkConnectTime,
            'Presión Atmosférica en Metadata': metadataAtmosphericPressure,
            'Temperatura en Metadata': metadataTemperature,
            'Humedad en Metadata': metadataHumidity,
            'PHN en Metadata': metadataPHN,
            'IMEI en Metadata': metadataIMEI,
            'FMV en Metadata': metadataFMV,
            'ICC en Metadata': metadataICC,
            'OPER en Metadata': metadataOPER,
            'HWI en Metadata': metadataHWI,
            'DST en Metadata': metadataDst,
            'Bienvenida en Metadata': metadataWelcome,
          },
        ];

        break;

      default:
        deviceCsv = [
          {
            'Nom de Fichier': fileName,
            'Nom de la Chose': thingName,
            Batterie: battery,
            'Capacité de la Poubelle': binCapacity,
            'Distance au Couvercle': distanceToLid,
            'Niveau de Remplissage': fillLevel,
            Emplacement: location,
            Statut: status,
            'Dernière Mise à Jour': lastUpdate,
            'Version du Firmware Signalée': reportedFirmwareVersion,
            'Température du MCU Signalée': reportedMcuTemperature,
            'Force du Signal Signalée': reportedSignalStrength,
            'Mesure VCC Signalée': reportedVccMeasurement,
            'Tension de Batterie Restante Signalée':
              reportedBatteryVoltageRemaining,
            'Distance au Couvercle Signalée': reportedDistanceToLid,
            'GPS Signalé': reportedGps,
            'Température Zéro Signalée': reportedTemperatureZero,
            'Temps de Connexion au Réseau Signalé': reportedNewtworkConnectTime,
            'Pression Atmosphérique Signalée': reportedAtmosphericPressure,
            'Température Signalée': reportedTemperature,
            'Humidité Signalée': reportedHumidity,
            'PHN Signalé': reportedPHN,
            'IMEI Signalé': reportedIMEI,
            'FMV Signalé': reportedFMV,
            'ICC Signalé': reportedICC,
            'OPER Signalé': reportedOPER,
            'HWI Signalé': reportedHWI,
            'Version du Firmware dans les Métadonnées': metadataFirmwareVersion,
            'Température du MCU dans les Métadonnées': metadataMcuTemperature,
            'Force du Signal dans les Métadonnées': metadataSignalStrength,
            'Mesure VCC dans les Métadonnées': metadataVccMeasurement,
            'Tension de Batterie Restante dans les Métadonnées':
              metadataBatteryVoltageRemaining,
            'Distance au Couvercle dans les Métadonnées': metadataDistanceToLid,
            'GPS dans les Métadonnées': metadataGps,
            'Température Zéro dans les Métadonnées': metadataTemperatureZero,
            'Temps de Connexion au Réseau dans les Métadonnées':
              metadataNewtworkConnectTime,
            'Pression Atmosphérique dans les Métadonnées':
              metadataAtmosphericPressure,
            'Température dans les Métadonnées': metadataTemperature,
            'Humidité dans les Métadonnées': metadataHumidity,
            'PHN dans les Métadonnées': metadataPHN,
            'IMEI dans les Métadonnées': metadataIMEI,
            'FMV dans les Métadonnées': metadataFMV,
            'ICC dans les Métadonnées': metadataICC,
            'OPER dans les Métadonnées': metadataOPER,
            'HWI dans les Métadonnées': metadataHWI,
            'DST dans les Métadonnées': metadataDst,
            'Bienvenue dans les Métadonnées': metadataWelcome,
          },
        ];
        break;
    }
    this.csvExportService.exportToCsv(deviceCsv, fileName);
  }

  // Function used to send user to device statistics page
  setNavToDeviceStat(thingName: string){
    sessionStorage.setItem('toDeviceStats', 'client-thing-list')
    this.router.navigate([`/device-statistics/${thingName}`])
  }

  /**
   * Gets the battery level style and information based on the provided battery level.
   * @param {any} batteryLevel - The battery level as any type (should be a number).
   * @returns {object} - An object containing icon, color, and title properties.
   */
  getBatteryLevel(batteryLevel: any): object {
    const result = {
      icon: '',
      color: '',
      title: '',
    };

    // Check if batteryLevel is a number and not NaN
    if (typeof batteryLevel === 'number' && !isNaN(batteryLevel)) {
      // Logic to set icon, color, and title based on the value of batteryLevel
      if (batteryLevel > 3.5) {
        return { color: '#63E6BE' };
      } else if (batteryLevel <= 3.5 && batteryLevel > 2.5) {
        return { color: '#63E6BE' };
      } else if (batteryLevel <= 2.5 && batteryLevel > 1.5) {
        return { color: '#FFD43B' };
      } else if (batteryLevel <= 1.5 && batteryLevel > 0) {
        return { color: '#f70202' };
      } else if (batteryLevel === 0) {
        return { color: '#f70202' };
      }

      result.title = `${batteryLevel}V`;
    } else {
      // If batteryLevel is not a number
      return { color: 'gray' };
    }

    return result;
  }

  /**
   * Gets the fill level style based on the provided fill level percentage.
   * @param {string} fillLevel - The fill level as a string with a percentage symbol (%).
   * @returns {object} - An object representing the style with a 'color' property.
   */
  getFillLevelStyle(fillLevel: string): object {
    // Remove the percentage symbol

    const fillLevelWithoutPercent: string = fillLevel
      ? fillLevel.replace('%', '')
      : '';

    // Convert to a floating-point number (float)
    const fillLevelFixed: number = parseFloat(fillLevelWithoutPercent);

    // Check if fillLevel is undefined
    if (fillLevel === undefined) {
      return { color: 'gray' }; // Style for gray color (default)
    }

    // Use the number for conditional comparisons
    if (fillLevelFixed <= 60) {
      return { color: 'green' }; // Style for green color
    } else if (fillLevelFixed <= 79.09) {
      return { color: '#B8860B' }; // Style for yellow color
    } else if (fillLevelFixed >= 79.09 && fillLevelFixed <= 100) {
      return { color: 'red' }; // Style for red color
    } else {
      return { color: 'gray' }; // Style for inactive (default)
    }
  }

  // Function to handle changes in the status filter
  onStatusFilterChange() {
    // Check if the status filter is null
    if (this.statusFilter === null) {
      // If null, call the search function to reset the array
      this.search();
    }

    // Always call the search function, regardless of the status filter value
    this.search();
  }

  // Function used to made a search in the list
  search(){
    // Check if there's a filterBy variable and set it to it's default value
    if(this.filterBy === ''){
      this.filterBy = 'thing_name';
    }

    this.filteredArray = this.filter.search(this.userFilter, this.filteredArray, this.originalArray, this.filterBy);
  }

  // Function called to send user to the work order creation page for the selected thing
  toCreateWorkOrder(thingName:string){
    sessionStorage.setItem('From','client-thing-list')
    this.router.navigate([`/work-order-create/${thingName}`])
  }

  // Function that set the selected date to unix timestamps
  dateToUnixTimestamp(dateString: string): number {
    // Parse the date string into a Date object
    const date = new Date(dateString);

    // Get the UTC timestamp in milliseconds
    const timestampInMilliseconds = date.getTime();

    // Convert milliseconds to seconds and return
    return Math.floor(timestampInMilliseconds / 1000);
  }
}
