import {
  Component,
  OnInit,
  Renderer2,
  HostListener,
  Inject,
  forwardRef,
  AfterViewInit,
  ViewChild,
  ElementRef,
} from '@angular/core'; // AfterViewInit, ViewChild, ElementRef
import { LocalStorageService } from '../local-storage.service';
import { TranslateService } from '@ngx-translate/core';
import { CognitoService } from '../service/cognito.service';
import { ModalService } from '../service/device-modal.service';
import { FilterService } from '../service/filter.service';
import { IotService, Devices } from '../service/iot.service';
import { ThemeService } from '../service/theme.service';
import { environment } from '../environments/environment';
import { ValidationService } from '../service/validation.service';
import { SystemMessageService } from '../service/system-message.service';
import { S3Service } from '../service/s3.service';
import { ClientService } from '../service/client.service';
import { BinsService } from '../service/bins.service';
import { DistributorsService } from '../service/distributors.service';
import { DashboardService } from '../service/dashboard.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { LocalizationService } from '../service/localization.service';
import { ReportsService } from '../service/reports.service';
import { CsvService } from '../service/csv.service';
import { PdfService } from '../service/pdf.service';
import { map, catchError } from 'rxjs';
import { NgSelectComponent } from '@ng-select/ng-select';
import { firstValueFrom } from 'rxjs';

// Interface for map marker properties
interface Marker {
  position: google.maps.LatLngLiteral;
  title: string;
  /* map: google.maps.Map */
}

interface MyObject {
  thingName: string;
  thingARN: string;
  thingTypeName: string;
  gps: any;
  location: string;
}

@Component({
  selector: 'app-iot',
  templateUrl: './iot.component.html',
  styleUrls: ['./iot.component.css', '../../global-elements.css', './iot.css'],
})
export class IotComponent implements OnInit, AfterViewInit {
  @ViewChild('usageCanvas') usageCanvas!: ElementRef;
  @ViewChild('marketCanvas') marketCanvas!: ElementRef;
  @ViewChild('countCanvas') countCanvas!: ElementRef;
  @ViewChild('tonnageCanvas') tonnageCanvas!: ElementRef;
  @ViewChild('thingsShadowFeed') thingsShadowFeed!: NgSelectComponent;
  @ViewChild('searchBoxDevice') searchBoxDevice!: ElementRef;
  @ViewChild('searchBoxMobile') searchBoxMobile!: ElementRef;
  // Variable to store the language selected value
  public selectedLanguage: string = '';

  // Retrieve current language selected from local storage
  languageStatus: string = this.localStorageService.getItem('language');
  themeClass:string = '';
  // Constructor for dependency injection
  constructor(
    @Inject(forwardRef(() => TranslateService))
    private translate: TranslateService,
    private cognitoService: CognitoService,
    private localStorageService: LocalStorageService,
    private renderer: Renderer2,
    public iotService: IotService,
    public modal: ModalService,
    public filter: FilterService,
    public theme: ThemeService,
    public validationService: ValidationService,
    public systemMessage: SystemMessageService,
    public s3: S3Service,
    private clientService: ClientService,
    public binService: BinsService,
    private distributorService: DistributorsService,
    public dashboardService: DashboardService,
    public route: ActivatedRoute,
    public location: Location,
    public localizationService: LocalizationService,
    public reports: ReportsService,
    public csv: CsvService,
    public pdf: PdfService,
    private router: Router
  ) {
    this.cognitoService.confirmValidUser();
    // Check if the user has selected a language in local storage
    //or use a default language
    if (this.languageStatus == null) {
      // Set the default language to French
      translate.use('fr');
    } else {
      // Set the default language to the user's selected language
      translate.use(this.languageStatus);
    }
    this.themeClass = this.theme.getThemeClass();
  }

  // String that controls the section that is displayed to the user
  public mainContent: string = 'devices';
  public hideMap: boolean = true;
  public address = '';

  public deviceFilter: string = ''; // Device filter string
  public filterBy: string = '';
  // public deviceFilterTechnical: string = ''; // This isnt used, should be remove?
  public newMap: string = ''; // Active map element ID
  public lastExpanded: string = ''; // Store last expanded device ID
  public currentId: string = ''; // Current device ID for modal
  public errorCalculatingFill: string = "";
  public filterThingGroup: string = '';

  // Various flags and values for UI control
  public showModal = false;
  public showModalInfoDevicesMobile = false;
  public showModalDevice = false;
  public selectedDevice: Devices = {} as Devices;
  public filterOn: boolean = false;
  public showList: boolean = false;
  public loadingMoreData = false;
  public stopMap: boolean = false;
  public hasRelations = false;

  public isDevicesActive: boolean = false; // true when Device view is displayed
  public isTechnicalActive: boolean = false; // true when Technical view is displayed
  public isModelsActive: boolean = false; // true when Models view is displayed
  public isAlertsActive: boolean = false; // true when Alerts view is displayed
  public isConnectorsActive: boolean = false; // true when Connectors view is displayed
  public isActionsActive: boolean = false; // true when Actions view is displayed
  public isShadowFeedActive: boolean = false; // True when Shadow feed view is displayed

  // Variables to handle the positions of the map pin
  public position = { lat: 0, lng: 0 };
  public longitude = 0;
  public latitude = 0;
  public item = 0;

  // Data arrays and maps for devices, models, alerts, and maps
  public modelArray: any = [];
  public alertArray: any = [];
  public mapaGerado: any = [];
  public markers: Marker[] = [];
  public map: any;

  public thingStatusArray: any;

  // sortBy varaibles
  public sortBy: string = 'thingName';
  public sortDirection: string = '';

  // Variable used for the device association in modal service
  public userType: string = '';
  public userRole: any;

  //variable that saves device clicked
  public currentDevice:any;

  // Value to manage the pagination
  public itemsPerPage = 10;
  public loadedItems = 0;
  // Value to manage the pagination for shadow feed
  public itemsPerPageShadowFeed = 20;
  public loadedItemsShadowFeed = 0;

  //Report Filters
  public startDate: any = this.reports.getDateThirtyDaysAgo();
  public endDate: any = this.reports.getCurrentDate();
  public statistic: string = 'Collections';
  public selectedThings: string[] = [];
  public selectedMarkets: any = 'All';

  //Filter Arrays
  public things: any = [];
  public zones: any = [];

  //Filter Booleans
  public allThings: boolean = true;
  public allMarkets: boolean = true;
  public generatingCSV: boolean = false;

  //Reports
  public collections: any[] = [];
  public history: any[] = [];
  public counts: any[] = [];

  //Shadow feed
  public allThingsShadowFeed: boolean = false;
  public deviceFilterShadowFeed: string = '';
  public thingsShadowLog: any[] = [];
  public selectedThingsShadowFeed: string[] = [];
  public startDateShadowFeed: any = this.reports.getCurrentDate();
  public endDateShadowFeed: any = this.reports.getCurrentDate();

  // Variable used to show info bubble on device state
  public showStateInfo: boolean[] = [];

  async ngOnInit(): Promise<void> {
    this.errorCalculatingFill = await this.systemMessage.getMessageText('errorCalculatingFill');

    // Check device creation success
    const checkCreateDeviceSucess = this.localStorageService.getItem('successCreateDevice');
    if (checkCreateDeviceSucess) {
      this.systemMessage.selectRibbon('success', 'alert-success-generic-message');
      this.localStorageService.removeItem('successCreateDevice');
    }

    // Check device creation failed
    const checkCreateDeviceFail = this.localStorageService.getItem('failCreateDevice');
    if (checkCreateDeviceFail) {
      this.systemMessage.selectRibbon('danger', 'alert-danger-generic-message');
      this.localStorageService.removeItem('failCreateDevice');
    }

    const tab = this.route.snapshot.params['tab'];

    // Initialize component with device view
    switch (tab) {
      case 'reports':
        this.reportsClicked();
        break;
      case 'devices':
        this.devicesClicked();
        break;
      case 'technical':
        this.technicalClicked();
        break;
      case 'feed':
        this.shadowFeedClicked();
        break;
      default:
        this.devicesClicked();
        break;
    }

    (await this.reports.getReportFilterData()).subscribe((response) => {
      for (const row of response.things) {
        this.things.push({ id: row.thing_name, name: row.thing_name });
      }
      for (const row of response.zones) {
        this.zones.push({ id: row.zone_id, name: row.zone_name });
      }
    });

    //Verifies that the current User is allowed to view this component
    this.cognitoService.getCurrentRole(
      [environment.users.role.administrator],
      [environment.users.superAdmin]
    ); //Role Check

    await this.dashboardService.getRelationships();

    await this.dashboardService.countApplicationBinModels();
    await this.dashboardService.countApplicationBins();
    await this.dashboardService.countApplicationClients();

    //await this.dashboardService.countApplicationDevicesAndParameters();

    // data loading to device list - device view
    this.initData();
    document.readyState;

    // Initialize maps
    this.initMap(); // desktop version map
    this.initMapMobile(); // mobile version map

    // Function that subscribe in modal service and will trigger this when modal.service.showmodal function is called from device-modal.html will be called.
    this.modal.showMap$.subscribe(() => {
      // Get the gps and the device selected in the modal service and trow it to this.showMap function so it
      this.showMap(this.modal.selectedDevice);
    });
    // Function that subscribe in modal service and will trigger this when modal.resetMap function is called from device-modal.html will be called.
    this.modal.resetMap$.subscribe(() => {
      this.resetMap();
    });

    this.iotService.shadowUpdate$.subscribe(() => {
      this.systemMessage.selectRibbon('success', 'alert-success-generic-message');
    });

    if(this.validationService.updateSuccessMessage) {
      this.systemMessage.selectRibbon('success',"deviceUpdated")
    }
  }

  ngAfterViewInit(): void {
    this.pdf.tickFontSize = 10;
    this.pdf.legendFontSize = 10;
    this.pdf.usageCanvas = this.usageCanvas;
    this.pdf.marketCanvas = this.marketCanvas;
    this.pdf.countCanvas = this.countCanvas;
    this.pdf.tonnageCanvas = this.tonnageCanvas;
    // Call the function to detect if the user has reached the end of the page on a mobile device
    this.detectEndOfPageOnMobile();

    this.searchBoxDevice.nativeElement.addEventListener('input', (event: Event) => {

    })
  }

  // Function called in initiation of the page to see if there is already filters
  checkStoredFilters(){
    if(this.localStorageService.getItem('deviceFilter') !== ''){
      this.deviceFilter = this.localStorageService.getItem('deviceFilter');
      this.filter.callingComponent = 'iotComponent';

      this.search();
      this.localStorageService.removeItem('deviceFilter');
    }

    if(this.localStorageService.getItem('filterThingGroup') !== ''){
      this.filterThingGroup = this.localStorageService.getItem('filterThingGroup');
      this.sortByGroupChange();
      this.localStorageService.removeItem('filterThingGroup');
    }

    if(this.localStorageService.getItem('sortBy') !== ''){
      this.sortBy = this.localStorageService.getItem('sortBy');

      if(this.localStorageService.getItem('sortDirection') !== ''){
        this.sortDirection = this.localStorageService.getItem('sortDirection');
      }else{
        this.sortDirection = 'asc';
      }

      this.sortItems(this.sortBy);
    }
  }

  // Function triggered when user click on switch of all thing on tab reports
  onChangeAllThings(event: any) {
    this.allThings = (event.target as HTMLInputElement).checked;
    return this.allThings;
  }

  // Function triggered when user click on switch of all market segment on tab reports
  onChangeAllMarkets(event: any) {
    this.allMarkets = (event.target as HTMLInputElement).checked;
    return this.allMarkets;
  }

  // Function triggered when user change status of all thing in shadow feed view
  onChangeAllThingsShadowFeed(event: any) {
    this.allThingsShadowFeed = (event.target as HTMLInputElement).checked;
    return this.allThingsShadowFeed;
  }

  // Function used to get device status
  verifyStatus(thingName: string): string {
    if(this.thingStatusArray){
      // Check if this.thingStatusArray is defined
      const array = this.thingStatusArray;
      const filteredDevices = array.filter((device:any) => device.thing_name === thingName);
      if (filteredDevices.length > 0) {
        return filteredDevices[0].status; // Assuming there's only one device with the given thingName
      }
    }
    // Returns null or some default value if no match is found
    return 'Inactive';
  }

  // Load data to Device List
  async initData() {
    // clean the device array of previous data - avoiding duplication of information
    this.iotService.resetDeviceArray();

    // activate getDeviceList function and devicesArray @ iot service
    await this.iotService.getDeviceList().then(() => {
      //this.checkStoredFilters();
    });

    await this.iotService.countDashboardDevicesAndParameters().then((res) => {
      this.thingStatusArray = JSON.parse(res.status);
    });
  }

  // Function to load status from HTML for a given thingName
  loadStatusFromHtml(thingName: string): string {
    // Call the function in iotService to check health status for the specified thingName
    // and return the result
    return this.iotService.checkHealthStatusUsingThingName(thingName);
  }

  // function used to load more data into the list
  loadMoreItems() {
    if (
      this.loadedItems + this.itemsPerPage <
      this.iotService.devicesArray.length
    ) {
      this.loadedItems += this.itemsPerPage;
    }
    if (
      this.loadedItemsShadowFeed + this.itemsPerPageShadowFeed <
      this.thingsShadowLog.length
    ) {
      this.loadedItemsShadowFeed += this.itemsPerPageShadowFeed;
    }
  }

  // Initialize maps
  resetMap() {
    this.initMap();
    this.initMapMobile();
    this.position = { lat: 0, lng: 0 };
    this.showMap([]);
  }

  // Function triggered when user click the refresh list button to reload the page
  refreshList() {
    window.location.reload();
  }

  // Change language based on user selection
  changeLanguage(language: string) {
    this.translate.use(language);
  }

  // Clear active state for UI buttons
  clearButtonsStats() {
    this.isDevicesActive = false;
    this.isTechnicalActive = false;
    this.isModelsActive = false;
    this.isAlertsActive = false;
    this.isConnectorsActive = false;
    this.isActionsActive = false;
    this.isShadowFeedActive = false;
  }

  // Initialize Google Maps
  initMap(
    gps: { latitude: number; longitude: number } = { latitude: 0, longitude: 0 }
  ): void {
    let map: google.maps.Map;
    this.markers.forEach((markerObj) => {
      const marker = new google.maps.marker.AdvancedMarkerElement({
        position: markerObj.position,
        title: markerObj.title,
        map: map,
      });
    });
    const MM = this.markers;

    async function initMap(
      gps: { latitude: number; longitude: number } = {
        latitude: 0,
        longitude: 0,
      }
    ): Promise<void> {
      // Initialisation of the position of the centered map
      let position: any = '';
      if (
        gps.latitude !== 0 ||
        (gps.latitude !== undefined && gps.longitude !== 0)
      ) {
        position = { lat: gps.latitude, lng: gps.longitude };
      } else {
        position = { lat: 45.65054871734081, lng: -73.84789617258333 };
      }

      // instanciate the position that th emap will be centered on
      //const position = { lat: 45.65054871734081, lng: -73.84789617258333 };
      const position2 = { lat: 51.5, lng: 0.1167 };

      // Import the necessary libraries for the Google Maps API
      const { Map } = (await google.maps.importLibrary('maps')) as google.maps.MapsLibrary;
      const { AdvancedMarkerElement } = (await google.maps.importLibrary( 'marker' )) as google.maps.MarkerLibrary;
      // Create a new Google Map instance
      map = new Map(
        document.getElementById('mapDesktop') as HTMLElement,
        {
          zoom: 10, // 1: World - 5: Landmass/continent - 10: City - 15: Streets - 20: Buildings
          center: position,
          mapId: 'DEVICES_MAP',
          disableDefaultUI: true,
        }
      );

      // Create markers on the map using the marker objects from MM arra
      MM.forEach((markerObj) => {
        const marker = new AdvancedMarkerElement({
          position: markerObj.position,
          title: markerObj.title,
          map: map,
        });
      });
    }
    // Call the function to initialize the Google Map

    initMap(gps);
  }

  // Initialize goolge map for mobile
  initMapMobile(
    gps: { latitude: number; longitude: number } = { latitude: 0, longitude: 0 }
  ): void {
    let map: google.maps.Map;

    // Loop through each marker object and create markers on the map
    this.markers.forEach((markerObj) => {
      const marker = new google.maps.marker.AdvancedMarkerElement({
        position: markerObj.position,
        title: markerObj.title,
        map: map,
      });
    });
    const MM = this.markers;

    async function initMap(
      gps: { latitude: number; longitude: number } = {
        latitude: 0,
        longitude: 0,
      }
    ): Promise<void> {
      // Initialisation of the position of the centered map
      let position: any = '';
      if (
        gps.latitude !== 0 ||
        (gps.latitude !== undefined && gps.longitude !== 0)
      ) {
        position = { lat: gps.latitude, lng: gps.longitude };
      } else {
        position = { lat: 45.65054871734081, lng: -73.84789617258333 }; // Position could be change on the user position or something like neer him in the futur
      }

      // Define initial positions for the map
      //const position = { lat: 45.65054871734081, lng: -73.84789617258333 };
      const position2 = { lat: 51.5, lng: 0.1167 };

      // Import the necessary libraries for the Google Maps API
      const { Map } = (await google.maps.importLibrary( 'maps')) as google.maps.MapsLibrary;

      const { AdvancedMarkerElement } = (await google.maps.importLibrary('marker')) as google.maps.MarkerLibrary;

      // Create a new Google Map instance
       map = new Map(document.getElementById("mapMobile") as HTMLElement, {
         zoom: 10, // 1: World - 5: Landmass/continent - 10: City - 15: Streets - 20: Buildings
         center: position,
         mapId: 'DEVICES_MAP', // Specify the map style
         disableDefaultUI: true, // Disable default map controls
      });
      // Create markers on the map using the marker objects from MM array
      MM.forEach((markerObj) => {
        const marker = new AdvancedMarkerElement({
          position: markerObj.position,
          title: markerObj.title,
          map: map,
        });
        /* marker.setMap(map);  proprty setMap is not available for AdvancedMarkerElement */
      });
    }
    // Call the initMap function to initialize the Google Map
    initMap(gps);
  }

  // Show specific map with device's location
  showMap( deviceRow: any): void {
   this.hideMap = false;
    // Clear any existing markers
    this.markers = [];
    let gps;

    //get device gps coordinates
    if(deviceRow.bin.bin_id){ //here

      if(deviceRow.bin.bin_gps.length > 0){
        gps = {
          latitude: Number(deviceRow.bin.bin_gps.split(',')[0]),
          longitude: Number(deviceRow.bin.bin_gps.split(',')[1]),
        }
      }
      else{
        gps = {
          latitude: Number(deviceRow.bin.bin_location.split(',')[0]),
          longitude: Number(deviceRow.bin.bin_location.split(',')[1]),
        }
      }

      // Store the ID and location of the last expanded device
      this.lastExpanded = deviceRow.thingName;
      this.address = deviceRow.bin.bin_address;
    }else{
      this.lastExpanded = deviceRow.thingName;
      this.address = 'withoutBin'
    }

    // Initialize the main map
    this.initMap(gps);

    // Initialize the secondary map
    this.initMapMobile(gps);

    // Push the DeviceID to the generated map list
    this.mapaGerado.push(deviceRow.thingName);

    // Store the current device information
    this.item = deviceRow;
    this.currentDevice = deviceRow;
    if(this.currentDevice){

    }

    // Set the stopMap flag to false
    this.stopMap = false;


    if(gps){
      // Check if the map should be stopped
    if (!this.stopMap) {
      // Get the coords directly by gps passing
      const latNumber = Number(gps.latitude);
      const lngNumber = Number(gps.longitude);

      // Set latitude and longitude for the map
      this.latitude = latNumber;
      this.longitude = lngNumber;

      // Define a new marker based on the GPS coordinates
      const newMarker: Marker = {
        position: { lat: latNumber, lng: lngNumber },
        title: 'New Marker',
      };

      // Add the new marker to the markers array
      this.markers.push(newMarker);
    } else {
      // If map should be stopped, set GPS coordinates but don't add a marker
      const latNumber = Number(gps.latitude);
      const lngNumber = Number(gps.longitude);

      // Set latitude and longitude for the map
      this.latitude = latNumber;
      this.longitude = lngNumber;
    }
    }
  }

  // Handle navigation to device view
  devicesClicked() {
    // Clear any buttons' stats
    this.clearButtonsStats();

    // Reset devicesArray to its original value
    this.resetDevicesArrayToOriginalArray();

    // empty search bar value
    this.deviceFilter = ''

    // Set the isDevicesActive flag to true, indicating the devices view is active
    this.isDevicesActive = true;

    // Update the main content to show the "devices" view
    this.mainContent = 'devices';
    this.location.go('/iot/devices');

    // Clear any existing markers
    this.markers = [];

    // Initialize the main map
    this.initMap();
  }

  // Handle navigation to model view
  technicalClicked() {
    // Clear any buttons' stats
    this.clearButtonsStats();

    // Reset devicesArray to its original value
    this.resetDevicesArrayToOriginalArray();

    // empty search bar value
    this.deviceFilter = ''

    // Set the isTechnicalActive flag to true, indicating the models view is active
    this.isTechnicalActive = true;

    // Update the main content to show the "models" view
    this.mainContent = 'technical';
    this.location.go('/iot/technical');

    // Set the isDevicesActive flag to false, indicating the devices view is not active
    this.isDevicesActive = false;
  }

  // Handle navigation to REPORTS view
  reportsClicked() {
    // Clear any buttons' stats
    this.clearButtonsStats();

    // Set the isConnectorsActive flag to true, indicating the connectors view is active
    this.isConnectorsActive = true;

    // Update the main content to show the "connectors" view
    this.mainContent = 'reports';
    this.location.go('/iot/reports');

    // Set the isDevicesActive flag to false, indicating the devices view is not active
    this.isDevicesActive = false;
  }

  // Handle navigation to shadow feed view
  shadowFeedClicked(){
    // Clear button stats
    this.clearButtonsStats();

    // Set the isShadowFeedActive flag to true, indicating shadow feed view is active
    this.isShadowFeedActive = true;

    // Update the main content to show the shadow feed view
    this.mainContent = 'feed';
    this.location.go('iot/feed');
  }

  // Function that sort items ondevice and technical views
  sortItems(itemSortBy: string) {
    // Choose the sort direction if user click more than once on the same column
    if (this.sortBy === itemSortBy) {
      this.sortDirection = this.sortDirection === 'asc' ? 'desc' : 'asc';
    } else {
      // Else sort direction will be at ascendant
      this.sortDirection = 'asc';
    }

    // Reasign the value of sortyBy so next click system will know if it's the first click on the column
    this.sortBy = itemSortBy;

    // This will sort between each of the selected column by the user
    this.iotService.devicesArray.sort((a, b) => {
      switch (itemSortBy) {
        case 'thingName':
          // This will sort asc or desc depend on the sort direction initialize up here
          if (this.sortDirection === 'asc') {
            return b.thingName.localeCompare(a.thingName);
          } else {
            return a.thingName.localeCompare(b.thingName);
          }

        case 'vcc':
          // This will sort asc or desc depend on the sort direction initialize up here
          if (this.sortDirection === 'asc') {
            return b.dat.vcc - a.dat.vcc;
          } else {
            return a.dat.vcc - b.dat.vcc;
          }

        case 'battery':
          // This will sort asc or desc depend on the sort direction initialize up here
          if (this.sortDirection === 'asc') {
            return b.dat.bat - a.dat.bat;
          } else {
            return a.dat.bat - b.dat.bat;
          }

        case 'version':
          // This will sort asc or desc depend on the sort direction initialize up here
          if (this.sortDirection === 'asc') {
            return b.dat.fdv.localeCompare(a.dat.fdv);
          } else {
            return a.dat.fdv.localeCompare(b.dat.fdv);
          }

        case 'signal':
          // This will sort asc or desc depend on the sort direction initialize up here
          if (this.sortDirection === 'asc') {
            return b.dat.sig - a.dat.sig;
          } else {
            return a.dat.sig - b.dat.sig;
          }

        case 'nct':
          // This will sort asc or desc depend on the sort direction initialize up here
          if (this.sortDirection === 'asc'){
            return b.dat.nct - a.dat.nct;
          } else {
            return a.dat.nct - b.dat.nct;
          }

        case 'prodStatus':
          // This will sort asc or desc depend on the sort direction initialize up here
          if (this.sortDirection === 'asc'){
            return b.prodStatus!.status_index - a.prodStatus!.status_index;
          } else {
            return a.prodStatus!.status_index - b.prodStatus!.status_index;
          }

        case 'temperature':
          // This will sort asc or desc depend on the sort direction initialize up here
          if (this.sortDirection === 'asc') {
            return b.dat.tm2 - a.dat.tm2;
          } else {
            return a.dat.tm2 - b.dat.tm2;
          }

        case 'height':
          // This will sort asc or desc depending on the sort direction initialized up here
          if (this.sortDirection === 'asc') {
            return (b.bin?.bin_height || 0) - (a.bin?.bin_height || 0);
          } else {
            return (a.bin?.bin_height || 0) - (b.bin?.bin_height || 0);
          }

        case 'distance':
          // This will sort asc or desc depending on the sort direction initialized up here
          if (this.sortDirection === 'asc') {
            return (b.dat?.dst || 0) - (a.dat?.dst || 0);
          } else {
            return (a.dat?.dst || 0) - (b.dat?.dst || 0);
          }

        case 'status':
          if (this.sortDirection === 'asc') {
            return this.statusComparison(a, b);
          } else {
            return this.statusComparison(b, a);
          }

        case 'filled':
          // This will sort asc or desc depend on the sort direction initialize up here
          if (this.sortDirection === 'desc') {
            return this.fillPercentageComparison(a, b);
          } else {
            return this.fillPercentageComparison(b, a);
          }

        case 'modal':
          // This will sort asc or desc depend on the sort direction initialize up here
          if (this.sortDirection === 'asc') {
            return (
              b.thingName.split('-')[0] +
              '-' +
              b.thingName.split('-')[1]
            ).localeCompare(
              a.thingName.split('-')[0] + '-' + a.thingName.split('-')[1]
            );
          } else {
            return (
              a.thingName.split('-')[0] +
              '-' +
              a.thingName.split('-')[1]
            ).localeCompare(
              b.thingName.split('-')[0] + '-' + b.thingName.split('-')[1]
            );
          }

        case 'date':
          // This will sort asc or desc depend on the sort direction initialize up here
          if (this.sortDirection === 'asc') {
            return b.lastUpdate - a.lastUpdate;
          } else {
            return a.lastUpdate - b.lastUpdate;
          }

        default:
          // Default will sort on thingName asc
          return a.thingName.localeCompare(b.thingName);
      }
    });
  }

  // Function that sort the list on shadow feed page
  sortItemLog(itemSortBy: string){
    // Choose the sort direction if user click more than once on the same column
    if (this.sortBy === itemSortBy) {
      this.sortDirection = this.sortDirection === 'asc' ? 'desc' : 'asc';
    } else {
      // Else sort direction will be at ascendant
      this.sortDirection = 'asc';
    }

    // Reasign the value of sortyBy so next click system will know if it's the first click on the column
    this.sortBy = itemSortBy;

    this.thingsShadowLog.sort((a, b) => {
      switch(itemSortBy){
        case 'thing_name':
          // This will sort asc or desc depend on the sort direction initialize up here
          if (this.sortDirection === 'asc') {
            return b.thing_name.localeCompare(a.thing_name);
          } else {
            return a.thing_name.localeCompare(b.thing_name);
          }

        case 'date':
          // This will sort asc or desc depend on the sort direction initialize up here
          if (this.sortDirection === 'asc') {
            return b.dst_timestamp - a.dst_timestamp;
          } else {
            return a.dst_timestamp - b.dst_timestamp;
          }

          default:
          // Default will sort on thingName asc
          return a.thing_name.localeCompare(b.thingName);
      }
    });
  }

  // This function compares the fill percentage between two objects.
  fillPercentageComparison = (a: any, b: any): number => {
    // Calculate the fill percentage for object 'a'
    const fillPercentageA: number = this.calculateFillPercentage(a.bin?.bin_height, a.dat.dst);
    // Calculate the fill percentage for object 'b'
    const fillPercentageB: number = this.calculateFillPercentage(b.bin?.bin_height, b.dat.dst);
    // Compare the fill percentages of 'a' and 'b' and return the difference
    return fillPercentageA - fillPercentageB;
  };

  // This function compares the status of two objects.
  statusComparison = (a: any, b: any): number => {
    // Obtain the status of object 'a'
    const statusA = this.verifyStatus(a.thingName);
    // Obtain the status of object 'b'
    const statusB = this.verifyStatus(b.thingName);

    // Place 'Healthy' before 'Unhealthy' and 'Unhealthy' before 'Inactive'
    if (statusA === 'Healthy' && statusB !== 'Healthy') {
      return -1;
    } else if (statusA === 'Unhealthy' && statusB === 'Inactive') {
      return -1;
    } else if (statusA === 'Inactive' && statusB !== 'Inactive') {
      return 1;
    } else {
      // If statuses are the same or not covered by the previous conditions, return 0
      return 0;
    }
  };

  // Function used to sort a list when user load a page
  sortOnLoad() {
    this.iotService.devicesArray.sort((a, b) => {
      return b.lastUpdate - a.lastUpdate;
    });
  }

  // function called to sort things by group name
  async sortByGroupChange(){
    // Set devicesArray to it's origanl state
    this.iotService.devicesArray = [...this.iotService.originalDevicesArray];

    // If we have a sort by property
    if(this.filterThingGroup !== ''){
      // Call the function that will filter all the devices by the thing group pass into filterThingGroup
      await this.iotService.filterThingGroup(this.filterThingGroup).then((list) => {
        // Set devicesArray to the filtered one
        this.iotService.devicesArray = this.iotService.devicesArray.filter(device => list.includes(device.thingName));
      });
    }
  }

  // Handle scroll event
  @HostListener('window:scroll', []) onScroll(): void {
    // Get the height of the visible window area
    const windowHeight =
      'innerHeight' in window
        ? window.innerHeight
        : document.documentElement.offsetHeight;

    // Get references to the document's body and html elements
    const body = document.body;
    const html = document.documentElement;

    // Calculate the maximum document height using various metrics
    const documentHeight = Math.max(
      body.scrollHeight,
      body.offsetHeight,
      html.clientHeight,
      html.scrollHeight,
      html.offsetHeight
    );

    // Get the current scroll position
    const scrollPosition =
      window.scrollY ||
      document.documentElement.scrollTop ||
      document.body.scrollTop ||
      0;

    // Check if the user has scrolled near the bottom of the page
    if (
      documentHeight - windowHeight - scrollPosition < 100 &&
      !this.loadingMoreData &&
      !this.filterOn
    ) {
      this.loadMoreItems();
    }
  }

  // Handle End Of Page On Mobile event
  detectEndOfPageOnMobile(): void {
    // Check if the user is using a mobile device
    if (this.isMobileDevice()) {
      // Listen for the 'touchmove' event to track scrolling on the document
      this.renderer.listen('document', 'touchmove', (event: TouchEvent) => {
        // Get the current scroll position, window height, and document height
        const scrollPosition =
          window.scrollY ||
          document.documentElement.scrollTop ||
          document.body.scrollTop ||
          0;
        const windowHeight =
          window.innerHeight ||
          document.documentElement.clientHeight ||
          document.body.clientHeight ||
          0;
        const documentHeight = Math.max(
          document.body.scrollHeight,
          document.documentElement.scrollHeight,
          document.body.offsetHeight,
          document.documentElement.offsetHeight,
          document.documentElement.clientHeight
        );
        // Check if the user has scrolled to the end of the page and not currently loading more data
        if (
          scrollPosition + windowHeight >= documentHeight &&
          !this.loadingMoreData
        ) {
          this.loadMoreItems();
        }
      });
    }
  }

  // checks if the application is being accessed from a mobile device
  isMobileDevice(): boolean {
    // Get the user agent string and convert it to lowercase
    const userAgent = navigator.userAgent.toLowerCase();

    // Check if the user agent matches any common mobile device keywords
    return /mobile|android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini/i.test(
      userAgent
    );
  }

  // Function used to load certificate
  async getCertificateFolderUrl(thingName: string) {
    await this.s3
      .getCertificatesForThing(thingName)
      .then((res) => {
        if (res?.length) {
          let cert = res[0];

          // Create a link element
          const a = document.createElement('a');

          // Set the link's href to the Blob URL
          a.href = cert;

          // Set the link's download attribute to specify the filename
          a.download = `${thingName}-certificates.zip`;

          // Append the link to the body
          document.body.appendChild(a);

          // Trigger a click on the link to start the download
          a.click();

          // Remove the link from the body
          document.body.removeChild(a);
        } else {
          this.systemMessage.selectRibbon(
            'danger',
            'noCertificatesSystemMessage'
          );
        }
      })
      .catch((error) => {
        this.systemMessage.selectRibbon(
          'danger',
          'noCertificatesSystemMessage'
        );
        console.error('Error', error);
      });
  }

  // Function called from the click of the relation logo in the iot list
  async showRelation(thingName: string) {
    // Call the function that get all infos for device association
    (await this.iotService.getDeviceRelationship(thingName)).subscribe(async () => {
      this.modal.deviceAssociationRow = this.iotService.deviceAssociationRow;

      // Set the distributor array
      let array: any = await this.distributorService.getDistributorForSelect();
      this.modal.distributorsArray = array;

      // Set the client array
      array = await this.clientService.getClientsForSelect();
      this.modal.clientsArray = array;

      this.binService.getBinsJson().subscribe((res: any) => {
        if(res){
          this.modal.binArray = JSON.parse(res);

          this.modal.binArray = this.modal.binArray.filter((bin: any) => bin.thing_name === '');
        }
      });

      this.modal.thingName = thingName;

      // Set the HTML show/hide variable to true to show the modal
      this.modal.showDeviceModal = true;
      this.modal.showRelationship = true;
    });
  }

  // Function triggered when user click on device note icon
  async showDeviceNotes(thingName: string){
    // Call the function that get all device notes
    (await this.iotService.getDeviceNotes(thingName)).subscribe(() => {
      this.modal.deviceNotes = this.iotService.deviceNotes;
      this.modal.showLoadingStatus = false;

      // Set the HTML show/hide variable to true to show the modal
      this.modal.showDeviceModal = true;
      this.modal.showNotesModal = true;
    });
  }

  // Function used to change a date in string to a date in timestamp
  dateToUnixTimestamp(dateString: string): number {
    // Parse the date string into a Date object
    const date = new Date(dateString);

    // Get the UTC timestamp in milliseconds
    const timestampInMilliseconds = date.getTime();

    // Convert milliseconds to seconds and return
    return Math.floor(timestampInMilliseconds / 1000);
  }

  // Function return fill percentage of a bin if device is associated to one or 0 if not
  calculateFillPercentage(height:number = 0, dst: number = 0): number{
    if(height > 0 && dst >= 0){
      let percentFilled =  (height - dst)/height * 100;
      if(percentFilled < 0 && dst > height){
       return 0;
      }
      return Math.round(percentFilled);
    }
    else{
      return 0;
    }
  }

  // Function that return the temperature rounded
  roundTemperature(temperature: number){
    return Math.round(temperature);
  }

  // Function used to
  async compileReport() {
    this.pdf.generatingPDF = true;
    try {
      let things = [];
      if (!this.allThings) {
        things = this.selectedThings;
      } else {
        for (const row of this.things) {
          things.push(row.id);
        }
      }

      this.pdf.things = things;

      this.pdf.start = this.startDate;

      this.pdf.end = this.endDate;

      await this.pdf.savePDF();
    } catch (error) {
      this.pdf.generatingPDF = false;
    }
  }

  // Function used in report page to download the CSV
  async exportCSVReports() {
    this.generatingCSV = true;

    this.history = [];
    this.counts = [];
    this.collections = [];
    const start = this.dateToUnixTimestamp(this.startDate+ "T00:00:00");
    const end = this.dateToUnixTimestamp(this.endDate+ "T23:59:59");

    let things = [];
    // Set the things that gonna be in th ereports
    if (!this.allThings) {
        things = this.selectedThings;
    } else {
      for (const row of this.things) {
        things.push(row.id);
      }
    }

    try {
      // Call the function that return all datas needed for the reports
      (await this.reports.getReportData(things, start, end))
        .pipe(
          map((response) => {
            return response
          }),
          catchError((error) => {
            console.error('API Error:', error);
            this.generatingCSV = false;
            this.systemMessage.selectRibbon(
              'danger',
              'alert-danger-generic-message'
            );
            throw error; // Re-throw the error for the calling code to handle
          })
        )
        .subscribe((res: any) => {

          // Increment the collection array with the result of getReportDatas
          for (const collection of JSON.parse(res?.collections)) {
            this.collections.push(collection);
          }

          // Increment the count array with the result of getReportDatas
          for (const counts of JSON.parse(res?.counts)) {
            this.counts.push(counts);
          }

          // Increment the history array with the result of getReportDatas
          for (const history of JSON.parse(res?.history)) {
            this.history.push(history);
          }

          const deviceReport: any = [];
          const historyArray = this.history;

          for (const row of historyArray) {
            // const date = new Date(row.dst_timestamp*1000);
            if (row.timestamp.toString().length <= 10) {
              row.timestamp = new Date(row.timestamp * 1000);
            } else {
              row.timestamp = new Date(parseInt(row.timestamp));
            }

            row.img = 'N/A';
            // Set timestamp to date
            row.fdv_timestamp = new Date(row.fdv_timestamp * 1000);
            row.img_timestamp = new Date(row.img_timestamp * 1000);
            row.tmp_timestamp = new Date(row.tmp_timestamp * 1000);
            row.vcc_timestamp = new Date(row.vcc_timestamp * 1000);
            row.sig_timestamp = new Date(row.sig_timestamp * 1000);
            row.pre_timestamp = new Date(row.pre_timestamp * 1000);
            row.bat_timestamp = new Date(row.bat_timestamp * 1000);
            row.tm1_timestamp = new Date(row.tm1_timestamp * 1000);
            row.tm2_timestamp = new Date(row.tm2_timestamp * 1000);
            row.dst_timestamp = new Date(row.dst_timestamp * 1000);
            row.tm0_timestamp = new Date(row.tm0_timestamp * 1000);
            row.nct_timestamp = new Date(row.nct_timestamp * 1000);
            row.lfr_timestamp = new Date(row.lfr_timestamp * 1000);
            row.hum_timestamp = new Date(row.hum_timestamp * 1000);
            row.voc_timestamp = new Date(row.voc_timestamp * 1000);
            row.hib_timestamp = new Date(row.hib_timestamp * 1000);

            deviceReport.push(row);
          }

          this.csv.exportToCsv(
            deviceReport,
            `History-${this.startDate.replaceAll(
              '-',
              ''
            )}-${this.endDate.replaceAll('-', '')}.csv`
          );
          this.generatingCSV = false;
        });
    } catch (error) {
      this.systemMessage.selectRibbon(
        'danger',
        'alert-danger-generic-message'
      );
      console.error("CSV Error: ", error)
      this.generatingCSV = false;
    }

  }

  // Function triggered with the X on the map div to close it
  closeMap(){
    this.hideMap = true;
  }

  // Function used to send user to statistic page with the selected device
  setNavToDeviceStat(thingName: string){
    sessionStorage.setItem('toDeviceStats', 'iot/technical')
    this.router.navigate([`/device-statistics/${thingName}`])
  }

  //function that adds a newline character between lat and long coordinates
  formatLocation(coordinates:string|undefined):string{
    let formatted = '';
    if(coordinates) formatted = coordinates.split(',')[0] + '\n' + coordinates.split(',')[1]
    return formatted
  }

  //function that toggles the device info element in mobile view
  toggleDeviceDetails(id:string){

    const element = document.getElementById(id);
    let arrow;
    if(element){
      arrow = document.getElementsByClassName(`${element.id}`)[0] as HTMLElement;
    }
    //show the device info of clicked device
    if(element && element.classList.contains('hidden')){
      this.renderer.removeClass(element,'hidden')
      this.renderer.addClass(element,'open')
      this.renderer.addClass(arrow,'open')

      this.flip180(arrow)
    }else{
      this.renderer.addClass(element,'hidden')
      this.renderer.removeClass(element,'open')
      this.renderer.removeClass(arrow,'open')

      this.flip90(arrow)
    }
    this.hideDeviceDetails(element);
  }

  // Function used in mobile view to hide the device detail box that appear under the device row in the list
  hideDeviceDetails(el:HTMLElement|null){
    let filteredArray;
    if(el){
      filteredArray = Array.from(document.querySelectorAll('.deviceInfo')).filter(element => element.id !== el.id)
      //hiddes the open device Info tab
      for(let el of filteredArray){
        if(el && !el.classList.contains('hidden') && el.classList.contains('open')){
          this.renderer.addClass(el,'hidden')
          this.renderer.removeClass(el,'open')

          const arrow = document.querySelector(`.${el.id}`) as HTMLElement;
          this.flip90(arrow)
        }
      }
    }
  }

  // functio that flips an element by 180 degrees
  flip180(element:HTMLElement|undefined){
    if(element)element.style.transform = `rotate(${180}deg)` ;
  }

  // function that flips an element by 90 degrees
  flip90(element:HTMLElement|undefined){
    if(element)element.style.transform = `rotate(${90}deg)` ;
  }

  // Function used to fade out an element
  fadeOutElement(element:HTMLElement) {
      if (element) {
          element.style.animation = 'fadeOutBottom 0.5s ease-in-out forwards';
      }
  }

  /**
  * function that checks if inputed value is string.
  *
  * @param value the input to check its type.
  * @returns a boolean indicationg wether value is a string or not
  */
  isString(value:any):boolean{
    const result = typeof(value) === 'string'? true: false;
    return result
  }

 // Function called to get to shadow feed page with or without a device
 async goToShadowFeed(deviceName: string){
  this.shadowFeedClicked();
  this.selectedThingsShadowFeed = [];

  this.selectedThingsShadowFeed.push(deviceName);
  await this.getShadowFeed();
 }

 // Function
 async getShadowFeed(){
  // Check first if user selected all devices
  if(this.allThingsShadowFeed){
    this.selectedThingsShadowFeed = this.things.map((thing: any) => {
      return thing.name;
    });
  }

  // Set start date to timestamp
  const startDate = new Date(this.startDateShadowFeed);
  const timestampStartDateHour = startDate.setUTCHours(0, 0, 0);
  const timestampStartDate = Math.floor(timestampStartDateHour / 1000)

  // Set end date to timestamp
  const endDate = new Date(this.endDateShadowFeed);
  const timestampEndDateHour = endDate.setUTCHours(23, 59, 59);
  const timestampEndDate = Math.floor(timestampEndDateHour / 1000);

  (await this.iotService.getShadowFeed(this.selectedThingsShadowFeed, timestampStartDate, timestampEndDate)).subscribe((response: any) => {
    this.thingsShadowLog = response;

    // Replace the timestamp by the readable date time
    this.thingsShadowLog.forEach((log: any) => {
      const newDate = new Date(Math.floor(log.timestamp));

      // Call the funcitno in iotService to convert timestamp by yyyy-mm-dd hh:mm:ss
      log.timestamp = this.iotService.formatDate(newDate);
    });
  });
 }

  // Function called to put or remove the ngIf boolean value on mouse over for the device state
  setShowStateInfo(item: any, value: boolean){
    item.showInfo = value;
  }

  // Function called to store in local storage the different filter
  storeFilters(){
    this.localStorageService.addItem('deviceFilter', this.deviceFilter);
    this.localStorageService.addItem('filterThingGroup', this.filterThingGroup);
    this.localStorageService.addItem('sortBy', this.sortBy);
    this.localStorageService.addItem('sortDirection', this.sortDirection);
  }

  // Function used in html to return value if it's not 0
  simOperator(value: string): string{
    if(parseInt(value) === 0){
      return "";
    }
    return value;
  }

  // Function used to refresh device data initialized for production
  async initializeDeviceForProduction(thingName: string){
    const confirm = window.confirm(await firstValueFrom(this.translate.get('initializeDevice')));

    // Wait to user to select confirm on the window message
    if(confirm){
      try{
        const initialized: any = await firstValueFrom(this.iotService.initializeDeviceForProduction(thingName));

        const shadowUpdate = await this.iotService.updateThingShadow(thingName);
        if(initialized.message === 'success'){
          this.systemMessage.selectRibbon('success', 'initializeWithSuccess');
        }
      }
      catch(error){
        console.error('Error',error);
        this.systemMessage.selectRibbon('danger', 'alert-danger-generic-message');
      }

    }
  }

  // Function used to made a search in the list
  search(){
    // Check if there's a filterBy variable and set it to it's default value
    if(this.filterBy === ''){
      this.filterBy = 'thingName';
    }
    this.iotService.devicesArray = this.filter.search(this.deviceFilter, this.iotService.devicesArray, this.iotService.originalDevicesArray, this.filterBy);
  }

  /**
   * Reset devicesArray variable to its original value. Using the searchbar set the variable
   * to a new array using the filter result, this is used to reset the new filtered array to its original
   * value when navigating between the Devices and Technical tabs. See search() for more details.
   */
  resetDevicesArrayToOriginalArray(){
    this.iotService.devicesArray = this.iotService.originalDevicesArray;
  }
}
