import { Injectable } from '@angular/core';
import { TruckBrands } from '../constants/truck-brands';
import { TruckTypes } from '../constants/truck-types';
import { TruckStatus } from '../constants/truck-status';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { environment } from '../environments/environment';
import { SystemMessageService } from './system-message.service';
import { map, catchError } from 'rxjs';
import { LocalStorageService } from '../local-storage.service';
import { RouteInterface } from './route.service';
import { error } from 'jquery';

export interface Truck{
  truck_id: string;
  operator_id: string; // owner of the truck
  driver_id: string;
  nickname: string; // truck name
  vin: string;
  brand: string; // const
  type: string; // const
  size: number; // user input
  capacity: number; // user input
  year: number;
  status: string; //const
  username: string; // driver's name
  routes?: any[];
  closedRoute?: boolean;
}

interface TruckID_Response{
  truck_id: string;
}

@Injectable({
  providedIn: 'root'
})

export class TruckService {
  trucksArray: Truck[]=[];
  original_trucks_array: Truck[] = [];
  trucks4Graph_array = []
  array: any;
  colorObj:any = [];

  successMessage: any;

  public truck: Truck = {
    truck_id: '',
    operator_id: '',
    driver_id: '',
    nickname: '',
    vin: '',
    brand: '',
    type: '',
    size: 0,
    capacity: 0,
    year: 0,
    status: '',
    username: ''
  }

  constructor(private http: HttpClient,
              private systemMessageService: SystemMessageService,
              private localStorageService: LocalStorageService
  ) {

  }

  // Function use to switch all value of the keys to int instead of string
  switchJSONToIntTruckResponse(element: any){
    // Tableau of witch keys we need to check
    const keysToCheck = [
      'size',
      'capacity',
      'year'
    ];
    // Call the function to transfert all value of the keys in Integer
    this.keyToTransfert(keysToCheck, element);
  }

  // Function use to convert string to int
  keyToTransfert(keysToCheck: any, element: any){
    keysToCheck.forEach((key: any) => {
      // Vérifier si la clé existe dans l'élément et si sa valeur est une chaîne
      if (typeof element[key] === 'string') {
          // Convertir la valeur de la clé en entier
          element[key] = parseInt(element[key], 10);
      }
    });
  }


  ////////////////////////////////////////   CRUD functions   ////////////////////////////////


  createTruck(){
    this.createTruckLambda().subscribe((response) => {
      this.successMessage = response; // Return response from lambda and put it in success message so bin-list or bin-model-list and will be able to read it

      if(this.successMessage.message === 'success'){
        this.localStorageService.addItem('createTruckSuccess', 'createTruckSuccess');
        //this.systemMessageService.selectRibbon('success','alert-success-generic-message');
      }else{
        this.localStorageService.addItem('createTruckFailed', 'createTruckFailed');
        //this.systemMessageService.selectRibbon('danger', 'alert-danger-generic-message');
      }
    });
  }

  updateTruck(){
    this.updateTruckLambda().subscribe((response) => {
      this.successMessage = response; // Return response from lambda and put it in success message so bin-list or bin-model-list and will be able to read it

      if(this.successMessage.message === 'success'){
        this.localStorageService.addItem('updateTruckSuccess', 'updateTruckSuccess');
        //this.systemMessageService.selectRibbon('success','alert-success-generic-message');
      }else{
        this.localStorageService.addItem('updateTruckFailed', 'updateTruckFailed');
        //this.systemMessageService.selectRibbon('danger', 'alert-danger-generic-message');
      }
    });
  }

  getAllTrucks(): Promise<Truck[]>{
    return new Promise<any[]>((resolve) => {
      // Call the function that call the lambda function
      this.getTrucksLambda().subscribe((response) => {
        // Put the array of Bins returned by the lambda function into an tmp array
        this.array = response;

        // Set the truck_array and original_truck_array whit the tmp array values
        this.trucksArray = this.array;
        this.original_trucks_array = this.array;
        resolve(this.array);
      });
    });
  }
  getTrucks4Graph(): Promise<Truck[]>{
    return new Promise<any[]>((resolve) => {
      // Call the function that call the lambda function
      this.getTrucks4GraphLambda().subscribe((response) => {
        // Put the array of Bins returned by the lambda function into an tmp array
        this.array = response;
        //console.log(response)
        const colorsArray = [
          { key: '0', color: "#FF5733" }, // Red
          { key: '1', color: "#33FF57" }, // Green
          { key: '2', color: "#3357FF" }, // Blue
          { key: '3', color: "#F3FF33" }, // Yellow
          { key: '4', color: "#FF33A1" }, // Pink
          { key: '5', color: "#33FFF5" }, // Cyan
          { key: '6', color: "#FF8C33" }  // Orange
        ];

        this.array.forEach((item:any)=>{
          this.colorObj.push(colorsArray.find((color) => color.key === item.truck_type));
        })
        // Set the truck_array and original_truck_array whit the tmp array values
        this.trucks4Graph_array = this.array
        resolve(this.array);
      });
    });
  }

  getTrucksByOperatorId(operator_id: string): Promise<Truck[]>{
    return new Promise<any[]>((resolve) => {
      // Call the function that call the lambda function
      this.getTrucksByOperatorIdLambda(operator_id).subscribe((response: any) => {
        // Put the array of Bins returned by the lambda function into an tmp array
        this.array = response;
        this.trucksArray = this.array;
        this.original_trucks_array = this.array;
        resolve(this.array);
      });
    });
  }

  getTruckByTruckId(truck_id: string): Promise<Truck[]>{
    return new Promise<any[]>((resolve) => {
      // Call the function that call the lambda function
      this.getTrucksByTruckIdLambda(truck_id).subscribe((response: any) => {
        // Put the array of Bins returned by the lambda function into an tmp array
        this.switchJSONToIntTruckResponse(response[0]);
        this.truck = response[0];
        resolve(this.array);
      });
    });
  }




  ////////////////////////////// Section for lambda funcitons ///////////////////////////////////////



  createTruckLambda(){
     // Define the HTTP headers with content type
     const headers = new HttpHeaders({
      'Content-Type':  'application/json' // Adjust content type as needed
    });
    // Will call the lambda function in createBin url whit the passed data then return a response
    return this.http.post(environment.api.stage + environment.api.route.createTruck, {
      // Doubled coats things are used into lambda function as data and used for the SQL's calls that those functions does
        "operator_id": this.truck.operator_id,
        "nickname": this.truck.nickname,
        "vin": this.truck.vin,
        "brand": this.truck.brand,
        "type": this.truck.type,
        "size": this.truck.size,
        "capacity": this.truck.capacity,
        "year": this.truck.year,
        "status": this.truck.status
      }, { headers: headers }
    );
  }

  updateTruckLambda(){
     // Define the HTTP headers with content type
     const headers = new HttpHeaders({
      'Content-Type':  'application/json' // Adjust content type as needed
    });
    // Will call the lambda function in createBin url whit the passed data then return a response
    return this.http.post(environment.api.stage + environment.api.route.updateTruck, {
        // Doubled coats things are used into lambda function as data and used for the SQL's calls that those functions does
        "truck_id": this.truck.truck_id,
        "operator_id": this.truck.operator_id,
        "nickname": this.truck.nickname,
        "vin": this.truck.vin,
        "brand": this.truck.brand,
        "type": this.truck.type,
        "size": this.truck.size,
        "capacity": this.truck.capacity,
        "year": this.truck.year,
        "status": this.truck.status
      }, { headers: headers }
    );
  }

  getTrucksLambda(){
    // Initiate url whit the url of lambda fonction getBins
    const url = environment.api.stage + environment.api.route.getAllTrucks;
    // Call the lambda fonction whit the url
    return this.http.get(url).pipe(
      map((response: any) => {
        return response;// Return response data
      }),
      catchError((error) => {
        //console.error('API Error : ' + error);
        throw error;
      })
    );
  }
  getTrucks4GraphLambda(){
    // Initiate url whit the url of lambda fonction getBins
    const url = environment.api.stage + environment.api.route.getTrucks4Graph;
    // Call the lambda fonction whit the url
    return this.http.get(url).pipe(
      map((response: any) => {
        return response;// Return response data
      }),
      catchError((error) => {
        //console.error('API Error : ' + error);
        throw error;
      })
    );
  }

  getTrucksByOperatorIdLambda(operator_id: string){
    // Initiate url whit the url of lambda fonction getBins
    const url = environment.api.stage + environment.api.route.getTrucksByOperatorId + '&operator_id=' + operator_id;
    // Call the lambda fonction whit the url
    return this.http.get(url).pipe(
      map((response: any) => {
        return response;// Return response data
      }),
      catchError((error) => {
        //console.error('API Error : ' + error);
        throw error;
      })
    );
  }

  getTrucksByTruckIdLambda(truck_id: string){
    // Initiate url whit the url of lambda fonction getBins
    const url = environment.api.stage + environment.api.route.getTruckByTruckId + '&truck_id=' + truck_id;
    // Call the lambda fonction whit the url
    return this.http.get(url).pipe(
      map((response: any) => {
        return response;// Return response data
      }),
      catchError((error) => {
        //console.error('API Error : ' + error);
        throw error;
      })
    );
  }

  getTruckIdByDriverIdHTTP(driver_id: string){
    // Define the HTTP headers with content type
    const headers = new HttpHeaders({'Content-Type':  'application/json'});
    const url = environment.api.stage + environment.api.route.getTruckIdByDriverId
    // Api call
    return this.http.post(url, {"driver_id": driver_id,}, { headers: headers });
  }

  async getTruckIdByDriverId(driver_id: string): Promise<string>{
    return new Promise<string>((resolve, reject) => {
      // Call the function that call the lambda function
      this.getTruckIdByDriverIdHTTP(driver_id).subscribe({
        next: (response: any) => {
          // cast response to the interface
          const truckResponse = response as TruckID_Response;
          // check if we have a reponse and if it has a truck_id
          if(truckResponse && truckResponse.truck_id){
            resolve(truckResponse.truck_id);
          }else{
            reject('No truck found for this driver');
          }
        },
        error: error =>{ reject(error); }
      });
    });
  }
}
