import { Injectable } from '@angular/core';
import { Devices, MultipleDevices } from './iot.service';
import { Subject } from 'rxjs';
import { environment } from '../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BinDetails } from './bins.service';
import { CognitoService } from './cognito.service';

export interface DeviceAssociation {
  thingName: string;
  client_id: string;
  bin_id: string;
  bin_depth: number;
}

export interface DeviceClient {
  thingName: string;
  client_id: string;
}

export interface DeviceNotes {
  device_note_id?: string;
  thing_name: string;
  user: string;
  given_name?: string;
  family_name?: string;
  note: string;
  prod_status?: number;
  created: number;
}

@Injectable({
  providedIn: 'root'
})

export class ModalService {
  // Store the name of the selected device as a string
  public selectedDeviceString: string = "";

  // Variable used only to show the app-device-modal
  public showDeviceModal: boolean = false;

  // Flags to control the visibility of different modals
  public showSingleDeviceModal: boolean = false;
  public showMultipleDevicesModal: boolean = false;
  public showJsonShadow: boolean = false;
  public showRelationship: boolean = false;
  public showNotesModal: boolean = false;
  public showProdStatusModal: boolean = false;

  // Variable that hold thingShadow in a json and thing name to show shadow of the thing in a modal
  public thingShadow: any;
  public thingName: string = '';
  public jsonStringTrimmed: string = '';
  public formattedJson: string = '';

  // Store the selected device as an object of type 'Devices'
  public selectedDevice: Devices = {} as Devices;

  // Store an array of selected multiple devices
  public selectedMultipleDevices: MultipleDevices[] = []

  // Store an array of names of selected multiple devices
  public selectedMultipleDevicesNames: any[] = []

  // A flag to check if a device has been selected
  public check: boolean = false;

  public twoTimeSameThing: boolean = false;

  public thingStatus: string = "";
  public prodStatusIndex: number = 0;
  // Variable for the open map on modal
  private showMap = new Subject<void>();
  private resetMap = new Subject<void>();

  // Variable for device association
  public tempDeviceAssociation: any;
  public deviceClient: DeviceClient = {
    thingName: '',
    client_id: ''
  }
  public deviceAssociationRow: any;
  public userType: string = '';
  public userRole: any;
  public clientsArray: any;
  public binArray: BinDetails[] = [];
  public distributorsArray: any;

  public deviceAssociation: DeviceAssociation = {
    thingName: '',
    client_id: '',
    bin_id: '',
    bin_depth: 0
  }

  public deviceNotes: DeviceNotes[] = [];
  public newNote: string = '';
  public newNoteFormated: DeviceNotes = {
    thing_name: '',
    user: '',
    given_name: '',
    family_name: '',
    note: '',
    prod_status: 0,
    created: 0
  }
  public oldNoteFormated: DeviceNotes = {
    device_note_id: '',
    thing_name: '',
    user: '',
    note: '',
    created: 0
  }

  // Variable used to show/hide loading logo
  public showLoadingStatus: boolean = false;

  constructor(private http: HttpClient,
              private cognitoService: CognitoService) {

  }

  // Function to set class thingStatus
  getStatus(status: string) {
    this.thingStatus = status
  }

  /**
   * Open a modal for a single device.
   * @param device - The device to display in the modal.
  */
  public openModalDevice(device: Devices){

    // Set the selected device
    this.selectedDevice = device;

    // Variable
    this.showDeviceModal = true;

    // Hide the multiple devices modal and show the single device modal
    this.showMultipleDevicesModal = false;
    this.showSingleDeviceModal = true;
  }

  // Variable that iotComponent gonna see to know to trigger his fonction
  showMap$ = this.showMap.asObservable();

  // Function that show modal of the device map
  showDeviceMap(){// Called from device-modal.html to trigger iotComponent show marker on map
    this.showMap.next();
  }

  // Variable that iotComponent gonna see to know to trigger his fonction
  resetMap$ = this.resetMap.asObservable();

  resetDeviceMap(){ // Called from device-modal.html to reset the map when you exit the modal
    this.resetMap.next();
  }

  // Get bin depth based on provided bin ID
  obtainBinDepthByBinId(binIdProvided: string): number | null {

    // Find the object in the array based on bin_id
    const foundBin = this.binArray.find((bin: any) => bin.bin_id === binIdProvided);

    // Check if the bin was found
    if (foundBin) {

        // Set the deviceAssociation's bin_depth to the bin_height from the found bin
        this.deviceAssociation.bin_depth = foundBin.details.bin_height;

        // Return the value of bin_depth if it exists, otherwise return null
        return foundBin.details.bin_height ?? null;
    } else {
        // Return null if the bin is not found
        return null;
    }
  }

  /**
   * Open a modal for selecting from multiple devices.
   * @param devices - The array of multiple devices to choose from.
   * @param deviceName - The name of the device.
  */
  public openModalMultipleDevices(devices: MultipleDevices, deviceName: string){
    // Add the device name to the list of selected multiple devices' names
    this.selectedMultipleDevicesNames.push(deviceName)

    // Add the selected multiple devices to the list
    this.selectedMultipleDevices.push(devices)

    // Show the multiple devices modal
    this.showMultipleDevicesModal = true;
  }

  // Function called when user want to show the shadow of the device
  showShadow(thingShadow: any, thingName: string){
    this.thingShadow = thingShadow;
    this.thingName = thingName;
    this.showDeviceModal = true;
    this.showJsonShadow = true;
  }

  // Function called to show device note modal
  async showNotes(thingName: string){
    this.thingName = thingName;
    this.showLoadingStatus = true;
  }

  
  showProdStatus(thingName : string, prodStatusIndex : number = 0){
    this.thingName = thingName;
    this.showDeviceModal = true;
    this.showProdStatusModal = true;
    this.prodStatusIndex = prodStatusIndex;
  }
  
  convertShadowToJson() {
    const json = JSON.stringify(this.thingShadow, null, 2).replace(/\\n/g, '\n');

    return json;
  }


  /**
   * Close the modals and reset selected devices.
  */
  closeModal(){
    // Clear the arrays storing selected multiple devices and their names
    this.selectedMultipleDevices = [];
    this.selectedMultipleDevicesNames = [];

    // Hide both the single device modal and the multiple devices modal
    this.showDeviceModal = false;
    this.showMultipleDevicesModal = false;
  }

  /**
   * Retrieves a device using its name from a list of selected multiple devices.
   * @param name - The name of the device to find.
  */
  public getDeviceUsingName(name: string) {
    // Filter the selected multiple devices to find a device with the given name
    const matchingDevices = this.selectedMultipleDevices.filter(device => {
      // Use the some function to check if any device has the given name
      return device.devices.some(deviceItem => deviceItem.thingName === name);
    });

    // Check if matching devices were found
    if (matchingDevices) {
      // Set the selected device to the first device in the matching devices list
      this.selectedDevice = matchingDevices[0].devices[0];

      // Set a flag to indicate that a device was found
      this.check = true;
    }
  }

  // Function called when the "Ok" button is clicked in a modal
  public onOkClick() {
    // Call the 'getDeviceUsingName' function to find the selected device by its name
    this.getDeviceUsingName(this.selectedDeviceString)

    // Check if a matching device was found
    if (this.check) {
      // Call a function to open a modal for the selected device
      this.openModalDevice(this.selectedDevice);
    }
  }

  // Function that will save the note taken on device
  async saveNotes(){
    // Create variables for user and timestamp
    const user = await this.cognitoService.getUser();
    const dateTimestamp = new Date().getTime();
    
    // Set the newNoteFormated object to the new values
    this.newNoteFormated.thing_name = this.thingName;
    this.newNoteFormated.user = user.attributes['sub'];
    this.newNoteFormated.created = dateTimestamp;
    this.newNoteFormated.note = this.newNote;
    this.newNoteFormated.given_name = user.attributes['given_name'];
    this.newNoteFormated.family_name = user.attributes['family_name'];

    // Push to the begining the new obj in the array
    this.deviceNotes.unshift(this.newNoteFormated);

    // Call the function to save the device note in the DB
    this.saveNoteDBCall().subscribe();

    setTimeout(()=> {
      // Clear the textarea
      this.newNote = '';
    },500)
  }

  // Function called the update device note
  updateNote(){
    // Set newNoteFormated  note to the instance of the textarea in the form
    this.newNoteFormated.note = this.newNote;

    // Call function to update the note in DB
    this.updateNoteDBCall().subscribe();

    // Set a time out of 0.5 sec
    setTimeout(() => {
      // Clear the textarea
      this.newNote = '';

      // Get the index of the note that user update
      const indexToRemove = this.deviceNotes.findIndex((obj: DeviceNotes) => {obj.device_note_id === this.oldNoteFormated.device_note_id});

      // If we found the note index, it remove it from the array
      if(indexToRemove > -1){
        this.deviceNotes.splice(indexToRemove, 1);
      }

      // Push the updated note to the begining of the array
      this.deviceNotes.unshift(this.newNoteFormated);
    }, 500);
  }

  // Function that is called to save note in DB
  saveNoteDBCall(){
    // Define the HTTP headers with content type
    const headers = new HttpHeaders({
      'Content-Type':  'application/json' // Adjust content type as needed
    });
    // Call lambda fucntion with the url of updateBin  and return the response
    return this.http.post(environment.api.stage + environment.api.route.createDeviceNote, {
      // Doubled quotes things are used into lambda function as data and used for the SQL's calls that those functions does
        "user": this.newNoteFormated.user,
        "thing_name": this.thingName,
        "note": this.newNote,
        "created": this.newNoteFormated.created
      }, {headers : headers}
    );
  }

  // Function called to update device note in DB
  updateNoteDBCall(){
    // Define the HTTP headers with content type
    const headers = new HttpHeaders({
      'Content-Type':  'application/json' // Adjust content type as needed
    });
    // Call lambda fucntion with the url of updateBin  and return the response
    return this.http.post(environment.api.stage + environment.api.route.updateDeviceNote, {
      // Doubled quotes things are used into lambda function as data and used for the SQL's calls that those functions does
        "device_note_id": this.newNoteFormated.device_note_id,
        "user": this.newNoteFormated.user,
        "thing_name": this.newNoteFormated.thing_name,
        "note": this.newNoteFormated.note,
        "created": this.newNoteFormated.created
      }, {headers : headers}
    );
  }

  async saveProdStatusAPI(prodStatus:number){
    // Define the HTTP headers with content type
    const headers = new HttpHeaders({
      'Content-Type':  'application/json' // Adjust content type as needed
    });
    // Create variables for user sub and timestamp
    const userSub = await this.cognitoService.getCurrentUserSub();
    const dateTimestamp = new Date().getTime();
    // Call lambda fucntion with the url of saveProductionStatus  and return the response
    return this.http.post(environment.api.stage + environment.api.route.saveProductionStatus, {
      // Doubled quotes things are used into lambda function as data and used for the SQL's calls that those functions does
        "thing_name": this.thingName,
        "user_id": userSub,
        "production_status": prodStatus,
        "date": dateTimestamp
      }, {headers : headers}
    );
  }
}


