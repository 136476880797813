<!-- <app-system-message></app-system-message> -->

<!-- Container for the form with dynamic styling -->
<section [ngClass]="theme.getThemeClass()" class="container-fluido"> 
    <article [ngClass]="theme.getThemeClass()" class="card bg-light">
        <article class="card-body mx-auto article">
            <h4 class="card-title mt-0 text-center">{{ 'ASSIGN ROUTE' | translate }}</h4>
            <form>
                <div>
                    <label class="label-spacer">{{ 'selectedTruck' | translate }}</label>
                    <div class="input-group mb-3">
                        <span class="input-group-text" id="basic-addon1"><i class="fas fa-truck"></i></span>
                        <input #thing_select disabled
                        class="form-control"
                        name="thing"
                        placeholder="this.dispatcherControlCenter.selectedTruck"
                        [(ngModel)]="this.dispatcherControlCenter.selectedTruck"
                        >
                    </div>
                    <label class="label-spacer">{{ 'selectDriver' | translate }}<span class="requiredStar"> *</span></label>
                    <div class="input-group mb-3">
                        <span class="input-group-text" id="basic-addon1"><i class="fas fa-id-card-alt"></i></span>
                        <ng-select [(ngModel)]="this.driverId"
                            #driver_select
                            class="form-control"
                            name="driver"
                            (change)="selectedDriverChange($event)"
                            placeholder="{{ 'driverName' | translate }}"
                            [attr.selected]="this.driverId"
                            [ngClass]="{'has-value': this.driverId}"
                            >
                            <ng-container *ngFor="let driver of drivers">
                                <ng-option [value]="driver.user" >{{ driver.given_name  }} {{ driver.family_name  }} </ng-option>
                            </ng-container>
                        </ng-select>
                    </div>
                    <label class="label-spacer" for="deadline">{{ 'routeStart' | translate }} <span class="requiredStar">*</span></label>
                    <div class="input-group mb-3">
                        <span class="input-group-text" id="deadline-input"><i class="far fa-clock"></i></span>
                        <input
                            type="datetime-local"
                            class="form-control"
                            aria-label="Deadline"
                            aria-describedby="deadline-addon"
                            (input)="updateRouteStart($event)"
                        />
                    </div>
                    <!-- DESCRIPTION TEXT INPUT  -->
                    <label class="label-spacer" for="deadline">{{ 'commentsOrInstructions' | translate }}</label>
                    <div class="input-group mb-3">
                        <textarea class="descriptionStyle bg-light"
                        id="descricao" name="descricao"
                        [(ngModel)]="routeComments"
                        maxlength="600"
                        (touchstart)="startScroll($event)"
                        (touchmove)="scrollTextarea($event)"
                        (touchend)="stopScroll()"></textarea>
                        <!-- REMAINING CHARACTERS COUNTER -->
                        <!-- <div>{{ 'remainingCharacters' | translate }} {{ 600 - descriptionData.description.length }}</div> -->
                    </div>

                    <!-- Submit button -->
                    <div class="form-group displayFlex">
                        <button (click)="cancel()" [ngClass]="theme.getThemeClass()" type="button" class="btn btn-primary btn-block btnCancel fixed-size">{{ 'clientcreateFormBtnCancel' | translate }}</button>
                        <button (click)="assignRoute(this.driverId)" [ngClass]="theme.getThemeClass()" type="button" class="btn btn-primary btn-block btnCreate fixed-size">
                        <span >
                            {{ "clientcreateFormBtnCreateClient" | translate }}
                        </span>
                        <!-- <div
                            class="spinner-border spinner-position spinner-button"
                            role="status"
                            *ngIf="isSaving"
                        > -->
                            <!-- <span class="visually-hidden">Loading...</span> -->
                        <!-- </div> -->
                        </button>
                    </div>





                </div>
            </form>
        </article>
    </article>
</section>
