import { Injectable } from '@angular/core';

Injectable({
  providedIn: 'root'
})

// Constant used for bin usage selects, label are keys for translate in .json files
export const BinUsage = [
    { "value" : "GW", "label" : "binSelectOptionGeneralWaste", "weight": 350},
    { "value" : "GR", "label" : "binSelectOptionGeneralRecycling", "weight": 350},
    { "value" : "MT", "label" : "binSelectOptionMetal", "weight": 600},
    { "value" : "PC", "label" : "binSelectOptionPaper", "weight": 250},
    { "value" : "PL", "label" : "binSelectOptionPlastic", "weight": 300},
    { "value" : "CN", "label" : "binSelectOptionCans", "weight": 400},
    { "value" : "GL", "label" : "binSelectOptionGlass", "weight": 450},
    { "value" : "OR", "label" : "binSelectOptionOrganic", "weight": 300},
    { "value" : "BA", "label" : "binSelectOptionBatteries", "weight": 400},
    { "value" : "MW", "label" : "binSelectOptionMedicalWaste", "weight": 300},
    { "value" : "RB", "label" : "binSelectOptionRubber", "weight": 400},
    { "value" : "TW", "label" : "binSelectOptionTechWaste", "weight": 400},
    { "value" : "CM", "label" : "binSelectOptionConstructionMaterial", "weight": 375},
    { "value" : "CL", "label" : "binSelectOptionClothing", "weight": 250},
    { "value" : "CB", "label" : "binSelectOptionCardBoard", "weight": 250},
];
