import { Component, Inject, forwardRef } from '@angular/core';
import { RelationshipModalService } from '../service/relationship-modal.service';
import { ThemeService } from '../service/theme.service';
import { TranslateService } from '@ngx-translate/core';
import { IotService } from '../service/iot.service';
import { firstValueFrom } from 'rxjs';
import { LocalStorageService } from '../local-storage.service';

@Component({
  selector: 'app-distributor-iot-association-modal',
  templateUrl: './distributor-iot-association-modal.component.html',
  styleUrls: ['./distributor-iot-association-modal.component.css','../../global-elements.css']
})
export class DistributorIotAssociationModalComponent{

  private systemMessage: string = '';

  constructor(public relationModalService: RelationshipModalService,
              public theme: ThemeService,
              @Inject(forwardRef(() => TranslateService)) private translate: TranslateService,
              private iotService: IotService,
              private localStorage: LocalStorageService){

  }

  // Function called to close the association modal
  closeModal(){
    // Put to false all the show/hide html variables
    this.relationModalService.showAssociationModal = false;
    this.relationModalService.showClientAssociationModal = false;
    this.relationModalService.showBinAssociationModal = false;

    // Empty all arrays related to the modal
    this.relationModalService.clientArray = [];
    this.relationModalService.binArray = [];

    // Empty all data related to the modal
    this.relationModalService.oldClient = '';
    this.relationModalService.selectedClient = '';
    this.relationModalService.selectedBin = '';
    this.relationModalService.distributor_id = '';
    this.relationModalService.thing_name = '';
  }

  // Function called to save association of client to a bin by a distributor
  async saveAssociation(){
    // Set a variable for the date
    const date = new Date().getTime();
    // set thing_name variable in iot service
    this.iotService.thingName = this.relationModalService.thing_name;
    // Set variables for update
    const client_id = this.relationModalService.selectedClient;
    let bin_id = this.relationModalService.selectedBin;
    const distributor_id = this.relationModalService.distributor_id;

    // If the user change the client association thingAssociationClientChange
    if(this.relationModalService.selectedClient !== this.relationModalService.oldClient){
      if(bin_id !== ''){
        const message: string = await firstValueFrom(this.translate.get('thingAssociationClientChange'));
        const confirmation = window.confirm(message);

        if(confirmation){
          // Set bin_id to nothing because user decide to change client even
          bin_id = '';
        }else{
          this.closeModal();
        }
      }
      (await this.iotService.updateDeviceAssociationLambda(client_id, bin_id, distributor_id, date)).subscribe((response) => {
        const message: any = response;

        if(message.message === 'success'){
          this.localStorage.addItem('processMessage', 'success');
        }
        else{
          this.localStorage.addItem('processMessage', 'fail');
        }

        this.closeModal();
        window.location.reload();
      });
    }
    // If user change bin association
    else{
      (await this.iotService.updateDeviceAssociationLambda(client_id, bin_id, distributor_id, date)).subscribe((response) => {
        const message: any = response;

        if(message.message === 'success'){
          this.localStorage.addItem('processMessage', 'success');
        }
        else{
          this.localStorage.addItem('processMessage', 'fail');
        }

        this.closeModal();
        window.location.reload();
      });
    }
  }
}
