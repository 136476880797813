import { Injectable } from '@angular/core';
import { Bin } from './bins.service';

interface Client {
  client_id: string;
  client_name: string;
  legal_name: string;
  email: string;
  address: string;
  phone_number: number;
  mws: number;
  active: number;
  created?: number;
  modified?: number;
}

@Injectable({
  providedIn: 'root'
})
export class RelationshipModalService {

  public binQuantity: number = 0;
  public binModelsQuantity: number = 0;
  public userQuantity: number = 0;
  public devicesQuantity: number = 0;
  public showModal: boolean = false;
  public deleteClientConfirmation: boolean = false;
  public updateTarget: string = '';

  public thing_name: string ='';

  // Variable used from distriubtor iot association
  public showAssociationModal: boolean = false;
  public showClientAssociationModal: boolean = false;
  public showBinAssociationModal: boolean = false;
  public selectedClient: string = '';
  public distributor_id: string = '';
  public oldClient: string = '';
  public client_legal_name: string = '';
  public selectedBin: string = '';
  public bin_name: string = '';
  public binArray: Bin[] = [];
  public clientArray: Client[] = [];
  public associatedClient: string = '';
  public associatedBin: string = '';


  constructor() { }


}
