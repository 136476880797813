import { Component, Inject, OnInit, forwardRef } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LocalStorageService } from '../local-storage.service';
import { ThemeService } from '../service/theme.service';
import { CognitoService } from '../service/cognito.service';
import { DashboardService } from '../service/dashboard.service';
import { ModalService } from '../service/device-modal.service';
import { ActivatedRoute, Router } from '@angular/router';
import { RoleService } from '../service/role.service';
import { Truck, TruckService } from '../service/truck.service';
import { TruckBrands } from '../constants/truck-brands';
import { TruckTypes } from '../constants/truck-types';
import { TruckStatus } from '../constants/truck-status';
import { FilterService } from '../service/filter.service';
import { environment } from '../environments/environment';
import { TruckModalService } from '../service/truck-modal.service';
import { SystemMessageService } from '../service/system-message.service';

@Component({
  selector: 'truck-list',
  templateUrl: './truck-list.component.html',
  styleUrl: './truck-list.component.css'
})
export class TruckListComponent {
  languageStatus:string = this.localStorageService.getItem('language');

  public userSub: any;
  public userUsername: any;
  public userType: any;
  public userId: any;
  public userRoles: any;

  public operator_id: string = '';

  public trucksArray: Truck[] = [] // Array of trucks
  public truckFilter: string = ""; // Filter for trucks
  public originalTruckArray: Truck []= [] // to save original truck array for filters
  public truckTypes:any;

  public filterBy: string = '';
  public sortBy: string = '';
  public ascDesc: number = 0;
  public isPlaceholderVisible: boolean = false;
  public filterCount: number = 0;
  public lastFilterCount: number = 0;
  public isFilter: boolean = false;

  public TruckStatus = TruckStatus;
  public TruckBrand = TruckBrands;
  public TruckType = TruckTypes;

  constructor(
    @Inject(forwardRef(() => TranslateService)) public translate: TranslateService,
    private localStorageService: LocalStorageService,
    public theme: ThemeService,
    private cognitoService: CognitoService,
    private route: ActivatedRoute,
    public roleService: RoleService,
    public truckService: TruckService,
    public dashboardService: DashboardService,
    public modal: ModalService,
    private router: Router,
    private filter:FilterService,
    public truckModalService: TruckModalService,
    private systemMessageService: SystemMessageService,
    ){
      this.route.queryParams.subscribe(params => {
        /* this.userSub = params['userSub'];
        this.userUsername = params['userUsername'];
        this.userType = params['userType']; */
    });
    // Check if the user has selected a language in local storage
      //or use a default language
      if (this.languageStatus == null){
        // Set the default language to French
        translate.use('fr');
      } else {
        // Set the default language to the user's selected language
        translate.use(this.languageStatus);
      }
      this.truckTypes = TruckTypes;
  }
  async ngOnInit() {
    // Get user type, roles, confir that user is valid in cognito
    await this.cognitoService.getUserType();
    await this.cognitoService.confirmValidUser();
    await this.roleService.getRoles().then(async (result)=>{
      const userRoles = JSON.parse(JSON.stringify(result))
      // Check with user roles and type if he's alowed in that page, if not he'll be redirect to his dashboard
      await this.cognitoService.getCurrentRole([environment.users.role.administrator, environment.users.role.operations, environment.users.role.dispatch],
      [environment.users.superAdmin, environment.users.maintenance],
      true, userRoles);
      }
    );

    this.operator_id = await this.cognitoService.getUser().then((user) =>{
      return user.attributes['custom:operations_id'];
    });

    // If we are the devs, display all trucks
    if(this.cognitoService.userType === 'muirwood'){
      this.trucksArray = await this.truckService.getAllTrucks();  // get the trucks array
    }
    else{
      // Get all the trucks own by the operator
      this.trucksArray = await this.truckService.getTrucksByOperatorId(this.operator_id)
    }
    this.originalTruckArray = this.trucksArray;//save the orignal truck array

    this.checkCRUDTrucksRibbons();
  }

  //navigates to the truck-create-component
  toCreateTruck() {
    sessionStorage.setItem('createTruck', 'truck-list')
    this.router.navigate(['/operator-create-truck'])
  }

  toUpdateTruck(truckID:string){
    if(this.roleService.roles.includes('_admin')){
      sessionStorage.setItem('updateTruck', 'truck-list')
      // If the user is an administrator, navigate to the operator-update-truck page
      this.router.navigate(['./operator-update-truck'], {
        queryParams: {
            truckID
        }
      });
    }
  }

  // Function called to get the style of the columns of the grid list
  getColumnStyle(): any {
    const numberOfColumn = this.ajustNumberOfColumns(this.getScreenWidth());
    if(numberOfColumn){
      if(this.trucksArray.length >= numberOfColumn){
        const gridColumns = `repeat(${numberOfColumn}, minmax(0, 300px))`;
        return {
          'grid-template-columns': gridColumns
        };
      }else{
        return {
          'display': `flex`,
          'justify-content': `center`,
          'margin': '0 -10px'
        };
      }
    }
  }

  // function called to adjust the number of column of the grid depbending of inner window width
  ajustNumberOfColumns(width: number): number | undefined{
    // Return the floor of the inner page width divided by 450 px as the width of the tile size
    return Math.floor((width / 325) * (90 / 100));
  }

  // Function to get the current screen width
  getScreenWidth(): number {
    // Return the inner width of the window as the screen width
    return window.innerWidth;
  }

  onSortChange(caller: string) {
     // Switch the value of ascending or descending
     if(caller === 'switch'){
      if(this.ascDesc === 0){
        this.ascDesc = 1;
      }else{
        this.ascDesc = 0;
      }
    }

    // Avoid sortBy to have no value
    if(this.sortBy === ''){
      this.sortBy = 'address';
    }

    // Function that sort the list
    this.trucksArray.sort((a, b) => {
      switch(this.sortBy){
          case 'size':
              return this.ascDesc === 1 ? b.size - a.size : a.size - b.size;

          case 'capacity':
              return this.ascDesc === 1 ? b.capacity - a.capacity : a.capacity - b.capacity;

          case 'year':
              return this.ascDesc === 1 ? b.year - a.year : a.year - b.year;

          // Add more cases for other numerical properties if needed

          default:
              return 0; // Default return value when sortBy doesn't match any case
      }
    });
  }

  search() {
       // Check if there's a filterBy variable and set it to it's default value
       if(this.filterBy === ''){
        this.filterBy = 'truckBrand';
      }
      this.trucksArray = this.filter.search(this.truckFilter, this.trucksArray, this.originalTruckArray, this.filterBy);
  }

  // Function used in HTML to get the proper status key to translate
  getTruckStatusKey(status: string){
    const statusLabel = this.TruckStatus.find(item => item.key === status.toString());
    return statusLabel ? statusLabel.value : 'unknow';
  }

  // Function used in HTML to get the proper type key to translate
  getTruckTypeKey(type: string){
    const typeLabel = this.TruckType.find(item => item.key === type.toString());
    return typeLabel ? typeLabel.value : 'unknow';
  }

  // Function used in HTML to get the proper brand key to translate
  getTruckBrandKey(brand: string){
    const brandLabel = this.TruckBrand.find(item => item.key === brand.toString());
    return brandLabel ? brandLabel.value : 'unknow';
  }

  /**
   * Function to check if we need to display success or failed ribbons on truck creation or update
   */
  checkCRUDTrucksRibbons() {
    const checkCreateTruckSuccess = this.localStorageService.getItem('createTruckSuccess');
    if(checkCreateTruckSuccess){
      this.systemMessageService.selectRibbon('success','alert-success-generic-message');
      this.localStorageService.removeItem('createTruckSuccess');
    }

    const checkCreateTruckFailed = this.localStorageService.getItem('createTruckFailed');
    if(checkCreateTruckFailed){
      this.systemMessageService.selectRibbon('danger', 'alert-danger-generic-message');
      this.localStorageService.removeItem('createTruckFailed');
    }
    
    const checkUpdateTruckSuccess = this.localStorageService.getItem('updateTruckSuccess');
    if(checkUpdateTruckSuccess){
      this.systemMessageService.selectRibbon('success','alert-success-generic-message');
      this.localStorageService.removeItem('updateTruckSuccess');
    }

    const checkUpdateTruckFailed = this.localStorageService.getItem('updateTruckFailed');
    if(checkUpdateTruckFailed){
      this.systemMessageService.selectRibbon('danger', 'alert-danger-generic-message');
      this.localStorageService.removeItem('updateTruckFailed');
    }
  }
}



