import { Component, OnInit, ElementRef, HostListener, Inject, forwardRef } from '@angular/core';
import { RoleService } from '../service/role.service';
import { CognitoService } from '../service/cognito.service';
import { TranslateService } from '@ngx-translate/core';
import { LocalStorageService } from '../local-storage.service';
import { ClientService } from '../service/client.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ValidationService } from '../service/validation.service';
import { Router } from '@angular/router';
import { ThemeService } from '../service/theme.service';
import { NavigationService } from '../service/navigation.service';
import { Roles } from '../constants/roles'
import { SystemMessageService } from '../service/system-message.service';
import { Types } from '../constants/user-type';
import { LocalizationService } from '../service/localization.service';
import { environment } from '../environments/environment';

@Component({
  selector: 'app-user-create-muirwood',
  templateUrl: './user-create-muirwood.component.html',
  styleUrls: ['./user-create-muirwood.component.css']
})
export class UserCreateMuirwoodComponent implements OnInit {

  public RolesArray = Roles;
  public form: FormGroup

  // Variable to store the language selected value
  public selectedLanguage: string = "";

  public successMessage: boolean = false;

  public autocomplete: any = google.maps.places; // Google Places Autocomplete

  // Retrieve current language selected from local storage
  languageStatus:string = this.localStorageService.getItem('language');

  // Array Fetch customer data
  public clientsData: any[] = [];

  public clientArray: any[] = [];

  public mapa: any = {};

  public newClientArray: any [] = [];

  public filteredArray: any[] = []

  public validateRolesCheckBox: boolean = false;

  public Types = Types;

  // Variable used for there system message display
  public systemMessage: string = '';

  // Variable to store the result of a place selection from Google Places Autocomplete.
  // It can hold a valid PlaceResult object or be undefined if no place is selected.
  selectedPlace: google.maps.places.PlaceResult | undefined;

  constructor(
    private localStorageService: LocalStorageService,
    @Inject(forwardRef(() => TranslateService)) private translate: TranslateService,
    public cognitoService: CognitoService,
    public roleService: RoleService,
    public clientService: ClientService,
    private formBuilder: FormBuilder,
    public validationService: ValidationService,
    private router: Router,
    public theme: ThemeService,
    public navigationService: NavigationService,
    public systemMessageService: SystemMessageService,
    public localizationService: LocalizationService
  )
  {
    // Create a form using the FormBuilder
    this.form = this.formBuilder.group({
      // Define form controls and their initial values
      client_name: ['', Validators.required],
      legal_name: [''],
      email: ['', [Validators.required, Validators.email]],
      phone_number: [''],
      address: ['']
    });

    this.cognitoService.confirmValidUser();
    this.roleService.getRoles();

    // Check if the user has selected a language in local storage
    //or use a default language
    if (this.languageStatus == null){
      // Set the default language to French
      translate.use('fr');
    } else {
      // Set the default language to the user's selected language
      translate.use(this.languageStatus);
    }
  }

  async ngOnInit(): Promise<void> {
    await this.cognitoService.getCurrentRole(
      [environment.users.role.administrator],
      [environment.users.superAdmin]
    ); //Role Check
    this.systemMessageService.buttonBlocked = false;

    this.cognitoService.clearCognitoUserData(); // clear last client create data
    this.getClientList(); // to fill option of select client input
    this.localizationService.initAutocomplete(); // init autocomplete api
  }

  disableButton() {
    this.systemMessageService.buttonBlocked = true;
  }

  // that return client list with only client_id and client_name
  getClientList() {
    try {
      // Use the clientService to fetch client data from an API
      this.clientService.getClients().subscribe(
        // Successful response callback
        (response: any) => {
          // Store the API response in the clientsData array
          this.clientsData = response;

          this.filteredArray = this.clientsData.filter(item => item.client_name === 'Muirwood Studio');

          // Create a copy of the clientArray for further processing
          this.clientArray = this.clientsData;

          // Deduplicate client data based on client_name and client_id
          this.newClientArray = this.filteredArray.reduce((accumulator, item) => {
            const key = item.client_name + item.client_id;
            if (!this.mapa[key]) {
              this.mapa[key] = true;
              // Add unique clients to the newClientArray
              accumulator.push({ client_id: item.client_id, client_name: item.client_name });
            }
            return accumulator;
          }, []);

          this.clientService.clientData.client_id = this.newClientArray[0].client_id;
          this.cognitoService.cognitoUserData.custom_client_id = this.newClientArray[0].client_id;

          this.clientService.clientData.client_name = this.newClientArray[0].client_name;
          this.cognitoService.cognitoUserData.client_name = this.newClientArray[0].client_name;
        }
      );
    } catch (error) {
      // Handle and log any errors that occur during the process
      console.error("Error: ", error);
    }
  }

  // Decorator @HostListener listens to the 'input' event on the specified target element
  @HostListener('input', ['$event.target']) onInput(input: HTMLInputElement): void {
    // Check if the input element has the class 'telefone-input'
    if (input.classList.contains('telefone-input')) {
      // Remove all non-numeric characters from the input value
      const value = input.value.replace(/\D/g, '');

      // Check if the value has a length of 10 characters or less
      if (value.length <= 10) {
        // Format the phone number as (000) 000-0000
        input.value = this.formatPhoneNumber(value);
      } else {
        // If the value is longer than 10 digits, limit the input to 10 digits
        input.value = input.value.slice(0, 10);
      }
    }
  }

  // runs a validation test checking every inputs
  checkValidationAllInputs() {

    // Check if username is not empty, its length is greater than 16,
    // or it doesn't match the alphanumeric pattern
    this.validationService.validateClientName(this.cognitoService.cognitoUserData.username);

    // Check if given_name is not empty, its length is greater than 16,
    // or it doesn't match the alphanumeric pattern
    this.validationService.validateGivenName(this.cognitoService.cognitoUserData.given_name);

    // Check if family_name is not empty, its length is greater than 16,
    // or it doesn't match the alphanumeric pattern
    this.validationService.validateFamilyName(this.cognitoService.cognitoUserData.family_name);

    // Check if the clientEmail is not a valid email or is empty
    this.validationService.validateClientEmail(this.cognitoService.cognitoUserData.email);

    this.validationService.validateRoles();

    // Define a mapping of error codes to corresponding error messages
    const errorMappings: Record<string, string> = {
      'roleSelectionInvalid': 'invalidRoleSelection',
      'usernameInvalid': 'invalidUserNameError',
      'givenNameInvalid': 'invalidGivenNameError',
      'familyNameInvalid': 'invalidFamilyNameError',
      'clientEmailInvalid': 'invalidEmail',
      'clientPhoneInvalid': 'invalidInput',
      'clientAddressInvalid': 'invalidAddress',
      'usernameNotUnique': 'usernameInUse',
    };

    // Retrieve the first validation error from the array
    const validationError = this.validationService.validationErrorArray[0];

    // Check if the validation error code exists in the mapping
    if (errorMappings[validationError]) {
      this.systemMessageService.buttonBlocked = true;

      // If so, display a danger ribbon message with the corresponding key
      this.systemMessageService.selectRibbon('danger', errorMappings[validationError]);
    } else {
      console.error('An error occurred, please contact support');
    }

  }

  // Function triggered when the "Ok" button is clicked
  async createBtn() {
    // Set the address of the cognito user data
    this.cognitoService.cognitoUserData.address = this.localizationService.autoCompletionReturnAddress;

    // Remove non-numeric characters from the phone number using the validationService
    this.validationService.keepOnlyNumbers(this.cognitoService.cognitoUserData.phone_number);
    // Update the client's phone number and creation date with the formatted values
    this.cognitoService.cognitoUserData.phone_number = this.validationService.phoneNumberFixed;

    // Check validation for all input fields
    this.checkValidationAllInputs();

    // Check if any of the validation conditions are met (username, family name, email, phone number, or address)
    if(this.validationService.usernameValid &&
      this.validationService.givenNameValid &&
      this.validationService.familyNameValid &&
      this.validationService.clientEmailValid &&
      this.validationService.userRolesValid
      ) {

      // If all validations pass, sign up the client user and send a temporary password
      try {
        // Attempt to sign up the user and send a temporary password
        await this.cognitoService.UserSignUpAndSendTemporaryPassword(Types[0].label, Roles[0].label);

        // Variable used by bin-model-list html to show the proper message on success updating bin model
        sessionStorage.setItem("from", "user-create");
        // If cognito add user doesn't break then call the function to get back to admin component
        this.returnToAdmin();
      } catch(error: any) {
        if (error.response && error.response.status === 400) {
          // Handle 400 Bad Request errors
          if (error.name === 'UsernameExistsException') {
            console.error("Bad Request: User account already exists.");

            // Set the message throw to the user
            this.systemMessage = 'userAlreadyExist';

          } else {
            // Log an error if the 400 error is not due to an existing username
            console.error("Error creating user:", error.message);
          }
        } else {
          // Handle unexpected errors
          if(error.toString().includes("UsernameExistsException")){
            // Set the message throw to the user

            this.systemMessage = 'userAlreadyExist';
          }
          console.error("Unexpected error:", error);
        }

        if(error.toString().includes("InvalidParameterException") && error.toString().includes("username")){
          this.systemMessage = "invalidUsernameFormat"
        }

        this.systemMessageService.buttonBlocked = true;
        // Set the username as invalid if error = UsernameExistsException: User account already exists
        this.systemMessageService.selectRibbon('danger', this.systemMessage);
      }
    }else{

      // Set the message error throwed to the uer
      this.systemMessage = 'fieldEmptyOrIncorrect';
      // throw the message to the user
      this.systemMessageService.buttonBlocked = true;

      this.systemMessageService.selectRibbon('danger', this.systemMessage);
    }
  }

  // function to return to admin page when cancel button is clicked
  returnToAdmin() {
    this.localStorageService.addItem('adminContent', 'user-create-client');
    // this.navigationService.entitiesContent = "clients"
    // window.location.reload();
    this.router.navigate(['/admin']); // Redirect to the '/client-list' route after the delay
  }

  // Function to format a numeric string as a phone number in the format (000) 000-0000
  formatPhoneNumber(value: string): string {
    // Check if the input value is empty and return an empty string if it is
    if (value.length === 0) return '';

    // Extract the area code, first part, and second part of the phone number
    const areaCode = value.slice(0, 3);
    const firstPart = value.slice(3, 6);
    const secondPart = value.slice(6, 10);

    // Format the phone number as (000) 000-0000 and return the formatted string
    return `(${areaCode}) ${firstPart}-${secondPart}`;
  }

  // Function that avoid submitting the page when user press enter at the end of inputting address in address input
  onInputAddressKeydown(event: KeyboardEvent): void {
    if(event.key === 'Enter'){
      event.preventDefault(); // Don't submit the page
    }
  }

  // Function to change the language based on user selection
  changeLanguage(language: string) {
    // Use the 'translate' service to set the language to the specified 'language'
    this.translate.use(language);
  }
}

