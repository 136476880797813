import { Component, OnInit } from '@angular/core';
import { ThemeService } from '../service/theme.service';
import { FilterService } from '../service/filter.service';
import { OperatorService } from '../service/operator.service';
import { ValidationService } from '../service/validation.service';
import { SystemMessageService } from '../service/system-message.service';

@Component({
  selector: 'app-operator-list',
  templateUrl: './operator-list.component.html',
  styleUrls: ['./operator-list.component.css']
})
export class OperatorListComponent implements OnInit {

  // Variables used for HTML whit ngIf for the showing or not of block of html cde
  public mainContent: string = 'operator-list';
  public fromComponent: string = '';

  // Variable used in HTMl for the active status of
  public active: number = 0;

  // Variables used for the filter service
  public userFilter: string = '';
  public filterBy: string = '';

  constructor(public theme: ThemeService,
              public filter: FilterService,
              public operator: OperatorService,
              public validationService: ValidationService,
              private systemMessageService: SystemMessageService){

  }

  ngOnInit(): void {
    // Call the function that init the list
    this.initList();

    // Function that is automatically trigger used by bin service when there is a change made from bin-create or bin-update component
    this.operator.changeMade$.subscribe(async () => {
      // Call the function that init the list
      this.initList();
      this.systemMessageService.selectRibbon('success', 'alert-success-generic-message');
    });

    this.operator.noChangeMade$.subscribe(async () => {
      this.systemMessageService.selectRibbon('danger', 'alert-danger-generic-message');
    });
  }

  // Function that will initializa the lists
  async initList(){
    // Initialize Bin array in bin.service
    await this.operator.getOperators();
   
  }

  // Takes a sequence of numbers as input and formats it as a CA phone number in the format "(XXX) XXX-XXXX".
  formatarNumero(numberSequence: string | undefined | null): string {
    // If the input is undefined or null, return an empty string (or handle the case appropriately)
    if (numberSequence === undefined || numberSequence === null) {
        return ''; // or handle the case appropriately
    }

    // If the length of the cleaned number is 10, format it as (XXX) XXX-XXXX
    const justNumbers = numberSequence.replace(/\D/g, '');

    if (justNumbers.length === 10) {
        const parte1 = justNumbers.slice(0, 3);
        const parte2 = justNumbers.slice(3, 6);
        const parte3 = justNumbers.slice(6);
        return `(${parte1}) ${parte2}-${parte3}`;
    } else {
      // If the length is not 10, return the input as is
        return numberSequence;
    }
  }

  // Function used to made a search in the list
  search(){
    // Check if there's a filterBy variable and set it to it's default value
    if(this.filterBy === ''){
      this.filterBy = 'operator_name';
    }

    this.operator.operator_array = this.filter.search(this.userFilter, this.operator.operator_array, this.operator.original_operator_array, this.filterBy);
  }
}
