

<app-system-message></app-system-message>

<!-- Container for the form with dynamic styling -->
<div [ngClass]="theme.getThemeClass()" class="container-fluido">

    <div [ngClass]="theme.getThemeClass()" class="card bg-light">
        <article class="card-body mx-auto article">
            <h4 class="card-title mt-0 text-center">{{ 'createWorkOrder' | translate }}</h4>
            <form>
                <!-- DEVICE LIST/THING NAMES SELECT INPUT -->
              <div>
                <label class="label-spacer" *ngIf="showOnlyThingName">{{ 'logsApiTableHeaderDevice' | translate }}</label>
                <label class="only-label-display" *ngIf="showOnlyThingName">{{ this.thing_name }}</label>
                <label class="label-spacer" *ngIf="!showOnlyThingName">{{ 'selectDevice' | translate }}<span class="requiredStar"> *</span></label>
                <div class="input-group mb-3" *ngIf="!showOnlyThingName">
                    <span class="input-group-text" id="basic-addon1"><i class="fas fa-angle-double-right"></i></span>
                    <ng-select
                        #thing_select
                        [(ngModel)]="this.workOrderService.workOrderData.thing_name"
                        class="form-control"
                        name="thing"
                        (change)="selectedThingChange($event)"
                        placeholder="{{ 'selectDevice' | translate }}"
                        [attr.selected]="this.workOrderService.workOrderData.thing_name"
                        [ngClass]="{'has-value': this.workOrderService.workOrderData.thing_name}"
                      >
                        <ng-container *ngFor="let thing of things">
                            <ng-option [value]="thing.thing_name" >{{ thing.thing_name }}</ng-option>
                        </ng-container>
                    </ng-select>
                </div>
              </div>


                <!-- CLIENT LIST/ID SELECT INPUT -->
                <div *ngIf="!hideClient && this.cognitoService.userType !== 'client'">
                  <label class="label-spacer" *ngIf="showOnlyClientLabel">{{ '_client' | translate }}</label>
                  <label class="only-label-display" *ngIf="showOnlyClientLabel">{{ this.clientData.legal_name }}</label>
                  <label class="label-spacer" *ngIf="!showOnlyClientLabel">{{ 'clientName' | translate }}</label>
                  <div class="input-group mb-3" *ngIf="!showOnlyClientLabel">
                      <span class="input-group-text" id="basic-addon1"><i class="fas fa-briefcase"></i></span>
                      <ng-select [(ngModel)]="this.workOrderService.workOrderData.client_id"
                          #client_select
                          class="form-control"
                          name="client"
                          (change)="selectedClientChange($event)"
                          placeholder="{{ 'clientName' | translate }}"
                          [attr.selected]="this.workOrderService.workOrderData.client_id"
                          [ngClass]="{'has-value': this.workOrderService.workOrderData.client_id}"
                        >
                          <ng-option [value]="" >{{ 'clientName' | translate }}</ng-option>
                          <ng-container *ngFor="let client of this.clientArray">
                            <ng-option [value]="client.client_id" >{{ client.client_name }}</ng-option>
                          </ng-container>
                      </ng-select>
                  </div>
                </div>

                <!-- BINS ID LIST SELECT INPUT -->
                <div *ngIf="!hideBin">
                  <label class="label-spacer" *ngIf="showOnlyBinLabel">{{ 'adminViewBinListNavBar' | translate }}</label>
                  <label class="only-label-display" *ngIf="showOnlyBinLabel">{{ this.binData.bin_name }}</label>
                  <label class="label-spacer" *ngIf="!showOnlyBinLabel">{{ 'selectBinId' | translate }}</label>
                  <div class="input-group mb-3" *ngIf="!showOnlyBinLabel">
                      <span class="input-group-text" id="basic-addon1"><i class="fab fa-artstation"></i></span>
                      <ng-select [(ngModel)]="this.workOrderService.workOrderData.bin_id"
                              #bin_select
                              class="form-control"
                              name="bin"
                              (change)="selectedBinChange($event)"
                              placeholder="{{ 'selectBinId' | translate }}"
                              [attr.selected]="this.workOrderService.workOrderData.bin_id"
                              [ngClass]="{'has-value': this.workOrderService.workOrderData.bin_id}"
                        >
                          <ng-option [value]="">{{ 'selectBinId' | translate }}</ng-option>
                          <ng-container *ngFor="let bin of binsArray">
                            <ng-option [value]="bin.bin_id">{{ bin.bin_name }}</ng-option>
                          </ng-container>
                      </ng-select>
                  </div>
                </div>


                <!-- WORK ORDER TYPE SELECT INPUT -->
                <label class="label-spacer" for="clientName">{{ 'workOrderType' | translate }} <span class="requiredStar">*</span></label>
                <div class="input-group mb-3">
                    <span class="input-group-text" id="basic-addon1"><i class="fas fa-clipboard-list"></i></span>
                    <select [(ngModel)]="this.workOrderService.workOrderData.work_order_type"
                            class="form-control"
                            name="work_order_type"
                            aria-label="Work Order Type"
                            aria-describedby="basic-addon1">
                        <option value="" disabled selected>{{ 'workOrderType' | translate }}</option>
                        <option *ngFor="let workOrderType of WorkOrderTypes" [value]="workOrderType.value">{{ getTranslatedLabel(workOrderType.label) }}</option>
                    </select>
                </div>

                <!-- WORK ORDER STATUS SELECT INPUT -->
                <label class="label-spacer" for="clientName">{{ 'workOrderStatus' | translate }} <span class="requiredStar">*</span></label>
                <div class="input-group mb-3">
                    <span class="input-group-text" id="basic-addon1"><i class="fas fa-circle-notch"></i></span>
                    <select [(ngModel)]="this.workOrderService.workOrderData.work_order_status"
                            class="form-control"
                            name="work_order_status"
                            aria-label="Work Order Status"
                            aria-describedby="basic-addon1"
                            [ngClass]="{'error-border': this.validationService.validationErrorArray[0] === 'clientInputError'}">
                        <option value="" disabled selected>{{ 'workOrderStatus' | translate }}</option>
                        <option *ngFor="let workOrderStatus of WorkOrderStatus" [value]="workOrderStatus.value">{{ getTranslatedLabel(workOrderStatus.label) }}</option>
                    </select>
                </div>

                <!-- WORK ORDER DEADLINE DATE INPUT  -->
                <label class="label-spacer" for="deadline">{{ 'deadline' | translate }} <span class="requiredStar">*</span></label>
                <div class="input-group mb-3">
                    <span class="input-group-text" id="deadline-input"><i class="far fa-clock"></i></span>
                    <input
                        type="datetime-local"
                        class="form-control"
                        aria-label="Deadline"
                        aria-describedby="deadline-addon"
                        (input)="updateDeadline($event)"
                    />
                </div>

                <!-- DESCRIPTION TEXT INPUT  -->
                <label class="label-spacer" for="deadline">{{ 'workOrderDescription' | translate }}</label>
                <div class="input-group mb-3">
                    <textarea class="descriptionStyle bg-light"
                    id="descricao" name="descricao"
                    [(ngModel)]="descriptionData.description"
                    maxlength="600"
                    (touchstart)="startScroll($event)"
                    (touchmove)="scrollTextarea($event)"
                    (touchend)="stopScroll()"></textarea>
                    <!-- REMAINING CHARACTERS COUNTER -->
                    <div>{{ 'remainingCharacters' | translate }} {{ 600 - descriptionData.description.length }}</div>
                </div>

                <!-- Submit button -->
                <div class="form-group displayFlex">
                    <button (click)="pageReload()" [ngClass]="theme.getThemeClass()" *ngIf="this.cognitoService.userType === 'muirwood'" type="button" class="btn btn-primary btn-block btnCancel fixed-size">{{ 'clientcreateFormBtnCancel' | translate }}</button>
                    <button (click)="returnToDistributorDashboard()" [ngClass]="theme.getThemeClass()" *ngIf="this.cognitoService.userType === 'distributor' && !this.comeFromIot" type="button" class="btn btn-primary btn-block btnCancel fixed-size">{{ 'clientcreateFormBtnCancel' | translate }}</button>
                    <button (click)="returnToClientDashboard()" [ngClass]="theme.getThemeClass()" *ngIf="this.cognitoService.userType === 'client'" type="button" class="btn btn-primary btn-block btnCancel fixed-size">{{ 'clientcreateFormBtnCancel' | translate }}</button>
                    <button (click)="returnToDistributorIot()" [ngClass]="theme.getThemeClass()" *ngIf="this.cognitoService.userType === 'distributor' && this.comeFromIot === true" type="button" class="btn btn-primary btn-block btnCancel fixed-size">{{ 'clientcreateFormBtnCancel' | translate }}</button>
                    <button (click)="createBtn()" [ngClass]="theme.getThemeClass()" type="button" class="btn btn-primary btn-block btnCreate fixed-size">
                      <span *ngIf="!isSaving">
                        {{ "clientcreateFormBtnCreateClient" | translate }}
                      </span>
                      <div
                        class="spinner-border spinner-position spinner-button"
                        role="status"
                        *ngIf="isSaving"
                      >
                        <span class="visually-hidden">Loading...</span>
                      </div>
                    </button>
                </div>
            </form>
        </article>
    </div>
</div>





