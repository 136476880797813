import { Injectable } from '@angular/core';
import { ClientService } from './client.service';
import { environment } from '../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LocalizationService {

  public APIKey: string = environment.google.APIKey;

  // Variables used for adress fill
  public caller: string = '';
  public referer: string = '';
  public autocomplete: any = google.maps.places;

  public autoCompletionReturnAddress: string = '';
  public autoCompletionReturnGpsLocation: string = '';
  public autoCompletionReturnPostalCode: string = '';

  constructor() { }

  // Asynchronously fetches batched locations for an array of devices
  async getBatchedLocations(devices: any[]) {
    // Array to store groups of coordinates along with corresponding devices
    const coordinateGroups: { latitude: number; longitude: number; devices: any[] }[] = [];

    // Group devices by coordinates
    devices.forEach(device => {
      const gps = device.gps;

      // Check if device has valid GPS coordinates
      if (gps && typeof gps === 'object' && gps.latitude !== undefined && gps.longitude !== undefined) {
        // Check if a group with the same coordinates already exists
        const existingGroup = coordinateGroups.find(group =>
          group.latitude === gps.latitude && group.longitude === gps.longitude
        );

        // Add device to existing group or create a new group
        if (existingGroup) {
          existingGroup.devices.push(device);
        } else {
          coordinateGroups.push({ latitude: gps.latitude, longitude: gps.longitude, devices: [device] });
        }
      }
    });

    try {
      // Make batched calls to obtain location information for each set of coordinates
      const locationsPromises = coordinateGroups.map(group =>
        this.getLocationFromGps(group.latitude, group.longitude)
      );

      // Wait for all location promises to resolve
      const locations = await Promise.all(locationsPromises);

      // Process the results; 'locations' is an array with locations corresponding to the coordinates
    } catch (error) {
      // Handle errors during the batched location fetching
      console.error('Error fetching batched locations:', error);
    }
  }

  // This fonction is a revert from gps location to get the locality and will return the closest administrative area from ths gps coords but not the adress, use getAddressFromGps for that.
  async getLocationFromGps(latitude: number, longitude: number) {
    let cityLocation: string = ''; // Variable that will get a string of "city, province, country"
    try {
      // Wait for google.map response when you give him gps coords and will put all informations then put all in data as a JSON
      const response = await fetch(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${this.APIKey}`);
      const data = await response.json();

      // Check if google.map return a response or not
      if(data.results && data.results.length > 0){
        // Loop through the data results that google.map return
        for(let i = 0; i < data.results.length; i ++){
          // This switch will check to get the closest administrative region
          switch(data.results[i].types[0]){
            case 'locality':
              cityLocation = data.results[i].formatted_address;
              break;

            case 'administrative_area_level_3':
              if(cityLocation === ''){
                cityLocation = data.results[i].formatted_address;
              }
              break;

            case 'administrative_area_level_2':
              if(cityLocation === ''){
                cityLocation = data.results[i].formatted_address;
              }
              break;

            case 'administrative_area_level_1':
              if(cityLocation === ''){
                cityLocation = data.results[i].formatted_address;
              }
              break;

            case 'country':
              if(cityLocation === ''){
                cityLocation = data.results[i].formatted_address;
              }
              break;
          }
        }
      }
    }
    catch(error) {
      console.error('Error of inverted geodecodage :', error);
      throw error;
    }
    return cityLocation; // Return the city location as a string "city, province, country"
  }

  // This fonction is using to get address when you pass a gps location
  async getAddressFromGps(latitude: number, longitude: number){
    let addressLocation: string = '';

    try {
      // Wait for google.map response when you give him gps coords and will put all informations then put all in data as a JSON
      const response = await fetch(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${this.APIKey}`);
      const data = await response.json();

      // Check if google.map return a response or not
      if(data.results && data.results.length > 0){
        // Loop through the data results that google.map return
        for(let i = 0; i < data.results.length; i ++){
          // if google.map return a value for the street address it will pass it to addressLocation
          if(data.results[i].types[0] === 'street_address'){
            addressLocation = data.results[i].formatted_address;
          }
        }
      }
    }
    catch(error) {
      console.error('Error of inverted geodecodage :', error);
      throw error;
    }
    return addressLocation; // Return street address
  }

  // Function that set a local variable autoCompoleteReturnPostalCode with the location given split as GPS locations
  async getPostalCodeFromGPS(location: string){
    const locationArray = location.split(',');
    const latitude = locationArray[0];
    const longitude = locationArray[1];

    // Wait for google.map response when you give him gps coords and will put all informations then put all in data as a JSON
    const response = await fetch(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${this.APIKey}`);
    const data = await response.json();
    const addressComponent = data.results[0].address_components;

    // Set the autoComplete variable for the postal code
    this.autoCompletionReturnPostalCode = addressComponent.find((component: any) => component.types.includes('postal_code')).long_name;
  }

  // Function to initialize the Google Maps Places Autocomplete used in address inputs
  initAutocomplete(): Promise<string | undefined> {

    return new Promise<string | undefined>((resolve) => {

      // Make sure the Google Maps API script is loaded before calling this function
      if (typeof google === 'undefined' || typeof google.maps === 'undefined') {
        throw new Error('Google Maps API is not loaded.');
      }

      // Get the input element with the ID 'autocomplete'
      const inputElement = document.getElementById('autocomplete') as HTMLInputElement;

      // Check if the input element is found
      if (!inputElement) {
        throw new Error('"autocomplete" input element not found.');
      }

      // Define the options for the Autocomplete widget
      const autocompleteOptions: google.maps.places.AutocompleteOptions = {
        // Specify the type of places to search for (geocode in this case)
        types: ['geocode'],
        // Restrict results to Canada
        componentRestrictions: { country: 'ca' },
        // Define the fields to retrieve from the place details
        fields: ['place_id', 'geometry', 'name', 'formatted_address'],
      };

      // Create a new instance of the Autocomplete widget and attach it to the input element
      this.autocomplete = new google.maps.places.Autocomplete(inputElement, autocompleteOptions);

      // Add a listener to handle when a place is selected from the Autocomplete results
      this.autocomplete.addListener('place_changed', () => {

        const selectedPlace = this.autocomplete.getPlace();

        if (selectedPlace && selectedPlace.geometry) {
          let formattedAddress = selectedPlace.formatted_address;

          // Check if the 'formatted_address' property is available
          if (!formattedAddress && selectedPlace.name) {
            formattedAddress = selectedPlace.name;
          }

          // Check if the 'name' property is available
          if (!formattedAddress && selectedPlace.address_components) {

            // Build the address from address components
            formattedAddress = selectedPlace.address_components.map((component: { long_name: any; }) => component.long_name).join(', ');
          }

          if (formattedAddress) {
            // Made differente calls depend on witch page we are
            switch(this.caller){
              case 'client-create':
                this.autoCompletionReturnAddress = formattedAddress;
                break;

              case 'bin-create':
                this.autoCompletionReturnAddress = formattedAddress;
                const gpsLocation = (selectedPlace.geometry.location.lat() + "," + selectedPlace.geometry.location.lng());
                this.autoCompletionReturnGpsLocation = gpsLocation;
                this.getPostalCodeFromGPS(gpsLocation);
                break;

              default:
                this.autoCompletionReturnAddress = formattedAddress;
                break;
            }
            resolve(this.autoCompletionReturnAddress);
          } else {
            resolve(undefined);
          }
        }
      });
    });
  }
}
