import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LegendPosition} from '@swimlane/ngx-charts';
import { ScaleType } from '@swimlane/ngx-charts';
import { firstValueFrom } from 'rxjs';
import { ReportsService } from './reports.service';

@Injectable({
  providedIn: 'root'
})
export class ChartService {

  //Pie Chart//
  singlePieChart: any[] =  [
    {
      "name": "",
      "value": 1
    },
    {
      "name": "",
      "value": 29
    },

  ];
  totalPieChart: number = 0;
  viewPieChart: [number, number] = [450, 300];

  // options
  gradientPieChart: boolean = true;
  showLegendPieChart: boolean = true;
  showLabelsPieChart: boolean = false;
  isDoughnutPieChart: boolean = false;
  legendPositionPieChart: LegendPosition = LegendPosition.Below;

  colorSchemePieChart = {
    name: "",
    selectable: true,
    group: ScaleType.Ordinal,
    domain: ['#5AA454', '#A10A28', 'lightgrey']
  };


  //Pie Chart functions
  onSelectPieChart(data:any): void {
     console.log('Item clicked', JSON.parse(JSON.stringify(data)));
  }

  onActivatePieChart(data:any): void {
     console.log('Activate', JSON.parse(JSON.stringify(data)));
  }

  onDeactivatePieChart(data:any): void {
     console.log('Deactivate', JSON.parse(JSON.stringify(data)));
  }


  // pie chart multiple slice
  singlePieChartSlice: any[] = [
    {
      "name": "",
      "value": 0
    },
    {
      "name": "",
      "value": 0
    },
  ];
  viewPieChartSlice: [number, number] = [625, 275];

  // options
  gradientPieChartSlice: boolean = true;
  showLegendPieChartSlice: boolean = true;
  showLabelsPieChartSlice: boolean = false;
  isDoughnutPieChartSlice: boolean = false;
  legendPositionPieChartSlice: LegendPosition = LegendPosition.Right;
  legendTitlePieChartSlice: string = "Legend";

  colorSchemePieChartSlice = {
    name: "",
    selectable: true,
    group: ScaleType.Ordinal,
    domain: ['#28a745', '#007bff', '#ffc107', '#dc3545', '#e83e8c', '#ff8c00', '#c8e6c9', '#17a2b8']
    // domain: ['#5AA454', '#A10A28', '#C7B42C', '#AAAAAA']
  };

  // Pie chatr slice functions
  onSelectPieChartSlice(data: any): void {
    console.log(data);
  }

  onActivatePieChartSlice(data: any): void {
    console.log(data);
  }

  onDeactivatePieChartSlice(data: any): void {
    console.log(data);
  }


  //Bar Chart//
  singleBarChart: any[]= [
    {
      "name": "",
      "value": 0
    }
  ];
  TrucksBarChart: any[]= [
    {
      "name": "",
      "value": 0
    }
  ];
  viewTrucksBarChart: [number,number] = [420, 350];

  multiBarChart: any[] = [
    {
      "name": "",
      "series": [
        {
          "name": "",
          "value": 0
        },
        {
          "name": "",
          "value": 0
        }
      ]
    }
  ];

  viewBarChart: [number,number] = [400, 250];

  // options
  showXAxisBarChart = false;
  showYAxisBarChart = true;
  gradientBarChart = false;
  showLegendBarChart = true;
  showXAxisLabelBarChart = false;
  xAxisLabelBarChart = '';
  showYAxisLabelBarChart = false;
  yAxisLabelBarChart = '';
  legendPositionBarChart: LegendPosition = LegendPosition.Below;
  legendTitleBarChart: string = "Legend";

  colorSchemeBarChart = {
    name: "",
    selectable: true,
    group: ScaleType.Ordinal,
    domain: ['#5AA454', '#6699CC', '#AAAAAA']
  };

  //Bar Chart Functions
  onSelectBarChart(event:any) {
    console.log(event);
  }

  //Bar Chart Collections//
  singleBarChartCollections: any[]= [
    {
      "name": "",
      "value": 0
    }
  ];

  multiBarChartCollections: any[] = [
    {
      "name": "",
      "series": [
        {
          "name": "",
          "value": 0
        },
        {
          "name": "",
          "value": 0
        }
      ]
    }
  ];

  viewBarChartCollections: [number,number] = [650, 450];

  // options
  showXAxisBarChartCollections = false;
  showYAxisBarChartCollections = true;
  gradientBarChartCollections = false;
  showLegendBarChartCollections = true;
  showXAxisLabelBarChartCollections = false;
  xAxisLabelBarChartCollections = '';
  showYAxisLabelBarChartCollections = false;
  yAxisLabelBarChartCollections = '';
  legendPositionBarChartCollections: LegendPosition = LegendPosition.Below;

  colorSchemeBarChartCollections = {
    name: "",
    selectable: true,
    group: ScaleType.Ordinal,
    domain: ['#5AA454', '#6699CC', '#AAAAAA']
  };

  //Bar Chart Functions
  onSelectBarChartCollections(event:any) {
    console.log(event);
  }

   //Bar Chart Admin//

   //Chart Data vertical
   singleBarChartAdmin: any[]= [
    {
      "name": "",
      "value": 0
    }
  ];

  multiBarChartAdmin: any[] = [
    {
      "name": "",
      "series": [
        {
          "name": "",
          "value": 0
        },
        {
          "name": "",
          "value": 0
        }
      ]
    }
  ];

  //View Dimensions
  viewBarChartAdmin: [number,number] = [500, 250];

  // options
  showXAxisBarChartAdmin = true;
  showYAxisBarChartAdmin = true;
  gradientBarChartAdmin = false;
  showLegendBarChartAdmin = true;
  showXAxisLabelBarChartAdmin = true;
  xAxisLabelBarChartAdmin = '';
  yAxisLabelBarChartAdmin = '';
  showYAxisLabelBarChartAdmin = false;
  legendPositionBarChartAdmin: LegendPosition = LegendPosition.Below;
  legendTitleBarChartAdmin: string = "Legend";
  noBarWhenZeroAdmin = false;

  colorSchemeBarChartAdmin = {
    name: "",
    selectable: true,
    group: ScaleType.Ordinal,
    domain: ['#5AA454', '#6699CC', '#AAAAAA', '#aa03fc']
  };

  //Bar Chart Admin functions
  onSelectBarChartAdmin(event:any) {
    console.log(event);
  }

  //Line Chart Functions
 // options
 showXAxisLineChartAdmin = false;
 showYAxisLineChartAdmin = true;
 gradientLineChartAdmin = false;
 showLegendLineChartAdmin = true;
 showXAxisLabelLineChartAdmin = false;
 xAxisLabelLineChartAdmin = '';
 xAxisLabelBatLineChartAdmin = '';
 xAxisLabelVccLineChartAdmin = '';
 showYAxisLabelLineChartAdmin = true;
 yAxisLabelLineChartAdmin = 'Distance From Device';
 yAxisLabelBatLineChartAdmin = 'Battery Rate';
 yAxisLabelVccLineChartAdmin = 'VCC Rate';
 yAxisLabelTemp = 'temperatureRate';
 yAxisLabelTech = 'technicalRates';
 legendPositionLineChartAdmin: LegendPosition = LegendPosition.Below;
 yScaleMinBat=0;
 yScaleMaxBat=0;
 yScaleMinVcc=0;
 yScaleMaxVcc=0;
 lineChartTempDate = '';
 lineChartTempValue = '';
 lineChartVb1Value = '';
 lineChartBatValue = '';
 lineChartVccValue = '';
 lineChartDateBat = '';
 lineChartDateVcc = '';
 yAxisTicks = [3,3.2,3.4,3.6,3.8,4];
 yScaleMinTemp=0;
 yScaleMaxTemp=0;
 hasVB1 = false;

   //View Dimensions
   viewLineChartAdmin: [number,number] = [1000, 450];
   viewLineChartTechnical: [number,number] = [300, 300];

   singleLineChart: any[] = [{
    "name": "",
    "series": [{
      "name": "",
      "value": ""
    }]
   }];
   singleLineChartBat: any[] = [
    {//battery rate
    "name": "",
    "series": [{
      "name": "",
      "value": ""
    }]
   },
];
   singleLineChartVcc: any[] = [
    {//battery rate
    "name": "",
    "series": [{
      "name": "",
      "value": ""
    }]
   },
];
   lineChartTechnical: any[] = [
    {//VB1 rate
    "name": "",
    "series": [{
      "name": "",
      "value": ""
    }]
   },
    {//battery rate
    "name": "",
    "series": [{
      "name": "",
      "value": ""
    }]
   },
    {//VCC rate
    "name": "",
    "series": [{
      "name": "",
      "value": ""
    }]
   },
];
singleLineChartTemp: any[] = [
  {//battery rate
  "name": "",
  "series": [{
    "name": "",
    "value": "",
    "date":""
  }]
 },
];
  // Define custom tick values for the y-axis
  customYAxisTicks: number[] = this.generateTicks(3, 4, 0.1);
  customTempYAxisTicks: number[] = [];


   /**
 * Function to generate ticks between min and max with a specified increment
 * @param min Axis minimum value.
 * @param max Axis maximal value.
 * @param increment increment value.
 * @returns an array of values for the diffent ticks of the Y axis.
 */
  generateTicks(min: number, max: number, increment: number): number[] {
    const ticks = [];
    for (let i = min; i <= max; i += increment) {
      ticks.push(i);
    }
    return ticks;
  }
  /**
 * Function that generate custom scaling for Y axis of the temperature line chart.
 * @param numberArray An array of values.
 */
  generateTempScaleMinMax(numberArray: any[]){
    //initialize variables
    let min = Math.min(...numberArray),//min
        max = Math.max(...numberArray),//max
        increment;//scaling increment
    this.yScaleMinTemp=min -1;
    this.yScaleMaxTemp=max +1;
    //calculate difference
    const difference = Math.abs(max - min)
    //dynamicly generates scaling based on difference
    switch(true){
      case difference < 10:
        increment = 1
        break
      case difference < 20:
        increment = 2
        break
      case difference > 30:
        increment = 5
        break
    }
    //geneate the ticks based on the values
    this.customTempYAxisTicks=this.generateTicks(min, max, increment!)
  }
    //format y axis unit
    formatYAxisTickBat(value: number): string {
      return `${value}V`; // Example: Appends "units" to the tick value
    }

 /**
 * Retrieves the minimum and maximum values for the y-axis scale based on battery values in the provided report array.
 *
 * @param reportArray An array containing report data.
 * @returns An array containing the minimum and maximum battery and vcc values found in the report array.
 */
 getYScaleMinMaxValue(reportArray: any[]): number[] {
  // Initialize 2 arrays to store battery and vcc values
  let batValue: number[] = [];
  let vccValue: number[] = [];

  // Iterate over each row in the report array
  for (let row of reportArray) {
      // Check if the row exists
      if (row) {
          // Convert the battery  and vcc values to a number
          let bat = Number(row.bat);
          let vcc = Number(row.vcc)

          // Add the battery  and vcc values to the array
          batValue.push(bat);
          vccValue.push(vcc)
      }
  }

  // Calculate and return the minimum and maximum battery and vcc values
  return [Math.min(...batValue), Math.max(...batValue),Math.min(...vccValue), Math.max(...vccValue)];
}

  colorSchemeLineChart = {
    name: "",
    selectable: true,
    group: ScaleType.Ordinal,
    domain: ['#5AA454', '#6699CC', '#AAAAAA', '#aa03fc']
  };
  colorSchemeLineChartTechnical = {
    name: "",
    selectable: true,
    group: ScaleType.Ordinal,
    domain: ['#5AA454', '#6699CC', '#aa03fc']
  };

  dates: any[] = [];

  public yAxisArray: any[] = []

  onSelectLineChart(data:any): void {
    console.log('Item clicked', JSON.parse(JSON.stringify(data)));
  }


  onActivateLineChart(data:any): void {
    console.log('Activate', JSON.parse(JSON.stringify(data)));
   /*  if(this.singleLineChartTemp.length >0){
      this.xAxisTemp =
    } */
  }

  onDeactivateLineChart(data:any): void {
    console.log('Deactivate', JSON.parse(JSON.stringify(data)));
  }

  // Function to format timestamps if needed
  formatTimestamp(timestamp: number): string {
    const date = new Date(timestamp);


    // Customize the formatting as needed, e.g., show only months
   // return date.toLocaleDateString('en-US', {day:'numeric', month: 'short', year: 'numeric' });
    return '';
  }

 //UI Functions t set the proper width and height for charts
 adjustView(currentScreenWidth: number, currentScreenHeight: number, tiles?:number){
  const widthPercentage = currentScreenWidth / 100;
  const heightPercentage = currentScreenHeight / 100;
  //console.log(`width: ${widthPercentage} ; height: ${heightPercentage}`)
  this.showLegendBarChart = true;
  this.showLegendBarChartAdmin = true;
  this.showXAxisBarChart = false;
  this.showLegendPieChartSlice = true;

  switch(true){
    case tiles == undefined:
      switch(true){
        case(currentScreenWidth > 1150):
          this.viewPieChart = [widthPercentage * 35, heightPercentage * 28];
          this.viewBarChart = [widthPercentage * 32, heightPercentage * 20];
          this.viewBarChartAdmin = [widthPercentage * 32, heightPercentage * 20];
          this.viewPieChartSlice = [widthPercentage * 33, heightPercentage * 28];
         // this.viewTrucksBarChart = [widthPercentage * 32, heightPercentage * 20];
          break;
        case (currentScreenWidth <= 1150) && (currentScreenWidth > 916):
          this.viewPieChart = [widthPercentage * 35, heightPercentage * 28];
          this.viewBarChart = [widthPercentage * 32, heightPercentage * 20];
          this.viewBarChartAdmin = [widthPercentage * 32, heightPercentage * 20];
          this.viewPieChartSlice = [widthPercentage * 33, heightPercentage * 28];
         // this.viewTrucksBarChart = [widthPercentage * 32, heightPercentage * 20];
          this.showLegendPieChartSlice = false;
          break;
        case (currentScreenWidth <= 915) && (currentScreenWidth > 800):
          this.viewPieChart = [widthPercentage * 60, heightPercentage * 60];
          this.viewBarChart = [widthPercentage * 60, heightPercentage * 45];
          this.viewBarChartAdmin = [widthPercentage * 60, heightPercentage * 45];
          this.viewPieChartSlice = [widthPercentage * 30, heightPercentage * 60];
          //this.viewTrucksBarChart = [widthPercentage * 60, heightPercentage * 45];
          this.showLegendPieChartSlice = false;
          break;
        case (currentScreenWidth <= 800) && (currentScreenWidth > 576):
          this.viewPieChart = [widthPercentage * 66, heightPercentage * 28];
          this.viewBarChart = [widthPercentage * 50, heightPercentage * 28];
          this.viewBarChartAdmin = [widthPercentage * 50, heightPercentage * 28];
          this.viewPieChartSlice = [widthPercentage * 30, heightPercentage * 28];
          this.showLegendBarChart = false;
          this.showLegendBarChartAdmin = false;
          this.showLegendPieChartSlice = false;
         // this.viewTrucksBarChart = [widthPercentage * 50, heightPercentage * 28];
          this.showLegendPieChart = false;
          break;
        case (currentScreenWidth <= 575) && (currentScreenWidth >= 501):
          this.viewPieChart = [widthPercentage * 60, heightPercentage * 28];
          this.viewBarChart = [widthPercentage * 60, heightPercentage * 28];
          this.viewBarChartAdmin = [widthPercentage * 60, heightPercentage * 28];
          this.viewPieChartSlice = [widthPercentage * 30, heightPercentage * 28];
          //this.viewTrucksBarChart = [widthPercentage * 60, heightPercentage * 28];
          this.showLegendBarChart = false;
          this.showLegendBarChartAdmin = false;
          this.showLegendPieChartSlice = false;
          this.showLegendPieChart = false;
          break;
        case (currentScreenWidth <= 500):
          this.viewPieChart = [widthPercentage * 60, heightPercentage * 28];
          this.viewBarChart = [widthPercentage * 60, heightPercentage * 20];
          this.viewBarChartAdmin = [widthPercentage * 60, heightPercentage * 20];
          this.viewPieChartSlice = [widthPercentage * 30, heightPercentage * 20];
          //this.viewTrucksBarChart = [widthPercentage * 60, heightPercentage * 20];
          this.showLegendBarChart = false;
          this.showLegendBarChartAdmin = false;
          this.showLegendPieChartSlice = false;
          this.showLegendPieChart = false;
          break;
        case (currentScreenWidth <= 400):
            this.viewPieChart = [widthPercentage * 60, heightPercentage * 28];
            this.viewBarChart = [widthPercentage * 60, heightPercentage * 20];
            this.viewBarChartAdmin = [widthPercentage * 50, heightPercentage * 10];
            this.viewPieChartSlice = [widthPercentage * 30, heightPercentage * 18];
            //this.viewTrucksBarChart = [widthPercentage * 60, heightPercentage * 20];
            this.showLegendBarChart = false;
            this.showLegendBarChartAdmin = false;
            this.showLegendPieChartSlice = false;
            this.showLegendPieChart = false;
            break
      }
      break
    case tiles !== undefined:
      switch(true){
        //only bar chart admin for 6 tiles have been configured for now
        case(currentScreenWidth > 1150):
          this.viewBarChartAdmin = [widthPercentage * 26, heightPercentage * 20];
          this.viewPieChartSlice = [widthPercentage * 26, heightPercentage * 20];
          break;
        case (currentScreenWidth <= 1150) && (currentScreenWidth > 916):
          this.viewBarChartAdmin = [widthPercentage * 25, heightPercentage * 20];
          this.viewPieChartSlice = [widthPercentage * 25, heightPercentage * 20];
          break;
        case (currentScreenWidth <= 915) && (currentScreenWidth > 800):
          this.viewBarChartAdmin = [widthPercentage * 25, heightPercentage * 22];
          break;
        case (currentScreenWidth <= 800) && (currentScreenWidth > 700):
          this.viewBarChartAdmin = [widthPercentage * 75, heightPercentage * 25];
          break;
        case (currentScreenWidth <= 700) && (currentScreenWidth > 630):
          this.viewBarChartAdmin = [widthPercentage * 75, heightPercentage * 40];
          break;
        case (currentScreenWidth <= 630) && (currentScreenWidth > 575):
          this.viewBarChartAdmin = [widthPercentage * 75, heightPercentage * 35];
          break;
        case (currentScreenWidth <= 575) && (currentScreenWidth >= 530):
          this.viewBarChartAdmin = [widthPercentage * 70, heightPercentage * 30];
          break;
        case (currentScreenWidth <= 530) && (currentScreenWidth >= 501):
          this.viewBarChartAdmin = [widthPercentage * 70, heightPercentage * 27];
          break;
        case (currentScreenWidth <= 500)  && (currentScreenWidth >= 450):
          this.viewBarChartAdmin = [widthPercentage * 70, heightPercentage * 15];
          break;
        case (currentScreenWidth < 450):
          this.viewBarChartAdmin = [widthPercentage * 70, heightPercentage * 25];
            this.showLegendBarChartAdmin = false;
            break
      }
      break
  }
}
adjustTruck4GraphView(width:number,height:number){
  this.viewTrucksBarChart = [width,height]
}
  // Function to format Y-axis ticks as percentages
 formatYAxisTicks(value: number): string {
    // Format the percentage with a fixed number of decimal places
    return value + '%';
 }
 /**
  * Function that return correct amount of ticks for graph Y axis (integers only)
  * @param data: graph ready array of values
  * @returns an array of ticks adjusted to the array
  */
getyAxisTicks(data:any[]):any[]{
  const values = data.map(item=>item.value);
  const maxValue = Math.max(...values);
  let ticks = []
  for(let i = 1 ; i <= maxValue ; i++){
    ticks.push(i)
  }
  return ticks
}
 // Function that return the unix language of the local storage
  getDateLanguage(){

    let dateLanguage = 'fr-CA'
    const language = localStorage.getItem('language')?.toString().replace(/"/g, "")
    switch(language){
      case 'en':
          dateLanguage = 'en-CA';
        break;
      case 'fr':
          dateLanguage = 'fr-CA'
        break;
      case 'es':
          dateLanguage = 'es-MX'
        break;
    }
    return dateLanguage;
  }

  //Returns a translated label for charts
  async getChartLabel(key: string){
    const label:string = await firstValueFrom(this.translate.get(key));
    return label;
  }

  constructor(private translate: TranslateService) {

  }

  // Function called to get the proper language for charts legend
  translateChartsLegends() {
    switch(this.getDateLanguage()){
      case "en-CA":
        this.legendTitlePieChartSlice = 'Status';
        this.legendTitleBarChart = 'Fill Level';
        this.legendTitleBarChartAdmin = 'User Types'
        break;
      case "es-MX":
        this.legendTitlePieChartSlice = 'Estado';
        this.legendTitleBarChart = 'Nivel de Llenado';
        this.legendTitleBarChartAdmin = 'Tipo de usuario'
        break;
      default:
        this.legendTitlePieChartSlice = 'Statut';
        this.legendTitleBarChart = 'Niveau de Remplissage';
        this.legendTitleBarChartAdmin = 'Type d\'utilisateur'
        break;
    }
  }
}
