import { Injectable } from '@angular/core';
import { environment } from '../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { SystemMessageService } from './system-message.service';
import { CognitoService } from './cognito.service';
import { map, catchError, Subject } from 'rxjs';

export interface RouteInterface {
  bin_usage: string;
  client_id: string;
  creation_date?: string;
  end_date?: string;
  kilometer?: string;
  nb_stops?: string;
  operator_id?: string;
  route_id: string;
  start_date?: string;
  truck_id?: string;
  legal_name: string;

}

export interface Stop{
  truck_id: string;
  route_id: string;
  route_stop_id: string;
  index: number;
  bin_id: string;
  bin_gps: string;
  bin_address: string;
  status: string;
}

@Injectable({
  providedIn: 'root'
})


export class RouteService {

  public todayRoutesArray: any;
  private tmpTransfertJSONArray: any;
  public driverRoutesArray: any;
  public driverSelectedRoute: string = '';
  public driver_id: string = '';
  public stopsArray: Stop[] = [];

  /*
  public stopsArray: Stop[] = [
    {
      truck_id: "TRd9bcbaf54ee42da606",
      route_id: "RTc33cffd6d39d0a5308",
      route_stop_id: "RSd86e26562d91566db7",
      index: 1,
      bin_id: "BN1df8df8ab91d7e46dd",
      bin_gps: "45,98415,73,84726",
      bin_address: "525 Rue Langlois, Saint-Calixte",
      status: "active"
    }
  ];
 */
  constructor(
    private http: HttpClient,
    private systemMessageService: SystemMessageService,
    private cognitoService: CognitoService,
  ) { }

  getTodayRoutes(): Promise<any[]>{

    return new Promise<any[]>((resolve) => {
      // Call the function that call the lambda function
      this.getTodayRoutesLambda().subscribe((response) => {
        // Put the array of Bins returned by the lambda function into an tmp array
        this.todayRoutesArray = response;

        // Set the bin_array and original_bin_array whit the tmp array values

        resolve(this.todayRoutesArray);
      });
    });
  }

  getTodayRoutesLambda() {
    // Initiate url whit the url of lambda function getTodayRoutes
    const url = environment.api.stage + environment.api.route.getTodayRoutes;
    // Call the lambda fonction whit the url
    return this.http.get(url).pipe(
      map((response) => {
        this.tmpTransfertJSONArray = response;
        // For each element of the array, we'r gonna transfer the JSON received in integer for some of the keys
        this.tmpTransfertJSONArray = this.tmpTransfertJSONArray.forEach((element: any) => {
          // this.switchJSONToIntForBinResponse(element);
        });
        return response;// Return response data
      }),
      catchError((error) => {
        //console.error('API Error : ' + error);
        throw error;
      })
    );
  }

  getRoutesIdByTruckId(truck_id: string): Promise<void>{
    return new Promise<void>((resolve, reject) => {
      // Call the function that call the lambda function
      this.getRoutesIdByTruckIdHTTP(truck_id).subscribe({
        next: (response) => {
          if(response){
            // Put the array of routes returned by HTTP response into the array
            this.driverRoutesArray = response;
            // Resolve the promise
            resolve();
          }else{
            // If there's no data, we throw an error
            reject('No routes found for this truck')
          }
        },
        error: error => { reject(error);}
      });
    });
  }

  getRoutesIdByTruckIdHTTP(truck_id: string){
    const url = environment.api.stage + environment.api.route.getRoutesIdByTruckId;
    const header = new HttpHeaders({'Content-Type': 'application/json'});
    return this.http.post(url, {"truck_id": truck_id}, {headers: header});
  }

  getRouteStopByRouteId(route_id: string): Promise<any>{
    return new Promise<any>((resolve, reject) => {
      // Call the function that call the lambda function
      this.getRouteStopByRouteIdHTTP(route_id).subscribe({
        next: (response) => {
          if(response){
            // Resolve the promise
            resolve(response);
          }else{
            // If there's no data, we throw an error
            reject('No stops found for this route')
          }
        },
        error: error => { reject(error);}
      });
    });
  }

  getRouteStopByRouteIdHTTP(route_id: string){
    const url = environment.api.stage + environment.api.route.getRouteStopByRouteId + '&route_id='+route_id;
    return this.http.get(url).pipe(
      map((response) => {
        return response;// Return response data
      }),
      catchError((error) => {
        //console.error('API Error : ' + error);
        throw error;
      })
    );
  }

  getStopsDetails(route_id: string): Promise<any>{
    return new Promise<any>((resolve, reject) => {
      this.getStopsDetailsHTTP(route_id).subscribe({
        next: (response) => {
          if(response as Stop[]){
            // Put the array of routes returned by HTTP response into the array
            this.stopsArray = response as Stop[];
            // Resolve the promise
            resolve(response);
          }else{
            // If there's no data, we throw an error
            reject('No stops found for this route')
          }
        },
        error: error => { reject(error); }
      });
    })
  }

  getStopsDetailsHTTP(route_id: string){
    const url = environment.api.stage + environment.api.route.getStopsDetails;
    const header = new HttpHeaders({'Content-Type': 'application/json'});
    return this.http.post(url, {"route_id": route_id}, {headers: header});
  }

  updateRouteStopHTTP(stop_id: string, status: string):Promise<boolean>{
    const url = environment.api.stage + environment.api.route.updateRouteStopStatus;
    const headers = new HttpHeaders({'Content-Type': 'application/json'});
    return new Promise((resolve)=>{
      this.http.post(url, { "stop_id": stop_id, "status": status }, { headers: headers })
        .subscribe({
          next: ()=>{
            resolve(true)
          },
          error: ()=>{
            resolve(false);
          }
        });
    })
  }

  /**
   * Call our API to log the stop, returns true on success and false on error.
   * @param route_stop_id
   * @param operator_id
   * @param status
   * @param route_stop_error
   * @param comment
   * @returns
   */
  logRouteStop(route_stop_id:string, operator_id:string, status:string, route_stop_error:string = '', comment:string = ''){
    const url = environment.api.stage + environment.api.route.logRouteStop;
    const headers = new HttpHeaders({'Content-Type': 'application/json'});
    return new Promise((resolve)=>{
      this.http.post(url, {
        "route_stop_id": route_stop_id,
        "operator_id": operator_id,
        "status": status,
        "route_stop_error": route_stop_error,
        "comment": comment
      },
      { headers: headers })
        .subscribe({
          next: ()=>{
            resolve(true);
            this.systemMessageService.selectRibbon('success', 'stopLoggedSuccess');
          },
          error: ()=>{
            resolve(false);
            this.systemMessageService.buttonBlocked = true;
            // If so, display a danger ribbon message with the corresponding key
            this.systemMessageService.selectRibbon('danger', 'stopLoggedError');
          }
        });
    })
  }

  /**
   * Set the start_date of the route in the DB
   * @param route_id
   * @returns
   */
  startRoute(route_id: string){
    const url = environment.api.stage + environment.api.route.startRoute;
    const headers = new HttpHeaders({'Content-Type': 'application/json'});
    return new Promise(()=>{
      this.http.post(url, {
        'route_id': route_id,
      }, {headers}).subscribe();
    })
  }

  /**
   * Set the end_date of the route in the DB
   * @param route_id
   * @returns
   */
  endRoute(route_id: string){
    const url = environment.api.stage + environment.api.route.endRoute;
    const headers = new HttpHeaders({'Content-Type': 'application/json'});
    return new Promise(()=>{
      this.http.post(url, {
        'route_id': route_id,
      }, {headers}).subscribe();
    })
  }
}
