import { Component, OnInit, OnDestroy } from '@angular/core';
import { ThemeService } from '../service/theme.service';
import { Subscription } from 'rxjs';
import { WebSocketService, Message } from '../service/websocket.service';
import { JocelynTestService } from '../service/jocelyn-test.service';
import { CognitoService } from '../service/cognito.service';

@Component({
  selector: 'app-jocelyn-test',
  templateUrl: './jocelyn-test.component.html',
  styleUrl: './jocelyn-test.component.css'
})
export class JocelynTestComponent {
  public message: Message = {
    action:'',
    user: '',
    body: '',
    recepient: [],
    type: '',
    title: '',
    timestamp: 0
  };

  public response: any;

  private messageSubscription: Subscription | null = null;

  constructor(private websocketService: WebSocketService,
              public theme: ThemeService,
              public jocelynTest: JocelynTestService,
              private cognitoService: CognitoService
  ) {}

  async ngOnInit(){
    // const userSub = await this.cognitoService.getCurrentUserSub();

    // this.websocketService.connectToWebsocket(userSub);
  }

  sendMessage(){
    const timestamp: number = Math.floor(Date.now() / 1000);

    let message = {
      action:'sendMessage',
      user: 'userid',
      body: 'message envoyer par client ts',
      recepient: [],
      type: 'M',
      title: 'Message',
      timestamp: timestamp
    };
    this.websocketService.send(message);
  }

  receiveMessage(){
    this.websocketService.receiveMessage();
  }

  // ngOnInit(): void {
  //   // Listen for messages from the WebSocket server
  //   this.messageSubscription = this.websocketService.listen().subscribe((data: any) => {
  //     console.log('Message received from server:', data);
  //     this.messages.push(data);
  //   });
  // }

  // ngOnDestroy(): void {
  //   // Unsubscribe from the message listener when the component is destroyed
  //   if (this.messageSubscription) {
  //     this.messageSubscription.unsubscribe();
  //   }
  // }

  // sendMessage(): void {
  //   const message = { content: 'Hello WebSockect!' };
  //   this.websocketService.send(message);
  // }

  // receiveMessage(){
  //   this.websocketService.receiveMessage();
  // }

  sendJson(){
    this.jocelynTest.sendJson();
  }
}
