import { Injectable } from "@angular/core";

Injectable({
    providedIn: 'root'
})

export const TruckStatus = [
    {'key':'0', 'value':'available'},
    {'key':'1', 'value':'maintenance'},
    {'key':'2', 'value':'out_of_order'},
    {'key':'3', 'value':'in_transit'},
    {'key':'4', 'value':'assigned'}
]
