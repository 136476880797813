import { Component } from '@angular/core';
import { ThemeService } from '../service/theme.service';


@Component({
  selector: 'app-loading-element',
  templateUrl: './loading-element.component.html',
  styleUrl: './loading-element.component.css',
  template: `
    <div class="fa-3x" [@fadeInOut]="fadeInOutClass"> <!-- Animate based on the fadeInOutClass -->
      <i class="fa-solid fa-spinner fa-spin-pulse fa-2xl" style="color: #328d1e82"></i> <!-- Font Awesome spinner icon -->
    </div>
  `
})

export class LoadingElementComponent {

  // Initial animation class
  fadeInOutClass = 'fade-in';

  // Constructor with injected ThemeService
  constructor(public theme: ThemeService,) {}
}
