import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from './../environments/environment';
import { Injectable } from '@angular/core';
import { map, catchError } from 'rxjs/operators'; // Import map from 'rxjs/operators'
import { CognitoService } from './cognito.service';

export interface Bin{
  above_ground: number
  active: number
  bin_address: string
  bin_depth: number
  bin_height: number
  bin_id: string
  bin_gps?: string
  bin_location: string
  bin_model_number: string
  bin_postal_code: string
  bin_shape: string
  bin_usage: string
  bin_width: number
  client_id: string
  distributor_id: string
  thing_name: string
  bin_volume: number
}

export interface ReportData{
  history: any[],
  collections: any[],
  counts: any[]
}

@Injectable({
  providedIn: 'root'
})

export class ReportsService {
  public historyArray: any[] = [];
  public binData: Bin[]=[];
  private array: any =[];
  public filters: any = [];
  public zonesArray: any[] = [];
  public thingsArray: any[] = [];
  public showReportModal: boolean = false;

  constructor(private http: HttpClient,
              private cognito: CognitoService) { }

  async getHistory(thingName: string){
    // Append the 'user' parameter to the URL as a query string
    const url = environment.api.stage + environment.api.route.getDeviceHistory+ "?action=history&thing_name=" + thingName;

    return this.http.get(url).pipe(
      map((response: any) => {

        return response;
      }),
      catchError((error) => {
        console.error('API Error:', error);
        throw error(error); // Re-throw the error for the calling code to handle
      }));
 }

  // Get bin by id
  async getBinByDevice(thingName: string){
    // Append thingNmae to the url
    const url = environment.api.stage + environment.api.route.getBinByDevice + "&thing_name=" + thingName;

    return this.http.get(url).pipe(
      map((response) => {
        // Process the response data here if needed
        this.array = response;
        return this.array;
      }),
      catchError((error) => {
          console.error('API Error:', error);
          throw error(error); // Re-throw the error for the calling code to handle
      }));
  }

  // Function that set style for chart-continer class html element to flex and device-statistics id element
  showGraphs(){
      // Assuming your divs have a class named 'chart-container'
      const section = document.getElementById("device-statistics");
      if(section){
        section.style.display = 'flex';
      }
      var targetDivs = document.querySelectorAll('.chart-container');

      // Check if there are elements with the specified class
      if (targetDivs.length > 0) {
        // Iterate through the NodeList and set the display property to flex for each element
        targetDivs.forEach(function (div) {

          if (div instanceof HTMLElement) {
            div.style.display = 'flex';
          }
        });
      }
  }

  // Function that received a thingArray and zonesArray, set them to local class variable then return it as an object of two arrays
  reportFilterDataClientsAndDistributors(thingsArray: any[], zonesArray: any[]) {
    for(const thing of thingsArray){
      this.thingsArray.push(thing)
    }

    for(const row of zonesArray){
      this.zonesArray.push(row)
    }

    return {things: this.thingsArray, zones: this.zonesArray}
  }

  // Function that do an API Gateway call to get a thingArray and zonesArray then return it as an object of two arrays
  async getReportFilterData(){
    const user = await this.cognito.getUser();

    // Append the 'user' parameter to the URL as a query string
    const url = environment.api.stage + environment.api.route.getReportFilterOptions+ "&user=" + user.attributes.sub;

    return this.http.get(url).pipe(
      map((response:any) => {
        const things = JSON.parse(response.things);
        const zones = JSON.parse(response.zones);
        for(const row of things){
            this.thingsArray.push(row)
        }

        for(const row of zones){
          this.zonesArray.push(row)
        }

        // return the response dat
        return {things: this.thingsArray, zones: this.zonesArray}
      }),
      catchError((error) => {
        console.error('API Error:', error);
        throw error(error); // Re-throw the error for the calling code to handle
      }));
  }

  // Function that made an API Gateway call to get all report datas
  async getReportData(things: any, start:number, end: number,histo?:boolean){
    const url = environment.api.stage + environment.api.route.getReportData;

    // Define the HTTP headers with content type
    const headers = new HttpHeaders({
      'Content-Type':  'application/json' // Adjust content type as needed
    });
    switch(histo){
      case false:
        return this.http.post(url,
          {
            "things": things,
            "start": start,
            "end": end,
            "history": history
          }, {headers: headers}
        );
      default:
        break;
    }
    return this.http.post(url,
      {
        "things": things,
        "start": start,
        "end": end
      }, {headers: headers}
    );
  }

  // Function that return current date in format yyyy-MM-dd
  getCurrentDate(): string {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0');
    const day = String(today.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  }

  // Function that return date 30 days ago from current date in format yyyy-MM-dd
  getDateThirtyDaysAgo(): string {
    const today = new Date();
    const thirtyDaysAgo = new Date(today);
    thirtyDaysAgo.setDate(thirtyDaysAgo.getDate() - 30);
    const year = thirtyDaysAgo.getFullYear();
    const month = String(thirtyDaysAgo.getMonth() + 1).padStart(2, '0');
    const day = String(thirtyDaysAgo.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  }

  // Function that return january 1st of the cuurent year in format yyyy-MM-dd
  getFirstDateOfTheCurrentYear() {
    const today = new Date();
    const year = today.getFullYear();
    // For purposed of test i put the current month, in DEV the server doesn't allow big size data report
    const month = '07'; // January is always month 01
    const day = '01'; // The first day of the year.

    return `${year}-${month}-${day}`;
  }
}
