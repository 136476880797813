import { Component, OnInit, Renderer2 } from '@angular/core';
import { ThemeService } from '../service/theme.service';
import { DistributorsService } from '../service/distributors.service';
import { TranslateService } from '@ngx-translate/core';
import { Inject, forwardRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CognitoService } from '../service/cognito.service';
import { FilterService } from '../service/filter.service';
import { BinsService } from '../service/bins.service';
import { RoleService } from '../service/role.service';

@Component({
  selector: 'app-distributor-bins-dashboard',
  templateUrl: './distributor-bins-dashboard.component.html',
  styleUrls: ['./distributor-bins-dashboard.component.css', '../distributor-bins-model-dashboard/distributor-tiles.css', '../../global-elements.css']
})
export class DistributorBinsDashboardComponent implements OnInit{
  // Gridster configuration options
  gridsterOptions = {
    // Enable dragging of grid items
    draggable: {
      enabled: false,
    },
    // Enable resizing of grid items
    resizable: {
      enabled: false,
    },
    // Minimum number of columns in the grid
    minCols: 3,
    // Maximum number of columns in the grid
    maxCols: 3,
    // Minimum number of rows in the grid
    minRows: 2,
    // Maximum number of rows in the grid
    maxRows: 2,
    // Default number of columns for each item
    defaultItemCols: 1,
    // Default number of rows for each item
    defaultItemRows: 1,
  };


  public binArray: any[] = [];
  private originalBinArray: any[] = [];
  public bin_model_id: string = '';
  public bin_id: string = '';
  public id: string = '';

  public userFilter: string = '';
  public filterBy: string = '';
  public sortBy: string = '';
  public ascDesc: number = 0;
  private lastFilterCount: number = 0;
  private filterCount: number = 0;
  private isFilter: boolean = false;
  public isSortBy: boolean =false;

  constructor(public theme: ThemeService,
              public distributors: DistributorsService,
              @Inject(forwardRef(() => TranslateService)) @Inject(forwardRef(() => TranslateService)) private translate: TranslateService,
              private route: Router,
              private cognitoService: CognitoService,
              private activatedRoute: ActivatedRoute,
              public filter: FilterService,
              public bin: BinsService,
              private renderer: Renderer2,
              public roleService: RoleService
              ){

  }

  // Function used to redirect user to bin creation page
  routeToBinCreate() {
    sessionStorage.setItem("referer", "bin-dashboard");
    this.route.navigate(['bin-create'])
  }

  async ngOnInit() {
    // Call role service to get the current user roles
    await this.roleService.getRoles();

    // Get the param that was pass from the bin-list of the bin_id selected
    this.activatedRoute.params.subscribe(params => {
      this.id = params['id']; // Set bin_model_id to the param:id receive whit url
    });

    let firstTwoCaracters = '';
    if(this.id !== undefined){
       firstTwoCaracters = this.id.substring(0, 2);
    }

    if(firstTwoCaracters === 'BM'){
      this.bin_model_id = this.id;
    }else{
      this.bin_id = this.id;
    }

    // Will get all bins and bin model form the distributor if the bin array is empty
    if(this.distributors.binArray.length === 0 || this.distributors.binArray === undefined){
      await this.distributors.getBinModelAssociationByDistributorId(this.cognitoService.distributorId).then((res) => {
        this.distributors.array = res;
        if(this.bin_model_id !== ''){
          this.distributors.binArray = this.distributors.array.filter((binModel: any) => binModel.bin_model_id === this.bin_model_id);
        }
        if(this.bin_id !== ''){
          this.distributors.binArray = this.distributors.array.filter((bin: any) => bin.bin_id === this.bin_id);
        }

        if(firstTwoCaracters == ''){
          this.distributors.binArray = this.distributors.array.filter((bin: any) => bin.bin_id !== null);
        }
      });
    }

    // Get all device related to the distributor id
    await this.distributors.getDeviceByDistributorId(this.cognitoService.distributorId).then((res) => {
      const array = res;
      array.forEach((item: any) => {
        // Find the index in the bin array of the distributor and will set the thing_name if there's a match
        const matchingIndex = this.distributors.binArray.findIndex(bin => bin.bin_id === item.bin_id);
        if(matchingIndex !== -1){
          this.distributors.binArray[matchingIndex].thing_name = item.thing_name;
        }
      });
    });

    this.originalBinArray = this.distributors.binArray;
  }

  // Function used to made a search in the list
  search(){
    // Check if there's a filterBy variable and set it to it's default value
    if(this.filterBy === ''){
      this.filterBy = 'bin_name';
    }

    this.distributors.binArray = this.filter.search(this.userFilter, this.distributors.binArray, this.originalBinArray, this.filterBy);
  }

  // Function called from switch ascendind/descending or select of sort by to sort the array of bin models
  onSortChange(caller: string, event: any){
    // Reset sortBy variable to hide the toggle switch when user press the X in the select
    if(event === undefined){
      this.isSortBy = false;
    }else{
      this.isSortBy = true;
    }

    // Switch the value of ascending or descending
    if(caller === 'switch'){
      if(this.ascDesc === 0){
        this.ascDesc = 1;
      }else{
        this.ascDesc = 0;
      }
    }

    // Avoid sortBy to have no value
    if(this.sortBy === ''){
      this.sortBy = 'bin_address';
    }

    // Function that sort the list
    this.distributors.binArray.sort((a, b) => {
      switch(this.sortBy){
        case 'bin_address':
          return this.ascDesc === 1 ? b.bin_address.localeCompare(a.bin_address) : a.bin_address.localeCompare(b.bin_address);

        case 'thing_name':
          if (a.thing_name && b.thing_name) {
            return this.ascDesc === 1 ? b.thing_name.localeCompare(a.thing_name) : a.thing_name.localeCompare(b.thing_name);
          } else {
            // If one of the instance in the array don't have thing_name it will put it at the end of the array
            if (a.thing_name) {
              return this.ascDesc === 0 ? -1 : 1; // Put a before if he have thing_name
            } else if (b.thing_name) {
              return this.ascDesc === 0 ? 1 : -1; // Put b before if he have thing_name
            } else {
              return 0;
            }
          }

        case 'legal_name':
            // Check if a.legal_name and b.legal_name are not null or undefined
            if (a.legal_name != null && b.legal_name != null) {
              // Comparison of legal_name values
              return this.ascDesc === 1 ? b.legal_name.localeCompare(a.legal_name) : a.legal_name.localeCompare(b.legal_name);
            } else {
                // If one of the values is null or undefined, return 0 to maintain the current order
                return 0;
            }
      }
    });
  }

  // Function called to get the column style of the grid list
  getColumnStyle(): any {
    const numberOfColumn = this.ajustNumberOfColumns(this.getScreenWidth());
    if(numberOfColumn){
      if(this.distributors.binArray.length >= numberOfColumn){
        const gridColumns = `repeat(${numberOfColumn}, minmax(0, 350px))`;
        return {
          'grid-template-columns': gridColumns
        };
      }else{
        return {
          'display': `flex`,
          'justify-content': `center`,
          'margin': '0 -10px'
        };
      }
    }
  }

  // function called to adjust the number of column of the grid depbending of inner window width
  ajustNumberOfColumns(width: number): number | undefined{
    // Return the floor of the inner page width divided by 450 px as the width of the tile size
    return Math.floor((width / 350) * (90 / 100));
  }

  // Function to get the current screen width
  getScreenWidth(): number {
    // Return the inner width of the window as the screen width
    return window.innerWidth;
  }

  // Function called to go back to bin model list
  backToBinModelList(){
    this.route.navigate(['/distributor-bins-model-dashboard']);
  }
     /**
 * Function that toggles the display of the complete address of the user .
 * @param i the id of the address container
 */
     displayAddress(i:number|string){

      //get the address container
      let element = document.getElementById(i.toString());
      //logic to display the address
      if(element && element.classList.contains('address')){
        //to show complete address
        this.renderer.removeClass(element,'address')
        this.renderer.addClass(element,'addressVisible')
      } else{
        //to show address with ellipsis
        this.renderer.addClass(element,'address')
        this.renderer.removeClass(element,'addressVisible')
      }
    }
}
