<app-system-message></app-system-message>
<!-- Search input component, shown when mainContent is 'clients' -->
<div [ngClass]="theme.getThemeClass()" class="min-width-div container-fluid">
    <nav id="search-component" class="filter-group" *ngIf="mainContent === 'taxe-type-list'">
        <input #searchBox type="search"  id="search-box" [(ngModel)]="userFilter" (input)="this.search()"
            class="form-control rounded searchInput"
            placeholder="{{ 'userlistSearchInputPlaceHolder' | translate }}" aria-label="Search" aria-describedby="search-addon" />
        <ng-select class="form-control ng-select select-filter-by" [(ngModel)]="this.filterBy"
          placeholder="{{ 'filterBy' | translate }}"  [ngClass]="{'has-value': this.filterBy }">
          <ng-option value="" disabled selected>{{ "filterBy" | translate }}</ng-option>
              <ng-option value="ISO_code">{{ "ISOCode" | translate }}</ng-option>
              <ng-option value="taxe_type">{{ "taxeTypeViewTaxeType" | translate }}</ng-option>
        </ng-select>
    </nav>

    <!-- Table header for the bin-list, shown when mainContent is 'bin-list' -->
    <header [ngClass]="theme.getThemeClass()" class="table-header" *ngIf="mainContent === 'taxe-type-list'">
        <span class="left">{{ "taxeTypeViewTaxeTypeList" | translate }}</span>
        <span class="right">
        <button [ngClass]="theme.getThemeClass()" class="btn btn-dropdown" id="buttonDropdown" routerLink="/taxe-type-create">
            {{ "taxeTypeViewTaxeTypeAdd" | translate }}
        </button>
        </span>
    </header>

    <!-- Table container for the taxe type list, shown when mainContent is 'taxe-type' -->
    <section [ngClass]="theme.getThemeClass()" class="table-container" *ngIf="mainContent === 'taxe-type-list'" style="width: 100%;">
        <article class="table-responsive ">
            <table class="table table-hover">
                <thead >
                    <tr>
                        <th [ngClass]="theme.getThemeClass()" scope="col">
                            {{ 'taxeTypeViewRegion' | translate }}
                        </th>
                        <th [ngClass]="theme.getThemeClass()"scope="col">
                            {{ 'taxeTypeViewType' | translate }}
                        </th>
                        <th [ngClass]="theme.getThemeClass()" class="hide-small-screen" scope="col">
                            {{ 'taxeTypeViewTaxeName' | translate }}
                        </th>
                        <th [ngClass]="theme.getThemeClass()" scope="col">
                            {{ 'taxeTypeViewRate' | translate }}
                        </th>
                        <th [ngClass]="theme.getThemeClass()" class="hide-small-screen" scope="col">
                            {{ 'taxeTypeViewTaxeFor' | translate }}
                        </th>
                        <th [ngClass]="theme.getThemeClass()" scope="col">
                            {{ 'iotTabHeaderResources' | translate }}
                        </th>
                    </tr>
                </thead>
                <!-- Table list container -->
                <ng-container  *ngFor="let taxe of this.taxeTypeService.taxe_type_array">
                    <ng-container *ngFor="let name of this.regionArray">
                        <tbody>
                            <tr style="vertical-align: middle;" *ngIf="taxe.ISO_code === name.value">
                                <td [ngClass]="theme.getThemeClass()">{{ taxe.ISO_code }}</td>
                                <td [ngClass]="theme.getThemeClass()">{{ taxe.taxe_type | translate }}</td>
                                <td [ngClass]="theme.getThemeClass()" class="hide-small-screen" [style.color]="(taxe.active) ? 'green!important' : 'red!important'">{{ taxe.taxe_name | translate }} {{ name.label }}</td>
                                <td [ngClass]="theme.getThemeClass()">{{ taxe.rate }}</td>
                                <td [ngClass]="theme.getThemeClass()" class="hide-small-screen">{{ taxe.is_federal === 1  ?  ("taxeTypeViewFederal" | translate)  :  ("taxeTypeViewProvincial" | translate) }} </td>
                                <td [ngClass]="theme.getThemeClass()">
                                    <a [routerLink]="['/taxe-type-update', taxe.taxe_type_id]"><img class="iconUpdate" src="./assets/update-pencil.png" alt="icon of update" height="30" width="30"></a>
                                </td>
                            </tr>
                        </tbody>
                    </ng-container>
                </ng-container>
            </table>
        </article>
      </section>
    </div>

