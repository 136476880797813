import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NavigationService } from './navigation.service';
import { LocalStorageService } from '../local-storage.service';
import { CognitoService } from './cognito.service';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { environment } from '../environments/environment';
import { map, catchError } from 'rxjs';
import { bool } from 'aws-sdk/clients/signer';

@Injectable({
  providedIn: 'root',
})
export class ValidationService {
  // Public variables to track the validation status for client creation
  public notUniqueUsername: boolean = false;
  public clientInputValid: boolean = false;
  public usernameValid: any;
  public givenNameValid: any;
  public middleNameValid: any;
  public familyNameValid: any;
  public clientNameValid: any; // Indicates if the client name is valid
  public clientLegalNameValid: any; // Indicates if the client's legal name is valid
  public clientEmailValid: any; // Indicates if the client's email is valid
  public clientPhoneNumberValid: any; // Indicates if the client's phone number is valid
  public clientAddressValid: any; // Indicates if the client's address is valid
  public userRolesValid: boolean = false;
  public nameValid: boolean = false;
  private isUnique: boolean = false;
  public pickupErrorSelectValid: boolean = false;

  // Public variable to track the validation status for bin creation
  public binModelNumberValid: any;
  public binUsageValid: any;
  public binWHDValid: any;

  // Public variable to track the validation status of taxe type creation
  public taxeTypeValid: any;
  public taxeRateValid: any;

  // Public variables for storing formatted data
  public phoneNumberFixed: string = '';
  public dateFixed: any;

  public clientCreateSuccessMessage: boolean = false;
  public clientUpdateSuccessMessage: boolean = false;
  public distributorCreateSuccessMessage: boolean = false;
  public createSuccessMessage: boolean = false;
  public updateSuccessMessage: boolean = false;
  public deleteSuccessMessage: boolean = false;

  // Public array to store validation error types
  public validationErrorArray: any[] = [];

  // Public variable to track the current error type being processed
  public errorType: string = '';
  // Regular expressions for strong and medium password criteria
  strongPassword = new RegExp(
    '(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{8,})'
  );
  mediumPassword = new RegExp(
    '((?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{6,}))|((?=.*[a-z])(?=.*[A-Z])(?=.*[^A-Za-z0-9])(?=.{8,}))'
  );

  // Public variable to track the validation status of truck creation
  public truckNivValid: any;
  public truckNicknameValid: any;
  public truckBrandValid: any;
  public truckTypeValid: any;
  public truckSizeValid: any;
  public truckCapacityValid: any;
  public truckYearValid: any;
  public truckStatusValid: any;

  public zoneNameValid: boolean = false;
  public wasteTypeValid: boolean = false;
  public entitySelectValid: boolean = false;

  constructor(
    private router: Router,
    public navigationService: NavigationService,
    public localStorageService: LocalStorageService,
    public cognitoService: CognitoService,
    private http: HttpClient
  ) {}

  // Function to verify and manage validation error types in an array
  verifyArray(errorType: string) {
    // Check if the errorType is not already in the validationErrorArray
    if (!this.validationErrorArray.includes(errorType)) {
      // If not, add it to the validationErrorArray
      this.validationErrorArray.push(errorType);
    }
  }

  // Function used to check if value is not undefined, empty or a empty string
  isNotEmpty(value: any) {
    return value !== null && value !== undefined && value !== '';
  }

  // Function to remove a specific validation error from the validationErrorArray
  removeValidationError(validationError: string) {
    // Loop through the validationErrorArray
    for (let i = 0; i < this.validationErrorArray.length; i++) {
      // Check if the current element in the array matches the specified validationError
      if (this.validationErrorArray[i] === validationError) {
        // If a match is found, remove the element from the validationErrorArray
        this.validationErrorArray.splice(i, 1);

        // Exit the loop as soon as the first matching element is removed
        break;
      }
    }
  }

  // Function to validate the username
  validateFamilyName(familyName: string) {
    // Set the error type to "clientNameInvalid"
    this.errorType = 'familyNameInvalid';

    // Regular expression to match alphanumeric characters and spaces
    const nameRegex = /^[a-zA-Z \-']+$/;

    // Test if the clientName matches the alphanumeric regex
    let testAlphaNumeric = nameRegex.test(familyName);

    // Check if clientName is not empty, its length is greater than 40,
    // or it doesn't match the alphanumeric pattern
    if ((familyName && familyName.length > 40) || !testAlphaNumeric) {
      // Set clientNameValid to false, indicating validation failure
      this.familyNameValid = false;

      // Call the verifyArray function with the current error type
      this.verifyArray(this.errorType);
    } else {
      // Remove the validation error for the current error type
      this.removeValidationError(this.errorType);

      // Set clientNameValid to true, indicating validation success
      this.familyNameValid = true;
    }
  }

  // Function to validate the username
  validateGivenName(givenName: string) {
    // Set the error type to "clientNameInvalid"
    this.errorType = 'givenNameInvalid';

    // Regular expression to match alphanumeric characters and spaces
    const nameRegex = /^[a-zA-Z \-']+$/;

    // Test if the clientName matches the alphanumeric regex
    let testAlphaNumeric = nameRegex.test(givenName);

    // Check if clientName is not empty, its length is greater than 25,
    // or it doesn't match the alphanumeric pattern
    if ((givenName && givenName.length > 25) || !testAlphaNumeric) {
      // Set clientNameValid to false, indicating validation failure
      this.givenNameValid = false;

      // Call the verifyArray function with the current error type
      this.verifyArray(this.errorType);
    } else {
      // Remove the validation error for the current error type
      this.removeValidationError(this.errorType);

      // Set clientNameValid to true, indicating validation success
      this.givenNameValid = true;
    }
  }

  // Function to validate the username
  validateMiddleName(givenName: string) {
    // Set the error type to "clientNameInvalid"
    this.errorType = 'middleNameInvalid';

    // Regular expression to match alphanumeric characters and spaces
    const nameRegex = /^[a-zA-Z \-']+$/;

    // Test if the clientName matches the alphanumeric regex
    let testAlphaNumeric = nameRegex.test(givenName);

    // Check if clientName is not empty, its length is greater than 25,
    // or it doesn't match the alphanumeric pattern
    if ((givenName && givenName.length > 25) || !testAlphaNumeric) {
      // Set clientNameValid to false, indicating validation failure
      this.middleNameValid = false;

      // Call the verifyArray function with the current error type
      this.verifyArray(this.errorType);
    } else {
      // Remove the validation error for the current error type
      this.removeValidationError(this.errorType);

      // Set clientNameValid to true, indicating validation success
      this.middleNameValid = true;
    }
  }

  // Function to validate the username
  validateUsername(username: string) {
    // Set the error type to "clientNameInvalid"
    this.errorType = 'usernameInvalid';

    // Regular expression to match alphanumeric characters and spaces
    const usernameRegex = /^[a-zA-Z0-9 ]+$/;

    // Test if the clientName matches the alphanumeric regex
    let testUsername = usernameRegex.test(username);

    // Check if clientName is not empty, its length is greater than 25,
    // or it doesn't match the alphanumeric pattern
    if ((username && username.length > 25) || !testUsername) {
      // Set clientNameValid to false, indicating validation failure
      this.clientNameValid = false;

      // Call the verifyArray function with the current error type
      this.verifyArray(this.errorType);
    } else {
      // Remove the validation error for the current error type
      this.removeValidationError(this.errorType);

      // Set clientNameValid to true, indicating validation success
      this.clientNameValid = true;
      this.usernameValid = true;
    }
  }

  // Function to validate the client name
  validateClientName(clientName: string) {
    // Set the error type to "clientNameInvalid"
    this.errorType = 'usernameInvalid';

    // Regular expression to match alphanumeric characters and spaces
    const nameRegex = /^[a-zA-Z \-']+$/;

    // Test if the clientName matches the alphanumeric regex
    let testAlphaNumeric = nameRegex.test(clientName);

    // Check if clientName is not empty, its length is greater than 40,
    // or it doesn't match the alphanumeric pattern
    if ((clientName && clientName.length > 40) || !testAlphaNumeric) {
      // Set clientNameValid to false, indicating validation failure
      this.usernameValid = false;
      this.clientNameValid = false;

      // Call the verifyArray function with the current error type
      this.verifyArray(this.errorType);
    } else {
      // Remove the validation error for the current error type
      this.removeValidationError(this.errorType);

      // Set clientNameValid to true, indicating validation success
      this.usernameValid = true;
      this.clientNameValid = true;
    }
  }

  // Function to validate the client's legal name
  validateClientLegalName(clientLegalName: string) {
    // Set the error type to "clientLegalNameInvalid"
    this.errorType = 'clientLegalNameInvalid';
    // Regular expression to match alphanumeric characters and spaces
    const nameRegex = /^[a-zA-Z \-']+$/;

    // Test if the clientLegalName matches the alphanumeric regex
    let testAlphaNumeric = nameRegex.test(clientLegalName);

    // Check if clientLegalName is not empty, its length is greater than 40,
    // or it doesn't match the alphanumeric pattern
    if ((clientLegalName && clientLegalName.length > 40) || !testAlphaNumeric) {
      // Set clientLegalNameValid to false, indicating validation failure
      this.clientLegalNameValid = false;

      // Call the verifyArray function with the current error type
      this.verifyArray(this.errorType);
    } else {
      // Remove the validation error for the current error type
      this.removeValidationError(this.errorType);

      // Set clientLegalNameValid to true, indicating validation success
      this.clientLegalNameValid = true;
    }
  }

  // Function to validate the client's email address
  validateClientEmail(clientEmail: string) {
    // Set the error type to "clientEmailInvalid"
    this.errorType = 'clientEmailInvalid';

    // Regular expression to match a valid email format
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    // Test if the clientEmail matches the email regex
    let testEmail = emailRegex.test(clientEmail);

    // Check if the clientEmail is not a valid email or is empty
    if (!testEmail || clientEmail.length == 0) {
      // Set clientEmailValid to false, indicating validation failure
      this.clientEmailValid = false;

      // Call the verifyArray function with the current error type
      this.verifyArray(this.errorType);
    } else {
      // Remove the validation error for the current error type
      this.removeValidationError(this.errorType);
      // Set clientEmailValid to true, indicating validation success
      this.clientEmailValid = true;
    }
  }

  // Function to remove non-numeric characters from a phone number
  keepOnlyNumbers(phoneNumber: string) {
    // Use a regular expression (/\D/g) to match all non-numeric characters
    // and replace them with an empty string. This effectively keeps only
    //  the numeric characters in the phoneNumber
    this.phoneNumberFixed = phoneNumber.replace(/\D/g, '');
  }

  // Function to validate a client's phone number
  validatePhoneNumber(phoneNumber: string) {
    // Clean phone number of all non-digit characters except for the +
    const cleanPhoneNumber = phoneNumber.replace(/(?!^\+)[^\d]/g, '');
    // Set the error type to "clientPhoneInvalid"
    this.errorType = 'clientPhoneInvalid';
    // Phone number can have an optional +1 at the beginning and must have 10 digits with or without the +1
    const phoneRegex = /^(\+1)?\d{10}$/;

    let testPhone = phoneRegex.test(cleanPhoneNumber);
    // Check if phoneNumber is not empty and its length is less than 10 or if it's empty
    if (testPhone == false || phoneNumber.length == 0) {
      // Set clientPhoneNumberValid to false, indicating validation failure
      this.clientPhoneNumberValid = false;

      // Call the verifyArray function with the current error type
      this.verifyArray(this.errorType);
    } else {
      // Remove the validation error for the current error type
      this.removeValidationError(this.errorType);

      // Set clientPhoneNumberValid to true, indicating validation success
      this.clientPhoneNumberValid = true;
    }
  }

  // Function to validate a client's address
  validateAddress(address: string) {
    // Set the error type to "clientAddressInvalid"
    this.errorType = 'clientAddressInvalid';

    // Check if the address is empty
    if (address == undefined || address.length == 0 || address == '') {
      // Set clientAddressValid to false, indicating validation failure
      this.clientAddressValid = false;

      // Call the verifyArray function with the current error type
      this.verifyArray(this.errorType);
    } else {
      // Remove the validation error for the current error type
      this.removeValidationError(this.errorType);

      // Set clientAddressValid to true, indicating validation success
      this.clientAddressValid = true;
    }
  }

  // Function to format a Date object as a string in YYYYMMDD format
  formatDate(date: Date) {
    // Get the year, month, and day from the Date object
    const year = date.getFullYear();
    const month = ('0' + (date.getMonth() + 1)).slice(-2); // Add leading zero if necessary
    const day = ('0' + date.getDate()).slice(-2); // Add leading zero if necessary

    // Create a formatted date string in the format YYYYMMDD
    this.dateFixed = `${year}${month}${day}`;
  }

  manageSuccessMessageDistributor(authorization: boolean) {
    this.localStorageService.addItem('entitiesContent', 'distributors');

    this.router.navigate(['/entities']); // Navigate to the client list page

    if (authorization) {
      // Add a 1-second delay before calling active3SecondTrigger
      setTimeout(() => {
        this.active3SecondTrigger((newValue) => {
          this.createSuccessMessage = newValue; // Set the success message
        });
      }, 1000); // 1-second delay (1000 milliseconds)
    } else {
      // Call the function to show and hide the <div> for 5 seconds
      this.active3SecondTrigger((newValue) => {
        // this.successMessage = newValue; // The variable will be set by the function
      });
    }
  }

  manageSuccessMessageClient(authorization: boolean) {
    this.localStorageService.addItem('entitiesContent', 'clients');
    // this.navigationService.entitiesContent = "clients";
    this.router.navigate(['/entities']); // Navigate to the client list page

    if (authorization) {
      // Add a 1-second delay before calling active3SecondTrigger
      setTimeout(() => {
        this.active3SecondTrigger((newValue) => {
          this.createSuccessMessage = newValue; // Set the success message
        });
      }, 1000); // 1-second delay (1000 milliseconds)
    } else {
      // Call the function to show and hide the <div> for 5 seconds
      this.active3SecondTrigger((newValue) => {
        // this.successMessage = newValue; // The variable will be set by the function
      });
    }
  }

  active3SecondTrigger(callback: (prop: boolean) => void) {
    // Set the variable to true to show the <div>
    callback(true);

    // Set a 5-second timeout to then set the variable to false and hide the <div>
    setTimeout(() => {
      callback(false);
    }, 5000);
  }

  intToDate(date: number) {
    const dateStr = date.toString();
    const year = dateStr.substring(0, 4);
    const month = dateStr.substring(4, 6);
    const day = dateStr.substring(6, 8);

    return new Date(parseInt(year), parseInt(month) - 1, parseInt(day));
  }

  varcharToDate(date: string) {
    const year = date.substring(0, 4);
    const month = date.substring(4, 6);
    const day = date.substring(6, 8);

    return new Date(parseInt(year), parseInt(month) - 1, parseInt(day));
  }

  // Funciton that validate that a bin number have been selected
  validateBinModelNumber(binModelNumber: string) {
    this.errorType = 'binModelError';

    if (!binModelNumber) {
      // Set clientAddressValid to false, indicating validation failure
      this.binModelNumberValid = false;

      // Call the verifyArray function with the current error type
      this.verifyArray(this.errorType);
    } else {
      // Remove the validation error for the current error type
      this.removeValidationError(this.errorType);

      // Set clientAddressValid to true, indicating validation success
      this.binModelNumberValid = true;
    }
  }

  validateBinUsage(binUsage: string) {
    this.errorType = 'binUsageError';

    if (!binUsage) {
      // Set clientAddressValid to false, indicating validation failure
      this.binUsageValid = false;

      // Call the verifyArray function with the current error type
      this.verifyArray(this.errorType);
    } else {
      // Remove the validation error for the current error type
      this.removeValidationError(this.errorType);

      // Set clientAddressValid to true, indicating validation success
      this.binUsageValid = true;
    }
  }

  validateBinWidthHeightDepth(value: string, caller: string) {
    const numericValue = parseInt(value);
    // Put the error at the proper type depend on witch one calling it
    switch (caller) {
      case 'width':
        this.errorType = 'binWidthError';
        break;
      case 'height':
        this.errorType = 'binHeightError';
        break;
      case 'depth':
        this.errorType = 'binDepthError';
        break;
    }

    if (!value || isNaN(numericValue)) {
      // Set clientAddressValid to false, indicating validation failure
      this.binWHDValid = false;

      // Call the verifyArray function with the current error type
      this.verifyArray(this.errorType);
    } else {
      // Remove the validation error for the current error type
      this.removeValidationError(this.errorType);

      // Set clientAddressValid to true, indicating validation success
      this.binWHDValid = true;
    }
  }

  validateTaxeType(type: string) {
    this.errorType = 'taxeTypeError';
    if (!type) {
      // Set clientAddressValid to false, indicating validation failure
      this.taxeTypeValid = false;

      // Call the verifyArray function with the current error type
      this.verifyArray(this.errorType);
    } else {
      // Remove the validation error for the current error type
      this.removeValidationError(this.errorType);

      // Set clientAddressValid to true, indicating validation success
      this.taxeTypeValid = true;
    }
  }

  validateTaxeRate(rate: string) {
    this.errorType = 'taxeRateError';
    if (!rate || isNaN(parseFloat(rate))) {
      // Set clientAddressValid to false, indicating validation failure
      this.taxeRateValid = false;

      // Call the verifyArray function with the current error type
      this.verifyArray(this.errorType);
    } else {
      // Remove the validation error for the current error type
      this.removeValidationError(this.errorType);

      // Set clientAddressValid to true, indicating validation success
      this.taxeRateValid = true;
    }
  }

  validateTaxeName(name: string) {
    this.errorType = 'taxeNameError';
    if (!name) {
      // Set clientAddressValid to false, indicating validation failure
      this.taxeRateValid = false;

      // Call the verifyArray function with the current error type
      this.verifyArray(this.errorType);
    } else {
      // Remove the validation error for the current error type
      this.removeValidationError(this.errorType);

      // Set clientAddressValid to true, indicating validation success
      this.taxeRateValid = true;
    }
  }

  validateRoles() {
    this.errorType = 'roleSelectionInvalid';

    if (this.cognitoService.roleUserArray.length == 0) {
      this.verifyArray(this.errorType);
      this.userRolesValid = false;
    } else {
      this.removeValidationError(this.errorType);
      this.userRolesValid = true;
    }
  }

  validateRolesUpdate(roles: any[]) {
    this.errorType = 'roleSelectionInvalid';

    if (roles.length == 0) {
      this.verifyArray(this.errorType);
      this.userRolesValid = false;
    } else {
      this.removeValidationError(this.errorType);
      this.userRolesValid = true;
    }
  }

  // Function to validate the uniqueness of a username
  validadeUsername(status: boolean) {
    // Set the default error type to "usernameNotUnique"
    this.errorType = 'usernameNotUnique';
    // Check if the status is true (indicating a unique username)
    if (status == true) {
      // If the username is unique, set notUniqueUsername to false
      this.notUniqueUsername = false;
      // Remove the validation error for the current error type
      this.removeValidationError(this.errorType);
    } else {
      // If the username is not unique, set notUniqueUsername to true
      this.notUniqueUsername = true;
      // Verify the existence of the error type in the error array
      this.verifyArray(this.errorType);
    }
  }

  validateClientInput(client_id: string) {
    this.errorType = 'clientInputError';
    // check if client_id is empty
    if (client_id == null || client_id == '') {
      this.clientInputValid = false;
      // Call the verifyArray function with the current error type
      this.verifyArray(this.errorType);
    } else {
      // Remove the validation error for the current error type
      this.removeValidationError(this.errorType);
      this.clientInputValid = true;
    }
  }

  formatPhoneNumber(numberSequence: string | undefined | null): string {
    if (numberSequence === undefined || numberSequence === null) {
      return ''; // or handle the case appropriately
    }

    // Remove non-numeric characters
    const justNumbers = numberSequence.replace(/\D/g, '');

    // Check if the input is empty after removing non-numeric characters
    if (justNumbers.length === 0) {
      return '';
    }

    // Extract the last 10 digits (ignoring the country code, if present)
    const last10Digits = justNumbers.slice(-10);

    // Format the last 10 digits
    const parte1 = last10Digits.slice(0, 3);
    const parte2 = last10Digits.slice(3, 6);
    const parte3 = last10Digits.slice(6);
    const numeroFormatado = `(${parte1}) ${parte2}-${parte3}`;

    // If the country code was present, add it back
    const codigoPais = justNumbers.slice(0, -10);
    return codigoPais.length > 0
      ? `+${codigoPais} ${numeroFormatado}`
      : numeroFormatado;
  }

  ////////////////// Operator Create Truck Validation ////////////////////////////////////

  /**
   * Function that validate and check uniqueness of VIN input of operator-create-truck component
   * @param NIV the VIN of the truck
   */
  validateTruckNIV(NIV: string, truck_id: string): Promise<void> {
    this.errorType = 'createTruckVinInputError';
    return new Promise<void>((resolve, reject) => {
      this.isTruckVINUnique(NIV, truck_id)
        .then((result) => {
          if (NIV == null || NIV == '' || result === false) {
            this.truckNivValid = false;
            this.verifyArray(this.errorType);
          } else {
            this.removeValidationError(this.errorType);
            this.truckNivValid = true;
          }
          resolve(); // Resolve the promise after validation
        })
        .catch((error) => {
          console.error('Error during VIN check:', error);
          reject(error); // Reject the promise in case of an error
        });
    });
  }

  /**
   * Function that validate brand input of operator-create-truck component
   * @param brand the brand of the truck
   */
  validateTruckBrand(brand: string) {
    this.errorType = 'createTruckBrandInputError';
    //check if input is empty
    if (brand == null || brand == '') {
      this.truckBrandValid = false;
      // Call the verifyArray function with the current error type
      this.verifyArray(this.errorType);
    } else {
      // Remove the validation error for the current error type
      this.removeValidationError(this.errorType);
      this.truckBrandValid = true;
    }
  }
  /**
   * Function that validate type input of operator-create-truck component
   * @param type the type of the truck
   */
  validateTruckType(type: string) {
    this.errorType = 'createTruckTypeInputError';
    //check if input is empty
    if (type == null || type == '') {
      this.truckTypeValid = false;
      // Call the verifyArray function with the current error type
      this.verifyArray(this.errorType);
    } else {
      // Remove the validation error for the current error type
      this.removeValidationError(this.errorType);
      this.truckTypeValid = true;
    }
  }
  /**
   * Function that validate size input of operator-create-truck component
   * @param size the size of the truck
   */
  validateTruckSize(size: number) {
    this.errorType = 'createTruckSizeInputError';
    //check if input is empty
    if (size == null || size <= 0) {
      this.truckSizeValid = false;
      // Call the verifyArray function with the current error type
      this.verifyArray(this.errorType);
    } else {
      // Remove the validation error for the current error type
      this.removeValidationError(this.errorType);
      this.truckSizeValid = true;
    }
  }
  /**
   * Function that validate capacity input of operator-create-truck component
   * @param capacity the capacity of the truck
   */
  validateTruckCapacity(capacity: number) {
    this.errorType = 'createTruckCapacityInputError';
    //check if input is empty
    if (capacity == null || capacity <= 0) {
      this.truckCapacityValid = false;
      // Call the verifyArray function with the current error type
      this.verifyArray(this.errorType);
    } else {
      // Remove the validation error for the current error type
      this.removeValidationError(this.errorType);
      this.truckCapacityValid = true;
    }
  }
  /**
   * Function that validate year input of operator-create-truck component
   * @param year the year of the truck
   */
  validateTruckYear(year: number) {
    this.errorType = 'createTruckYearInputError';
    const yearRegex = /^[0-9]+(?:[.,][0-9]+)?$/;
    if (
      year == null ||
      !yearRegex.test(year.toString()) ||
      typeof year !== 'number'
    ) {
      this.truckYearValid = false;
      // Call the verifyArray function with the current error type
      this.verifyArray(this.errorType);
    } else {
      // Remove the validation error for the current error type
      this.removeValidationError(this.errorType);
      this.truckYearValid = true;
    }
  }
  /**
   * Function that validate satus input of operator-create-truck component
   * @param satus the satus of the truck
   */
  validateTruckStatus(status: string) {
    this.errorType = 'createTruckStatusInputError';
    if (status == null || status == '') {
      this.truckStatusValid = false;
      // Call the verifyArray function with the current error type
      this.verifyArray(this.errorType);
    } else {
      // Remove the validation error for the current error type
      this.removeValidationError(this.errorType);
      this.truckStatusValid = true;
    }
  }

  /**
   * Function that validate the nickname input of operator-create-truck component
   * @param nickname the nickname of the truck
   */
  async validateTruckNickname(nickname: string) {
    this.errorType = 'createTruckNicknameInputError';
    //check if input is empty
    if (nickname == null || nickname == '') {
      this.truckNicknameValid = false;
      // Call the verifyArray function with the current error type
      this.verifyArray(this.errorType);
    } else {
      // Remove the validation error for the current error type
      this.removeValidationError(this.errorType);
      this.truckNicknameValid = true;
    }
  }

  // Function to validate the zone name
  validateZoneName(zoneName: string) {
    // Set the error type to "usernameInvalid"
    this.errorType = 'emptyZoneName';

    // Regular expression to match alphanumeric characters and spaces
    const nameRegex = /^[a-zA-Z 0-9\-']+$/;

    // Test if the zoneName matches the alphanumeric regex
    let testAlphaNumeric = nameRegex.test(zoneName);
    // Check if zoneName is not empty,
    // or it doesn't match the alphanumeric pattern
    if ((zoneName.length <= 0 && zoneName.length > 40) || !testAlphaNumeric) {
      // Set zoneNameValid to false, indicating validation failure
      this.zoneNameValid = false;
      // Call the verifyArray function with the current error type
      this.verifyArray(this.errorType);
    } else {
      // Remove the validation error for the current error type
      this.removeValidationError(this.errorType);
      // Set zoneNameValid to true, indicating validation success
      this.zoneNameValid = true;
    }
  }

  validateWasteType(wasteType: string) {
    // Set the error type to "usernameInvalid"
    this.errorType = 'noWasteType';

    // Check if zoneName is not empty, its length is greater than 40,
    // or it doesn't match the alphanumeric pattern
    if (wasteType == '' || wasteType == null) {
      // Set zoneNameValid to false, indicating validation failure
      this.wasteTypeValid = false;
      // Call the verifyArray function with the current error type
      this.verifyArray(this.errorType);
    } else {
      // Remove the validation error for the current error type
      this.removeValidationError(this.errorType);
      // Set zoneNameValid to true, indicating validation success
      this.wasteTypeValid = true;
    }
  }
  isTruckVINUnique(vin: string, truck_id: string): Promise<boolean> {
    return new Promise<any>((resolve, reject) => {
      // Call the function that call the lambda function
      this.isTruckVINUniqueLambda(vin, truck_id).subscribe({
        next: (response) => {
          resolve(response.unique);
        },
        error: (error) => {
          console.error('Error:', error);
          reject(error);
        },
      });
    });
  }

  isTruckVINUniqueLambda(vin: string, truck_id: string) {
    // Define the HTTP headers with content type
    const headers = new HttpHeaders({
      'Content-Type': 'application/json', // Adjust content type as needed
    });
    // Initiate url with the url of lambda fonction
    const url = `${environment.api.stage}${
      environment.api.route.isTruckVINUnique
    }&vin=${encodeURIComponent(vin)}`;
    return this.http.post<{ unique: boolean }>(
      url,
      { vin: vin, truck_id: truck_id },
      { headers: headers }
    );
  }

  // Function to validate the Entity selection
  validateEntitySelect(entity: string) {
    // Set the error type to "entitySelectInvalid"
    this.errorType = 'entitySelectInvalid';

    // check if entity is not empty or null
    if (entity == '' || entity == null) {
      // Set clientNameValid to false, indicating validation failure
      this.entitySelectValid = false;

      // Call the verifyArray function with the current error type
      this.verifyArray(this.errorType);
    } else {
      // Remove the validation error for the current error type
      this.removeValidationError(this.errorType);

      // Set clientNameValid to true, indicating validation success
      this.entitySelectValid = true;
    }
  }

  validatePickupErrorSelect(option: string) {
    this.errorType = 'PickupErrorSelectInvalid';

    if (option == '' || option == null) {
      this.pickupErrorSelectValid = false;
      this.verifyArray(this.errorType);
    } else {
      this.pickupErrorSelectValid = true;
      this.removeValidationError(this.errorType);
    }
  }
}
