<app-system-message></app-system-message>

<div [ngClass]="theme.getThemeClass()" class="container-fluid entitiesContainer"> <!-- (window:scroll)="onScroll()"-->

    <!-- Header section with navigation menu -->
    <header class="entitiesHeader">
        <nav [ngClass]="theme.getThemeClass()" class="navbar navbar-expand-lg navbar-light bg-light">
          <div [ngClass]="theme.getThemeClass()" class="navbar-nav flex-nowrap" id="navbarNavAltMarkup">
            <a class="nav-item nav-link" href="javascript:void(0)"
            (click)="clientsClicked()" [ngClass]="{'active' : isClientsActive}">
            {{ 'userlistNavMenuClients' | translate }}
            </a>
            <a class="nav-item nav-link" href="javascript:void(0)"
            (click)="distributorsClicked()" [ngClass]="{'active' : isDistributorsActive}">
            {{ 'userlistNavMenuDistributors' | translate }}
            </a>
            <a class="nav-item nav-link" href="javascript:void(0)"
            (click)="operatorClicked()" [ngClass]="{'active' : isOperatorActive}">
            {{ 'userlistNavMenuOperators' | translate }}
            </a>
            <a class="nav-item nav-link" href="javascript:void(0)"
            (click)="locationsClicked()" [ngClass]="{'active' : isLocationActive}">
            {{ 'binListViewLocation' | translate }}
            </a>
          </div>
        </nav>
    </header>

    <!-- Components list add to the entitie component -->
    <app-distributors-list *ngIf="isDistributorsActive" class="container-fluido componentBelow"></app-distributors-list>
    <app-client-list *ngIf="isClientsActive" class="container-fluido componentBelow"></app-client-list>
    <app-operator-list *ngIf="isOperatorActive" class="container-fluido componentBelow"></app-operator-list>
    <app-entities-location-list *ngIf="isLocationActive" class="container-fluido componentBelow"></app-entities-location-list>
</div>

