import { ValidationService } from './../service/validation.service';
import { Component, OnInit, Renderer2, HostListener, ElementRef, Inject, forwardRef } from '@angular/core';
import { LocalStorageService } from '../local-storage.service';
import { CognitoService } from '../service/cognito.service';
import { TranslateService } from '@ngx-translate/core';
import { ThemeService } from '../service/theme.service';
import { NavigationService } from '../service/navigation.service';
import { ClientService } from '../service/client.service';
import { DistributorsService } from '../service/distributors.service';
import { Router } from '@angular/router';
import { SystemMessageService } from '../service/system-message.service';
import { FilterService } from '../service/filter.service';

export interface UserAttribute {
  Name: string;
  Value: string;
}

@Component({
  selector: 'app-user-list',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.css', '../../global-elements.css']
})

export class UserListComponent implements OnInit {

  // Variable to store the language selected value
  public selectedLanguage: string = "";

  // Retrieve current language selected from local storage
  languageStatus:string = this.localStorageService.getItem('language');

  // Retrieve last content selected from local storage
  lastEntitiesContent:string = this.localStorageService.getItem('adminContent')

  // Variables for user filtering
  public mainContent: string = '';
  public userFilterTest: string ="";
  public userFilter: string = "";
  public filterBy: string = '';
  public userTest: string = "";
  public searchText: string = '';

  public clientArray: any[] = [];

  // Array Fetch customer data
  public clientsData: any[] = [];

  // Flags for UI state
  public isClientsActive: boolean = false;
  public isDistributorsActive: boolean = false;
  public isProvidersActive: boolean = false;
  public isTaxeTypeActive: boolean = true;

  // Flags for UI state
  public showList: boolean = false;
  public filterOn: boolean = false;
  public loadingMoreData = false;
  public isDistributorsActvive: boolean = false;

  public userList: any[] = [];
  public originalUserArray: any[] = [];
  public legalName: any;

  // Value to manage the pagination
  private itemsPerPage = 5;
  private loadedItems = 0;

  public isDropdownOpen: boolean = false;
  // Put a host listener on document click event
  @HostListener('document:click', ['$event'])
  // Function that handle the event click
  handleClick(event: Event){
    // Set an instance of the dropdown button with the id of it
    const buttonDropdown = this.elementRef.nativeElement.querySelector('#buttonDropdown');
    // Set an instance of the dropdown content element with the id of it
    const dropdownContent = this.elementRef.nativeElement.querySelector('#dropdownContent');
    // If the click event is not targetting one of the button or dropdown content
    if(event.target !== buttonDropdown && event.target !== dropdownContent){
      // Set the variable to show/hide the dropdown content to false to hide it
      this.isDropdownOpen = false;
    }
  }

  constructor(
    @Inject(forwardRef(() => TranslateService)) private translate: TranslateService,
    private localStorageService: LocalStorageService,
    public cognitoService: CognitoService,
    public validationService: ValidationService,
    public theme: ThemeService,
    public navigationService: NavigationService,
    public clientService: ClientService,
    public distributorService: DistributorsService,
    private router: Router,
    private renderer: Renderer2,
    private elementRef: ElementRef,
    public systemMessageService: SystemMessageService,
    private filter: FilterService
  ) {

    // Check if the user has selected a language in local storage
    //or use a default language
    if (this.languageStatus == null){
      // Set the default language to French
      translate.use('fr');
    } else {
      // Set the default language to the user's selected language
      translate.use(this.languageStatus);
    }
  }

  async ngOnInit(): Promise<void> {

    // Call the getAllUsers function from the cognitoService to retrieve all users
    await this.cognitoService.getAllUsers();

    // Function to load initial user data and reset the loading state
    this.getUserList();

    // Call the getUser function from the cognitoService to retrieve the current user
    await this.cognitoService.getUser();

    const fromComponent = sessionStorage.getItem('from');

    if(fromComponent){
      // Reload the page to reflect the updated list
      window.location.reload();
      switch(fromComponent){
        case 'user-create':
          this.systemMessageService.selectRibbon('success', 'userCreateSuccessfully');
          break;

        case 'user-update':
          this.systemMessageService.selectRibbon('success', 'userUpdated')
          break;
      }
      sessionStorage.removeItem('from');
    }
  }

  ngAfterViewInit(): void {
    // Call the function to detect if the user has reached the end of the page on a mobile device
    this.detectEndOfPageOnMobile();
  }

  // get user list and get data with lazy loading
  getUserList() {
    // Retrieve the user list from the cognitoService
    this.userList = this.cognitoService.cognitoUsersArray;
    this.originalUserArray = this.cognitoService.cognitoUsersArray;

    // Slice the user list to only include the first 'itemsPerPage' users
    this.userList = this.userList.slice(0, this.itemsPerPage);

    // Set the number of loaded items to 'itemsPerPage'
    this.loadedItems = this.itemsPerPage;
  }

  // Load more users when reaching the bottom of the page
  loadMoreItems() {
    // Slice the remaining items based on the loaded items and items per page
    const remainingItems = this.cognitoService.cognitoUsersArray.slice(this.loadedItems, this.loadedItems + this.itemsPerPage);

    // Add more items to the displayed list by concatenating the remaining items
    this.userList = this.userList.concat(remainingItems);

    // Update the count of loaded items
    this.loadedItems += this.itemsPerPage;
  }

  // Function used to check how mush user scroll down on a mobile to load more items on the list
  detectEndOfPageOnMobile(): void {
    // Check if the user is using a mobile device
    if (this.isMobileDevice()) {
      // Listen for the 'touchmove' event to track scrolling on the document
      this.renderer.listen('document', 'touchmove', (event: TouchEvent) => {
        // Get the current scroll position, window height, and document height
        const scrollPosition = window.scrollY || document.documentElement.scrollTop || document.body.scrollTop || 0;
        const windowHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight || 0;
        const documentHeight = Math.max(
          document.body.scrollHeight,
          document.documentElement.scrollHeight,
          document.body.offsetHeight,
          document.documentElement.offsetHeight,
          document.documentElement.clientHeight
        );
        // Check if the user has scrolled to the end of the page and not currently loading more data
        if (scrollPosition + windowHeight >= documentHeight && !this.loadingMoreData) {
            this.loadMoreItems();
        }
      });
    }
  }

  // Function to determine if the current device is a mobile device
  isMobileDevice(): boolean {
    // Get the user agent string and convert it to lowercase
    const userAgent = navigator.userAgent.toLowerCase();

    // Check if the user agent matches any common mobile device keywords
    return /mobile|android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini/i.test(userAgent);
  }

  // Host listener to detect scroll events on the window
  @HostListener('window:scroll', []) onScroll(): void {

    // Get the height of the visible window area
    const windowHeight = 'innerHeight' in window ? window.innerHeight : document.documentElement.offsetHeight;

    // Get references to the document's body and html elements
    const body = document.body;
    const html = document.documentElement;

    // Calculate the maximum document height using various metrics
    const documentHeight = Math.max(body.scrollHeight, body.offsetHeight, html.clientHeight, html.scrollHeight, html.offsetHeight);

    // Get the current scroll position
    const scrollPosition = window.scrollY || document.documentElement.scrollTop || document.body.scrollTop || 0;

    // Check if the user has scrolled near the bottom of the page
    if (documentHeight - windowHeight - scrollPosition < 100 && !this.loadingMoreData && !this.filterOn) {
      this.loadMoreItems()
    }
  }

  // Function used to check witch user type the current user id then redirect him to the proper user-update page
  identifyUserType(user: any) {
    // Extract user details
    let userSub = user.sub;
    let userUsername = user.username;
    let userType = user.custom_user_type.toLowerCase();

    // Use a switch statement to navigate to the appropriate update route based on user type
    switch(userType) {
      // If the user type is 'client'
      case "client":
        // Navigate to the 'user-update-client' route with query parameters
        this.router.navigate(['/user-update-client'], {
          queryParams: {
            userSub,
            userUsername,
            userType
          }
        });
        break;
      // If the user type is 'distributor'
      case "distributor":
        // Navigate to the 'user-update-distributor' route with query parameters
        this.router.navigate(['/user-update-distributor'], {
          queryParams: {
            userSub,
            userUsername,
            userType
          }
        });
        break;
      // If the user type is 'muirwood'
      case "muirwood":
        // Navigate to the 'user-update-muirwood' route with query parameters
        this.router.navigate(['/user-update-muirwood'], {
          queryParams: {
            userSub,
            userUsername,
            userType
          }
        });
        break;
      // If the user type is operator
      case 'operator':
        //Navigate to the user-update-operator component
        this.router.navigate(['/user-update-operator'], {
          queryParams:{
            userSub,
            userUsername,
            userType
          }
        });
        break;
    }
  }

  /**
   * Retrieve a specific attribute value for a user from their attributes array.
   * @param user - The user object containing attributes.
   * @param attributeName - The name of the attribute to retrieve.
   * @returns The value of the specified attribute or an empty string if not found.
   */
  getUserAttributeValue(user: any, attributeName: string): string {
    // Find the attribute with the given 'attributeName' in the user's attributes array
    const attribute = user.Attributes.find((attr: { Name: string, Value: string }) => attr.Name === attributeName);

    // Return the value of the attribute if found, or an empty string if not found
    return attribute && attribute.Value ? attribute.Value : '';
  }

  // Function used to made a search in the list
  search(){
    // Check if there's a filterBy variable and set it to it's default value
    if(this.filterBy === ''){
      this.filterBy = 'given_name';
    }

    this.userList = this.filter.search(this.userFilter, this.userList, this.originalUserArray, this.filterBy);
  }

  // function triggered when user change filterBy select
  filterByChange(){
    if(this.userFilter !== ''){
      // Trigger search function if the userfilter is not empty
      this.search();
    }
  }

  // Function used to format phone number as (###) ###-####
  formatNumber(numberSequence: string | undefined | null): string {
    // Check if the numberSequence is undefined or null
    if (numberSequence === undefined || numberSequence === null || numberSequence.length == 0) {
        return ''; // Return an empty string
    }

    // Remove non-numeric characters from the input
    const justNumbers = numberSequence.replace(/\D/g, '');

    // Check if there is a country code
    const countryCode = justNumbers.length > 10 ? `+${justNumbers.slice(0, -10)}` : '';

    // Get the last 10 digits of the number
    const last10Digits = justNumbers.slice(-10);

    // Format the last 10 digits as (XXX) XXX-XXXX
    const part1 = last10Digits.slice(0, 3);
    const part2 = last10Digits.slice(3, 6);
    const part3 = last10Digits.slice(6);
    const formattedNumber = `(${part1}) ${part2}-${part3}`;

    // Include the country code if it exists
    return countryCode.length > 0 ? `${countryCode} ${formattedNumber}` : formattedNumber;
  }

  // Function used to trigger the drop down on the action button
  dropdownAction(){
    this.isDropdownOpen = !this.isDropdownOpen;
  }
}

