import { Component, HostListener } from '@angular/core';
import { ThemeService } from '../service/theme.service';
import { navigation } from '../constants/navigation';
import { DashboardService } from '../service/dashboard.service';
import { environment } from '../environments/environment';
import { ChartService } from '../service/chart.service';
import { CognitoService } from '../service/cognito.service';
import { lastValueFrom } from 'rxjs';
import { Router } from '@angular/router';

@Component({
  selector: 'app-distributor-dashboard',
  templateUrl: './distributor-dashboard.component.html',
  styleUrls: ['./distributor-dashboard.component.css', '../../dashboard-tiles.css']
})
export class DistributorDashboardComponent {
  gridsterOptions = {
    draggable: {
      enabled: false,
    },
    resizable: {
      enabled: true,
    },
    minCols: 1,
    maxCols: 3,
    minRows: 1,
    maxRows: 2,
    defaultItemCols: 1,
    defaultItemRows: 1,
  };

  public navigationItemsArray: any = [];

  // Variable for the size of the screen
  public isSmallScreenWidth: boolean = this.getScreenWidth() <= 859;

  public stopLoading: boolean = false;

  constructor(public theme: ThemeService,
              public dashboard: DashboardService,
              public charts: ChartService,
              private cognitoService: CognitoService,
              public dashboardService: DashboardService,
              private router: Router,
              public users: CognitoService
    ){

  }

  async ngOnInit(){
    this.navigationItemsArray = navigation.filter(item => item.userType === environment.users.supplier);

    this.dashboard.initArrays();

    // function to translate all labels in the charts
    this.charts.translateChartsLegends();

    //Get current screen width on init
    const currentScreenWidth = this.getScreenWidth();
    const currentScreenHeight = this.getScreenHeight();

    // Check the screen width and update the isSmallScreen flag accordingly
    this.isSmallScreenWidth = currentScreenWidth <= 859;

    //Adjust chart parameters for view size
    this.charts.adjustView(currentScreenWidth, currentScreenHeight);

    // Call function to initialize dashboard in dashboard service
    await this.dashboard.initializaDistributorDashboardData();
  }

  // Event listener for window resize
  @HostListener('window:resize', ['$event'])
    onResize(event: any): void {
      // get the current window width and height
      const currentScreenWidth = this.getScreenWidth();
      const currentScreenHeight = this.getScreenHeight();
      // Check the screen width and update the isSmallScreen flag accordingly
      this.isSmallScreenWidth = currentScreenWidth <= 859;

      //Adjust chart parameters for view size
      this.charts.adjustView(currentScreenWidth, currentScreenHeight);
  }

  /**
   * Function that handles click on anchor tag of the tiles of the client dashboard
   * @param event the event object
   * @param route the target route
   */
  handleLinkClick(event: MouseEvent, route: string) {
    //set a key-value pair for navigation of user-create-client
    if (route === '/user-create-distributor') {
      sessionStorage.setItem('createDistributor','dashboard')
    }
    //navigate to proper target
    this.router.navigate([`/${route}`])
  }

  // Function to get the current screen width
  getScreenWidth(): number {
    // Return the inner width of the window as the screen width
    return window.innerWidth;
  }

  // Funciton to get the current screen height
  getScreenHeight(): number {
    // Retunr the current screen height of the window
    return window.innerHeight;
  }
}
