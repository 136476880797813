<app-system-message class="systemMessage"></app-system-message>

<div class="container-fluid" [ngClass]="theme.getThemeClass()" >
  <app-loading-element *ngIf="loading" class="container-fluido componentBelow"></app-loading-element>
  <!-- LOGIN VIEW -->
  <section
    class="form-group"
    [ngClass]="{ 'd-none': !loginActive }"
    *ngIf="loginActive"
  >
    <article  [ngClass]="theme.getThemeClass()" class="wrapper fadeInDown">
      <div  [ngClass]="theme.getThemeClass()" id="formContent">
        <!-- ICON -->
        <nav class="fadeIn first">
          <img class="logo" src="../assets/muirwood-studio.png" />
        </nav>
        <div>
          <h1>{{ "cognitoLoginSignIn" | translate }}</h1>
        </div>
        <!-- LOGIN FORM -->
        <form (ngSubmit)="onSubmit()">
          <div class="form-group fadeIn second">
            <!-- Input for user email -->
            <input
              type="text"
              id="login"
              [(ngModel)]="user.email"
              class="form-control"
              name="login"
              placeholder="{{ 'cognitoLoginUsername' | translate }}"
              required
              required
              minlength="4"
              #userName="ngModel"
              autocomplete="off"
            />
            <!-- Input for user password -->
            <div class="password-input">
              <input
                type="{{ showPassword ? 'text' : 'password' }}"
                id="password"
                [(ngModel)]="user.password"
                class="form-control"
                name="password"
                placeholder="{{ 'cognitoLoginPassword' | translate }}"
                required
                minlength="4"
                #userPassword="ngModel"
                autocomplete="off"
              />
              <!-- Icon of an eye thet show/hide the user password -->
              <span (click)="togglePasswordVisibility('password')">
                <i
                  id="eyeIconPassword"
                  style="color: #000;"
                  class="fa"
                  [class.fa-eye-slash]="!showPassword"
                ></i>
              </span>
            </div>
          </div>
          <!-- Button that submit the form -->
          <input
            type="submit"
            skipLocationChange
            class="fadeIn fourth"
            value="{{ 'cognitoLoginBtnLogIn' | translate }}"
            height="30px"
          />
        </form>
        <!-- RESET PASSWORD -->
        <nav  [ngClass]="theme.getThemeClass()" id="formFooter">
          <a
            class="underlineHover"
            href="javascript:void(0)"
            (click)="forgotPasswordClick()"
          >
            {{ "loginForgotPassword" | translate }}
          </a>
        </nav>
      </div>

    </article>
  </section>

  <!-- RESET PASSWORD USING MAIL VIEW -->
  <section
    class="form-group"
    [ngClass]="{ 'd-none': !resetPasswordActive }"
    *ngIf="resetPasswordActive"
  >
    <article [ngClass]="theme.getThemeClass()" class="wrapper fadeInDown wrapperAdds">
      <img class="logo" src="../assets/muirwood-studio.png" />
      <h2 class="text-center">
        {{ "loginForgotPassword" | translate }}
      </h2>
      <p>{{ "loginForgotPasswordMessage" | translate }}</p>
      <form
        (ngSubmit)="sendUserEmailClick()"
        id="register-form"
        role="form"
        autocomplete="off"
        class="form"
        method="post"
      >
        <div [ngClass]="theme.getThemeClass()" class="form-group">
          <div class="input-group">
            <!-- Input for user email -->
            <input
              type="email"
              id="email"
              [(ngModel)]="email"
              class="form-control"
              name="email"
              placeholder="{{
                'loginForgotPasswordPlaceHolderEmailAddress' | translate
              }}"
              required
              required
              minlength="1"
              #resetPassword="ngModel"
              email="true"
              (input)="checkEmail()"
            />
          </div>
          <!-- Button to send email of reset password to the user -->
          <nav [ngClass]="theme.getThemeClass()" class="divBtn">
            <a class="btnA">
              <div class="btn">
                <input
                  class="inputBtn"
                  name="recover-submit"
                  value="{{
                    'loginForgotPasswordBtnResetPassword' | translate
                  }}"
                  type="submit"
                />
              </div>
            </a>
          </nav>
        </div>
      </form>
    </article>
  </section>

  <!-- RESET PASSWORD USING RESET CODE VIEW -->
  <section
    class="form-group"
    [ngClass]="{ 'd-none': !forgotPasswordForm }"
    *ngIf="forgotPasswordForm"
  >
    <article [ngClass]="theme.getThemeClass()" class="wrapper fadeInDown wrapperAdds">
      <img class="logo" src="../assets/muirwood-studio.png" />
      <h2 class="text-center">
        {{ "loginForgotPasswordCreateNewPW" | translate }}
      </h2>
      <p>{{ "loginForgotPasswordFillFields" | translate }}</p>
      <form
        (ngSubmit)="submitPasswordReset()"
        id="register-form"
        role="form"
        autocomplete="off"
        class="form resetWithCodeView"
        method="post"
      >
        <!-- Input section -->
        <input
          type="text"
          id="resetCode"
          [(ngModel)]="user.code"
          class="form-control"
          name="resetCode"
          placeholder="{{ 'loginForgotPasswordPlaceHolder' | translate }}"
          required
          minlength="1"
          #userCode="ngModel"
        />
        <input
          type="{{ showPassword ? 'text' : 'password' }}"
          id="password"
          [(ngModel)]="user.password"
          class="form-control"
          name="password"
          placeholder="{{ 'loginForgotPasswordPlaceHolderNewPW' | translate }}"
          required
          minlength="1"
          #requestPassword="ngModel"
          (input)="validatePassword()"
        />
        <a>
          <div [ngClass]="theme.getThemeClass()" class="popup" *ngIf="showCheckBoxTipResetPw">
            <label>{{
              "loginFNewPasswordLabelPasswordRequirements" | translate
            }}</label
            ><br />
            <!-- IMG check or oncheck section for password wikeness-->
            <nav class="centered-container">
              <img
                *ngIf="!charactersChecked"
                id="noChecked"
                src="assets/iconchecked.png"
                alt="dinamicCheckBox"
                class="checkSymbol"
              />
              <img
                *ngIf="charactersChecked"
                id="noChecked"
                src="assets/icochecked.png"
                alt="dinamicCheckBox"
                class="checkSymbol"
              />
              <span>{{
                "loginFNewPasswordPasswordRequirementsCharQtt" | translate
              }}</span>
            </nav>
            <nav [ngClass]="theme.getThemeClass()" class="centered-container">
              <img
                *ngIf="!lowerCaseChecked"
                id="noChecked"
                src="assets/iconchecked.png"
                alt="dinamicCheckBox"
                class="checkSymbol"
              />
              <img
                *ngIf="lowerCaseChecked"
                id="noChecked"
                src="assets/icochecked.png"
                alt="dinamicCheckBox"
                class="checkSymbol"
              />
              <span>{{
                "loginFNewPasswordPasswordRequirementsOneLowercaseLetter"
                  | translate
              }}</span>
            </nav>
            <nav [ngClass]="theme.getThemeClass()" class="centered-container">
              <img
                *ngIf="!upperCaseChecked"
                id="noChecked"
                src="assets/iconchecked.png"
                alt="dinamicCheckBox"
                class="checkSymbol"
              />
              <img
                *ngIf="upperCaseChecked"
                id="noChecked"
                src="assets/icochecked.png"
                alt="dinamicCheckBox"
                class="checkSymbol"
              />
              <span>{{
                "loginFNewPasswordPasswordRequirementsOneUppercaseLetter"
                  | translate
              }}</span>
            </nav>
            <nav [ngClass]="theme.getThemeClass()" class="centered-container">
              <img
                *ngIf="!oneDigitChecked"
                id="noChecked"
                src="assets/iconchecked.png"
                alt="dinamicCheckBox"
                class="checkSymbol"
              />
              <img
                *ngIf="oneDigitChecked"
                id="noChecked"
                src="assets/icochecked.png"
                alt="dinamicCheckBox"
                class="checkSymbol"
              />
              <span>{{
                "loginFNewPasswordPasswordRequirementsOneDigit" | translate
              }}</span>
            </nav>
            <nav [ngClass]="theme.getThemeClass()" class="centered-container">
              <img
                *ngIf="!specialCharChecked"
                id="noChecked"
                src="assets/iconchecked.png"
                alt="dinamicCheckBox"
                class="checkSymbol"
              />
              <img
                *ngIf="specialCharChecked"
                id="noChecked"
                src="assets/icochecked.png"
                alt="dinamicCheckBox"
                class="checkSymbol"
              />
              <span>{{
                "loginFNewPasswordPasswordRequirementsOneSpecialChar"
                  | translate
              }}</span>
            </nav>
          </div>
          <!-- End of IMG check or oncheck section for password wikeness-->
          <nav [ngClass]="theme.getThemeClass()" class="btn">
            <input
              class="inputBtn"
              name="recover-submit"
              value="{{ 'loginForgotSubmit' | translate }}"
              type="submit"
            />
          </nav>
        </a>
      </form>
    </article>
  </section>

  <!-- NEW PASSWORD VIEW -->
  <section
    class="form-group"
    [ngClass]="{ 'd-none': !newPasswordFormActive }"
    *ngIf="newPasswordFormActive"
  >
    <article [ngClass]="theme.getThemeClass()" class="wrapper fadeInDown">
      <div id="formContent" [ngClass]="theme.getThemeClass()">
        <img class="logo" src="../assets/muirwood-studio.png" [ngClass]="theme.getThemeClass()"/>
        <br />
        <h2 [ngClass]="theme.getThemeClass()" class="text-center">{{ "loginFNewPassword" | translate }}</h2>
        <p>{{ "loginFNewPasswordMessage" | translate }}</p>
        <!-- You can reset your password here.-->
        <div class="panel-body">
          <form
            name="newPassForm"
            (ngSubmit)="confirmNewPasswordClick()"
            id="register-form"
            role="form"
            autocomplete="off"
            class="form"
            method="post"
          >
            <div [ngClass]="theme.getThemeClass()" class="form-group">
              <div class="password-input">
                <!-- Old password -->
                <input
                  type="{{ showOldPassword ? 'text' : 'password' }}"
                  id="oldPassword"
                  [(ngModel)]="user.password"
                  class="form-control font14px"
                  name="requestOldPassword"
                  placeholder="{{
                    'loginFNewPasswordPlaceHolderOldPassword' | translate
                  }}"
                  required
                  required
                  minlength="1"
                  #requestPassword="ngModel"
                />
              </div>
              <!-- New password input -->
              <div [ngClass]="theme.getThemeClass()" class="password-input">
                <input
                  type="{{ showPassword ? 'text' : 'password' }}"
                  id="newPassword"
                  [(ngModel)]="requestNewPassword"
                  class="form-control font14px"
                  name="requestNewPasswordfirst"
                  placeholder="{{
                    'loginFNewPasswordPlaceHolderNewPassword' | translate
                  }}"
                  required
                  required
                  minlength="1"
                  #requestPassword="ngModel"
                  (input)="validatePassword()"
                />
                <!-- Toggle show/hide password -->
                <span  (click)="togglePasswordVisibility('password')">
                  <i
                    id="eyeIconPassword"
                    class="fa text-dark"
                    [class.fa-eye-slash]="!showPassword"
                    style="color: #000;"
                  ></i>
                </span>
              </div>

              <div class="colorGreen" *ngIf="showAlertStrong">
                {{ "loginFNewPasswordAlertStrongPassword" | translate }}
              </div>
              <div class="colorRed" *ngIf="showAlertWeak">
                {{ "loginFNewPasswordAlertWeakPassword" | translate }}
              </div>
              <div class="password-input">
                <!-- Confirm new password -->
                <input
                  type="{{ showPasswordConfirmation ? 'text' : 'password' }}"
                  id="newPasswordConfirmation"
                  [(ngModel)]="requestNewPasswordConfirmation"
                  class="form-control font14px"
                  name="requestNewPasswordConfirm"
                  placeholder="{{
                    'loginFNewPasswordPlaceHolderConfirmNewPassword' | translate
                  }}"
                  required
                  required
                  minlength="1"
                  #requestPasswordConf="ngModel"
                />
                <!-- Toggle show/hide password -->
                <span (click)="togglePasswordVisibility('confirmPassword')">
                  <i
                    id="eyeIconConfirmPassword"
                    class="fa text-dark"
                    [class.fa-eye-slash]="!showPasswordConfirmation"
                  ></i>
                </span>
              </div>
              <div class="popup" *ngIf="showCheckBoxTip" [ngClass]="theme.getThemeClass()">
                <label>{{
                  "loginFNewPasswordLabelPasswordRequirements" | translate
                }}</label>
                <!-- Section IMG for password wickness -->
                <nav class="container-checkbox centered-container">
                  <img
                    *ngIf="!charactersChecked"
                    id="noChecked"
                    src="assets/iconchecked.png"
                    alt="dinamicCheckBox"
                    class="checkSymbol"
                  />
                  <img
                    *ngIf="charactersChecked"
                    id="noChecked"
                    src="assets/icochecked.png"
                    alt="dinamicCheckBox"
                    class="checkSymbol"
                  />
                  <span>{{
                    "loginFNewPasswordPasswordRequirementsCharQtt" | translate
                  }}</span>
                </nav>
                <nav class="centered-container container-checkbox" [ngClass]="theme.getThemeClass()">
                  <img
                    *ngIf="!lowerCaseChecked"
                    id="noChecked"
                    src="assets/iconchecked.png"
                    alt="dinamicCheckBox"
                    class="checkSymbol"
                  />
                  <img
                    *ngIf="lowerCaseChecked"
                    id="noChecked"
                    src="assets/icochecked.png"
                    alt="dinamicCheckBox"
                    class="checkSymbol"
                  />
                  <span>{{
                    "loginFNewPasswordPasswordRequirementsOneLowercaseLetter"
                      | translate
                  }}</span>
                </nav >
                <nav class="centered-container container-checkbox" [ngClass]="theme.getThemeClass()">
                  <img
                    *ngIf="!upperCaseChecked"
                    id="noChecked"
                    src="assets/iconchecked.png"
                    alt="dinamicCheckBox"
                    class="checkSymbol"
                  />
                  <img
                    *ngIf="upperCaseChecked"
                    id="noChecked"
                    src="assets/icochecked.png"
                    alt="dinamicCheckBox"
                    class="checkSymbol"
                  />
                  <span>{{
                    "loginFNewPasswordPasswordRequirementsOneUppercaseLetter"
                      | translate
                  }}</span>
                </nav>
                <nav class="centered-container container-checkbox" [ngClass]="theme.getThemeClass()">
                  <img
                    *ngIf="!oneDigitChecked"
                    id="noChecked"
                    src="assets/iconchecked.png"
                    alt="dinamicCheckBox"
                    class="checkSymbol"
                  />
                  <img
                    *ngIf="oneDigitChecked"
                    id="noChecked"
                    src="assets/icochecked.png"
                    alt="dinamicCheckBox"
                    class="checkSymbol"
                  />
                  <span>{{
                    "loginFNewPasswordPasswordRequirementsOneDigit" | translate
                  }}</span>
                </nav>
                <nav class="centered-container container-checkbox" [ngClass]="theme.getThemeClass()">
                  <img
                    *ngIf="!specialCharChecked"
                    id="noChecked"
                    src="assets/iconchecked.png"
                    alt="dinamicCheckBox"
                    class="checkSymbol"
                  />
                  <img
                    *ngIf="specialCharChecked"
                    id="noChecked"
                    src="assets/icochecked.png"
                    alt="dinamicCheckBox"
                    class="checkSymbol"
                  />
                  <!-- End of section IMG for password wickness -->
                  <span>{{
                    "loginFNewPasswordPasswordRequirementsOneSpecialChar"
                      | translate
                  }}</span>
                </nav>
              </div>
              <div
                *ngIf="requestNewPassword !== requestNewPasswordConfirmation"
                class="customalert"
              >
                {{ "loginFNewPasswordAlertPwDontMatch" | translate }}
              </div>
              <div class="recoverBtn">
                <a>
                  <input
                    name="recover-return"
                    class="btn btn-lg btn-primary btn-block return-btn"
                    value="{{ 'userEditButtonLabelReturn' | translate }}"
                    type="button"
                    (click)="cancelHandler()"
                  />
                </a>
                <a>
                  <input
                    name="recover-submit"
                    class="btn btn-lg btn-primary btn-block submit-btn"
                    value="{{ 'loginFNewPasswordBtnConfirmNewPw' | translate }}"
                    type="submit"
                  />
                </a>
              </div>
            </div>
          </form>
        </div>
      </div>
    </article>
  </section>
  <!-- Form to verified email adress on first signIn -->
  <section
    class="form-group"
    [ngClass]="{ 'd-none': !emailValidationActive }"
    *ngIf="emailValidationActive"
  >
    <article [ngClass]="theme.getThemeClass()" class="wrapper fadeInDown" id="formContent">
      <!-- ICON -->
      <nav class="fadeIn first">
        <img class="logo" src="../assets/muirwood-studio.png" />
      </nav>
      <div>
        <h3>{{ "validationCode" | translate }}</h3>
      </div>
      <!-- LOGIN FORM -->
      <form (ngSubmit)="emailValidate()">
        <div  class="form-group fadeIn second">
          <!-- Input for confirmation code received from email -->
          <input
            id="emailValidation"
            type="text"
            [(ngModel)]="confirmationCode"
            class="form-control"
            name="emailValidation"
            placeholder="{{ 'validationCode' | translate }}"
            required
            autocomplete="off"
            s
          />
        </div>
        <div style="display: flex; flex-direction: column;">
          <input
              type="submit"
              skipLocationChange
              class="fadeIn fourth"
              value="{{ 'validateCode' | translate }}"
              height="30px"
          />
          <a
              href="#"
              onclick="return false;"
              (click)="this.cognitoService.resendValidationCode()"
              class="fadeIn fourth"
              height="30px">{{ 'requestNewCode' | translate }}
          </a>
        </div>
      </form>
    </article>




  </section>
   <!-- Form to verified phone number -->
   <section
   class="form-group"
   [ngClass]="{ 'd-none': !phoneAuthentificationActive }"
   *ngIf="phoneAuthentificationActive"
 >
   <article class="wrapper fadeInDown" id="formContent">
     <!-- ICON -->
     <nav class="fadeIn first">
       <img class="logo" src="../assets/muirwood-studio.png" />
     </nav>
     <div>
       <h3>{{ "validationCode" | translate }}</h3>
     </div>
     <!-- LOGIN FORM -->
     <form (ngSubmit)="phoneNumberValidate()">
       <div class="form-group fadeIn second">
        <!-- Input for validation code received by phone number -->
         <input
           id="phoneNumberValidation"
           type="text"
           [(ngModel)]="confirmationCode"
           class="form-control"
           name="phoneNumberValidation"
           placeholder="{{ 'validationCode' | translate }}"
           required
           autocomplete="off"
           s
         />
       </div>
       <input
         type="submit"
         skipLocationChange
         class="fadeIn fourth"
         value="{{ 'validateCode' | translate }}"
         height="30px"
       />
     </form>
   </article>
 </section>
  <!-- Sign-up whit MFA -->
  <section
    class="form-group fadeInDown text-center"
    id="formContent"
    [ngClass]="{ 'd-none': !signUpMFAActive }"
    *ngIf="signUpMFAActive"
  >
    <article [ngClass]="theme.getThemeClass()">
      <img class="logo" src="../assets/muirwood-studio.png" />
      <h2 class="text-center">{{ "signUpMFA" | translate }}</h2>


      <form
        (ngSubmit)="submitMFACodeClick()"
        id="register-form"
        role="form"
        autocomplete="off"
        class="form"
        method="post"
      >
          <!-- Section for the SMS sign-up display ------ NOT IN USE FOR NOW -->
          <div *ngIf="mfaChecked">
            <p>{{ "smsSendTo" | translate }}</p>
            <div class="input-group">
              <input
                type="text"
                id="phone_number"
                [(ngModel)]="phone_number"
                class="form-control"
                name="phone_number"
                placeholder="{{ 'phoneNumber' | translate }}"
                required
                required
                minlength="1"
                [attr.readonly]="true"
                onfocus="this.blur()"
              />
            </div>
            <div class="input-group">
              <input
                type="text"
                id="codeMFA"
                [(ngModel)]="MFACodeSMS"
                class="form-control"
                name="codeMFA"
                placeholder="{{ 'enterMFACode' | translate }}"
                required
                required
                minlength="1"
              />
            </div>
            <nav class="divBtn">
              <a
                class="btnA"
                (click)="this.cognitoService.sendSMS()"
              >
                <div class="btn">
                  <input
                    class="inputBtn"
                    name="recover-submit"
                    value="{{ 'sendMFACode' | translate }}"
                    type="submit"
                  />
                </div>
              </a>
              <a class="btnA">
                <div class="btn">
                  <input
                    class="inputBtn"
                    name="recover-submit"
                    value="{{ 'loginForgotSubmit' | translate }}"
                    type="submit"
                  />
                </div>
              </a>
            </nav>
          </div>

          <!-- Section for the TOTP sign-up -->
          <!-- First page that display the Qr code -->
          <div [ngClass]="theme.getThemeClass()" *ngIf="!mfaChecked && !nextTotpPage">
            <img
              *ngIf="this.cognitoService.qrCodeImgActive"
              [src]="this.cognitoService.imgUrl"
              alt="Code Qr"
            />

            <nav class="divBtn">
              <a class="btnA" (click)="backToEditPage()">
                <div class="btn">
                  <input
                    class="inputBtn"
                    name="recover-submit"
                    value="{{ 'userEditButtonLabelReturn' | translate }}"
                    type="submit"
                  />
                </div>
              </a>
              <a class="btnA">
                <div class="btn">
                  <input
                    class="inputBtn"
                    (click)="nextTotpPage = true"
                    name="recover-submit"
                    value="{{ 'next' | translate }}"
                    type="button"
                  />
                </div>
              </a>
            </nav>
          </div>
          <!-- Second page that ask to enter the verification TOTP code -->
          <div [ngClass]="theme.getThemeClass()" *ngIf="!mfaChecked && nextTotpPage">
            <div [ngClass]="theme.getThemeClass()" class="input-group">
              <input #enterMFA
                type="text"
                id="codeMFA"
                [(ngModel)]="MFACodeTOTP"
                class="form-control"
                name="codeMFA"
                placeholder="{{ 'enterMFACode' | translate }}"
                required
                minlength="1"

              />
            </div>
            <nav [ngClass]="theme.getThemeClass()" class="divBtn">
              <a class="btnA">
                <div class="btn">
                  <input
                    class="inputBtn"
                    (click)="nextTotpPage = false"
                    name="recover-submit"
                    value="{{ 'back' | translate }}"
                    type="button"
                  />
                </div>
              </a>
              <a class="btnA">
                <div class="btn">
                  <input
                    class="inputBtn"
                    name="recover-submit"
                    value="{{ 'loginForgotSubmit' | translate }}"
                    type="submit"
                  />
                </div>
              </a>
            </nav>
          </div>
      </form>
    </article>
  </section>
  <!-- MFA sign-in form -->
  <section
    class="form-group"
    [ngClass]="{ 'd-none': !MFACodeRequiredActive }"
    *ngIf="MFACodeRequiredActive"
  >
    <article [ngClass]="theme.getThemeClass()" class="wrapper fadeInDown">
      <div [ngClass]="theme.getThemeClass()" id="formContent">
        <!-- ICON -->
        <nav class="fadeIn first">
          <img class="logo" src="../assets/muirwood-studio.png" />
        </nav>
        <div>
          <h3>{{ "enterMFACode" | translate }}</h3>
        </div>
        <!-- LOGIN FORM -->
        <form (ngSubmit)="MFASignIn()">
          <div [ngClass]="theme.getThemeClass()" class="form-group">
            <div class="fadeIn second">
              <input
                id="MFACode"
                type="text"
                [(ngModel)]="MFACode"
                class="form-control"
                name="MFACode"
                placeholder="{{ 'MFACode' | translate }}"
                required
                autocomplete="off"
                s
              />
            </div>
          </div>
          <input
            type="submit"
            skipLocationChange
            class="fadeIn fourth"
            value="{{ 'cognitoLoginBtnLogIn' | translate }}"
            height="30px"
          />
        </form>
      </div>

    </article>
  </section>
</div>
