<!-- Container -->
<div [ngClass]="theme.getThemeClass()" id="chart-container" class="chart-container">
  <!-- Chart -->
  <ngx-charts-bar-vertical id="bar-chart-collections"
    [view]="charts.viewBarChartCollections"
    [scheme]="charts.colorSchemeBarChartCollections"
    [results]="charts.singleBarChartCollections"
    [gradient]="charts.gradientBarChartCollections"
    [xAxis]="charts.showXAxisBarChartCollections"
    [yAxis]="charts.showYAxisBarChartCollections"
    [legend]="charts.showLegendBarChartCollections"
    [showXAxisLabel]="charts.showXAxisLabelBarChartCollections"
    [showYAxisLabel]="charts.showYAxisLabelBarChartCollections"
    [xAxisLabel]="charts.xAxisLabelBarChartCollections"
    [yAxisLabel]="charts.yAxisLabelBarChartCollections"
    (select)="charts.onSelectBarChartCollections($event)"
    [legendPosition]="charts.legendPositionBarChartCollections"
    [yAxisTickFormatting]="charts.formatYAxisTicks"
    >
  </ngx-charts-bar-vertical>
</div>
