
<div [ngClass]="theme.getThemeClass()" class="chart-container">
  <ngx-charts-pie-chart
    [view]="charts.viewPieChartSlice"
    [scheme]="charts.colorSchemePieChartSlice"
    [results]="charts.singlePieChartSlice"
    [gradient]="charts.gradientPieChartSlice"
    [legend]="charts.showLegendPieChartSlice"
    [legendTitle]="charts.legendTitlePieChartSlice"
    [legendPosition]="charts.legendPositionPieChartSlice"
    [labels]="charts.showLabelsPieChartSlice"
    [doughnut]="charts.isDoughnutPieChartSlice"
    (select)="charts.onSelectPieChartSlice($event)"
    (activate)="charts.onActivatePieChartSlice($event)"
    (deactivate)="charts.onDeactivatePieChartSlice($event)"
    >
  </ngx-charts-pie-chart>
</div>

