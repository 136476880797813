<app-system-message></app-system-message>

<div [ngClass]="theme.getThemeClass()" class="container-fluid" (window:scroll)="onScroll()">

  <!-- Search input component, shown when mainContent is 'clients' -->
  <header id="search-component" class="input-group" *ngIf="mainContent === 'clients'">
    <input #searchBox type="search"  id="search-box" [(ngModel)]="userFilter" (input)="search()" class="form-control rounded"
      placeholder="{{ 'userlistSearchInputPlaceHolder' | translate }}" aria-label="Search" aria-describedby="search-addon" />
  </header>

  <!-- Table header for the bin-list, shown when mainContent is 'bin-list' -->
  <header  class="table-header" *ngIf="mainContent === 'clients'">
    <span class="left">{{ "distributorsList" | translate }}</span>
    <span class="right">
      <button [ngClass]="theme.getThemeClass()" class="btn btn-dropdown" id="buttonDropdown" routerLink="/distributors-create">
        {{ "newDistributor" | translate }}
      </button>
    </span>
  </header>

  <!-- Table container for the user list, shown when mainContent is 'distributors' -->
  <section [ngClass]="theme.getThemeClass()" class="table-container width" *ngIf="mainContent === 'clients'">
    <article  class="table-responsive ">
      <table  class="table table-hover">
        <thead>
          <tr>
            <th [ngClass]="theme.getThemeClass()" scope="col">
              {{ 'name' | translate }}
            </th>
            <th [ngClass]="theme.getThemeClass()" class="hide-small-screen" scope="col">
              {{ 'legalName' | translate }}
            </th>
            <th [ngClass]="theme.getThemeClass()" scope="col">
                {{ 'email' | translate }}
              </th>
            <th [ngClass]="theme.getThemeClass()" class="hide-small-screen" scope="col">
              {{ 'phoneNumber' | translate }}
            </th>
            <th [ngClass]="theme.getThemeClass()" class="hide-small-screen" scope="col">
              {{ 'address' | translate }}
            </th>
            <th [ngClass]="theme.getThemeClass()" scope="col">
              {{ 'iotTabHeaderResources' | translate }}
          </th>
          </tr>
        </thead>
        <ng-container  *ngFor="let distributor of this.distributorsArray">
          <tbody>
            <tr class="align">
              <td [ngClass]="theme.getThemeClass()">
                <a [routerLink]="['/distributors-edit', distributor.distributor_id]" href="javascript:void(0)">
                  {{distributor.distributor_name}}
                </a>
              </td>
              <td [ngClass]="theme.getThemeClass()" class="hide-small-screen">
                {{distributor.legal_name}}
              </td>
              <td [ngClass]="theme.getThemeClass()">
                {{distributor.email}}
              </td>
              <td [ngClass]="theme.getThemeClass()" class="hide-small-screen">
                {{this.formatarNumero(distributor.phone_number)}}
              </td>
              <td [ngClass]="theme.getThemeClass()" class="hide-small-screen">
                {{distributor.address}}
              </td>
              <td [ngClass]="theme.getThemeClass()">
                <a [routerLink]="['/distributors-edit', distributor.distributor_id]"><img class="iconUpdate" src="./assets/update-pencil.png" alt="icon of update" height="30" width="30"></a>
            </td>
            </tr>
          </tbody>
        </ng-container>
      </table>
    </article>
  </section>
</div>







