<app-system-message></app-system-message>
<div class="container-fluid" *ngIf="this.workOrderModalService.applyingChanges">
  <!-- Loading Element -->
  <nav [ngClass]="theme.getThemeClass()" *ngIf="this.workOrderModalService.applyingChanges" class="loading-gif">
  <!-- Container with box styles and fade-in-out animation -->
    <div class="box fade-in-out" >
      <!-- Font Awesome spinner icon with custom styles -->
      <div class="fa-3x mb-2">
          <i class="fa-solid fa-spinner fa-spin-pulse fa-2xl color"></i>
      </div>
      <!-- Logo container with dynamic theme class and navigation link -->
      <div>
          <a [ngClass]="theme.getThemeClass()"
          class=" hide-too-small-screen navbar-brand px-1" href="javascript:void(0);" data-bs-toggle="dropdown">
              <!-- Logo image with custom styles -->
              <img class="logo" src="../assets/muirwood-studio.png">
          </a>
      </div>
  </div>
  </nav>
</div>
<div [ngClass]="theme.getThemeClass()" *ngIf="!this.workOrderModalService.applyingChanges">
  <!-- Section for header and filter -->
  <header class="page-header">
    <!-- For future development -->
    <h2>{{"workOrderList" | translate}}</h2>
  </header>
    <nav
    id="search-component"
    class="nav-sort-filter"
  >
    <input
      #searchBox
      type="search"
      id="search-box"
      [(ngModel)]="userFilter"
      (input)="
        this.search()
      "
      class="form-control rounded searchInput"
      placeholder="{{ 'userlistSearchInputPlaceHolder' | translate }}"
      aria-label="Search"
      aria-describedby="search-addon"
    />
    <ng-select class="form-control ng-select sort-by-select" [(ngModel)]="this.filterBy"
      placeholder="{{ 'filterBy' | translate }}" [ngClass]="{'has-value': this.filterBy }">
      <ng-option value="" disabled selected>{{ "filterBy" | translate }}</ng-option>
          <ng-option value="work_order_id">{{ "workOrderId" | translate }}</ng-option>
          <ng-option value="legal_name">{{ "clientcreateFormInputClientName" | translate }}</ng-option>
          <ng-option value="bin_id">{{ "bin" | translate }}</ng-option>
          <ng-option value="thing_name">{{ "iotModalViewDeviceName" | translate }}</ng-option>

    </ng-select>
    <ng-select class="form-control ng-select sort-by-select" [(ngModel)]="this.sortBy"
      (change)="onSortChange('select', $event)" placeholder="{{ 'sortBy' | translate }}" [ngClass]="{'has-value': this.sortBy}">
      <ng-option value="" disabled selected>{{ "sortBy" | translate }}</ng-option>
          <ng-option value="work_order_id">{{ "workOrderId" | translate }}</ng-option>
          <ng-option value="work_order_type">{{ "workOrderTypeSort" | translate }}</ng-option>
          <ng-option value="legal_name">{{ "clientcreateFormInputLegalName" | translate }}</ng-option>
    </ng-select>
    <div class="card-title mt-0 status">
      <label class="switch">
          <input type="checkbox" [ngModel]="this.ascDesc" (change)="onSortChange('switch', $event)">
          <span class="slider round">
            <span class="toggle-switch-label-off">{{ "asc" | translate }}</span>
            <span class="toggle-switch-label-on">{{ "desc" | translate }}</span>
          </span>
      </label>
  </div>
  </nav>
  <section class="createBtns d-flex justify-content-center mb-2">
    <button [ngClass]="theme.getThemeClass()" class="btn btn-dropdown"  routerLink="/work-order-create">
      {{  "createWorkOrder" | translate }}
    </button>
  </section>
  <div class="grid-container">
    <section [ngClass]="theme.getThemeClass()" class="gridster" [ngStyle]="getColumnStyle()" style="height: 100vh!important;">
      <ng-container *ngFor="let item of this.workOrderModalService.workOrderArray">
      <article class="gridster-item">
        <!-- Your tile content goes here -->
          <nav [ngClass]="theme.getThemeClass()" class="dashboard-tile">
            <header class="dashboard-tile-header">
              <h6 class="dashboard-tile-title">{{ item.work_order_id }}</h6>
            </header>
            <div class="tiles-container" *ngIf="!this.workOrderModalService.applyingChanges">
              <div class="work-order-owner-infos">
                <div>
                  <i class="fa-solid fa-circle-question"></i><label class="item-label">{{ item.work_order_type | translate }}</label>
                </div>
                <div>
                  <i class="fa-solid fa-user"></i><label class="item-label">{{ item.legal_name }}</label>
                </div>
                <div>
                  <i class="fa fa-trash"></i><label class="item-label">{{ item.bin_id }}</label>
                </div>
                <div *ngIf="item.thing_name">
                  <i class="fa-solid fa-microchip device-icon"></i><label class="item-label">{{ item.thing_name }}</label>
                </div>
              </div>
              <hr>
              <div class="work-order-infos">
                <div>
                  <a (click)="changeWorkOrderStatus(item.work_order_id, item.status)"><i class="fa-solid fa-hourglass-start"></i><label class="item-label label-clickable">{{ item.status | translate }}</label></a>
                </div>
                <div>
                  <a [ngClass]="theme.getThemeClass()" (click)="changeWorkOrderDeadlineDate(item.work_order_id, item.work_order_deadline)"><i class="fa-regular fa-calendar"></i><label class="item-label label-clickable">{{ formatDate(item.work_order_deadline) }}</label></a>
                </div>
                <div class="work-order-description">
                  <a [ngClass]="theme.getThemeClass()" (click)="changeWorkOrderDescription(item.work_order_id, item.description)" class="comments-div"><i class="fa-solid fa-circle-question"></i><label class="item-label label-clickable">{{ item.description | translate }}</label></a>
                </div>
                <hr>
                <label>{{ 'comments' | translate }}</label>
                <div class="work-order-comments">
                  <a (click)="changeWorkOrderComments(item.work_order_id, item.comment)"><i class="fa-solid fa-comment"></i><label class="item-label label-clickable">{{ item.comment | translate }}</label></a>
                </div>
              </div>
            </div>
          </nav>
        </article>
    </ng-container>
  </section>
  </div>

  <app-work-order-dashboard-modal *ngIf="this.workOrderModalService.showModal" class="modal-container" [ngClass]="theme.getThemeClass()"></app-work-order-dashboard-modal>
</div>
<div style="height: 400px !important; width: 10px !important;"></div>
