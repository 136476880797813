<!-- main container -->
<div class="container" [ngClass]="theme.getThemeClass()">
       <section class="route-selction" *ngIf="!DriverService.isRouteSelected" >
         <!-- search bar container -->
         <div class="search-bar">
            <div>
                <!-- icon -->
                <span class="search"><i class="fa-solid fa-magnifying-glass"></i></span>
                <!-- input text search -->
                <input type="text" name="routes-search" placeholder="Search by address" >
            </div>
             <!-- display driver's routes num -->
            <span class="routes-number">Routes({{routeNum}})</span>
        </div>
        <!-- route list container -->
        <div class="routes-list-container">
            <!-- routes list -->
            <ul class="routes-list">
                <ng-container *ngFor="let route of routes">
                    <!-- individual route -->
                     <a [routerLink]="route.routeUrl"  (click)="selectRoute(route.routeID)" class="route-link">
                    <li  class="route-item">
                        <!-- route name -->
                        <div class="route-name">
                           <span>{{route.routeID}} - {{route.name}}</span>
                        </div>
                        <!-- route info -->
                        <div class="route-info">
                            &nbsp;
                            <span>{{route.date}}</span>
                            &nbsp;&nbsp;
                            <span>{{(route.distance * 1.60934).toFixed(1)}}Kms</span>
                        </div>
                        <i class="fa-solid fa-chevron-right right"></i>
                    </li>
                </a>
                </ng-container>
            </ul>
        </div>
       </section>
       <section class="selected-route" *ngIf="DriverService.isRouteSelected">
        
       </section>
</div>
