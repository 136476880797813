import { Component, Inject, forwardRef } from '@angular/core';
import { RelationshipModalService } from '../service/relationship-modal.service';
import { LocalStorageService } from '../local-storage.service';
import { LocalizationService } from '../service/localization.service';
import { SystemMessageService } from '../service/system-message.service';
import { NavigationService } from '../service/navigation.service';
import { ThemeService } from '../service/theme.service';
import { Router } from '@angular/router';
import { ClientService } from '../service/client.service';
import { CognitoService } from '../service/cognito.service';
import { TranslateService } from '@ngx-translate/core';
import { DistributorsService } from '../service/distributors.service';


@Component({
  selector: 'app-relationship-modal',
  templateUrl: './relationship-modal.component.html',
  styleUrl: './relationship-modal.component.css'
})
export class RelationshipModalComponent{

  // Variable to store the language selected value
  public selectedLanguage: string = "";

  // Retrieve current language selected from local storage
  languageStatus:string = this.localStorageService.getItem('language');

  constructor(

    private localStorageService: LocalStorageService,
    @Inject(forwardRef(() => TranslateService)) @Inject(forwardRef(() => TranslateService)) private translate: TranslateService,
    public cognitoService: CognitoService,
    public clientService: ClientService,
    private router: Router,
    public theme: ThemeService,
    public navigationService: NavigationService,
    public systemMessage: SystemMessageService,
    public localizationService: LocalizationService,
    public relationshipModalService: RelationshipModalService,
    public distributorService: DistributorsService) {

  }

  // Reset values of quantities in the relationship modal service
  resetValues() {
    this.relationshipModalService.binQuantity = 0; // Reset bin quantity
    this.relationshipModalService.devicesQuantity = 0; // Reset devices quantity
    this.relationshipModalService.userQuantity = 0; // Reset user quantity
  }

  // Close the modal and reset values
  closeModal() {
    this.resetValues(); // Reset values before closing modal
    this.relationshipModalService.showModal = false; // Hide modal
  }

  // Asynchronously delete client and associated data
  async deleteClient() {

    // Subscribe to delete client service call
    this.clientService.onDeleteClient().subscribe(success => {});

    // Iterate through each user to disable their access
    for(let i = 0; this.clientService.userNameArray.length > 0; i++){

      // Disable user access via Cognito service
      const disabledResult = await this.cognitoService.disableUserAccess(this.clientService.userNameArray[i]);
    }

    // Store client-related content in local storage
    this.localStorageService.addItem('entitiesContent', 'deleteClientSuccess')
    // Redirect to entities page after deletion
    this.router.navigate(['/entities']); // Redirect to the '/client-list' route after the delay
  }

  // Asynchronously delete client and associated data
  async deleteDistributor() {

    // Subscribe to delete client service call
    this.distributorService.onDeleteDistributor().subscribe(success => {});

    // Iterate through each user to disable their access
    for(let i = 0; this.clientService.userNameArray.length > 0; i++){

      // Disable user access via Cognito service
      const disabledResult = await this.cognitoService.disableUserAccess(this.clientService.userNameArray[i]);
    }

    // Store client-related content in local storage
    this.localStorageService.addItem('entitiesContent', 'deleteDistributorSuccess')
    // Redirect to entities page after deletion
    this.router.navigate(['/entities']); // Redirect to the '/client-list' route after the delay
  }
}
