import { Component, HostListener, OnInit } from '@angular/core';
import { ThemeService } from '../service/theme.service';
import { OperatorService } from '../service/operator.service';
import { ValidationService } from '../service/validation.service';
import { Router } from '@angular/router';
import { LocalStorageService } from '../local-storage.service';
import { formatDate } from '@angular/common';
import { CognitoService } from '../service/cognito.service';
import { SystemMessageService } from '../service/system-message.service';
import { LocalizationService } from '../service/localization.service';
import { environment } from '../environments/environment';

@Component({
  selector: 'app-operator-create',
  templateUrl: './operator-create.component.html',
  styleUrls: ['./operator-create.component.css']
})
export class OperatorCreateComponent implements OnInit {

  // Variable used in the submit for validation conrfim
  public validateCreate: boolean = false;

  // Variables used in UI HTML to show/hide messages
  private systemMessage: string = '';

  constructor(public theme: ThemeService,
              public operator: OperatorService,
              public validationService: ValidationService,
              private router: Router,
              private localStorageService: LocalStorageService,
              private cognitoService: CognitoService,
              public systemMessageService: SystemMessageService,
              private localizationService: LocalizationService) {

    this.cognitoService.confirmValidUser();
  }

  async ngOnInit(): Promise<void> {

    await this.cognitoService.getCurrentRole(
      [environment.users.role.administrator],
      [environment.users.superAdmin]
    ); //Role Check

    // get operator list to check later if has some duplicated data when creates operator
    await this.operator.getOperators();

    this.systemMessageService.buttonBlocked = false;
    // Call the function that init the operator data
    this.operator.initOperatorData();
    this.localizationService.initAutocomplete();
  }
   // Decorator @HostListener listens to the 'input' event on the specified target element
   @HostListener('input', ['$event.target']) onInput(input: HTMLInputElement): void {
    // Check if the input element has the class 'telefone-input'
    if (input.classList.contains('telefone-input')) {
      // Remove all non-numeric characters from the input value
      const value = input.value.replace(/\D/g, '');

      // Check if the value has a length of 10 characters or less
      if (value.length <= 10) {
        // Format the phone number as (000)-000-0000
        input.value = this.formatPhoneNumber(value);
      } else {
        // If the value is longer than 10 digits, limit the input to 10 digits
        input.value = input.value.slice(0, 13);
      }
    }
  }

  disableButton() {
    this.systemMessageService.buttonBlocked = true;
  }

  async onSubmit() {

    // Create a variable whit the current date
    const currentDate = new Date();
    const formattedDate = formatDate(currentDate, 'yyyyMMdd', 'en_US');

    // Set created and modified date to current date, modified is required event if it's not so the query won't break
    this.operator.operator_data.created = formattedDate;
    this.operator.operator_data.modified = formattedDate;

    this.operator.operator_data.address = this.localizationService.autoCompletionReturnAddress;
    

    // Call the fonction in addition of validation service to validate operator_data
    this.checkValidation();

    if(this.validateCreate){
      // If the validation id good will call the create function
      await this.operator.createOperator();
      // Then call the function that will return in entitie page
      this.returnToEntities();
    }else{
      // If system message have nothing it put a generic message
      if(this.systemMessage === ''){
       /*  this.systemMessageService.buttonBlocked = true;
        this.systemMessage = 'alert-danger-generic-message'; */
        switch(this.validationService.validationErrorArray[0]){
          case 'usernameInvalid':
            this.systemMessage = 'nameInvalid'
            break
          case 'clientLegalNameInvalid':
            this.systemMessage = 'legalNameInvalid'
            break
          case 'clientPhoneInvalid':
            this.systemMessage = 'phoneInvalid'
            break
          case 'clientAddressInvalid':
            this.systemMessage ='addressInvalid'
            break
          case 'clientEmailInvalid':
            this.systemMessage = 'emailInvalid'
            break
        }
      }
      // Set the system message service to display the error to the user
      this.systemMessageService.buttonBlocked = true;

      this.systemMessageService.selectRibbon('danger', this.systemMessage);
    }
    this.systemMessageService.buttonBlocked = false;
  }

  checkOperatorNameInArray(operatorName: string, operatorsArray: { operator_id: string, operator_name: string, legal_name: string, phone_number: string, address: string }[]): boolean {
    return operatorsArray.some(op => op.operator_name === operatorName);
  }

  // Function call in addition of validation service to validate input of the
  checkValidation() {

    // Check if the operator name is duplicated
    const operatorNameDuplicated = this.checkOperatorNameInArray(this.operator.operator_data.operator_name, this.operator.operator_array )

    // If the operator name is already present
    if(operatorNameDuplicated) {
      this.systemMessageService.buttonBlocked = true;
      this.systemMessage = 'duplicatedOperatorName';
    }


    // Call the function to check if there is an empty input
    this.operator.hasOwnProperty('create');

    if(this.operator.emptyField){
      this.systemMessageService.buttonBlocked = true;

      this.systemMessage = 'fieldEmptyOrIncorrect';
    }

    // Check if all function that we passed before not returning a true value then will set the validation variable to true to be able to create a new taxe type
    if(!operatorNameDuplicated && !this.operator.emptyField && (this.validationService.validationErrorArray[0] === '' || this.validationService.validationErrorArray[0] === undefined)){
      // Set validation variable to true and remove the empty input message
      this.validateCreate = true;
    }
  }

  // Implemented by cancel button to return to the admin component
  returnToEntities(){
    // Put a local store variable so entitie component could know to return on operator-list
    this.localStorageService.addItem('entitiesContent', 'operator-list');
    sessionStorage.setItem("from", "operator-create"); // This variable is used for operator-list to show the proper success message on create or update
    this.router.navigate(['/entities']); // Return to entitie component
  }

  // Function that avoid submitting the page when user press enter at the end of inputting address in address input
  onInputAddressKeydown(event: KeyboardEvent): void {
    if(event.key === 'Enter'){
      event.preventDefault(); // Don't submit the page
    }
  }
   // Function to format a numeric string as a phone number in the format (000)-000-0000
  formatPhoneNumber(value: string): string {
    // Check if the input value is empty and return an empty string if it is
    if (value.length === 0) return '';

    // Extract the area code, first part, and second part of the phone number
    const areaCode = value.slice(0, 3);
    const firstPart = value.slice(3, 6);
    const secondPart = value.slice(6, 10);

    // Format the phone number as (000) 000-0000 and return the formatted string
    return `(${areaCode}) ${firstPart}-${secondPart}`;
  }
}
