<!-- Section for the relationship modal -->
<section [ngClass]="theme.getThemeClass()" class="modal col-10">
    <h4 class="truck-modal-title">
      {{ "driverAssociation" | translate }}
    </h4>
    <article class="truck-modal-article col-12">
      <div class="col-10 truck-modal">
        <h5 class="truck-modal-sub-title">
          {{ "selectedTruck" | translate}}
        </h5>
        <!-- Section for the truck name -->
        <label class="truck-modal-label">
          {{ this.trucks.truck.nickname }}
        </label>
        <!-- Section for driver association -->
        <h5 class="truck-modal-sub-title">
          {{ "associatedDriver" | translate}}
        </h5>
        <div class="truck-modal-select-div">
          <ng-select class="form-control ng-select"
            [(ngModel)]="selectedDriver"
            [ngClass]="{'has-value': selectedDriver }"
            >
            @for (driver of drivers; track $index) {
              <ng-option [value]="driver.user">{{ driver.username }}</ng-option>
            }
          </ng-select>
        </div>
      </div>
    </article>
    <nav class="col-6">
      <button [ngClass]="theme.getThemeClass()" class="change-association col-6" (click)="saveDriverAssociation()">{{ "save" | translate }}</button>
      <button [ngClass]="theme.getThemeClass()" class="change-association col-6" (click)="closeModal()">{{ "cancel" | translate }}</button>
    </nav>
  </section>