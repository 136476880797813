import { Injectable } from '@angular/core';

Injectable({
  providedIn: 'root'
})

// Constant used in select of bin shapes in bin create and bin update pages
export const BinShapes =[
  {"value": "C", "Label" : "binModelUpdateOptionCylinder"},
  {"value": "S", "Label" : "binModelUpdateOptionSquare"},
];
