import { Component, OnInit, ElementRef, HostListener, Inject, forwardRef } from '@angular/core';
import { RoleService } from '../service/role.service';
import { CognitoService } from '../service/cognito.service';
import { TranslateService } from '@ngx-translate/core';
import { LocalStorageService } from '../local-storage.service';
import { ClientService } from '../service/client.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ValidationService } from '../service/validation.service';
import { Router } from '@angular/router';
import { take, timer } from 'rxjs';
import { ThemeService } from '../service/theme.service';
import { DistributorsService } from '../service/distributors.service';
import { NavigationService } from '../service/navigation.service';
import { SystemMessageService } from '../service/system-message.service';
import { environment } from '../environments/environment';


@Component({
  selector: 'app-distributors-create',
  templateUrl: './distributors-create.component.html',
  styleUrls: ['./distributors-create.component.css']
})
export class DistributorsCreateComponent implements OnInit{

  public form: FormGroup

  // Variable to store the language selected value
  public selectedLanguage: string = "";

  public successMessage: boolean = false;

  public autocomplete: any = google.maps.places; // Google Places Autocomplete

  // Retrieve current language selected from local storage
  languageStatus:string = this.localStorageService.getItem('language');

  // Variable to store the result of a place selection from Google Places Autocomplete.
  // It can hold a valid PlaceResult object or be undefined if no place is selected.
  selectedPlace: google.maps.places.PlaceResult | undefined;

  constructor(
    private localStorageService: LocalStorageService,
    @Inject(forwardRef(() => TranslateService)) private translate: TranslateService,
    private cognitoService: CognitoService,
    public roleService: RoleService,
    public clientService: ClientService,
    private formBuilder: FormBuilder,
    public validationService: ValidationService,
    private router: Router,
    public theme: ThemeService,
    public distributorsService: DistributorsService,
    public navigationService: NavigationService,
    public systemMessage: SystemMessageService

  ) {

    // Create a form using the FormBuilder
    this.form = this.formBuilder.group({
      // Define form controls and their initial values
      distributor_name: ['', Validators.required],
      legal_name: [''],
      email: ['', [Validators.required, Validators.email]],
      phone_number: [''],
      address: ['']
    });

    this.cognitoService.confirmValidUser();
    this.roleService.getRoles();

    // Check if the user has selected a language in local storage
    //or use a default language
    if (this.languageStatus == null){
      // Set the default language to French
      translate.use('fr');
    } else {
      // Set the default language to the user's selected language
      translate.use(this.languageStatus);
    }
  }

  async ngOnInit(): Promise<void> {
    await this.cognitoService.getCurrentRole(
      [environment.users.role.administrator],
      [environment.users.superAdmin]
    ); //Role Check
    this.systemMessage.buttonBlocked = false;
    this.clearPastClientData();
    this.initAutocomplete();
  }

  // Funciton called to clear all clietn data before creating new one
  clearPastClientData() {
    this.distributorsService.distributorData.distributor_name = "";
    this.distributorsService.distributorData.distributor_id = "";
    this.distributorsService.distributorData.address = "";
    this.distributorsService.distributorData.created = "";
    this.distributorsService.distributorData.email = "";
    this.distributorsService.distributorData.legal_name = "";
    this.distributorsService.distributorData.phone_number = "";
    this.distributorsService.distributorData.modified = "";
  }

  // Decorator @HostListener listens to the 'input' event on the specified target element
  @HostListener('input', ['$event.target']) onInput(input: HTMLInputElement): void {
    // Check if the input element has the class 'telefone-input'
    if (input.classList.contains('telefone-input')) {
      // Remove all non-numeric characters from the input value
      const value = input.value.replace(/\D/g, '');

      // Check if the value has a length of 10 characters or less
      if (value.length <= 10) {
        // Format the phone number as (000) 000-0000
        input.value = this.formatPhoneNumber(value);
      } else {
        // If the value is longer than 10 digits, limit the input to 10 digits
        input.value = input.value.slice(0, 10);
      }
    }
  }

  // runs a validation test checking every inputs
  checkValidationAllInputs() {

    // Check if clientName is not empty, its length is greater than 16,
    // or it doesn't match the alphanumeric pattern
    this.validationService.validateClientName(this.distributorsService.distributorData.distributor_name);

    // Check if clientLegalName is not empty, its length is greater than 16,
    // or it doesn't match the alphanumeric pattern
    this.validationService.validateClientLegalName(this.distributorsService.distributorData.legal_name);

    // Check if the clientEmail is not a valid email or is empty
    this.validationService.validateClientEmail(this.distributorsService.distributorData.email);

    // Check if phoneNumber is not empty and its length is less than 10 or if it's empty
    this.validationService.validatePhoneNumber(this.distributorsService.distributorData.phone_number);

    // Check if the address is empty
    this.validationService.validateAddress(this.distributorsService.distributorData.address);

    // Define a mapping of error codes to corresponding error messages
    const errorMappings: Record<string, string> = {
      'usernameInvalid': 'invalidNameError',
      'clientLegalNameInvalid': 'invalidLegalNameError',
      'clientEmailInvalid': 'invalidEmail',
      'clientPhoneInvalid': 'invalidInput',
      'clientAddressInvalid': 'invalidAddress',
    };

    // Retrieve the first validation error from the array
    const validationError = this.validationService.validationErrorArray[0];

    // Check if the validation error code exists in the mapping
    if (errorMappings[validationError]) {
      this.systemMessage.buttonBlocked = true;

      // If so, display a danger ribbon message with the corresponding key
      this.systemMessage.selectRibbon('danger', errorMappings[validationError]);
    }
  }

  disableButton() {
    this.systemMessage.buttonBlocked = true;
  }

  // Function triggered when the "Ok" button is clicked
  createBtn() {
    this.checkValidationAllInputs();

    // Create a new Date object to get the current date and time
    const date = new Date();

    // Format the date using the validationService
    this.validationService.formatDate(date);

    // Remove non-numeric characters from the phone number using the validationService
    this.validationService.keepOnlyNumbers(this.distributorsService.distributorData.phone_number);

    // Update the client's phone number and creation date with the formatted values
    this.distributorsService.distributorData.phone_number = this.validationService.phoneNumberFixed;
    this.distributorsService.distributorData.created = this.validationService.dateFixed;

    // Check if any of the validation flags are false, indicating validation errors
    if (!this.validationService.clientNameValid || !this.validationService.clientLegalNameValid ||
        !this.validationService.clientEmailValid || !this.validationService.clientPhoneNumberValid ||
        !this.validationService.clientAddressValid) {
          console.error('validation error')
    } else {
      // If all validations pass, log a message and call the createClient function
      this.distributorsService.createDistributors().subscribe((result) => {

      // A flag to check if the result is valid
      let resultaValid = true;

      if(resultaValid){
        // Call the function to show and hide the <div> for 5 seconds
        this.active3SecondTrigger((newValue) => {
          this.successMessage = newValue; // Set the success message

          const delay = 5500; // Define a delay of 5500 milliseconds (5.5 seconds)
          const timer$ = timer(delay); // Create a timer observable with the specified delay

          timer$.pipe(take(1)).subscribe(() => {
            this.localStorageService.addItem('entitiesContent', 'distributors')
            this.router.navigate(['/entities']); // Redirect to the '/client-list' route after the delay
            this.successMessage = newValue;
            this.validationService.distributorCreateSuccessMessage = newValue// Set the success message

          });
        });
      }
      else{
        // Call the function to show and hide the <div> for 5 seconds
        this.active3SecondTrigger((newValue) => {
        // this.successMessage = newValue; // The element will be set whit the function
        });
      }
    });
    }
    this.systemMessage.buttonBlocked = false;
  }

  // Function called ot set a 5 second timer
  active3SecondTrigger(callback: (prop: boolean) => void){
    // Put the variable to true to show the <div>
    callback(true);
    // Set a time out of 5 second then put the variable at false to hide the <div>
    setTimeout(() => {
      callback(false);
    }, 5000);
  }

  // function to reload page when cancel button is clicked
  pageReload() {
    this.localStorageService.addItem('entitiesContent', 'distributors')
    this.router.navigate(['/entities']); // Redirect to the '/client-list' route after the delay
  }

  // Function to format a numeric string as a phone number in the format (000) 000-0000
  formatPhoneNumber(value: string): string {
    // Check if the input value is empty and return an empty string if it is
    if (value.length === 0) return '';

    // Extract the area code, first part, and second part of the phone number
    const areaCode = value.slice(0, 3);
    const firstPart = value.slice(3, 6);
    const secondPart = value.slice(6, 10);

    // Format the phone number as (000) 000-0000 and return the formatted string
    return `(${areaCode}) ${firstPart}-${secondPart}`;
  }

  // Function to initialize the Google Maps Places Autocomplete
  initAutocomplete() {
    // Ensure that the Google Maps API script is loaded before calling this function
    if (typeof google === 'undefined' || typeof google.maps === 'undefined') {
      throw new Error('Google Maps API is not loaded.');
    }

    // Get the input element with the ID 'autocomplete'
    const inputElement = document.getElementById('autocomplete') as HTMLInputElement;

    // Check if the input element was found
    if (!inputElement) {
      throw new Error('"autocomplete" input element not found.');
    }

    // Set options for the Autocomplete widget
    const autocompleteOptions: google.maps.places.AutocompleteOptions = {
      // Specify the types of places to search for (geocode in this case)
      types: ['geocode'],
      // Restrict results to Canada
      componentRestrictions: { country: 'ca' },
      // Define the fields to be retrieved from place details
      fields: ['place_id', 'geometry', 'name', 'formatted_address'],
    };

    // Create a new instance of the Autocomplete widget and associate it with the input element
    this.autocomplete = new google.maps.places.Autocomplete(inputElement, autocompleteOptions);

    // Add a listener to handle the selection of a place in the Autocomplete results
    this.autocomplete.addListener('place_changed', () => {

      // Variable to store the result of a place selection from Google Places Autocomplete.
      // It can hold a valid PlaceResult object or be undefined if no place is selected.
      const selectedPlace = this.autocomplete.getPlace();

      if (selectedPlace && selectedPlace.geometry) {
        let formattedAddress = selectedPlace.formatted_address;

        // Check if the 'formatted_address' property is available
        if (!formattedAddress && selectedPlace.name) {
          formattedAddress = selectedPlace.name;
        }

        // Check if the 'name' property is available
        if (!formattedAddress && selectedPlace.address_components) {
          // Build the address from address components
          formattedAddress = selectedPlace.address_components.map((component: { long_name: any; }) => component.long_name).join(', ');
        }

        if (formattedAddress) {
          this.distributorsService.distributorData.address = formattedAddress;
        }
      }
    });
  }

 // Handler to handle the selection of a place
  onPlaceChanged() {
    const selectedPlace = this.autocomplete.getPlace();

    if (selectedPlace && selectedPlace.geometry) {
      // Associate the selected address with the variable 'xaxa'
      const formattedAddress = selectedPlace.formatted_address;

    }
  }

  // Function to change the language based on user selection
  changeLanguage(language: string) {
    // Use the 'translate' service to set the language to the specified 'language'
    this.translate.use(language);
  }
}
