import { Component, forwardRef, Inject, OnInit } from '@angular/core';
import { CognitoService } from '../service/cognito.service';
import { LocalStorageService } from '../local-storage.service';
import { TranslateService } from '@ngx-translate/core';
import { ThemeService } from '../service/theme.service';
import { SystemMessageService } from '../service/system-message.service';
import { ErrorModalService } from '../service/error-modal.service';
import { navigation } from '../constants/navigation';
import { environment } from '../environments/environment';
import { DashboardService } from '../service/dashboard.service';
import { DriverService } from '../service/driver.service';
@Component({
  selector: 'driver-dashboard',
  templateUrl: './driver-dashboard.component.html',
  styleUrls: ['./driver-dashboard.component.css','../../dashboard-tiles.css']
})


export class DriverDashboardComponent implements OnInit {
  //Grid option object
  gridsterOptions = {
    draggable: {
      enabled: false,
    },
    resizable: {
      enabled: false,
    },
    minCols: 1,
    maxCols: 3,
    minRows: 1,
    maxRows: 2,
    defaultItemCols: 1,
    defaultItemRows: 1,
  };

  public navigationItemsArray: any = [];//To save tile objects

  constructor(
    public cognitoService: CognitoService,
    private localStorageService: LocalStorageService,
    @Inject(forwardRef(() => TranslateService)) private translate: TranslateService,
    public theme: ThemeService,
    public systemMessage: SystemMessageService,
    public dashboard: DashboardService,
    private errorModal: ErrorModalService,
    public DriverService: DriverService){

  }
  ngOnInit(){
    this.navigationItemsArray = navigation.filter(item => item.userType === environment.users.maintenance && item.userRole === environment.users.role.driver);// recupereate navigation object for drivers
    // Initialize indexToRemove with -1
    let indexToRemove = -1;
    // Loop through the navigationItemsArray to find the index of the item to remove
    for (let i = 0; i < this.navigationItemsArray.length; i++) {
        // Check if the name of the current item is 'sidenavMenu.WORKORDER'
        if (this.navigationItemsArray[i].name === 'sidenavMenu.WORKORDER') {
          // If found, set indexToRemove to the current index and exit the loop
            indexToRemove = i;
            break;
        }
    }
    // Remove the item if the index is found
    if (indexToRemove !== -1) {
        this.navigationItemsArray.splice(indexToRemove, 1);
    }                    
  }
}
