import { Component, inject, Input, ViewEncapsulation } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ThemeService } from '../service/theme.service';
import { StepperSelectionEvent } from '@angular/cdk/stepper';


@Component({
  selector: 'app-truck-stepper',
  templateUrl: './truck-stepper.component.html',
  styleUrl: './truck-stepper.component.css',
})
export class TruckStepperComponent {

  private _formBuilder = inject(FormBuilder);

  firstFormGroup = this._formBuilder.group({
    firstCtrl: ['', Validators.required],
  });
  secondFormGroup = this._formBuilder.group({
    secondCtrl: ['', Validators.required],
  });

  @Input() selectedTruckInfo: any;

  // Assuming a variable to indicate the truck's current stop
  activeStepIndex = 0;

  constructor(
    public theme: ThemeService,
  ) {
  }

  ngOnChanges() {
    // Update active index based on truck progress
    this.updateActiveStep();
  }

  updateActiveStep() {
    // Logic to determine which step is active, for example:
    this.activeStepIndex = this.calculateActiveStep();
  }

  calculateActiveStep(): number {
    // get information from current route step
    return 1; // get from route status
  }

  // Function to check if the step is completed
  isStepCompleted(stepIndex: number): boolean {
    return stepIndex < this.activeStepIndex;  // Mark as complete if the index is less than the active step
  }
  
  // Update the active index as needed
  setActiveStepIndex(index: number): void {
    this.activeStepIndex = index;
  }

  onStepChange(event: StepperSelectionEvent): void {
    this.activeStepIndex = event.selectedIndex;
  }

  getBinAddress(route: any): string {
    // If route is an array, returns the bin_address of the first object
    return Array.isArray(route) && route.length > 0 ? route[0].bin_address : route.bin_address;
  }
  
  getTotalApproximateTime(route: any): number {
    if (Array.isArray(route) && route.length > 0) {
      return route.reduce((total, current) => total + Number(current.approximate_time), 0);
    }
    return Number(route.approximate_time);
  }
  
  convertSecondsToMinutes(seconds: number): string {
    // Convert seconds to minutes and format to two decimal places
    return (seconds / 60).toFixed(0);
  }

}
