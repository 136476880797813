<div  [ngClass]="theme.getThemeClass()" style="width: fit-content;">
    <ngx-charts-line-chart
      [view]="charts.viewLineChartTechnical"
      [scheme]="charts.colorSchemeLineChartTechnical"
      [legend]="false"
      [legendPosition]="charts.legendPositionLineChartAdmin"
      [showXAxisLabel]="charts.showXAxisLabelLineChartAdmin"
      [showYAxisLabel]="charts.showYAxisLabelLineChartAdmin"
      [xAxis]="charts.showXAxisLineChartAdmin"
      [yAxis]="charts.showYAxisLineChartAdmin"
      [xAxisLabel]="charts.xAxisLabelBatLineChartAdmin"
      [yAxisLabel]="charts.yAxisLabelTech | translate"
      [timeline]="true"
      [yAxisTicks]="charts.customYAxisTicks"
      [yScaleMin]="3"
      [yScaleMax]="4"
      [results]="charts.lineChartTechnical"
      (select)="charts.onSelectLineChart($event)"
      (activate)="charts.onActivateLineChart($event)"
      (deactivate)="charts.onDeactivateLineChart($event)"
      [xAxisTickFormatting]="charts.formatTimestamp"
      [xAxisTickFormatting]="charts.formatTimestamp"
      >
     </ngx-charts-line-chart>
     <div class="line-chart-container legend" >
        <h6 ><span class="legendColor green"></span>{{"battery"|translate}}</h6>
        <h6 ><span class="legendColor blue"></span>VCC</h6>
        <h6 *ngIf="charts.hasVB1"><span class="legendColor purple"></span>VB1</h6>
        </div>
   </div>
