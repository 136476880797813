<app-system-message></app-system-message>

<!-- Container for the form with dynamic styling -->
<div [ngClass]="theme.getThemeClass()" class="container-fluido">

    <section [ngClass]="theme.getThemeClass()" class="card bg-light">
        <article class="card-body mx-auto article">
            <h4 class="card-title mt-0 text-center">{{ 'updateTruck' | translate }}</h4>
            <form>
                <!-- TRUCK VIN INPUT -->
                <label class="label-spacer" for="truck_vin">{{ 'VIN' | translate }} <span class="requiredStar">*</span></label>
                <div class="input-group mb-3">
                    <span class="input-group-text" id="basic-addon1"><i class="fa-solid fa-fingerprint"></i></span>
                    <input [(ngModel)]="truckService.truck.vin" type="text" disabled="disabled"
                    class="form-control" name="truck_vin"
                    placeholder="VIN/NIV"
                    aria-label="Vehicle Identification Number" aria-describedby="basic-addon1"
                    [class.error-border]="this.validationService.validationErrorArray[0] == 'createTruckVinInputError'"> <!-- replace for the right string for truck -->
                </div>

                <!-- SELECT OPERATOR -->
                <label *ngIf="this.cognitoService.userType === 'muirwood'" class="label-spacer" for="truck_brand">{{ 'operator' | translate }} <span class="requiredStar">*</span></label>
                <div *ngIf="this.cognitoService.userType === 'muirwood'" class="input-group mb-3 firstInput">
                    <span class="input-group-text" id="basic-addon1"><i class="fa-solid fa-truck-front"></i></span>
                    <ng-select
                      [(ngModel)]="this.truckService.truck.operator_id"
                      [ngClass]="{'has-value': this.truckService.truck.operator_id }"
                      class="form-control ng-select"
                      placeholder="{{ 'selectOperator' | translate }}"
                      name="operator"
                      [appendTo]="'body'"
                      [hideSelected]="true"
                      [class.error-border]="this.validationService.validationErrorArray[0] == 'createTruckBrandInputError'"
                      > <!-- replace for the right string for truck -->
                        <ng-option *ngFor="let operator of operatorArray" [value]="operator.operator_id">{{ operator.legal_name }}</ng-option>
                    </ng-select>
                </div>
                
                <!-- Nickname Input -->
                <label class="label-spacer" for="truck_nickname">{{ 'truckNickname' | translate }} <span class="requiredStar">*</span></label>
                <div class="input-group mb-3">
                    <span class="input-group-text" id="basic-addon1"><i class="fa-solid fa-fingerprint"></i></span>
                    <input [(ngModel)]="truckService.truck.nickname" type="text"
                    class="form-control" name="truck_nickname"
                    aria-label="Truck nickname" aria-describedby="basic-addon1"
                    [class.error-border]="this.validationService.validationErrorArray[0] == 'createTruckNicknameInputError'">
                </div>

                <!-- TRUCK BRAND -->
                <label class="label-spacer" for="truck_brand">{{ 'truckBrand' | translate }} <span class="requiredStar">*</span></label>
                <div class="input-group mb-3 firstInput">
                    <span class="input-group-text" id="basic-addon1"><i class="fa-solid fa-truck-front"></i></span>
                    <ng-select
                    [(ngModel)]="truckService.truck.brand"
                    class="form-control"
                    name="truck_brand"
                    [appendTo]="'body'"
                    [hideSelected]="true"
                    [class.error-border]="this.validationService.validationErrorArray[0] == 'createTruckBrandInputError'"
                    > <!-- replace for the right string for truck -->
                    <ng-option *ngFor="let brand of TruckBrands" [value]="brand.key">{{ brand.value.replaceAll("_",' ') }}</ng-option>
                </ng-select>
                </div>

                <!-- TRUCK TYPE INPUT -->
                <label class="label-spacer" for="truck_type">{{ 'truckType' | translate }} <span class="requiredStar">*</span></label>
                <div class="input-group mb-3 firstInput">
                    <span class="input-group-text" id="basic-addon1"><i class="fa-solid fa-truck-ramp-box"></i></span>
                    <ng-select
                    [(ngModel)]="truckService.truck.type"
                    class="form-control"
                    name="truck_type"
                    [appendTo]="'body'"
                    [hideSelected]="true"
                    [class.error-border]="this.validationService.validationErrorArray[0] == 'createTruckTypeInputError'">
                    <ng-option *ngFor="let type of TruckTypes" [value]="type.key">{{ type.value | translate }}</ng-option>
                </ng-select>
                </div>

                <!-- TRUCK SIZE INPUT -->
                <label class="label-spacer" for="truck_size">{{ 'truckSize' | translate }} (m) <span class="requiredStar">*</span></label>
                <div class="input-group mb-3 firstInput">
                    <span class="input-group-text" id="basic-addon1"><i class="fa-solid fa-text-height"></i></span>
                    <input [(ngModel)]="truckService.truck.size" type="number"
                    class="form-control" name="truck_size"
                    placeholder="{{ 'truckSize' | translate }} "
                    aria-label="Truck Size" aria-describedby="basic-addon1"
                    [class.error-border]="this.validationService.validationErrorArray[0] == 'createTruckSizeInputError'">
                </div>

                <!-- TRUCK CAPACITY INPUT -->
                <label class="label-spacer" for="truck_capacity">{{ 'truckCapacity' | translate }} (L) <span class="requiredStar">*</span></label>
                <div class="input-group mb-3">
                    <span class="input-group-text" id="basic-addon1"><i class="fa-solid fa-weight-scale"></i></span>
                    <input [(ngModel)]="truckService.truck.capacity" type="number"
                    class="form-control" name="truck_capacity"
                    placeholder="{{ 'truckCapacity' | translate }}"
                    aria-label="Truck Capacity" aria-describedby="basic-addon1"
                    [class.error-border]="this.validationService.validationErrorArray[0] == 'createTruckCapacityInputError'">
                </div>

                <!-- TRUCK YEAR INPUT -->
                <label class="label-spacer" for="truck_year">{{ 'truckYear' | translate }} <span class="requiredStar">*</span></label>
                <div class="input-group mb-3">
                    <span class="input-group-text" id="basic-addon1"><i class="fa-solid fa-calendar-days"></i></span>
                    <input [(ngModel)]="truckService.truck.year"
                    type="number" class="form-control" name="truck_year"
                    min="1900"
                    (change)="checkYear($event)"
                    placeholder="{{ 'truckYear' | translate }}"
                    aria-label="Truck year" aria-describedby="basic-addon1"
                    [class.error-border]="this.validationService.validationErrorArray[0] == 'createTruckYearInputError'">
                </div>

                <!-- TRUCK STATUS INPUT -->
                <label class="label-spacer" for="truck_status">{{ 'truckStatus' | translate }} <span class="requiredStar">*</span></label>
                <div class="input-group mb-3">
                    <span class="input-group-text" id="basic-addon1"><i class="fa-solid fa-heart-pulse"></i></span>
                    <ng-select
                    [(ngModel)]="truckService.truck.status"
                    class="form-control"
                    name="truck_status"
                    [appendTo]="'body'"
                    [hideSelected]="true"
                    [class.error-border]="this.validationService.validationErrorArray[0] == 'createTruckStatusInputError'">
                    <ng-option *ngFor="let status of TruckStatus" [value]="status.key">{{ status.value | translate }}</ng-option>
                </ng-select>
                </div>
                <!-- Submit button -->
                <nav class="form-group displayFlex nav-btn">
                    <button [disabled]="this.systemMessageService.buttonBlocked" [ngClass]="theme.getThemeClass()" (click)="updateTruck(); disableButton()" type="submit" class="btn btn-primary btn-block btnCreate form-control">{{ 'usereditFormBtnSaveChanges' | translate }}</button>
                    <button (click)="toPreviousPage()" [ngClass]="theme.getThemeClass()" type="button" class="btn btn-primary btn-block btnCancel form-control">{{ 'clientcreateFormBtnCancel' | translate }}</button>                   
                </nav>
            </form>
        </article>
    </section>
</div>






