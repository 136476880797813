<app-system-message></app-system-message>

<!-- Search input component, shown when mainContent is 'bin-list' -->
<div [ngClass]="theme.getThemeClass()" class="min-width-div container-fluid">
  <nav
    id="search-component"
    class="filter-group"
    *ngIf="mainContent === 'bin-list'"
  >
    <input
      #searchBox
      type="search"
      id="search-box"
      [(ngModel)]="userFilter"
      (input)="this.search()"
      class="form-control"
      placeholder="{{ 'userlistSearchInputPlaceHolder' | translate }}"
      aria-label="Search"
      aria-describedby="search-addon"
    />
    <ng-select class="form-control ng-select select-filter-by" [(ngModel)]="this.filterBy"
      placeholder="{{ 'filterBy' | translate }}" [ngClass]="{'has-value': this.filterBy }">
      <ng-option value="" disabled selected>{{ "filterBy" | translate }}</ng-option>
          <ng-option value="bin_name">{{ "binName" | translate }}</ng-option>
          <ng-option value="bin_model_number">{{ "binNumber" | translate }}</ng-option>
          <ng-option value="bin_usage">{{ "adminViewBinUsageNavBar" | translate }}</ng-option>
    </ng-select>
  </nav>


  <!-- Table header for the bin-list, shown when mainContent is 'bin-list' -->
  <header [ngClass]="theme.getThemeClass()" class="table-header" *ngIf="mainContent === 'bin-list'">
    <span class="left">{{ "binListViewBinList" | translate }}</span>
    <span class="right">
      <button [ngClass]="theme.getThemeClass()" class="btn btn-dropdown" id="buttonDropdown" routerLink="/bin-create">
        {{ "binListViewNew" | translate }}
      </button>
    </span>
  </header>

  <!-- Table container for the bin-list, shown when mainContent is 'bin-list' -->
  <section
    [ngClass]="theme.getThemeClass()"
    class="table-container"
    *ngIf="mainContent === 'bin-list'"
  >
    <article class="table-responsive">
      <table class="table table-hover">
        <thead>
          <tr>
            <th [ngClass]="theme.getThemeClass()" scope="col">
              {{ "binName" | translate }}
            </th>
            <th [ngClass]="theme.getThemeClass()" scope="col">
              {{ "binListViewNumber" | translate }}
            </th>
            <th
              [ngClass]="theme.getThemeClass()"
              class="hide-small-screen"
              scope="col"
            >
              {{ "binListViewUsage" | translate }}
            </th>
            <th [ngClass]="theme.getThemeClass()"
              class="hide-small-screen" scope="col">
              {{ "binListViewLocation" | translate }}
            </th>
            <th
              [ngClass]="theme.getThemeClass()"
              class="hide-small-screen"
              scope="col"
            >
              {{ "binListViewCreated" | translate }}
            </th>
            <th
              [ngClass]="theme.getThemeClass()"
              class="hide-small-screen"
              scope="col"
            >
              {{ "binListViewModified" | translate }}
            </th>
            <th [ngClass]="theme.getThemeClass()" scope="col">
              {{ "iotTabHeaderResources" | translate }}
            </th>
          </tr>
        </thead>
        <!-- Bin list container -->
        <ng-container *ngFor="let bin of this.bin.bin_detail_array">
          <tbody>
            <tr class="trTbody">
              <td
                [ngClass]="theme.getThemeClass()"
                [style.color]="
                  this.bin.details.active ? 'green!important' : 'red!important'
                "
              >
                {{ bin.bin_name }}
              </td>
              <td
                [ngClass]="theme.getThemeClass()"
                [style.color]="
                  this.bin.details.active ? 'green!important' : 'red!important'
                "
              >
                {{ bin.details.bin_model_number }}
              </td>
              <td
                [ngClass]="theme.getThemeClass()"
                class="hide-small-screen"
                [style.color]="
                  this.bin.active ? 'green!important' : 'red!important'
                "
              >
                {{ bin.bin_usage | translate }}
              </td>
              <td class="hide-small-screen" [ngClass]="theme.getThemeClass()">
                {{ bin.bin_gps || bin.bin_address }}
              </td>
              <td [ngClass]="theme.getThemeClass()" class="hide-small-screen">
                {{
                  this.validationService.intToDate(bin.created)
                    | date : "dd/MM/yyyy"
                }}
              </td>
              <td [ngClass]="theme.getThemeClass()" class="hide-small-screen">
                {{
                  this.validationService.intToDate(bin.modified)
                    | date : "dd/MM/yyyy"
                }}
              </td>
              <td [ngClass]="theme.getThemeClass()">
                <a [routerLink]="['/bin-update', bin.bin_id]" (click)="storeUrl()"
                  ><img
                    class="iconUpdate"
                    src="./assets/update-pencil.png"
                    alt="icon of update"
                    height="30"
                    width="30"
                /></a>
              </td>
            </tr>
          </tbody>
        </ng-container>
      </table>
    </article>
  </section>
</div>
