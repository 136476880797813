import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AdminService {

  private returnToBinList = new Subject<void>;
  returnToBinList$ = this.returnToBinList.asObservable();

  constructor() { }

  // Function use to return bin list
  returnBinList(){
    this.returnToBinList.next();
  }
}
