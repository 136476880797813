<app-system-message></app-system-message>
<div [ngClass]="theme.getThemeClass()">
  <!-- Section for header and filter -->
  <header class="page-header">
    <!-- For future development -->
    <h2>{{"binModelListViewBinModelList" | translate}}</h2>
  </header>
    <nav
    id="search-component"
    class="nav-sort-filter"
    >
    <!-- Section for filter and sort list -->
    <div class="filters">
      <input
      #searchBox
      type="search"
      id="search-box"
      [(ngModel)]="userFilter"
      (input)="
        this.search()
      "
      class="form-control rounded searchInput"
      placeholder="{{ 'userlistSearchInputPlaceHolder' | translate }}"
      aria-label="Search"
      aria-describedby="search-addon"
    />
    <ng-select class="form-control ng-select sort-by-select" [(ngModel)]="this.sortBy"
      (change)="onSortChange('select', $event)" placeholder="{{ 'sortBy' | translate }}" [ngClass]="{'has-value': this.sortBy}">
      <ng-option value="" disabled selected>{{ "sortBy" | translate }}</ng-option>
          <ng-option value="number">{{ "binModalViewBinModelNumber" | translate }}</ng-option>
          <ng-option value="volume">{{ "binModelUpdateViewTotalVolume" | translate }}</ng-option>
          <ng-option value="numberBin">{{ "numberOfBins" | translate }}</ng-option>
          <ng-option value="thing">{{ "haveDevice" | translate }}</ng-option>
    </ng-select>
    <div class="card-title mt-0 status">
      <label class="switch">
          <input type="checkbox" [ngModel]="this.ascDesc" (change)="onSortChange('switch', $event)">
          <span class="slider round">
            <span class="toggle-switch-label-off">{{ "asc" | translate }}</span>
            <span class="toggle-switch-label-on">{{ "desc" | translate }}</span>
          </span>
      </label>
  </div>
    </div>
   <div class="createBtns">
    <button [ngClass]="theme.getThemeClass()" class="btn btn-dropdown"  routerLink="/bin-model-create">
      {{  "newBinModel" | translate }}
    </button>
    <button [ngClass]="theme.getThemeClass()" class="btn btn-dropdown"  routerLink="/bin-dashboard">
      {{  "binListViewBinList" | translate }}
    </button>
    <button [ngClass]="theme.getThemeClass()" class="btn btn-dropdown" (click)="routeToBinCreate();">
      {{  "newBin" | translate }}
    </button>
   </div>

  </nav>
  <div class="grid-container">
    <section [ngClass]="theme.getThemeClass()" class="gridster" [ngStyle]="getColumnStyle()" >
      <ng-container *ngFor="let item of this.uniqueBinModelArray">
      <article class="gridster-item">
        <!-- Your tile content goes here -->
          <nav [ngClass]="theme.getThemeClass()" class="dashboard-tile">
            <header class="dashboard-tile-header">
              <h5 class="dashboard-tile-title"><a [ngClass]="theme.getThemeClass()" [routerLink]="['/bin-model-update', item.bin_model_id]">{{item.bin_model_number | translate}}</a> &nbsp; <i #deviceIcon class="fa-solid fa-microchip device-icon" title="{{'binModelHasDevice'|translate}}"  *ngIf="item.with_thing"></i></h5>
              <a [ngClass]="theme.getThemeClass()" class="number-of-bins" (click)="goToDistributorBinDashboard(item.bin_model_id)">
                <i class="fa fa-trash trash-icon textAlignCenter"></i>
                <label class="label-clickable">{{item.number_of_bin}}</label>
              </a>
            </header>
            <hr>
            <div class="tiles-container">
              <div class="titles-items-above-ground col-2"  [ngClass]="theme.getThemeClass()" >
                <img  src="../../assets/above_ground.svg" *ngIf="item.above_ground === '1' && this.currentTheme.includes('text-dark')">
                <img  src="../../assets/above_groundWhite.svg" *ngIf="item.above_ground === '1' && this.currentTheme.includes('text-white')">
              </div>
              <div class="titles-items-dimensions col-10">
                <div>
                  <div class="shapes col-2"  title="{{'binShape'| translate}}">
                    <i *ngIf="item.bin_shape === 'S'" class="fa fa-cube"></i>
                    <img src="../../assets/cylinder.png" *ngIf="item.bin_shape === 'C'">
                  </div>
                  <div class="bin-model-volume" title="{{'binVolume'| translate}}">
                    <i class="fa fa-v"></i><label class="item-label">{{ item.total_volume }}</label>
                  </div>
                </div>
                <div class="dimensions col-10">
                  <div class="bin-model-dimensions">
                    <div *ngIf="item.bin_width !== null && item.bin_width > 0 " class="dimension" title="{{'binWidthMM'| translate}}">
                      <span  class="width"><i  class="fa fa-arrow-down"  [ngStyle]="{ 'opacity': item.bin_shape != 'C' ? 1 : 0 }"></i><i class="fa fa-arrow-down"></i><i class="fa fa-arrow-down binWidth" ></i></span>
                      <label *ngIf="item.bin_width > 0" class="item-label">{{ item.bin_width }}</label>
                    </div>
                    <div class="dimension" title="{{'binHeightMM'| translate}}">
                      <span class="height"><i class="fa fa-arrow-down"  [ngStyle]="{ 'opacity': item.bin_shape != 'C' ? 1 : 0 }"></i><i class="fa fa-arrow-down binHeight"></i><i class="fa fa-arrow-down"></i></span>
                      <label class="item-label">{{ item.bin_height }}</label>
                    </div>
                    <div *ngIf="item.bin_depth !== null && item.bin_shape === 'S'" class="dimension" title="{{'binDepthMM'| translate}}">
                      <span class="depth"><i class="fa fa-arrow-down binDepth " ></i><i class="fa fa-arrow-down "></i><i class="fa fa-arrow-down"></i></span>
                      <label  class="item-label">{{ item.bin_depth }}</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </nav>
        </article>
    </ng-container>
    <div class="mobileExtend"></div>
  </section>
  </div>
</div>
