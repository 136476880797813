import { Injectable } from "@angular/core";

Injectable({
    providedIn: 'root'
})

export const TruckTypes = [
    {'key':'0', 'value':'front_loaders'},
    {'key':'1', 'value':'rear_loaders'},
    {'key':'2', 'value':'side_loaders'},
    {'key':'3', 'value':'roll_off_trucks'},
    {'key':'4', 'value':'grapple_trucks'},
    {'key':'5', 'value':'hook_lift_trucks'},
    {'key':'6', 'value':'satellite_trucks'}
]

