<app-system-message></app-system-message>

<div [ngClass]="theme.getThemeClass()" class="container-fluido">
    <section [ngClass]="theme.getThemeClass()" class="card bg-light">
        <article class="card-body mx-auto article">
            <h4 class="card-title mt-0 text-center">{{ 'distributorEditHeaderEditDistributor' | translate }}</h4>
            <form>
                <!-- Input field for distributor name -->
                <label class="label-spacer" for="distributor_name">{{ 'name' | translate }} <span class="requiredStar">*</span></label>
                <div class="input-group firstInput">
                    <span class="input-group-text" id="basic-addon1"><i class="fa fa-user"></i></span>
                    <input [(ngModel)]="this.distributorService.distributorData.distributor_name" type="text"
                    class="form-control" name="distributor_name" value="this.distributorService.distributorData.distributor_name"
                    placeholder="{{ 'name' | translate }}"
                    aria-label="Client Name" aria-describedby="basic-addon1"
                    [class.error-border]="this.validationService.validationErrorArray[0] == 'usernameInvalid'">
                </div>
                <!-- Input field for distributor legal name -->
                <label class="label-spacer" for="legal_name">{{ 'legalName' | translate }}<span class="requiredStar">*</span></label>
                <div class="input-group">
                    <span class="input-group-text" id="basic-addon1"><i class="fa fa-address-card"></i></span>
                    <input [(ngModel)]="this.distributorService.distributorData.legal_name" type="text"
                    class="form-control" name="legal_name" value="this.distributorService.distributorData.legal_name"
                    placeholder="{{ 'legalName' | translate }}"
                    aria-label="Legal Name" aria-describedby="basic-addon1"
                    [class.error-border]="this.validationService.validationErrorArray[0] == 'clientLegalNameInvalid'">
                </div>
                <!-- Input field for email -->
                <label class="label-spacer" for="email">{{ 'email' | translate }}<span class="requiredStar">*</span></label>
                <div class="input-group">
                    <span class="input-group-text" id="basic-addon1"><i class="fa fa-envelope"></i></span>
                    <input [(ngModel)]="this.distributorService.distributorData.email" type="text"
                    class="form-control" name="email" value="this.distributorService.distributorData.email"
                    placeholder="{{ 'email' | translate }}"
                    aria-label="Email" aria-describedby="basic-addon1"
                    [class.error-border]="this.validationService.validationErrorArray[0] == 'clientEmailInvalid'">
                </div>
                <!-- Input field for phone number -->
                <label class="label-spacer" for="phone_number">{{ 'phoneNumber' | translate }}<span class="requiredStar">*</span></label>
                <div class="input-group">
                    <span class="input-group-text" id="basic-addon1"><i class="fa fa-phone"></i></span>
                    <input [(ngModel)]="this.distributorService.distributorData.phone_number" type="text"
                    class="form-control telefone-input" name="phone_number" value="this.phoneNumberWithMask"
                    placeholder="(000) 000-0000" aria-label="Phone Number"
                    aria-describedby="basic-addon1"
                    [class.error-border]="this.validationService.validationErrorArray[0] == 'clientPhoneInvalid'">
                </div>
                <!-- Input field for address with Autocomplete -->
                <label class="label-spacer" for="address">{{ 'address' | translate }}<span class="requiredStar">*</span></label>
                <div class="input-group lastInput">
                    <span class="input-group-text" id="basic-addon1"><i class="fas fa-search-location"></i></span>
                    <input [(ngModel)]="this.distributorService.distributorData.address" id="autocomplete"
                    type="text" class="form-control" name="address" value="this.distributorService.distributorData.address"
                    placeholder="{{ 'clientcreateFormInputFindAddress' | translate }}"
                    aria-label="Enter a place" aria-describedby="basic-addon1"
                    [class.error-border]="this.validationService.validationErrorArray[0] == 'clientAddressInvalid'">
                </div>
                                                           
                <nav class="form-group displayFlex">
                    <button (click)="pageReload()" [ngClass]="theme.getThemeClass()" type="submit" class="btn btn-primary btnCancel fixed-size">{{ 'clientcreateFormBtnCancel' | translate }}</button>
                    <button [ngClass]="theme.getThemeClass()" (click)="confirmatioQuestion();" type="submit" class="btn btn-primary btnCreate fixed-size">{{ 'clienteditFormBtnUpdateClient' | translate }}</button>
                </nav>

                <nav class="form-group displayFlex">
                    <button [ngClass]="theme.getThemeClass()" class="button btn btn-primaryy btnCancel fixed-size red" (click)="deleteClient()">{{ 'delete' | translate }}</button>
                </nav>

            </form>
        </article>
    </section>
</div>

<app-relationship-modal 
    *ngIf="this.relationshipModalService.showModal"
    class="appDeviceModal container-fluid">
</app-relationship-modal>

