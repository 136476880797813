import { Injectable } from '@angular/core';

Injectable({
  providedIn: 'root'
})

// Constant used in device modal config in sleep time select
export const SleepTime = [
    { "value": 300, "label": "deviceConfigModal5MinuteSleep"},
    { "value": 3600, "label": "deviceConfigModal1HourSleep" },
    { "value": 7200, "label": "deviceConfigModal2HourSleep"},
    { "value": 10800, "label": "deviceConfigModal3HourSleep"},
    { "value": 14400, "label": "deviceConfigModal4HourSleep"},
    { "value": 21600, "label": "deviceConfigModal6HourSleep"},
    { "value": 28800, "label": "deviceConfigModal8HourSleep" },
    { "value": 43200, "label": "deviceConfigModal12HourSleep"},
    { "value": 86400, "label": "deviceConfigModal24HourSleep"},
    { "value": 172800, "label": "deviceConfigModal48HourSleep"},
    { "value": 259200, "label": "deviceConfigModal72HourSleep"},
    { "value": 604800, "label": "deviceConfigModal1weekSleep"},
]
