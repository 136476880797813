import { Component, Injectable, ChangeDetectorRef, ViewChild, ElementRef, OnInit, AfterViewInit } from '@angular/core';
import { Devices, IotService, MultipleDevices } from '../service/iot.service';
import { LocalStorageService } from '../local-storage.service';
import { ModalService } from '../service/device-modal.service';
import { ThemeService } from '../service/theme.service';
import { RoleService } from '../service/role.service';
import { CognitoService } from '../service/cognito.service';
import { Bin, BinDetails, BinsService } from '../service/bins.service';
import { SystemMessageService } from '../service/system-message.service';
import { DashboardService } from '../service/dashboard.service';
import { ProductionStatus } from '../constants/device-poduction-status';


@Component({
  selector: 'app-device-modal',
  templateUrl: './device-modal.component.html',
  styleUrls: ['./device-modal.component.css', './device-modal.css', '../../global-elements.css'],
})

@Injectable({
  providedIn: 'root'
})

export class DeviceModalComponent implements OnInit, AfterViewInit {
  // This will have all the items for the selected device
  public device: Devices = {} as Devices;
  public devices: MultipleDevices[] = [];
  public devicesNames: string[] = [];
  public selectedDevice: string = "";

  // Variables to handle the change of tabs in the modal
  public command: string = 'Details';
  public currentTab: number = 0;
  public modalTabs = document.getElementsByClassName("tab") as HTMLCollectionOf<HTMLElement>;
  public isDetailsActive: boolean = false;
  public isConfigsActive: boolean = false;

  // Variables for the batery of the device
  public pourcentage: number = 0;
  public color: string = '';
  public inCharge: boolean = false;

  // Variables for the capacity of the trash can
  public distanceForDevice: number = 0;

  public lastCommand: any

  public binDepthInMeters = 2.5;

  private successMessage: any;

  // Retrieve current language selected from local storage
  languageStatus:string = this.localStorageService.getItem('language');

  // Set a timeout variable for UI display
  public timeout: boolean = false;
  public numberOfTimeoutIteration: number = 0;

  // Variables used in device association
  public clientLegalname: string = '';
  public distributorLegalName: string = '';
  public binNumber: string = '';
  public binName: string = '';
  public client_id: string = '';
  public distributor_id: string = '';
  public bin_id: string = '';
  public changeAssociationClicked: boolean = false;
  public selectedClient: string = '';
  public selectedDistributor: string = '';
  public selectedBin: string = '';
  public binArray: any;
  private originalBinArray: any;
  private date: number = 0;

  public oldBinData: Bin[] = [];

  public associationResult: any[] = []

  public binID: any;

  public deviceStatus2: string = "";

  public fillLevel: any;

  // Variables used for device notes
  public isDeviceNoteUpdate: boolean = false;
  public characterLeft: number = 500;

  protected selectedProdStatusIndex = 0;

  constructor(
    private localStorageService: LocalStorageService,
    public modal: ModalService,
    public theme: ThemeService,
    public cognitoService: CognitoService,
    private binService: BinsService,
    private systemMessageService: SystemMessageService,
    public iotService: IotService,
    public bin: BinsService,
    public dashboardService: DashboardService
  ) {}

  @ViewChild('inputThingName') inputField!: ElementRef;
  @ViewChild('inputDeviceNote') inputDeviceNote!: ElementRef;

  // Get current theme from localstorage
  themeStatus: string = this.localStorageService.getItem('theme');

  getBinId(thingName: string) {
    // Get the list of relationships for your service
    const relationships = this.dashboardService.relationships;

    // Search for thingName in the list of relationships
    const relationshipFound = relationships.find(rel => rel.thing_name === thingName);


    // If found, return the bin_id, otherwise, return an empty string or treat as you prefer
    return relationshipFound ? relationshipFound.bin_id : '';

  }

  async ngOnInit(){
    // Will select only thing that the modal need depend from where it is showed
    switch(true){
      case this.modal.showSingleDeviceModal:
        this.detailsClicked();
        this.device = this.modal.selectedDevice;

        // Call the function pourcentage batterie to be sure that at the begining the color class of the batterie showed is allready on the html balise
        // this.getBateryPourcentage();

        // Get the bin detail array
        this.modal.binArray = await this.binService.setBinDetailArray();

        const binCapacity = this.modal.obtainBinDepthByBinId(this.getBinId(this.device.thingName));
        const distanceToLid = this.device.dat.dst;

        this.fillLevel = this.iotService.checkFillLevelPercentage(distanceToLid, binCapacity)
        break;

      case this.modal.showMultipleDevicesModal:
        this.detailsClicked();
        this.device = this.modal.selectedDevice;
        this.devices = this.modal.selectedMultipleDevices;

        // Call the function pourcentage batterie to be sure that at the begining the color class of the batterie showed is allready on the html balise
        this.getBatteryPercentage();
        // this.checkBinFillLevel();
        break;

      case this.modal.showJsonShadow:
        this.device = this.modal.selectedDevice;
        break;
      
      case this.modal.showProdStatusModal:
        // Set Option to device production status
        this.selectedProdStatusIndex = this.modal.prodStatusIndex
        break;

      case this.modal.showRelationship:
        let count = 0;
        const waitForDeviceAssociation = () => {

          if(this.checkAllArrayNotEmpty() || count >= 50){
            this.originalBinArray = this.modal.binArray;
            this.setAssociation();
          }else{
            count ++;
            setTimeout(() => {
              waitForDeviceAssociation();
            }, 100);
          }
        }
        waitForDeviceAssociation();
        break;
    }
  }

  ngAfterViewInit(): void {
    if(this.inputField){
      this.inputField.nativeElement.focus();
    }
    if(this.inputDeviceNote){
      this.inputDeviceNote.nativeElement.focus();
    }
  }

  // Function to load status from HTML for a given thingName
  loadStatusFromHtml(thingName: string): string {
    // Call the function in iotService to check health status for the specified thingName
    // and return the result
    return this.iotService.checkHealthStatusUsingThingName(thingName);
  }

  loadFillLevelFromHtml(thingName: string): string {
    return this.iotService.checkDeviceFillLevelUsingThingName(thingName);
  }

  // Function called when we need to check if we got all arrays for the device association
  checkAllArrayNotEmpty(){

    if(this.modal.deviceAssociationRow &&
      this.modal.deviceAssociationRow.length > 0 &&
      this.modal.clientsArray &&
      this.modal.clientsArray.length > 0 &&
      this.modal.distributorsArray &&
      this.modal.distributorsArray.length > 0 &&
      this.modal.binArray &&
      this.modal.binArray.length > 0){
        // If all condition are met, return true, else return false to make an other loop
        return true;
      }
    else{
      return false;
    }
  }

  // Function to change command for the tabs
  changeCommand(command: string, index: number){
    this.currentTab = index;
    let selectedTab: HTMLElement | null = null;

    // Get the command and put the selected tab to the good one
    switch(command){
      case 'Details':
        this.command = 'Details';
        selectedTab = document.getElementById("tabDetails");
        break;

      case 'Configs':
        this.command = 'Configs';
        selectedTab = document.getElementById("tabConfigs");
        break;
    }
    this.lastCommand = command;

    // This loop set the propertys background-color and box-shadow for the different tabs of the menu
    // It will put also and remove the class active and deactive for witch one of the tab is selected
    for(let i = 0; i < this.modalTabs.length; i ++){
      if(this.modalTabs[i] !== selectedTab){ // This is for the unselected tabs
        // Set background color to darkgray
        this.modalTabs[i].style.setProperty('background-color', 'darkgray');
        // For now, this is for only two tabs, details is the first tab and will put a box shadow
        if(index === 0){
          this.modalTabs[i].style.setProperty('box-shadow', 'inset 2px -2px 4px 1px var(--theme-color)');
        }else{
          this.modalTabs[i].style.setProperty('box-shadow', 'inset -2px -2px 4px 1px var(--theme-color)');
        }
        // Those class are for the mouse over the tabs
        this.modalTabs[i].classList.remove('active');
        this.modalTabs[i].classList.add('deactive');
      }else{ // This will be what is for the selected tab
        // It will first remove the darkgray and the box shadow of the unselected tab
        this.modalTabs[i].style.removeProperty('background-color');
        this.modalTabs[i].style.removeProperty('box-shadow');
        // Those class are for the mouse over the tabs
        this.modalTabs[i].classList.remove('deactive');
        this.modalTabs[i].classList.add('active');
      }
    }
  }

  // Get the pourcentage and put the color(red, yellow or green) for the battery of the selected device
  getBatteryPercentage(){

    // Will put this.pourcentage at zero if the pourcentage received is null or not a number
    if((this.device.dat.bat !== 0 || this.device.dat.bat !== (null) || this.device.dat.bat !== undefined) && typeof(this.device.dat.bat) === 'number'){
      // Convert the max voltage of the battery(3.7V) in pourcentage 0% to 100%
      this.pourcentage = Math.round((this.device.dat.bat / 3.7) * 100);
    }else{
      this.pourcentage = 0;
    }
    // If the battery is more then 3.7V it's because she is in charging mode
    if(this.device.dat.bat > 3.7){
      this.pourcentage = 100;
      this.inCharge = true;
    }

    // Put the good color for the text depend on the pourcentage of the battery
    switch(true){
      case this.pourcentage >= 0 && this.pourcentage <= 30:
        this.color = 'red';
        break;

      case this.pourcentage >= 31 && this.pourcentage <= 60:
        this.color = 'yellow';
        break;

      case this.pourcentage >= 61:
        this.color = 'green';
        break;

      default:
        this.color = 'red';
        break;
    }
    return this.pourcentage;
  }

  // This function determines the fill level style based on the fill percentage.
  getFillLevelStyle() {

    // Obtenha o valor numérico da porcentagem
    const numericFillLevel = this.getNumericValueFromFillLevel(this.fillLevel);

    // Determine o estilo com base no nível de preenchimento
    if (numericFillLevel <= 60) {
      return { color: 'green' }; // Estilo para a cor verde
    } else if (numericFillLevel <= 79.09) {
      return { color: '#B8860B' }; // Estilo para a cor amarela
    } else {
      return { color: 'red' }; // Estilo para a cor vermelha
    }
  }

  getNumericValueFromFillLevel(fillLevel: string): number {
    // Extrai apenas os caracteres numéricos e o ponto decimal da string
    const numericValue = parseFloat(fillLevel.replace(/[^0-9.]/g, ''));

    // Verifique se o valor obtido é um número válido
    return isNaN(numericValue) ? 0 : numericValue;
  }

  getStatusStyle() {
    const status = this.modal.thingStatus;

    switch (status) {
      case "Healthy":
        return { color: 'green' };
        // break;
      case "Unhealthy":
        return { color: 'red' };
        // break;
      default:
        return  { color: 'gray' };
        // break;
    }
  }

  // Close the modal view
  closeModal(){
    // Set all HTML boolean variable to false
    this.modal.showDeviceModal = false;
    this.modal.showJsonShadow = false;
    this.modal.showSingleDeviceModal = false;
    this.modal.showRelationship = false;
    this.modal.showNotesModal = false;
    this.modal.showProdStatusModal = false;


    // initialize all the datas
    this.modal.selectedMultipleDevicesNames = [];
    this.modal.selectedMultipleDevices = [];
    this.modal.deviceNotes = [];
  }

  // Funciton that reset all the instance of the modal relationships to avoid bad infos
  closeDeviceRelationModal(){
    // Reset all arrays in modal service
    this.modal.deviceAssociationRow = [];
    this.modal.binArray = [];
    this.modal.clientsArray = [];
    this.modal.distributorsArray = [];
    this.binService.bin_array = [];
    this.binService.bin_model_array = [];

    // Reset all his own data
    this.selectedBin = '';
    this.selectedClient = '';
    this.selectedDevice = '';
    this.selectedDistributor = '';
    this.clientLegalname = '';
    this.distributorLegalName = '';
    this.binNumber = '';

    // Call close function
    this.closeModal();
  }

  // Function called when the "Ok" button is clicked
  onOkClick() {
     // Call a function to retrieve device information using the selected device's name
    this.modal.getDeviceUsingName(this.selectedDevice);

     // Check if the 'check' property is true in the modal
    if (this.modal.check) {
      // If 'check' is true, assign the selected device to 'this.device'
      this.device = this.modal.selectedDevice;

      // Open a modal for the selected device
      this.modal.openModalDevice(this.device);
    }
  }

  // This function resets button stats for details and configurations.
  cleanButtonStats() {
    this.isDetailsActive = false;
    this.isConfigsActive = false;
  }

  // This function handles the click event for the "Details" button.
  detailsClicked() {
    this.cleanButtonStats();
    this.isDetailsActive = true;
    this.isConfigsActive = false;
  }

  // This function handles the click event for the "Configs" button.
  configsClicked() {
    this.cleanButtonStats();
    this.isDetailsActive = false;
    this.isConfigsActive = true;
  }

  // Function called to update device association
  async saveDeviceAssociation(){
    // Set the current date format to yyyyMMDD
    const date = new Date();
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    this.date = parseInt(`${year}${month}${day}`);
    this.iotService.thingName = this.modal.thingName;

    // Call the function to update device association in device modal service
    (await this.iotService.updateDeviceAssociationLambda(this.client_id, this.bin_id, this.distributor_id, this.date)).subscribe((response: any) => {

      this.successMessage = response;
      if(this.successMessage.message === 'success'){
        this.systemMessageService.selectRibbon('success', 'alert-success-generic-message');
        this.closeDeviceRelationModal();
      }else{
        this.systemMessageService.selectRibbon('danger', 'alert-danger-generic-message');
      }
    });
  }

  // This function converts seconds to hours and minutes in a formatted string.
  convertSecondsToHours(seconds: number): string {
    const hours = Math.floor(seconds / 3600); // 3600 seconds in an hour
    const remainingSeconds = seconds % 3600;
    const minutes = Math.floor(remainingSeconds / 60);
    return `${hours}h ${minutes}m`;
  }

  // Function call to change the delete reference from a device
  deleteReferencesChange(){
    this.iotService.deleteReference = !this.iotService.deleteReference;
  }

  // Function call to change the delete Logs from a device
  deleteLogsChange(){
    this.iotService.deleteLogs = !this.iotService.deleteLogs;
  }

  // function call to change the delete report datats from device
  deleteReportDatasChange(){
    this.iotService.deleteReportDatas = !this.iotService.deleteReportDatas;
  }

  // Function call to decode line break and text formating
  decodeNoteText(note: string): string {
    return decodeURIComponent(note);
  }

  // Function called to save the user note
  saveNotes(){
    // If note is not empty, call the function to save the note in device modal service
    if(this.modal.newNote !== ''){
      this.modal.saveNotes();
    }

    // Set a timeout to clear the textarea when note is saved
    setTimeout(() => {
      this.modal.newNote = '';
    },500);
  }

  // Function called when user double click on a device note
  async editDeviceNote(note: any){
    // Set the newNote variable to the selected note
    this.modal.newNote = note.note;

    // Set user sub and date ttimestamp
    const dateTimestamp = new Date().getTime();
    const user = await this.cognitoService.getCurrentUserSub();

    // Set all instance of the newNoteformated object
    this.modal.newNoteFormated.device_note_id = note.device_note_id;
    this.modal.newNoteFormated.thing_name = note.thing;
    this.modal.newNoteFormated.note = this.modal.newNote;
    this.modal.newNoteFormated.created = dateTimestamp;
    this.modal.newNoteFormated.user = user;
    this.modal.newNoteFormated.given_name = note.given_name;
    this.modal.newNoteFormated.family_name = note.family_name;

    // Set an old note formated to the present note so when we save, we gonna be abble to remove this note in the array and push the updated one
    this.modal.oldNoteFormated = this.modal.newNoteFormated;

    // Call a funciton to calculate the number of character left to the note
    this.calculateCharacterLeft(note.note);
  }

  // Function call to get timestamp into yyyy-mm-dd hh:mm:ss format
  convertTimestamp(timestamp: number){
    const date = new Date(Math.floor(timestamp));

    return this.iotService.formatDate(date);
  }

  // function call on input in textarea to know how much caracter left
  calculateCharacterLeft(note: string){
    this.characterLeft = 500 - note.length;
  }

  // Function called when user change the client select
  clientSelectChange(){
    if(this.client_id){
      this.modal.binArray = this.originalBinArray.filter((bin: any) => bin.client_id === this.client_id);
    } else{
      this.modal.binArray = this.originalBinArray;
    }
  }

  // Function called to set the association on device association modal
  setAssociation(){
    const deviceAssociation = this.modal.deviceAssociationRow[0];

    if(deviceAssociation.distributor_id){
      const distributorAssociated = this.modal.distributorsArray.find((distributor: any) => distributor.distributor_id === deviceAssociation.distributor_id);

      if(distributorAssociated){
        this.distributorLegalName = distributorAssociated.legal_name;
        this.distributor_id = distributorAssociated.distributor_id;
      }
    }

    if(deviceAssociation.client_id){
      const clientAssociated = this.modal.clientsArray.find((client: any) => client.client_id === deviceAssociation.client_id);

      if(clientAssociated){
        this.clientLegalname = clientAssociated.legal_name;
        this.client_id = clientAssociated.client_id;
      }
    }

    if(deviceAssociation.bin_id){
      if(deviceAssociation.client_id && this.modal.binArray){
        this.modal.binArray = this.modal.binArray.filter((bin: any) => bin.client_id === deviceAssociation.client_id);
      }

      const binAssociated = this.modal.binArray.find((bin: any) => bin.bin_id === deviceAssociation.bin_id);
      if(binAssociated){
        this.binName = binAssociated.bin_name;
        this.bin_id = binAssociated.bin_id;
      }
    }
  }

  async saveProdStatus(){
    (await this.modal.saveProdStatusAPI(this.selectedProdStatusIndex)).subscribe()
    var dev = this.iotService.devicesArray.find((item) => item.thingName == this.modal.thingName)
    // update front end table with new value
    dev!.prodStatus!.status_index = this.selectedProdStatusIndex;
    // add note to device when we change the production status
    this.modal.newNote = "Device production status changed to " + ProductionStatus[dev!.prodStatus!.status_index].value;
    this.modal.saveNotes();
  }
}
