import {
  ActivatedRouteSnapshot,
  DetachedRouteHandle,
  RouteReuseStrategy,
} from '@angular/router';

export class CustomRouteReuseStrategy implements RouteReuseStrategy {
  private routeCache = new Map<string, DetachedRouteHandle>();

  //Detach previous route
  shouldDetach(route: ActivatedRouteSnapshot): boolean {
    return false; // We won't store any routes for later retrieval
  }

  //Store current active route and handle the last route detached
  store(
    route: ActivatedRouteSnapshot,
    handle: DetachedRouteHandle
  ): void {
    this.routeCache.set(route.routeConfig?.path || '', handle);
  }

  //Attach next route
  shouldAttach(route: ActivatedRouteSnapshot): boolean {
    return this.routeCache.has(route.routeConfig?.path || '');
  }

  //Retrieve route snapshot
  retrieve(route: ActivatedRouteSnapshot): DetachedRouteHandle | null {
    return this.routeCache.get(route.routeConfig?.path || '') || null;
  }

  //Determine if we reuse a route or not
  shouldReuseRoute(
    future: ActivatedRouteSnapshot,
    curr: ActivatedRouteSnapshot
  ): boolean {
    return future.routeConfig === curr.routeConfig;
  }
}
