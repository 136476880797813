import { Injectable } from '@angular/core';
import { HttpClient , HttpHeaders } from '@angular/common/http';
import { Observable, lastValueFrom } from 'rxjs';
import { environment } from '../environments/environment';
import { map, catchError } from 'rxjs/operators'; // Import map from 'rxjs/operators'
import { String } from 'aws-sdk/clients/cloudhsm';
import { WorkOrderStatus } from '../constants/work-order-status';
import { WorkOrderTypes } from '../constants/work-order-types';

export interface WorkOrder {
  workOrderId: string,
  client_id: string,
  distributor_id?: string,
  creator_entity_id: string,
  created_by: string,
  work_order_type: string,
  work_order_status: string,
  creator_entity_type: string,
  description: string,
  bin_id: string,
  thing_name: string,
  status: string,
  work_order_datetime: string,
  work_order_deadline: string,
  closing_datetime: string,
  created: string,
  modified?: string,
  deleted?: string,
}

@Injectable({
  providedIn: 'root'
})

export class WorkOrderService {

  workOrderData = {
    workOrderId: '',
    client_id: "",
    distributor_id: "",
    creator_entity_id: "",
    created_by: "",
    work_order_type: "",
    work_order_status: "",
    creator_entity_type: "",
    description: "",
    bin_id: "",
    thing_name: "",
    status: "",
    work_order_datetime: 0,
    work_order_deadline: 0,
    closing_datetime: "",
    created: 0,
    modified: "",
    deleted: "",
  }

  public array: any;
  public workOrdersArray: any[] = [];
  public clientIDArray: any[] = [];
  public clientIDArrayFixed: any[] = [];

  public thingNamesList: any;
  public binNamesList: any;

  constructor(private http: HttpClient) { }


  // Funciton called to get any array of witch keys in the returned JSON we have to change into a int
  switchJSONToIntForBinResponse(element: any){
    // Tableau of witch keys we need to check
    const keysToCheck = [
      'active',
      'created',
      'modified',
      'threshold'
    ];
    // Call the function to transfert all value of the keys in Integer
    this.keyToTransfert(keysToCheck, element);
  }

  // Funciton that transfer a string to an int depending on the key that we pass in parameter
  keyToTransfert(keysToCheck: any, element: any){
    keysToCheck.forEach((key: any) => {
      // Vérifier si la clé existe dans l'élément et si sa valeur est une chaîne
      if (typeof element[key] === 'string') {
          // Convertir la valeur de la clé en entier
          element[key] = parseInt(element[key], 10);
      }
    });
  }

  // function called to replace work order status and type key by proper translation key
  replaceWorkOrderStatusAndTypeAndDates(){
    this.workOrdersArray.forEach(item => {
      const status = WorkOrderStatus.find(status => status.value === item.status);
      const type = WorkOrderTypes.find(types => types.value === item.work_order_type);
      if(status){
        item.status = status.label;
      }
      if(type){
        item.work_order_type = type.label;
      }
    })
  }

  // Function that return all work order of a distributor
  async getAllEntityWorkOrders(distributor_id: string): Promise<any[]>{
    return new Promise<any[]>((resolve) => {
      this.getAllEntityWorkOrdersLambda(distributor_id).subscribe((response) => {
        // Put the array returned by the lambda function into an tmp array
        this.array = response;
        this.workOrdersArray = this.array;
        this.replaceWorkOrderStatusAndTypeAndDates();
        resolve(this.workOrdersArray);
      })
    });
  }

  // Function to create a new work order using an HTTP POST request
  createWorkOrder(): Observable<any> {
    // Define the HTTP headers with content type
    const headers = new HttpHeaders({
      'Content-Type':  'application/json'
    });

    // Make an HTTP POST request to the createWorkOrder endpoint
    return this.http.post(
      environment.api.stage + environment.api.route.createWorkOrder, // URL for the createWorkOrder endpoint

      // Request body: Data for creating the work order
      {
        "client_id": this.workOrderData.client_id,
        "creator_entity_id": this.workOrderData.creator_entity_id,
        "created_by": this.workOrderData.created_by,
        "work_order_type": this.workOrderData.work_order_type,
        "creator_entity_type": this.workOrderData.creator_entity_type,
        "description": this.workOrderData.description,
        "bin_id": this.workOrderData.bin_id,
        "thing_name": this.workOrderData.thing_name,
        "status": this.workOrderData.work_order_status,
        "work_order_datetime": this.workOrderData.work_order_datetime,
        "work_order_deadline": this.workOrderData.work_order_deadline,
        "created": this.workOrderData.created,
      }, {headers: headers} // Include the defined headers in the request
    );
  }


  // Function to retrieve Thing Names list using a client ID
  public async getThingNamesList(client_id: string) {

    // Initialize array to store the list of Thing Names
    this.thingNamesList = [];

    // Define the base URL for the Lambda function to get Things using client ID
    const baseUrl = environment.api.stage + environment.api.route.getClientThings;

    // Construct the complete URL with client_id parameter
    const url = `${baseUrl}&client_id=${client_id}`;

    try {
      // Make an HTTP GET request to the Lambda function
      const response$ = this.http.get(url)

      // Retrieve and store the Thing Names list from the response
      this.thingNamesList = await lastValueFrom(response$);

      // Return the original response (Observable) to the calling code
      return response$;

    } catch (error) {
      // Log an error message and re-throw the error for the calling code to handle
      console.error('API Error:', error);
      throw error; // Re-throw the error for the calling code to handle
    }
  }

  // Function to retrieve Bins ID list using a client ID
  public async getBinsIdList(client_id: string) {

    // Initialize array to store the list of Bins IDs
    this.binNamesList = [];

    // Define the base URL for the Lambda function to get Bins using client ID
    const baseUrl = environment.api.stage + environment.api.route.getClientBins;

    // Construct the complete URL with the client_id parameter
    const url = `${baseUrl}&client_id=${client_id}`;

    try {
      // Make an HTTP GET request to the Lambda function
      const response$ = this.http.get(url)

      // Retrieve and store the Bins ID list from the response
      this.binNamesList = await lastValueFrom(response$);

      this.binNamesList = this.binNamesList.forEach((element: any) => {
        this.switchJSONToIntForBinResponse(element);
      });

      // Return the original response (Observable) to the calling code
      return response$;

    } catch (error) {
      // Log an error message and re-throw the error for the calling code to handle
      console.error('API Error:', error);
      throw error; // Re-throw the error for the calling code to handle
    }
  }

  // Function to retrieve all work orders based on creator entity type and client IDs
  getAllWorkOrders(creatorEntityType: String, clientIds: string[]) {
    // Initialize array to store retrieved work orders
    this.workOrdersArray = [];

    // Define the API endpoint URL for retrieving all work orders
    const url = environment.api.stage + environment.api.route.getWorkOrders;

    // Add parameters to the HTTP request
    const params = { creator_entity_type: creatorEntityType,
      clients: clientIds.join(','), }; // Convert array of client IDs to comma-separated string

    // Make an HTTP GET request to the specified URL with the provided parameters
    return this.http.get(url, { params }).pipe(
      map((response) => {
        // Process the response and add the data to the workOrdersArray
        this.workOrdersArray.push(response);

        // Return the response data to the calling code
        return response;
      }),
      catchError((error) => {
        // Handle errors during the API request
        console.error('API Error:', error);
        throw error(error); // Re-throw the error for the calling code to handle
      })
    );
  }

  // Function called API Gateway to get all distributor work order
  getAllEntityWorkOrdersLambda(distributor_id: string){
    // Set url for the lambda function
    const url = environment.api.stage + environment.api.route.getAllEntityWorkOrders + "&distributor_id=" + distributor_id;

    // Will call the lambda function then return a response
    return this.http.get(url).pipe(
      map((response) => {
        // Return the response data
        return response;
      }),
      catchError((error) => {
        console.error("API Error: ", error);
        throw error(error);
      })
    );
  }
}
