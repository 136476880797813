<!-- Container -->
<div [ngClass]="theme.getThemeClass()"  class="chart-container" [style.transform]="scaleValue" [style.transform-origin]="'0 0'">
  <!-- Chart -->
  <ngx-charts-bar-horizontal
    [view]="charts.viewBarChartAdmin"
    [scheme]="charts.colorSchemeBarChartAdmin"
    [results]="charts.singleBarChartAdmin"
    [gradient]="charts.gradientBarChartAdmin"
    [xAxis]="charts.showXAxisBarChartAdmin"
    [yAxis]="charts.showYAxisBarChartAdmin"
    [legend]="charts.showLegendBarChartAdmin"
    [legendTitle]="charts.legendTitleBarChartAdmin"
    [showXAxisLabel]="charts.showXAxisLabelBarChartAdmin"
    [showYAxisLabel]="charts.showYAxisLabelBarChartAdmin"
    [xAxisLabel]="charts.xAxisLabelBarChartAdmin"
    [yAxisLabel]="charts.yAxisLabelBarChartAdmin"
    (select)="charts.onSelectBarChartAdmin($event)"
    [legendPosition]="charts.legendPositionBarChartAdmin"
    [xAxisTickFormatting]="axisFormat"
    [noBarWhenZero]="charts.noBarWhenZeroAdmin"  >
  </ngx-charts-bar-horizontal>
</div>
