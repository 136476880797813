import { NgSelectComponent } from '@ng-select/ng-select';
import { Component, OnInit, ElementRef, Inject, forwardRef, ViewChild } from '@angular/core';
import { RoleService } from '../service/role.service';
import { CognitoService } from '../service/cognito.service';
import { TranslateService } from '@ngx-translate/core';
import { LocalStorageService } from '../local-storage.service';
import { ClientService } from '../service/client.service';
import { FormBuilder } from '@angular/forms';
import { ValidationService } from '../service/validation.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ThemeService } from '../service/theme.service';
import { NavigationService } from '../service/navigation.service';
import { firstValueFrom  } from 'rxjs';
import { WorkOrderTypes } from '../constants/work-order-types'
import { WorkOrderStatus } from '../constants/work-order-status'
import { WorkOrderService } from '../service/work-order.service';
import { SystemMessageService } from '../service/system-message.service';
import { IotService } from '../service/iot.service';
import { Bin, BinsService } from '../service/bins.service';
import { DistributorsService } from '../service/distributors.service';
import { DashboardService } from '../service/dashboard.service';


class DescriptionData {
  description: string = '';
}

interface thingSelect{
  thing_name: string;
  bin_id: string;
}

interface DeviceRelation {
  bin_id: string;
  client_id: string;
  distributor_id: string;
  thing_name: string;
  created: string;
  modified: string;
}

@Component({
  selector: 'app-work-order-create',
  templateUrl: './work-order-create.component.html',
  styleUrls: ['./work-order-create.component.css', '../../global-elements.css']
})
export class WorkOrderCreateComponent implements OnInit {
  @ViewChild('thing_select') thingSelect!: NgSelectComponent;
  @ViewChild('client_select') clientSelect!: NgSelectComponent;
  @ViewChild('bin_select') binSelect!: NgSelectComponent;
  // Constants import to be used @ select list input
  WorkOrderTypes = WorkOrderTypes;
  WorkOrderStatus = WorkOrderStatus;
  originWorkOrderTypes = WorkOrderTypes;

  // Retrieve current language selected from local storage
  languageStatus:string = this.localStorageService.getItem('language');

  // Variable to store the description work order text
  descriptionData: DescriptionData = new DescriptionData();

  // Variable to store the language selected value
  public selectedLanguage: string = "";

  // Flag to keep input validations status
  public validInputs: boolean = false;

  // Array Fetch important data


  public clientArray: any[] = [];
  public thingsClientArray: any[] = [];
  public binsArray: any[] = [];
  public thingNamesArray: thingSelect[] = [];
  public map: any = {};
  public binName: string = "";
  public clientLegalName: string = '';
  public binId: string = "";
  public things: any[] = [];
  public bin_name: string = '';

  private originBinsArray: any[] = [];
  private originClientsArray: any[] = [];
  private originThingsArray: any[] = [];

  private startTouchY: number = 0;
  public isSaving: boolean = false;

  // Variable used in work order when distributor create a work order from iot list
  public thing_name: string = '';
  public client_id: string = '';

  public clientData = {
    client_id: "",
    client_name: "",
    legal_name: "",
    phone_number: "",
    email: "",
    address: "",
    created: "",
    modified: "",
    distributor_id: ""
  };

  public binData: Bin = {
    bin_name: '',
    bin_id: '',
    bin_model_id: '',
    bin_usage: '',
    bin_gps: '',
    bin_location: '',
    bin_address: '',
    bin_postal_code: '',
    active: 0,
    created: 0,
    modified: 0,
    client_id: '',
    thing_name: '',
    market_segment: '',
    threshold: 80
  }

  public deviceRelations: DeviceRelation = {
    bin_id: '',
    client_id: '',
    distributor_id: '',
    thing_name: '',
    created: '',
    modified: ''
  }
  public comeFromIot: boolean = false;

  // Variable to store the result of a place selection from Google Places Autocomplete.
  // It can hold a valid PlaceResult object or be undefined if no place is selected.
  selectedPlace: google.maps.places.PlaceResult | undefined;

  // Variable used in show/hide html
  public showOnlyClientLabel: boolean = false;
  public showOnlyBinLabel: boolean = false;
  public showOnlyThingName: boolean = false;
  public hideClient: boolean = false;
  public hideBin: boolean = false;

  constructor(
    private localStorageService: LocalStorageService,
    @Inject(forwardRef(() => TranslateService)) private translate: TranslateService,
    public cognitoService: CognitoService,
    public roleService: RoleService,
    public clientService: ClientService,
    public validationService: ValidationService,
    private router: Router,
    public theme: ThemeService,
    public navigationService: NavigationService,
    public workOrderService: WorkOrderService,
    public systemMessage: SystemMessageService,
    private activatedRoute: ActivatedRoute,
    private iotService: IotService,
    private distributorService: DistributorsService,
    private binService: BinsService
  ) {

    this.cognitoService.confirmValidUser();
    this.roleService.getRoles();

    // Check if the user has selected a language in local storage
    //or use a default language
    if (this.languageStatus == null){
      // Set the default language to French
      translate.use('fr');
    } else {
      // Set the default language to the user's selected language
      translate.use(this.languageStatus);
    }
  }

  // Function called when the component is initialized
  async ngOnInit() {
    // Get the param that was pass from the iot list of thing selected to create a new work order directly on a device
    this.activatedRoute.params.subscribe(async params => {
      this.thing_name = params['thing'];
      if(this.thing_name){
        // If there's a thing_name in url, set the work order data thing_name then call the function to put all required
        this.workOrderService.workOrderData.thing_name = this.thing_name;
        await this.setWorkOrderFromIotList();
      }
    });
    this.systemMessage.buttonBlocked = false;
    // Retrieve the current user information
    this.workOrderService.workOrderData.created_by = await this.cognitoService.getCurrentUserSub();
    await this.cognitoService.getUserType();
    // Fetch the list of clients to populate the options for the select client input
    if(!this.thing_name || this.thing_name === undefined){
      this.getThingList();
      this.getBinList();

      // get client list only if the user type is not client
      if(this.cognitoService.userType !== 'client'){
        this.getClientList();
      }else{
        // If user type is client, set the work order client_id to the current client_id of the user
        this.workOrderService.workOrderData.client_id = this.cognitoService.clientId;
      }
    }
  }

  // Function called to reset client data
  resetClientData(){
    this.clientData = {
      client_id: "",
      client_name: "",
      legal_name: "",
      phone_number: "",
      email: "",
      address: "",
      created: "",
      modified: "",
      distributor_id: ""
    };
  }

  // Function called to reset bin data
  resetBinData(){
    this.binData = {
      bin_name: '',
      bin_id: '',
      bin_model_id: '',
      bin_usage: '',
      bin_gps: '',
      bin_location: '',
      bin_address: '',
      bin_postal_code: '',
      active: 0,
      created: 0,
      modified: 0,
      client_id: '',
      thing_name: '',
      market_segment: '',
      threshold: 80
    }
  }

  // Function called to set all required when user come from iot list
  async setWorkOrderFromIotList(){
    // Call the function that return all device relationship with bin, client and distributor infos
    ((await this.iotService.returnDeviceRelationship(this.thing_name)).subscribe((res: any) => {
      this.deviceRelations = JSON.parse(res.relation)[0];

      // Check if device relation have a bin
      if(this.deviceRelations.bin_id !== ''){
        // set bindata to the bin infos get by res
        this.binData = JSON.parse(res.bin)[0];

        this.showOnlyBinLabel = true; // This variable will make show only a label instead of a select for the bin
        this.workOrderService.workOrderData.bin_id = this.deviceRelations.bin_id; // Set the work order data bin_id
      }else{
        // If there's no bin associated, hide the bin section
        this.hideBin = true;
      }
      // Check if device relation have a client
      if(this.deviceRelations.client_id !== ''){
        // Set clientdate to ths client info get by res
        this.clientData = JSON.parse(res.client)[0];

        this.showOnlyClientLabel = true; // This variable will make show only a label instead of a select for the client
        this.workOrderService.workOrderData.client_id = this.deviceRelations.client_id; // Set the work order data client_id
      }else{
        // If there's no client associated, hide the client section
        this.hideClient = true;
      }

      this.comeFromIot = true; // This variable set the cancel button to the button that will return in iot list intead of work order list
      this.showOnlyThingName = true; // This variable will make show only the thing name label instead of the select
      this.workOrderService.workOrderData.thing_name = this.deviceRelations.thing_name; // Set the work order data thing name
      this.setTypeOfWorkAvailable();
    }));
  }

  // Function called to set type of work available
  setTypeOfWorkAvailable(){
    let elementToRemove: any = [];
    switch(true){
      case (this.binData.bin_id === '' && this.clientData !== undefined):
        elementToRemove = ['I', 'C'];
        // Set the new work order type available
        this.WorkOrderTypes = this.WorkOrderTypes.filter(type => !elementToRemove.includes(type.value));
        break;

      case (this.binData.bin_id !== '' && this.clientData === undefined):
        // Set an array of the work order type to remove
        elementToRemove = ['C','O'];

        // Set the new work order type available
        this.WorkOrderTypes = this.WorkOrderTypes.filter(type => !elementToRemove.includes(type.value));
        break;

      case (this.binData.bin_id === '' && this.clientData === undefined):
        // Set an array of the work order type to remove
        elementToRemove = ['C','I'];

        // Set the new work order type available
        this.WorkOrderTypes = this.WorkOrderTypes.filter(type => !elementToRemove.includes(type.value));
        break;
    }
  }

  // Function called to get all device from the user type
  async getThingList(){
    // switch and implement the thing array depend on the user type
    switch(this.cognitoService.userType){
      case 'muirwood':
        this.iotService.getAllDevicerelationships().subscribe((response) => {
          const tmparray: any = response;
          this.things = tmparray;
          this.originThingsArray = tmparray;
        });
        break;

      case 'distributor':
        await this.distributorService.getDeviceByDistributorId(this.cognitoService.distributorId).then((response) => {
          // Set thing array and old thing array
          this.things = response;
          this.originThingsArray = response;
        });
        break;

      case 'client':
        (await this.workOrderService.getThingNamesList(this.cognitoService.clientId)).subscribe((response: any) => {
          // Set thing array and old thing array
          this.things = response;
          this.originThingsArray = response;
        });
        break;
    }
  }

  // Function used to get all clients
  async getClientList() {
    try {
      // Use the clientService to fetch client data from an API
      this.clientService.getClients().subscribe(
        // Successful response callback
        (response: any) => {
          // Store the API response in the clientsData array
          this.clientArray = response;
          this.originClientsArray = this.clientArray;
        }
      );
    } catch (error) {
      // Handle and log any errors that occur during the process
      console.error("Error: ", error);
    }
  }

  // Function to update the deadline and date data based on user input
  updateDeadline(event: any) {
    // Store the current date and time
    const currentDate = new Date();

    // Get the date and time string from the user input
    const selectedDateTimeString = event.target.value;

    // Create an instance of Date using the user-selected date and time
    const selectedDateTime = new Date(selectedDateTimeString);

    // Update the work order deadline with the selected date and time in milliseconds
    this.workOrderService.workOrderData.work_order_deadline = selectedDateTime.getTime();

    // Update the work order datetime with the current date and time in milliseconds
    this.workOrderService.workOrderData.work_order_datetime = currentDate.getTime();

    // Update the 'created' property with the transformed current date in 8-digit format
    this.workOrderService.workOrderData.created = currentDate.getTime();
  }

  // Function to adjust a label as needed, called by html
  getTranslatedLabel(label: string): string {
    // Logic to adjust the label as needed
    if (label.startsWith('_')) {
        // Remove the underscore and capitalize the first letter
        return label.slice(1, 2).toUpperCase() + label.slice(2);
    }
    // Return the label unchanged if it doesn't start with '_'
    return label;
  }

  // Function used to validate that all input are well fill
  validateFormInputs() {
    // Initialize the flag for valid inputs
    this.validInputs = false;

    // Extract data from the work order service for validation
    const createWorkOrderData = [
      this.workOrderService.workOrderData.work_order_deadline,
      this.workOrderService.workOrderData.work_order_datetime,
      this.workOrderService.workOrderData.work_order_type,
      this.workOrderService.workOrderData.created,
      this.workOrderService.workOrderData.work_order_status,
      this.descriptionData.description,
      this.workOrderService.workOrderData.created_by,
      this.workOrderService.workOrderData.creator_entity_type,
      this.workOrderService.workOrderData.creator_entity_id,
      this.workOrderService.workOrderData.thing_name
    ];

    // Check if any variable is empty, null, or undefined
    for (const item of createWorkOrderData) {
      if (item === undefined || item === null || item === '') {
        // Return false if any variable fails validation
        return false;
      }
    }
    // Return true if all variables pass validation
    return true;
  }

  // Function triggered when touch starts for scrolling
  startScroll(event: TouchEvent) {
    // Store the initial Y coordinate of the touch
    this.startTouchY = event.touches[0].clientY;
  }

  // Function to handle scrolling during touch movement
  scrollTextarea(event: TouchEvent) {
    // Calculate the change in Y coordinate since the last touch event
    const deltaY = event.touches[0].clientY - this.startTouchY;

    // Update the startTouchY for the next calculation
    this.startTouchY = event.touches[0].clientY;

    // Get the textarea element by its ID ('descricao')
    const textarea = document.getElementById('descricao') as HTMLTextAreaElement;

    // Update the scrollTop property of the textarea to simulate scrolling
    textarea.scrollTop += deltaY;
  }

  // Function triggered when touch ends, resetting the startTouchY
  stopScroll() {
    // Reset the startTouchY to 0 when touch ends
    this.startTouchY = 0;
  }

  // Function triggered when Create button is clicked
  async createBtn() {
    // Set the description from the input to the work order data
    this.workOrderService.workOrderData.description = this.descriptionData.description;

    // If the work order status is empty, set it to "O" (assuming it represents a default value)
    if (this.workOrderService.workOrderData.work_order_status === "") {
      this.workOrderService.workOrderData.work_order_status = "O";
    }

    // Validate the form inputs before proceeding
    if (this.validateFormInputs()) {

      try {
        // Create a new work order and wait for the result
        const result = await firstValueFrom(this.workOrderService.createWorkOrder());

        // Set a local storage message to reuse it in work order list of the proper user
        this.localStorageService.addItem('workOrderCreate', 'success');
        this.isSaving = true;
        setTimeout(() => {
          this.isSaving = false;
          // Call the function to send back the user to his proper work order list
          this.sendBackToWorkOrderList();
        }, 2000);

      } catch (error) {
        // Set a local storage message to reuse it in work order list of the proper user
        this.localStorageService.addItem('workOrderCreate', 'fail');
        console.error('Error creating work order:', error);

        this.isSaving = true;
        setTimeout(() => {
          this.isSaving = false;
          // Call the function to send back the user to his proper work order list
          this.sendBackToWorkOrderList();
        }, 2000);
      }
    } else {
      this.systemMessage.buttonBlocked = true;

      // Display error message for invalid inputs and log a simple message
      this.systemMessage.selectRibbon('danger',"createOrderErrorInvalidInputs")
      console.error('Empty input');
    }
  }

  // function called to send back the user to his proper work order list
  sendBackToWorkOrderList(){
    switch(this.cognitoService.userType){
      case 'muirwood':
        this.router.navigate(['/work-order-list']);
        break;

      case 'distributor':
        this.returnToDistributorDashboard();
        break;

      case 'client':
        this.returnToClientWorkOrderList();
        break;
    }
  }

  // Function used by distributor to return to his dashboard page
  returnToDistributorDashboard(){
    this.router.navigate(['/distributor-work-order-dashboard']);
  }

  // Function used by client to return to his dashboard
  returnToClientDashboard(){
    const from = sessionStorage.getItem('From')
    if(from && from != null){
      this.router.navigate([`/${from}`])
      sessionStorage.removeItem('From')
    } else{
      this.router.navigate(['/client-work-order-dashboard']);
    }

  }

  // Function called to return client to is work order list
  returnToClientWorkOrderList(){
    this.router.navigate(['/client-work-order-dashboard']);
  }

  // function to reload page when cancel button is clicked
  pageReload() {
    this.router.navigate(['/work-order-list']); // Redirect to the '/work-order-list'
  }

  // Function called to retur to distributor iot list
  returnToDistributorIot(){
    this.router.navigate(['/distributor-iot-dashboard']);
  }

  unselectThing(): void {
    this.thingSelect.clearModel();
  }

  // function called when user change thing select
  selectedThingChange(event: any){
    // set an instance of the selected thing
    const thing = this.things.find((thing: any) => thing.thing_name === event);

    // Set the thing_name at thing_name of the select
    this.workOrderService.workOrderData.thing_name = event;

    if(thing){
      // If there's client and bin, set there value in work order data
      if(thing.bin_id !== "" && thing.bin_id !== null && thing.bin_id !== undefined){
        this.workOrderService.workOrderData.bin_id = thing.bin_id;
      }else{
        this.workOrderService.workOrderData.bin_id = "";
      }
      if(thing.client_id !== "" && thing.client_id !== null && thing.client_id !== undefined){
        this.workOrderService.workOrderData.client_id = thing.client_id;
      }else{
        this.workOrderService.workOrderData.client_id = "";
      }

      // Disable bin and client select
      this.binSelect.setDisabledState(true);
      this.clientSelect.setDisabledState(true);
    }else{
      // Enable bin and client select
      this.binSelect.setDisabledState(false);
      this.clientSelect.setDisabledState(false);

      // Call the function to reset the arrays to there original form
      this.setOriginsArray();

      // Remove previous data in work order data
      this.workOrderService.workOrderData.bin_id = "";
      this.workOrderService.workOrderData.client_id = "";
    }
    // Call the function to implement the work order type select
    this.setTypeOfWorkAvailable();
  }

  // Function to fetch data (Thing Names and Bins IDs) based on the client ID or distributor ID
  async selectedClientChange(event: any) {
    // set an instance of client
    const client = this.clientArray.find((client: any) => client.client_id === event);

    // Set the client_id at client_id of the select
    this.workOrderService.workOrderData.client_id = event;

    if(client){
      this.clientData = client;
      // Reset bin and things array to it's original state
      this.things = [...this.originThingsArray];
      this.binsArray = [...this.originBinsArray];

      // Set the things and bins array for the selected client
      this.things = this.things.filter(thing => thing.client_id === this.workOrderService.workOrderData.client_id);
      this.binsArray = this.binsArray.filter(bin => bin.client_id === this.workOrderService.workOrderData.client_id);
    }else{
      // Reset client data and set client array to is original form
      this.resetClientData();
      this.setOriginsArray();

      // Remove previous data in work order data
      this.workOrderService.workOrderData.thing_name = '';
      this.workOrderService.workOrderData.bin_id = '';
    }
    // Call the function to implement the work order type select
    this.setTypeOfWorkAvailable();
  }

  // Function called when user change the select of bins
  selectedBinChange(event: any){
    // set an instance of bin
    const bin = this.binsArray.find((bin: any) => bin.bin_id === event);

    // set the bin_id at bin_id of the select
    this.workOrderService.workOrderData.bin_id = event;

    if(bin){
      this.binData = bin;

      // If there's client and bin, set there value in work order data
      if(bin.client_id !== ""){
        this.workOrderService.workOrderData.client_id = bin.client_id;
      }
      if(bin.thing_name !== ""){
        this.workOrderService.workOrderData.thing_name = bin.thing_name;
      }
      // Set the two other select at read-only and client select only if user is not a client
      if(this.cognitoService.userType !== 'client'){
        this.clientSelect.setDisabledState(true);
      }
      this.thingSelect.setDisabledState(true);
    }else{
      // reset the bin data
      this.resetBinData();

      // remove the read-only on select and client select only if user is not a client
      if(this.cognitoService.userType !== 'client'){
        this.clientSelect.setDisabledState(false);
      }
      this.thingSelect.setDisabledState(false);

      // reset the arrays at there original form
      this.setOriginsArray();

      // Remove previous data in work order data
      this.workOrderService.workOrderData.thing_name = '';
      this.workOrderService.workOrderData.client_id = '';
    }
    // Call the function to implement the work order type select
    this.setTypeOfWorkAvailable();
  }

  // Function called to reset original arrays
  setOriginsArray(){
    // Reset bin and things array to it's original state
    this.things = [...this.originThingsArray];
    this.binsArray = [...this.originBinsArray];
    this.clientArray = [...this.originClientsArray];
  }

  // Functio caled to get all the bin list and filter it with only those who have devices
  async getBinList(){
    // Get all the bins details
    await this.binService.setBinDetailArray().then((response) => {
      // filter the response with the bins that only have things ////// For now we assumed that user use work order for devices
      this.binsArray = response.filter((bin: any) => bin.thing_name !== "" && bin.thing_name !== null);
      this.originBinsArray = this.binsArray;
    });
  }
}

