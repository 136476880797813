<app-system-message></app-system-message>

<div [ngClass]="theme.getThemeClass()" class="container-fluid">
  <section [ngClass]="theme.getThemeClass()" class="row flex-lg-nowrap">
    <article [ngClass]="theme.getThemeClass()" class="col height100V">


  <div class="toggle-container vam">
    <label class="MFAAuthenticatedDiv marginTop">
      <h2>{{ "alertPreferences" | translate }}</h2>
    </label>
    <a [ngClass]="theme.getThemeClass()" class="advanced-option-a" (click)="this.openAvancedOptionModal()">
      <div class="advanced-option-btn">
        <label class="advanced-option-label">{{ "advancedOptions" | translate }}</label>
      </div>
    </a>
    <label class="toggle-container-label fontSubtitle">
      {{ "titleSMSAlertPreference" | translate }}
    </label>
    <ng-container *ngFor="let alert of alertPreferenceSMS" class="test">
      <div class="role-container mb-2 mt-2 disable-toggle">
        <div class="custom-control custom-switch cs-container">
          <!-- Rounded switch -->
          <label class="switch">
            <input *ngIf="alert.label"
              type="checkbox"
              [value]="alert.value"
              (change)="alertToggle(alert.label)"
              name="selected-role"
              [(ngModel)]="alert.value"
            />
            <span class="slider round"></span>
          </label>
        </div>
        <label *ngIf="alert.label" class="role-label ml-2">{{ alert.label | translate }}</label>
      </div>
    </ng-container>
    <label class="toggle-container-label fontSubtitle">
      {{ "titleEmailAlertPreference" | translate }}
    </label>
    <ng-container *ngFor="let alert of alertPreferenceEmail" class="test">
      <div class="role-container mb-2 mt-2" [ngClass]="{'disable-toggle': alert.label === 'workorder_update_email'}">
        <div class="custom-control custom-switch cs-container">
          <!-- Rounded switch -->
          <label class="switch">
            <input *ngIf="alert.label"
              type="checkbox"
              [value]="alert.value"
              (change)="alertToggle(alert.label)"
              name="selected-role"
              [(ngModel)]="alert.value"
            />
            <span class="slider round"></span>
          </label>
        </div>
        <label *ngIf="alert.label" class="role-label ml-2">{{ alert.label | translate }}</label>
      </div>
    </ng-container>
    <nav class="row mb-2">
      <div class="col d-flex justify-content-center">
        <a (click)="backToUserEdit()"
          [ngClass]="theme.getThemeClass()"
          class="btn"
          >
          {{ "clientcreateFormBtnCancel" | translate }}
      </a>
      <a (click)="saveAlertPreferences()"
          [ngClass]="theme.getThemeClass()"
          class="btn"
          >
          {{ "loginFNewPasswordBtnConfirmNewPw" | translate }}
      </a>
      </div>
    </nav>
  </div>
</article>
</section>
</div>

<!--End of Alert Group-->

<app-user-edit-modal *ngIf="this.userEditModalService.showUserEditModal" class="user-edit-modal"></app-user-edit-modal>
