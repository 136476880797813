import { Component, OnInit, HostListener, Inject, forwardRef, ViewChildren, ElementRef, QueryList, ViewRef } from '@angular/core';
import { environment } from '../environments/environment';
import { CognitoService } from '../service/cognito.service';
import { ValidationService } from '../service/validation.service';
import { ThemeService } from '../service/theme.service';
import { Roles } from '../constants/roles';
import { ActivatedRoute, Router } from '@angular/router';
import { lastValueFrom } from 'rxjs';
import { LocalizationService } from '../service/localization.service';
import { TranslateService } from '@ngx-translate/core';
import { SystemMessageService } from '../service/system-message.service';
import { RoleService } from '../service/role.service';

interface Role {
  value: string;
  label: string;
}

interface UserRole {
  user: string;
  role: number;
  label?: string;
}
@Component({
  selector: 'app-user-update-operator',
  templateUrl: './user-update-operator.component.html',
  styleUrl: './user-update-operator.component.css'
})
export class UserUpdateOperatorComponent implements OnInit {

  public userRolesFiltered: any;
  public Roles = Roles;
  public cognitoUsersArray: any[] = [];
  private userRoles: any;
  private userSub: string = '';
  private userUsername: string = '';
  private userType = '';
  public userFound: any;
  private validateRolesCheckBox: boolean = false;
  private userRolesNumber: any;
  private rolesToUncheck: string[] = [];

  constructor(public cognitoService: CognitoService,
              public validationService: ValidationService,
              public theme: ThemeService,
              private router: Router,
              private route: ActivatedRoute,
              @Inject(forwardRef(() => TranslateService)) private translate: TranslateService,
              public localizationService: LocalizationService,
              private systemMessageService: SystemMessageService,
              public roleService: RoleService){

    this.route.queryParams.subscribe(params => {
      this.userSub = params['userSub'];
      this.userUsername = params['userUsername'];
      this.userType = params['userType'];
    });
  }

  // Decorator @HostListener listens to the 'input' event on the specified target element
  @HostListener('input', ['$event.target']) onInput(input: HTMLInputElement): void {
    // Check if the input element has the class 'telefone-input'
    if (input.classList.contains('telefone-input')) {
      // Remove all non-numeric characters from the input value
      const value = input.value.replace(/\D/g, '');

      // Check if the value has a length of 10 characters or less
      if (value.length <= 12) {
        // Format the phone number as (000) 000-0000
        input.value = this.validationService.formatPhoneNumber(value);
      } else {
        // If the value is longer than 10 digits, limit the input to 10 digits
        input.value = input.value.slice(0, 12);
      }
    }
  }

  // Returns the specified elements from the DOM, in this case the roles checkboxes. Used to manage the checkboxes
  @ViewChildren('rolesSwitch') rolesSwitch!: QueryList<ElementRef>
  
  // Asynchronous method to initialize the component
  async ngOnInit(): Promise<void> {
    // Confirm and get role for the current user
    await this.cognitoService.confirmValidUser();
    await this.cognitoService.getCurrentRole([environment.users.role.administrator], [environment.users.superAdmin]);
    await this.roleService.getRoles();

    // Reset user role array in role service
    this.roleService.userRoles = [];

    // Clear any lingering data related to user creation
    this.cognitoService.clearCognitoUserData(); // clear last client create data
    
    // If the user is found, populate user data
    this.cognitoService.getUserData(this.userSub).subscribe((res:any)=>{
      const user = JSON.parse(JSON.stringify(res))[0];
      console.log(user);
      // Populate the cognito user data
      this.populateCognitoUserData(user);
      console.log(this.cognitoService.cognitoUserData)
    })
    
    // Assign the array of Cognito users to a local variable
    this.cognitoUsersArray = this.cognitoService.cognitoUsersArray;

    // Find the user with a matching 'sub' (subject) in the Cognito user array
    // If the user is found, populate user data
   
    try {
      // Retrieve user roles for the current user
      const sub = this.userSub;
      const response = await lastValueFrom(await this.roleService.getRolesForUser(sub));
      this.userRolesNumber = response;
      console.log(response);

      // If user roles exist and are an array, process and filter them
      if (this.userRolesNumber && Array.isArray(this.userRolesNumber)) {
        // Map user roles to their corresponding labels
        this.userRoles = this.userRolesNumber.map((userRole: any) => {
          const roleDefinition = Roles.find((role: Role) => role.value === userRole.role);
          return roleDefinition ? { user: sub, role: Number(roleDefinition.value), label: roleDefinition.label } : null;
        }).filter(role => role !== null) as UserRole[];
      }
      // Set userRoles in role service as same of the userRoles array of the component
      this.roleService.userRoles = this.userRoles;
    } catch (error) {
      // Handle errors during role retrieval
      console.error('Error:', error);
    }
    // Update user roles based on the processed data
    this.updateUserRoles();
    
    // Call the function in localization service to implement the auto complete of the address input
    this.localizationService.initAutocomplete();

    // Function called automatically when a change is made in role service by chnaging a role switch
    this.roleService.userRoleChange$.subscribe(() => {
      this.userRoles = this.roleService.userRoles;
    });
  }

  // Function called when user save the change made
  async saveUser(){
    if(this.userRoles && this.userRoles.length > 0) {
      this.validateRolesCheckBox = true;
    }
    if(this.localizationService.autoCompletionReturnAddress !== ''){
      // Set the address of the cognito user data
      this.cognitoService.cognitoUserData.address = this.localizationService.autoCompletionReturnAddress;
    }

    // Check validation for all input fields
    this.checkValidationAllInputs();
    //Check if any of the validation flags are false, indicating validation errors
    if (
      !this.validationService.givenNameValid ||
      !this.validationService.familyNameValid ||
      !this.validationService.clientPhoneNumberValid ||
      !this.validationService.clientAddressValid ||
      !this.validateRolesCheckBox) {
        if(!this.validateRolesCheckBox){
          // Throw an error message to the user if there's no roles selected
          this.systemMessageService.selectRibbon('danger', 'leastOneRoleSelected');
        }else{
          // Throw an error message to the user if there's a invalid value
          this.systemMessageService.selectRibbon('danger', 'fieldEmptyOrIncorrect');
        }

        // will update the attributes of the cognito user if all values are valid
      } else {
        // Update user attributes in cognito if there is no errors in validation
        const updateUserResult = await this.cognitoService.updateUserAttributesCognito(this.cognitoService.cognitoUserData.username)
        // delete all outdated roles from user
        try {
          if(updateUserResult){
            // Call the function to delete all user roles
            await this.roleService.deleteAllUserRole(this.userRoles[0].user);  

            try {
              // Set the new role to the user in the DB
              await this.roleService.updateUserRole(this.userRoles[0].user, this.userRoles);
            } catch (error) {
              console.error('Error updating user role:', error);
            }

            // After update role, set a success ribbon message and re-route user to the user-list
            this.systemMessageService.selectRibbon('success', 'alert-success-generic-message');
            sessionStorage.setItem('previous', 'user-List')
            this.router.navigate(['/admin']); // Redirect to the '/admin' route after the delay
          }
        } catch(error) {
          console.error('Error deleting all user role:', error);
        }
      }
  }

  // Function that disable user access to the app
  async disableUser() {

    // Get the translation of the confirmation message
    const confirmationMessage$ = this.translate.get('questionEnable/DisableUser');
    const confirmationMessage = await lastValueFrom(confirmationMessage$);

    // Display a confirmation dialog to the user
    const isConfirmed = window.confirm(confirmationMessage);

    // Check if the user confirmed the deletion
    if (isConfirmed) {
      try {
        // If successful, proceed to delete the user
        const disabledResult = await this.cognitoService.disableUserAccess(this.cognitoService.cognitoUserData.username);

        if(disabledResult === 'success'){
          this.systemMessageService.selectRibbon('success', 'alert-success-generic-message');
          sessionStorage.setItem('from', 'user-update');
          sessionStorage.setItem('previous', 'user-List')
          this.router.navigate(['/admin']); // Redirect to the '/admin' route after the delay
        } else {
          this.systemMessageService.selectRibbon('danger', 'alert-danger-generic-message');
        }
      } catch (error) {
        // Handle any errors that occur during the deletion process
        console.error('Error deleting user roles:', error);
        // Handle the error appropriately, depending on your use case.
      }
    }
  }

  // Function that enable user acces to the app
  async enableUser(){
    // Get the translation of the confirmation message
    const confirmationMessage$ = this.translate.get('questionEnable/DisableUser');
    const confirmationMessage = await lastValueFrom(confirmationMessage$);

    // Display a confirmation dialog to the user
    const isConfirmed = window.confirm(confirmationMessage);

    // Check if the user confirmed the deletion
    if (isConfirmed) {
      try {
        // If successful, proceed to delete the user
        const enabledResult = await this.cognitoService.enableUserAcces(this.cognitoService.cognitoUserData.username);
        if(enabledResult === 'success'){
          this.systemMessageService.selectRibbon('success', 'alert-success-generic-message');
          sessionStorage.setItem('from', 'user-update');
          sessionStorage.setItem('previous', 'user-List')
          this.router.navigate(['/admin']); // Redirect to the '/admin' route after the delay
        } else {
          this.systemMessageService.selectRibbon('danger', 'alert-danger-generic-message');
        }
      } catch (error) {
        // Handle any errors that occur during the deletion process
        console.error('Error deleting user roles:', error);
        // Handle the error appropriately, depending on your use case.
      }
    }
  }

  // Function called from the ngOnInt function to populate cognito user data
  populateCognitoUserData(user:any){
    // Populate user data from the found Cognito user
    this.cognitoService.cognitoUserData.username = user.username;
    this.cognitoService.cognitoUserData.given_name = user.given_name;
    this.cognitoService.cognitoUserData.family_name = user.family_name;
    this.cognitoService.cognitoUserData.email = user.email;
    this.cognitoService.cognitoUserData.phone_number = this.validationService.formatPhoneNumber(user.phone_number);
    this.cognitoService.cognitoUserData.address = user.address;
    this.cognitoService.cognitoUserData.custom_current_role = user.current_role;
    this.cognitoService.cognitoUserData.sub = user.user;
    this.cognitoService.cognitoUserData.enabled = user.enable;
    this.cognitoService.cognitoUserData.custom_user_type = user.user_type;
    this.cognitoService.cognitoUserData.custom_client_id = user.client_id || '';
    this.cognitoService.cognitoUserData.custom_distributor_id = user.distributor_id || '';
    this.cognitoService.cognitoUserData.custom_operator_id = user.operator_id || '';
    this.cognitoService.cognitoUserData.client_name = user.client_name || '';
  }

  // runs a validation test checking every inputs
  checkValidationAllInputs() {

    // Check if given_name is not empty, its length is greater than 16,
    // or it doesn't match the alphanumeric pattern
    this.validationService.validateGivenName(this.cognitoService.cognitoUserData.given_name);

    // Check if family_name is not empty, its length is greater than 16,
    // or it doesn't match the alphanumeric pattern
    this.validationService.validateFamilyName(this.cognitoService.cognitoUserData.family_name);

    // Check if phoneNumber is not empty and its length is less than 10 or if it's empty
    this.validationService.validatePhoneNumber(this.cognitoService.cognitoUserData.phone_number);

    // Check if the address is empty
    this.validationService.validateAddress(this.cognitoService.cognitoUserData.address);

    // Define a mapping of error codes to corresponding error messages
    const errorMappings: Record<string, string> = {
      'clientInputError': 'invalidClientSelection',
      'roleSelectionInvalid': 'invalidRoleSelection',
      'givenNameInvalid': 'invalidGivenNameError',
      'familyNameInvalid': 'invalidFamilyNameError',
      'clientPhoneInvalid': 'invalidInput',
      'clientAddressInvalid': 'invalidAddress',
      'usernameNotUnique': 'usernameInUse',
    };

    // Retrieve the first validation error from the array
    const validationError = this.validationService.validationErrorArray[0];

    // Check if the validation error code exists in the mapping
    if (errorMappings[validationError]) {
      // If so, display a danger ribbon message with the corresponding key
      this.systemMessageService.selectRibbon('danger', errorMappings[validationError]);
    }
  }

  // Function that go back to admin component
  cancel(){
    sessionStorage.setItem('previous', 'user-List')
    this.router.navigate(['/admin']); // Redirect to the '/admin' route
  }

  // Function called to implement the labels for userRoles object array
  updateUserRoles(): void {
    this.userRoles = this.userRoles.map(this.roleService.addLabel);
  }

  // Function called from html to know witch roles is selected
  isRoleChecked(roleLabel: string): boolean {
    return this.userRoles && this.userRoles.some((role: { label?: string }) => role.label === roleLabel);
  }

  // Function that avoid submitting the page when user press enter at the end of inputting address in address input
  onInputAddressKeydown(event: KeyboardEvent): void {
    if(event.key === 'Enter'){
      event.preventDefault(); // Don't submit the page
    }
  }

  // Function to change the language based on user selection
  changeLanguage(language: string) {
    // Use the 'translate' service to set the language to the specified 'language'
    this.translate.use(language);
  }

  /**
   *  Function use for operators so they can be only one of those roles: driver, dispatch or maintenance.
   * @param role 
   */
  checkRolesRestriction(role: string){
    this.roleService.toggleRoleUpdate(role, this.cognitoService.cognitoUserData.sub)
    switch(role){
      case '_driver':
        // Array of roles we want to uncheck if we select driver
        this.rolesToUncheck = ['_dispatch', '_maintenance'];
        // Manage unchecking and removing roles from the userRoles
        this.manageRolesRestriction(this.rolesToUncheck);
        break;

      case '_dispatch':
        // Array of roles we want to uncheck if we select dispatch
        this.rolesToUncheck = ['_driver', '_maintenance'];
        // Manage unchecking and removing roles from the userRoles
        this.manageRolesRestriction(this.rolesToUncheck);
        break;

      case '_maintenance':
        // Array of roles we want to uncheck if we select maintenance
        this.rolesToUncheck = ['_driver', '_dispatch'];
        // Manage unchecking and removing roles from the userRoles
        this.manageRolesRestriction(this.rolesToUncheck);
        break;
    }
  }

  /**
   * Manage uncheck of sliders and removing roles from the userRoles array. Takes a string[] of the roles we want to uncheck
   * and remove from the userRoles.
   * @param roles 
   */
  manageRolesRestriction(roles: string[]){
    for(const role of roles){
      this.rolesSwitch.forEach(element => {
        const checkbox = element.nativeElement as HTMLInputElement;
        // Uncheck slider and remove role from userRoles if we click on driver or dispatch and it is check
        if(checkbox.value === role && checkbox.checked === true){
          checkbox.checked = false;
          // Remove role from userRoles
          this.roleService.toggleRoleUpdate(checkbox.value, this.cognitoService.cognitoUserData.sub)
        }
      })
    }
  }

  /**
   * Simple function that return true or false depending if we want to NOT display a role in the HTML role container
   * @param role
   * @returns 
   */
  operatorRolesToBeDisplayed(role : string){
    switch(role){
      case '_distributor':
      case '_client':
        return false;
        break;
      default:
        return true;
        break;
    }
  }
}
