import { ClientService } from './../service/client.service';
import { Component, HostListener, OnInit, Inject, forwardRef } from '@angular/core';
import { LocalStorageService } from '../local-storage.service';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { CognitoService } from '../service/cognito.service';
import { RoleService } from '../service/role.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ValidationService } from '../service/validation.service';
import { environment } from '../environments/environment';
import { ThemeService } from '../service/theme.service';
import { NavigationService } from '../service/navigation.service';
import { SystemMessageService } from '../service/system-message.service';
import { LocalizationService } from '../service/localization.service';
import { lastValueFrom } from 'rxjs';
import { BinArrayItem, DashboardService } from '../service/dashboard.service';
import { RelationshipModalService } from '../service/relationship-modal.service';

interface Item {
  thing_name: string;
  bin_id: string;
  client_id: string;
  distributor_id: string;
}


@Component({
  selector: 'app-client-edit',
  templateUrl: './client-edit.component.html',
  styleUrls: ['./client-edit.component.css']
})

export class ClientEditComponent implements OnInit {

  public form: FormGroup // Form for client data

  // Variable to store the language selected value
  public selectedLanguage: string = "";

  public successMessage: boolean = false; // Flag for success message display

  // Retrieve current language selected from local storage
  languageStatus:string = this.localStorageService.getItem('language');

  // Variables to store edited client information
  public client_id: string  = "a";
  public phoneNumberWithMask: any = ""
  public relationship: BinArrayItem[] = []
  public filteredRelationships: BinArrayItem[] = []

  constructor(
    private localStorageService: LocalStorageService,
    @Inject(forwardRef(() => TranslateService)) @Inject(forwardRef(() => TranslateService)) private translate: TranslateService,
    private cognitoService: CognitoService,
    public roleService: RoleService,
    public clientService: ClientService,
    private formBuilder: FormBuilder,
    public validationService: ValidationService,
    private router: Router,
    private activedRoute: ActivatedRoute,
    public theme: ThemeService,
    public navigationService: NavigationService,
    public systemMessage: SystemMessageService,
    public localizationService: LocalizationService,
    public dashboardService: DashboardService,
    public relationshipModalService: RelationshipModalService

  ) {

    // Create a form using the FormBuilder
    this.form = this.formBuilder.group({
      // Define form controls and their initial values
      client_name: ['', Validators.required],
      legal_name: [''],
      email: ['', [Validators.required, Validators.email]],
      phone_number: [''],
      address: ['']
    });

    // Check if the user has selected a language in local storage
    //or use a default language
    if (this.languageStatus == null){
      // Set the default language to French
      translate.use('fr');
    } else {
      // Set the default language to the user's selected language
      translate.use(this.languageStatus);
    }
  }

  // Function called that delete a client
  async deleteClient() {
    // Filter items based on client ID
    this.filterItemsByClientId();

    // Get confirmation message for client deletion
    const confirmationMessage$ = this.translate.get('confirmationQuestionDeleteClient');
    const confirmationMessage = await lastValueFrom(confirmationMessage$);
    let isConfirmed = false;

    // Check if there are related items or users associated with the client
    if(this.filteredRelationships.length > 0 || this.relationshipModalService.userQuantity > 0) {

      // Show modal if there are related items or users
      this.relationshipModalService.showModal = true;
    } else {
      // Display a confirmation dialog to the user
      isConfirmed = window.confirm(confirmationMessage);
    }
    // If user confirms deletion
    if (isConfirmed) {

      try {
        // Call onDeleteClient to initiate client deletion
        this.clientService.onDeleteClient().subscribe(success => {

        });

        // Iterate through each user to disable their access
        for(let i = 0; this.clientService.userNameArray.length > 0; i++){
          // Disable user access via Cognito service
          const disabledResult = await this.cognitoService.disableUserAccess(this.clientService.userNameArray[i]);
        }

        this.localStorageService.addItem('entitiesContent', 'clients')
        this.router.navigate(['/entities']); // Redirect to the '/client-list' route after the delay

      } catch (error) {
        console.error(error)
        // Display an informational message related to client deletion failure
        this.localStorageService.addItem('entitiesContent', 'deleteClientFail');
        this.router.navigate(['/entities']); // Redirect to the '/client-list' route
      }
    }
  }

  async ngOnInit(): Promise<void> {
    this.cognitoService.confirmValidUser();
    this.roleService.getRoles();

    // Check if user have the roles to get in this page
    this.cognitoService.getCurrentRole([environment.users.role.administrator], [environment.users.superAdmin, environment.users.standardUser], true, this.roleService.roles);

    // Subscribe to route parameter changes using ActivatedRoute
    this.activedRoute.params.subscribe((param: any) => {
      this.client_id = param.id; // Retrieve the 'id' parameter
      this.client_id = param['id']; // Alternative syntax to retrieve the 'id' parameter
    })

    // get array of user from client
    try {
      const res = await this.clientService.getUsersByClientId(this.client_id.toString());

      for(let i = 0; res.length > i; i++ ) {
        this.clientService.userNameArray.push(res[i].username)
      }

      this.relationshipModalService.userQuantity = res.length;

    } catch (error) {
      console.error('Error:', error);
    }

    // get device relationship
    // try {
    //   const relationships: BinArrayItem[] = await lastValueFrom(this.dashboardService.getRelationships());

    //   for(let i = 0; this.dashboardService.relationships.length > i; i++ ) {
    //     this.relationship.push(this.dashboardService.relationships[i])
    //   }

    // } catch (error) {
    //   console.error('Error:', error);
    // }

    // Call a function to fetch client data
    this.getClientList()

    // Call a function to initialize Google Places Autocomplete
    this.localizationService.initAutocomplete();
  }

  /**
  * Function to filter items by client ID.
  */
  filterItemsByClientId(): void {

    // Filtering relationships based on distributor ID
    this.filteredRelationships = this.relationship.filter(item => item.client_id === this.client_id.toString());

    // Iterating through filtered relationships
    this.filteredRelationships.forEach(item => {
        if (item.thing_name !== '') {
          // Incrementing devices quantity if thing name is not empty
          this.relationshipModalService.devicesQuantity++;
        }
        if (item.bin_id !== '') {
          // Incrementing bin quantity if bin ID is not empty
          this.relationshipModalService.binQuantity++;
        }
    });
  }

  // Decorator @HostListener listens to the 'input' event on the specified target element
  @HostListener('input', ['$event.target']) onInput(input: HTMLInputElement): void {
    // Check if the input element has the class 'telefone-input'
    if (input.classList.contains('telefone-input')) {
      // Remove all non-numeric characters from the input value
      const value = input.value.replace(/\D/g, '');

      // Check if the value has a length of 10 characters or less
      if (value.length <= 10) {
        // Format the phone number as (000) 000-0000
        input.value = this.formatPhoneNumber(value);
      } else {
        // If the value is longer than 10 digits, limit the input to 10 digits
        input.value = input.value.slice(0, 10);
      }
    }
  }

  // Function called ot return all information from a selected client
  getClientList() {
    try{
      // Use the clientService to fetch client data from an API
      this.clientService.getClients().subscribe(
        // Successful response callback
        (response: any) => {

          // Assuming this.client_id is the ID you want to find
          const clientToFind = this.client_id;

          // Use the find function to search for the client with the specified client_id
          const foundClient = response.find((client: any) => client.client_id === clientToFind);

          if (foundClient) {
            // You can now work with the foundClient as needed
            this.clientService.clientData.client_id = this.client_id;
            this.clientService.clientData.client_name = foundClient.client_name;
            this.clientService.clientData.legal_name = foundClient.legal_name;
            this.clientService.clientData.phone_number = foundClient.phone_number;
            this.clientService.clientData.email = foundClient.email;
            this.clientService.clientData.address = foundClient.address;
            this.phoneNumberWithMask = this.formatPhoneNumber(this.clientService.clientData.phone_number);
          }
        }
      );
    }
    catch(error){
      console.error("Error: ", error);
    }
  }

  // Function called to validate input for the form submition
  checkValidationAllInputs() {
    // Check if clientName is not empty, its length is greater than 16,
    // or it doesn't match the alphanumeric pattern
    this.validationService.validateClientName(this.clientService.clientData.client_name);

    // Check if clientLegalName is not empty, its length is greater than 16,
    // or it doesn't match the alphanumeric pattern
    this.validationService.validateClientLegalName(this.clientService.clientData.legal_name);

    // Check if the clientEmail is not a valid email or is empty
    this.validationService.validateClientEmail(this.clientService.clientData.email);

    // Check if phoneNumber is not empty and its length is less than 10 or if it's empty
    this.validationService.validatePhoneNumber(this.clientService.clientData.phone_number);

    // Check if the address is empty
    this.validationService.validateAddress(this.clientService.clientData.address);

    // Define a mapping of error codes to corresponding error messages
    const errorMappings: Record<string, string> = {
      'usernameInvalid': 'distributorErrorClientName',
      'clientLegalNameInvalid': 'distributorErrorClientLegalName',
      'clientEmailInvalid': 'distributorErrorEmail',
      'clientPhoneInvalid': 'distributorErrorPhone',
      'clientAddressInvalid': 'distributorErrorAddress',
    };

    // Retrieve the first validation error from the array
    const validationError = this.validationService.validationErrorArray[0];

    // Check if the validation error code exists in the mapping
    if (errorMappings[validationError]) {
      // If so, display a danger ribbon message with the corresponding key
      this.systemMessage.selectRibbon('danger', errorMappings[validationError]);
    }
  }

  updateClientData() {
      // Get the returned address from the localizzation service
      if(this.localizationService.autoCompletionReturnAddress){
        this.clientService.clientData.address = this.localizationService.autoCompletionReturnAddress;
      }

      // Call validation function
      this.checkValidationAllInputs();

      // Create a new Date object to get the current date and time
      const date = new Date();

      // Format the date using the validationService
      this.validationService.formatDate(date);

      // Remove non-numeric characters from the phone number using the validationService
      this.validationService.keepOnlyNumbers(this.clientService.clientData.phone_number);

      // Update the client's phone number and creation date with the formatted values
      this.clientService.clientData.phone_number = this.validationService.phoneNumberFixed;
      this.clientService.clientData.created = this.validationService.dateFixed;
      this.clientService.clientData.modified = this.validationService.dateFixed;

      // Check if any of the validation flags are false, indicating validation errors
      if (this.validationService.clientNameValid && this.validationService.clientLegalNameValid &&
          this.validationService.clientEmailValid && this.validationService.clientPhoneNumberValid &&
          this.validationService.clientAddressValid) {

          // If all validations pass, log a message and call the createClient function
          const result = this.clientService.updateClient().subscribe((result) => {});

          if(result){
            this.systemMessage.selectRibbon('success','alert-success-generic-message');
            this.pageReload();
          }
      }
  }

  // Display a confirmation dialog to the user and call updateClientData() if confirmed.
  confirmationQuestion() {
    const messageKey = 'clientupdateConfirmationUpdateQuestion'; // Key to retrieve the confirmation message for translation

    // Get the translated confirmation message based on the message key
    this.translate.get(messageKey).subscribe((message: string) => {
      // Display a confirmation dialog with the translated message
      if (window.confirm(message)) {
        // if user click ok, call updateClientData function
        this.updateClientData();
      }
    });
  }

  // function to reload page when cancel button is clicked
  pageReload() {
    this.localStorageService.addItem('entitiesContent', 'clients')
    this.router.navigate(['/entities']); // Redirect to the '/client-list' route after the delay
  }

  /**
   * Formats a numeric sequence into a specific pattern (e.g., phone number format).
   *
   * @param numberSequence - The numeric sequence to be formatted.
   * @returns The formatted numeric sequence or an empty string if the input is undefined or null.
   */
  formatPhoneNumber(numberSequence: string | undefined | null): string {
    if (numberSequence === undefined || numberSequence === null) {
      return ''; // Returns an empty string if the number sequence is undefined or null
    }

    const onlyNumbers = numberSequence.replace(/\D/g, ''); // Removes all non-numeric characters from the sequence

    if (onlyNumbers.length === 10) {
      const part1 = onlyNumbers.slice(0, 3); // Extracts the first 3 digits
      const part2 = onlyNumbers.slice(3, 6); // Extracts the next 3 digits
      const part3 = onlyNumbers.slice(6);    // Extracts the last 4 digits
      return `(${part1}) ${part2}-${part3}`;       // Returns the formatted number
    } else {
      return numberSequence; // Returns the original sequence if it doesn't have 10 digits
    }
  }
}
