<app-system-message></app-system-message>
<!-- Search input component, shown when mainContent is 'bin-model-list' -->
<div [ngClass]="theme.getThemeClass()" class="min-width-div container-fluid">
    <nav id="search-component" class="filter-group" *ngIf="mainContent === 'bin-model-list'">
        <input #searchBox type="search"  id="search-box" [(ngModel)]="userFilter" (input)="this.search()"
            class="form-control rounded searchInput"
            placeholder="{{ 'userlistSearchInputPlaceHolder' | translate }}" aria-label="Search" aria-describedby="search-addon" />
        <ng-select class="form-control ng-select select-filter-by" [(ngModel)]="this.filterBy"
          placeholder="{{ 'filterBy' | translate }}"  [ngClass]="{'has-value': this.filterBy }">
          <ng-option value="" disabled selected>{{ "filterBy" | translate }}</ng-option>
              <ng-option value="bin_model_number">{{ "binModel" | translate }}</ng-option>
              <ng-option value="total_volume">{{ "binModelListViewTotalVolume" | translate }}</ng-option>
        </ng-select>
    </nav>

    <!-- TABLE TITLE -->
    <header [ngClass]="theme.getThemeClass()" class="table-header" *ngIf="mainContent === 'bin-model-list'">
        <span class="left">{{ "binModelListViewBinModelList" | translate }}</span>
        <span class="right">
            <button [ngClass]="theme.getThemeClass()" class="btn btn-dropdown bg-light text-dark" id="buttonDropdown" routerLink="/bin-model-create">
            {{ "binModelListViewNew" | translate }}
            </button>
        </span>
    </header>

    <!-- Table container for the bin-model-list, shown when mainContent is 'bin-model-list' -->
    <section [ngClass]="theme.getThemeClass()" class="table-container" *ngIf="mainContent === 'bin-model-list'">
        <article class="table-responsive ">
            <table class="table table-hover">
                <!-- TABLE HEADER -->
                <thead>
                    <tr>
                        <th [ngClass]="theme.getThemeClass()" scope="col">
                            {{ 'iotTabHeaderStatus' | translate }}
                        </th>
                        <th [ngClass]="theme.getThemeClass()" scope="col">
                            {{ 'binListBinModel' | translate }}
                        </th>
                        <th [ngClass]="theme.getThemeClass()" class="hide-small-screen" scope="col">
                            {{ '_distributor' | translate }}
                        </th>
                        <th [ngClass]="theme.getThemeClass()" class="hide-small-screen" scope="col">
                            {{ '_installation' | translate }}
                        </th>
                        <th [ngClass]="theme.getThemeClass()" class="hide-small-screen" scope="col">
                            {{ 'height' | translate }}
                        </th>
                        <th [ngClass]="theme.getThemeClass()" class="hide-small-screen" scope="col">
                            {{ 'binShape' | translate }}
                        </th>
                        <th [ngClass]="theme.getThemeClass()" class="hide-small-screen" scope="col">
                            {{ 'binModelListViewTotalVolume' | translate }}
                        </th>
                        <th [ngClass]="theme.getThemeClass()" class="hide-small-screen" scope="col">
                            {{ 'numberOfBin' | translate }}
                        </th>
                        <th [ngClass]="theme.getThemeClass()" class="hide-small-screen" scope="col">
                            {{ 'binListViewCreated' | translate }}
                        </th>
                        <th [ngClass]="theme.getThemeClass()" class="hide-small-screen" scope="col">
                            {{ 'binListViewModified' | translate }}
                        </th>
                        <th [ngClass]="theme.getThemeClass()" scope="col">
                            {{ 'iotTabHeaderResources' | translate }}
                        </th>
                    </tr>
                </thead>
                <ng-container  *ngFor="let binModel of this.bin.bin_model_array">
                    <tbody>
                        <!-- TABLE DATA -->
                        <tr class="verticalAlign">
                            <!-- BIN STAUS -->
                            <td [ngClass]="theme.getThemeClass()" [title]="binModel.active === 1 ? ('active' | translate) : ('inactive ' | translate)" >
                                <a (click)="this.binModal.openModal(binModel)" [style.color]="binModel.active ? 'green!important' : 'red!important'">
                                  <i class="fa-solid fa-dumpster binIconSize" [style.color]="binModel.active ? '#63E6BE' : '#c4c4c4'"></i>
                                </a>
                            </td>
                            <!-- BIN MODEL NAME -->
                            <td [ngClass]="theme.getThemeClass()"><a (click)="this.binModal.openModal(binModel);" [style.color]="this.binModel.active ? 'green!important' : 'red!important'">{{ binModel.bin_model_number }}</a></td>

                            <!-- DISTRIBUTOR NAME -->
                            <td class="hide-small-screen" [ngClass]="theme.getThemeClass()">{{ this.getDistributorNameById(binModel.distributor_id) }}</td>

                            <!-- INSTALLATION TYPE -->
                            <td class="hide-small-screen" [ngClass]="theme.getThemeClass()" [title]="binModel.above_ground === 1 ? ('aboveGround' | translate) : ('inGround' | translate)">
                                <ng-container *ngIf="binModel.above_ground === 1; else belowGround">
                                  <i class="fa-solid fa-arrow-up-wide-short installationIcon"></i>
                                </ng-container>
                                <ng-template #belowGround>
                                  <i class="fa-solid fa-arrow-down-wide-short installationIcon"></i>
                                </ng-template>
                            </td>

                            <!-- BIN HEIGHT -->
                            <td class="hide-small-screen" [ngClass]="theme.getThemeClass()">{{ binModel.bin_height }}mm</td>

                            <!-- BIN SHAPE -->
                            <td class="hide-small-screen" [ngClass]="theme.getThemeClass()" [title]="binModel.bin_shape === 'C' ? ('binModelUpdateOptionCylinder' | translate) : ('binModelUpdateOptionSquare' | translate)">
                                <ng-container *ngIf="binModel.bin_shape === 'C'; else squareShape">
                                  <i class="fa-regular fa-circle binIcon"></i>
                                </ng-container>
                                <ng-template #squareShape>
                                  <i class="fa-regular fa-square binIcon"></i>
                                </ng-template>
                            </td>

                            <!-- VOLUME -->
                            <td [ngClass]="theme.getThemeClass()" class="hide-small-screen">{{ binModel.total_volume }}L</td>

                            <!-- QUANTITY OF BINS MODEL -->
                            <td [ngClass]="theme.getThemeClass()" class="hide-small-screen">{{ binModel.number_of_bin }}</td>

                            <!-- CREATED -->
                            <td [ngClass]="theme.getThemeClass()" class="hide-small-screen">{{ this.validationService.intToDate(binModel.created) | date: 'dd/MM/yyyy' }}</td>

                            <!-- MODIFIED -->
                            <td [ngClass]="theme.getThemeClass()" class="hide-small-screen">{{ this.validationService.intToDate(binModel.modified) | date: 'dd/MM/yyyy' }}</td>

                            <!-- RESOURCES -->
                            <td [ngClass]="theme.getThemeClass()">
                                <a [routerLink]="['/bin-model-update', binModel.bin_model_id]"><img class="iconUpdate" src="./assets/update-pencil.png" alt="icon of update" height="30" width="30"></a>
                            </td>
                        </tr>
                    </tbody>
                </ng-container>
            </table>
          </article>
        <app-bin-modal *ngIf="this.binModal.showModal" class="bin-modal"></app-bin-modal>
      </section>
</div>
