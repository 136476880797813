import { Component, OnInit } from '@angular/core';
import { LocalStorageService } from '../local-storage.service';
import { FilterService } from '../service/filter.service';
import { BinsService, Bin, BinModel, BinDetails } from '../service/bins.service';
import { ValidationService } from '../service/validation.service';
import { ModalAlertService } from '../service/alert-modal.service';
import { ThemeService } from '../service/theme.service';
import { SystemMessageService } from '../service/system-message.service';

@Component({
  selector: 'app-bin-list',
  templateUrl: './bin-list.component.html',
  styleUrls: ['./bin-list.component.css', '../../global-elements.css']
})
export class BinListComponent implements OnInit{

  // Variables used for HTML whit ngIf for the showing or not of block of html cde
  public mainContent: string = 'bin-list';
  public fromComponent: string = '';

  // Variables used for the filter service
  public userFilter: string = '';
  public filterBy: string = '';

  // Array of the bins
  public array: any; // Array used to attribute a received array frombin-service to the bin_array and bin_model_array
  public binArray: Bin = {} as Bin;
  public binModelArray: BinModel = {} as BinModel;
  public binDetailsArray: BinDetails = {} as BinDetails; // Array made from bin-service for UI display of bin-list. the bin-list show a mixte of bin_array and bin_model_array

  public show: boolean = true;

  constructor(private localStorageService: LocalStorageService,
              public filter: FilterService,
              public bin: BinsService,
              public validationService: ValidationService,
              public alert: ModalAlertService,
              public theme: ThemeService,
              private systemMessageService: SystemMessageService){

  }

  async ngOnInit(): Promise<void> {
    // Initializa alert component to nothing so if the user come and go on creation or update page the alert component will reinitialize
    this.alert.initAlert();

    try{
      // Initialize the bin-list object to implement the list
      await this.initList();
      await this.getFromComponent();
    }
    catch (error){
      console.error('An error as occured: ' + error);
    }

    // Function that is automatically trigger used by bin service when there is a change made from bin-create or bin-update component
    this.bin.changeMade$.subscribe(async () => {
      this.systemMessageService.selectRibbon('success','alert-success-generic-message');
    });

    this.bin.noChangeMade$.subscribe(async () => {
      this.systemMessageService.selectRibbon('danger','alert-danger-generic-message');
    });
  }

  storeUrl() {
    // Add the 'deviceList' item to the localStorageService
    this.localStorageService.addItem('lastUrl', 'binList')
  }

  // Function that will initializa the lists
  async initList(){
    // Reset and set back bin detail array to set it whit new value when you create or update bin and bin model
    await this.bin.resetBinDetailArray();
    await this.bin.setBinDetailArray();
  }

  // Function that set-up variables depend on where the component was called
  async getFromComponent(){
    // Check if there is a session variable called from. Then it will be used in html if there is a success create or update message to show the proper stuff
    // Or to filter the proper bins if it's comming from bin-model-list
    const fromComponent = sessionStorage.getItem("from");
    if(fromComponent){
      // Initialize is own variable
      this.fromComponent = fromComponent;
      if(this.fromComponent === 'bin-model-list'){
        // Set the bin number from the session variable
        const binNumber = sessionStorage.getItem("bin_number");

        if(binNumber !== null){
          // Set the user filter at the bin number received from bin-model-list
          this.userFilter = binNumber;
          sessionStorage.removeItem("bin_number"); // Remove the session veriable to avoid an other input when user come back to the page

          // Get the filter element from DOM
          const filterElement = document.getElementById("search-box") as HTMLInputElement;
          if(filterElement){
            // If there's an html element filter set the value of it to the user filter so user can see there's a filter ON
            filterElement.value = this.userFilter;
          }
          // Set the calling component in filter service then it will do the search to implement the list whit the bins that have the bin-model-number throwed by bin-model-list
          this.filter.setCallingComponent('bin-details-list');

          // Check if there's a filterBy variable and set it to it's default value
          if(this.filterBy === ''){
            this.filterBy = 'bin_name';
          }

          // Call filter service search function
          this.filter.search(this.userFilter, this.bin.bin_array, this.bin.original_bin_array, this.filterBy);
        }
      }
      sessionStorage.removeItem("from"); // Then remove it from session variable to avoid is not wanted use from other components
    }
  }

  // Function used to made a search in the list
  search(){
    // Check if there's a filterBy variable and set it to it's default value
    if(this.filterBy === ''){
      this.filterBy = 'bin_name';
    }

    this.bin.bin_detail_array = this.filter.search(this.userFilter, this.bin.bin_detail_array, this.bin.original_bin_detail_array, this.filterBy);
  }
}
