import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../environments/environment';
import { map, catchError, Subject } from 'rxjs';
import { BinUsage } from '../constants/bin-usage';
import { BinShapes } from '../constants/bin-shapes';
import { MarketSegment } from '../constants/market-segments';
import { SystemMessageService } from './system-message.service';
import { CognitoService } from './cognito.service';
import { TranslateService } from '@ngx-translate/core';
import { firstValueFrom } from 'rxjs';
import { Router } from '@angular/router';

export interface BinModel {
  bin_model_id: string;
  bin_model_number: string;
  bin_depth: number;
  bin_width: number;
  bin_height: number;
  total_volume: number;
  bin_shape: string;
  above_ground: number;
  with_thing: number;
  number_of_bin: number;
  active: number;
  created: number;
  modified: number;
  distributor_id: string;
}

export interface BinData {
  bin_id: string;
  bin_name?: string;
  bin_gps?: string;
  bin_location: string;
  thing_name: string;
  bin_usage: string;
  bin_address: string;
  legal_name: string;
  market_segment: string;
  threshold: number;
  bin_height: number;
  total_volume: number;
  client: string;
  bin_model_number: string;
  above_ground: string;
  client_id?: string;
}

export interface Bin {
  bin_name: string,
  bin_id: string;
  bin_model_id: string;
  bin_usage: string;
  bin_gps?: string;
  bin_location: string;
  bin_address: string;
  bin_postal_code: string;
  active: number;
  created: number;
  modified: number;
  client_id: string;
  thing_name: string;
  market_segment: string;
  threshold: number;
  total_volume?: number
}

export interface BinDetails {
  bin_name: string,
  bin_id: string;
  bin_model_id: string;
  bin_usage: string;
  bin_gps?: string;
  bin_location: string;
  bin_address: string;
  bin_postal_code: string;
  active: number;
  created: number;
  modified: number;
  client_id: string;
  thing_name: string;
  market_segment: string;
  threshold: number;
  details: BinModel;
}

@Injectable({
  providedIn: 'root'
})
export class BinsService {

  bin_model_data = {
    bin_name: '',
    bin_model_id: '',
    bin_model_number: '',
    bin_depth: 0,
    bin_width: 0,
    bin_height: 0,
    total_volume: 0,
    bin_shape: '',
    above_ground: 0,
    with_thing: 0,
    number_of_bin: 0,
    active: 0,
    created: 0,
    modified: 0,
    distributor_id: ''
  }

  bin_data = {
    bin_name: '',
    bin_id: '',
    bin_model_id: '',
    bin_usage: '',
    bin_location: '',
    bin_gps: '',
    bin_address: '',
    bin_postal_code: '',
    active: 0,
    created: 0,
    modified: 0,
    client_id: '',
    thing_name: '',
    market_segment: '',
    threshold: 80
  }

  bin_details_data = {
    bin_id: '',
    bin_model_id: '',
    bin_usage: '',
    bin_gps: '',
    bin_location: '',
    bin_address: '',
    bin_postal_code: '',
    active: 0,
    created: 0,
    modified: 0,
    client_id: '',
    thing_name: '',
    marker_segment: '',
    threshold: 80,
    details: {
      bin_model_id: '',
      bin_model_number: '',
      bin_depth: 0,
      bin_width: 0,
      bin_height: 0,
      total_volume: 0,
      bin_shape: '',
      above_ground: 0,
      with_thing: 0,
      nunmber_of_bin: 0,
      active: 0,
      created: 0,
      modified: 0,
      distributor_id: ''
    }
  }

  // Array of bin and bin model
  public array: any; // Array used to attribute a received array frombin-service to the bin_array and bin_model_array
  public bin_array: Bin[] = [];
  public bin_model_array: BinModel[] = [];
  public bin_detail_array: BinDetails[] = []; // Array made from bin-service for UI display of bin-list. the bin-list show a mixte of bin_array and bin_model_array

  // Array and variables used for the filter, original arrays are copy of their own properties. so when user remove filter we use those arrays to repopulate the array used by the list to avoid a call to lambda function
  public original_bin_array: Bin[] = [];
  public original_bin_model_array: BinModel[] = [];
  public original_bin_detail_array: BinDetails[] = [];
  public filter_count: number = 0;

  // Variables that bin and bin-model list will used to trigger there function when there is a create or update made or not
  private changeMade = new Subject<void>;
  changeMade$ = this.changeMade.asObservable();
  private noChangeMade = new Subject<void>;
  noChangeMade$ = this.noChangeMade.asObservable();

  // Variables used to put a message returned from the lambda function and used in bin-list and bin-model-list component
  public successMessage: any;

  // Variable used between bin-create and bin-usage to know whitch type of waste have been selected in bin-usage
  private selectedTypeOfWaste = new Subject<void>();
  selectedTypeOfWaste$ = this.selectedTypeOfWaste.asObservable();
  public typeOfWaste: string = '';


  // Variables used for association between bin and client or distributor
  public association_id: string = '';
  public distributor_id: string = '';
  public device_array: any;
  public isDeviceArray: boolean = false;
  public thing_name: string = '';
  public selectedBinModel: any;
  public numberOfThingsAvailable: number = 0;
  public oldThingName: string = '';

  private tmpTransfertJSONArray: any;

  public binsNameList: any[] = [] ;

  constructor(private http: HttpClient,
              private systemMessageService: SystemMessageService,
              private cognitoService: CognitoService,
              private translate: TranslateService,
              private route: Router) { }

  // Function that will reset the detail array used in bin-list, when we create update or delete stuff from the list to show the changes
  async resetBinDetailArray(){
    this.bin_detail_array = [];
    this.bin_array = [];
    this.bin_model_array = [];
  }

  // Function used by lists to initialize the bin datas
  initBinData(){
    this.bin_data = {
      bin_name: '',
      bin_id: '',
      bin_model_id: '',
      bin_usage: '',
      bin_location: '',
      bin_gps: '',
      bin_address: '',
      bin_postal_code: '',
      active: 0,
      created: 0,
      modified: 0,
      client_id: '',
      thing_name: '',
      market_segment: '',
      threshold: 80
    }
  }

  // Function used by lists to initialize the bin model datas
  initBinModelData(){
    this.bin_model_data = {
      bin_name: '',
      bin_model_id: '',
      bin_model_number: '',
      bin_depth: 0,
      bin_width: 0,
      bin_height: 0,
      total_volume: 0,
      bin_shape: '',
      above_ground: 0,
      with_thing: 0,
      number_of_bin: 0,
      active: 0,
      created: 0,
      modified: 0,
      distributor_id: ''
    }
  }

  //Asynchronously generates a list of bin names based on bin details.
  async generateBinNamesList() {
    // Retrieve the array of bin details using the setBinDetailArray method
    const binlist = await this.setBinDetailArray();

    // Check if the retrieved bin list is not an array
    if (!Array.isArray(binlist)) {
      console.error('The provided argument is not an array');
      return [];
    }

    // Create a new array containing only the bin names
    const binsNames = binlist.map(bin => bin.bin_name);

    // Update the binsNameList property with the generated bin names
    this.binsNameList = binsNames;

    // Return the array of bin names
    return this.binsNameList;
  }

  // This function made a new array whit the bin and bin_model array used in the bin_list to show the good stuff about the bin
  async setBinDetailArray(id: string = ''){
    this.bin_detail_array = [];
    // Call from component list directly to have all infos from bin and bin_model all in one array
    await this.initializeBinsInfos(id);

    // Loop first inside the bin array and will put the details of each bin whit the bin_model_array
    for(let i = 0; i < this.bin_array.length; i ++){

      // Call a function that will put inside bin_detail_array the usage of the bin in texte not in code as it is in DB
      const usage = this.getBinUsage(this.bin_array[i].bin_usage);

      // Loop inside bin_model_array to put the details of  bin_array
      for(let y = 0; y < this.bin_model_array.length; y ++){

        // If we got the same bin_model_id on each array
        if(this.bin_array[i].bin_model_id === this.bin_model_array[y].bin_model_id){
          // Implement the new bin detail array used for the display of the list in bin-list component
          const newBinDetail: BinDetails = {
            bin_name: this.bin_array[i].bin_name,
            bin_id: this.bin_array[i].bin_id,
            bin_model_id: this.bin_array[i].bin_model_id,
            bin_usage: usage,
            bin_gps:this.bin_array[i].bin_gps,
            bin_location: this.bin_array[i].bin_location,
            bin_address: this.bin_array[i].bin_address,
            bin_postal_code: this.bin_array[i].bin_postal_code,
            created: this.bin_array[i].created,
            modified: this.bin_array[i].modified,
            active: this.bin_array[i].active,
            client_id: this.bin_array[i].client_id,
            thing_name: this.bin_array[i].thing_name,
            market_segment: this.bin_array[i].market_segment,
            threshold: this.bin_array[i].threshold,
            details: {
              bin_model_id: this.bin_model_array[y].bin_model_id,
              bin_model_number: this.bin_model_array[y].bin_model_number,
              bin_depth: this.bin_model_array[y].bin_depth,
              bin_width: this.bin_model_array[y].bin_width,
              bin_height: this.bin_model_array[y].bin_height,
              total_volume: this.bin_model_array[y].total_volume,
              bin_shape: this.bin_model_array[y].bin_shape,
              above_ground: this.bin_model_array[y].above_ground,
              with_thing: this.bin_model_array[y].with_thing,
              number_of_bin: this.bin_model_array[y].number_of_bin,
              active: this.bin_model_array[y].active,
              created: this.bin_model_array[y].created,
              modified: this.bin_model_array[y].modified,
              distributor_id: this.bin_model_array[y].distributor_id
            }
          }

          // It will push the new bin detail in the array an made an original array only used for the filter service when user filter the list
          this.bin_detail_array.push(newBinDetail);
          this.original_bin_detail_array.push(newBinDetail);
        }
      }
    }
    return this.bin_detail_array; // Return the array if needed
  }

  // Function called in setBinDetailArray() function to initialize the proper bin and bin_model array to implement bin_detail_array
  async initializeBinsInfos(id: string = ''){
    let client_id: string = '';
    let bin_id: string = '';

    // switch to know if we get a client_id or bin_id
    if(id){
      switch(id.substring(0, 2)){
        case 'CL':
          client_id = id;
          break;

        case 'BN':
          bin_id = id;
          break;

        default:
          break;
      }
    }

    switch(true){
      case ((this.bin_array.length === 0 || this.bin_model_array.length === 0) && client_id !== ''):
        await this.getBinForSpecificClient(id);
        await this.getBinsModel();
        break;

      case ((this.bin_array.length === 0 || this.bin_model_array.length === 0) && client_id === '' && bin_id === ''):
        await this.getBins();
        await this.getBinsModel();
        break;

      case client_id !== '':
        await this.getBinForSpecificClient(client_id);
        await this.getBinsModel();
        break;

      default:
        break;
    }
  }

  // Function called by initializeBinsInfos() function to get specific bins from a client_id
  async getBinForSpecificClient(client_id: string){
    // Get the instance of the bin array
    await this.getBins();

    // Filter the bin array to get only the one for the selected client
    this.bin_array = this.bin_array.filter((bin) => bin.client_id === client_id && (bin.thing_name === '' || bin.thing_name === null) );
  }

  // Get the texte into BinUsage constant of each usage code passed in function
  getBinUsage(usage: string){
    let usageInTexte: string = 'N/A';

    // Loop into BinUsage constant values to get the proper texte for the usage code that we throw in function
    for(let i = 0; i < BinUsage.length; i ++){
      if(BinUsage[i].value === usage){
        usageInTexte = BinUsage[i].label; // Set usageInTexte variable whit the label of BinUsage constant
      }
    }
    return usageInTexte; // return the value in texte
  }

  // Get the texte into BinUsage constant of each usage code passed in function
  getBinMarket(market: string){
    let label: string = 'N/A';

    // Loop into BinUsage constant values to get the proper texte for the usage code that we throw in function
    for(let i = 0; i < MarketSegment.length; i ++){
      if(MarketSegment[i].value === market){
        label = MarketSegment[i].label; // Set usageInTexte variable whit the label of BinUsage constant
      }
    }
    return label; // return the value in texte
  }

  // Get the texte into BinShape constant of each shape passed in function
  getBinShape(shape: string){
    let shapeInTexte: string = '';

    // Loop into BinShape constant values to get the proper texte for the shape code that we throw in function
    for(let i = 0; i < BinShapes.length; i ++){
      if(BinShapes[i].value === shape){
        shapeInTexte = BinShapes[i].Label; // Set shapeInTexte variable whit the label of BinShape constant
      }
    }
    if(shapeInTexte){
      return shapeInTexte; // return the value in texte
    }else{
      return 'N/A'
    }
  }

  // Reset the filtered array whitout making a new call to AWS. Used when user clear filter or backspace
  resetBinArray(){
    this.bin_array = [...this.original_bin_array];
    this.bin_model_array = [...this.original_bin_model_array];
    this.bin_detail_array = [...this.original_bin_detail_array];
  }

  // Will be set whit filter service whit the count of the string(userFilter) to know when user do backspace and reset the array
  setCountFilter(count: number){
    this.filter_count = count;
  }

  // Will be called by the component that call filter service to share the last userFilter count
  getCountFilter(){
    return this.filter_count;
  }

  // Function called from create/update bin and bin-model
  onSuccessCRUD(){
    // Call the function that will trigger bin-list or bin-model-list that there is a change made in the list
    this.changeHaveBeenMade();
  }

  // Function called when a bin or client is change in iot device modal modal
  async binModelOrClientChange(){
    // Get the instance of the bin_model user select
    this.selectedBinModel = this.bin_model_array.find(bin_model => bin_model.bin_model_id === this.bin_data.bin_model_id);
    // Set back the isDeviceArray
    this.isDeviceArray = false;
    // Check if this bin_modal required a device
    if(this.selectedBinModel?.with_thing === 1){
      // Set the distributor_id and get all the available device from his distributor
      this.distributor_id = this.selectedBinModel.distributor_id;

      // Check if client_id is not empty and is he's not, he include the client_id in the select of lambda function
      if(this.bin_data.client_id !== ''){
        await this.getAvailableDistributorDevice(this.bin_data.client_id);
      }else{
        await this.getAvailableDistributorDevice();
      }

      // Set the number of device available for this distributor
      this.numberOfThingsAvailable = this.device_array.length;

      // Check if there's device available from this distributor
      if(this.numberOfThingsAvailable > 0){
        // Set variable for html to display the device <select> and set an information ribbon for the user
        this.isDeviceArray = true;
        this.systemMessageService.selectRibbon('info', 'deviceNeeded');
      }else{
        // Reset bin_model_id to it's initial data and set a ribbon throw to the user because there's no more device for this bin model
        this.bin_data.bin_model_id = '';
        this.systemMessageService.selectRibbon('danger', 'distributorHaveNoMoreDevice');

        // If it's a muirwood user, it will ask if he want to go to iot component to get new device for the distributor
        if(this.cognitoService.userType === 'muirwood'){
          const result = window.confirm(await firstValueFrom(this.translate.get('distributorHaveNoMoreDevice')) + '. ' + await firstValueFrom(this.translate.get('beRedirectToIot')));
          if(result === true){
            this.route.navigate(['/iot']); // Return to iot component
          }
        }
      }
    }
  }

  //////////////// Those functions are called when we get a response from lambdas function to transfert some of the keys from receiving JSON in Integer /////////////////
  //////////////// Instead of changing all stuff in app for the integer we change the data directly after receiving them /////////////////////////////////////////////

  // Function use to switch all value of the keys to int instead of string
  switchJSONToIntForBinResponse(element: any){
    // Tableau of witch keys we need to check
    const keysToCheck = [
      'active',
      'created',
      'modified',
      'threshold'
    ];
    // Call the function to transfert all value of the keys in Integer
    this.keyToTransfert(keysToCheck, element);
  }

  // Function use to switch all value of the keys to int instead of string
  switchJSONToIntForBinModelResponse(element: any){
    // Tableau of witch keys we need to check
    const keysToCheck = [
      'above_ground',
      'active',
      'bin_height',
      'bin_width',
      'bin_depth',
      'total_volume',
      'with_thing',
      'number_of_bin',
      'created',
      'modified'
    ];
    // Call the function to transfert all value of the keys in Integer
    this.keyToTransfert(keysToCheck, element);
  }

  // Function use to convert string to int
  keyToTransfert(keysToCheck: any, element: any){
    keysToCheck.forEach((key: any) => {
      // Vérifier si la clé existe dans l'élément et si sa valeur est une chaîne
      if (typeof element[key] === 'string') {
          // Convertir la valeur de la clé en entier
          element[key] = parseInt(element[key], 10);
      }
    });
  }



  //////// Those functions are functions that trigger function whit subscribe in other components /////////



  // Function that is been subscribe in bin-create and bin-update component to trigger a function that will get the type of waste selected in this service
  typeOfWasteSelected(){
    this.selectedTypeOfWaste.next();
  }

  // Function that is been subscribe in bin-list and bin-model-list when there is an create or update made in bin-create, bin-update, bin-model-create or bin-model-update
  // This function will made reinitialize the list of both when a change apend
  async changeHaveBeenMade(){
    this.changeMade.next();
  }

  // Function called to trigger an other function in different component when change haven't been made
  async noChangeHaveBeenMade(){
    this.noChangeMade.next();
  }


  //////// Those CRUD functions are the one called by the components. Please do not do function bellow insted of function used to CALL Lambda functions /////////
  //////// No delete function is in because we just update the status of bin and bin-model ///////////



  // Select all bin associated to a distributor and are not yet attribuated to a client or a bin
  async getAvailableDistributorDevice(client_id: string = ''){
    return new Promise<any[]>((resolve) => {
      // Call the function that call the lambda function
      this.getAvailableDistributorDeviceLambda(client_id).subscribe((response) => {
        // Put the array of Bins returned by the lambda function into an tmp array
        this.array = response;

        // Set the bin_array and original_bin_array whit the tmp array values
        this.device_array = this.array;
        resolve(this.array);
      });
    });
  }

  // Create bin
  async createBin(){
    // Call the function that call the lambda function
    this.createBinLambda().subscribe((response) => {
      this.successMessage = response; // Return response from lambda and put it in success message so bin-list or bin-model-list and will be able to read it

      if(this.successMessage.message === 'success'){
        this.onSuccessCRUD(); // Call a function that will set all thing for the UI
      }else{
        this.noChangeHaveBeenMade();
      }
    });
  }

  // Get all bins
  getBins(): Promise<any[]>{
    return new Promise<any[]>((resolve) => {
      // Call the function that call the lambda function
      this.getBinsLambda().subscribe((response) => {
        // Put the array of Bins returned by the lambda function into an tmp array
        this.array = response;

        // Set the bin_array and original_bin_array whit the tmp array values
        this.bin_array = this.array;
        this.original_bin_array = this.array;
        resolve(this.array);
      });
    });
  }

  // Get bin by id
  async getBinById(bin_id: string): Promise<any[]>{
    return new Promise<any[]>((resolve) => {
      // Call the function that call the lambda function
      this.getBinByIdLambda(bin_id).subscribe((response) => {
        this.array = response;
        this.bin_data = this.array;
        resolve(this.array);
      });
    });
  }

  // Update bin
  async updateBin(){
    // Call the function that made the call to lambda function and wait for is response
    this.updateBinLambda().subscribe((response) => {
      this.successMessage = response;
      if(this.successMessage.message === 'success'){
        this.onSuccessCRUD(); // Call a function that will set all thing for the UI
      }else{
        this.noChangeHaveBeenMade();
      }
    });
  }

  // Create bin model
  async createBinModel(){
    // Call the function that made the call to lambda function and wait for is response
    this.createBinModelLambda().subscribe((response) => {
      this.successMessage = response;
      if(this.successMessage.message === 'success'){
        this.onSuccessCRUD(); // Call a function that will set all thing for the UI
      }else{
        this.noChangeHaveBeenMade();
      }
    });
  }

  // Get all the bin models
  getBinsModel(): Promise<any[]>{
    return new Promise<any[]>((resolve) => {
      this.getBinsModelsLambda().subscribe((response) => {
        // Put the array of Bins returned by the lambda function into an tmp array
        this.array = response;
        // Put the tmp array into the service bin model array
        this.bin_model_array = this.array;
        this.original_bin_model_array = this.array;
        resolve(this.array);
      });
    });
  }

  // Get bin model by is id
  async getBinModelById(binModelId: string): Promise<any>{
    return new Promise<any[]>((resolve) => {
      this.getBinModelByIdLambda(binModelId).subscribe((response) => {
        this.array = response;

        for(const element of this.array){
          this.bin_model_data = element;
        }
        //this.bin_model_data = this.array;
        resolve(this.array);
      });
    });
  }

  // Update bin model
  async updateBinModel(){
    // Call the function that made the call to lambda function and wait for is response
    this.updateBinModelLambda().subscribe((response) => {
      this.successMessage = response;
      if(this.successMessage.message === 'success'){
        this.onSuccessCRUD(); // Call a function that will set all thing for the UI
      }else{
        this.noChangeHaveBeenMade();
      }
    });
  }


  ////////// All function bellow are function that call the lambda function from AWS. Please do not do functions bellow insted of Lambda calls /////////////
  //////// No delete function is in because we just update the status of bin and bin-model ///////////



  // Function to get all device from a distributor that are still available
  getAvailableDistributorDeviceLambda(client_id: string = ''){
    // Get the url for the lambda function getBinsModels
    const url = environment.api.stage + environment.api.route.getAvailableDistributorDevices + "&distributor_id=" + this.distributor_id + "&client_id=" + client_id;
    // Will call the lambda function in getBinModel url then return a response
    return this.http.get(url).pipe(
      map((response) => {
        // return the response data
        return response;
      }),
      catchError((error) => {
        //console.error('API Error:', error);
        throw error(error); // Re-throw the error for the calling code to handle
      })
    );
  }

  // Function to create Bin Models
  createBinModelLambda(){
     // Define the HTTP headers with content type
     const headers = new HttpHeaders({
      'Content-Type':  'application/json' // Adjust content type as needed
    });
    // Will call the lambda function in createBinModel url whit all the bin model datas that user put in the inputs then return a response
    return this.http.post(environment.api.stage + environment.api.route.createBinModel, {
        // Doubled coats things are used into lambda function as data and used for the SQL's calls that those functions does
        "bin_model_number": this.bin_model_data.bin_model_number,
        "bin_depth": this.bin_model_data.bin_depth,
        "bin_width": this.bin_model_data.bin_width,
        "bin_height": this.bin_model_data.bin_height,
        "total_volume": this.bin_model_data.total_volume,
        "bin_shape": this.bin_model_data.bin_shape,
        "above_ground": this.bin_model_data.above_ground,
        "with_thing": this.bin_model_data.with_thing,
        "created": this.bin_model_data.created,
        "modified": this.bin_model_data.modified,
        "distributor_id": this.bin_model_data.distributor_id
      }, { headers: headers }
    );
  }

  // Get all the bins models
  getBinsModelsLambda(){
    // Get the url for the lambda function getBinsModels
    const url = environment.api.stage + environment.api.route.getBinModels;
    // Will call the lambda function in getBinModel url then return a response
    return this.http.get(url).pipe(
      map((response) => {
        this.tmpTransfertJSONArray = response;
        // For each element of the array, we'r gonna transfer the JSON received in integer for some of the keys
        this.tmpTransfertJSONArray = this.tmpTransfertJSONArray.forEach((element: any) => {
          this.switchJSONToIntForBinModelResponse(element);
        });
        // return the response data
        return response;
      }),
      catchError((error) => {
        //console.error('API Error:', error);
        throw error(error); // Re-throw the error for the calling code to handle
      })
    );
  }

  // Get bin model by id
  getBinModelByIdLambda(binModelId: string){
    // Get the url for the lambda function getBinModelById and pass the binModelId
    const url = environment.api.stage + environment.api.route.getBinModel + "&bin_model_id=" + binModelId;
    // Call the lambda fonction whit the url
    return this.http.get(url).pipe(
      map((response) => {
        this.tmpTransfertJSONArray = response;

        // For each element of the array, we'r gonna transfer the JSON received in integer for some of the keys
        this.tmpTransfertJSONArray = this.tmpTransfertJSONArray.forEach((element: any) => {
          this.switchJSONToIntForBinModelResponse(element);
        });
        // Return the response data
        return response;
      }),
      catchError((error) => {
        //console.error('API Error:' + error);
        throw error(error);
      })
    );
  }

  // Update the Bin Models
  updateBinModelLambda(){

    if (!this.bin_model_data.bin_width) {
      this.bin_model_data.bin_width = 0;
    }

    // Define the HTTP headers with content type
    const headers = new HttpHeaders({
      'Content-Type':  'application/json' // Adjust content type as needed
    });
    // Will call the lambda function in updateBinModel url whit the passed data then return a response
    return this.http.post(environment.api.stage + environment.api.route.updateBinModel, {
      // Doubled coats things are used into lambda function as data and used for the SQL's calls that those functions does
        "bin_model_id": this.bin_model_data.bin_model_id,
        "bin_model_number": this.bin_model_data.bin_model_number,
        "bin_depth": this.bin_model_data.bin_depth,
        "bin_width": this.bin_model_data.bin_width,
        "bin_height": this.bin_model_data.bin_height,
        "total_volume": this.bin_model_data.total_volume,
        "bin_shape": this.bin_model_data.bin_shape,
        "above_ground": this.bin_model_data.above_ground,
        "with_thing": this.bin_model_data.with_thing,
        "active": this.bin_model_data.active,
        "created": this.bin_model_data.created,
        "modified": this.bin_model_data.modified,
        "distributor_id": this.bin_model_data.distributor_id
      }, { headers: headers }
    );
  }

  // Create a Bin
  createBinLambda(){
     // Define the HTTP headers with content type
     const headers = new HttpHeaders({
      'Content-Type':  'application/json' // Adjust content type as needed
    });
    // Will call the lambda function in createBin url whit the passed data then return a response
    return this.http.post(environment.api.stage + environment.api.route.createBin, {
      // Doubled coats things are used into lambda function as data and used for the SQL's calls that those functions does
        "bin_name": this.bin_data.bin_name,
        "bin_model_id": this.bin_data.bin_model_id,
        "bin_usage": this.bin_data.bin_usage,
        "bin_gps": this.bin_data.bin_gps,
        "bin_location": this.bin_data.bin_location,
        "bin_address": this.bin_data.bin_address,
        "bin_postal_code": this.bin_data.bin_postal_code,
        "created": this.bin_data.created,
        "modified": this.bin_data.modified,
        "client_id": this.bin_data.client_id,
        "thing_name": this.thing_name,
        "market_segment": this.bin_data.market_segment,
        "threshold": this.bin_data.threshold,
        "distributor_id": this.distributor_id
      }, { headers: headers }
    );
  }

  // Get all Bins
  getBinsLambda(){
    // Initiate url whit the url of lambda fonction getBins
    const url = environment.api.stage + environment.api.route.getBins;
    // Call the lambda fonction whit the url
    return this.http.get(url).pipe(
      map((response) => {
        this.tmpTransfertJSONArray = response;
        // For each element of the array, we'r gonna transfer the JSON received in integer for some of the keys
        this.tmpTransfertJSONArray = this.tmpTransfertJSONArray.forEach((element: any) => {
          this.switchJSONToIntForBinResponse(element);
        });
        return response;// Return response data
      }),
      catchError((error) => {
        //console.error('API Error : ' + error);
        throw error;
      })
    );
  }

  // Function called to get a JSON of all bins
  getBinsJson(){
    // Define the HTTP headers with content type
    const headers = new HttpHeaders({
      'Content-Type':  'application/json' // Adjust content type as needed
    });
    // Initiate url whit the url of lambda fonction getBins
    const url = environment.api.stage + environment.api.route.getBins;
    // Call the lambda fonction whit the url
    return this.http.get(url, {headers});
  }

  // Get a Bin by it's id
  getBinByIdLambda(bin_id: string){
    // Initiate url whit the lambda function url of getBinById and pass the id
    const url = environment.api.stage + environment.api.route.getBinById + "&bin_id=" + bin_id;
    // Call the fonction
    return this.http.get(url).pipe(
      map((response) => {
        this.tmpTransfertJSONArray = response;

        // For each element of the array, we'r gonna transfer the JSON received in integer for some of the keys
        this.tmpTransfertJSONArray = this.tmpTransfertJSONArray.forEach((element: any) => {
          this.switchJSONToIntForBinResponse(element);
        });
        return response; // Return response data
      }),
      catchError((error) => {
        //console.error('API Error : ' + error);
        throw error;
      })
    )
  }

  // Update the Bin
  updateBinLambda(){
    // Define the HTTP headers with content type
    const headers = new HttpHeaders({
      'Content-Type':  'application/json' // Adjust content type as needed
    });
    // Call lambda fucntion whit the url of updateBin  and return the response
    return this.http.post(environment.api.stage + environment.api.route.updateBin, {
      // Doubled coats things are used into lambda function as data and used for the SQL's calls that those functions does
        "bin_name": this.bin_data.bin_name,
        "bin_id": this.bin_data.bin_id,
        "bin_model_id": this.bin_data.bin_model_id,
        "bin_usage": this.bin_data.bin_usage,
        "bin_gps": this.bin_data.bin_gps,
        "bin_location": this.bin_data.bin_location,
        "bin_address": this.bin_data.bin_address,
        "bin_postal_code": this.bin_data.bin_postal_code,
        "active": this.bin_data.active,
        "modified": this.bin_data.modified,
        "client_id": this.bin_data.client_id,
        "distributor_id": this.distributor_id,
        "market_segment": this.bin_data.market_segment,
        "threshold": this.bin_data.threshold,
        "old_thing_name": this.oldThingName,
        "thing_name": this.bin_data.thing_name

      }, {headers : headers}
    );
  }

  // Functionthat return the bin that thing is in if there is a bin
  async getBinsForDevices(things:any[] = []){
    // Define the HTTP headers with content type
    const headers = new HttpHeaders({
      'Content-Type':  'application/json' // Adjust content type as needed
    });
    // Will call the lambda function in updateBinModel url whit the passed data then return a response
    return this.http.post(environment.api.stage + environment.api.route.getBinsForDevices, {
      "things": things
      }, { headers: headers }
    );
  }

  // Function that return all info bout the bin that device is in
  filterBinData(array: BinData[], thingName: string): BinData | undefined {
    return array.find((item) => item.thing_name === thingName);
  }
}
