import { Component, OnInit, Inject, forwardRef } from '@angular/core';
import { RoleService } from '../service/role.service';
import { CognitoService } from '../service/cognito.service';
import { TranslateService } from '@ngx-translate/core';
import { LocalStorageService } from '../local-storage.service';
import { ClientService } from '../service/client.service';
import { ValidationService } from '../service/validation.service';
import { Router } from '@angular/router';
import { IotService } from '../service/iot.service';
import { DeviceModels } from '../constants/thing-models';
import { ThemeService } from '../service/theme.service';
import { SystemMessageService } from '../service/system-message.service';
import { environment } from '../environments/environment';
import { Bin, BinsService } from '../service/bins.service';
import { DistributorsService } from '../service/distributors.service';


@Component({
  selector: 'app-device-create',
  templateUrl: './device-create.component.html',
  styleUrls: ['./device-create.component.css']
})
export class DeviceCreateComponent implements OnInit {

  // Variables to store various selected values and flags
  public selectedLanguage: string = "";
  public selectedDeviceModel: string = "";
  public selectedDeviceType: string = "";
  public selectedDeviceGroup: string = "";
  public selectedClient: string = "";
  public selectedQuantity: number = 0;
  public successMessage: any;
  public validationStatus: boolean = true;
  public deviceModels = DeviceModels;
  public autocomplete: any = google.maps.places; // Google Places Autocomplete

  // Arrays to store data fetched from services
  public thingClient: any[];
  public thingTypeNames: any[];
  public thingGroups: any[];
  public thingClientNames: any[];

  // Variable for bin association
  public binArray: Bin[] = [];
  public selectedBinId: string = '';

  // Variables for distributors
  public distributorArray: any[] = [];
  public selectedDistributorId: string = '';

  public isCustomSufix: boolean = true;
  public customSufix: number = 0;

  // Retrieve current language selected from local storage
  languageStatus:string = this.localStorageService.getItem('language');

  // Variable to store the result of a place selection from Google Places Autocomplete.
  // It can hold a valid PlaceResult object or be undefined if no place is selected.
  selectedPlace: google.maps.places.PlaceResult | undefined;

  constructor(
    private localStorageService: LocalStorageService,
    @Inject(forwardRef(() => TranslateService)) private translate: TranslateService,
    public cognitoService: CognitoService,
    public roleService: RoleService,
    public clientService: ClientService,
    public distributorService: DistributorsService,
    public validationService: ValidationService,
    private router: Router,
    public iotService: IotService,
    public theme: ThemeService,
    public systemMessage: SystemMessageService,
    public binService: BinsService
  ) {

    // Check if the user has selected a language in local storage
    //or use a default language
    if (this.languageStatus == null){
      // Set the default language to French
      translate.use('fr');
    } else {
      // Set the default language to the user's selected language
      translate.use(this.languageStatus);
    }
    // Initialize arrays to store data
    this.thingTypeNames = [];
    this.thingGroups = [];
    this.thingClient = [];
    this.thingClientNames = []
  }

  async ngOnInit() {
    this.systemMessage.buttonBlocked = false;

    // Get bin and bin model arrays
    await this.binService.getBinsModel();
    await this.binService.getBins();

    // Perform necessary initialization and service calls
    await this.cognitoService.confirmValidUser();
    await this.roleService.getRoles();

    // Check if user have the roles to get in this page
    await this.cognitoService.getCurrentRole([environment.users.role.administrator], [environment.users.superAdmin], true, this.roleService.roles);

    // Get userType and user Role
    this.cognitoService.getUserType();
    // Initialize the biggestDeviceNumber property to 0.
    this.iotService.biggestDeviceNumber = 0;

    // Fetch a list of IoT things.
    this.iotService.listThings();

    // Fetch the list of clients
    this.getClientList();

    // Fetch list of distributor
    this.getDistributor();

    try {
      // Attempt to get a list of thing types from the IoT service.
      const data = await this.iotService.getThingTypes();

      // Use the optional chaining operator to avoid errors if thingTypes is undefined.
      // Extract the thing type names and store them in the thingTypeNames array.
      this.thingTypeNames = data?.thingTypes?.map(item => item.thingTypeName) || [];

    } catch (error) {
      // Handle errors if they occur when fetching thing types.
      console.error('Error fetching thing types:', error);
    }

    try {
      // Attempt to get a list of thing groups from the IoT service.
      const data = await this.iotService.getThingGroups();

      // Use the optional chaining operator to avoid errors if thingGroups is undefined.
      // Extract the group names and store them in the thingGroups array.
      this.thingGroups = data?.thingGroups?.map(item => item.groupName) || [];

    } catch (error) {
      // Handle errors if they occur when fetching thing groups.
      console.error('Error fetching thing groups:', error);
    }

    // If the user is a distributor, it will set the selected distributor id to his own distributor id
    if(this.cognitoService.userType === 'distributor'){
      this.selectedDistributorId = this.cognitoService.distributorId;
    }
  }

  disableButton() {
    this.systemMessage.buttonBlocked = true;
  }

  // Fetch the list of clients
  getClientList(){
    try{
      // Use the clientService to fetch client data from an API
      this.clientService.getClients().subscribe((response: any) => {
          this.thingClient = response; // Fill the array with customer data
      });
    }
    catch(error){
      console.error("Error getting client list: ", error);
    }
  }

  // Function used to get all distributors
  async getDistributor(){
    try{
      // Call the getDistributor from distributor service to have the array of all distributors
      this.distributorService.getDistributors().subscribe((response: any) => {
        // Set his own distributor array
        this.distributorArray = response;
      });
    }
    catch(error){
      console.error("Error getting distributor list: ", error);
    }
  }

  /**
   * Check if a value is not null, not undefined, and not an empty string.
   * @param value - The value to check.
   * @returns true if the value is not null, not undefined, and not an empty string; otherwise, false.
   */
  checkInput(value: any) {
    // Check if the value is not null, not undefined, and not an empty string.
    return value !== null && value !== undefined && value !== "";
  }

  // Function triggered when user change the select of client
  async selectedClientChange(){
    // Reset the bin_detail_array to avoid multiple insert
    this.binService.resetBinDetailArray();

    // Get the bin array from bin service
    await this.binService.setBinDetailArray(this.selectedClient);
  }

  // Function to create a device
  async createDevice() {
    // Set binId in iot service to be abble to made the association in lambda funcion
    this.iotService.selectedBinId = this.selectedBinId;
    this.iotService.selectedClientId = this.selectedClient;
    this.iotService.selectedDistributorID = this.selectedDistributorId;


    if (this.checkInput(this.selectedDeviceType) && this.checkInput(this.selectedDeviceModel) && this.checkInput(this.selectedDeviceGroup) && this.selectedQuantity > 0) {
      // Generate a unique thing name by combining selectedDeviceType and selectedDeviceModel.
      let thingNamePlusThingModel = `${this.selectedDeviceType}-${this.selectedDeviceModel}`;

      // Set the IoT service's thingGroupName to selectedDeviceGroup.
      this.iotService.thingGroupName = this.selectedDeviceGroup;
      // Create multiple things using the IoT service with the specified quantity and thingNamePlusThingModel.
      try {
        await this.iotService.createMultipleThings(this.selectedQuantity, this.iotService.biggestDeviceNumber, thingNamePlusThingModel, this.isCustomSufix, this.customSufix);
        // Add an item to local storage indicating successful alerts update
        this.localStorageService.addItem('successCreateDevice', 'successCreateDevice');
        this.router.navigate(['/iot']); // Redirect to the '/iot' route
      } catch (error) {

        this.systemMessage.buttonBlocked = true;
        this.localStorageService.addItem('failCreateDevice', 'failCreateDevice');
        this.router.navigate(['/iot']); // Redirect to the '/iot' route
        console.error('Error:', error);
      }
    } else {
      this.systemMessage.buttonBlocked = true;
      this.systemMessage.selectRibbon('danger', "devicecreateValidationErrorMessage");
      this.validationStatus = false;
    }
    this.systemMessage.buttonBlocked = false;
  }

  // Function called by the change of the selet of bin to be sure that selectedQuantity have always a value
  binChange(){
    if(this.selectedBinId !== ''){
      this.selectedQuantity = 1;
    }else{
      this.selectedQuantity = 0;
    }
  }

  customSufixChange(){
    // Set the isCustonSufix to the oposite
    this.isCustomSufix = !this.isCustomSufix;

    // Set the quantity to create to 1 if user want to create a custom sufix for the device
    if(this.isCustomSufix === true){
      this.selectedQuantity = 1;
    }
  }

  // Function to cancel and navigate to the device list component
  cancelButton() {
    this.systemMessage.buttonBlocked = false;
    this.router.navigate(['/iot']);
  }

  // Function to change the language based on user selection
  changeLanguage(language: string) {
    // Use the 'translate' service to set the language to the specified 'language'
    this.translate.use(language);
  }
}
