<!-- Footer section -->
<footer>
  <div class="container-fluid">
    <div
      [ngClass]="theme.getThemeClass()"
      class="mw-footer-container px-3 py-0 text-bg-light border-bottom fixed-bottom d-flex justify-content-between align-items-center"
    >
      <!-- Left column with flag image and language selection -->
      <div class="column-left">
        <nav class="nav-select">
          <img
            src="assets/canadaflag.png"
            alt=""
            class="flag flagPic hide-small-screen"
          />
          <!-- Code to implement eventualy to get a custom select that will drop-up instead of drop-down and just be abble to choose between two language -->

          <!-- <div class="custom-dropdown language-select" [class.open]="isOpen" (click)="toggleDropdown()">
            <div class="selected-option">{{ selectedLanguage }}</div>
            <div class="options" [ngClass]="theme.getThemeClass()" *ngIf="isOpen">
              <div class="option" *ngFor="let option of options" (click)="selectOption(option)">{{ option }}</div>
            </div>
          </div> -->

          <select
            [(ngModel)]="selectedLanguage"
            (change)="onLanguageChange()"
            [ngClass]="theme.getThemeClass()"
            class="language-select"
          >
            <option value="fr" selected>FR</option>
            <option value="en">EN</option>
            <option value="es">ES</option>
          </select>
        </nav>
      </div>
      <!-- Main column with copyright information -->
      <nav class="column-main">
        <div>
          <a><p class="text-body-primary">&copy; {{ currentYear }} - Muirwood Studio</p></a>
        </div>
      </nav>
      <!-- Right column (empty for now) -->
      <div class="column-right .hide-small-screen">0.3.0.2</div>
    </div>
  </div>
</footer>
