export const State = {

    desired: {
      config: {
        dst: 1,
        env: 1,
        voc: 0,
        img: 0,
        lfr: 0,
        gps: 0,
        gtc: 1,
        slp: 3600,
        hit: -35,
        his: 28800
      }
    },
    reported: {
      dat: {
        fdv: '0.0.0',
        tmp: 0,
        sig: 0,
        vcc: 0,
        bat: 0,
        dst: 0,
        gps: [45.65081, -73.84834],
        tm0: 0,
        nct: 0,
        img: [],
        pre: 0,
        tm2: 0,
        hum: 0,
        hib: 0
      }
    }

};
