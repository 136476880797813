import { catchError, firstValueFrom, map } from 'rxjs';
import { Injectable, Inject, forwardRef } from '@angular/core';
import { CognitoService } from './cognito.service';
import { IotService, Devices } from './iot.service';
import { BinData, BinsService } from './bins.service';
import { ClientService } from './client.service';
import { DistributorsService } from './distributors.service';
import { OperatorService } from './operator.service';
import { TaxeTypeService } from './taxe-type.service';
import { ChartService } from './chart.service';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../environments/environment';
import { PdfService } from './pdf.service';
import { HttpClient } from '@angular/common/http';
import { WorkOrderService } from './work-order.service';
import { TruckService } from './truck.service';
import { BinUsage } from '../constants/bin-usage';
import { TruckTypes } from '../constants/truck-types';


export interface BinArrayItem {
  thing_name: string;
  bin_id: string;
  client_id?: string;
  distributor_id?: string;
}

interface StatusData {
  healthy?: number,
  unhealthy?: number,
  inactive?: number,
}

@Injectable({
  providedIn: 'root'
})
export class DashboardService {
  // For global use
  public userType: string = '';
  public userRole: string = '';
  public adminArray: any[] = [];
  public clientArray: any[] = [];
  public distributorArray: any[] = [];
  public operatorArray: any[] = [];
  public collectionArray: any[] = [];

  // For admin dashboard
  public userCount: number = 0;
  public binCount: number = 0;
  public binModelCount: number = 0;
  public taxTypeCount: number = 0;
  public clientCount: number = 0;
  public distributorCount: number = 0;
  public operatorCount: number= 0;
  public deviceCount: number = 0;
  public healthyDeviceCount: number = 0;
  public unhealthyDeviceCount: number = 0;
  public relationships: BinArrayItem[] = [];

  // For distributor dashboard
  public distributorBinModelCount: number = 0;
  public distributorBinCount: number = 0;
  public distributorClientCount: number = 0;
  public distributorBinModelArray: any[] = [];
  public distributorBinArray: any[] = [];
  public distributorDeviceArray: any[] = [];
  public distributorDeviceCount: number = 0;
  public distributorAdminCount: number = 0;
  public distributorDistributorCount: number = 0;
  public distributorOperatorCount: number = 0;
  public distributorCollectionCount: number = 0;
  public distributorWorkOrderArray: any[] = [];
  public distributorWorkOrderCount: number = 0;

  // For distributor work order status count pie chart
  public distributorWorkOrderStatusCompleteCount: number = 0;
  public distributorWorkOrderStatusInProgressCount: number = 0;
  public distributorWorkOrderStatusPausedCount: number = 0;
  public distributorWorkOrderStatusCancelledCount: number = 0;
  public distributorWorkOrderStatusErrorCount: number = 0;
  public distributorWorkOrderStatusDelayedCount: number = 0;
  public distributorWorkOrderStatusOpenCount: number = 0;
  public distributorWorkOrderStatusAssignedCount: number = 0;
  public binFillLevelLow: number = 0;
  public binFillLevelMedium: number = 0;
  public binFillLevelHigh: number = 0;

  //For truck usage type count bar chart
  public constructionMaterial:number = 0

  // variable to control the loading element
  public stopLoading: boolean = false;

  // for client dashboard
  public healthy: number = 0;
  public unhealthy: number = 0;
  public inactive: number = 0;
  public clientAdminCount: number = 0;
  public clientClientCount: number = 0;
  public clientCollectionCount: number = 0;
  public binDataArray: BinData[] = [];
  public countStatus: StatusData[] = [];
  public finalStats: any[] = [];
  public things: string[] = [];

  // For Operator dashboard
  public trucks4Graph:any[] = []
  public frontLoaderTruckCount:number = 0
  public rearLoaderTruckCount:number = 0
  public sideLoaderTruckCount:number = 0
  public rollOffTruckCount:number = 0
  public grappleTruckCount:number = 0
  public hookLiftTruckCount:number = 0
  public sateliteTruckCount:number = 0
  /*
/*   
   {'key':'0', 'value':'front_loaders'},
    {'key':'1', 'value':'rear_loaders'},
    {'key':'2', 'value':'side_loaders'},
    {'key':'3', 'value':'roll_off_trucks'},
    {'key':'4', 'value':'grapple_trucks'},
    {'key':'5', 'value':'hook_lift_trucks'},
    {'key':'6', 'value':'satellite_trucks'} */ 

  constructor(
    public users: CognitoService,
    public devices: IotService,
    public bins: BinsService,
    public clients: ClientService,
    public distributors: DistributorsService,
    public operators: OperatorService,
    public taxes: TaxeTypeService,
    private charts: ChartService,
    @Inject(forwardRef(() => TranslateService)) @Inject(forwardRef(() => TranslateService)) private translate: TranslateService,
    public pdf: PdfService,
    private iot: IotService,
    private http: HttpClient,
    public workOrderService: WorkOrderService,
    public truckService: TruckService,
    private cognitoService: CognitoService
  ) { }

  // set array to nothing
  initArrays(){
    this.adminArray = [];
    this.clientArray = [];
    this.distributorArray = [];
    this.operatorArray = [];
    this.collectionArray = [];
  }

  // initializes data for the client dashboard
  async initializeClientData(
    clientId: string,
    adminCount: number, clientCount: number, collectionCount: number,
    workOrdersComplete: number, workOrdersInProgress: number,
    workOrdersPaused: number, workOrdersCanceled: number, workOrdersError: number,
    workOrdersDelayed: number, workOrdersOpen: number, workOrdersAssigned: number,
    ) {
      this.things = await this.getThingNamesByEntityId(this.cognitoService.clientId);
      const data = await this.devices.countDashboardDevicesAndParameters(this.things);

      this.countBinStatus(JSON.parse(data.shadows), JSON.parse(data.bins));

      this.devices.thingStatusArray = data.status;
      this.devices.thingsAllData = data;
      this.healthy = parseInt(data.healthy);
      this.unhealthy = parseInt(data.unhealthy);
      this.inactive = parseInt(data.inactive);
      this.relationships = data.relationships;
      this.deviceCount = (this.healthy + this.unhealthy + this.inactive);

      try{
      // device status count to chart
      this.charts.singlePieChart = [
        { "name": await this.charts.getChartLabel('chartLabelHealthy'), "value": this.healthy},
        { "name": await this.charts.getChartLabel('chartLabelUnhealthy'), "value": this.unhealthy},
        { "name": await this.charts.getChartLabel('inactive'), "value": this.inactive}
      ];

      // user list count to chart
      this.charts.singleBarChartAdmin = [
        { "name": await this.charts.getChartLabel('chartLabelAdmin'), "value": adminCount },
        { "name": await this.charts.getChartLabel('chartLabelClients'), "value": clientCount },
        { "name": await this.charts.getChartLabel('chartLabelCollection'), "value": collectionCount }
      ]

      // this.charts.totalPieChart = healthyCount + unhealthyCount + inactiveCount;

      // bin usage count to chart
      this.charts.singleBarChart = [
        { "name": await this.charts.getChartLabel('chartLabelLowFillLevel'), "value": this.binFillLevelLow},
        { "name": await this.charts.getChartLabel('chartLabelMediumFillLevel'), "value": this.binFillLevelMedium},
        { "name": await this.charts.getChartLabel('chartLabelHighFillLevel'), "value": this.binFillLevelHigh}
      ]

      // work order status count to chart
      this.charts.singlePieChartSlice = [
        { "name": await this.charts.getChartLabel('Complete'), "value": workOrdersComplete },
        { "name": await this.charts.getChartLabel('In-progress'), "value": workOrdersInProgress },
        { "name": await this.charts.getChartLabel('Paused'), "value": workOrdersPaused },
        { "name": await this.charts.getChartLabel('Cancelled'), "value": workOrdersCanceled },
        { "name": await this.charts.getChartLabel('Error'), "value": workOrdersError },
        { "name": await this.charts.getChartLabel('Delayed'), "value": workOrdersDelayed },
        { "name": await this.charts.getChartLabel('Open'), "value": workOrdersOpen },
        { "name": await this.charts.getChartLabel('Assigned'), "value": workOrdersAssigned }
      ]
      // Truck usage type count to chart
      this.charts.TrucksBarChart = [
        { "name": await this.charts.getChartLabel('binSelectOptionConstructionMaterial'), "value": this.constructionMaterial }
      ]

      // Assuming your divs have a class named 'chart-container'
      var targetDivs = document.querySelectorAll('.chart-container');

      // Check if there are elements with the specified class
      if (targetDivs.length > 0) {
          // Iterate through the NodeList and set the display property to flex for each element
          targetDivs.forEach(function(div) {
            if (div instanceof HTMLElement) {
                div.style.display = 'flex';
            }
        });
      } else {
          console.error('No elements found with the specified class');
      }

      this.stopLoading = true
    }
    catch(e){
      this.stopLoading = true;
      console.error(e);
    }
  }

  // initializes data for the admin dashboard
  async initializeDashboardData(){
    const data = await this.devices.countDashboardDevicesAndParameters(); // Contain bins, shadow, configs, healthy, unhealthy, inactive, statusArray as status, relationships and production_status for the things given
    await this.countApplicationBinModels();
    await this.countApplicationBins();
    await this.countApplicationClients();
    await this.countApplicationDistributors();
    await this.countApplicationOperators();
    await this.countApplicationUsers();
    await this.countTaxTypes();

    this.devices.thingStatusArray = data.status;
    this.devices.thingsAllData = data;
    this.healthy = parseInt(data.healthy);
    this.unhealthy = parseInt(data.unhealthy);
    this.inactive = parseInt(data.inactive);
    this.relationships = data.relationships;
    this.deviceCount = (this.healthy + this.unhealthy + this.inactive);

    // device status count to chart
    this.charts.singlePieChart = [
      { "name": await this.charts.getChartLabel('chartLabelHealthy'), "value": this.healthy},
      { "name": await this.charts.getChartLabel('chartLabelUnhealthy'), "value": this.unhealthy},
      { "name": await this.charts.getChartLabel('inactive'), "value": this.inactive}
    ];

    this.charts.totalPieChart = this.deviceCount;

    // user list count to chart
    this.charts.singleBarChart = [
      { "name": await this.charts.getChartLabel('chartLabelClients'), "value": this.clientCount},
      { "name": await this.charts.getChartLabel('chartLabelDistributors'), "value": this.distributorCount},
      { "name": await this.charts.getChartLabel('chartLabelOperators'), "value": this.operatorCount},
    ];

    this.charts.singleBarChartAdmin = [
      // bin count to chart
      { "name": await this.charts.getChartLabel('chartLabelBins'), "value": this.binCount},

      // bin model count to chart
      { "name": await this.charts.getChartLabel('chartLabelBinModels'), "value": this.binModelCount},

      // user count to chart
      { "name": await this.charts.getChartLabel('chartLabelUsers'), "value": this.userCount},

      // tax type count to chart
      { "name": await this.charts.getChartLabel('chartLabelTaxTypes'), "value": this.taxTypeCount},
    ];
      // Assuming your divs have a class named 'chart-container'
    var targetDivs = document.querySelectorAll('.chart-container');

    // Check if there are elements with the specified class
    if (targetDivs.length > 0) {
        // Iterate through the NodeList and set the display property to flex for each element
        targetDivs.forEach(function(div) {
          if (div instanceof HTMLElement) {
              div.style.display = 'flex';
          }
      });
    } else {
        console.error('No elements found with the specified class');
    }

    this.stopLoading = true;
  }

  

  // initializes data for the distributor dashboard
  async initializaDistributorDashboardData(){
    // Set the array of things that belong to the connected distributor user
    this.things = await this.getThingNamesByEntityId(this.cognitoService.distributorId);
    const data = await this.devices.countDashboardDevicesAndParameters(this.things); // Contain bins, shadow, configs, healthy, unhealthy, inactive, statusArray as status, relationships and production_status for the things given
    await this.getBinModelAssociationByDistributor();
    await this.getAllDistributorUsers();
    await this.getAllDistributorWorkOrders();

    this.healthy = parseInt(data.healthy);
    this.unhealthy = parseInt(data.unhealthy);
    this.inactive = parseInt(data.inactive);
    this.deviceCount = (this.healthy + this.unhealthy + this.inactive);
    this.devices.thingStatusArray = data.status;
    this.devices.thingsAllData = data;

    this.charts.singleBarChart = [
      { "name": await this.charts.getChartLabel('chartLabelBinModels'), "value": this.distributorBinModelCount},
      { "name": await this.charts.getChartLabel('chartLabelBins'), "value": this.distributorBinCount},
      { "name": await this.charts.getChartLabel('chartLabelClients'), "value": this.distributorClientCount}
    ]

    // device status count to chart
    this.charts.singlePieChart = [
      { "name": await this.charts.getChartLabel('chartLabelHealthy'), "value": this.healthy},
      { "name": await this.charts.getChartLabel('chartLabelUnhealthy'), "value": this.unhealthy},
      { "name": await this.charts.getChartLabel('Inactive'), "value": this.inactive}
    ]

    this.charts.totalPieChart = this.distributorDeviceCount;

    // users count to chart
    this.charts.singleBarChartAdmin = [
      { "name": await this.charts.getChartLabel('chartLabelAdmin'), "value": this.distributorAdminCount },
      { "name": await this.charts.getChartLabel('chartLabelDistributors'), "value": this.distributorDistributorCount },
      { "name": await this.charts.getChartLabel('chartLabelOperator'), "value": this.distributorOperatorCount },
      { "name": await this.charts.getChartLabel('chartLabelCollection'), "value": this.distributorCollectionCount }
    ]

    // work order status count to chart
    this.charts.singlePieChartSlice = [
      { "name": await this.charts.getChartLabel('Complete'), "value": this.distributorWorkOrderStatusCompleteCount },
      { "name": await this.charts.getChartLabel('In-progress'), "value": this.distributorWorkOrderStatusInProgressCount },
      { "name": await this.charts.getChartLabel('Paused'), "value": this.distributorWorkOrderStatusPausedCount },
      { "name": await this.charts.getChartLabel('Cancelled'), "value": this.distributorWorkOrderStatusCancelledCount },
      { "name": await this.charts.getChartLabel('Error'), "value": this.distributorWorkOrderStatusErrorCount },
      { "name": await this.charts.getChartLabel('Delayed'), "value": this.distributorWorkOrderStatusDelayedCount },
      { "name": await this.charts.getChartLabel('Open'), "value": this.distributorWorkOrderStatusOpenCount },
      { "name": await this.charts.getChartLabel('Assigned'), "value": this.distributorWorkOrderStatusAssignedCount }
    ]

      // // Assuming your divs have a class named 'chart-container'
      var targetDivs = document.querySelectorAll('.chart-container');

      // Check if there are elements with the specified class
      if (targetDivs.length > 0) {
          // Iterate through the NodeList and set the display property to flex for each element
          targetDivs.forEach(function(div) {
            if (div instanceof HTMLElement) {
                div.style.display = 'flex';
            }
        });
      } else {
          console.error('No elements found with the specified class');
      }
      this.stopLoading = true;
  }
 // initialize data fo the operator dashboard
 async initializeOperatorDashboardData(){
  //set the data for the dashboard tiles
 try{
  this.trucks4Graph = await this.truckService.getTrucks4Graph(); //get array of truck per usage
  //console.log(this.trucks4Graph) #debug
  if(this.trucks4Graph.length > 0){
    // count truck per types
    this.setTruckCounts(this.trucks4Graph)
    // Set the original truck array for graph
   let orginalBarChartAdmin  = [
      { "name": await this.charts.getChartLabel('front_loaders'), "value": this.frontLoaderTruckCount },
      { "name": await this.charts.getChartLabel('rear_loaders'), "value": this.rearLoaderTruckCount },
      { "name": await this.charts.getChartLabel('side_loaders'), "value": this.sideLoaderTruckCount },
      { "name": await this.charts.getChartLabel('roll_off_trucks'), "value": this. rollOffTruckCount },
      { "name": await this.charts.getChartLabel('grapple_trucks'), "value": this.grappleTruckCount },
      { "name": await this.charts.getChartLabel('hook_lift_trucks'), "value": this.hookLiftTruckCount },
      { "name": await this.charts.getChartLabel('satellite_trucks'), "value": this.sateliteTruckCount },
    ]
    // Filter out trucks with a null count and set the truck graph array
    this.charts.singleBarChartAdmin = orginalBarChartAdmin.filter((t:any)=>{
      return t.value !== 0
    })
  } 
  //display chart after 3s
    setTimeout(()=>{
       // // Assuming your divs have a class named 'chart-container'
     var targetDivs = document.querySelectorAll('.chart-container');

     // Check if there are elements with the specified class
     if (targetDivs.length > 0) {
         // Iterate through the NodeList and set the display property to flex for each element
         targetDivs.forEach(function(div) {
           if (div instanceof HTMLElement) {
               div.style.display = 'flex';
           }
       });
     } else {
         console.error('No elements found with the specified class');
     }
     this.stopLoading = true;
    },3000)
 }catch(e){
  console.error(e)
 }
}
/**
 * Function that count trucks per type and save it
 * @param data an array of truck 
 */
setTruckCounts(data:any[]){
  data.forEach(item => {
    switch(item.truck_type){
      case '0':
        this.frontLoaderTruckCount = parseInt(item.truck_count, 10);
        break;
      case '1':
        this.rearLoaderTruckCount = parseInt(item.truck_count, 10);
        break;
      case '2':
        this.sideLoaderTruckCount = parseInt(item.truck_count, 10);
        break;
      case '3':
        this.rollOffTruckCount = parseInt(item.truck_count, 10);
        break;
      case '4':
        this.grappleTruckCount = parseInt(item.truck_count, 10);
        break;
      case '5':
        this.hookLiftTruckCount = parseInt(item.truck_count, 10);
        break;
      case '6':
        this.sateliteTruckCount = parseInt(item.truck_count, 10);
        break;
    }
  });
}
  //Sets the total number of Users in numeric form as a service property
  async countApplicationUsers(){
    await this.users.getAllUsers().then((res)=>{
        this.userCount = res.length;
    }).catch((error)=>{
      console.error("Error: ", error);
    })
  }

  //Sets the total number of Bins in numeric form as a service property
  async countApplicationBins(){
    await this.bins.getBins().then((res)=>{
      this.binCount = res.length;
    }).catch((error)=>{
      console.error("Error: ", error);
    })
  }
  //Get bin label from bin usage value
  getBinLabel(binUsageValue:string):string {
    const bin = BinUsage.find(b => b.value === binUsageValue);
    console.log(bin?.label)
    return bin ? bin.label : binUsageValue; // Return 'Unknown' if no match is found
  }
  /**
   * Get truck type from truck type key
   * @param key the key mapped to truck type 
   * @returns the truck type
   */
  getTruckType(key:string):string{
    const type = TruckTypes.find(t=> t.key === key)
    return type!.value
  }

  //Sets the total number of Bin Models in numeric form as a service property
  async countApplicationBinModels(){
    await this.bins.getBinsModel().then((res)=>{
      this.binModelCount = res.length;
    }).catch((error)=>{
      console.error("Error: ", error);
    })
  }

  //Sets the total number of Tax Types in numeric form as a service property
  async countTaxTypes(){
    await this.taxes.getTaxeTypes().then((res)=>{
      this.taxTypeCount = res.length;
    }).catch((error)=>{
      console.error("Error: ", error);
    })
  }

  //Sets the total number of clients in numeric form as a service property
  async countApplicationClients(){
    await this.clients.getClients().subscribe((res)=>{
      try{
        let clientArray = JSON.parse(JSON.stringify(res));
        this.clientCount = clientArray.length;
      }
      catch(error){
        console.error("Error: ", error);
      }
    });
  }

  //Sets the total number of distributors in numeric form as a service property
  async countApplicationDistributors(){
    this.distributors.getDistributors().subscribe((res)=>{
      try{
        let distributorArray = JSON.parse(JSON.stringify(res));
        this.distributorCount = distributorArray.length;
      }
      catch(error){
        console.error("Error: ", error);
      }
    });
  }

  //Sets the total number of operators in numeric form as a service property
  async countApplicationOperators(){
    await this.operators.getOperators().then((res)=>{
      try{
        let OperatorArray = JSON.parse(JSON.stringify(res));
        this.operatorCount = OperatorArray.length;
      }
      catch(error){
        console.error("Error: ", error);
      }
    });
  }

  // Method get data to fill charts based on devices status and bin fill levels
  countBinStatus(devicesArray: any[], bins: BinData[]) {
    // Loop through each device in the array
    devicesArray.forEach(item => {
      const binData =  this.bins.filterBinData(bins, item.thing_name) ;
      let binHeight = binData?.bin_height ?? 0;
      // Extract the numeric fill level by removing "%" and replacing commas
      const fixedFillLevel = 100 - (parseFloat(item.dst)/binHeight*100);

      // Check the fill level and increment corresponding counters
      switch (true) {
        case fixedFillLevel < 0:
          //Do nothing, these values don't count
          break;
        case fixedFillLevel <= 60:
            this.binFillLevelLow++;
            break;
        case fixedFillLevel <= 79.09:
            this.binFillLevelMedium++;
            break;
        case fixedFillLevel >= 79.09 && fixedFillLevel <= 100:
            this.binFillLevelHigh++;
            break;
      }
    });
  }

  // Function to retrieve thing names associated with a given client or distributor ID
  // Returns an array of matching thing names
  async getThingNamesByEntityId(id: string): Promise<string[]> {
    // Array to store matching thing names
    const matchingThingNames: string[] = [];

    if(this.relationships.length === 0){
      this.relationships = await this.getRelationships();
    }

    // Iterate through relationships in the dashboard service
    this.relationships.forEach(item => {
      switch(true){
        case id.startsWith('CL'):
          // Check if the client ID matches the specified client ID
          if (item.client_id === id) {
            // Add the thing name to the array if the client ID matches
            matchingThingNames.push(item.thing_name);
          }
          break;

        case id.startsWith('DI'):
          // Check if the client ID matches the specified client ID
          if (item.distributor_id === id) {
            // Add the thing name to the array if the client ID matches
            matchingThingNames.push(item.thing_name);
          }
      }
    });

    // Return the array of matching thing names
    return matchingThingNames;
  }

  // Asynchronous function to retrieve bin model association details by distributor
  async getBinModelAssociationByDistributor(){
    // Fetching bin model association details based on distributor ID
    await this.distributors.getBinModelAssociationByDistributorId(this.users.distributorId).then((res) => {
      try{
        // Assigning the retrieved array to the distributorBinArray property
        this.distributorBinArray = res;
        // Updating counts based on the first element of the retrieved array
        this.distributorBinCount = this.distributorBinArray[0].bin_count;
        this.distributorBinModelCount = this.distributorBinArray[0].bin_model_count;
        this.distributorClientCount = this.distributorBinArray[0].client_count;
      }catch(error){
        // Handling any potential errors and logging them
        console.error('Error: ', error);
      }
    });
  }

  // Asynchronous function to retrieve devices by distributor ID
  async getDeviceByDistributorId(){
    await this.distributors.getDeviceByDistributorId(this.users.distributorId).then((res) => {
      try{

      }catch(error){
        // Handling any potential errors and logging them
        console.error('Error: ', error);
      }
    });
  }

  // Asynchronous function to retrieve all users associated with a distributor
  async getAllDistributorUsers(){
    // Fetching users based on distributor ID
    await this.distributors.getUsersByDistributorId(this.users.distributorId).then((res) => {
      try{
        // Iterating through the response array of users
        for(let users of res){
          // Categorizing users based on their user_type
          switch(users.user_type){
            case 'admin':
              this.adminArray.push(users);
              break;
            case 'distributor':
              this.distributorArray.push(users);
              break;
            case 'operator':
              this.operatorArray.push(users);
              break;
            case 'collection':
              this.collectionArray.push(users);
              break;
          }
        }
        // Updating counts for each user type
        this.distributorAdminCount = this.adminArray.length;
        this.distributorDistributorCount = this.distributorArray.length;
        this.distributorOperatorCount = this.operatorArray.length;
        this.distributorCollectionCount = this.collectionArray.length;
      }catch(error){
        // Handling any potential errors and logging them
        console.error('Error: ', error);
      }
    });
  }

  // Function called to get all relationship of a device
  async getRelationships(): Promise<BinArrayItem[]>{
    // Append the 'user' parameter to the URL as a query string
    const url = environment.api.stage + environment.api.route.getDeviceRelationships;

    const response = await firstValueFrom(this.http.get(url));

    const dataArray = JSON.parse(JSON.stringify(response));

    const data: BinArrayItem[] = dataArray.map((data: any) => ({
        thing_name: data.thing_name,
        bin_id: data.bin_id,
        client_id: data.client_id,
        distributor_id: data.distributor_id
    }));
    this.relationships = data;
    return data;
  }

  // Function called to get all work order for a distributor
  async getAllDistributorWorkOrders(){
    await this.workOrderService.getAllEntityWorkOrders(this.users.distributorId).then((res) => {
      try{
        this.distributorWorkOrderArray = res;
        this.distributorWorkOrderCount = this.distributorWorkOrderArray.length;
        this.distributorWorkOrderStatusCompleteCount = this.distributorWorkOrderArray.filter(workOrder => workOrder.status === '_complete').length;
        this.distributorWorkOrderStatusInProgressCount = this.distributorWorkOrderArray.filter(workOrder => workOrder.status === '_in-progress').length;
        this.distributorWorkOrderStatusPausedCount = this.distributorWorkOrderArray.filter(workOrder => workOrder.status === '_paused').length;
        this.distributorWorkOrderStatusCancelledCount = this.distributorWorkOrderArray.filter(workOrder => workOrder.status === '_cancelled').length;
        this.distributorWorkOrderStatusErrorCount = this.distributorWorkOrderArray.filter(workOrder => workOrder.status === '_error').length;
        this.distributorWorkOrderStatusDelayedCount = this.distributorWorkOrderArray.filter(workOrder => workOrder.status === '_delayed').length;
        this.distributorWorkOrderStatusOpenCount = this.distributorWorkOrderArray.filter(workOrder => workOrder.status === '_open').length
        this.distributorWorkOrderStatusAssignedCount = this.distributorWorkOrderArray.filter(workOrder => workOrder.status === '_assigned').length;
      }catch(error){
        console.error('Error: ', error);
      }
    });
  }

  // Function called to check if the
  isDateGapGreaterThan48Hours(dateToCompare: Date): boolean {
    // Get the current date and time
    const currentDate = new Date();

    // Calculate the time difference in milliseconds
    const timeDifference = currentDate.getTime() - dateToCompare.getTime();

    // Convert the time difference to hours
    const hoursDifference = timeDifference / (1000 * 60 * 60);

    // Check if the gap is greater than 48 hours
    return hoursDifference > 48;
  }

  // Function that return a bin by a thing name
  isBinIdPopulated(thingName: string, array: BinArrayItem[]): boolean {
    const matchingItem = array.find(item => item.thing_name === thingName);

    return !!matchingItem && !!matchingItem.bin_id;
  }

  // Function that create a device object
  getDevicesStructure(shadow: any, bin: any, config: any){
    //Set some temporary variables
    let latitude = 0;
    let longitude = 0;
    let gpsBoolean = 0;
    let fmv = '';
    let icc = 0;
    let imei = 0;
    let oper = '';
    let phn = '';
    const thingDetails = this.iot.getThingDetails(shadow.thing_name);

    // Set the date in format 'Thu Feb 29 2024 13:14:29 GMT-0500 (Eastern Standard Time)'
    const date = new Date(shadow.dst_timestamp * 1000);
    const formatedDate = this.iot.formatDate(date);

    const device: Devices = {
      thingName: shadow.thing_name,
      bin: bin,
      thingARN: thingDetails?.thingARN ?? "",
      thingTypeName: thingDetails?.thingTypeName ?? "",
      gps: { latitude : latitude,longitude : longitude},
      location: '',
      lastUpdate: shadow.dst_timestamp ,
      date: formatedDate,
      config: this.iot.filterDeviceConfig(config, shadow.thing_name, 'last'),
      previous_config: this.iot.filterDeviceConfig(config, shadow.thing_name, 'second_last'),
      car: {
        fmv:  shadow.fmv ?? fmv,
        icc:  shadow.icc ?? icc,
        imei: shadow.imei ?? imei,
        oper: shadow.oper ?? oper,
        phn:  shadow.phn ?? phn,
      },
      dat: {
        bat: shadow.bat,
        dst: shadow.dst,
        fdv: shadow.fdv,
        hib: shadow.hib,
        hum: shadow.hum,
        img: shadow.img,
        nct: shadow.nct,
        pre: shadow.pre,
        sig: shadow.sig,
        tm0: shadow.tm0,
        tm2: shadow.tm2,
        tmp: shadow.tmp,
        vcc: shadow.vcc,
        voc: shadow.voc,
      }
    }
    return device;
  }
}
