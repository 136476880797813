<div [ngClass]="theme.getThemeClass()" class="container-fluid">
  <div class="page-header">
    <!-- For future development -->
    <h2>{{"binListViewBinList" | translate}}</h2>
  </div>
  <nav
    id="search-component"
    class="nav-sort-filter"
  >
    <input
      #searchBox
      type="search"
      id="search-box"
      [(ngModel)]="userFilter"
      (input)="
        this.search()
      "
      class="form-control rounded searchInput"
      placeholder="{{ 'userlistSearchInputPlaceHolder' | translate }}"
      aria-label="Search"
      aria-describedby="search-addon"
    />
    <ng-select class="form-control ng-select sort-by-select" [(ngModel)]="this.filterBy"
      placeholder="{{ 'filterBy' | translate }}" [ngClass]="{'has-value': this.filterBy }">
      <ng-option value="" disabled selected>{{ "filterBy" | translate }}</ng-option>
          <ng-option value="bin_address">{{ "binAddress" | translate }}</ng-option>
          <ng-option value="thing_name">{{ "iotModalViewDeviceName" | translate }}</ng-option>
          <ng-option value="legal_name">{{ "clientcreateFormInputClientName" | translate }}</ng-option>
    </ng-select>
    <ng-select class="form-control ng-select sort-by-select" [(ngModel)]="this.sortBy"
      (change)="onSortChange('select', $event)" placeholder="{{ 'sortBy' | translate }}" [ngClass]="{'has-value': this.sortBy}">
      <ng-option value="" disabled selected>{{ "sortBy" | translate }}</ng-option>
          <ng-option value="bin_address">{{ "binAddress" | translate }}</ng-option>
          <ng-option value="thing_name">{{ "iotModalViewDeviceName" | translate }}</ng-option>
          <ng-option value="legal_name">{{ "clientcreateFormInputClientName" | translate }}</ng-option>
    </ng-select>
    <div class="card-title mt-0 status">
      <label class="switch">
          <input type="checkbox" [ngModel]="this.ascDesc" (change)="onSortChange('switch', $event)">
          <span class="slider round">
            <span class="toggle-switch-label-off">{{ "asc" | translate }}</span>
            <span class="toggle-switch-label-on">{{ "desc" | translate }}</span>
          </span>
      </label>
    </div>
  </nav>
  <section class="createBtns mb-2">
    <button [ngClass]="theme.getThemeClass()" class="btn btn-dropdown"  (click)="routeToBinCreate();">
      {{  "newBin" | translate }}
    </button>
  </section>
<div class="grid-container">
  <section [ngClass]="theme.getThemeClass()" class="gridster" [ngStyle]="getColumnStyle()" >
    <ng-container *ngFor="let item of this.distributors.binArray ; let i = index">
    <article class="gridster-item">
      <!-- Your tile content goes here -->
        <nav [ngClass]="theme.getThemeClass()" class="dashboard-tile">
          <header class="dashboard-tile-header">
            <a [ngClass]="theme.getThemeClass()" [routerLink]="['/bin-update', item.bin_id]">
              <h6 class="dashboard-tile-title">{{ item.bin_name || ("binNoAddress" | translate) }}</h6>
            </a>

            <div class="thing" *ngIf="item.thing_name">
              <i class="fa-solid fa-microchip device-icon"></i><label class="item-label thing-name">{{ item.thing_name }}</label>
            </div>
          </header>
          <hr>
          <div class="tiles-container">
            <!-- Section for bin infos -->
            <div class="bin-infos">
              <div class="addressFormat" (click)="displayAddress(i)" style="cursor: pointer;">
                <i class="fa-solid fa-map-marker pointer"></i><label style="cursor: pointer; "  id="{{i}}" class="address">{{ item.bin_address || "N/A" }}</label>
              </div>
              <div class="titles-items-usage">
                <i class="fa fa-trash"></i>
                <label class="item-label">{{ item.bin_usage | translate }}</label>
              </div>
            </div>
            <!-- Section for client related to the bin -->
            <hr class="hr-middle">
            <div class="client-infos">
              <div class="titles-items">
                <i class="fa fa-user"></i><label class="item-label">{{ item.legal_name || "N/A" }}</label>
              </div>
              <div class="titles-items" (click)="displayAddress('id-' + i)" style="cursor: pointer;">
                <i class="fa fa-map-marker pointer"></i><label style="cursor: pointer;" [id]="'id-' + i" class="item-label address">{{ item.address || ("binNoAddress" | translate) }}</label>
              </div>
              <div class="titles-items">
                <i class="fa fa-phone"></i><label class="item-label">{{ item.phone_number || "N/A" }}</label>
              </div>
              <div class="titles-items">
                <i class="fa-solid fa-at"></i><label class="item-label">{{ item.email || "N/A" }}</label>
              </div>
            </div>
          </div>
        </nav>
      </article>
  </ng-container>
</section>
<div class="filler"></div>
</div>
</div>

