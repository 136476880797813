<!-- Displaying a custom system message component -->
<app-system-message></app-system-message>

<!-- Main content of the device configuration modal desktop version -->
<div
  [ngClass]="theme.getThemeClass()"
  [style]="this.getDisplayByUserType()"
  class="device-modal-graphs"
  *ngIf="
    this.iotService.showDeviceTechnicalGraphs &&
    this.cognitoService.userType === 'muirwood'
  "
>

  <h5 class="model">{{ this.iotService.thingName }}</h5> <span  [ngClass]="theme.getThemeClass()" id="close" (click)="cancel()"><i class="fa-solid fa-xmark"></i></span>
  <div class="fixed">
    <div class="filters">
        <div class="form-group">
          <div class="force-column">
            <label class="form-label">{{ "startingDate" | translate }}</label>
            <input [(ngModel)]="startDate" class="form-control" type="date" max="{{this.reports.getCurrentDate()}}"/>
          </div>
        </div>
        <div class="form-group">
          <div class="force-column">
            <label class="form-label">{{ "endDate" | translate }}</label>
            <input [(ngModel)]="endDate" class="form-control" type="date" />
          </div>
        </div>
        <div class="applyFilter" >
            <button
                [ngClass]="theme.getThemeClass()"
                (click)="applyFilter(this.iotService.thingName)"
                class="form-control btn btn-primary btn-block"
              >
                {{ "apply" | translate }}
              </button>
        </div>
      </div>
  </div>
  <div #graphs class="graphs" >
    <line-chart-technical style="width: 50%; display: inline-block;"></line-chart-technical>
    <line-chart-temp style="width: 50%; display: inline-block;"></line-chart-temp>
  </div>
</div>
