import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ThemeService } from '../service/theme.service';
import { CognitoService } from '../service/cognito.service';
import { Drivers, TruckModalService } from '../service/truck-modal.service';
import { RoleService } from '../service/role.service';
import { TruckService } from '../service/truck.service';
import { environment } from '../environments/environment';
import { TruckBrands } from '../constants/truck-brands';
import { lastValueFrom } from 'rxjs';

@Component({
  selector: 'app-truck-modal',
  templateUrl: './truck-modal.component.html',
  styleUrls: ['./truck-modal.component.css', '../../global-elements.css']
})

export class TruckModalComponent implements OnInit {

  public brand: string = '';
  public userSub: string = '';
  public operator_id: string = '';
  public drivers: Drivers[] = [];
  public selectedDriver: string = '';

  constructor(
    public truckModal: TruckModalService,
    public theme: ThemeService,
    public translate: TranslateService,
    public trucks: TruckService,
    public cognitoService: CognitoService,
    public roleService: RoleService
  ){
  }

  async ngOnInit(){
    // Get user type, roles, confir that user is valid in cognito
    await this.cognitoService.getUserType();
    await this.cognitoService.confirmValidUser();
    await this.roleService.getRoles().then(async (result)=>{

      const userRoles = JSON.parse(JSON.stringify(result))
      // Check with user roles and type if he's alowed in that page, if not he'll be redirect to his dashboard
      await this.cognitoService.getCurrentRole([environment.users.role.administrator, environment.users.role.operations, environment.users.role.dispatch],
      [environment.users.superAdmin, environment.users.maintenance],
      true, userRoles);
      }
    );

    // Get the truck brand using the const
    this.brand = TruckBrands.find(x=> x.key === this.trucks.truck.brand)!.value;
    // Get our user sub
    this.userSub = await this.cognitoService.getUser().then((user) =>{
      return user.attributes.sub;
    });
    this.truckModal.userSub = this.userSub;
    // Get the operator linked to the driver
    this.operator_id = await this.cognitoService.getUser().then((user) =>{
      return user.attributes['custom:operations_id'];
    });
    this.truckModal.operator_id = this.operator_id;
    // Get our drivers associated with the operator
    const driversArray = await this.truckModal.getAllDriversByOperatorId()
    // Insert the drivers we got from the DB
    this.drivers.push(...driversArray);
    // Set our driver
    this.selectedDriver = this.trucks.truck.driver_id;
  }

  // Save button function
  async saveDriverAssociation(){
    // check if driver is already associated
    const isDriverAlreadyAssociated = this.drivers.find(driver => driver.user === this.selectedDriver)?.truck_id ? true : false;
    if(isDriverAlreadyAssociated){
      // Get the translated confirmation message based on the message key
      const messageKey$ = this.translate.get('driverAlreadyAssociated');
      const messageKey = await lastValueFrom(messageKey$);
  
      // Display a confirmation dialog with the translated message
      if (window.confirm(messageKey)) {
        // Delete and Update the driver-truck association in the DB using the selected driver and truck_id
        this.truckModal.updateDriverTruckAssociation((this.selectedDriver || ''), this.trucks.truck.truck_id, true);
        // Find the old truck own by the driver
        let oldTruck = this.trucks.trucksArray.find(driver => driver.driver_id === this.selectedDriver);
        // Remove the driver from its old truck in the trucksArray
        oldTruck!.username = ''; 
        oldTruck!.driver_id = '';
      }
    }
    else{
      //Update the driver-truck association in the DB using the selected driver and truck_id
      this.truckModal.updateDriverTruckAssociation((this.selectedDriver || ''), this.trucks.truck.truck_id);
    }
    // Find the new truck in the truckArray
    let newTruck = this.trucks.trucksArray.find(truck => truck.truck_id === this.trucks.truck.truck_id);
    // Update the truck with the new driver_id
    newTruck!.driver_id = this.selectedDriver;
    // Check for null values, this is used if we leave the select empty
    if(newTruck!.driver_id !== null){
      newTruck!.username = this.drivers.find(driver => driver.user === this.selectedDriver)!.username;
    }
    else{
      newTruck!.username = '';
    }
    // Close the modal window after saving the association
    this.closeModal();
    // Set our truck driver to the new selected driver
    //this.trucks.truck.driver_id = this.selectedDriver;
  }

  closeModal(){
    this.truckModal.showTruckModal = false;
  }
};
