import { Injectable } from '@angular/core';

Injectable({
  providedIn: 'root'
})

export const TruckBrands = [
  {"key":"0", "value":"American LaFrance"},
  {"key":"1", "value":"Autocar"},
  {"key":"2", "value":"Battle Motors"},
  {"key":"3", "value":"Chevrolet"},
  {"key":"4", "value":"Condor"},
  {"key":"5", "value":"Crane Carrier"},
  {"key":"6", "value":"Cycle successful"},
  {"key":"7", "value":"Dennis Eagle"},
  {"key":"8", "value":"Dodge"},
  {"key":"9", "value":"Elgin"},
  {"key":"10", "value":"Ford"},
  {"key":"11", "value":"Freightliner"},
  {"key":"12", "value":"GMC"},
  {"key":"13", "value":"G-S Products"},
  {"key":"14", "value":"Hino"},
  {"key":"15", "value":"International"},
  {"key":"16", "value":"Isuzu"},
  {"key":"17", "value":"Kenworth"},
  {"key":"18", "value":"Mack"},
  {"key":"19", "value":"Peterbilt"},
  {"key":"20", "value":"Sterling"},
  {"key":"21", "value":"Volvo"},
  {"key":"22", "value":"Western Star"},
  {"key":"23", "value":"Other"}
]