<meta charset="UTF-8">
<app-system-message style="margin-left: 20vw;"></app-system-message>
<!-- Container -->
<div [ngClass]="theme.getThemeClass()" class="container-fluid">
  <app-loading-element *ngIf="loading" class="loadingElementContainer componentBelow"></app-loading-element>

  <header class="mobileAlign2" *ngIf="this.dataLoaded">
    <h2 class="mobile">{{"userlistTableHeaderDeviceList" | translate}} ({{this.filteredArray.length}})</h2>
  </header>
  <nav *ngIf="this.dataLoaded" id="search-component" class="nav-sort-filter">
    <input
      #searchBox
      type="search"
      id="search-box"
      [(ngModel)]="userFilter"
      (input)="search()"
      class="form-control rounded searchInput"
      placeholder="{{ 'userlistSearchInputPlaceHolder' | translate }}"
      aria-label="Search"
      aria-describedby="search-addon"
    />
    <ng-select placeholder="{{ 'filterBy' | translate }}" [ngClass]="{'has-value': this.statusFilter}" (input)="onStatusFilterChange()" class="form-control ng-select sort-by-select" [(ngModel)]="statusFilter" (ngModelChange)="onStatusFilterChange()">
      <!-- <ng-option value="" disabled selected>{{ "filterBy" | translate }}</ng-option> -->
      <ng-option value="" disabled selected class="sort-by-header" >{{ 'filterBy' | translate }}</ng-option>


      <!-- FILTER BY STATUS -->
      <ng-option value="Healthy">{{ "Healthy" | translate }}</ng-option>
      <ng-option value="Unhealthy">{{ "Unhealthy" | translate }}</ng-option>
      <ng-option value="Inactive">{{ "Inactive" | translate }}</ng-option>

      <!-- FILTER BY BIN FILL LEVEL -->
      <ng-option value="low">{{ 'fillLevelLow' | translate }}</ng-option>
      <ng-option value="medium">{{ 'fillLevelMedium' | translate }}</ng-option>
      <ng-option value="high">{{ 'fillLevelHigh' | translate }}</ng-option>

      <!-- FILTER BY BATTERY LEVEL -->
      <ng-option value="lowBattery">{{ 'batteryLevelLow' | translate }}</ng-option>
      <ng-option value="mediumBattery">{{ 'batteryLevelMedium' | translate }}</ng-option>
      <ng-option value="highBattery">{{ 'batteryLevelHigh' | translate }}</ng-option>

      <!-- <ng-option value="legal_name">{{ "clientcreateFormInputClientName" | translate }}</ng-option> -->
    </ng-select>
    <ng-select class="form-control ng-select sort-by-select"
           [(ngModel)]="sortBy"
           (change)="onSortChange('select', $event)"
           [ngClass]="{'has-value': this.sortBy}"
           placeholder="{{ 'sortBy' | translate }}">
      <ng-option value="" disabled selected>{{ 'sortBy' | translate }}</ng-option>
      <ng-option value="battery">{{' '}}{{ 'BatteryLevel' | translate }}</ng-option>
      <ng-option value="binFillLevel">{{' '}}{{ 'BinLevel' | translate }}</ng-option>
      <ng-option value="lastUpdate">{{' '}}{{ 'LastActivity' | translate }}</ng-option>
    </ng-select>

    <div class="card-title status">
      <div class="mobileAlign">
        <label class="switch">
          <input type="checkbox" [ngModel]="this.ascDesc" (change)="onSortChange('switch', $event)">
          <span class="slider round">
              <span class="toggle-switch-label-off">{{ "desc" | translate }}</span>
              <span class="toggle-switch-label-on">{{ "asc" | translate }}</span>
          </span>
        </label>
      </div>
    </div>
  </nav>


  <!-- Gridster Parent Container -->
  <section *ngIf="this.dataLoaded" class="gridThing" [ngClass]="theme.getThemeClass()">

    <!-- Navigation Items -->
    <ng-container *ngFor="let thing of this.filteredArray; let i = index">
      <!-- Tile -->
      <article  class=gridsterItemClientThing>
        <!-- Your tile content goes here -->
        <nav [ngClass]="theme.getThemeClass()" class="dashboardThing-tile">
          <header class="dashboard-tile-header">
            <!-- Light for device state -->
            <div class="mouseover-div"
            (mouseenter)="this.showStateInfo[i] = true"
            (mouseleave)="this.showStateInfo[i] = false"
            (touchstart)="showStateInfo[i] = true"
            (touchend)="showStateInfo[i] = false"
            >
              <div class="trafic-light-box">
                <div class="traffic-light">
                  <div class="light" [ngClass]="{
                    'light-green': thing.heartbeat && thing.heartbeat.color === 'green',
                    'light-yellow': thing.heartbeat && thing.heartbeat.color === 'yellow',
                    'light-red': thing.heartbeat && thing.heartbeat.color === 'red'
                    }">
                  </div>
                </div>
              </div>
              <h6 class="dashboardThing-tile-title">{{thing.thing_name | translate}}</h6>

              <!-- Info bubble on mouse over -->
              <div class="info-state-box" [ngClass]="theme.getThemeClass()" *ngIf="showStateInfo[i]">
                <div class="state-info-layer">
                  <label class="state-title">
                    {{ "beatMiss" | translate }} :
                  </label>
                  <label class="state-info">
                    <span
                            *ngIf="this.iotService.isBeatMissMoreThenOneHundred(thing.heartbeat?.beat_miss)"
                            >+ </span
                          >
                    {{ thing.heartbeat.beat_miss }}
                  </label>
                </div>
                <div class="state-info-layer">
                  <label class="state-title">
                    {{ "suposeToCommunicate" | translate }} :
                  </label>
                  <label class="state-info">
                    {{ thing.heartbeat.supose_to_communicate}}
                  </label>
                </div>
                <div class="state-info-layer">
                  <label class="state-title">
                    {{ "sleepTimeConfig" | translate }} :
                  </label>
                  <label class="state-info">
                    {{ this.iotService.convertSecondToHour(thing.sleep_time) | translate }}
                  </label>
                </div>
              </div>

            </div>

            <i  *ngIf="thing.battery > 100" class="fa-solid fa-plug-circle-bolt"></i>
            <span class="dashboard-tile-action-container">

              <a class="nav-link e" href="javascript:void(0);" data-bs-toggle="dropdown">
                <i class="fa fa-bars"></i>
              </a>
              <ul [ngClass]="theme.getThemeClass()" class="dropdown-menu dropdown-menu-lg-end" >
                <!-- Menu Items -->
                  <li>
                    <a [ngClass]="theme.getThemeClass()" class="dropdown-item" style="cursor: pointer;" (click)="setNavToDeviceStat(thing.thing_name)" >
                      <i class="textAlignCenter" [ngClass]="thing.icon"></i>
                      <span class="customMarginLeft1">
                        {{ 'deviceStatistics' | translate }}
                      </span>
                    </a>
                  </li>
                  <li [ngClass]="{'closed': thing.bin_id === ''}">
                    <a (click)="routeToUpdateBin(thing.bin_id)" [ngClass]="theme.getThemeClass()" class="dropdown-item" href="javascript:void(0)">
                      <i class="textAlignCenter" [ngClass]="thing.icon"></i>
                      <span class="customMarginLeft1" [ngClass]="{'closed': thing.bin_id === ''}">
                        {{ 'viewBin' | translate }}
                      </span>
                    </a>
                  </li>
                  <li>
                    <a (click)="exportCSV(thing)" [ngClass]="theme.getThemeClass()" class="dropdown-item" href="javascript:void(0)">
                      <i class="textAlignCenter" [ngClass]="thing.icon"></i>
                      <span class="customMarginLeft1">
                        {{ 'exportCSV' | translate }}
                      </span>
                    </a>
                  </li>
                  <li>
                    <a (click)="this.iotService.showConfig(thing.thing_name)" [ngClass]="theme.getThemeClass()" class="dropdown-item" href="javascript:void(0)">
                      <i class="textAlignCenter" [ngClass]="thing.icon"></i>
                      <span class="customMarginLeft1">
                        {{ 'iotModalViewConfig' | translate }}
                      </span>
                    </a>
                  </li>
                  <li>
                    <a routerLink="/work-order-create/{{thing.thing_name}}" [ngClass]="theme.getThemeClass()" class="dropdown-item" href="javascript:void(0)">
                      <i class="textAlignCenter" [ngClass]="thing.icon"></i>
                      <span class="customMarginLeft1">
                        {{ 'createWorkOrder' | translate }}
                      </span>
                    </a>
                  </li>
                  <li>
                    <a (click)="this.associateDevice(thing.thing_name, 'client')" [ngClass]="theme.getThemeClass()" class="dropdown-item" href="javascript:void(0)">
                      <i class="textAlignCenter" [ngClass]="thing.icon"></i>
                      <span class="customMarginLeft1">
                        {{ 'clientAssociation' | translate }}
                      </span>
                    </a>
                  </li>
                  <li>
                    <a (click)="this.associateDevice(thing.thing_name, 'bin')" [ngClass]="theme.getThemeClass()" class="dropdown-item" href="javascript:void(0)">
                      <i class="textAlignCenter" [ngClass]="thing.icon"></i>
                      <span class="customMarginLeft1">
                        {{ 'binAssociation' | translate }}
                      </span>
                    </a>
                  </li>
              </ul>
            </span>
          </header>
          <hr>
          <div [ngClass]="theme.getThemeClass()" class="dashboardThing-tile-content" >
            <div class="marketLabel" >{{ thing.device.bin.bin_usage | translate }} {{ thing.device.bin.market_segment | translate }}</div>
            <div class="itemsDisplay">
              <div class="firstRow">
                <!-- HEALTHY -->
                <div class="itemsColum">
                  <div class="fa-3x">
                    <i *ngIf="thing.status === 'Healthy'" class="fa-solid fa-heart-pulse greenC" [title]="'Healthy'"></i>
                    <i *ngIf="thing.status === 'Inactive'" class="fa-solid fa-heart-pulse grayC" [title]="'Inactive'"></i>
                    <i *ngIf="thing.status === undefined" class="fa-solid fa-heart-pulse grayC" [title]="'Inactive'"></i>
                    <i *ngIf="thing.status === 'Unhealthy'" class="fa-solid fa-heart-pulse fa-fade redC" [title]="'Unhealthy'"></i>
                  </div>
                  <div class="iconLabel">
                    {{ thing.status | translate }}
                  </div>
                </div>

                <!-- BATTERY -->
                <div class="itemsColum">
                  <div class="fa-3x">
                    <i *ngIf="thing.status === 'Inactive'" class="fa-solid fa-battery-empty grayC"   [title]="'Inactive'"></i>
                    <i [ngStyle]="getBatteryLevel(thing.battery)" *ngIf="thing.battery > 3.5 && thing.status != 'Inactive'" class="fa-solid fa-battery-full"  [title]="thing.battery + '%'"></i>
                    <i [ngStyle]="getBatteryLevel(thing.battery)" *ngIf="thing.battery <= 3.5 && thing.battery > 2.5 && thing.status != 'Inactive'" class="fa-solid fa-battery-three-quarters"  [title]="thing.battery + '%'"></i>
                    <i [ngStyle]="getBatteryLevel(thing.battery)" *ngIf="thing.battery <= 2.5 && thing.battery > 1.5 && thing.status != 'Inactive'" class="fa-solid fa-battery-half"  [title]="thing.battery + 'V'"></i>
                    <i [ngStyle]="getBatteryLevel(thing.battery)" *ngIf="thing.battery <= 1.5 && thing.battery > 0 && thing.status != 'Inactive'" class="fa-solid fa-battery-quarter fa-fade"  [title]="thing.battery + '%'"></i>
                    <i [ngStyle]="getBatteryLevel(thing.battery)" *ngIf="thing.battery == 0 && thing.status != 'Inactive' " class="fa-solid fa-battery-empty fa-fade" [title]="thing.battery + '%'"></i>
                    <i [ngStyle]="getBatteryLevel(thing.battery)" *ngIf="thing.battery == null || thing.battery == undefined" class="fa-solid fa-battery-empty grayC"  [title]="'Inactive'"></i>
                  </div>
                  <div *ngIf="thing.battery >= 0 && thing.battery <= 100 && thing.status != 'Inactive'" class="iconLabel">
                    {{ thing.battery  + '%' | translate }}
                  </div>
                  <div *ngIf="thing.battery > 100 " class="iconLabel">
                    {{ 'charging' | translate }}
                  </div>
                  <div *ngIf=" thing.status === 'Inactive' " class="iconLabel">
                    {{ 'Inactive' | translate }}
                  </div>
                </div>
                <!-- FILL LEVEL -->
                <div class="itemsColum">
                  <div class="fa-3x">
                    <i [ngStyle]="getFillLevelStyle(thing.fill_level)" *ngIf="thing.status === 'Healthy'" class="fa-solid fa-trash-can grayC" [title]="thing.fill_level"></i>
                    <i [ngStyle]="getFillLevelStyle(thing.fill_level)" *ngIf="thing.status === 'Unhealthy'" class="fa-solid fa-trash-can fa-fade grayC" [title]="thing.fill_level"></i>
                    <i  *ngIf="thing.status === 'Inactive' || thing.status === undefined"  class="fa-solid fa-trash-can grayC" [title]="thing.fill_level"></i>
                  </div>
                  <div class="iconLabel">
                    {{ thing.fill_level | translate }}
                  </div>
                </div>
                <!-- TONNAGE -->
                <div class="itemsColum">
                  <div class="fa-3x">
                    <nav class="fadeIn first">
                      <img style="height: 35px; width: 35px;" class="logo" src="../assets/tonne-5123.webp" />
                    </nav>
                  </div>
                  <div class="iconLabel">
                    {{ thing.tonnage + ' T' }}
                  </div>
                </div>
              </div>
              <div class="secondRow">
                <div class="locationLabel">
                  <i class="fa-solid fa-map-pin locationLabel"></i>{{ thing.location }}
                </div>
              </div>
            </div>
          </div>
        </nav>
      </article>
    </ng-container>
    <article [ngClass]="theme.getThemeClass()" class="gridsterItemClientThing noDevice" *ngIf="this.filteredArray.length === 0 && this.originalArray.length != 0 ">
      <p>{{ 'noDevice' | translate}}</p>
      <!-- <h3><a href="javascript:void(0)" >Clear Filters</a></h3> -->
      <button [ngClass]="theme.getThemeClass()" class="btn clearFilters" (click)="clearFilters()">{{ 'clearFilter'|translate }}</button>
    </article>
  </section>

  <app-report-modal class="appDeviceConfigModal" [hidden]="!this.reports.showReportModal"></app-report-modal>



  </div>

  <!-- Graph Canvas for PDF Report -->
<div id="canvas-holder" class="canvasHolder">
  <canvas #tonnageCanvas class="displayNone"></canvas>
  <canvas #usageCanvas class="displayNone"></canvas>
  <canvas #marketCanvas class="displayNone"></canvas>
  <canvas #countCanvas class="displayNone"></canvas>
</div>

  <app-device-modal-config
    *ngIf="this.iotService.showDeviceConfigModal"
    class="appDeviceConfigModal"
></app-device-modal-config>

<app-distributor-iot-association-modal
  *ngIf="this.relationModalService.showAssociationModal"
  class="appAssociationModal"
  >
</app-distributor-iot-association-modal>
<app-system-message></app-system-message>

