import { CognitoService } from '../service/cognito.service';
import { stage } from './stage'





export const environment = {
  production: false,
  cognito :{
    userPoolId : "ca-central-1_Bp8ozfBkH",
    userPoolWebClientId: "7nsbfeejrak4egs9n8e024l1l0",
    region: 'ca-central-1',
    identityPoolId: 'ca-central-1:ff34f699-f6ec-43e4-a6f1-887f09da54df',
    sessionToken: sessionStorage.getItem("sessionToken") || "",
    accessKeyId: sessionStorage.getItem("accessKeyId") || "",
    secretAccessKey: sessionStorage.getItem("secretAccessKey") || "",
    userPoolClientId: 'ca-central-1_Bp8ozfBkH',
    username: 'true',
    email: 'false', // Optional
    phone: 'false', // Optional
  },
  sns: {
    url: "",
  },
  iot: {
    region: 'ca-central-1',
    accessKeyId: sessionStorage.getItem("accessKeyId") || "",
    secretAccessKey: sessionStorage.getItem("secretAccessKey") || "",
    sessionToken: sessionStorage.getItem("sessionToken") || "",
    endpoint: 'a1oadwr7wa53ee-ats.iot.ca-central-1.amazonaws.com',
    policy: 'arn:aws:iot:ca-central-1:478507580210:policy/MWS-B01'
 },
  api:{
    stage: stage,
    route: {
        createBin: 'createBin?action=createBin',
        createBinModel: 'createBinModel?action=createBinModel',
        createClient: 'createClient?action=createClient',
        createDeviceNote: 'createDeviceNote?action=createDeviceNote',
        createDistributor: 'createDistributor?action=createDistributor',
        createEntitiesLocations: 'createEntitiesLocations?action=createEntitiesLocation',
        createOperator: 'createOperator?action=createOperator',
        createTaxType: 'createTaxType?action=createTaxeType',
        createUser: 'createUser?action=createUser',
        createUserRole: 'createUserRole?action=createUserRoles',
        createWorkOrder: 'createWorkOrder?action=createWorkOrder',
        createClientZone: 'createClientZone?action=createClientZone',
        deleteUserRoles: 'deleteUserRoles?action=deleteUserRoles',
        deleteClientZone: 'deleteClientZone?action=deleteClientZone',
        deleteDeviceReferences: 'deleteDeviceReferences?action=deleteDeviceReferences',
        deleteDeviceLogs: 'deleteDeviceLogs?action=deleteDeviceLogs',
        deleteDeviceReportDatas: 'deleteDeviceReportDatas',
        deleteUserAdvanceAlertPreferences: 'deleteUserAdvanceAlertPreferences?action=deleteUserAdvanceAlertPreferences',
        enableDisableUser: 'enableDisableUser?action=enableDisableUser',
        getAllEntityWorkOrders: 'getAllEntityWorkOrder?action=getAllEntityWorkOrder',
        getAvailableDistributorDevices: 'getAvailableDistributorDevices?action=getAvailableDistributorDevice',
        getBinById: 'getBinById?action=getBinById',
        getBinModel: 'getBinModel?action=getBinModelById',
        getBins: 'getBins?action=getBins',
        getBinsByClientId: 'getBinsByClientId?action=getBinsByClientId',
        getBinModels: 'getBinModels?action=getBinModels',
        getBinModelAssociationsByDistributorId: 'getBinModelAssociationsByDistributorId?action=getBinModelAssociationByDistributorId',
        getClientBins: 'getBinsByClientId?action=getBinsByClientId',
        getClientById: 'getClientById?action=getClientById',
        getClientLegalName: 'getClientLegalName?action=getClientLegalName',
        getClientThings: 'getClientThings?action=getClientThings',
        getClientThingByThingName: 'getClientThingByThingName',
        getClients: 'getClients?action=getClients',
        getDevicesByBinId: 'getDevicesByBinId?action=getDeviceByBinId',
        getDeviceByDistributorId: 'getDeviceByDistributorId?action=getDeviceByDistributorId',
        getDeviceConfigs: 'getDeviceConfigs?action=getDeviceConfigs',
        getDeviceNotes: 'getDeviceNotes?action=getDeviceNotes',
        getDeviceRelationshipByThingname: 'getDeviceRelationshipByThingname?action=getDeviceRelationshipsByThingName',
        returnDeviceRelationShipsByThingNameWithRelated: 'returnDeviceRelationShipsByThingNameWithRelated?action=returnDeviceRelationShipsByThingNameWithRelated',
        getDistributorLegalName: 'getDistributorLegalName?action=getDistributorLegalName',
        getDistributors: 'getDistributors?action=getDistributors',
        getEntitiesLocations: 'getEntitiesLocations?action=getEntitiesLocations',
        getEntityLocationById: 'getEntityLocationById?action=getEntityLocationById',
        getHistoryByDate: 'getHistoryByDate?action=getHistoryByDate',
        getOperatorLegalName: 'getOperatorLegalName?action=getOperatorLegalName',
        getOperators: 'getOperators?action=getOperators',
        getUsersByDistributorId: 'getUserByDistributorId?action=getUserByDistributorId',
        getZones: 'getZones?action=getZones',
        getTaxType: 'getTaxType?action=getTaxType',
        getWorkOrders: 'getWorkOrders?action=getAllWorkOrders',
        getUserRoles: 'getUserRoles?action=getUserRoles',
        getUserAdvanceAlertPreferences: 'getUserAdvanceAlertPreferences?action=getUserAdvanceAlertPreferences',
        initializeDeviceforProduction: 'initializeDeviceforProduction?action=initializeDeviceforProduction',
        saveDeviceNote: 'saveDeviceNote?action=saveDeviceNote',
        setDeviceAssociation: 'setDeviceAssociation?action=setDeviceAssociations',
        setDeviceHealth:'setDeviceHealth?action=setDeviceHealth',
        setUserAdvanceAlertPreferences: 'setUserAdvanceAlertPreferences?action=setUserAdvanceAlertPreferences',
        setUserIsAdvanceAlertPreference: 'setUserIsAdvanceAlertPreference?action=setUserIsAdvanceAlertPreference',
        shadowUpdateLogs: 'shadowUpdateLogs?action=shadowUpdateLog',
        updateBin : 'updateBin?action=updateBin',
        updateBinModel: 'updateBinModel?action=updateBinModel',
        updateClient: 'updateClient?action=updateClient',
        updateDeviceAssociation: 'updateDeviceAssociation?action=updateDeviceAssociations',
        updateDeviceNote: 'updateDeviceNote?action=updateDeviceNote',
        updateDistributor: 'updateDistributor?action=updateDistributor',
        updateEntitiesLocations: 'updateEntitiesLocations?action=updateEntityLocation',
        updateOperator: 'updateOperator?action=updateOperator',
        updateTaxType: 'updateTaxType?action=updateTaxType',
        updateUser: 'updateUser?action=updateUser',
        updateUserRole: 'updateUserRole?action=updateUserRoles',
        updateClientZone: 'updateClientZone?action=updateClientZone',
        updateWorkOrder: 'updateWorkOrder?action=updateWorkOrder',
        getDeviceHistory: 'getDeviceHistory',
        getBinByDevice: 'getBinByDevice?action=getBinByDevice',
        getDeviceRelationships: 'getDeviceRelationships?action=getDeviceRelationships',
        carl: 'carl',
        getUsersByClientId: 'getUserByClientId?action=getUserByClientId',
        getReportFilterOptions: 'getReportFilterOptions?action=filters',
        getReportData: 'getReportData',
        getThingShadows: 'getThingShadows',
        getUserAlertPreferenceDB: 'getUserAlertPreferenceDB?action=getUserAlertPreferenceDB',
        updateUserAlertPreferenceDB: 'updateUserAlertPreferenceDB?action=updateUserAlertPreferenceDB',
        getBinsForDevices: 'getBinsForDevices?action=getBinsForDevices',
        deleteClientAndRelatedData: 'deleteClientAndRelatedData?action=deleteClientAndRelatedData',
        deleteDistributorAndRelatedData: 'deleteDistributorAndRelatedData?action=deleteDistributorAndRelatedData',
        getUser: 'getUser?action=getUser',
        saveProductionStatus: 'saveDeviceProductionStatus?action=saveDeviceProductionStatus',
        deleteOperator: 'deleteOperator?action=deleteOperator',
        getAllUsers: 'getAllUsers',
        getTodayRoutes: 'getTodayRoutes?action=getTodayRoutes',
        getOperatorClientsById: 'getOperatorClientsById?action=getOperatorClientsById',
        getClientsByMultipleIds: 'getClientsByMultipleIds?action=getClientsByMultipleIds',
        createTruck: 'createTruck?action=createTruck',
        getAllTrucks: 'getTrucks?action=getAllTrucks',
        getTrucks4Graph: 'getTrucks4Graph?action=getTrucks4Graph',
        getTrucksByOperatorId: 'getTrucksByOperatorId?action=getTrucksByOperatorId',
        getTruckByTruckId: 'getTruckByTruckId?action=getTruckByTruckId',
        updateTruck: 'updateTruck?action=updateTruck',
        sendJsonToAPI: 'sendJsonToAPI?action=callByCron',
        getAllDriversByOperatorId: 'getAllDriversByOperatorId?action=getAllDriversByOperatorId',
        updateDriverTruckAssociation: 'updateDriverTruckAssociation?action=updateDriverTruckAssociation',
        isTruckVINUnique: 'isTruckVINUnique?action=isTruckVINUnique',
        getOperatorZones: 'getOperatorZones?action=getOperatorZones',
        createOperatorZone: 'createOperatorZone?action=createOperatorZone',
        updateOperatorZone: 'updateOperatorZone?action=updateOperatorZone',
        deleteOperatorZone: 'deleteOperatorZone?action=deleteOperatorZone',
        getZonesAndAssociatedEntities: 'getZonesAndAssociatedEntities?action=getZonesAndAssociatedEntities',
        testWebsocket: 'testWebsocket?action=testWebsocket',
        getNotificationByUsersub: 'getNotificationByUsersub?action=getNotificationByUsersub',
        getTruckIdByDriverId: 'getTruckIdByDriverId?action=getTruckIdByDriverId',
        getRoutesIdByTruckId: 'getRoutesIdByTruckId?action=getRoutesIdByTruckId',
        getRouteStopByRouteId: 'getRouteStopByRouteId?action=getRouteStopByRouteId',
        getStopsDetails: 'getStopsDetails?action=getStopsDetails',
        getUsersDriversByOperatorId: 'getUsersDriversByOperatorId?action=getUsersDriversByOperatorId',
        updateRouteStopStatus: 'updateRouteStopStatus?action=updateRouteStopStatus',
        logRouteStop: 'logRouteStop?action=logRouteStop',
        startRoute: 'startRoute?action=startRoute',
        endRoute: 'endRoute?action=endRoute',
        getAllDevice: 'getAllDevice?action=getAllDevice',


    }
  },
  google: {
    APIKey: 'AIzaSyCyzMRxB8Re27NYcuI8KDFCEucI0GwHLHA&libraries=drawing'
  },
  users:{
    superAdmin: "muirwood",
    standardUser: "client",
    supplier: "distributor",
    maintenance: "operator",
    role: {
      administrator: "_admin",
      client: "_client",
      distributor: "_distributor",
      operations: "_operations",
      driver: "_driver",
      dispatch: "_dispatch"
    }

  },
  saml:{
    microsoft: {
      clientId: '58704f7c-1889-4e8e-9474-155df9919ce8',
      authority: 'https://login.microsoftonline.com/c0b50357-f018-4f60-bf08-47342010bf9b/',
      redirectUri: "https://ifm-dev.mwstudio.io/login",
      provider: "login.microsoftonline.com/c0b50357-f018-4f60-bf08-47342010bf9b/v2.0",
      domain: "sts.windows.net/c0b50357-f018-4f60-bf08-47342010bf9b",
      navigateToLoginRequestUrl: true,
      loginFailedRoute: '/login',
      cacheLocation: 'localStorage',
      storeAuthStateInCookie: true,
      scopes: ['user.read', 'openid']
    }
  },
  s3: {
    bucketName: 'ifm2-dev',
    folderPath: 'iot-certificates/'
  }
}
