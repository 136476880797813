<app-system-message style="margin-left: 20vw;"></app-system-message>
<div class="container-fluid" [ngClass]="theme.getThemeClass()">
  <!-- Loading Element -->
  <nav [ngClass]="theme.getThemeClass()" *ngIf="loading" class="loading-gif">
    <!-- Container with box styles and fade-in-out animation -->
    <div class="box fade-in-out">
      <!-- Font Awesome spinner icon with custom styles -->
      <div class="fa-3x mb-2">
        <i class="fa-solid fa-spinner fa-spin-pulse fa-2xl color"></i>
      </div>
      <!-- Logo container with dynamic theme class and navigation link -->
      <div>
        <a
          [ngClass]="theme.getThemeClass()"
          class="hide-too-small-screen navbar-brand px-1"
          href="javascript:void(0);"
          data-bs-toggle="dropdown"
        >
          <!-- Logo image with custom styles -->
          <img class="logo" src="../assets/muirwood-studio.png" />
        </a>
      </div>
    </div>
  </nav>

  <!-- Main Container -->
  <section id="device-statistics" [ngClass]="theme.getThemeClass()">
    <h4 class="filter-title">{{ "filters" | translate }}</h4>
    <nav class="filter-bar">
      <!-- Device Statistics Filters -->
      <div class="group1">
        <div class="form-group">
          <div class="force-column">
            <label class="form-label" id="deciveStats">{{
              "deviceStatistics" | translate
            }}</label>
            <ng-select
              disabled="true"
              [(ngModel)]="statistic"
              class="form-control ng-select"
              [ngClass]="{ 'has-value': statistic }"
            >
              <ng-option disabled value="Collections" selected>{{
                "collections" | translate
              }}</ng-option>
            </ng-select>
          </div>
        </div>

        <div class="form-group">
          <div class="force-column">
            <label class="form-label">{{ "startingDate" | translate }}</label>
            <input [(ngModel)]="startDate" class="form-control" type="date" />
          </div>
        </div>
      </div>

      <div class="group2">
        <div class="form-group">
          <div class="force-column">
            <label class="form-label">{{ "endDate" | translate }}</label>
            <input [(ngModel)]="endDate" class="form-control" type="date" />
          </div>
        </div>

        <div class="btn-holder col-3">
          <button
            [ngClass]="theme.getThemeClass()"
            (click)="applyFilter()"
            class="form-control btn btn-primary btn-block"
          >
            {{ "apply" | translate }}
          </button>
            <details [ngClass]="theme.getThemeClass()"   class="form-control btn btn-primary" #details>
            <summary>{{ "exportCSV" | translate }}</summary>
            <div>
              <button
              [ngClass]="theme.getThemeClass()"
                _ngcontent-ng-c915876578=""
                class="form-control btn btn-primary btn-bloc  "
                ng-reflect-ng-class="bg-dark text-white"
                [disabled]="!returnedResult"
                (click)=" exportCSVReports(reports.historyArray[0].thing_name,1)"
              >
              {{ "historyCSV" | translate }}
              </button>
            </div>
            <div>
              <button
              [ngClass]="theme.getThemeClass()"
              [style]="getDisabledButtonStyle()"
                _ngcontent-ng-c915876578=""
                class="form-control btn btn-primary btn-bloc  "
                ng-reflect-ng-class="bg-dark text-white"
                [disabled]="!returnedResult"
                (click)=" exportCSVReports(reports.historyArray[0].thing_name,2)"
              >
              {{ "binReportCSV" | translate }}
              </button>
            </div>
            <div>
              <button
              [ngClass]="theme.getThemeClass()"
              [style]="getDisabledButtonStyle()"
                _ngcontent-ng-c915876578=""
                class="form-control btn btn-primary btn-bloc  "
                ng-reflect-ng-class="bg-dark text-white"
                [disabled]="!returnedResult"
                (click)=" exportCSVReports(reports.historyArray[0].thing_name,3)"
              >
              {{ "collectionCSV" | translate }}
              </button>
            </div>
          </details>
        </div>
      </div>
    </nav>
    <nav class="page-header">
      <h3>{{ thing }}</h3>
      <p>{{ binData.bin_address }}</p>
    </nav>

    <ng-container *ngIf="returnedResult; else noDataTemplate">

      <!-- Device Information heremofo -->
      <article id="deviceInfo">
        <h4>{{ "deviceInformation" | translate }}</h4>
        <table class="info-table">
          <tr>
            <td class="table-header">
              {{ "collectionAlertThreshold" | translate }}:
            </td>
            <td>{{ binThreshold }}</td>
          </tr>
          <tr>
            <td class="table-header">{{ "binHeightMM" | translate }}:</td>
            <td>{{ binData.bin_height ? binData.bin_height : 'N/A' }}</td>
          </tr>
          <tr *ngIf="binData.bin_depth && binData.bin_depth > 0">
            <td class="table-header">{{ "binDepthMM" | translate }}</td>
            <td>{{ binData.bin_depth }}</td>
          </tr>

          <tr>
            <td class="table-header">{{ "binVolume" | translate }}:</td>
            <td>{{ binData.bin_volume ? binData.bin_volume + 'L' : 'N/A' }}</td>
          </tr>
          <tr>
            <td class="table-header">{{ "binShape" | translate }}:</td>
            <td>{{  bins.getBinShape(binData.bin_shape) | translate }}</td>
          </tr>
          <tr>
            <td class="table-header">{{ "deviceHealth" | translate }}:</td>
            <td>
              {{
                displayHealth(
                  reports.historyArray[0].thing_name,
                  timestamp,
                  bat
                ) | translate
              }}
            </td>
          </tr>
          <tr>
            <td class="table-header">{{ "battery" | translate }}:</td>
            <td id="battery">
              {{
                getBatteryPercentage(
                  reports.historyArray[reports.historyArray.length - 1].bat
                )
              }}%
            </td>
          </tr>
          <tr>
            <td class="table-header">
              {{ "mostDaysBetweenCollections" | translate }}:
            </td>
            <td>{{ !isFiniteNumber(maxDaysWithNoCollections) ? '(N/A)' : maxDaysWithNoCollections }}</td>
          </tr>
          <tr>
            <td class="table-header">
              {{ "leastDaysBetweenCollections" | translate }}:
            </td>
            <td>{{  !isFiniteNumber(minDaysWithNoCollections) ? '(N/A)' : minDaysWithNoCollections }}</td>
          </tr>
          <tr>
            <td class="table-header">{{ "binUsageType" | translate }}:</td>
            <td>{{ bins.getBinUsage(binData.bin_usage) | translate }}</td>
          </tr>
          <tr>
            <td class="table-header">
              {{ "wasteCollectedTonnes" | translate }}:
            </td>
            <td>{{ totalWasteCollected }}</td>
          </tr>
          <tr>
            <td class="table-header">{{ "binStatus" | translate }}:</td>
            <td>{{ translateStatus(binData.active) | translate }}</td>
          </tr>
          <tr>
            <td class="table-header">{{ "totalCollections" | translate }}:</td>
            <td>{{ this.totalCollections }}</td>
          </tr>
        </table>
      </article>


       <!-- Line Chart Displaying Fill Rate -->
       <article *ngIf="validFillRate">
        <h4>{{ "fillRate" | translate }}</h4>
        <app-line-chart></app-line-chart>
      </article>

      <!-- Line Chart Displaying Battery and Vcc Rate -->
      <article>
        <div>
        <button
        [ngClass]="theme.getThemeClass()"
        class="btn btn-primary btn-block chartSwitch selectedChart"
        id="bat"
        (click)=" toggleLineChart(0)">
          {{ "battery" | translate }}
        </button>
        <button
          [ngClass]="theme.getThemeClass()"
          class="btn btn-primary btn-block chartSwitch"
          id="vcc"
          (click)=" toggleLineChart(1)">
            {{ "VCC"|translate }}
        </button>
        </div>
        <div #battery>
          <h4 style="width: 100%; text-align: center;">{{'batRate'|translate}} {{reports.historyArray[0].thing_name}}</h4>
            <app-line-chart-bat></app-line-chart-bat>
          </div>
          <div #VCC class="displayeNone">
          <h4 style="width: 100%; text-align: center;">{{'vccRate'|translate}} {{reports.historyArray[0].thing_name}}</h4>
            <app-line-chart-vcc></app-line-chart-vcc>
          <div class="line-chart-container">
            <h6>{{charts.xAxisLabelLineChartAdmin}}</h6>
            </div>
          </div>
      </article>


      <!-- Horizontal Bar Chart for Collections per Month -->
      <article *ngIf="validFillRate">
        <h4>{{ "xAxisCollectionBarChartLabel" | translate }}</h4>
        <app-bar-chart-admin></app-bar-chart-admin>
      </article>

      <!-- Vertical Bar Chart Displaying the fill rate of collections -->
      <article *ngIf="validFillRate">
        <h4>{{ "collectionFillRatio" | translate }}</h4>
        <app-bar-chart-collections></app-bar-chart-collections>
      </article>

      <!-- Vertical Bar Chart Displaying the number of days between collections -->
      <!-- *ngIf="collections.length > 1" -->
      <article  *ngIf="validFillRate">
        <h4>{{ "daysBetweenCollection" | translate }}</h4>
        <app-bar-chart></app-bar-chart>
      </article>


    </ng-container>
    <ng-template #noDataTemplate>
      <article>
        <p class="noDataTemplate">{{ "notEnoughData" | translate }}</p>
        <button
          [ngClass]="theme.getThemeClass()"
          class="btn btn-primary btn-block"
          (click)="toPreviousPage()"
        >
          {{
            "toPreviousPage" | translate
          }}
        </button>
      </article>
    </ng-template>
  </section>
</div>
