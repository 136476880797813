import { Injectable } from '@angular/core';
import { HttpClient , HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../environments/environment';
import { map, catchError } from 'rxjs/operators'; // Import map from 'rxjs/operators'

@Injectable({
  providedIn: 'root'
})
export class ZoneService {

  zoneData = {
    owner_id: "", // this is the client_id, operator_id etc.
    zone_coordinates: "",
    zone_name: "",
    modified: "",
    created: "",
    created_by: "",
    active: "",
    zone_color: "",
    owner_name: "",
    zone_id: "",
    type_of_operation: "",
    waste_type: "",
  }

  public zonesArray: any[] = [];

  public zoneCreated: any;
  public zoneUpdated: boolean = false;

  constructor(private http: HttpClient) { }

  // Function used to set all zone datas to it's initial value
  clearZoneData() {
    this.zoneData.owner_id = "",
    this.zoneData.zone_coordinates = "",
    this.zoneData.zone_name = "",
    this.zoneData.modified = "",
    this.zoneData.created = "",
    this.zoneData.created_by = "",
    this.zoneData.active = "",
    this.zoneData.zone_color = "",
    this.zoneData.owner_name = "",
    this.zoneData.type_of_operation = "",
    this.zoneData.waste_type= ""
  }

  // function that set zonearray with all zones
  getZones() {
    // Append the 'user' parameter to the URL as a query string
    const url = environment.api.stage + environment.api.route.getZones;

    return this.http.get(url).pipe(
      map((response) => {
        // return the response data
        this.zonesArray.push(response)
        return response;
      }),
      catchError((error) => {
        console.error('API Error:', error);
        throw error(error); // Re-throw the error for the calling code to handle
      }));
  }

   // function that set zonearray with all zones and their associated entities ids and names
   getZonesAndAssociatedEntities() {
    // Append the 'user' parameter to the URL as a query string
    const url = environment.api.stage + environment.api.route.getZonesAndAssociatedEntities;

    return this.http.get(url).pipe(
      map((response) => {
        // return the response data
        this.zonesArray.push(response);
        return response;
      }),
      catchError((error) => {
        console.error('API Error:', error);
        throw error(error); // Re-throw the error for the calling code to handle
      }));
  }

  // Function that call API Gateway to create a client zone
  createClientZone(): Observable<any> {

    // Define the HTTP headers with content type
    const headers = new HttpHeaders({
      'Content-Type':  'application/json' // Adjust content type as needed
    });

    return this.http.post(environment.api.stage + environment.api.route.createClientZone,
      {
        "client_id": this.zoneData.owner_id,
        "zone_coordinates": this.zoneData.zone_coordinates,
        "zone_name": this.zoneData.zone_name,
        "created": this.zoneData.created,
        "created_by": this.zoneData.created_by,
        "active": this.zoneData.active,
        "zone_color": this.zoneData.zone_color
      }, {headers: headers}
    );
  }

  // Function that call API Gateway to update client zone
  updateClientZone(): Observable<any> {

    // Define the HTTP headers with content type
    const headers = new HttpHeaders({
      'Content-Type':  'application/json' // Adjust content type as needed
    });

    return this.http.post(environment.api.stage + environment.api.route.updateClientZone,
      {
        "zone_id": this.zoneData.zone_id,
        "zone_coordinates": this.zoneData.zone_coordinates,
        "zone_name": this.zoneData.zone_name,
        "modified": this.zoneData.modified,
        "active": this.zoneData.active,
        "zone_color": this.zoneData.zone_color,
        "type_of_operation": this.zoneData.type_of_operation
      }, {headers: headers}
    );
  }

  // Function that call API Gateway to delete a client zone
  deleteClientZone(): Observable<any> {

    // Define the HTTP headers with content type
    const headers = new HttpHeaders({
      'Content-Type':  'application/json' // Adjust content type as needed
    });

    return this.http.post(environment.api.stage + environment.api.route.deleteClientZone,
      {
        "zone_id": this.zoneData.zone_id,
      }, {headers: headers}
    );
  }


  /////////////////////////
  // OPERATORS ZONES CRUD//
  /////////////////////////

  // function that set zonearray with all zones
  getOperatorZones(operator_id: string) {
  // Append the 'user' parameter to the URL as a query string
  const url = `${environment.api.stage}${environment.api.route.getOperatorZones}`;
  const body = {operator_id: encodeURIComponent(operator_id)};
  return this.http.post(url, body).pipe(
    map((response: any) => {
      // return the response data
      this.zonesArray.push(...response)
      return response;
    }),
    catchError((error) => {
      console.error('API Error:', error);
      throw error(error); // Re-throw the error for the calling code to handle
    }));
  }

  // Function that call API Gateway to create a client zone
  createOperatorZone(): Observable<any> {

    // Define the HTTP headers with content type
    const headers = new HttpHeaders({
      'Content-Type':  'application/json' // Adjust content type as needed
    });

    return this.http.post(environment.api.stage + environment.api.route.createOperatorZone,
      {
        "operator_id": this.zoneData.owner_id,
        "zone_coordinates": this.zoneData.zone_coordinates,
        "zone_name": this.zoneData.zone_name,
        "created": this.zoneData.created,
        "created_by": this.zoneData.created_by,
        "active": this.zoneData.active,
        "zone_color": this.zoneData.zone_color,
        "waste_type": this.zoneData.waste_type
      }, {headers: headers}
    );
  }

  // Function that call API Gateway to update client zone
  updateOperatorZone(): Observable<any> {

    // Define the HTTP headers with content type
    const headers = new HttpHeaders({
      'Content-Type':  'application/json' // Adjust content type as needed
    });
    return this.http.post(environment.api.stage + environment.api.route.updateOperatorZone,
      {
        "zone_id": this.zoneData.zone_id,
        "zone_coordinates": this.zoneData.zone_coordinates,
        "zone_name": this.zoneData.zone_name,
        "modified": this.zoneData.modified,
        "active": this.zoneData.active,
        "zone_color": this.zoneData.zone_color,
        "waste_type": this.zoneData.waste_type
      }, {headers: headers}
    );
  }

  // Function that call API Gateway to delete a client zone
  deleteOperatorZone(): Observable<any> {

    // Define the HTTP headers with content type
    const headers = new HttpHeaders({
      'Content-Type':  'application/json' // Adjust content type as needed
    });

    return this.http.post(environment.api.stage + environment.api.route.deleteOperatorZone,
      {
        "zone_id": this.zoneData.zone_id,
      }, {headers: headers}
    );
  }
}
