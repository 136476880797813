<app-system-message></app-system-message>
<!-- Container for the form with dynamic styling -->
<div [ngClass]="theme.getThemeClass()" class="container-fluido">
    <section [ngClass]="theme.getThemeClass()" class="card bg-light">
        <article class="card-body mx-auto article">
            <h4 class="card-title mt-0 text-center">{{ 'taxeTypeCreateViewCreateTaxeType' | translate }}</h4>
            <form>
                <!-- select for country -->
                <label class="label-spacer">{{ "country" | translate }} <span class="requiredStar">*</span></label>
                <div class="input-group mb-3">
                    <span class="input-group-text" id="basic-addon1"><i class="fa-solid fa-earth-americas"></i></span>
                    <select [(ngModel)]="this.country" (change)="updateTaxeTypeArray('country')" class="form-control" name="country"
                    aria-label="Country" aria-describedby="basic-addon1">
                        <option value="" disabled selected>{{ "taxeTypeCreateViewSelectCountry" | translate }}</option>
                        <ng-container *ngFor="let option of this.countryISOConstant">
                            <option value="{{ option.value }}">{{ option.label | translate }}</option>
                        </ng-container>
                    </select>
                </div>
                <!-- Select for provincial/federal taxe -->
                <label class="label-spacer">{{ "taxeTypeViewTaxeType" | translate }} <span class="requiredStar">*</span></label>
                <div class="input-group mb-3">
                    <span class="input-group-text" id="basic-addon1"><i class="fa-solid fa-filter-circle-dollar"></i></span>
                    <select [(ngModel)]="this.is_federal" (change)="updateTaxeTypeArray(this.is_federal)" class="form-control" name="is_federal"
                    aria-label="is Federal" aria-describedby="basic-addon1">
                        <option value="" disabled selected>{{ "taxeTypeViewTaxeFor" | translate }}</option>
                        <option value="1">{{ "taxeTypeViewFederal" | translate }}</option>
                        <option value="0">{{ "taxeTypeViewProvincial" | translate }}</option>
                    </select>
                </div>
                <!-- Select for province, only showed if the select for provincial taxe -->
                <label *ngIf="this.is_federal === '0'" class="label-spacer">{{ "province" | translate }} <span class="requiredStar">*</span></label>
                <div class="input-group mb-3" *ngIf="this.is_federal === '0'">
                    <span class="input-group-text" id="basic-addon1"><i class="fa fa-hashtag"></i></span>
                    <select [(ngModel)]="this.province" (change)="updateTaxeTypeArray('province')" class="form-control" name="province"
                    aria-label="Province" aria-describedby="basic-addon1">
                        <option value="" disabled selected>{{ "taxeTypeCreateViewSelectProvince" | translate }}</option>
                        <ng-container *ngFor="let option of this.provinceISOConstant">
                            <option value="{{ option.value }}">{{ option.label | translate }}</option>
                        </ng-container>
                    </select>
                </div>
                <!-- Select for taxe type / taxe name -->
                <label class="label-spacer">{{ "taxAcronym" | translate }} <span class="requiredStar">*</span></label>
                <div class="input-group mb-3">
                    <span class="input-group-text" id="basic-addon1"><i class="fa-regular fa-square-check"></i></span>
                    <select [(ngModel)]="this.type" class="form-control" name="taxe type"
                    aria-label="taxe type" aria-describedby="basic-addon1">
                        <option value="" disabled selected>{{ "taxeTypeCreateViewEnterType" | translate }}</option>
                        <ng-container *ngFor="let option of this.taxeTypeArray">
                            <option value="{{ option.label }}">{{ option.label | translate }} : {{ option.name | translate }} {{ option.province | translate }}</option>
                        </ng-container>
                    </select>
                </div>
                <!-- Input for taxe rate -->
                <label class="label-spacer">{{ "taxRate" | translate }} <span class="requiredStar">*</span></label>
                <div class="input-group mb-3">
                    <span class="input-group-text" id="basic-addon1"><i class="fa-solid fa-percent"></i></span>
                    <input [(ngModel)]="this.rate" type="text"
                    class="form-control" name="taxe-type-rate"
                    placeholder="{{ 'taxeTypeCreateViewEnterRate' | translate }}" aria-label="taxe rate"
                    aria-describedby="basic-addon1"
                    (blur)="this.validationService.validateTaxeRate(this.rate)"
                    [class.error-border]="this.validationService.validationErrorArray[0] == 'taxeRateInvalid'">
                </div>
                <!-- Submit button -->
                <nav class="form-group buttonDiv">
                    <button (click)="returnToAdmin()" [ngClass]="theme.getThemeClass()" type="button" class="btn btn-block btnCancel">{{ 'clientcreateFormBtnCancel' | translate }}</button>
                    <button [disabled]="this.systemMessageService.buttonBlocked" [ngClass]="theme.getThemeClass()" (click)="onSubmit(); disableButton()" type="button" class="btn btn-block btnCreate">{{ 'create' | translate }}</button>
                </nav>
            </form>
        </article>
    </section>
</div>

