import { Component } from '@angular/core';
import { ChartService } from '../service/chart.service';
import { ThemeService } from '../service/theme.service';

@Component({
  selector: 'app-pie-chart-single',
  templateUrl: './pie-chart-single.component.html',
  styleUrl: './pie-chart-single.component.css'
})
export class PieChartSingleComponent {

  constructor(public theme: ThemeService,
              public charts: ChartService){
    //Declare chart data as a constant
    const single = charts.singlePieChart
    //Assign data to chart object
    Object.assign(this, { single });
  }
}
