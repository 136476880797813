  <div class="hideNavBar"></div>
  <!-- Modal for work order status change -->
  <article *ngIf="this.workOrderModalService.showStatusModal && !this.workOrderModalService.applyingChanges">
    <label class="title">{{ "workOrderStatus" | translate }}</label>
    <div class="select-div">
      <ng-select class="form-control ng-select" [(ngModel)]="this.selectedStatus" (change)="this.workOrderModalService.statusChange(this.selectedStatus)" [attr.selected]="this.selectedStatus">
        <ng-option *ngFor="let status of this.statusArray" value="{{ status.value }}">{{ status.label | translate }}</ng-option>
      </ng-select>
    </div>
  </article>
  <!-- Modal for work order deadline date change -->
  <article *ngIf="this.workOrderModalService.showDeadlineDateModal && !this.workOrderModalService.applyingChanges">
    <label class="title">{{ "deadline" | translate }}</label>
    <div class="date-picker-div">
      <input matInput [matDatepicker]="picker" (dateChange)="this.workOrderModalService.deadlineDateChange(this.selectedDate)" [(ngModel)]="this.selectedDate" class="form-control" placeholder="{{this.convertTimestamp(this.selectedDate)}}">
      <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-datepicker #picker></mat-datepicker>
    </div>
  </article>
  <!-- Modal for work order description change -->
  <article *ngIf="this.workOrderModalService.showDescriptionModal && !this.workOrderModalService.applyingChanges">
    <label class="title">{{ "workOrderDescription" | translate }}</label>
    <textarea [(ngModel)]="this.selectedDescription" class="text-description" (input)="this.workOrderModalService.descriptionChange(this.selectedDescription)"></textarea>
  </article>
  <!-- Modal for work order comments change -->
  <article *ngIf="this.workOrderModalService.showCommentModal && !this.workOrderModalService.applyingChanges">
    <label class="title">{{ "writeComments" | translate }}</label>
    <textarea [(ngModel)]="this.selectedComments" class="text-description" (input)="this.workOrderModalService.commentChange(this.selectedComments)"></textarea>
  </article>
  <nav class="button-bar" *ngIf="!this.workOrderModalService.applyingChanges">
    <button (click)="this.workOrderModalService.close()" [ngClass]="theme.getThemeClass()" type="button" class="btn btn-primary btn-block btnCancel fixed-size">{{ 'clientcreateFormBtnCancel' | translate }}</button>
    <button [ngClass]="theme.getThemeClass()" (click)="this.workOrderModalService.submitChange()" type="button" class="btn btn-primary btn-block btnCreate fixed-size">{{ 'clienteditFormBtnUpdateClient' | translate }}</button>
  </nav>

