
<div class="mat-stepper-container">
  <mat-stepper [linear]="true" 
  [ngClass]="theme.getThemeClass()" 
  orientation="vertical" 
  [selectedIndex]="activeStepIndex"
  (selectionChange)="onStepChange($event)"
  class="stepper-container">

    <mat-step class="text-white" [ngClass]="theme.getThemeClass()" [editable]="false" [completed]="isStepCompleted(0)" label="Start">
      <ng-template matStepLabel>
        <div class="stepLabels bg-transparent" [ngClass]="theme.getThemeClass()">
          {{ "Start" | translate }}
        </div>
      </ng-template>
      <p><strong>{{ "Departure Information:" | translate }} </strong></p>
      <p><strong>{{ "Operator" | translate }}: </strong> {{ selectedTruckInfo.operator_id }}</p>
      <p><strong>{{ "Nickname" | translate }}: </strong> {{ selectedTruckInfo.nickname }}</p>
      <p><strong>{{ "VIN" | translate }}:</strong> {{ selectedTruckInfo.vin }}</p>
      <p><strong>{{ "Year" | translate }}:</strong> {{ selectedTruckInfo.year }}</p>
    </mat-step>
    
    <ng-container *ngFor="let route of selectedTruckInfo.routes; let i = index">
      <mat-step class="text-white" [ngClass]="theme.getThemeClass()" [editable]="false" [completed]="isStepCompleted(i + 1)" [label]="'Stop ' + (i + 1)">
        <ng-template matStepLabel>
          <div [ngClass]="theme.getThemeClass()">
            {{ getBinAddress(route) }}
          </div></ng-template>
        <p><strong>{{ "Estimated stint time:" | translate }} {{ convertSecondsToMinutes(getTotalApproximateTime(route)) }} {{ "minutes" | translate }}</strong></p>
      </mat-step>
    </ng-container>
    
    <mat-step [editable]="false" label="Return/Arrival">
      <ng-template matStepLabel>
        <div [ngClass]="theme.getThemeClass()">
          {{ "Arrival" | translate }}
        </div>
      </ng-template>
      <!-- <p><strong>Return or Arrival Information:</strong></p> -->
      <!-- <p>Here we can include information about the return or final arrival of the truck.</p> -->
    </mat-step>
  </mat-stepper>

</div>


    




  
  