import { Injectable } from '@angular/core';

Injectable({
  providedIn: 'root'
})

export const RouteStopErrors = [
  { "value" : "0", "label" : "_obstruction"},
  { "value" : "1", "label" : "_binCondition"},
  { "value" : "2", "label" : "_technicalIssue"},
  { "value" : "3", "label" : "_wrongWasteType"},
  { "value" : "4", "label" : "_other"}
];