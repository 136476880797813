import { ClientService } from './../service/client.service';
import { Component, ElementRef, HostListener, OnInit, Inject, forwardRef } from '@angular/core';
import { LocalStorageService } from '../local-storage.service';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { CognitoService } from '../service/cognito.service';
import { RoleService } from '../service/role.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ValidationService } from '../service/validation.service';
import { ThemeService } from '../service/theme.service';
import { DistributorsService } from '../service/distributors.service';
import { NavigationService } from '../service/navigation.service';
import { SystemMessageService } from '../service/system-message.service';
import { RelationshipModalService } from '../service/relationship-modal.service';
import { BinArrayItem, DashboardService } from '../service/dashboard.service';
import { lastValueFrom } from 'rxjs';
import { BinsService } from '../service/bins.service';

@Component({
  selector: 'app-distributors-edit',
  templateUrl: './distributors-edit.component.html',
  styleUrls: ['./distributors-edit.component.css']
})
export class DistributorsEditComponent implements OnInit {
  public form: FormGroup // Form for client data

  // Variable to store the language selected value
  public selectedLanguage: string = "";

  public successMessage: boolean = false; // Flag for success message display

  public autocomplete: any = google.maps.places; // Google Places Autocomplete

  // Retrieve current language selected from local storage
  languageStatus:string = this.localStorageService.getItem('language');

  // Variable to store the result of a place selection from Google Places Autocomplete.
  // It can hold a valid PlaceResult object or be undefined if no place is selected.
  selectedPlace: google.maps.places.PlaceResult | undefined;

  // Variables to store edited distributor information
  public distributorName: any = "";
  public legalName: any = "";
  public email: any = "";
  public phoneNumber: any = "";
  public address: any = "";
  public distributor_id: string  = "";
  public phoneNumberWithMask: any = ""
  public filteredRelationships: BinArrayItem[] = []
  public relationships: BinArrayItem[] = []


  constructor(
    private localStorageService: LocalStorageService,
    @Inject(forwardRef(() => TranslateService)) private translate: TranslateService,
    private cognitoService: CognitoService,
    public roleService: RoleService,
    public clientService: ClientService,
    private formBuilder: FormBuilder,
    public validationService: ValidationService,
    private router: Router,
    private activedRoute: ActivatedRoute,
    public theme: ThemeService,
    public distributorService: DistributorsService,
    public navigationService: NavigationService,
    public systemMessage: SystemMessageService,
    public relationshipModalService: RelationshipModalService,
    public dashboardService: DashboardService,
    public bin: BinsService) {

    // Create a form using the FormBuilder
    this.form = this.formBuilder.group({
      // Define form controls and their initial values
      distributor_name: ['', Validators.required],
      legal_name: [''],
      email: ['', [Validators.required, Validators.email]],
      phone_number: [''],
      address: ['']
    });

    this.cognitoService.confirmValidUser();
    this.roleService.getRoles();

    // Check if the user has selected a language in local storage
    //or use a default language
    if (this.languageStatus == null){
      // Set the default language to French
      translate.use('fr');
    } else {
      // Set the default language to the user's selected language
      translate.use(this.languageStatus);
    }
  }

  async ngOnInit(): Promise<void> {
    // Subscribe to route parameter changes using ActivatedRoute
    this.activedRoute.params.subscribe((param: any) => {
      this.distributor_id = param.id; // Retrieve the 'id' parameter
      this.distributor_id = param['id']; // Alternative syntax to retrieve the 'id' parameter
    })

    this.bin.getBinsModel().then((array) => {
      // Filtrar os itens com distributor_id igual a this.distributor_id
      const itensComDistributorId = array.filter(item => item.distributor_id === this.distributor_id);

      // Obter o número de itens com o distributor_id desejado
      const contador = itensComDistributorId.length;
    }).catch((error) => {
      console.error(error);
    });

     // get array of user from client
     try {
      const res = await this.distributorService.getUsersByDistributorId(this.distributor_id.toString());

      for(let i = 0; res.length > i; i++ ) {
        this.clientService.userNameArray.push(res[i].username)
      }

      this.relationshipModalService.userQuantity = res.length;

    } catch (error) {
      console.error('Error:', error);
    }

     // get device relationship
     try {
      this.relationships = await this.dashboardService.getRelationships();
    } catch (error) {
      console.error('Error:', error);
    }

    // Call a function to fetch client data
    this.getDistributorList()

    // Call a function to initialize Google Places Autocomplete
    this.initAutocomplete();
  }

  // Decorator @HostListener listens to the 'input' event on the specified target element
  @HostListener('input', ['$event.target']) onInput(input: HTMLInputElement): void {
    // Check if the input element has the class 'telefone-input'
    if (input.classList.contains('telefone-input')) {
      // Remove all non-numeric characters from the input value
      const value = input.value.replace(/\D/g, '');

      // Check if the value has a length of 10 characters or less
      if (value.length <= 10) {
        // Format the phone number as (000) 000-0000
        input.value = this.formatPhoneNumber(value);
      } else {
        // If the value is longer than 10 digits, limit the input to 10 digits
        input.value = input.value.slice(0, 10);
      }
    }
  }

  // Function called to format a phone number ### ###-####
  formatPhoneNumber(value: string): string {
    // Check if the input value is empty and return an empty string if it is
    if (value.length === 0) return '';

    // Extract the area code, first part, and second part of the phone number
    const areaCode = value.slice(0, 3);
    const firstPart = value.slice(3, 6);
    const secondPart = value.slice(6, 10);

    // Format the phone number as (000) 000-0000 and return the formatted string
    return `(${areaCode}) ${firstPart}-${secondPart}`;
  }

  // Function called to get distributor and set distributor datas
  getDistributorList() {
    try{
      // Use the clientService to fetch client data from an API
      this.distributorService.getDistributors().subscribe(
        // Successful response callback
        (response: any) => {

          // Assuming this.client_id is the ID you want to find
          const distributorToFind = this.distributor_id;

          // Use the find function to search for the client with the specified client_id
          const foundDistributor = response.find((distributor: any) => distributor.distributor_id === distributorToFind);

          if (foundDistributor) {
            // You can now work with the foundClient as needed
            this.distributorService.distributorData.distributor_id = this.distributor_id;
            this.distributorService.distributorData.distributor_name = foundDistributor.distributor_name;
            this.distributorService.distributorData.legal_name = foundDistributor.legal_name;
            this.distributorService.distributorData.phone_number = foundDistributor.phone_number;
            this.distributorService.distributorData.email = foundDistributor.email;
            this.distributorService.distributorData.address = foundDistributor.address;
            this.formatNumber(this.distributorService.distributorData.phone_number);
            this.phoneNumberWithMask = this.formatNumber(this.distributorService.distributorData.phone_number);
          }
        }
      );
    }
    catch(error){
      console.error("Error: ", error);
    }
  }

  checkValidationAllInputs() {

    // Check if clientName is not empty, its length is greater than 16,
    // or it doesn't match the alphanumeric pattern
    this.validationService.validateClientName(this.distributorService.distributorData.distributor_name);

    // Check if clientLegalName is not empty, its length is greater than 16,
    // or it doesn't match the alphanumeric pattern
    this.validationService.validateClientLegalName(this.distributorService.distributorData.legal_name);

    // Check if the clientEmail is not a valid email or is empty
    this.validationService.validateClientEmail(this.distributorService.distributorData.email);

    // Check if phoneNumber is not empty and its length is less than 10 or if it's empty
    this.validationService.validatePhoneNumber(this.distributorService.distributorData.phone_number);

    // Check if the address is empty
    this.validationService.validateAddress(this.distributorService.distributorData.address);

    // Define a mapping of error codes to corresponding error messages
    const errorMappings: Record<string, string> = {
      'usernameInvalid': 'distributorErrorClientName',
      'clientLegalNameInvalid': 'distributorErrorClientLegalName',
      'clientEmailInvalid': 'distributorErrorEmail',
      'clientPhoneInvalid': 'distributorErrorPhone',
      'clientAddressInvalid': 'distributorErrorAddress',
    };

    // Retrieve the first validation error from the array
    const validationError = this.validationService.validationErrorArray[0];

    // Check if the validation error code exists in the mapping
    if (errorMappings[validationError]) {
      // If so, display a danger ribbon message with the corresponding key
      this.systemMessage.selectRibbon('danger', errorMappings[validationError]);
    } else {
      console.error('An error occurred, please contact support');
    }

  }

  // Function that update client
  updateClientData() {
      this.checkValidationAllInputs();

      // Create a new Date object to get the current date and time
      const date = new Date();

      // Format the date using the validationService
      this.validationService.formatDate(date);

      // Remove non-numeric characters from the phone number using the validationService
      this.validationService.keepOnlyNumbers(this.distributorService.distributorData.phone_number);

      // Update the client's phone number and creation date with the formatted values
      this.distributorService.distributorData.phone_number = this.validationService.phoneNumberFixed;
      this.distributorService.distributorData.modified = this.validationService.dateFixed;

      // Check if any of the validation flags are false, indicating validation errors
      if (this.validationService.clientNameValid && this.validationService.clientLegalNameValid &&
          this.validationService.clientEmailValid && this.validationService.clientPhoneNumberValid &&
          this.validationService.clientAddressValid) {

          // If all validations pass, log a message and call the createClient function
          this.distributorService.updateDistributor().subscribe((result) => {
            if(result) {
              this.systemMessage.selectRibbon('success',"createOrderSuccess")
            }
          });

          // A flag to check if the result is valid
          let resultaValid = true;

          if(resultaValid){
            // this.validationService.successMessage = true;
            this.validationService.manageSuccessMessageDistributor(resultaValid);
          }
      }
  }

  // Display a confirmation dialog to the user and call updateClientData() if confirmed.
  confirmatioQuestion() {
    const messageKey = 'clientupdateConfirmationUpdateQuestion'; // Key to retrieve the confirmation message for translation

    // Get the translated confirmation message based on the message key
    this.translate.get(messageKey).subscribe((message: string) => {
      // Display a confirmation dialog with the translated message
      if (window.confirm(message)) {
        // if user click ok, call updateClientData function
        this.updateClientData();
      } else {
        // Handle any action if the user cancels the confirmation (not specified in the original code)
      }
    });
  }

  // Function to initialize the Google Maps Places Autocomplete
  initAutocomplete() {
    // Ensure that the Google Maps API script is loaded before calling this function
    if (typeof google === 'undefined' || typeof google.maps === 'undefined') {
      throw new Error('Google Maps API is not loaded.');
    }

    // Get the input element with the ID 'autocomplete'
    const inputElement = document.getElementById('autocomplete') as HTMLInputElement;

    // Check if the input element was found
    if (!inputElement) {
      throw new Error('"autocomplete" input element not found.');
    }

    // Set options for the Autocomplete widget
    const autocompleteOptions: google.maps.places.AutocompleteOptions = {
      // Specify the types of places to search for (geocode in this case)
      types: ['geocode'],
      // Restrict results to Canada
      componentRestrictions: { country: 'ca' },
      // Define the fields to be retrieved from place details
      fields: ['place_id', 'geometry', 'name', 'formatted_address'],
    };

    // Create a new instance of the Autocomplete widget and associate it with the input element
    this.autocomplete = new google.maps.places.Autocomplete(inputElement, autocompleteOptions);

    // Add a listener to handle the selection of a place in the Autocomplete results
    this.autocomplete.addListener('place_changed', () => {

      // Get the selected place information from the Autocomplete widget
      const selectedPlace = this.autocomplete.getPlace();

      // Check if a selected place has geometry information
      if (selectedPlace && selectedPlace.geometry) {
        let formattedAddress = selectedPlace.formatted_address;

        // Check if the 'formatted_address' property is available
        if (!formattedAddress && selectedPlace.name) {
          formattedAddress = selectedPlace.name;
        }

        // Check if the 'name' property is still not available and there are address components
        if (!formattedAddress && selectedPlace.address_components) {
          // Build the address by joining the 'long_name' of address components
          formattedAddress = selectedPlace.address_components.map((component: { long_name: any; }) => component.long_name).join(', ');
        }

        // If a formatted address is determined, update the client's address in the service
        if (formattedAddress) {
          this.distributorService.distributorData.address = formattedAddress;
        }
      }
    });
  }

  // function to reload page when cancel button is clicked
  pageReload() {
    this.localStorageService.addItem('entitiesContent', 'distributors')

    this.router.navigate(['/entities']); // Redirect to the '/client-list' route after the delay
  }

  // Change language based on user selection
  changeLanguage(language: string) {
    this.translate.use(language);
  }

  /**
   * Formats a numeric sequence into a specific pattern (e.g., phone number format).
   *
   * @param numberSequence - The numeric sequence to be formatted.
   * @returns The formatted numeric sequence or an empty string if the input is undefined or null.
   */
  formatNumber(numberSequence: string | undefined | null): string {
    if (numberSequence === undefined || numberSequence === null) {
      return ''; // Returns an empty string if the number sequence is undefined or null
    }

    const onlyNumbers = numberSequence.replace(/\D/g, ''); // Removes all non-numeric characters from the sequence

    if (onlyNumbers.length === 10) {
      const part1 = onlyNumbers.slice(0, 3); // Extracts the first 3 digits
      const part2 = onlyNumbers.slice(3, 6); // Extracts the next 3 digits
      const part3 = onlyNumbers.slice(6);    // Extracts the last 4 digits
      return `(${part1}) ${part2}-${part3}`;       // Returns the formatted number
    } else {
      return numberSequence; // Returns the original sequence if it doesn't have 10 digits
    }
  }

  // Function
  async deleteClient() {
    // Filter items based on client ID
    this.filterItemsByClientId();

    // Get confirmation message for client deletion
    const confirmationMessage$ = this.translate.get('confirmationQuestionDeleteClient');
    const confirmationMessage = await lastValueFrom(confirmationMessage$);
    let isConfirmed = false;

    // Check if there are related items or users associated with the client
    if(this.filteredRelationships.length > 0 || this.relationshipModalService.userQuantity > 0) {

      this.relationshipModalService.updateTarget = 'distributor';
      // Show modal if there are related items or users
      this.relationshipModalService.showModal = true;
    } else {
      // Display a confirmation dialog to the user
      isConfirmed = window.confirm(confirmationMessage);
    }
    // If user confirms deletion
    if (isConfirmed) {

      try {
        // Call onDeleteClient to initiate client deletion
        this.distributorService.onDeleteDistributor().subscribe(success => {});

        // Iterate through each user to disable their access
        for(let i = 0; this.clientService.userNameArray.length > 0; i++){
          // Disable user access via Cognito service
          const disabledResult = await this.cognitoService.disableUserAccess(this.clientService.userNameArray[i]);
        }

        this.localStorageService.addItem('entitiesContent', 'distributorDeleteSuccess')
        this.router.navigate(['/entities']); // Redirect to the '/client-list' route after the delay

      } catch (error) {
        console.error(error)
        // Display an informational message related to client deletion failure
        this.localStorageService.addItem('entitiesContent', 'deleteDistributorFail');
        this.router.navigate(['/entities']); // Redirect to the '/client-list' route
      }
    }
  }

  /**
  * Function to filter items by distributor ID.
  */
  filterItemsByClientId(): void {
    // Filtering relationships based on distributor ID
    this.filteredRelationships = this.relationships.filter(item => item.distributor_id === this.distributor_id.toString());

    // Iterating through filtered relationships
    this.filteredRelationships.forEach(item => {
        if (item.thing_name !== '') {
          // Incrementing devices quantity if thing name is not empty
          this.relationshipModalService.devicesQuantity++;
        }
        if (item.bin_id !== '') {
          // Incrementing bin quantity if bin ID is not empty
          this.relationshipModalService.binQuantity++;
        }
    });
  }
}
