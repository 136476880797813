import { Component } from '@angular/core';
import { FilterService } from '../service/filter.service';
import { BinsService, BinModel } from '../service/bins.service';
import { ValidationService } from '../service/validation.service';
import { ModalAlertService } from '../service/alert-modal.service';
import { BinModalService } from '../service/bin-modal.service';
import { AdminService } from '../service/admin.service';
import { ThemeService } from '../service/theme.service';
import { SystemMessageService } from '../service/system-message.service';
import { DistributorsService } from '../service/distributors.service';

@Component({
  selector: 'app-bin-model-list',
  templateUrl: './bin-model-list.component.html',
  styleUrls: ['./bin-model-list.component.css', '../../global-elements.css']
})
export class BinModelListComponent {

  // Variables used for HTML contents
  public mainContent: string = 'bin-model-list';
  public fromComponent: string = '';

  // Variables used for filter service
  public userFilter: string = '';
  public filterBy: string = '';

  // Array of the bins
  public array: any; // Array used to attribute a received array frombin-service to the bin_array and bin_model_array
  public binModelArray: BinModel = {} as BinModel;
  public distributorArray: any[] = [];

  constructor(public filter: FilterService,
              public bin: BinsService,
              public validationService: ValidationService,
              public alert: ModalAlertService,
              public binModal: BinModalService,
              private admin: AdminService,
              public theme: ThemeService,
              private systemMessageService: SystemMessageService,
              public distributorsService: DistributorsService){

  }

  async ngOnInit(): Promise<void> {
    this.alert.initAlert(); // Reset alert variables into alert-modal service

    // Check if there is a session variable called from. then it wil be used in html if there is a success create or update essage to show the proper stuff
    const fromComponent = sessionStorage.getItem('from');
    if(fromComponent){
      // Initialize is own variable
      this.fromComponent = fromComponent;
      sessionStorage.removeItem("from"); // Then remove it from session variable to avoid is not wanted use from other components
    }
    try{
      // Initialize Bin Model array in bin.service
      await this.bin.getBinsModel();
    }
    catch (error){
      console.error('An error as occured: ' + error);
    }

    try {
      // Initialize this.distributorsArray. Array in DistributorsService
      this.distributorsService.getDistributors().subscribe((response: any) => {
      });
    } catch (error) {
      console.error('An error occurred:', error);
    }

    // Function that is automatically trigger used by bin service when there is a change made from bin-create, bin-update, bin-model-create or bin-model-update components
    this.bin.changeMade$.subscribe(async () => {
      await this.bin.getBinsModel(); // Reinitialize the bin-list
      this.systemMessageService.selectRibbon('success', 'alert-success-generic-message');
    });

    this.bin.noChangeMade$.subscribe(async () => {
      this.systemMessageService.selectRibbon('danger', 'alert-danger-generic-message');
    });

    // Function that is trigger by bin-modal-service to return to admin component and bin-list and will automatically filter the list whit the bin-model-number
    this.binModal.returnToBinList$.subscribe(() => {
      this.admin.returnBinList();
    });

  }

  // Function to get the distributor name by distributor ID
  getDistributorNameById(distributorId: string): string | undefined {

    // Flatten the array of distributors (if it's a nested array structure)
    const distributors = this.distributorsService.distributorsArray.flat(1);

    // Find the distributor with the specified distributor ID
    const distributor = distributors.find(d => d.distributor_id === distributorId);

    // Check if the distributor was found and return the name, otherwise return undefined
    return distributor ? distributor.distributor_name : undefined;
  }

  // Function used to made a search in the list
  search(){
    // Check if there's a filterBy variable and set it to it's default value
    if(this.filterBy === ''){
      this.filterBy = 'bin_model_number';
    }

    this.bin.bin_detail_array = this.filter.search(this.userFilter, this.bin.bin_detail_array, this.bin.original_bin_detail_array, this.filterBy);
  }
}
