import { Component, ElementRef, HostListener, OnInit } from '@angular/core';
import { CognitoService } from '../service/cognito.service';
import { ThemeService } from '../service/theme.service';
import { SystemMessageService } from '../service/system-message.service';
import { navigation } from '../constants/navigation';
import { environment } from '../environments/environment';
import { DashboardService } from '../service/dashboard.service';
import { DriverService } from '../service/driver.service';
import { RoleService } from '../service/role.service';
import { TruckService } from '../service/truck.service';
import { ChartService } from '../service/chart.service';
import { ScaleType } from '@swimlane/ngx-charts';
import { LegendPosition } from '@swimlane/ngx-charts';

@Component({
  selector: 'dispatch-dashboard',
  templateUrl: './dispatch-dashboard.component.html',
  styleUrls: [
    './dispatch-dashboard.component.css',
    '../../dashboard-tiles.css',
  ],
})
export class DispatchDashboardComponent implements OnInit {
  public _myChart: ElementRef | undefined;
  //@ViewChildren('dashboardTile') dashboardTiles!: QueryList<ElementRef>; // recupereate each tiles component for chart dimensions
  // @ViewChild('chart') barChart!: ElementRef; // get a reference of the gridster component
  //Grid option object creates a 3 x 2 grid
  gridsterOptions = {
    draggable: {
      enabled: false,
    },
    resizable: {
      enabled: false,
    },
    minCols: 1,
    maxCols: 3,
    minRows: 1,
    maxRows: 2,
    defaultItemCols: 1,
    defaultItemRows: 1,
  };

  trucks4Graph: any[] = []; // array that saves truck for dashboard graph
  public navigationItemsArray: any = []; //To save tile objects
  public isSmallScreenWidth: boolean = this.getScreenWidth() <= 859; // small screen flag
  public showTruckCount = false; //to display truck count on UI
  public showTruckGraph = true; // to display truck graph on UI

  constructor(
    public cognitoService: CognitoService,
    public theme: ThemeService,
    public systemMessage: SystemMessageService,
    public dashboard: DashboardService,
    private roleService: RoleService,
    public truckService: TruckService,
    public chartSerice: ChartService,
    public DriverService: DriverService
  ) {}
  async ngOnInit() {
    // Call cognito to confirm valid user and get the user type of the current user
    await this.cognitoService.confirmValidUser();
    await this.cognitoService.getUserType();
    await this.dashboard.initializeOperatorDashboardData();

    // Call role service to get the current user roles
    await this.roleService.getRoles();

    this.cognitoService.getCurrentRole(
      [environment.users.role.dispatch], //dispatch
      [environment.users.maintenance] //operator
    );
    // Get navigation object for dispatch nav
    this.navigationItemsArray = navigation.filter(
      (item) =>
        item.userType === environment.users.maintenance &&
        item.userRole === environment.users.role.dispatch
    );
    // Initialize indexToRemove with -1
    let indexToRemove = -1;
    // Loop through the navigationItemsArray to find the index of the item to remove
    for (let i = 0; i < this.navigationItemsArray.length; i++) {
      // Check if the name of the current item is 'sidenavMenu.WORKORDER'
      if (this.navigationItemsArray[i].name === 'sidenavMenu.WORKORDER') {
        // If found, set indexToRemove to the current index and exit the loop
        indexToRemove = i;
        break;
      }
    }
    console.log(this.navigationItemsArray)
    // Remove the item if the index is found
    if (indexToRemove !== -1) {
      this.navigationItemsArray.splice(indexToRemove, 1);
    }
    //console.log(this.navigationItemsArray) #debug
    // Get the current screen width and height on initialization
    const currentScreenWidth = this.getScreenWidth();
    const currentScreenHeight = this.getScreenHeight();

    // Check if the screen width is small (less than or equal to 859 pixels)
    this.isSmallScreenWidth = currentScreenWidth <= 859;
    // Adjust chart parameters based on the view size
    this.chartSerice.colorSchemeBarChartAdmin = {
      name: '', // Color scheme name (optional)
      selectable: true, // Allow colors to be selected
      group: ScaleType.Ordinal, // Define the color scale type
      domain: [
        '#FF5733', // Red-Orange
        '#33FF57', // Green
        '#3357FF', // Blue
        '#F3FF33', // Yellow
        '#FF33A1', // Pink
        '#33FFF5', // Cyan
        '#FF8C33', // Orange
      ],
    };
    // Set graph options for UI
    this.chartSerice.showYAxisBarChartAdmin = false; // Y axis
    this.chartSerice.legendTitlePieChartSlice =
      await this.chartSerice.getChartLabel('truckLegend'); // legend title
    this.chartSerice.legendPositionPieChartSlice = LegendPosition.Below; //legend position
    this.chartSerice.adjustView(currentScreenWidth, currentScreenHeight, 6); // adjust graph size
    this.chartSerice.showLegendBarChartAdmin = true; // to show the legend
  }
  // Event listener for window resize
  @HostListener('window:resize', ['$event'])
  onResize(event: any): void {
    // get the current window width and height
    const currentScreenWidth = this.getScreenWidth();
    const currentScreenHeight = this.getScreenHeight();
    //console.log(`width: ${currentScreenWidth}, height:${currentScreenHeight}`) #debug
    // Check the screen width and update the isSmallScreen flag accordingly
    this.isSmallScreenWidth = currentScreenWidth <= 859;
    //Adjust chart parameters for view size
    this.chartSerice.adjustView(currentScreenWidth, currentScreenHeight, 6);
  }
  // Function to get the current screen width
  getScreenWidth(): number {
    // Return the inner width of the window as the screen width
    return window.innerWidth;
  }
  // Function to get the current screen height
  getScreenHeight(): number {
    // Retunr the current screen height of the window
    return window.innerHeight;
  }
  // Function that display the truck count in the truck tile
  displayTruckCount() {
    //if the truck graph is visible
    if (this.showTruckGraph) {
      this.showTruckCount = true; //show truck count
      this.showTruckGraph = false; // hide truck graph
    }
  }
  // Function that display the truck graph in the truck tile
  displayTruckGraph() {
    //if the truck count is visible
    if (this.showTruckCount) {
      this.showTruckCount = false; //hide truck count
      this.showTruckGraph = true; // show truck graph
    }
  }
}
