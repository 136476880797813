
<div class="main-container" [ngClass]="theme.getThemeClass()">
  <!-- Section for client association of distributor devices -->
  <section *ngIf="this.relationModalService.showClientAssociationModal" [ngClass]="theme.getThemeClass()" class="client-association-container">
    <h3 class="container-title">{{ "clientAssociation" | translate }}</h3>
    <label class="thing-label">
      {{ this.relationModalService.thing_name }}
    </label>
    <div class="select-container">
      <ng-select
      class="form-control select"
      [(ngModel)]="this.relationModalService.selectedClient"
      [attr.selected]="this.relationModalService.client_legal_name">
      <ng-option value="">{{ "removeClient" | translate }}</ng-option>
      <ng-container *ngFor="let client of this.relationModalService.clientArray">
        <ng-option [value]="client.client_id">{{ client.legal_name }}</ng-option>
      </ng-container>
      </ng-select>
    </div>
  </section>

  <!-- Section fro bin association of distributor devices -->
  <section *ngIf="this.relationModalService.showBinAssociationModal" [ngClass]="theme.getThemeClass()" class="bin-association-container">
    <h3 class="container-title">{{ "binAssociation" | translate }}</h3>
    <label class="thing-label">
      {{ this.relationModalService.thing_name }}
    </label>
    <div class="select-container">
      <ng-select
      class="form-control select"
      [(ngModel)]="this.relationModalService.selectedBin"
      [attr.selected]="this.relationModalService.selectedBin">
      <ng-option value="">{{ "removeBin" | translate }}</ng-option>
      <ng-container *ngFor="let bin of this.relationModalService.binArray">
        <ng-option [value]="bin.bin_id">{{ bin.bin_name }}</ng-option>
      </ng-container>
      </ng-select>
    </div>
  </section>

  <nav class="nav-btn" [ngClass]="theme.getThemeClass()">
    <button [ngClass]="theme.getThemeClass()" class="btn" (click)="closeModal()">{{ "iotModalButtonClose" | translate }}</button>
    <button [ngClass]="theme.getThemeClass()" class="btn" (click)="saveAssociation()">{{ "loginFNewPasswordBtnConfirmNewPw" | translate }}</button>
  </nav>
</div>

