import { Injectable } from '@angular/core';
import { LocalStorageService } from '../local-storage.service';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ThemeService {
  public localTheme: string = this.localStorageService.getItem('theme') ?? "light";
  public themeStatus: string= this.localTheme.replaceAll(/["\\]/g, '');
  // Variables that being used by css
  public themeColor: string = '';
  isSwitchOn:boolean = false; // Flag for switch status
  isDarkMode: boolean = false; // Flag for dark mode

  // Variable that will force the nav-bar SVG's to change theme
  private forceNavBarToChange = new Subject<void>;
  forceNavBarToChange$ = this.forceNavBarToChange.asObservable();

  constructor(public localStorageService: LocalStorageService) { }

    // Get current theme class for UI
    getThemeClass(isModal: boolean = false): string {
      
      // Get the current theme status from local storage
      this.themeStatus = this.localStorageService.getItem('theme') ?? "light";

      this.themeStatus = this.themeStatus.replaceAll(/["\\]/g, '');

      // Check if the theme status is 'dark
      if (this.themeStatus == 'dark' ) {
        this.isSwitchOn = true;
        // Set the theme status to dark theme class
        this.themeStatus = 'bg-dark text-white';
      } else if (this.themeStatus == 'light') {
        this.isSwitchOn = false;
        // Set the theme status to light theme class
        this.themeStatus = 'bg-light text-dark';
      }

      if(isModal){
         // Check if the theme status is 'dark
        if (this.themeStatus == 'dark' ) {
          // Set the theme status to dark theme class
          this.themeColor = 'lightgray';
        } else if (this.themeStatus == 'light') {
          // Set the theme status to light theme class
          this.themeColor = 'black';
        }
        document.documentElement.style.setProperty('--theme-color', this.themeColor);
      }
      // Return the theme status (theme class)
      return this.themeStatus;




    }
}
