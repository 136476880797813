import { Component, OnInit } from '@angular/core';
import { ThemeService } from '../service/theme.service';
import { ValidationService } from '../service/validation.service';
import { EntitiesLocationsService } from '../service/entities-locations.service';
import { LocalizationService } from '../service/localization.service';
import { LocalStorageService } from '../local-storage.service';
import { Router } from '@angular/router';
import { CognitoService } from '../service/cognito.service';
import { ClientService } from '../service/client.service';
import { DistributorsService } from '../service/distributors.service';
import { OperatorService } from '../service/operator.service';
import { SystemMessageService } from '../service/system-message.service';
import { environment } from '../environments/environment';

@Component({
  selector: 'app-entities-location-create',
  templateUrl: './entities-location-create.component.html',
  styleUrls: ['./entities-location-create.component.css', '../../global-elements.css']
})
export class EntitiesLocationCreateComponent implements OnInit{
  // Variable used in UI to get selected entities
  public selectedClient: string = '';
  public selectedDistributor: string = '';
  public selectedOperator: string = '';
  public selectedOption!: string;

  // Variable used for validation
  private createValidate: boolean = false;

  constructor(public theme: ThemeService,
              public validationService: ValidationService,
              public entitiesLocationService: EntitiesLocationsService,
              public localizationService: LocalizationService,
              private localStorageService: LocalStorageService,
              private router: Router,
              public cognitoService: CognitoService,
              public clientService: ClientService,
              public distributorService: DistributorsService,
              public operatorService: OperatorService,
              public systemMessageService: SystemMessageService){

    this.cognitoService.confirmValidUser();
  }

 async ngOnInit() {
  await this.cognitoService.getCurrentRole(
    [environment.users.role.administrator],
    [environment.users.superAdmin]
  ); //Role Check
    this.systemMessageService.buttonBlocked = false;
    // Get user type
    this.cognitoService.getUserType();

    // Get client array
    await this.clientService.getClientsForSelect();

    // Get distributor array
    await this.distributorService.getDistributorForSelect();

    // Get operator array
    await this.operatorService.getOperators();

    // Init the autocomplete for address input
    this.localizationService.initAutocomplete();
  }

  disableButton() {
    this.systemMessageService.buttonBlocked = true;
  }

  // Function called when user save a new location
  onSubmit(){
    // Set the location variable
    this.entitiesLocationService.location_data.location = this.localizationService.autoCompletionReturnAddress;

    this.setEntityIdAndType();

    if((this.selectedClient !== '' || this.selectedDistributor !== '' || this.selectedOperator !== '') && this.entitiesLocationService.location_data.location !== ''){
      // Function called to create new location
      this.entitiesLocationService.createEntityLocation();

      // function called to go back to entities location list
      this.returnToEntities();
    } else {
      this.systemMessageService.buttonBlocked = true;
      this.systemMessageService.selectRibbon('danger', 'fieldEmptyOrIncorrect');

    }

    this.systemMessageService.buttonBlocked = false;
  }

  // Function called to set entity id and type
  setEntityIdAndType(){
    switch(true){
      case (this.selectedClient !== ''):
        this.entitiesLocationService.location_data.entity_id = this.selectedClient;
        this.entitiesLocationService.location_data.entity_type = 'C';
        break;

      case (this.selectedDistributor !== ''):
        this.entitiesLocationService.location_data.entity_id = this.selectedDistributor;
        this.entitiesLocationService.location_data.entity_type = 'D';
        break;

      case (this.selectedOperator !== ''):
        this.entitiesLocationService.location_data.entity_id = this.selectedOperator;
        this.entitiesLocationService.location_data.entity_type = 'O';
        break;
    }
  }

  // Function called when user change the select of client
  selectedClientChange(){
    if(this.selectedClient !== '' && (this.selectedDistributor !== '' || this.selectedOperator !== '')){
      // Display a message to the user
      this.systemMessageService.selectRibbon('danger', 'youCantSelectMoreThenOne');
      // Set other select to nothing
      this.selectedDistributor = '';
      this.selectedOperator = '';
    }
  }

  // Function called when user change the select of distributor
  selectedDistributorChange(){
    if(this.selectedDistributor !== '' && (this.selectedClient !== '' || this.selectedOperator !== '')){
      // Display a message to the user
      this.systemMessageService.selectRibbon('danger', 'youCantSelectMoreThenOne');
      // Set other select to nothing
      this.selectedClient = '';
      this.selectedOperator = '';
    }
  }

  // Function called when user change the select of operator
  selectedOperatorChange(){
    if(this.selectedOperator !== '' && (this.selectedClient !== '' || this.selectedDistributor !== '')){
      // Display a message to the user
      this.systemMessageService.selectRibbon('danger', 'youCantSelectMoreThenOne');
      // Set other select to nothing
      this.selectedClient = '';
      this.selectedDistributor = '';
    }
  }

  // Implemented by cancel button to return to the admin component
  returnToEntities(){
    // Put a local store variable so entitie component could know to return on operator-list
    this.localStorageService.addItem('entitiesContent', 'locations');
    sessionStorage.setItem("from", "location-create"); // This variable is used for operator-list to show the proper success message on create or update
    this.router.navigate(['/entities']); // Return to entitie component
  }

  // Function that avoid submitting the page when user press enter at the end of inputting address in address input
  onInputAddressKeydown(event: KeyboardEvent): void {
    if(event.key === 'Enter'){
      event.preventDefault(); // Don't submit the page
    }
  }

  // Function called to reset the radio button
  clearRadio(){
    this.selectedOption = ''
  }
}
