import { Component, OnInit, ViewChild, ElementRef, Inject, forwardRef } from '@angular/core';
import {
  CdkDragDrop,
  CdkDrag,
  CdkDropList,
  CdkDropListGroup,
  moveItemInArray,
  transferArrayItem,
} from '@angular/cdk/drag-drop';
import { ThemeService } from '../service/theme.service';
import { SystemMessageService } from '../service/system-message.service';
import { TranslateService } from '@ngx-translate/core';
import { LocalStorageService } from '../local-storage.service';
import { RouteService } from '../service/route.service';
import { RouteInterface } from '../service/route.service';
import { OperatorService } from '../service/operator.service';
import { CognitoService } from '../service/cognito.service';
import { RoleService } from '../service/role.service';
import { Truck, TruckService } from '../service/truck.service';
import { DistributorsService } from '../service/distributors.service';
import { BinUsage } from 'src/app/constants/bin-usage';
import { environment } from '../environments/environment';

export interface Route {
  bin_usage: string;
  creation_date: string;
  end_date: string | null;
  kilometer: string | null;
  legal_name: string;
  nb_stops: string;
  operator_id: string;
  route_id: string;
  start_date: string | null;
  truck_id: string;
}

export interface OperatorClient {
  operator_id: string;
  client_id: string;
}

// change to interface from routeservice
export interface RouteInfo {
  routeId: string;
  driverInfo?: string;      // Driver's information
  status: string;           // Route status (e.g., "in progress", "completed")
  legal_name?: string;      // Client's name
  dateTime?: string;        // Date and time in a single field (ISO 8601)
  estimatedTime?: string;
  numberOfStops: number;    // Total number of stops
  stopsCompleted?: number;  // Number of completed stops
  totalKm: number;          // Total kilometers traveled
  zones?: string[];         // Zones covered by the route
}

export interface DriverInfo {
  id: string; 
  user: string;               // Unique identifier for the driver
  given_name: string;       
  family_name: string;       
  licenseNumber: string;     // Driver's license number (could map to another field if available)
  phoneNumber: string;       // Driver's contact phone number (phone_number in the log)
  email?: string;            // Driver's email address
  address?: string;          // Driver's physical address
  created: string;           // Date when the driver was created
  modified: string;          // Date when the driver was last modified
  currentRole: string;       // Driver's current role (current_role in the log)
  operatorId: string;        // ID of the operator associated with the driver
  username: string;          // Username of the driver
  userType: string;          // Type of user (e.g., 'operator')
  enable: string;            // Indicates if the driver is enabled (1 for enabled)
  role: string;              // Role ID associated with the driver
  distributorId?: string;    // ID of the distributor (if applicable)
  clientId?: string;  
}

export interface TruckInfo {
  truckId: string;            
  model: string;              
  licensePlate: string;       
  driverInfo?: DriverInfo;   
  routes?: RouteInterface[]; 
  closedRoute?: boolean;      
  truck_id?: string;
  operator_id?: string; 
  driver_id?: string;
  nickname?: string; 
  vin?: string;
  brand?: string; 
  type?: string; 
  size?: number; 
  capacity?: number; 
  year?: number;
  status?: string; 
  username?: string; 
  bin_usage?: string;
}

@Component({
  selector: 'app-dispatcher-control-center',
  templateUrl: './dispatcher-control-center.component.html',
  styleUrl: './dispatcher-control-center.component.css',
})

export class DispatcherControlCenterComponent implements OnInit {

  public selectedTruck: string = '';

  public trucksArray: Truck[] = [] // Array of trucks
  public routeList: RouteInterface[] = [];
  public filteredRouteList: any[] = [];
  public routeArray: any[] = [];
  public truckList: Truck[] = [];
  public processedRouteArray: any[] = [];

  fillLevel: number = 0;
  fillLevelBigTruck: number = 100;
  startLevel: number = 0;

  public operatorClientList: any;
  fillingPercentage: any;
  calculatedWeight: any;
  selectedRouteInfo: any | null = null;

  selectedTruckInfo: TruckInfo | null = null;

  public loading: boolean = true;
  public noInfo: boolean = true;
  public showAssignRouteModal: boolean = false;

  drivers: DriverInfo[] = this.operatorService.operatorDriversArray

  // Retrieve current language selected from local storage
  languageStatus: string = this.localStorageService.getItem('language');

  // Reference to the scroll container element in the template, using ViewChild to get the ElementRef
  @ViewChild('scrollContainer', { read: ElementRef }) scrollContainer!: ElementRef;

  // Reference to the truck columns wrapper element in the template, using ViewChild to get the ElementRef
  @ViewChild('truck-columns-wrapper', { read: ElementRef }) scrollContainerTrucks!: ElementRef;

  constructor(
    public cognitoService: CognitoService,
    public operatorService: OperatorService,
    public routeService: RouteService,
    public theme: ThemeService,
    public systemMessage: SystemMessageService,
    @Inject(forwardRef(() => TranslateService)) @Inject(forwardRef(() => TranslateService)) private translate: TranslateService,
    private localStorageService: LocalStorageService,
    private roleService: RoleService,
    public truckService: TruckService,
    public distributorService: DistributorsService,
  ) {
    // await this.cognitoService.confirmValidUser();
  }

  async ngOnInit(): Promise<void> {
    // 03cdc38d-3068-45dc-92e7-b9eb2be669aa

    
    // to do 
    // this.cognitoService.getCurrentRole([environment.users.role.administrator, environment.users.role.operations], [environment.users.standardUser, environment.users.maintenance], true, this.roleService.roles);

    // Confirm that the user is valid using the Cognito service
    await this.cognitoService.confirmValidUser();
    
    // Retrieve the user's type using the Cognito service
    await this.cognitoService.getUserType();

    // Fetch the roles associated with the user using the Role service
    await this.roleService.getRoles();

    // Extract the operator ID from the user's information
    const operatorid = this.cognitoService.userInfos?.attributes?.['custom:operations_id'];

    await this.operatorService.getDriversByOperatorId(operatorid);

    // Get the list of trucks associated with the operator ID using the Truck service
    this.truckList = await this.truckService.getTrucksByOperatorId(operatorid)

    // adds missing parameters that will be used for each object
    this.truckList = this.truckList.map(truck => ({
      ...truck,
      status: '0',
      closedRoute: false,
      routes: [] 
    }));



    // Fetch today's routes using the Route service
    this.routeList = await this.routeService.getTodayRoutes();

    // Filter the routes to include only those that match the operator's ID
    this.filteredRouteList = this.routeList.filter(route => route.operator_id === operatorid);

    // Group the filtered routes by their route ID
    this.routeArray = this.groupRoutesByRouteId(this.filteredRouteList); 

    this.addRoutesToTrucks(this.routeArray, this.truckList)

    // reorder trucklist based on routes quantity
    this.truckList = this.reorderTruckListByRoutes(this.truckList);

    // display the first truck on the right panel
    this.showInfoTruck(this.truckList[0]);

    // remove loading screen
    this.loading = false;

    // show welcome message - instruction message about click on trucks or routes
    this.systemMessage.selectRibbon('info', "dispatcherWelcome");
  }

  getCurrentTruckDriverName(user: any): string | null {
    const driver = this.operatorService.operatorDriversArray.find(driver => driver.user === user);
    
    if (driver) {
      return `${driver.given_name} ${driver.family_name}`;
    }
  
    return null; // Retorna null se o user não for encontrado
  }

  // Returns the bin_usage
  getBinUsage(route: any): string {
    if (Array.isArray(route)) {
      return route[0]?.bin_usage || ''; // Returns the bin_usage of the first item or an empty string
    }
    return route.bin_usage || ''; // Returns bin_usage of the object
  }

  reorderTruckListByRoutes(truckList: any[]): any[] {
    return truckList.sort((a, b) => {
      const routesA = a.routes ? a.routes.length : 0;
      const routesB = b.routes ? b.routes.length : 0;

      // Sorting in ascending order based on the number of routes
      return  routesB - routesA;
    });
  }

  isArray(value: any): boolean {
    return Array.isArray(value);
  }

  formatTimestamp(timestamp: string): string {
    const date = new Date(parseInt(timestamp, 10) * 1000); // Multiply by 1000 to convert from seconds to milliseconds
    return date.toLocaleDateString('en-CA', { // Canadian date format as example (yyyy-mm-dd)
        year: 'numeric',
        month: '2-digit',
        day: '2-digit'
    });
  }

  groupRoutesByRouteId(items: any[]): any[] {
    const grouped: { [key: string]: any[] } = {};
  
    items.forEach(item => {
      if (!grouped[item.route_id]) {
        grouped[item.route_id] = [];
      }
      grouped[item.route_id].push(item);
    });
  
    // Transform object into an array, preserving independent and grouped items
    return Object.values(grouped);
  }

  assignDriver(truck: any) {
    this.showAssignRouteModal = true;
    this.selectedTruck = truck.nickname;
  }

  showInfo(info: any) {
    this.selectedRouteInfo = info;
    this.selectedTruckInfo = null;
    this.noInfo = false;
  }

  convertSecondsToMinutesOnly(seconds: number): number {
    return Math.floor(seconds / 60);
  }

  // Função para calcular o total de approximate_time em segundos e converter para minutos
  calculateTotalTime(route: any): number {
    if (Array.isArray(route)) {
      // Somar o tempo de todos os itens do array
      const totalSeconds = route.reduce((acc: number, item: any) => {
        const time = item?.approximate_time;

        // Tentar converter o valor para número, caso seja uma string
        const numericTime = typeof time === 'string' ? parseFloat(time) : time;

        // Verificar se o valor é um número e se não é NaN
        if (typeof numericTime === 'number' && !isNaN(numericTime)) {
          return acc + numericTime;
        }

        // Se o valor não for válido, logar e ignorar
        console.warn('Valor inválido encontrado:', time);
        return acc; // Retornar o acumulador sem adicionar
      }, 0);

      return this.convertSecondsToMinutesOnly(totalSeconds);
    } else {
      // Se não for um array, apenas converter o tempo
      const time = route?.approximate_time;

      // Tentar converter o valor para número, caso seja uma string
      const numericTime = typeof time === 'string' ? parseFloat(time) : time;

      return this.convertSecondsToMinutesOnly(typeof numericTime === 'number' && !isNaN(numericTime) ? numericTime : 0);
    }
  }


  showInfoTruck(info: any) {
    this.calculatedWeight = 0;
    this.fillingPercentage = 0;
    this.fillLevelBigTruck = 0;

    // Store the selected truck's information
    this.selectedTruckInfo = info;

    // Clear the previously selected route information
    this.selectedRouteInfo = null;

    // Set the flag indicating that information is available
    this.noInfo = false;
  
    // Calculate the total weight of all bins (lixeiras) in the truck
    this.calculatedWeight = this.calculateTotalWeight(info);

    // Calculate the filling percentage of the truck
    this.fillingPercentage = this.getFillingPercentage(info);

    // Set the fill level of the big truck to the calculated filling percentage
    this.fillLevelBigTruck = this.fillingPercentage;

    // Simulate the filling of the big truck based on the calculated fill level
    this.simulateFillingBigTruck();

    // Optionally, you can uncomment these lines to log the calculated values to the console for debugging
    // console.log('info', info);
    // console.log(`Total Weight: ${this.calculatedWeight}`);
    // console.log(`Filling Percentage: ${this.fillingPercentage}%`);
  }

  calculateRouteVolume(route: any): number {
    let totalWeight = 0;

    // Check if info is an array or just a single object
    const routes = Array.isArray(route) ? route : [route];

    // Checks if the route array exists and contains items
    if (routes && routes.length > 0) {
        // Iterates over the routes and calculates the total weight
        totalWeight = routes.reduce((sum: number, route: any) => {
            // Gets the total volume and threshold, converting to number
            const volume = parseFloat(route.total_volume) || 0;
            const threshold = parseFloat(route.threshold) || 0;

            // Calculates weight based on volume and threshold percentage
            const weight = volume * (threshold / 100);

            // Add the weight to the total
            return sum + weight;
        }, 0);
    }

    // Returns the calculated total weight
    return totalWeight;
  }

  calculateTotalWeight(info: any): number {
    let totalWeight = 0;

    // Check if the routes array exists and has at least one item
    if (info.routes && info.routes.length > 0) {
      // Flatten the array if it contains arrays inside it
      const flattenedRoutes = info.routes.flat();

      // Iterate over the flattened routes array and calculate the total weight of all bins
      totalWeight = flattenedRoutes.reduce((sum: number, route: any) => {
        // Calculate the weight of each bin as total_volume * (threshold / 100)
        const volume = parseFloat(route.total_volume) || 0;
        const threshold = parseFloat(route.threshold) || 0;
        const weight = volume * (threshold / 100);
        // Add the weight of the current bin to the sum
        return sum + weight;
      }, 0);
    }

    // Return the total calculated weight
    return totalWeight;
  }
  
  getFillingPercentage(info: any): number {
    // Calculate the total weight of all bins in the truck
    const totalWeight = this.calculateTotalWeight(info);

    // Convert the truck's capacity to a number (assuming it's a string)
    const capacity = parseFloat(info.capacity);

    // Calculate the filling percentage by dividing the total weight by the truck's capacity and multiplying by 100
    const fillingPercentage = (totalWeight / capacity) * 100;
  
    // Round the filling percentage to the nearest whole number and return it
    return Math.round(fillingPercentage);
  }

  // This function handles the drag-and-drop events for moving items (routes and trucks) 
  // within or between containers.
  drop(event: CdkDragDrop<any[], any[]> | CdkDragDrop<any[], TruckInfo[]> | CdkDragDrop<TruckInfo[], any[]> | CdkDragDrop<TruckInfo[], TruckInfo[]>) {

    // remove comments to debug 
    // console.log('event', event)
    // console.log('event.item.data[0].bin_usage', event.item.data[0].bin_usage) 
    // console.log('event.previousContainer.data', event.previousContainer.data) 
    // console.log('event.container.data', event.container.data) 

    // getting the data array
    const data = event.container.data; 

    // Access the first sub-array
    const dataArray = data[0] || data;  

    // Get the dragged item as a RouteInfo object
    const route = event.item.data as any;
    this.showInfo(route);

    if (event.previousContainer === event.container) {
      // If the item is being sorted within the same container
      // moveItemInArray(event.container.data as RouteInfo[], event.previousIndex, event.currentIndex);
      moveItemInArray(event.container.data as any[], event.previousIndex, event.currentIndex);

    } else {
      // If the item is being moved to a different container
      // const route = event.item.data as RouteInfo;
      const route = event.item.data as any;

      const previousTruck = this.truckList.find(truck => truck.routes === event.previousContainer.data);
      const currentTruck = this.truckList.find(truck => truck.routes === event.container.data);

      // Check if the current truck's route is locked
      if(currentTruck?.closedRoute) {
        this.systemMessage.selectRibbon('danger', "truckLocked");
        return;
      }
      if (event.previousContainer.id === 'routesList') {
        // Moving from route list to a truck
        // verify if the operator is trying to mix different types of bin_usage , if yes block the operation
        if (event.item.data[0].bin_usage !== undefined && dataArray[0] && dataArray[0].bin_usage !== undefined) {
          if (event.item.data[0].bin_usage != dataArray[0].bin_usage) {
              this.systemMessage.selectRibbon('danger', "differentBinUsageType");
              return;
          }
        } 

        transferArrayItem(
          this.routeArray,
          event.container.data as any[],
          event.previousIndex,
          event.currentIndex
        );
      } else if (event.container.id === 'routesList') {
        // Moving from a truck to the route list
        if (previousTruck) {
          transferArrayItem(
            previousTruck.routes ?? [], 
            this.routeArray,
            event.previousIndex,
            event.currentIndex
            );
        }
      } else if (previousTruck && currentTruck) {
        // Moving from one truck to another truck
        // verify if the operator is trying to mix different types of bin_usage , if yes block the operation
        if (event.item.data[0].bin_usage !== undefined && dataArray[0] && dataArray[0].bin_usage !== undefined) {
          if (event.item.data[0].bin_usage != dataArray[0].bin_usage) {
              this.systemMessage.selectRibbon('danger', "differentBinUsageType");
              return;
          }
        } 

        transferArrayItem(
          previousTruck.routes ?? [], 
          currentTruck.routes ?? [],   
          event.previousIndex,
          event.currentIndex
        );
      }
    }
  }

  toggleTruckLock(truck: any): void {
    // Toggle the lock status of the truck's route
    // If the route is locked (closedRoute is true), it will be unlocked (closedRoute will become false), and vice versa
    truck.closedRoute = !truck.closedRoute;

    switch(truck.status) {
      case '0':
        truck.status = '4';
      break;

      case '4':
        truck.status = '0';
      break;
    }

  }
  
  scrollLeft() {
    // Check if the scroll container and its native element exist
    if (this.scrollContainer && this.scrollContainer.nativeElement) {
        // Scroll the container to the left by 190 pixels
        this.scrollContainer.nativeElement.scrollBy({ 
            left: -190, // Scroll to the left by 190 pixels
            behavior: 'smooth' // Ensure the scrolling is smooth
        });
    }
  }
  
  scrollRight() {
    // Check if the scroll container and its native element exist
    if (this.scrollContainer && this.scrollContainer.nativeElement) {
        // Scroll the container to the right by 180 pixels
        this.scrollContainer.nativeElement.scrollBy({ 
            left: 180, // Scroll to the right by 180 pixels
            behavior: 'smooth' // Ensure the scrolling is smooth
        });
    }
  }

  // Scroll the truck list to the left
  scrollLeftTrucks(): void {
    // Find the element that contains the truck columns
    const truckColumns = document.querySelector('.truck-columns') as HTMLElement;
    
    // Scroll the truck columns to the left by 150 pixels
    truckColumns.scrollBy({ 
      left: -150, // Scroll to the left by 150 pixels
      behavior: 'smooth' // Ensure the scrolling is smooth
    });
  }

  // Scroll the truck list to the right
  scrollRightTrucks(): void {
    // Find the element that contains the truck columns
    const truckColumns = document.querySelector('.truck-columns') as HTMLElement;
    // Scroll the truck columns to the right by 150 pixels
    truckColumns.scrollBy({ 
      left: 150, // Scroll to the right by 150 pixels
      behavior: 'smooth' // Ensure the scrolling is smooth
    });
  }

  scrollUp(truckId: string) {
    // Find the element that matches the specified truck ID
    const list = document.querySelector(`.vertical-list[data-truck-id="${truckId}"]`);
    // If the element is found, scroll it up by 100 pixels
    if (list) {
      list.scrollBy({
        top: -100,  // Scroll up by 100 pixels
        behavior: 'smooth' // Ensure the scrolling is smooth
      });
    }
  }

  scrollDown(truckId: string) {
    // Find the element that matches the specified truck ID
    const list = document.querySelector(`.vertical-list[data-truck-id="${truckId}"]`);
    // If the element is found, scroll it up by 100 pixels
    if (list) {
        list.scrollBy({
            top: 100, // Scroll up by 100 pixels
            behavior: 'smooth' // Ensure the scrolling is smooth
        });
    }
  }

  simulateFillingBigTruck(): void {
    const interval = setInterval(() => {
      if (this.startLevel <= this.fillLevelBigTruck ) {
        this.startLevel += (this.fillLevelBigTruck/4); 
      } else {
        clearInterval(interval);
      }
    }, 200);

    // comment line below to see FULL fill level animation
    // this.fillLevelBigTruck = 100;
    // console.log(this.fillLevelBigTruck);

  }

  addRoutesToTrucks(routesList: any[], truckList: any[]) {
    // We use a backward loop to avoid problems when modifying the array during iteration
    for (let i = routesList.length - 1; i >= 0; i--) {
      const routeArray = routesList[i];
      const truckId = routeArray[0]?.truck_id;
  
      // Find the corresponding truck in the truckList
      const truck = truckList.find(truckItem => truckItem.truck_id === truckId);
  
      if (truck) {
        // If the truck was found, add the complete route array to the truck's routes array
        if (!truck.routes) {
          truck.routes = []; // Initialize the routes array if it does not already exist
        }
  
        // Add the entire route array (as a single item) to the truck's routes array
        truck.routes.push(routeArray);
  
        // Remove the routes array from the routesList
        routesList.splice(i, 1);
      }
    }
  }

  // Method to return the translated label of the acronym
  getBinUsageLabel(value: string): string {
    const bin = BinUsage.find(item => item.value === value);
    return bin ? bin.label : value; // Returns the translation key (label) or the acronym itself if not found
  }
}

