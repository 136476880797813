// Constant used in roles for the user edit page
export const Roles = [
  { "value" : "0", "label" : "_admin"},
  { "value" : "1", "label" : "_client"},
  { "value" : "2", "label" : "_distributor"},
  { "value" : "4", "label" : "_operations"},
  { "value" : "5", "label" : "_driver"},
  { "value" : "6", "label" : "_dispatch"},
  { "value" : "7", "label" : "_maintenance"}
];
