<!-- Gridster Parent Container -->
<article [ngClass]="theme.getThemeClass()">
  <!-- Gridster -->
  <gridster
    [ngClass]="theme.getThemeClass()"
    [options]="gridsterOptions"
    style="height: 100vh !important"
    class="gridster"
  >
    <!-- Navigation Items -->
    <ng-container *ngFor="let item of navigationItemsArray">
      <!-- Tile -->
      <gridster-item [item]="item">
        <!-- Tile content -->
        <nav [ngClass]="theme.getThemeClass()" class="dashboard-tile">
          <header class="dashboard-tile-header">
            <!-- tile title  -->
            <h6 class="dashboard-tile-title">
              <a
                style="color: inherit; text-decoration: none; cursor: pointer"
                href="{{ item.routeUrl }}"
                >{{ item.name | translate }}</a
              >
            </h6>
            <span class="dashboard-tile-action-container">
              <a
                class="nav-link e"
                href="javascript:void(0);"
                style="border: none"
                data-bs-toggle="dropdown"
              >
                <i class="fa fa-bars"></i>
              </a>
              <ul
                [ngClass]="theme.getThemeClass()"
                class="ulDrop dropdown-menu dropdown-menu-light"
              >
                <!-- Menu Items -->
                <li>
                  <a
                    [ngClass]="theme.getThemeClass()"
                    class="dropdown-item"
                    href="{{ item.routeUrl }}"
                  >
                    <i class="textAlignCenter" [ngClass]="item.icon"></i>
                    <span class="customMarginLeft1">
                      {{ item.name | translate }}
                    </span>
                  </a>
                </li>
                <!-- special menu items for truck tile -->
                <li
                  style="cursor: pointer"
                  *ngIf="item.name === 'sidenavMenu.Trucks'"
                >
                  <a
                    [ngClass]="theme.getThemeClass()"
                    class="dropdown-item"
                    (click)="displayTruckCount()"
                  >
                    <i class="fa-solid fa-hashtag"></i>
                    <span class="customMarginLeft1">
                      {{ "truckCount" | translate }}
                    </span>
                  </a>
                </li>
                <li
                  style="cursor: pointer"
                  *ngIf="item.name === 'sidenavMenu.Trucks'"
                >
                  <a
                    [ngClass]="theme.getThemeClass()"
                    class="dropdown-item"
                    (click)="displayTruckGraph()"
                  >
                    <i class="fa-solid fa-chart-bar"></i>
                    <span class="customMarginLeft1">
                      {{ "truckGraph" | translate }}
                    </span>
                  </a>
                </li>
              </ul>
            </span>
          </header>
          <div
            #dashboardTile
            [ngClass]="theme.getThemeClass()"
            class="dashboard-tile-content"
          >
            <nav
              *ngIf="
                !dashboard.stopLoading && item.name !== 'sidenavMenu.ZONES'
              "
              class="loading-gif"
              style="
                display: flex;
                justify-content: center;
                align-items: center;
              "
            >
              <!-- Container with box styles and fade-in-out animation -->
              <div class="box fade-in-out">
                <!-- Font Awesome spinner icon with custom styles -->
                <div class="fa-3x mb-2">
                  <i class="fa-solid fa-spinner fa-spin-pulse fa-2xl color"></i>
                </div>
                <!-- Logo container with dynamic theme class and navigation link -->
                <div>
                  <a
                    [ngClass]="theme.getThemeClass()"
                    class="hide-too-small-screen navbar-brand px-1"
                    href="javascript:void(0);"
                    data-bs-toggle="dropdown"
                  >
                    <!-- Logo image with custom styles -->
                    <img class="logo" src="../assets/muirwood-studio.png" />
                  </a>
                </div>
              </div>
            </nav>
            <!-- charts would go here if applicable -->
            <div class="tile-body">
              <!-- chart-container -->
              <div
                class="chart-container"
                *ngIf="item.name === 'sidenavMenu.Trucks'"
              >
                <!-- truck graph -->
                <div
                  [ngClass]="{ hideGraph: showTruckCount }"
                  class="side front-side"
                >
                  <app-bar-chart-admin
                    #chart
                    class="bar-chart"
                  ></app-bar-chart-admin>
                </div>
                <!-- truck count -->
                <div
                  [ngClass]="{ showCount: showTruckCount }"
                  class="side back-side"
                >
                  <div class="progress-text">
                    {{ this.chartSerice.singleBarChartAdmin.length }}
                  </div>
                  <img
                    class="truck"
                    src="../../assets/truckpng.png"
                    alt="truck image"
                    srcset=""
                  />
                </div>
              </div>
            </div>
          </div>
        </nav>
      </gridster-item>
    </ng-container>
  </gridster>
</article>
