<div  [ngClass]="theme.getThemeClass()"  class="container-fluid bg-light main-dashboard" style="background-color: rgb(255, 255, 255); height: 100vh; margin-top: 5px;">

    <section [ngClass]="theme.getThemeClass()" class="dashboard-container row bg-light">
    <app-error-modal></app-error-modal>

    <!-- Section of different dashboard depend on user type -->
    <!-- <truck-list></truck-list> -->
  <driver-dashboard *ngIf="userType === 'operator' && roles.includes('_driver')"></driver-dashboard>
 <dispatch-dashboard *ngIf="userType === 'operator' && roles.includes('_dispatch')"></dispatch-dashboard>

  <!-- <operator-admin-dashboard></operator-admin-dashboard> -->
    </section>
</div>


  