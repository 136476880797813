<app-system-message></app-system-message>
<!-- Container for the form with dynamic styling -->
<div [ngClass]="theme.getThemeClass()" class="container-fluido">
    <section [ngClass]="theme.getThemeClass()" class="card bg-light">
        <article class="card-body mx-auto article">
            <h4 class="card-title mt-0 text-center">{{ 'operatorCreateViewOperatorCreate' | translate }}</h4>
            <form>
                <!-- Input for operator name -->
                <label class="label-spacer" for="distributor_name">{{ 'operatorCreateViewOperatorName' | translate }} <span class="requiredStar">*</span></label>
                <div class="input-group mb-3">
                    <span class="input-group-text" id="basic-addon1"><i class="fa fa-user"></i></span>
                    <input [(ngModel)]="this.operator.operator_data.operator_name" type="text"
                    class="form-control" name="operator_name"
                    placeholder="{{ 'operatorCreateViewOperatorName' | translate }}" aria-label="operator_name"
                    aria-describedby="basic-addon1"
                    (blur)="this.validationService.validateClientName(this.operator.operator_data.operator_name)"
                    [class.error-border]="this.validationService.validationErrorArray[0] == 'usernameInvalid'">
                </div>
                <!-- Input for operator legal name -->
                <label class="label-spacer" for="distributor_name">{{ 'legalName' | translate }} <span class="requiredStar">*</span></label>
                <div class="input-group mb-3">
                    <span class="input-group-text" id="basic-addon1"><i class="fa-regular fa-id-card"></i></span>
                    <input [(ngModel)]="this.operator.operator_data.legal_name" type="text"
                    class="form-control" name="legal_name"
                    placeholder="{{ 'clientcreateFormInputLegalName' | translate }}" aria-label="legal_name"
                    aria-describedby="basic-addon1"
                    (blur)="this.validationService.validateClientLegalName(this.operator.operator_data.legal_name)"
                    [class.error-border]="this.validationService.validationErrorArray[0] == 'clientLegalNameInvalid'">
                </div>
                <!-- Input for operator phone number -->
                <label class="label-spacer" for="distributor_name">{{ 'userlistClientsViewTableHeaderPhoneNumber' | translate }} <span class="requiredStar">*</span></label>
                <div class="input-group mb-3">
                    <span class="input-group-text" id="basic-addon1"><i class="fa fa-phone"></i></span>
                     <input [(ngModel)]="this.operator.operator_data.phone_number" type="text"
                    class="form-control telefone-input" name="phone_number"
                    placeholder="(000) 000-0000" aria-label="phone_number"
                    aria-describedby="basic-addon1"
                    (blur)="this.validationService.validatePhoneNumber(this.operator.operator_data.phone_number)"
                    [class.error-border]="this.validationService.validationErrorArray[0] == 'clientPhoneInvalid'"> 
                   
                </div>
                <!-- Input field for address with Autocomplete -->
                <label class="label-spacer" for="distributor_name">{{ 'address' | translate }} <span class="requiredStar">*</span></label>
                <div class="input-group mb-3">
                    <span class="input-group-text" id="basic-addon1"><i class="fas fa-search-location"></i></span>
                    <input id="autocomplete" [(ngModel)]="this.operator.operator_data.address" (keydown)="onInputAddressKeydown($event);"
                    type="text" class="form-control" name="address"
                    placeholder="{{ 'clientcreateFormInputFindAddress' | translate }}"aria-label="Enter a place" aria-describedby="basic-addon1"
                    (blur)="this.validationService.validateAddress(this.operator.operator_data.address)"
                    [class.error-border]="this.validationService.validationErrorArray[0] == 'clientAddressInvalid'">
                </div>
                <!-- Input for operator email -->
                <label class="label-spacer" for="distributor_name">{{ 'email' | translate }} <span class="requiredStar">*</span></label>
                <div class="input-group mb-3">
                    <span class="input-group-text" id="basic-addon1"><i class="fa fa-envelope"></i></span>
                    <input [(ngModel)]="this.operator.operator_data.email" type="text"
                    class="form-control" name="email"
                    placeholder="{{ 'email' | translate }}" aria-label="email"
                    aria-describedby="basic-addon1"
                    (blur)="this.validationService.validateClientEmail(this.operator.operator_data.email)"
                    [class.error-border]="this.validationService.validationErrorArray[0] == 'clientEmailInvalid'">
                </div>
                <!-- Submit and cancel button -->
                <div class="form-group buttonDiv">
                    <button (click)="returnToEntities()" [ngClass]="theme.getThemeClass()" type="button" class="btn btn-block btnCancel">{{ 'clientcreateFormBtnCancel' | translate }}</button>
                    <button [disabled]="this.systemMessageService.buttonBlocked" [ngClass]="theme.getThemeClass()" (click)="onSubmit(); disableButton()" type="button" class="btn btn-block btnCreate">{{ 'operatorCreateViewOperatorCreate' | translate }}</button>
                </div>
            </form>
        </article>
    </section>
</div>


