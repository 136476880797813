import { Component, Injectable, OnInit } from '@angular/core';
import { LocalStorageService } from '../local-storage.service';
import { ModalAlertService } from '../service/alert-modal.service';
import { ThemeService } from '../service/theme.service';

@Component({
  selector: 'app-alert-modal',
  templateUrl: './alert-modal.component.html',
  styleUrls: ['./alert-modal.component.css']
})
@Injectable({
  providedIn: 'root'
})
export class AlertModalComponent implements OnInit{


  // Variables used for UI
  public toActiveAction: boolean = false;

  constructor(public localStorageService: LocalStorageService, 
              public alert: ModalAlertService, 
              public theme: ThemeService){

  }

  ngOnInit(): void {
    // Get the value in alert service of bin or bin-model status active or not then put the proper value of toActivate used in UI to show the proper message
    if(this.alert.active === 1){
      this.toActiveAction = true;
    }else{
      this.toActiveAction = false;
    }
  }
}
