import { Injectable } from '@angular/core';

Injectable({
  providedIn: 'root'
})

// Constant used in tax type pages for country selects
export const ISOCountry = [
    { "value" : "CA", "label" : "Canada"},
]
