import { Component, OnInit, ElementRef, HostListener, Inject, forwardRef } from '@angular/core';
import { RoleService } from '../service/role.service';
import { CognitoService } from '../service/cognito.service';
import { TranslateService } from '@ngx-translate/core';
import { LocalStorageService } from '../local-storage.service';
import { ClientService } from '../service/client.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ValidationService } from '../service/validation.service';
import { Router } from '@angular/router';
import { ThemeService } from '../service/theme.service';
import { NavigationService } from '../service/navigation.service';
import { DistributorsService } from '../service/distributors.service';
import { Roles } from '../constants/roles';
import { SystemMessageService } from '../service/system-message.service';
import { LocalizationService } from '../service/localization.service';
import { Types } from '../constants/user-type';


@Component({
  selector: 'app-user-create-distributor',
  templateUrl: './user-create-distributor.component.html',
  styleUrls: ['./user-create-distributor.component.css']
})
export class UserCreateDistributorComponent implements OnInit {

  public redirectToDistributorUserList: boolean = false;

  lastUrl:string = this.localStorageService.getItem('lastUrl')

  Roles = Roles;
  public Types = Types;
  public form: FormGroup

  // Variable to store the language selected value
  public selectedLanguage: string = "";

  public successMessage: boolean = false;

  public autocomplete: any = google.maps.places; // Google Places Autocomplete

  // Retrieve current language selected from local storage
  languageStatus:string = this.localStorageService.getItem('language');

  // Array Fetch customer data
  public clientsData: any[] = [];
  public distributorData: any[] = [];

  public clientArray: any[] = [];
  public distributorArray: any[] = [];

  public mapa: any = {};

  public newDistributorArray: any [] = [];

  public validateRolesCheckBox: boolean = false;
  public validateDistributorSelect: boolean = false;

  // Variable used to display the UI message
  private systemMessage: string = '';
  public placeholderText: string = "distributorName";

  // Variable to store the result of a place selection from Google Places Autocomplete.
  // It can hold a valid PlaceResult object or be undefined if no place is selected.
  selectedPlace: google.maps.places.PlaceResult | undefined;

  constructor(
    private localStorageService: LocalStorageService,
    @Inject(forwardRef(() => TranslateService)) private translate: TranslateService,
    public cognitoService: CognitoService,
    public roleService: RoleService,
    public clientService: ClientService,
    private formBuilder: FormBuilder,
    public validationService: ValidationService,
    private router: Router,
    public theme: ThemeService,
    public navigationService: NavigationService,
    public distributorService: DistributorsService,
    public systemMessageService: SystemMessageService,
    public localizationService: LocalizationService
  ) {

    // Create a form using the FormBuilder
    this.form = this.formBuilder.group({
      // Define form controls and their initial values
      client_name: ['', Validators.required],
      legal_name: [''],
      email: ['', [Validators.required, Validators.email]],
      phone_number: [''],
      address: ['']
    });

    this.cognitoService.confirmValidUser();
    this.roleService.getRoles();

    // Check if the user has selected a language in local storage
    //or use a default language
    if (this.languageStatus == null){
      // Set the default language to French
      translate.use('fr');
    } else {
      // Set the default language to the user's selected language
      translate.use(this.languageStatus);
    }
  }

  async ngOnInit() {
    this.systemMessageService.buttonBlocked = false;

    this.cognitoService.clearCognitoUserData(); // clear last client create data
    await this.cognitoService.getUserType(); // Get user type

     // Remove the 'lastUrl' item from the localStorage
     this.localStorageService.removeItem('lastUrl');

     // Switch statement based on the value of 'lastUrl'
     switch (this.lastUrl) {

      // If 'lastUrl' is "deviceList"
      case "distributor-admin-dashboard":
        // Activate the "clients" state
        this.redirectToDistributorUserList = true;
      break;

      default:
         // Deactivate both states
         this.redirectToDistributorUserList = false;
      break;
     }

    if(this.cognitoService.userType === 'muirwood'){
      this.getDistributorList(); // to fill option of select distributor input
    }
    else{
      await this.cognitoService.getUser().then((response) => {
        this.cognitoService.cognitoUserData.custom_distributor_id = response.attributes['custom:distributor_id'];
      })
    }
    this.localizationService.initAutocomplete(); // init autocomplete api
  }

  disableButton() {
    this.systemMessageService.buttonBlocked = true;
  }

  // Function that return distributor list with only distributor_id and distributor_name
  getDistributorList() {
    try {
      // Call the distributor service to get the list of distributors
      this.distributorService.getDistributors().subscribe(
        (response: any) => {
          // Store the API response in the clientsData array
          this.distributorData = response;

          // Assign distributorData to distributorArray
          this.distributorArray = this.distributorData;

          // Create a new array containing unique distributors based on name and ID
          this.newDistributorArray = this.distributorArray.reduce((accumulator, item) => {
            const key = item.distributor_name + item.distributor_id;
            if (!this.mapa[key]) {
              this.mapa[key] = true;
              // Add unique clients to the newDistributorArray
              accumulator.push({ distributor_id: item.distributor_id, distributor_name: item.distributor_name });
            }
            return accumulator;
          }, []);

      })

    } catch(error) {
      // Log any errors that occur during the process
      console.error(error);
    }
  }

  // runs a validation test checking every inputs
  checkValidationAllInputs() {

    // Check if username is not empty, its length is greater than 16,
    // or it doesn't match the alphanumeric pattern
    this.validationService.validateClientName(this.cognitoService.cognitoUserData.username);

    // Check if a distributor was selected
    this.validationService.validateClientInput(this.cognitoService.cognitoUserData.custom_distributor_id)

    // Check if given_name is not empty, its length is greater than 16,
    // or it doesn't match the alphanumeric pattern
    this.validationService.validateGivenName(this.cognitoService.cognitoUserData.given_name);

    // Check if family_name is not empty, its length is greater than 16,
    // or it doesn't match the alphanumeric pattern
    this.validationService.validateFamilyName(this.cognitoService.cognitoUserData.family_name);

    // Check if the clientEmail is not a valid email or is empty
    this.validationService.validateClientEmail(this.cognitoService.cognitoUserData.email);

    // Check if the roles is empty
    this.validationService.validateRoles();

    // Define a mapping of error codes to corresponding error messages
    const errorMappings: Record<string, string> = {
      'clientInputError': 'invalidDistributorSelection',
      'roleSelectionInvalid': 'invalidRoleSelection',
      'usernameInvalid': 'invalidUserNameError',
      'givenNameInvalid': 'invalidGivenNameError',
      'familyNameInvalid': 'invalidFamilyNameError',
      'clientEmailInvalid': 'invalidEmail',
      'clientPhoneInvalid': 'invalidInput',
      'clientAddressInvalid': 'invalidAddress',
      'usernameNotUnique': 'usernameInUse',
    };

    // Retrieve the first validation error from the array
    const validationError = this.validationService.validationErrorArray[0];

    // Check if the validation error code exists in the mapping
    if (errorMappings[validationError]) {
      this.systemMessageService.buttonBlocked = true;
      // If so, display a danger ribbon message with the corresponding key
      this.systemMessageService.selectRibbon('danger', errorMappings[validationError]);
    }
  }

  // Function triggered when user click save button to create new user
  async createUser(){
    // Set the address of the cognito user data
    this.cognitoService.cognitoUserData.address = this.localizationService.autoCompletionReturnAddress;

    // Remove non-numeric characters from the phone number using the validationService
    this.validationService.keepOnlyNumbers(this.cognitoService.cognitoUserData.phone_number);
    // Update the client's phone number and creation date with the formatted values
    this.cognitoService.cognitoUserData.phone_number = this.validationService.phoneNumberFixed;

    // Check the validation of all fields
    this.checkValidationAllInputs();

    if(this.validationService.usernameValid &&
      this.validationService.givenNameValid &&
      this.validationService.familyNameValid &&
      this.validationService.clientEmailValid &&
      this.validationService.userRolesValid){

        try {
          // Attempt to sign up the user and send a temporary password
          await this.cognitoService.UserSignUpAndSendTemporaryPassword(Types[2].label, Roles[2].label);

           // Variable used by bin-model-list html to show the proper message on success updating bin model
          sessionStorage.setItem("from", "user-create");

          // If cognito add user doesn't break then call the function to get back to admin component
          setTimeout(()=>{

            if(this.redirectToDistributorUserList) {
              this.returnToDistributorAdminDashboard();
            } else {
              this.returnToAdmin();

            }
          }, 3000);
        }catch(error: any){

          if (error.response && error.response.status === 400) {
            // Handle 400 Bad Request errors
            if (error.name === 'UsernameExistsException') {
              console.error("Bad Request: User account already exists.");
              // Set the message throw to the user
              this.systemMessage = 'userAlreadyExist';

            } else {
              // Log an error if the 400 error is not due to an existing username
              console.error("Error creating user:", error.message);
            }
          } else {
            // Handle unexpected errors
            if(error.toString().includes("UsernameExistsException")){
              // Set the message throw to the user
              this.systemMessage = 'emailAlreadyExist';
            }
            console.error("Unexpected error:", error);
          }
          // Set the username as invalid if error = UsernameExistsException: User account already exists
          if(error.toString().includes("InvalidParameterException") && error.toString().includes("username")){
            this.systemMessage = "invalidUsernameFormat"
          }
          this.systemMessageService.buttonBlocked = true;
          this.systemMessageService.selectRibbon('danger', this.systemMessage);
        }
      }else{
        this.systemMessageService.buttonBlocked = true;
        // Set the message error throwed to the uer
        this.systemMessage = 'fieldEmptyOrIncorrect';
        // throw the message to the user
        this.systemMessageService.selectRibbon('danger', this.systemMessage);
      }
  }

  // function to reload page when cancel button is clicked
  returnToAdmin() {
    this.localStorageService.addItem('adminContent', 'userList')
    this.router.navigate(['/admin']); // Redirect to the '/admin' route after the delay
  }

  // function to reload page when cancel button is clicked
  returnToDistributorAdminDashboard(){
    this.router.navigate(['/admin']);
  }

  // Function used to redirect user to his dashboard depending on user userType
  toPreviousPage(){
    const from = sessionStorage.getItem('createDistributor')
    if(from){
      switch(from){
        case 'dashboard':
          this.router.navigate(['/dashboard'])
          break;
        case 'distributor-admin-dashboard':
          this.router.navigate(['/distributor-admin-dashboard']);/* client-user-list */
          break;
      }
    }else{
      this.router.navigate(['/admin']);
    }
    sessionStorage.removeItem('createDistributor')
  }

  // Function used to remove placeholder on select of distributor name when there's something selected
  onOptionSelected(): void {
    if (this.cognitoService.cognitoUserData.custom_distributor_id) {
      this.placeholderText = "";
    } else {
      this.placeholderText = 'distributorName';
    }
  }

  // Function to format a numeric string as a phone number in the format (000) 000-0000
  formatPhoneNumber(value: string): string {
    // Check if the input value is empty and return an empty string if it is
    if (value.length === 0) return '';

    // Extract the area code, first part, and second part of the phone number
    const areaCode = value.slice(0, 3);
    const firstPart = value.slice(3, 6);
    const secondPart = value.slice(6, 10);

    // Format the phone number as (000) 000-0000 and return the formatted string
    return `(${areaCode}) ${firstPart}-${secondPart}`;
  }

  // Function to change the language based on user selection
  changeLanguage(language: string) {
    // Use the 'translate' service to set the language to the specified 'language'
    this.translate.use(language);
  }

  // Function that avoid submitting the page when user press enter at the end of inputting address in address input
  onInputAddressKeydown(event: KeyboardEvent): void {
    if(event.key === 'Enter'){
      event.preventDefault(); // Don't submit the page
    }
  }

  // Decorator @HostListener listens to the 'input' event on the specified target element
  @HostListener('input', ['$event.target']) onInput(input: HTMLInputElement): void {
    // Check if the input element has the class 'telefone-input'
    if (input.classList.contains('telefone-input')) {
      // Remove all non-numeric characters from the input value
      const value = input.value.replace(/\D/g, '');

      // Check if the value has a length of 10 characters or less
      if (value.length <= 10) {
        // Format the phone number as (000) 000-0000
        input.value = this.formatPhoneNumber(value);
      } else {
        // If the value is longer than 10 digits, limit the input to 10 digits
        input.value = input.value.slice(0, 10);
      }
    }
  }

  /**
   * Simple function that return true or false depending if we want to NOT display a role in the HTML role container
   * @param role
   * @returns 
   */
  distributorRolesToBeDisplayed(role : string){
    switch(role){
      case '_client':
      case '_driver':
      case '_dispatch':
      case '_maintenance':
        return false;
        break;
        
      default:
        return true;
        break;
    }
  }
}
