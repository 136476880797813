import { Component, OnInit, HostListener, ViewChildren, QueryList, ElementRef } from '@angular/core';
import { CognitoService } from '../service/cognito.service';
import { ThemeService } from '../service/theme.service';
import { OperatorService } from '../service/operator.service';
import { environment } from '../environments/environment';
import { ValidationService } from '../service/validation.service';
import { SystemMessageService } from '../service/system-message.service';
import { Roles } from '../constants/roles';
import { Router } from '@angular/router';
import { Types } from '../constants/user-type';
import { LocalizationService } from '../service/localization.service';

@Component({
  selector: 'app-user-create-operator',
  templateUrl: './user-create-operator.component.html',
  styleUrl: './user-create-operator.component.css'
})
export class UserCreateOperatorComponent implements OnInit{

  public operatorArray: any;

  // Variable used for the validation
  private operatorInputValid: boolean = false;

  // Used by HTML array
  public RolesArray = Roles;
  // 
  private rolesToUncheck: string[] = [];

  private systemMessage: string = '';
  public userEnabled: boolean = false;

  constructor(public cognitoService: CognitoService,
              public theme: ThemeService,
              public operatorService: OperatorService,
              public validationService: ValidationService,
              public systemMessgeService: SystemMessageService,
              private router: Router,
              private localizationService: LocalizationService,
              public systemMessageService: SystemMessageService){

    this.cognitoService.confirmValidUser();
    this.cognitoService.getCurrentRole([environment.users.role.administrator], [environment.users.superAdmin]);
  }

  // Decorator @HostListener listens to the 'input' event on the specified target element
  @HostListener('input', ['$event.target']) onInput(input: HTMLInputElement): void {
    // Check if the input element has the class 'telefone-input'
    if (input.classList.contains('telefone-input')) {
      // Remove all non-numeric characters from the input value
      const value = input.value.replace(/\D/g, '');

      // Check if the value has a length of 10 characters or less
      if (value.length <= 12) {
        // Format the phone number as (000) 000-0000
        input.value = this.formatPhoneNumber(value);
      } else {
        // If the value is longer than 10 digits, limit the input to 10 digits
        input.value = input.value.slice(0, 12);
      }
    }
  }

  // Returns the specified elements from the DOM, in this case the roles checkboxes. Used to manage the checkboxes
  @ViewChildren('rolesSwitch') rolesSwitch!: QueryList<ElementRef>

  async ngOnInit() {
    this.systemMessageService.buttonBlocked = false;

    // Get an array of all operator in DB
    this.operatorArray = await this.operatorService.getOperators();
    // set autocomplete for the address in localization service
    this.localizationService.initAutocomplete();
    // Clear the existing data on cognito user data
    this.cognitoService.clearCognitoUserData();
  }

  disableButton() {
    this.systemMessageService.buttonBlocked = true;
  }

  // Function called when user click on creation button
  async createUser(){
    // Set the address of the cognito user data
    this.cognitoService.cognitoUserData.address = this.localizationService.autoCompletionReturnAddress;

    // Remove non-numeric characters from the phone number using the validationService
    this.validationService.keepOnlyNumbers(this.cognitoService.cognitoUserData.phone_number);
    // Update the client's phone number and creation date with the formatted values
    this.cognitoService.cognitoUserData.phone_number = this.validationService.phoneNumberFixed;

    // Check the validation of all fields
    this.checkValidationAllInputs();

    if(this.validationService.usernameValid &&
      this.validationService.givenNameValid &&
      this.validationService.familyNameValid &&
      this.validationService.clientEmailValid &&
      this.validationService.clientPhoneNumberValid &&
      this.validationService.clientAddressValid &&
      this.validationService.userRolesValid){

        try {
          // Attempt to sign up the user and send a temporary password
          const result = await this.cognitoService.UserSignUpAndSendTemporaryPassword(Types[3].label, Roles[4].label);
          if(result === 'duplicated'){
            this.systemMessageService.buttonBlocked = true;

            this.systemMessageService.selectRibbon('danger','Duplicated email');
          } else {
            // Variable used by bin-model-list html to show the proper message on success updating bin model
            sessionStorage.setItem("from", "user-create");
            // If cognito add user doesn't break then call the function to get back to admin component
            this.returnToAdmin();
          }
        }catch(error: any){
          if (error.response && error.response.status === 400) {
            // Handle 400 Bad Request errors
            if (error.name === 'UsernameExistsException') {
              console.error("Bad Request: User account already exists.");
              // Set the message throw to the user
              this.systemMessage = 'userAlreadyExist';

            } else {
              // Log an error if the 400 error is not due to an existing username
              console.error("Error creating user:", error.message);
            }
          } else {
            // Handle unexpected errors
            if(error = "UsernameExistsException: User account already exists"){
              // Set the message throw to the user
              this.systemMessage = 'userAlreadyExist';
            }
            console.error("Unexpected error:", error);
          }

          if(error.toString().includes("InvalidParameterException") && error.toString().includes("username")){
            this.systemMessage = "invalidUsernameFormat"
          }

          this.systemMessageService.buttonBlocked = true;

          // Set the username as invalid if error = UsernameExistsException: User account already exists
          this.systemMessageService.selectRibbon('danger', this.systemMessage);
        }
      }else{
        this.systemMessageService.buttonBlocked = true;

        // Set the message error throwed to the uer
        this.systemMessage = 'fieldEmptyOrIncorrect';
        // throw the message to the user
        this.systemMessageService.selectRibbon('danger', this.systemMessage);
      }
  }

  // Funciton used to check if all input are not empty and well fill
  checkValidationAllInputs(){
    // Check if user select an operator
    if(this.cognitoService.cognitoUserData.custom_operator_id !== ''){
      this.operatorInputValid = true;
    }
    
    // Check if username is not empty, its length is greater than 16,
    // or it doesn't match the alphanumeric pattern
    this.validationService.validateUsername(this.cognitoService.cognitoUserData.username);
    this.validationService.validateGivenName(this.cognitoService.cognitoUserData.given_name);
    this.validationService.validateFamilyName(this.cognitoService.cognitoUserData.family_name);

    // Check if the clientEmail is not a valid email or is empty
    this.validationService.validateClientEmail(this.cognitoService.cognitoUserData.email);

    // Check if phoneNumber is not empty and its length is less than 10 or if it's empty
    this.validationService.validatePhoneNumber(this.cognitoService.cognitoUserData.phone_number);

    // Check if the address is empty
    this.validationService.validateAddress(this.cognitoService.cognitoUserData.address);

    this.validationService.validateRoles();
  }

  // Function that avoid submitting the page when user press enter at the end of inputting address in address input
  onInputAddressKeydown(event: KeyboardEvent): void {
    if(event.key === 'Enter'){
      event.preventDefault(); // Don't submit the page
    }
  }

  // Function that return phone number formated ### ########
  formatPhoneNumber(numberSequence: string | undefined | null): string {
    if (numberSequence === undefined || numberSequence === null) {
        return ''; // or handle the case appropriately
    }

    // Remove non-numeric characters
    const justNumbers = numberSequence.replace(/\D/g, '');

    // Check if the input is empty after removing non-numeric characters
    if (justNumbers.length === 0) {
        return '';
    }

    // Extract the last 10 digits (ignoring the country code, if present)
    const last10Digits = justNumbers.slice(-10);

    // Format the last 10 digits
    const part1 = last10Digits.slice(0, 3);
    const part2 = last10Digits.slice(3, 6);
    const part3 = last10Digits.slice(6);
    const formatedNumber = `(${part1}) ${part2}-${part3}`;

    // If the country code was present, add it back
    const areaCode = justNumbers.slice(0, -10);
    return areaCode.length > 0 ? `+${areaCode} ${formatedNumber}` : formatedNumber;
  }

  // Implemented by cancel button to return to the admin component
  returnToAdmin(){
    // Put a sessions store variable so admin component could know to return on bin-list
    sessionStorage.setItem("previous", "user-list");
    this.router.navigate(['/admin']); // Return to admin component
  }

  /**
   *  Function use for operators so they can be only one of those roles: driver, dispatch or maintenance.
   * @param role 
   */
  checkRolesRestriction(role: string){
    // Each checkbox must toggle itself on click
    this.cognitoService.toggleRole(role)
    switch(role){
      case '_driver':
        // Array of roles we want to uncheck if we select driver
        this.rolesToUncheck = ['_dispatch', '_maintenance'];
        // Manage unchecking and removing roles from the userRoles
        this.manageRolesRestriction(this.rolesToUncheck);
        break;

      case '_dispatch':
        // Array of roles we want to uncheck if we select dispatch
        this.rolesToUncheck = ['_driver', '_maintenance'];
        // Manage unchecking and removing roles from the userRoles
        this.manageRolesRestriction(this.rolesToUncheck);
        break;

      case '_maintenance':
        // Array of roles we want to uncheck if we select maintenance
        this.rolesToUncheck = ['_driver', '_dispatch'];
        // Manage unchecking and removing roles from the userRoles
        this.manageRolesRestriction(this.rolesToUncheck);
        break;
    }
  }

  /**
   * Manage uncheck of sliders and removing roles from the userRoles array. Takes a string[] of the roles we want to uncheck
   * and remove from the userRoles.
   * @param roles 
   */
  manageRolesRestriction(roles: string[]){
    for(const role of roles){
      this.rolesSwitch.forEach(element => {
        const checkbox = element.nativeElement as HTMLInputElement;
        // Uncheck slider and remove role from userRoles if we click on driver or dispatch and it is check
        if(checkbox.value === role && checkbox.checked === true){
          checkbox.checked = false;
          // Remove role from userRoles
          this.cognitoService.toggleRole(checkbox.value)
        }
      })
    }
  }

  /**
   * Simple function that return true or false depending if we want to NOT display a role in the HTML role container
   * @param role
   * @returns 
   */
  operatorRolesToBeDisplayed(role : string){
    switch(role){
      case '_distributor':
      case '_client':
        return false;
        break;
      default:
        return true;
        break;
    }
  }
}
