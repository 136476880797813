import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from '../environments/environment';
import { Injectable } from '@angular/core';
import { map, catchError, Subject, lastValueFrom, Observable, throwError } from 'rxjs';
import { error } from 'jquery';

export interface Operator {
  operator_id: string;
  operator_name: string;
  legal_name: string;
  phone_number: string;
  address: string;
  logo: string;
  email: string;
  active: string;
  created: string;
  modified: string;
}

@Injectable({
  providedIn: 'root'
})
export class OperatorService {

  // Variables used by HTML on success CRUD
  public successMessage: any;

  // Array of operators
  public array: any;
  public operator_array: Operator[]= [];
  public original_operator_array: Operator[] = [];
  public operatorClientList: any;
  public operatorClientListWithNames: any;
  public operatorDriversArray: any[] = [];

  // Variable used for the Filter
  public filter_count: number = 0;

  // Variables used for validation
  public emptyField: boolean = false;

  // Variables that bin and bin-model list will used to trigger there function when there is a create or update made
  private changeMade = new Subject<void>;
  changeMade$ = this.changeMade.asObservable();
  private noChangeMade = new Subject<void>;
  noChangeMade$ = this.noChangeMade.asObservable();


  operator_data: Operator = {
    operator_id: '',
    operator_name: '',
    legal_name: '',
    phone_number: '',
    address: '',
    logo: '',
    email: '',
    active: '',
    created: '',
    modified: ''
  }

  constructor(private http: HttpClient) {

  }

  // Function callled to set back operator_array to it's original
  async resetOperatorArray() {
    this.operator_array = [...this.original_operator_array];
  }

  // Function called to set operator_data datas to its original values
  initOperatorData() {
    this.operator_data = {
      operator_id: '',
      operator_name: '',
      legal_name: '',
      phone_number: '',
      address: '',
      logo: '',
      email: '',
      active: '',
      created: '',
      modified: ''
    }
  }

  // Reset the filtered array without making a new call to AWS. Used when user clear filter or backspace
  resetFilterOperatorArray(){
    this.operator_array = [...this.original_operator_array];
  }

  // Will be set whit filter service with the count of the string(userFilter) to know when user do backspace and reset the array
  setCountFilter(count: number){
    this.filter_count = count;
  }

  // Will be called by the component that call filter service to share the last userFilter count
  getCountFilter(){
    return this.filter_count;
  }

  // Function called to check into TAXE TYPE DATA IF THERE's value when created or updated for an additionnal validation
  hasOwnProperty(CRUD:string){
    //reset the value of the emptyField variable
    this.emptyField = false;

    // Check each field if they are empty
    if(this.operator_data.operator_name === ''){
      this.emptyField = true;
    }
    if(this.operator_data.legal_name === ''){
      this.emptyField = true;
    }
    if(this.operator_data.phone_number === ''){
      this.emptyField = true;
    }
    if(this.operator_data.address === ''){
      this.emptyField = true;
    }
    if(this.operator_data.email === ''){
      this.emptyField = true;
    }
    if(CRUD === 'update' && this.operator_data.operator_id === ''){
      this.emptyField = true;
    }
  }

  // Function called from create/update/delete operator
  onSuccessCRUD(){
     // Call the function that will trigger operator-list that there is a change made in the list
     this.changeHaveBeenMade();

    // Set a timeOut of 5 seconds so the message of success create or update will disapear
    setTimeout(() => {
      this.successMessage = '';
    }, 5000);
  }

  // Function that is been subscribe in operator-list when there is an create or update made in operator-create or operator-update
  // This function will made reinitialize the list of operator when a change apend


  // Function called when a CRUD change have been made
  async changeHaveBeenMade(){
    this.changeMade.next();
  }

  // Function called when CRUD fail
  async noChangeHaveBeenMade(){
    this.noChangeMade.next();
  }


  //////// Those CRUD functions are the one called by the components. Please do not do function bellow insted of function used to CALL Lambda functions /////////
  //////// No delete function is in because we just update the status of operators ///////////



  // Create operator
  async createOperator(){
    // Call the function that call the lambda function
    this.createOperatorLambda().subscribe((response) => {
      this.successMessage = response; 
      if(this.successMessage.message === 'success'){
        this.onSuccessCRUD(); // Call a function that will set all thing for the UI
      }else{
        this.noChangeHaveBeenMade();
      }
    });
  }

  // Get the operator list
  async getOperators(){
    return new Promise<any[]>((resolve) => {
      this.getOperatorsLambda().subscribe((response) => {
        // Put the array of operators returned by the lambda function into an tmp array
        this.array = response;
        // Put the tmp array into the service operator_array
        this.operator_array = this.array;
        this.original_operator_array = this.array;
        resolve(this.array);
      });
    });
  }

  // Get operator by id
  getOperatorById(operator_id: string){
    // Loop thought the operator array to get the selected bin
    for(let i = 0; i < this.operator_array.length; i ++){
      // will select only the operator in operator array for the asked id
      if(this.operator_array[i].operator_id === operator_id){
        this.operator_data = this.operator_array[i]; // Initiate the operator_data whit the selected bin in the bin array
        break;
      }
    }
    return this.operator_data; // Return the selected operator
  }

  // Update operator
  async updateOperator(){
    // Call the function that made the call to lambda function and wait for is response
    this.updateOperatorLambda().subscribe((response) => {
      this.successMessage = response;
      if(this.successMessage.message === 'success'){
        this.onSuccessCRUD(); // Call a function that will set all thing for the UI
      }else{
        this.noChangeHaveBeenMade();
      }
    });
  }

  // Delete a operator
  async deleteOperator(){
    // Call the function that made the call to lambda function and wait for is response
    this.deleteOperatorLambda().subscribe((response) => {
        this.successMessage = response;
      if(this.successMessage.message ==='success'){
        this.onSuccessCRUD(); // Call a function that will set all thing for the UI
      }else{
        this.noChangeHaveBeenMade();
      }
    });
  }

  async getDriversByOperatorId(operator_id: string): Promise<any[]> {
    return new Promise<any[]>(async (resolve) => {
      // Call getUsersDriversByOperatorId with the operator_id and subscribe to the response
      (await (this.getUsersDriversByOperatorId(operator_id.toString()))).subscribe(
        (response) => {
          // If the response is successful, store the drivers in operatorDriversArray
          this.operatorDriversArray = response;
          // Resolve the promise with the drivers array
          resolve(this.operatorDriversArray);
        },
        (error) => {
          // Log any errors that occur during the subscription
          console.error("Subscription Error:", error); 
          // Resolve the promise with an empty array if there's an error
          resolve([]); 
        }
      );
    });
  }
  
  // Function to set an array of the keys we should
  switchJSONToIntForUserResponse(element: any){
    // Tableau of witch keys we need to check
    const keysToCheck = [
      'created',
      'modified'
    ];
    // Call the function to transfert all value of the keys in Integer
    this.keyToTransfert(keysToCheck, element);
  }

  // Use the array of keys to transfert strings into int
  keyToTransfert(keysToCheck: any, element: any){
    keysToCheck.forEach((key: any) => {
      // Vérifier si la clé existe dans l'élément et si sa valeur est une chaîne
      if (typeof element[key] === 'string') {
          // Convertir la valeur de la clé en entier
          element[key] = parseInt(element[key], 10);
      }
    });
  }

  ////////// All function bellow are function that call the lambda function from AWS. Please do not do functions bellow insted of Lambda calls /////////////
  //////// No delete function is in because we just update the status of bin and bin-model ///////////

  // function that call the lambda API to get all users of the distributor
  async getUsersDriversByOperatorId(operator_id: string) {
    // Set url for lambda call
    const baseUrl = environment.api.stage + environment.api.route.getUsersDriversByOperatorId;

    const url = `${baseUrl}&operator_id=${operator_id}`;

    try {
      // Make an HTTP GET request to the Lambda function
      // const response$ = this.http.get(url)
      const response$ = this.http.get<any[]>(url);
      const response = await lastValueFrom(response$);

      this.operatorDriversArray = response

      // Return the original response (Observable) to the calling code
      return response$;

    } catch (error) {
      // Log an error message and re-throw the error for the calling code to handle
      console.error('API Error:', error);
      throw error; // Re-throw the error for the calling code to handle
    }
  }

  // Function to retrieve Bins ID list using a client ID
  public async getOperatorClientsById(operator_id: string) {

    // Initialize array to store the list of Bins IDs
    this.operatorClientList = [];

    // Define the base URL for the Lambda function to get Bins using client ID
    const baseUrl = environment.api.stage + environment.api.route.getOperatorClientsById;

    // Construct the complete URL with the client_id parameter
    const url = `${baseUrl}&operator_id=${operator_id}`;

    try {
      // Make an HTTP GET request to the Lambda function
      // const response$ = this.http.get(url)
      const response$ = this.http.get<any[]>(url);
      const response = await lastValueFrom(response$);

      this.operatorClientList = response

      // Return the original response (Observable) to the calling code
      return response$;

    } catch (error) {
      // Log an error message and re-throw the error for the calling code to handle
      console.error('API Error:', error);
      throw error; // Re-throw the error for the calling code to handle
    }
  }

  // Function to retrieve client details by client IDs
  // public async getClientsByMultipleIds(clientIds: string[]) {

  //   console.log(clientIds);

  //   // Define the base URL for your PHP endpoint
  //   const baseUrl = environment.api.stage + environment.api.route.getClientsByMultipleIds; // getClientsByIds

  //   // Converte o array de IDs para uma string JSON
  //   const clientIdsJson = JSON.stringify(clientIds);

  //   // Cria a URL com o parâmetro client_id
  //   const url = `${baseUrl}?client_id=${encodeURIComponent(clientIdsJson)}`;

  //   console.log(url)

  //   // Faz a chamada GET
  //   return this.http.get(url);
  

  //   // Construct the complete URL with the client_id parameter
  //   // Joining the array elements with a comma or any other separator that your API expects
  //   // const idsParam = clientIds.join(',');
  //   // const url = `${baseUrl}&client_ids=${idsParam}`;

  //   console.log(url);

  //   try {
  //     // Make an HTTP GET request to the Lambda function
  //     const response$ = this.http.get<any[]>(url);
  //     const response = await lastValueFrom(response$);

  //     this.operatorClientList = response;

  //     // Return the original response to the calling code
  //     return response;
  //   } catch (error) {
  //     // Log an error message and re-throw the error for the calling code to handle
  //     console.error('API Error:', error);
  //     throw error;
  //   } 

  //   // try {
  //   //   // Make an HTTP POST request to the PHP endpoint with the clientIds array in the body
  //   //   const response$ = this.http.post<any[]>(baseUrl, { clientIds });
  //   //   const response = await lastValueFrom(response$);

  //   //   this.operatorClientList = response;

  //   //   console.log(response)
  //   //   console.log(response$)


  //   //   // Return the original response (Observable) to the calling code if needed
  //   //   // return response$;
  //   //   return response;


  //   // } catch (error) {
  //   //   // Log an error message and re-throw the error for the calling code to handle
  //   //   console.error('API Error:', error);
  //   //   throw error; // Re-throw the error for the calling code to handle
  //   // }
  // }

  // getClientsByMultipleIds(clientIds: string): Observable<any> {

  //   const apiUrl = environment.api.stage + environment.api.route.getClientsByMultipleIds;
  //   // Converte o array de clientIds em uma string separada por vírgulas
  //   // const clientIdsString = clientIds.join(',');

  //   // Formata a URL final corretamente com um único ponto de interrogação
  //   const url = `${apiUrl}&client_ids=${encodeURIComponent(clientIds)}`;

  //   console.log(url)

  //   // Realiza a requisição HTTP GET com tratamento de erros
  //   return this.http.get<any>(url).pipe(
  //     catchError(error)
  //   );
  // }

//   async getClientsByMultipleIds(clientIds: string): Promise<Observable<any>> {
//     console.log(clientIds); // Log para verificar a URL

//     const baseUrl = environment.api.stage + environment.api.route.getClientsByMultipleIds;

//     const url = `${baseUrl}&client_ids=${clientIds}`;


//     // const apiUrl = `${environment.api.stage}${environment.api.route.getClientsByMultipleIds}`;
    
//     // Formata a URL final corretamente com um único ponto de interrogação
//     // const url = `${apiUrl}?client_ids=${encodeURIComponent(clientIds)}`;
    
//     console.log(url); // Log para verificar a URL

//     try {
//       // Make an HTTP GET request to the Lambda function
//       // const response$ = this.http.get(url)
//       const response$ = this.http.get<any>(url);
//       const response = await lastValueFrom(response$);

//       console.log(response)
//       console.log(response$)


//       // Return the original response (Observable) to the calling code
//       // return response$;
//       return response;


//     } catch (error) {
//       // Log an error message and re-throw the error for the calling code to handle
//       console.error('API Error:', error);
//       throw error; // Re-throw the error for the calling code to handle
//     }

//     // Realiza a requisição HTTP GET com tratamento de erros
//     return this.http.get<any>(url).pipe(
//         catchError(error => {
//             console.error('Error in GET request:', error);
//             return throwError(error);
//         })
//     );
// }

// public async getClientsByMultipleIds(clientIds: string[]) {
//   // Initialize array to store the list of operator clients
//   this.operatorClientList = [];

//   // Define the base URL for the API endpoint
//   const baseUrl = environment.api.stage + environment.api.route.getClientsByMultipleIds;

//   // Convert the array of operator IDs into a query string format
//   const queryString = clientIds.map(id => `client_id=${encodeURIComponent(id)}`).join('&');
  
//   // Construct the complete URL with the query string
//   const url = `${baseUrl}?${queryString}`;

//   try {
//       // Make an HTTP GET request to the API
//       const response$ = this.http.get<any[]>(url);
//       const response = await lastValueFrom(response$);

//       const responseRIGHT = response;

//       // Return the response
//       return response;

//   } catch (error) {
//       // Log an error message and re-throw the error
//       console.error('API Error:', error);
//       throw error;
//   }
// }

public async getClientsByMultipleIds(clientIds: string[]): Promise<any[]> {
  // Define the base URL for the API endpoint

  console.log(clientIds)
  const baseUrl = environment.api.stage + environment.api.route.getClientsByMultipleIds;

  // Convert the array of client IDs into a query string format
  const queryString = clientIds.map(id => `client_id=${encodeURIComponent(id)}`).join('&');
  
  // Construct the complete URL with the query string
  const url = `${baseUrl}?${queryString}`;
  console.log('Fetching URL:', url);

  const url2 = 'https://j8xvd1iyxc.execute-api.ca-central-1.amazonaws.com/IFM2/getClientsByMultipleIds?action[]=CL4ccb363d4cd095d852&action[]=CL4ccb363d4cd095d853';

  try {
    // Make an HTTP GET request to the API
    const response$ = this.http.get<any[]>(url2);
    const response = await lastValueFrom(response$);
    console.log('API Response:', response);

    // Return the response
    return response;
  } catch (error) {
    // Log an error message and re-throw the error
    console.error('API Error:', error);
    throw error;
  }
}







  // Create a operator
  createOperatorLambda(){
    // Define the HTTP headers with content type
    const headers = new HttpHeaders({
     'Content-Type':  'application/json' // Adjust content type as needed
   });
   // Will call the lambda function in createOperator url whit the passed data then return a response
   return this.http.post(environment.api.stage + environment.api.route.createOperator, {
     // Doubled quotes things are used into lambda function as data and used for the SQL's calls that those functions does
       "operator_name": this.operator_data.operator_name,
       "legal_name": this.operator_data.legal_name,
       "phone_number": this.operator_data.phone_number,
       "address": this.operator_data.address,
       "logo": this.operator_data.logo,
       "email": this.operator_data.email,
       "created": this.operator_data.created,
       "modified": this.operator_data.modified
     }, { headers: headers }
   );
 }

    // Get all the operator
  getOperatorsLambda(){
    // Get the url for the lambda function getBinsModels
    const url = environment.api.stage + environment.api.route.getOperators;
    // Will call the lambda function in getBinModel url then return a response
    return this.http.get(url).pipe(
      map((response) => {
        // return the response data
        return response;
      }),
      catchError((error) => {
        console.error('API Error:', error);
        throw error(error); // Re-throw the error for the calling code to handle
      })
    );
  }

  // Update the operator
  updateOperatorLambda(){
    // Define the HTTP headers with content type
    const headers = new HttpHeaders({
      'Content-Type':  'application/json' // Adjust content type as needed
    });
    // Call lambda fucntion whit the url of updateBin  and return the response
    return this.http.post(environment.api.stage + environment.api.route.updateOperator, {
      // Doubled quotes things are used into lambda function as data and used for the SQL's calls that those functions does
        "operator_id": this.operator_data.operator_id,
        "operator_name": this.operator_data.operator_name,
        "legal_name": this.operator_data.legal_name,
        "phone_number": this.operator_data.phone_number,
        "address": this.operator_data.address,
        "logo": this.operator_data.logo,
        "email": this.operator_data.email,
        "active": this.operator_data.active,
        "modified": this.operator_data.modified
      }, {headers : headers}
    );
  }

  // Delete operator, call to the API
  deleteOperatorLambda() {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    // Call lambda fucntion whit the url of deleteOperator  and return the response
    return this.http.post(environment.api.stage + environment.api.route.deleteOperator, {
        // Data to send to the API
        "operator_id": this.operator_data.operator_id
      }, {headers : headers}
    );
  }
}
