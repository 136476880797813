import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../environments/environment';
import { map, catchError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class JocelynTestService {
  public response: any;

  constructor(private http: HttpClient) { }

  sendJsonLambda(){
    // Initiate url whit the url of lambda fonction getBins
    const url = environment.api.stage + environment.api.route.sendJsonToAPI;
    // Call the lambda fonction whit the url
    return this.http.get(url).pipe(
      map((response) => {
        return response;
      }),
      catchError((error) => {
        //console.error('API Error : ' + error);
        throw error;
      })
    );
  }

  async sendJson(){
    return new Promise<any[]>((resolve) => {
      // Call the function that call the lambda function
      this.sendJsonLambda().subscribe((response) => {
        // Put the array of Bins returned by the lambda function into an tmp array
        this.response = response;

        resolve(this.response);
      });
    });
  }
}
