<app-system-message></app-system-message>

<div [ngClass]="theme.getThemeClass()" class="container-fluid">
  <!-- Search input component, shown when mainContent is 'clients' -->
  <header id="search-component" class="input-group">
    <input #searchBox type="search"  id="search-box" [(ngModel)]="userFilter" (input)="search()" class="form-control rounded"
      placeholder="{{ 'userlistSearchInputPlaceHolder' | translate }}" aria-label="Search" aria-describedby="search-addon" />
  </header>

  <header [ngClass]="theme.getThemeClass()" class="table-header">
    <span class="left">{{ "clientList" | translate }}</span>
    <span class="right">
      <button [ngClass]="theme.getThemeClass()" class="btn btn-dropdown" id="buttonDropdown" routerLink="/client-create">
        {{ "newClient" | translate }}
      </button>
    </span>
  </header>

  <!-- Table container for the user list, shown when mainContent is 'clients' -->
  <section [ngClass]="theme.getThemeClass()" class="table-container width100">
    <article  class="table-responsive minMarginBotton">
      <table  class="table table-hover">
        <thead >
          <tr>
            <th [ngClass]="theme.getThemeClass()" scope="col">
              {{ 'name' | translate }}
            </th>
            <th [ngClass]="theme.getThemeClass()" class="hide-small-screen" scope="col">
              {{ 'legalName' | translate }}
            </th>
            <th [ngClass]="theme.getThemeClass()" scope="col">
                {{ 'email' | translate }}
              </th>
            <th [ngClass]="theme.getThemeClass()" class="hide-small-screen" scope="col">
              {{ 'phoneNumber' | translate }}
            </th>
            <th [ngClass]="theme.getThemeClass()" class="hide-small-screen" scope="col">
              {{ 'address' | translate }}
            </th>
            <th [ngClass]="theme.getThemeClass()" scope="col">
              {{ 'iotTabHeaderResources' | translate }}
          </th>
          </tr>
        </thead>
        <ng-container  *ngFor="let client of this.clientArray">
          <tbody>
            <tr class="verticalAlignMiddle">
              <td [ngClass]="theme.getThemeClass()"><a [routerLink]="['/client-edit', client.client_id]" href="javascript:void(0)">{{client.client_name}}</a></td>
              <td [ngClass]="theme.getThemeClass()" class="hide-small-screen">{{client.legal_name}}</td>
              <td [ngClass]="theme.getThemeClass()">{{client.email}}</td>
              <td [ngClass]="theme.getThemeClass()" class="hide-small-screen">{{this.formatNumber(client.phone_number)}}</td>
              <td [ngClass]="theme.getThemeClass()" class="hide-small-screen">{{client.address}}</td>
              <td [ngClass]="theme.getThemeClass()">
                <a [routerLink]="['/client-edit', client.client_id]"><img class="iconUpdate" src="./assets/update-pencil.png" alt="icon of update" height="30" width="30"></a>
            </td>
            </tr>
          </tbody>
        </ng-container>
      </table>
    </article>
  </section>
</div>






