import { Component } from '@angular/core';

@Component({
  selector: 'app-dispatch-work-order',
  templateUrl: './dispatch-work-order.component.html',
  styleUrl: './dispatch-work-order.component.css'
})
export class DispatchWorkOrderComponent {

}
