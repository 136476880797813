import { Component, OnInit } from '@angular/core';
import { WorkOrderDashboardModalService } from '../service/work-order-dashboard-modal.service';
import { WorkOrderStatus } from '../constants/work-order-status';
import { ThemeService } from '../service/theme.service';
import { TranslateService } from '@ngx-translate/core';
import { firstValueFrom } from 'rxjs';
import { IotService } from '../service/iot.service';

@Component({
  selector: 'app-work-order-dashboard-modal',
  templateUrl: './work-order-dashboard-modal.component.html',
  styleUrls: ['./work-order-dashboard-modal.component.css', '../../global-elements.css']
})
export class WorkOrderDashboardModalComponent implements OnInit{
  public selectedStatus: string = '';
  public selectedDate: string = this.workOrderModalService.deadlineDate;
  public selectedDescription: string = this.workOrderModalService.description;
  public selectedComments: string = this.workOrderModalService.comments;

  public statusArray = WorkOrderStatus;

  constructor(public workOrderModalService: WorkOrderDashboardModalService,
    public theme: ThemeService,
    private translate: TranslateService,
    private iotService: IotService){

  }

  async ngOnInit() {
    this.selectedStatus = await firstValueFrom(this.translate.get(this.workOrderModalService.status));
  }

  convertTimestamp(timestamp: string){
    return this.iotService.convertTimestamp(Number(timestamp));
  }
}
