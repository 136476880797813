import { Component } from '@angular/core';

@Component({
  selector: 'operator-admin-dashboard',
  templateUrl: './operator-admin-dashboard.component.html',
  styleUrl: './operator-admin-dashboard.component.css'
})
export class OperatorAdminDashboardComponent {

}
