<div>
    <ngx-charts-line-chart
      [view]="charts.viewLineChartAdmin"
      [scheme]="charts.colorSchemeLineChart"
      [legend]="false"
      [legendPosition]="charts.legendPositionLineChartAdmin"
      [showXAxisLabel]="charts.showXAxisLabelLineChartAdmin"
      [showYAxisLabel]="charts.showYAxisLabelLineChartAdmin"
      [xAxis]="charts.showXAxisLineChartAdmin"
      [yAxis]="charts.showYAxisLineChartAdmin"
      [xAxisLabel]="charts.xAxisLabelBatLineChartAdmin"
      [yAxisLabel]="charts.yAxisLabelVccLineChartAdmin"
      [timeline]="true"
      [results]="charts.singleLineChartVcc"
      (select)="charts.onSelectLineChart($event)"
      (activate)="charts.onActivateLineChart($event)"
      (deactivate)="charts.onDeactivateLineChart($event)"
      [xAxisTickFormatting]="charts.formatTimestamp"
      
      [xAxisTickFormatting]="charts.formatTimestamp"
      
      [yScaleMin]="charts.yScaleMinVcc"
      [yScaleMax]="charts.yScaleMaxVcc"
      >
     </ngx-charts-line-chart>
    <div class="line-chart-container">
      <h6>{{charts.xAxisLabelLineChartAdmin}}</h6>
      </div>
   </div> 
