import { Injectable } from '@angular/core';
import { environment } from '../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
interface Client {
  active: string;               // e.g., "1" or "0"
  address: string;              // e.g., "25 rue chap"
  auto_collect: string;         // e.g., "1"
  checked: boolean;             // e.g., false
  client_id: string;            // e.g., "CL4ccb363d4cd095d853"
  client_logo: string | null;   // e.g., null or a string
  client_name: string;          // e.g., "jocelyn client"
  created: Date | null;         // e.g., a date or null
  email: string;                // e.g., "joccorm@gmail.com"
  legal_name: string;           // e.g., "jocelyn"
  modified: Date | null;        // e.g., a date or null
  mws: string;                  // e.g., "0"
  phone_number: string;         // e.g., "4388375731"
}

interface Device {
  thing_name: string;
}

@Injectable({
  providedIn: 'root'
})
export class DispatchService {
  associatedClients: Client[] = []; //save associated client created from dispatch network component
  deviceNameList: string[] = []
  constructor( private http: HttpClient

  ) {
   
   }

  //To associate selected client to the dispatch
  associateClient(clients:Client[]){
    clients.forEach((client)=>{
      let alreadyPresent = this.associatedClients.find((c)=>{
        return c.client_id === client.client_id
      })
      if(!alreadyPresent){
       this.associatedClients.push(client)
      }
    })
  }
  //To dissociate selected client to the dispatch
  dissociateClient(clients:Client[]){
    clients.forEach((client)=>{
      if(client.checked){
        this.associatedClients = this.associatedClients.filter(c => c !== client);
      }
    })
  }

  async getAllThings(){
    const url = environment.api.stage + environment.api.route.getAllDevice;

 // Define the HTTP headers with content type
    const headers = new HttpHeaders({
      'Content-Type':  'application/json' // Adjust content type as needed
    }); 
    return this.http.get<Device[]>(url, { headers })
  }
}
