import { Component } from '@angular/core';
import { ChartService } from '../service/chart.service';
import { ThemeService } from '../service/theme.service';


@Component({
  selector: 'line-chart-technical',
  templateUrl: './line-chart-technical.component.html',
  styleUrls: ['./line-chart-technical.component.css',  '../../charts.css']
})
export class LineChartTechnicalComponent {

  constructor(public charts: ChartService,
    public theme: ThemeService){
       //Declare chart data as a constant
  const single = charts.singleLineChart
  //Assign Data to the Graph object
  Object.assign(this, { single });
 }
}
