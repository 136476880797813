import { Component } from '@angular/core';
import { ChartService } from '../service/chart.service';
import { ThemeService } from '../service/theme.service';

@Component({
  selector: 'line-chart-temp',
  templateUrl: './line-chart-temp.component.html',
  styleUrls: ['./line-chart-temp.component.css' ,'../../charts.css']
})
export class LineChartTempComponent {

  constructor(public charts: ChartService,
    public theme: ThemeService){
       //Declare chart data as a constant
  const single = charts.singleLineChart
  //Assign Data to the Graph object
  Object.assign(this, { single });
 }
 customTooltipTemplate = `
    <ng-template #tooltipTemplate let-model="model">
      <div class="custom-tooltip">
        <p><strong>{{ model.name }}</strong></p>
        <p>{{ model.value }}</p>
      </div>
    </ng-template>
  `;
}
