import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../environments/environment';
@Injectable({
  providedIn: 'root'
})
export class WorkOrderDashboardModalService {
  public showStatusModal: boolean = false;
  public showDeadlineDateModal: boolean = false;
  public showDescriptionModal: boolean = false;
  public showCommentModal: boolean = false;
  public showModal: boolean = false;

  public work_order_id: string = '';
  private oldStatus : string = '';
  public status: string = '';
  private oldDeadline: string = '';
  public deadlineDate: string = '';
  private oldDescription: string = '';
  public description: string = '';
  public comments: string = '';
  private date: string = '';
  private isStatusChange: boolean = false;
  private isDeadlineChange: boolean = false;
  private isCommentChange: boolean = false;
  private isDescriptionchange: boolean = false;

  public userSub: string = '';

  public applyingChanges: boolean = false;

  // Work order arrays
  public workOrderArray: any[] = [];
  public originalWorkOrderArray: any[] = [];

  constructor(private http: HttpClient) { }

  // Function called from the work order list to open the status change modal
  changeStatus(work_order_id: string, status: string){
    this.work_order_id = work_order_id;
    this.status = status;
    this.oldStatus = status;
    this.showModal = true;
    this.showStatusModal = true;
    this.isStatusChange = true;
  }

  // Function called from the work order list to open the deadline date modal
  changeDeadline(work_order_id: string, deadlineDate: string){
    this.work_order_id = work_order_id;
    this.deadlineDate = deadlineDate;
    this.oldDeadline = deadlineDate;
    this.showModal = true;
    this.showDeadlineDateModal = true;
    this.isDeadlineChange = true;
  }

  // function called by the work order list to open the description modal
  changeDescription(work_order_id: string, description: string){
    this.work_order_id = work_order_id;
    this.description = description;
    this.oldDescription = description;
    this.showModal = true;
    this.showDescriptionModal = true;
    this.isDescriptionchange = true;
  }

  // function called by the work order list to open the comments modal
  addComments(work_order_id: string, comments: string, userSub: string){
    this.work_order_id = work_order_id;
    this.comments = comments;
    this.userSub = userSub;
    this.showModal = true;
    this.showCommentModal = true;
    this.isCommentChange = true;
  }

  // Function called when user change the status of the work order
  statusChange(status: string){
    this.status = status;
  }

  // Function called when user chaneg the date on the modal
  deadlineDateChange(selectedDate: string){
    const convertedDate = new Date(selectedDate);
    const utcTimeStamp = convertedDate.getTime();
    this.deadlineDate = utcTimeStamp.toString();
  }

  // Function called when user change the description on modal
  descriptionChange(selectedDescription: string){
    this.description = selectedDescription;
  }

  // Function called when user change the comments on the modal
  commentChange(comments: string){
    this.comments = comments;
  }

  // Function called when user submit the change made
  submitChange(){
    // Get today's date for saving in DB
    const today: Date = new Date();
    this.date = today.getTime().toString();
    this.applyingChanges = true;

    if(this.isCommentChange && this.comments === ''){
      this.comments = '...';
    }

    if(this.isDescriptionchange && this.description === ''){
      this.description = '...';
    }

    if(this.work_order_id !== ''){
      // Call the function that made the call to lambda function and wait for is response
      this.updateWorkOrderLambda().subscribe((response: any) => {
        setTimeout(() => {
          console.log(this.work_order_id);
          this.setNewValues();
          this.close();
          //window.location.reload();
        }, 500);
      });
    }
  }

  // Function used when work order update is done to change the vlue on work order list
  setNewValues(){
    let selectedWorkOrder = this.workOrderArray.find((workOrder) => workOrder.work_order_id === this.work_order_id);
    console.log('TOTO');
    // Switch between different bool variable to know witch part of the work order the user have change
    switch(true){
      case this.isStatusChange:
        selectedWorkOrder.status = this.status;
        break;

      case this.isDeadlineChange:
        selectedWorkOrder.deadlineDate = this.deadlineDate;
        break;

      case this.isDescriptionchange:
        selectedWorkOrder.description = this.description;
        break;

      case this.isCommentChange:
        selectedWorkOrder.comment = this.comments;
        break;
    }
  }

  updateWorkOrderLambda(){
    // Define the HTTP headers with content type
    const headers = new HttpHeaders({
      'Content-Type':  'application/json' // Adjust content type as needed
    });
    // Will call the lambda function in updateBinModel url whit the passed data then return a response
    return this.http.post(environment.api.stage + environment.api.route.updateWorkOrder, {
      // Doubled coats things are used into lambda function as data and used for the SQL's calls that those functions does
        "work_order_id": this.work_order_id,
        "user_sub": this.userSub,
        "status": this.status,
        "deadlineDate": this.deadlineDate,
        "description": this.description,
        "comment": this.comments,
        "date": this.date,
      }, { headers: headers }
    );
  }

  // Function that close all from modal
  close(){
    // Set all variable for showing modal at false to hide the modal
    this.showModal = false;
    this.showDeadlineDateModal = false;
    this.showDescriptionModal = false;
    this.showStatusModal = false;
    this.showCommentModal =false;

    // Set all his variables to default to avoid bad entry
    this.work_order_id = '';
    this.status = '';
    this.description = '';
    this.comments = '';

    // Set to false all other variables
    this.isCommentChange = false;
    this.isDeadlineChange = false;
    this.isDeadlineChange = false;
    this.isStatusChange = false;
  }
}
