<app-system-message style="margin-left: 20vw;"></app-system-message>

<!-- <script src="https://maps.googleapis.com/maps/api/js?key=AIzaSyCyzMRxB8Re27NYcuI8KDFCEucI0GwHLHA&libraries=drawing&libraries=places"></script> -->

<!-- Main container for the web page -->
<section [ngClass]="theme.getThemeClass()" class="container-fluid adminMapComponentDiv">
  <!-- Container for columns and content related to devices -->
  <article class="rowContentDevices" [ngClass]="{'novoLayout': shouldApplyNewLayout(), 'rowContentDevices': !shouldApplyNewLayout()}">
    <div  class="left-column" [ngClass]="{'novoLayoutLeft': shouldApplyNewLayout(), 'left-column': !shouldApplyNewLayout()}">
      <div [ngClass]="theme.getThemeClass()" class="zoneList" *ngIf="!showCreateZoneModal && !this.showUpdateZoneModal">
        <div [ngClass]="theme.getThemeClass()" class="container-fluid" id="zoneListContainer">
          <!-- Search input component -->
          <nav id="search-component" class="input-group zindex">
            <input #searchBox type="search"  id="search-box" [(ngModel)]="zoneFilter" (input)="search()" class="form-control"
              placeholder="{{ 'zoneNameFilter' | translate }}" aria-label="Search" aria-describedby="search-addon" />
          </nav>
          <!-- Table header for the user list -->
          <section [ngClass]="theme.getThemeClass()" class="table-header">
            <span class="left">{{ 'zoneList' | translate }}
            </span>
            <article [ngClass]="theme.getThemeClass()" (click)="dropdownAction()" class="dropdownDiv">
              <button [ngClass]="theme.getThemeClass()" class="btn btn-dropdown" id="buttonDropdown">
                {{ "actions" | translate }}
              </button>
              <div [ngClass]="theme.getThemeClass()" *ngIf="isDropdownOpen" class="dropdown-content" id="dropdownContent">
                <a (click)="createNewZone()" class="btnStyle" href="javascript:void(0)">
                  &#x2b; {{ 'createNewZone' | translate }}
                </a>
                <a *ngIf="!this.showAllZones" class="btnStyle" (click)="showZones()" href="javascript:void(0)">
                  &#x2b; {{ 'showAllZones' | translate }}
                </a>
                <a *ngIf="this.showAllZones" class="btnStyle" (click)="hideZones()" href="javascript:void(0)">
                  &#x2b; {{ 'hideAllZones' | translate }}
                </a>
              </div>
            </article>
          </section>
          <!-- Table container for the Zone list -->
          <section [ngClass]="theme.getThemeClass()" class="table-container">
            <article  class="table-responsive minMarginBotton">
              <table  class="table table-hover">
                <thead>
                  <tr>
                    <th [ngClass]="theme.getThemeClass()" scope="col">
                      {{ 'entities' | translate }}
                    </th>
                    <th [ngClass]="theme.getThemeClass()" scope="col">
                      {{ 'type' | translate }}
                    </th>
                    <th [ngClass]="theme.getThemeClass()" scope="col">
                        {{ 'name' | translate }}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <ng-container *ngFor="let zone of this.zonesArray">
                      <tr class="vertical-align"  (click)="update(zone)">
                        <!--   NAME    -->
                        <td [ngClass]="theme.getThemeClass()">
                          <a  [routerLink]="" href="javascript:void(0)">
                            {{ zone.ownerName }}
                          </a>
                        </td>
                        <!--   TYPE    -->
                        <td [ngClass]="theme.getThemeClass()">
                          <a [routerLink]="" href="javascript:void(0)">
                            {{ zone.entityType | translate }}
                          </a>
                        </td>
                        <!--   LEGAL NAME    -->
                        <td [ngClass]="theme.getThemeClass()">
                          <a [routerLink]="" href="javascript:void(0)">
                            {{ zone.zoneName }}
                          </a>
                        </td>
                      </tr>
                  </ng-container>
                </tbody>
              </table>
            </article>
          </section>
        </div>
      </div>

<!---------------------------------------------------------------->
      <!-- CREATE ZONE MODAL -->
      <div [ngClass]="theme.getThemeClass()" *ngIf="showCreateZoneModal" class="modal-container">
        <!-- MODAL CONTENT -->
        <div class="modalTitle">
          <h4 class="updateLabel"> {{ 'createZone' | translate }} </h4>
        </div>
        <form class="formDisplay">
          <div class="ladoLado">
            <div class="largura">
              <!-- ENTITY NAME SELECT INPUT -->
              <label class="label-spacer" for="entityName">{{ 'selectEntity' | translate }} <span class="requiredStar">*</span></label>
              <div class="input-group mt-0 mb-3">
                  <span class="input-group-text" id="basic-addon1"><i class="fas fa-briefcase"></i></span>
                  <select 
                      [(ngModel)]="this.entitySelected"
                      class="form-control"
                      name="entity_id"
                      aria-label="Entity Name"
                      aria-describedby="basic-addon1"
                      [ngClass]="{'error-border': this.validationService.validationErrorArray[0] === 'clientInputError'}"
                      >
                      <option value="" disabled selected>{{ 'selectEntity' | translate }}</option>
                      <option *ngFor="let entity of this.entitiesList" [value]="entity.entity_id">{{ entity.entity_name }}</option>
                  </select>
              </div>
            </div>
            <div class="largura secondInput">
              <!-- ZONE NAME -->
              <label class="label-spacer" for="username">{{ 'zoneName' | translate }} <span class="requiredStar">*</span></label>
              <div class="input-group mt-0 mb-3 firstInput">
                  <span class="input-group-text" id="basic-addon1"><i class="fas fa-chart-area"></i></span>
                  <input [(ngModel)]="this.zoneName"   type="text"
                  class="form-control" name="username"
                  placeholder="{{ 'zoneName' | translate }}"
                  aria-label="Username" aria-describedby="basic-addon1"
                  maxlength="40"
                  [disabled]="!entitySelected"
                  [class.disabled]="!entitySelected"
                  [ngClass]="{'error-border': this.validationService.validationErrorArray[0] === 'usernameInvalid'}"
                  >
              </div>
            </div>
          </div>

          <!-- ZONE COLOR -->
          <div class="centralizado">
            <label class="label-spacer" for="username">{{ 'selectZoneColor' | translate }}</label>
            <div class="input-group mt-0 mb-1">
              <span class="input-group-text" id="basic-addon2"><i class="fas fa-paint-brush"></i></span>
              <input type="color"
                id="corInput"
                name="selectedColor"
                [(ngModel)]="selectedColor"
                (input)="onColorSelected($event)"
                class="form-control standardHeight"
                aria-label="Selected Color"
                aria-describedby="basic-addon2"
                [disabled]="!entitySelected"
                [class.disabled]="!entitySelected"
                [ngModelOptions]="{ standalone: true }"
              >
            </div>
          </div>

          <!-- CANCEL/SAVE BUTTONS -->
          <nav class="btnDisplay mt-3">
            <button [ngClass]="theme.getThemeClass()" class="btn btnClose" (click)="closeModal()">{{ 'cancel' | translate }}</button>
            <button [ngClass]="theme.getThemeClass()" *ngIf="!this.drawingOn" class="btn btnSave" (click)="startDrawing()">{{ 'startDrawing' | translate }}</button>
            <button [disabled]="this.systemMessage.buttonBlocked" [ngClass]="theme.getThemeClass()" *ngIf="this.drawingOn" class="btn btnSave" (click)="saveZone(); disableButton()">{{ 'save' | translate }}</button>
          </nav>
        </form>
      </div>

<!----------------------------------------------------------------------------------------------->
      <!-- UPDATE ZONE MODAL -->
      <div [ngClass]="theme.getThemeClass()" *ngIf="showUpdateZoneModal" class="modal-container ">
        <!-- MODAL CONTENT -->
        <div class="modalTitle">
          <h4 class="updateLabel"> {{ 'updateZone' | translate }} </h4>
        </div>
        <form class="formDisplay">
          <div class="ladoLado">
            <div class="largura">
              <!-- ENTITY NAME -->
              <label class="label-spacer" for="client_name">{{ 'entityNameLabel' | translate }} </label>
              <div class="input-group mt-0 mb-3 firstInput">
                  <span class="input-group-text" id="basic-addon1"><i class="fas fa-briefcase"></i></span>
                  <input [(ngModel)]="this.zoneService.zoneData.owner_name" type="text"
                  class="form-control no-edit" name="client_name" value="this.zoneService.zoneData.owner_name"
                  placeholder=""
                  aria-label="Client Name" aria-describedby="basic-addon1" readonly
                  >
              </div>
            </div>

            <div class="largura secondInput">
              <!-- ZONE NAME -->
              <label class="label-spacer" for="zone_name">{{ 'zoneName' | translate }} <span class="requiredStar">*</span></label>
              <div class="input-group mt-0 mb-3 firstInput">
                  <span class="input-group-text" id="basic-addon1"><i class="fas fa-chart-area"></i></span>
                  <input [(ngModel)]="this.zoneService.zoneData.zone_name" type="text"
                  class="form-control" name="zone_name" value="this.zoneService.zoneData.zone_name"
                  placeholder="{{ 'zoneName' | translate }}"
                  aria-label="Zone Name" aria-describedby="basic-addon1"
                  [ngClass]="{'error-border': this.validationService.validationErrorArray[0] === 'usernameInvalid'}"
                  >
              </div>
            </div>
          </div>

          <!-- ZONE COLOR -->
          <div class="centralizado">
            <label class="label-spacer" for="username">{{ 'zoneColor' | translate }}</label>
            <div class="input-group mt-0 mb-1">
              <span class="input-group-text" id="basic-addon2"><i class="fas fa-paint-brush"></i></span>
              <input type="color"
                id="corInput"
                [(ngModel)]="selectedColor"
                (input)="onColorSelected($event)"
                class="form-control standardHeight"
                aria-label="Selected Color"
                aria-describedby="basic-addon2"
                [value]="this.zoneService.zoneData.zone_color"
                [ngModelOptions]="{ standalone: true }"
              >
            </div>
          </div>

          <!-- ACTIVE/DEACTIVE ZONE -->
          <nav class="form-group sliderButtonDisplay">
            <label class="switch-label">
              <ng-container *ngIf="enable; else deactivateText">{{ 'activate' | translate }}</ng-container>
              <ng-template #deactivateText>{{ 'deactivate' | translate }}</ng-template>
            </label>
            <label class="switch">
              <input type="checkbox" [value]="enable" (change)="toggleZone()" [checked]="enable">
              <span class="slider round"></span>
            </label>
          </nav>

          <!-- CANCEL/SAVE BUTTONS -->
          <nav class="btnDisplay">
            <button [ngClass]="theme.getThemeClass()" class="btn btnClose" (click)="closeModal()">{{ 'cancel' | translate }}</button>
            <button [ngClass]="theme.getThemeClass()" class="btn btnSave" (click)="saveUpdateZone()">{{ 'save' | translate }}</button>
          </nav>
          <nav class="btnDisplay">
            <button [ngClass]="theme.getThemeClass()" class="btn btnClose" (click)="deleteZone()">{{ 'deleteZone' | translate }}</button>
          </nav>
        </form>
      </div>
    </div>

    <!-- Container for the Google Map -->
    <div id="columMap" class="columMap" [ngClass]="{'columMap2': shouldApplyNewLayout(), 'columMap': !shouldApplyNewLayout()}">
      <!-- Map container element -->
      <div #mapContainer id="mainMap" class="mainMap"></div>
    </div>

    <!-- Container for device modal component -->
    <section [ngClass]="theme.getThemeClass()" class="deviceModalContainer">
      <!-- Device modal component with conditional rendering -->
      <app-device-modal [ngClass]="{'custom-modal': isCustomComponent}"
        *ngIf="this.modal.showDeviceModal || this.modal.showMultipleDevicesModal"
        class="appDeviceModal container-fluid">
      </app-device-modal>
    </section>
  </article>
</section>





