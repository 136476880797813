import { Component, OnInit, HostListener, ChangeDetectorRef } from '@angular/core';
import { ThemeService } from '../service/theme.service';
import { DistributorsService } from '../service/distributors.service';
import { Inject, forwardRef } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CognitoService } from '../service/cognito.service';
import { RoleService } from '../service/role.service';
import { Router } from '@angular/router';
import { environment } from '../environments/environment';
import { FilterService } from '../service/filter.service';
import { BinsService } from '../service/bins.service';
import { SystemMessageService } from '../service/system-message.service';
import { LocalStorageService } from '../local-storage.service';

@Component({
  selector: 'app-distributor-bins-model-dashboard',
  templateUrl: './distributor-bins-model-dashboard.component.html',
  styleUrls: ['./distributor-bins-model-dashboard.component.css', './distributor-tiles.css', '../../global-elements.css']
})
export class DistributorBinsModelDashboardComponent implements OnInit{

  public distributorBinArray: any[] = [];
  public uniqueBinModelArray: any[] = [];
  private originalUniqueBinArray: any[] = [];
  public isSmallScreenWidth: boolean = this.getScreenWidth() <= 859;
  public currentTheme = '';
  public userFilter: string = '';
  public filterBy: string = '';
  public isSortBy: boolean = false;
  public sortBy: string = '';
  public ascDesc: number = 0;



  constructor(public theme: ThemeService,
              public distributors: DistributorsService,
              @Inject(forwardRef(() => TranslateService)) public translate: TranslateService,
              private cognitoService: CognitoService,
              private roleService: RoleService,
              private route: Router,
              private cdr: ChangeDetectorRef,
              public filter: FilterService,
              public bin: BinsService,
              public systemMessageService: SystemMessageService
              ){

  }

  async ngOnInit() {
    // Call cognito to confirm valid user and get the user type of the current user
    this.cognitoService.confirmValidUser();
    await this.cognitoService.getUserType();

    // Call role service to get the current user roles
    await this.roleService.getRoles();

    // Retrieve the value of 'from' from sessionStorage
    const fromComponent = sessionStorage.getItem('from');

    // Check if 'fromComponent' has a value
    if(fromComponent){
      // Switch statement based on the value of 'fromComponent'
      switch(fromComponent){
        // If 'fromComponent' is 'bin-model-update'
        case 'bin-model-update':
          // Call a method from systemMessageService to select a ribbon with success message
          this.systemMessageService.selectRibbon('success', 'binModelCreateViewAlertSuccessUpdate');
        break;

        case 'bin-model-update':
          // Call a method from systemMessageService to select a ribbon with success message
          this.systemMessageService.selectRibbon('success', 'bin-model-create');
        break;


        // Default case
        default:
        break;

      }
      // Remove 'from' from sessionStorage
      sessionStorage.removeItem('from');
    }

    this.cognitoService.getCurrentRole(
      [environment.users.role.distributor],
      [environment.users.supplier]
    );

    // Call the function to get all bin model association of the distributor
    await this.getBinModelAssociationByDistributor();

    // Call a function to create a new array with only single bin model
    this.createArrayOfBinModel();

    // Set an original array used with the filter when we reset the array
    this.originalUniqueBinArray = this.uniqueBinModelArray;
    console.log(this.uniqueBinModelArray[0])

    const currentScreenWidth = this.getScreenWidth();
  }

  // Function that set currentTheme to themeClass of the theme service
  ngDoCheck(){
    this.currentTheme = this.theme.getThemeClass()
  }

  // Function call to create a new array of only single bin model
  createArrayOfBinModel(){
    // Set an object array that will receiving bin_number to be abble to compare if we already have this bin model number
    let valueAlreadyIn: { [key: string]: boolean } = {};

    // Filter the new array
    this.uniqueBinModelArray = this.distributorBinArray.filter(element => {
      let bin_number = element.bin_model_number;
      if(!valueAlreadyIn[bin_number]){
        valueAlreadyIn[bin_number] = true;
        return true;
      }
      return false;
    });
  }

  // Event listener for window resize
  @HostListener('window:resize', ['$event'])
    onResize(event: any): void {

      const currentScreenWidth = this.getScreenWidth();
      // Check the screen width and update the isSmallScreen flag accordingly
      this.isSmallScreenWidth = currentScreenWidth <= 859;
  }

  // Function called to get the column style of the grid list
  getColumnStyle(): any {
    const numberOfColumn = this.ajustNumberOfColumns(this.getScreenWidth());
    if(numberOfColumn){
      if(this.uniqueBinModelArray.length >= numberOfColumn){
        const gridColumns = `repeat(${numberOfColumn}, minmax(0, 325px))`;
        return {
          'grid-template-columns': gridColumns
        };
      }else{
        return {
          'display': `flex`,
          'justify-content': `center`,
          'margin': '0 -10px'
        };
      }
    }
  }

  // Function to get the current screen width
  getScreenWidth(): number {
    // Return the inner width of the window as the screen width
    return window.innerWidth;
  }

  // function called to adjust the number of column of the grid depbending of inner window width
  ajustNumberOfColumns(width: number): number | undefined{
    // Return the floor of the inner page width divided by 325 px as the width of the tile size
    return Math.floor((width / 325) * (90 / 100));
  }

  // Get all ths bin model for the distributor
  async getBinModelAssociationByDistributor(){
    await this.distributors.getBinModelAssociationByDistributorId(this.cognitoService.distributorId).then((res) => {
      try{
        this.distributorBinArray = res;
      }catch(error){
        console.error('Error: ', error);
      }
    });
  }

  // Function used to made a search in the list
  search(){
    // Check if there's a filterBy variable and set it to it's default value
    if(this.filterBy === ''){
      this.filterBy = 'bin_model_number';
    }

    this.uniqueBinModelArray = this.filter.search(this.userFilter, this.uniqueBinModelArray, this.originalUniqueBinArray, this.filterBy);
  }

  // Function called from switch ascendind/descending or select of sort by to sort the array of bin models
  onSortChange(caller: string, event: any){
    // Reset sortBy variable to hide the toggle switch when user press the X in the select
    if(event === undefined){
      this.isSortBy = false;
    }else{
      this.isSortBy = true;
    }

    // Switch the value of ascending or descending
    if(caller === 'switch'){
      if(this.ascDesc === 0){
        this.ascDesc = 1;
      }else{
        this.ascDesc = 0;
      }
    }

    // Avoid sortBy to have no value
    if(this.sortBy === ''){
      this.sortBy = 'number';
    }

    // Function that sort the list
    this.uniqueBinModelArray.sort((a, b) => {
      switch(this.sortBy){

        // Sorting based on bin model number
        case 'number':
          return this.ascDesc === 1 ? b.bin_model_number.localeCompare(a.bin_model_number) : a.bin_model_number.localeCompare(b.bin_model_number);

        // Sorting based on total volume
        case 'volume':
          return this.ascDesc === 1 ? b.total_volume - a.total_volume : a.total_volume - b.total_volume;

        // Sorting based on number of bins
        case 'numberBin':
          return this.ascDesc === 1 ? b.number_of_bin - a.number_of_bin : a.number_of_bin - b.number_of_bin;

        // Sorting based on presence of a certain thing
        case 'thing':
          return this.ascDesc === 1 ? b.with_thing - a.with_thing : a.with_thing - b.with_thing;
      }
    });
  }

  // Function called to go to distributor bin list from the passed bin model id and filter the list of bin related to that bin model
  goToDistributorBinDashboard(bin_model_id: string){
    // Filter the bin array of the distributor
    this.distributors.binArray = this.distributorBinArray.filter(bin => bin.bin_model_id === bin_model_id);
    this.route.navigate(['./bin-dashboard', bin_model_id]);
  }

  // Function called to go back to the dashboard
  backToDashboardList(){
    this.route.navigate(['./dashboard']);
  }

  // Function that redirect user to bin create page
  routeToBinCreate() {
    sessionStorage.setItem("referer", "distributor-bins-model-dashboard");
    this.route.navigate(['bin-create'])
  }
}
