import { Component } from '@angular/core';

@Component({
  selector: 'app-dispatch-overview',
  templateUrl: './dispatch-overview.component.html',
  styleUrl: './dispatch-overview.component.css'
})
export class DispatchOverviewComponent {

}
