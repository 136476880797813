<app-system-message></app-system-message>
<!-- Search input component, shown when mainContent is 'clients' -->
<div [ngClass]="theme.getThemeClass()" class="min-width-div container-fluid">
    <nav id="search-component" class="filter-group" *ngIf="mainContent === 'operator-list'">
        <input #searchBox type="search"  id="search-box" [(ngModel)]="userFilter" (input)="this.search()"
            class="form-control rounded searchInput"
            placeholder="{{ 'userlistSearchInputPlaceHolder' | translate }}" aria-label="Search" aria-describedby="search-addon" />
        <ng-select class="form-control ng-select select-filter-by" [(ngModel)]="this.filterBy"
          placeholder="{{ 'filterBy' | translate }}">
          <ng-option value="" disabled selected>{{ "filterBy" | translate }}</ng-option>
              <ng-option value="bin_name">{{ "binName" | translate }}</ng-option>
              <ng-option value="bin_model_number">{{ "binNumber" | translate }}</ng-option>
              <ng-option value="bin_usage">{{ "adminViewBinUsageNavBar" | translate }}</ng-option>
        </ng-select>
    </nav>

    <header [ngClass]="theme.getThemeClass()" class="table-header" *ngIf="mainContent === 'operator-list'">
        <span class="left">{{ "operatorListViewOperatorList" | translate }}</span>
        <span class="right">
          <button [ngClass]="theme.getThemeClass()" class="btn btn-dropdown" id="buttonDropdown" routerLink="/operator-create">
            {{ "newOperator" | translate }}
          </button>
        </span>
      </header>

    <!-- Table container for the user list, shown when mainContent is 'clients' -->
    <section [ngClass]="theme.getThemeClass()" class="table-container" *ngIf="mainContent === 'operator-list'">
        <article  class="table-responsive ">
            <table  class="table table-hover">
                <thead >
                    <tr>
                        <th [ngClass]="theme.getThemeClass()" scope="col">
                          {{ 'name' | translate }}
                        </th>
                        <th [ngClass]="theme.getThemeClass()" class="hide-small-screen" scope="col">
                          {{ 'legalName' | translate }}
                        </th>
                        <th [ngClass]="theme.getThemeClass()" scope="col">
                            {{ 'email' | translate }}
                          </th>
                        <th [ngClass]="theme.getThemeClass()" class="hide-small-screen" scope="col">
                          {{ 'phoneNumber' | translate }}
                        </th>
                        <th [ngClass]="theme.getThemeClass()" class="hide-small-screen" scope="col">
                          {{ 'address' | translate }}
                        </th>
                        <th [ngClass]="theme.getThemeClass()" scope="col">
                            {{ 'iotTabHeaderResources' | translate }}
                        </th>
                      </tr>
                </thead>
                <ng-container  *ngFor="let operator of this.operator.operator_array">
                    <tbody>
                        <tr style="vertical-align: middle;">
                            <td [ngClass]="theme.getThemeClass()" [style.color]="(operator.active === '1') ? 'green!important' : 'red!important'">{{ operator.operator_name }}</td>
                            <td [ngClass]="theme.getThemeClass()">{{ operator.legal_name }}</td>
                            <td [ngClass]="theme.getThemeClass()">{{ operator.email }}</td>
                            <td [ngClass]="theme.getThemeClass()">{{ this.formatarNumero(operator.phone_number) }}</td>
                            <td [ngClass]="theme.getThemeClass()" class="hide-small-screen">{{ operator.address }}</td>
                            <td [ngClass]="theme.getThemeClass()">
                                <a [routerLink]="['/operator-update', operator.operator_id]"><img class="iconUpdate" src="./assets/update-pencil.png" alt="icon of update" height="30" width="30"></a>
                            </td>
                        </tr>
                    </tbody>
                </ng-container>
            </table>
          </article>
      </section>
    </div>
