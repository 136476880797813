<app-system-message></app-system-message>
<!-- Container for the form with dynamic styling -->
<div [ngClass]="theme.getThemeClass()" class="container-fluid">
    <section [ngClass]="theme.getThemeClass()" class="card bg-light">
        <article class="card-body mx-auto article">
            <h4 class="card-title mt-0 text-center">{{ 'createLocation' | translate }}</h4>
            <form>
              <!-- Input field for address with Autocomplete -->
              <label class="label-spacer" for="distributor_name">{{ 'address' | translate }} <span class="requiredStar">*</span></label>
              <div class="input-group mb-3">
                <span class="input-group-text" id="basic-addon1"><i class="fas fa-search-location"></i></span>
                <input id="autocomplete" [(ngModel)]="this.entitiesLocationService.location_data.location" (keydown)="onInputAddressKeydown($event);"
                type="text" class="form-control" name="bin_location"
                placeholder="{{ 'clientcreateFormInputFindAddress' | translate }}"aria-label="Enter a place" aria-describedby="basic-addon1">
              </div>
              <label class="label-spacer" for="distributor_name" *ngIf="!selectedOption">{{ 'userType' | translate }} <span class="requiredStar">*</span></label>
             <div class="radioBtns" *ngIf="!selectedOption">
              <label >
                <input type="radio" name="option" value="client" [(ngModel)]="selectedOption">
                {{'_client'|translate}}
               </label>
               <label>
                <input type="radio" name="option" value="distributor" [(ngModel)]="selectedOption">
                {{'_distributor'|translate}}
               </label>
                <label>
                  <input type="radio" name="option" value="operator" [(ngModel)]="selectedOption">
                  {{'chartLabelOperator'|translate}}
                </label>
             </div>
              
              <!-- Client Input -->
              <div *ngIf="selectedOption === 'client'">
                <label *ngIf="this.cognitoService.userType === 'muirwood'" class="label-spacer" for="distributor_name">{{ 'client' | translate }} <span class="requiredStar">*</span></label>
                <div class="input-group mb-3" *ngIf="this.cognitoService.userType === 'muirwood'">
                  <span class="input-group-text" id="basic-addon1"><i class="fa-regular fa-address-card"></i></span>
                  <ng-select [(ngModel)]="this.selectedClient" class="form-control ng-select" name="client"
                    aria-label="Client Name" aria-describedby="basic-addon1" (change)="selectedClientChange()"
                    [ngClass]="{'has-value': this.selectedClient }">
                      <ng-option value="" selected>{{ "clientName" | translate }}</ng-option>
                      <ng-container *ngFor="let option of this.clientService.clientsArray">
                        <ng-option value="{{ option.client_id }}">{{ option.legal_name }}</ng-option>
                      </ng-container>
                  </ng-select>
                  <span class="input-group-text" (click)="clearRadio()" style="cursor: pointer;"><i class="fa-solid fa-xmark"></i></span>
                </div>
              </div>
              <!-- Distributor Input -->
              <div *ngIf="selectedOption === 'distributor'">
                <label *ngIf="this.cognitoService.userType === 'muirwood'" class="label-spacer" for="distributor_name">{{ '_distributor' | translate }} <span class="requiredStar">*</span></label>
              <div class="input-group mb-3" *ngIf="this.cognitoService.userType === 'muirwood'">
                <span class="input-group-text" id="basic-addon1"><i class="fa-solid fa-id-card"></i></span>
                <ng-select [(ngModel)]="this.selectedDistributor" class="form-control ng-select" name="client"
                  aria-label="Client Name" aria-describedby="basic-addon1" (change)="selectedDistributorChange()"
                  [ngClass]="{'has-value': this.selectedDistributor}">
                    <ng-option value="" selected>{{ "distributorName" | translate }}</ng-option>
                    <ng-container *ngFor="let option of this.distributorService.distributorsArray">
                      <ng-option value="{{ option.distributor_id }}">{{ option.legal_name }}</ng-option>
                    </ng-container>
                </ng-select>
                <span class="input-group-text" (click)="clearRadio()" style="cursor: pointer;"><i class="fa-solid fa-xmark"></i></span>
              </div>
              </div>
              <!-- Operator Input -->
              <div *ngIf="selectedOption === 'operator'">
                <label *ngIf="this.cognitoService.userType === 'muirwood'" class="label-spacer" for="distributor_name">{{ 'chartLabelOperator' | translate }} <span class="requiredStar">*</span></label>
              <div class="input-group mb-3" *ngIf="this.cognitoService.userType === 'muirwood'">
                <span class="input-group-text" id="basic-addon1"><i class="fa-regular fa-id-card"></i></span>
                <ng-select [(ngModel)]="this.selectedOperator" class="form-control ng-select" name="client"
                  aria-label="Client Name" aria-describedby="basic-addon1" (change)="selectedOperatorChange()"
                  [ngClass]="{'has-value': this.selectedOperator}">
                    <ng-option value="" selected>{{ "selectOperator" | translate }}</ng-option>
                    <ng-container *ngFor="let option of this.operatorService.operator_array">
                      <ng-option value="{{ option.operator_id }}">{{ option.legal_name }}</ng-option>
                    </ng-container>
                </ng-select>
                <span class="input-group-text" (click)="clearRadio()" style="cursor: pointer;"><i class="fa-solid fa-xmark"></i></span>
              </div>
              </div>
              <!-- Submit button -->
              <nav class="form-group buttonDiv">
                  <button (click)="returnToEntities()" [ngClass]="theme.getThemeClass()" type="button" class="btn btn-block btnCancel">{{ 'clientcreateFormBtnCancel' | translate }}</button>
                  <button [disabled]="this.systemMessageService.buttonBlocked" [ngClass]="theme.getThemeClass()" (click)="onSubmit(); disableButton()" type="button" class="btn btn-block btnCreate">{{ 'clientcreateFormBtnCreateClient' | translate }}</button>
              </nav>
            </form>
        </article>
    </section>
</div>


