<app-system-message></app-system-message>
<div [ngClass]="theme.getThemeClass(true)" class="container-fluid">
  <div class="page-header">
    <!-- For future development -->
    <!-- <button class="btn back" (click)="backToBinModelList()"><i class="fa fa-arrow-left"></i></button> -->
    <h2>{{"userList" | translate}}</h2>
  </div>
  <nav
    id="search-component"
    class="nav-sort-filter"
  >
  <!-- Section for filter and sort list -->
    <input
      #searchBox
      type="search"
      id="search-box"
      [(ngModel)]="userFilter"
      (input)="
        this.search()
      "
      class="form-control rounded searchInput"
      placeholder="{{ 'userlistSearchInputPlaceHolder' | translate }}"
      aria-label="Search"
      aria-describedby="search-addon"
    />
    <ng-select class="form-control ng-select sort-by-select" [(ngModel)]="this.filterBy" (change)="onFilterByChange()"
      placeholder="{{ 'filterBy' | translate }}" [ngClass]="{'has-value': this.filterBy }">
      <ng-option value="" disabled selected>{{ "filterBy" | translate }}</ng-option>
          <ng-option value="username">{{ "cognitoLoginUsername" | translate }}</ng-option>
          <ng-option value="given_name">{{ "givenName" | translate }}</ng-option>
          <ng-option value="family_name">{{ "familyName" | translate }}</ng-option>
    </ng-select>
    <ng-select class="form-control ng-select sort-by-select" [(ngModel)]="this.sortBy"
      (change)="onSortChange('select', $event)" placeholder="{{ 'sortBy' | translate }}" [ngClass]="{'has-value': this.sortBy}">
      <ng-option value="" disabled selected>{{ "sortBy" | translate }}</ng-option>
          <ng-option value="username">{{ "cognitoLoginUsername" | translate }}</ng-option>
          <ng-option value="given_name">{{ "givenName" | translate }}</ng-option>
          <ng-option value="family_name">{{ "familyName" | translate }}</ng-option>
    </ng-select>
    <div class="card-title mt-0 status">
      <label class="switch">
          <input type="checkbox" [ngModel]="this.ascDesc" (change)="onSortChange('switch', $event)">
          <span class="slider round">
            <span class="toggle-switch-label-off">{{ "asc" | translate }}</span>
            <span class="toggle-switch-label-on">{{ "desc" | translate }}</span>
          </span>
      </label>
    </div>
  </nav>
  <section class="createBtns d-flex justify-content-center mb-2">
    <button [ngClass]="theme.getThemeClass()" class="btn btn-dropdown" id="buttonDropdown" (click)="toCreateDistributor()">
      {{ "newDistributorUser" | translate }}
    </button>
  </section>
  
<div class="grid-container">
  <section [ngClass]="theme.getThemeClass(true)" class="gridster" [ngStyle]="getColumnStyle()" style="height: 100vh!important;">
    <ng-container *ngFor="let item of this.distributors.distributorUsersArray; let i = index">
    <article class="gridster-item">
      <!-- Your tile content goes here -->
        <nav [ngClass]="theme.getThemeClass(true)" class="dashboard-tile">
          <header class="dashboard-tile-header">
            <a *ngIf="this.isUserHaveAdminRole()" (click)="goToUserUpdate(item.user, item.username)"><h6 class="dashboard-tile-title">{{ item.given_name }} {{ item.family_name }}</h6></a>
          </header>
          <hr>
          <div class="tiles-container">
            <div class="titles-items">
              <i class="fa fa-user"></i><label>{{ item.username}}</label>
            </div>
            <div class="titles-items ellipsis-container" (click)="displayAddress(i)" style="cursor: pointer;">
              <span class="i"><i class="fa-solid fa-location-dot pointer"></i></span> <label  style="cursor: pointer;" *ngIf="item.address; else noneApplicable" id="{{i}}" class="address ">{{ item.address }}</label>
            </div>
            <div class="titles-items" >
              <i class="fa-solid fa-phone"></i><label  *ngIf="item.phone_number; else noneApplicable">{{ item.phone_number }}</label>
            </div>
            <div class="titles-items">
              <i class="fa-solid fa-at"></i><label>{{ item.email }}</label>
            </div>
          </div>
        </nav>
      </article>
  </ng-container>
</section>
</div>
</div>
<ng-template #noneApplicable>
  <label>N/A</label>
</ng-template>
