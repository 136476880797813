

<div class="container-fluid">
    <section [ngClass]="theme.getThemeClass()" class="modal col-10">
        <div class="hideNavBar"></div>
        <h4 class="relationship-modal-title"><i class="fa-solid fa-triangle-exclamation"></i>
            {{ "warning" | translate }} <i class="fa-solid fa-triangle-exclamation"></i>
        </h4>
        <article class="device-association-article col-12">
            <h5 class="association-sub-title text-center subtitle"> 
                {{ "warningMessage" | translate }}
            </h5>
            <div class="col-10 device-association ">
    
                <!-- DEVICES QUANTITY -->
                <h5 class="association-sub-title mainContent">
                    {{ "devicesQuantity" | translate }} {{ this.relationshipModalService.devicesQuantity }}
                </h5>
    
                <!-- BIN QUANTITY -->
                <h5 class="association-sub-title mainContent">
                    {{ "binsQuantity" | translate}} {{ this.relationshipModalService.binQuantity }}
                </h5>
        
                <!-- USERS QUANTITY -->
                <h5 class="association-sub-title mainContent">
                    {{ "userQuantity" | translate}} {{ this.relationshipModalService.userQuantity }}
                </h5>
                
                <div class="association-select-div">
                
                </div>
            </div>
            <nav class="form-group displayFlex">
                <button [ngClass]="theme.getThemeClass()" (click)="closeModal()"  type="button" class="button btn btn-primary btnCancel fixed-size">{{ 'cancel' | translate }}</button>
                <button *ngIf="this.relationshipModalService.updateTarget === ''" [ngClass]="theme.getThemeClass()" (click)="deleteClient()" type="button" class="button btn btn-primaryy btnCancel fixed-size red">{{ 'delete' | translate }}</button>
                <button *ngIf="this.relationshipModalService.updateTarget === 'distributor'" [ngClass]="theme.getThemeClass()" (click)="deleteDistributor()" type="button" class="button btn btn-primaryy btnCancel fixed-size red">{{ 'delete' | translate }}</button>
            </nav>
        </article>
        
    </section>
</div>
