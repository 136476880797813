import { Component, OnInit, Renderer2 } from '@angular/core';
import { ThemeService } from '../service/theme.service';
import { Router } from '@angular/router';
import { DistributorsService } from '../service/distributors.service';
import { CognitoService } from '../service/cognito.service';
import { environment } from '../environments/environment';
import { RoleService } from '../service/role.service';
import { SystemMessageService } from '../service/system-message.service';
import { LocalStorageService } from '../local-storage.service';

@Component({
  selector: 'app-distributor-admin-dashboard',
  templateUrl: './distributor-admin-dashboard.component.html',
  styleUrls: ['./distributor-admin-dashboard.component.css', '../distributor-bins-model-dashboard/distributor-tiles.css', '../../global-elements.css']
})
export class DistributorAdminDashboardComponent implements OnInit{
  gridsterOptions = {
    draggable: {
      enabled: false,
    },
    resizable: {
      enabled: false,
    },
    minCols: 1,
    maxCols: 5,
    minRows: 4,
    maxRows: 100,
    defaultItemCols: 1,
    defaultItemRows: 1,
  };

  private originalUserArray: any[] = [];

  public userFilter: string = '';
  public filterBy: string = '';
  public sortBy: string = '';
  public ascDesc: number = 0;
  private filterCount: number = 0;
  private lastFilterCount: number = 0;
  private isFilter: boolean = false;
  public isSortBy: boolean = false;

  constructor(public theme: ThemeService,
              private route: Router,
              public distributors: DistributorsService,
              private cognitoService: CognitoService,
              private renderer: Renderer2,
              public systemMessageService: SystemMessageService,
              public roleService: RoleService,
              private router: Router
    ){

  }

  // Function used to redirect user to the distributor creation page
  toCreateDistributor(){
    sessionStorage.setItem('createDistributor', 'distributor-admin-dashboard')
    this.router.navigate(['user-create-distributor']);
  }

  async ngOnInit() {
    // Call cognito to confirme the user and get his user type
    await this.cognitoService.confirmValidUser();
    await this.cognitoService.getUserType();

    // Get user roles
    await this.roleService.getRoles();
    // Retrieve the value of 'from' from sessionStorage
    const fromComponent = sessionStorage.getItem('from');

    // Check if 'fromComponent' has a value
    if(fromComponent){
      // Switch statement based on the value of 'fromComponent'
      switch(fromComponent){
        // If 'fromComponent' is 'user-update-distributor
        case 'user-update-distributor':
          // Call a method from systemMessageService to select a ribbon with success message
          this.systemMessageService.selectRibbon('success', 'alert-success-generic-message-changes');
        break;
        // Default case
        default:
        break;

      }
      // Remove 'from' from sessionStorage
      sessionStorage.removeItem('from');
    }


    // If the array of users for the distributor ID of this user is not implemented yet, it go that it
    if(this.distributors.distributorUsersArray.length === 0 || this.distributors.distributorUsersArray === undefined){
      await this.distributors.getUsersByDistributorId(this.cognitoService.distributorId);
    }
    // Set the original array for the search
    this.originalUserArray = this.distributors.distributorUsersArray;
  }

  // Function called to reset the user array
  resetUserArray(){
    this.distributors.distributorUsersArray = [...this.originalUserArray];
  }

  // Funciton called to know if user have the admin role
  isUserHaveAdminRole(){
    return this.roleService.roles.includes('_admin');
  }

  // Ensures that the user performs a new search every time the type of thing to be filtered changes
  onFilterByChange() {
    // Reset user array
    this.resetUserArray();

    // Perform search based on selected filter
    this.search();

    // Clear the user input filter
    this.userFilter = '';

  }

  // Function called to filter the array
  search(){
    // Set last filter count, filter count and if there's a filter
    this.lastFilterCount = this.filterCount;
    this.isFilter = this.userFilter.toLowerCase() !== '';
    this.filterCount = this.userFilter.length;

    // Set a filter property
    let filterProperty = this.filterBy;
    if(filterProperty === '' || filterProperty === undefined || filterProperty === null){
      filterProperty = 'username';
      this.filterBy = 'username';
    }

    // Switch if user enter more filter, press backspace or delete filter
    switch(true){

      case (this.filterCount > this.lastFilterCount):
        this.distributors.distributorUsersArray = this.distributors.distributorUsersArray.filter( v => {
          if(v[filterProperty] && typeof v[filterProperty] === 'string'){
            return v[filterProperty].toLowerCase().includes(this.userFilter.toLowerCase());
          }else{
            return false;
          }
        });
        this.lastFilterCount = this.filterCount; // Set the count of the user filter
      break;

      case (this.filterCount < this.lastFilterCount):
        this.resetUserArray(); // Reset the array of bin models
        // Filter the array of bin models
        this.distributors.distributorUsersArray = this.distributors.distributorUsersArray.filter( v => {
          if(v[filterProperty] && typeof v[filterProperty] === 'string'){
            return v[filterProperty].toLowerCase().includes(this.userFilter);
          }else{
            return false;
          }
        });
        this.lastFilterCount = this.filterCount; // Set the count of the user filter
      break;

      case (this.isFilter === false):
        this.resetUserArray(); // Reset the array of bin models
        this.filterCount = 0;
        this.lastFilterCount = 0;
      break;
    }
  }

  // Function triggered when user change sort on collumn
  onSortChange(caller: string, event: any){

    // Reset sortBy variable to hide the toggle switch when user press the X in the select
    if(event === undefined){
      this.isSortBy = false;
    }else{
      this.isSortBy = true;
    }

    // Switch the value of ascending or descending
    if(caller === 'switch'){
      if(this.ascDesc === 0){
        this.ascDesc = 1;
      }else{
        this.ascDesc = 0;
      }
    }

    // Avoid sortBy to have no value
    if(this.sortBy === ''){
      this.sortBy = 'username';
    }

    // Function that sort the list
    this.distributors.distributorUsersArray.sort((a, b) => {
      switch(this.sortBy){
        case 'username':
          return this.ascDesc === 1 ? b.username.localeCompare(a.username) : a.username.localeCompare(b.username);

        case 'given_name':
          return this.ascDesc === 1 ? b.given_name.localeCompare(a.given_name) : a.given_name.localeCompare(b.given_name);

        case 'family_name':
          return this.ascDesc === 1 ? b.family_name.localeCompare(a.family_name) : a.family_name.localeCompare(b.family_name);
      }
    });
  }

  // Function called to get the style of the columns of the grid list
  getColumnStyle(): any {
    const numberOfColumn = this.ajustNumberOfColumns(this.getScreenWidth());
    if(numberOfColumn){
      if(this.distributors.distributorUsersArray.length >= numberOfColumn){
        const gridColumns = `repeat(${numberOfColumn}, minmax(0, 350px))`;
        return {
          'grid-template-columns': gridColumns
        };
      }else{
        return {
          'display': `flex`,
          'justify-content': `center`,
          'margin': '0 -10px'
        };
      }
    }
  }

  // function called to adjust the number of column of the grid depbending of inner window width
  ajustNumberOfColumns(width: number): number | undefined{
    // Return the floor of the inner page width divided by 450 px as the width of the tile size
    return Math.floor((width / 350) * (90 / 100));
  }

  // Function to get the current screen width
  getScreenWidth(): number {
    // Return the inner width of the window as the screen width
    return window.innerWidth;
  }

  // Function used to redirect user to distributor user update page
  goToUserUpdate(user:string, username: string){
    let userType = environment.users.supplier;
    this.route.navigate(['./user-update-distributor'], {
      queryParams: {
        user,
        username,
        userType
      }
    })
  }

    /**
 * Function that toggles the display of the complete address of the user .
 * @param i the id of the address container
 */
  displayAddress(i:number){
    //get the address container
    let element = document.getElementById(i.toString());
    //logic to display the address
    if(element && element.classList.contains('address')){
        //to show complete address
        this.renderer.removeClass(element,'address')
        this.renderer.addClass(element,'addressVisible')
    } else{
      //to show address with ellipsis
      this.renderer.addClass(element,'address')
      this.renderer.removeClass(element,'addressVisible')
    }
  }

  // Function used to redirect user to his dashboard
  backToDashboardList(){
    this.route.navigate(['./dashboard']);
  }
}
