import { Injectable } from '@angular/core';
import { HttpClient , HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { environment } from '../environments/environment';
import { map, catchError } from 'rxjs/operators';
import { BinUsage } from '../constants/bin-usage';

export interface Distributors {
  name: string;
  legalName: string;
  phoneNumber: string;
  address: string;
  logo: string;
  email: string;
  created: string;
  modified: string;
}

@Injectable({
  providedIn: 'root'
})
export class DistributorsService {

  distributorData = {
    distributor_id: "",
    distributor_name: "",
    legal_name: "",
    phone_number: "",
    address: "",
    email: "",
    created: "",
    modified: ""
  };

  public distributorsArray: any[] = [];
  public distributorUsersArray: any[] = [];
  public distributorBinsDashboardArray: any[] = [];
  public distributorIotDashboardArray: any[] = [];
  public binArray: any[] = [];

  public dateFixed: any;

  public array: any;

  constructor(private http: HttpClient) {}

  // Function called to replace bin usage key by there proper translation key
  replaceBinUsageKey(): void{
    this.distributorBinsDashboardArray.forEach(item => {
      const binUsage = BinUsage.find(usage => usage.value === item.bin_usage);
      if(binUsage){
        item.bin_usage = binUsage.label;
      }
    });
  }

  // Trigger deletion of client and related data
  onDeleteDistributor(): Observable<boolean> {
    // Call deleteClientAndRelatedData with client ID from client data
    return this.deleteDistributorAndRelatedData(this.distributorData.distributor_id)
      .pipe(
        map(response => {
          // Add any desired success handling here
          return true; // Return true to indicate success
        }),
        catchError(error => {
          console.error('Error deleting Distributor and related data', error);
          // Return false to indicate failure
          return of(false);
        })
      );
  }

  // Delete client and related data by sending a POST request to the server
  deleteDistributorAndRelatedData(distributorId: string): Observable<any> {

    const headers = new HttpHeaders({
      'Content-Type':  'application/json' // Adjust content type as needed
    });

    // Send POST request to delete client and related data
    return this.http.post(environment.api.stage + environment.api.route.deleteDistributorAndRelatedData,
      {
        "distributor_id": distributorId, // Include client ID in the request body
      }, {headers: headers}
    );
  }

  // Function to set an array of the keys we should set as int into bin model response
  switchJSONToIntForBinModelResponse(element: any){
    // Tableau of witch keys we need to check
    const keysToCheck = [
      'above_groud',
      'active',
      'bin_height',
      'bin_width',
      'bin_depth',
      'total_volume',
      'with_thing',
      'number_of_bin',
      'created',
      'modified'
    ];
    // Call the function to transfert all value of the keys in Integer
    this.keyToTransfert(keysToCheck, element);
  }

  // Function to set an array of the keys we should
  switchJSONToIntForUserResponse(element: any){
    // Tableau of witch keys we need to check
    const keysToCheck = [
      'created',
      'modified'
    ];
    // Call the function to transfert all value of the keys in Integer
    this.keyToTransfert(keysToCheck, element);
  }

  // Use the array of keys to transfert strings into int
  keyToTransfert(keysToCheck: any, element: any){
    keysToCheck.forEach((key: any) => {
      // Vérifier si la clé existe dans l'élément et si sa valeur est une chaîne
      if (typeof element[key] === 'string') {
          // Convertir la valeur de la clé en entier
          element[key] = parseInt(element[key], 10);
      }
    });
  }

  // Function async used for select in modals to insure that select are implemented
  getDistributorForSelect(): Promise<any[]>{
    return new Promise<any[]>((resolve) => {
      // Call the function that call the lambda function
      this.getDistributors().subscribe((response) => {
        this.array = response;
        this.distributorsArray = this.array;
        resolve(this.distributorsArray);
      });
    });
  }

  // Function that return bin model associated to a distributor
  async getBinModelAssociationByDistributorId(distributor_id: string): Promise<any[]>{
    return new Promise<any[]>((resolve) => {
      this.getBinModelAssociationByDistributorIdLambda(distributor_id).subscribe((response) => {
        // Put the array returned by the lambda function into an tmp array
        this.array = response;
        this.distributorBinsDashboardArray = this.array;
        this.replaceBinUsageKey();

        resolve(this.array);
      });
    });
  }

  // Function that return device associated to a distributor
  async getDeviceByDistributorId(distributor_id: string): Promise<any[]>{
    return new Promise<any[]>((resolve) => {
      this.getDeviceByDistributorIdLambda(distributor_id).subscribe((response) => {
        // Put the array returned by the lambda function into an tmp array
        this.array = response;
        this.distributorIotDashboardArray = this.array;
        resolve(this.array);
      })
    });
  }

  // Function that return users of a distributor
  async getUsersByDistributorId(distributor_id: string): Promise<any[]>{
    return new Promise<any[]>((resolve) => {
      this.getUserByDistributorIdLambda(distributor_id).subscribe((response) => {
        // Put the array returned by the lambda function into an tmp array
        this.array = response;
        this.distributorUsersArray = this.array;
        resolve(this.distributorUsersArray);
      })
    });
  }



  ///////////////////////////// Section for lambda functinos ////////////////////////////////////



  // Function that call API Gateway to create a distributor
  createDistributors(): Observable<any>{
    // Define the HTTP headers with content type
    const headers = new HttpHeaders({
      'Content-Type':  'application/json' // Adjust content type as needed
    });

    return this.http.post(environment.api.stage + environment.api.route.createDistributor,
      {
        "distributor_name": this.distributorData.distributor_name,
        "legal_name": this.distributorData.legal_name,
        "phone_number": this.distributorData.phone_number,
        "email": this.distributorData.email,
        "address": this.distributorData.address,
        "created": this.distributorData.created,
        "modified": this.distributorData.modified
      }, {headers: headers}
    );
  }

  //Update Distributor with API Gateway
  updateDistributor(): Observable<any>{

    // Define the HTTP headers with content type
    const headers = new HttpHeaders({
      'Content-Type':  'application/json' // Adjust content type as needed
    });

    return this.http.post(environment.api.stage + environment.api.route.updateDistributor,
      {
       "distributor_id": this.distributorData.distributor_id,
       "distributor_name": this.distributorData.distributor_name,
       "legal_name": this.distributorData.legal_name,
       "phone_number": this.distributorData.phone_number,
       "email": this.distributorData.email,
       "address": this.distributorData.address,
       "modified": this.distributorData.modified
     }, {headers: headers}
   );
 }

 // Function that call API Gateway to get all distributor
 getDistributors(){
  // Append the 'user' parameter to the URL as a query string
  const url = environment.api.stage + environment.api.route.getDistributors

  return this.http.get(url).pipe(
    map((response) => {
      // return the response data
      this.distributorsArray.push(response);

      return response;
    }),
    catchError((error) => {
      console.error('API Error:', error);
      throw error(error); // Re-throw the error for the calling code to handle
    }));
  }

  // Function that call API Gateway to get all bin model from a distributor
  getBinModelAssociationByDistributorIdLambda(distributor_id: string){
    // set the url for lambda call
    const url = environment.api.stage + environment.api.route.getBinModelAssociationsByDistributorId + "&distributor_id=" + distributor_id;

    // Will call the lambda function then return a response
    return this.http.get(url).pipe(
      map((response) => {
        this.array = response;

        this.array = this.array.forEach((element: any) => {
          this.switchJSONToIntForBinModelResponse(element);
        });
        // return the response data
        return response;
      }),
      catchError((error) => {
        console.error('API Error:', error);
        throw error(error); // Re-throw the error for the calling code to handle
      })
    );
  }

  // Function that call API Gateway to get device associated to a distributor
  getDeviceByDistributorIdLambda(distributor_id: string){
    // Set url for lambda call
    const url = environment.api.stage + environment.api.route.getDeviceByDistributorId + "&distributor_id=" + distributor_id;

    // Will call the lambda function then return a response
    return this.http.get(url).pipe(
      map((response) => {
        // Return the response data
        return response;
      }),
      catchError((error) => {
        console.error("API Error: ", error);
        throw error(error);
      })
    );
  }

  // function that call the lambda API to get all users of the distributor
  getUserByDistributorIdLambda(distributor_id: string){
    // Set url for lambda call
    const url = environment.api.stage + environment.api.route.getUsersByDistributorId + "&distributor_id=" + distributor_id;

    // Will call the lambda function then return a response
    return this.http.get(url).pipe(
      map((response) => {
        this.array = response;

        this.array = this.array.forEach((element: any) => {
          this.switchJSONToIntForUserResponse(element);
        });
        // Return the response data
        return response;
      }),
      catchError((error) => {
        console.error("API Error: ", error);
        throw error(error);
      })
    );
  }

  // Function called API Gateway to get all distributor work order
  getAllEntityWorkOrdersLambda(distributor_id: string){
    // Set url for the lambda function
    const url = environment.api.stage + environment.api.route.getAllEntityWorkOrders + "&distributor_id=" + distributor_id;

    // Will call the lambda function then return a response
    return this.http.get(url).pipe(
      map((response) => {
        // Return the response data
        return response;
      }),
      catchError((error) => {
        console.error("API Error: ", error);
        throw error(error);
      })
    );
  }
}
