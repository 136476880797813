<div [ngClass]="theme.getThemeClass()" class="modal col-10" *ngIf="this.reports.showReportModal" >
  <!-- Close modal button -->
  <div class="modal-header">
    <button type="button" class="close" aria-label="Close" (click)="closeModal()">
        <span [ngClass]="theme.getThemeClass()" aria-hidden="true">&times;</span>
    </button>
  </div>
  <!-- Reports VIEW -->
  <div [ngClass]="theme.getThemeClass()" class="divReportViewConfig">
    <h4 class="filter-title">{{ "filters" | translate }}</h4>
    <nav class="filter-bar">
      <!-- Device Statistics Filters -->
      <div class="device-filter">
        <div class="form-group">
        </div>
        <!-- Device Statistics Filters -->
        <!--Device Date pickers-->
        <div class="device-filter">
          <div class="form-group">
            <div class="force-column">
              <label class="form-label">
                {{ "startingDate" | translate }}
              </label>
              <input [(ngModel)]="startDate" class="form-control" type="date" />
            </div>
          </div>
          <div class="form-group">
            <div class="force-column">
              <label class="form-label">
                {{ "endDate" | translate }}
              </label>
              <input [(ngModel)]="endDate" class="form-control" type="date" />
            </div>
          </div>
        </div>
      </div>
      <!-- Device Statistics Filters -->
      <div *ngIf="!allMarkets" class="form-group">
        <div class="force-column">
          <label class="form-label">{{ "marketSegments" | translate }}</label>
          <ng-select
            disabled="true"
            [(ngModel)]="selectedMarkets"
            [multiple]="true"
            class="form-control ng-select"
          >
            <ng-option disabled value="All" selected>{{
              "allMarkets" | translate
            }}</ng-option>
          </ng-select>
        </div>
      </div>
    </nav>
    <nav class="filter-bar">
      <div class="toggle-stack">
        <div class="toggle-box">
          <label class="devices-toggle">{{ "allDevices" | translate }}</label>
          <label class="markets-toggle">{{ "allMarkets" | translate }}</label>
        </div>
        <div class="filter-toggle">
          <div class="custom-control custom-switch cs-container">
            <!-- Rounded switch -->
            <label class="switch">
              <input
                type="checkbox"
                [ngModel]="allThings"
                name="devices"
                (change)="onChangeAllThings($event)"
              />
              <span class="slider round"></span>
            </label>
          </div>
          <div class="custom-control custom-switch cs-container">
            <!-- Rounded switch -->
            <label class="switch">
              <input
                type="checkbox"
                [ngModel]="allMarkets"
                name="markets"
                (change)="onChangeAllMarkets($event)"
                [disabled]="true"
              />
              <span class="slider round"></span>
            </label>
          </div>
        </div>
      </div>
    </nav>
    <!-- Select Thing Input -->
    <div *ngIf="!allThings" class="form-group">
      <div class="force-column">
        <label class="form-label">{{ "devices" | translate }}</label>
        <ng-select
          [(ngModel)]="selectedThings"
          class="form-control ng-select inputWidth"
          [multiple]="true"
        >
          <ng-container *ngFor="let option of this.things">
            <ng-option value="{{ option.id }}"> {{ option.name }}</ng-option>
          </ng-container>
        </ng-select>
      </div>
    </div>
    <div class="btn-holder col-3">
      <!-- Button to open the option list of PRINT PDF -->
      <details  [ngClass]="theme.getThemeClass()"  class="form-control btn btn-primary">
        <summary>{{ "printPDF" | translate }}</summary>
        <div>
          <button [ngClass]="theme.getThemeClass()"
            _ngcontent-ng-c915876578=""
            class="form-control btn btn-primary btn-bloc btn-second optionSlcBtn "
            ng-reflect-ng-class="bg-dark text-white"
            (click)="compileReport()"
            [disabled]="pdf.generatingPDF"
          >
          {{ "collections" | translate }}
          </button>
        </div>
        <div>
          <button [ngClass]="theme.getThemeClass()"
            _ngcontent-ng-c915876578=""
            class="form-control btn btn-primary btn-bloc btn-second optionSecondSlcBtn"
            ng-reflect-ng-class="bg-dark text-white"
            [disabled]="true"
            [ngStyle]="{'background-color': 'lightgray', 'cursor': 'not-allowed'}"
          >
          {{ "capacity" | translate }}
          </button>
        </div>
        <div>
          <button [ngClass]="theme.getThemeClass()"
            _ngcontent-ng-c915876578=""
            class="form-control btn btn-primary btn-bloc btn-second optionThirdSlcBtn "
            ng-reflect-ng-class="bg-dark text-white"
            [disabled]="true"
            [ngStyle]="{'background-color': 'lightgray', 'cursor': 'not-allowed'}"
          >
          {{ "connectivity" | translate }}
          </button>
        </div>
        <div>
          <button [ngClass]="theme.getThemeClass()"
            _ngcontent-ng-c915876578=""
            class="form-control btn btn-primary btn-bloc btn-second optionThirdSlcBtn "
            ng-reflect-ng-class="bg-dark text-white"
            [disabled]="true"
            [ngStyle]="{'background-color': 'lightgray', 'cursor': 'not-allowed'}"
          >
          {{ "events" | translate }}
          </button>
        </div>
      </details>
      <!-- Button to open the option list of CSV EXPORT -->
      <details  [ngClass]="theme.getThemeClass()"  class="form-control btn btn-primary">
        <summary>{{ "exportCSV" | translate }}</summary>
        <div>
          <button [ngClass]="theme.getThemeClass()"
            _ngcontent-ng-c915876578=""
            class="form-control btn btn-primary btn-bloc btn-second optionSlcBtn "
            ng-reflect-ng-class="bg-dark text-white"
            (click)="exportCSVReports(3)"
          >
          {{ "collectionCSV" | translate }}
          </button>
        </div>
        <div>
          <button [ngClass]="theme.getThemeClass()"
            _ngcontent-ng-c915876578=""
            class="form-control btn btn-primary btn-bloc btn-second optionSecondSlcBtn"
            ng-reflect-ng-class="bg-dark text-white"
            (click)="exportCSVReports(1)"   
          >
          {{ "historyCSV" | translate }}
          </button>
        </div>
        <div>
          <button [ngClass]="theme.getThemeClass()"
            _ngcontent-ng-c915876578=""
            class="form-control btn btn-primary btn-bloc btn-second optionThirdSlcBtn "
            ng-reflect-ng-class="bg-dark text-white"
            (click)="exportCSVReports(2)"
          >
          {{ "binReportCSV" | translate }}
          </button>
        </div>
      </details>
    </div>
  </div>
</div>
  
<!-- Graph Canvas for PDF Report -->
<div id="canvas-holder" style="max-height: 1000px;overflow-y: auto;">
  <canvas #tonnageCanvas style="display: none"></canvas>
  <canvas #usageCanvas style="display: none"></canvas>
  <canvas #marketCanvas style="display: none"></canvas>
  <canvas #countCanvas style="display: none"></canvas>
</div>
  