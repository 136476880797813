<div [ngClass]="theme.getThemeClass()" style="width: 100%;">
    <ngx-charts-line-chart
      [view]="charts.viewLineChartTechnical"
      [scheme]="charts.colorSchemeLineChart"
      [legend]="false"
      [legendPosition]="charts.legendPositionLineChartAdmin"
      [showXAxisLabel]="true"
      [showYAxisLabel]="charts.showYAxisLabelLineChartAdmin"
      [xAxis]="charts.showXAxisLineChartAdmin"
      [yAxis]="charts.showYAxisLineChartAdmin"
      [xAxisLabel]="charts.xAxisLabelBatLineChartAdmin"
      [yAxisLabel]="charts.yAxisLabelTemp | translate "
      [timeline]="true"
      [yAxisTicks]="charts.customTempYAxisTicks"
      [results]="charts.singleLineChartTemp"
      (select)="charts.onSelectLineChart($event)"
      (activate)="charts.onActivateLineChart($event)"
      (deactivate)="charts.onDeactivateLineChart($event)"
      [yScaleMin]="charts.yScaleMinTemp"
      [yScaleMax]="charts.yScaleMaxTemp"
      >
     </ngx-charts-line-chart>
    <div class="line-chart-container legend">
      <h6><span class="legendColor green"></span>{{"temperature"|translate}}</h6>
      </div>
   </div> 
